import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Participant from '../Participant';
import { useCompetitor } from './AccordionCompetitor';
import { ParticipantAdminDetails } from '../ParticipantAdminDetails';
import { memo } from '../../../../util/memo';
import { stableHash } from '../../../../../functions/src/util/hash/stableHash';
import { useTournamentPermissions } from '../../../../hooks/tournaments/useTournamentPermissions';

const AccordionDetailsCompetitorUnmemoized: React.FC = () => {
  const { teamMax, participants } = useCompetitor();
  const { isTournamentAdmin } = useTournamentPermissions();

  return (
    <AccordionDetails sx={{ p: 0 }}>
      <Stack>
        {participants.slice(0, teamMax).map((competitor) => {
          const {
            imgUrl,
            username,
            userId,
            checkedIn,
            status,
            tournamentId,
            ...memberAdminDetails
          } = competitor;
          return (
            <Stack key={stableHash(competitor)}>
              <Participant {...competitor} />
              {isTournamentAdmin && (
                <ParticipantAdminDetails {...memberAdminDetails} />
              )}
            </Stack>
          );
        })}
      </Stack>
    </AccordionDetails>
  );
};
export const AccordionDetailsCompetitor = memo(
  AccordionDetailsCompetitorUnmemoized,
);
