import AccountTreeIcon from '@mui/icons-material/AccountTreeRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { MarkdownAccordionInput } from '../specific/MarkdownAccordionInput';
import { SelectInput } from '../generic/SelectInput';
import { TournamentPayoutsInput } from '../payouts/TournamentPayoutsInput';
import { SponsorsInput } from '../specific/SponsorsInput';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import PublicIcon from '@mui/icons-material/PublicRounded';
import { RenderOverrideEntry } from '../generic/ValueWrapper';
import { BRACKET_TYPE } from '../../../../functions/src/types/firestore/Game/Tournament';
import { EVENT_VISIBILITY } from '../../../../functions/src/types/firestore/Game/Competition';

export const MAX_TEAM_SIZE_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
] as const;
export const MIN_TEAM_SIZE_OPTIONS = MAX_TEAM_SIZE_OPTIONS;
export const REGION_OPTIONS = [
  'Global',
  'NA-East',
  'NA-West',
  'EU-West',
  'EU-East',
  'Middle East',
  'Asia-West',
  'Asia-East',
  'SA',
  'SEA',
  'Africa',
] as const;

export type RenderOverrides = {
  [key: string]: RenderOverrideEntry<React.ComponentType<any>>;
};

export const BRACKET_TYPE_TO_DISPLAY = {
  'single-elimination': 'Single Elimination',
  'double-elimination': 'Double Elimination',
  heats: 'Heats',
  'no-bracket': 'No Bracket',
};

export const RENDER_OVERRIDES: RenderOverrides = {
  maxTeamSize: {
    InputComponent: SelectInput,
    props: {
      label: 'Max Team Size',
      values: MAX_TEAM_SIZE_OPTIONS,
      IconComponent: GroupsRoundedIcon,
    },
  },
  minTeamSize: {
    InputComponent: SelectInput,
    props: {
      label: 'Min Team Size',
      values: MIN_TEAM_SIZE_OPTIONS,
      IconComponent: GroupsRoundedIcon,
    },
  },
  region: {
    InputComponent: SelectInput,
    props: {
      label: 'Region',
      values: REGION_OPTIONS,
      IconComponent: PublicIcon,
    },
  },
  bracketType: {
    InputComponent: SelectInput,
    props: {
      label: 'Bracket',
      values: BRACKET_TYPE,
      valueDisplayedMap: BRACKET_TYPE_TO_DISPLAY,
      IconComponent: AccountTreeIcon,
    },
  },
  tournamentDetails: {
    InputComponent: MarkdownAccordionInput,
    props: {
      fieldName: 'tournamentDetails',
    },
  },
  payouts: {
    InputComponent: TournamentPayoutsInput,
    props: {
      fieldName: 'payouts',
      sx: { fontWeight: 500, color: 'text.secondary' },
    },
  },
  sponsors: {
    InputComponent: SponsorsInput,
    props: {
      fieldName: 'sponsors',
    },
  },
  visibility: {
    InputComponent: SelectInput,
    props: {
      fieldName: 'visibility',
      values: EVENT_VISIBILITY,
      IconComponent: VisibilityIcon,
    },
  },
};
