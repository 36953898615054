import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { useMemo, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toRoomPath } from '../../../functions/src/util/liveKit/toRoomPath';
import { VoiceChatWidget } from './VoiceChatWidget';
import { VoiceChatProvider } from '../../contexts/voice-chat/VoiceChatContext';
import { useRoom } from '../../contexts/RoomContext';
import { useConnectedRoom } from '../../hooks/voice-chat/useConnectedRoom';

export type VoiceChatHubProps = {
  channelType?: string;
  channelId: string;
};

const VoiceChatHubUnmemoized = ({
  channelType,
  channelId,
}: VoiceChatHubProps) => {
  const { uid } = useAuth();
  const { initialize } = useRoom();
  const { channelGroupId } = useActiveChannelGroup();
  const [hoveredElement, setHoveredElement] = useState<string | null>(null);
  const { isConnectedRoom } = useConnectedRoom();

  const studioConnected = useMemo(() => {
    return isConnectedRoom('studio');
  }, [isConnectedRoom]);

  const arenaHovered = useMemo(() => {
    return hoveredElement === 'arena';
  }, [hoveredElement]);

  const studioHovered = useMemo(() => {
    return hoveredElement === 'studio';
  }, [hoveredElement]);

  const voiceChatStudio = useMemo(() => {
    // NOTE: channelType should change from general to public after guild page is merged
    if (!channelGroupId || channelType !== 'general') {
      return;
    }
    const mode = 'studio';
    const roomPathStudio = toRoomPath({
      segments: [channelGroupId, channelId, mode],
    });

    if (!roomPathStudio) {
      return;
    }

    initialize(roomPathStudio, mode);

    return (
      !!uid && (
        <Stack
          onMouseEnter={() => {
            setHoveredElement('studio');
          }}
          onMouseLeave={() => {
            setHoveredElement(null);
          }}
          sx={{
            flex: studioConnected ? '1 1 1' : '1 1 0',
            width: {
              xs: arenaHovered
                ? '0px'
                : studioHovered
                ? '300px'
                : studioConnected
                ? '250px'
                : 'fit-content',
              md: arenaHovered
                ? '0px'
                : studioHovered
                ? '444px'
                : studioConnected
                ? '322px'
                : 'fit-content',
            },
            opacity: arenaHovered ? 0 : 1,
            transition: 'all 300ms',
          }}
        >
          <VoiceChatProvider mode={mode}>
            <VoiceChatWidget />
          </VoiceChatProvider>
        </Stack>
      )
    );
  }, [
    arenaHovered,
    channelGroupId,
    channelId,
    channelType,
    initialize,
    studioConnected,
    studioHovered,
    uid,
  ]);

  const voiceChatArena = useMemo(() => {
    if (!channelGroupId) {
      return;
    }
    const mode = 'arena';
    const roomPathArena = toRoomPath({
      segments: [channelGroupId, channelId, mode],
    });

    if (!roomPathArena) {
      return;
    }

    initialize(roomPathArena, mode);

    return (
      <Stack
        onMouseEnter={() => {
          setHoveredElement('arena');
        }}
        onMouseLeave={() => {
          setHoveredElement(null);
        }}
        sx={{
          flex: studioConnected ? '1 1 0' : '1 1 1',
          width: {
            xs: studioHovered
              ? '0px'
              : arenaHovered
              ? '300px'
              : !!voiceChatStudio
              ? '250px'
              : 'fit-content',
            md: studioHovered
              ? '0px'
              : arenaHovered
              ? '444px'
              : !!voiceChatStudio
              ? '322px'
              : '444px',
          },
          opacity: studioHovered ? 0 : 1,
          transition: 'all 300ms',
        }}
      >
        <VoiceChatProvider mode={mode}>
          <VoiceChatWidget />
        </VoiceChatProvider>
      </Stack>
    );
  }, [
    arenaHovered,
    channelGroupId,
    channelId,
    initialize,
    studioConnected,
    studioHovered,
    voiceChatStudio,
  ]);

  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      spacing={!!hoveredElement ? 0 : 1} // Smaller spacing on mobile
      sx={{
        px: 4,
        pt: 3,
        height: '100%',
        width: { xs: '335px', md: '476px' }, // Smaller container width on mobile
        overflow: 'hidden',
      }}
    >
      {voiceChatArena}
      {voiceChatStudio}
    </Stack>
  );
};

export const VoiceChatHub = memo(VoiceChatHubUnmemoized);
