import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type OnlineContextProps = {
  isOnline: boolean;
};

const OnlineContext = createContext<OnlineContextProps | undefined>(undefined);

export type OnlineProviderProps = {
  children: ReactNode;
};

export const useOnline = () => {
  const context = useContext(OnlineContext);
  if (!context) {
    throw new Error('useOnline must be used within an OnlineProvider');
  }
  return context;
};

const OnlineProviderUnmemoized: FC<OnlineProviderProps> = ({ children }) => {
  const defaultOnline =
    typeof window === 'undefined' ? false : window.navigator.onLine;

  const [isOnline, setIsOnline] = useState(defaultOnline);

  useEffect(() => {
    const onOnline = () => {
      return setIsOnline(true);
    };
    const onOffline = () => {
      return setIsOnline(false);
    };

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  const onlineStatus = useMemo(() => {
    return { isOnline };
  }, [isOnline]);

  return (
    <OnlineContext.Provider value={onlineStatus}>
      {children}
    </OnlineContext.Provider>
  );
};

export const OnlineProvider = memo(OnlineProviderUnmemoized);
