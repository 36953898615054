import { connectWithCredential } from './connectWithCredential';

export const appAuthWithGoogle = async () => {
  const firebaseAuth = await import('firebase/auth');
  const { FirebaseAuthentication } = await import(
    '@capacitor-firebase/authentication'
  );
  const { GoogleAuthProvider } = firebaseAuth;
  const result = await FirebaseAuthentication.signInWithGoogle();
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  return connectWithCredential(credential);
};
