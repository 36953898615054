import { Timestamp } from '../../config/firebaseAdmin';
import { User, UserProviderInfo } from '../../types/firestore/User';
// eslint-disable-next-line no-restricted-imports
import type { UserInfo } from 'firebase/auth';
import { formatProviderData } from './formatProviderData';

export const connectedProvidersOf = <TTime = Timestamp>(
  userData: User<TTime>,
  userRecord: { providerData: (UserProviderInfo | UserInfo)[] },
): UserProviderInfo[] => {
  const {
    hidden: { customProviders = [] },
    addresses = [],
  } = userData;
  const addressesFormatted: UserProviderInfo[] = [...addresses].map(
    (address) => {
      return {
        email: '',
        uid: address,
        photoURL: '',
        displayName: '',
        providerId: 'wallet',
        phoneNumber: '',
      };
    },
  );

  const { providerData = [] } = userRecord;
  const providerDataFormatted = formatProviderData(providerData);
  return [...providerDataFormatted, ...addressesFormatted, ...customProviders];
};
