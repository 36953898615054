export const ELEVATIONS = {
  '0': 'linear-gradient(180deg, #000E21 0%, #001533 100%)',
  '1': 'rgba(0, 14, 33, 0.8)',
  '2': 'rgba(0, 14, 33, 0.8)',
  '3': 'rgba(0, 16, 38, 0.8)',
  '4': 'rgba(0, 19, 43, 0.8)',
  '5': 'rgba(0, 19, 43, 0.8)',
  '6': 'rgba(0, 22, 48, 0.8)',
  '7': 'rgba(0, 24, 54, 0.8)',
  '8': 'rgba(0, 27, 59, 0.8)',
  '9': 'rgba(1, 30, 64, 0.8)',
  '10': 'rgba(1, 34, 69, 0.8)',
  '11': 'rgba(2, 37, 74, 0.8)',
  '12': 'rgba(3, 41, 79, 0.8)',
  '13': 'rgba(4, 44, 84, 0.8)',
  '14': 'rgba(5, 49, 89, 0.8)',
  '15': 'rgba(6, 51, 92, 0.8)',
  '16': 'rgba(7, 47, 84, 0.8)',
  '17': 'rgba(9, 54, 97, 0.8)',
  '18': 'rgba(10, 56, 99, 0.8)',
  '19': 'rgba(10, 59, 102, 0.8)',
  '20': 'rgba(10, 61, 105, 0.8)',
  '21': 'rgba(11, 64, 107, 0.8)',
  '22': 'rgba(11, 65, 110, 0.8)',
  '23': 'rgba(11, 68, 112, 0.8)',
  '24': 'rgba(11, 70, 115, 0.8)',
  '25': 'rgba(11, 72, 117, 0.8)',
  '26': 'rgba(11, 74, 120, 0.8)',
  '27': 'rgba(11, 76, 122, 0.8)',
  '28': 'rgba(11, 78, 125, 0.8)',
  '29': 'rgba(11, 80, 127, 0.8)',
  '30': 'rgba(11, 82, 130, 0.8)',
};

export const ELEVATIONS_SOLID = {
  '0': 'linear-gradient(180deg, #000E21 0%, #001533 100%)',
  '1': 'rgba(0, 14, 33, 1)',
  '2': 'rgba(0, 14, 33, 1)',
  '3': 'rgba(0, 16, 38, 1)',
  '4': 'rgba(0, 19, 43, 1)',
  '5': 'rgba(0, 19, 43, 1)',
  '6': 'rgba(0, 22, 48, 1)',
  '7': 'rgba(0, 24, 54, 1)',
  '8': 'rgba(0, 27, 59, 1)',
  '9': 'rgba(1, 30, 64, 1)',
  '10': 'rgba(1, 34, 69, 1)',
  '11': 'rgba(2, 37, 74, 1)',
  '12': 'rgba(3, 41, 79, 1)',
  '13': 'rgba(4, 44, 84, 1)',
  '14': 'rgba(5, 49, 89, 1)',
  '15': 'rgba(6, 51, 92, 1)',
  '16': 'rgba(7, 47, 84, 1)',
  '17': 'rgba(9, 54, 97, 1)',
  '18': 'rgba(10, 56, 99, 1)',
  '19': 'rgba(10, 59, 102, 1)',
  '20': 'rgba(10, 61, 105, 1)',
  '21': 'rgba(11, 64, 107, 1)',
  '22': 'rgba(11, 65, 110, 1)',
  '23': 'rgba(11, 68, 112, 1)',
  '24': 'rgba(11, 70, 115, 1)',
  '25': 'rgba(11, 72, 117, 1)',
  '26': 'rgba(11, 74, 120, 1)',
  '27': 'rgba(11, 76, 122, 1)',
  '28': 'rgba(11, 78, 125, 1)',
  '29': 'rgba(11, 80, 127, 1)',
  '30': 'rgba(11, 82, 130, 1)',
};
