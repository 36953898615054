import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { TeammateProps } from '../../components/tournaments/registration/teammates/TeammatesTable';

export type GetTeammatesParams = {
  userId?: string;
  foundTeam?: Team<Date>;
};
export const getTeammates = ({
  userId,
  foundTeam,
}: GetTeammatesParams): TeammateProps[] | undefined => {
  if (!userId || !foundTeam) {
    return;
  }
  const { members } = foundTeam;
  return members.map((member) => {
    const { userId: memberUserId } = member;
    return {
      ...member,
      isCurrentUser: memberUserId === userId,
    };
  });
};
