import { memo } from '../../../util/memo';
import { PrizeGrid } from '../../organizer/prize-pools/PrizeGrid';
import { WithCancelIcon } from '../../organizer/prize-pools/prize-pool-page/WithCancelIcon';
import { Token } from '../../../../functions/src/types/firestore/User/Payout';
import { ThumbnailCardToken } from '../../cards/thumbnail-prize/ThumbnailCardToken';
import { Item } from '../../../../functions/src/types/firestore/Game/Collection/Item';

export type PayoutInputPrizeGridProps = {
  tokens: Token[];
  onRemoveToken: (tokenIndex: number) => void;
  onAddTokens?: () => void;
  countPlaceholders: number;
};

export const PayoutInputPrizeGrid = memo(
  function PayoutInputPrizeGridUnmemoized({
    tokens,
    onAddTokens,
    onRemoveToken,
    countPlaceholders,
  }: PayoutInputPrizeGridProps) {
    return (
      <PrizeGrid
        items={tokens.filter(Boolean)}
        renderCard={(prize: Token | Item<Date>, token: Token) => {
          if (!prize || !token) {
            return null;
          }
          return (
            <WithCancelIcon
              onRemove={async () => {
                return onRemoveToken(tokens.indexOf(token));
              }}
            >
              {<ThumbnailCardToken token={prize} />}
            </WithCancelIcon>
          );
        }}
        countPlaceholders={countPlaceholders}
        onPlaceholderClick={onAddTokens}
      />
    );
  },
);
