import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { QualificationConditions } from 'functions/src/types/firestore/Game/Tournament';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

type QualifiedProps = {
  rank: number;
  scoreValue?: number;
  resultsLength: number;
  qualification?: QualificationConditions;
};

export const Qualified = ({
  rank,
  scoreValue,
  resultsLength: results,
  qualification,
}: QualifiedProps) => {
  const hasScore = !!scoreValue;
  const determineQualified = () => {
    switch (qualification?.type) {
      case 'proportion':
        return results * qualification.proportion >= rank;
      case 'top':
        return rank <= qualification.numberOfPlaces;
      default:
        return false;
    }
  };
  const isQualified = determineQualified();
  return (
    <GradientIcon
      IconComponent={
        hasScore
          ? isQualified
            ? CheckCircleRoundedIcon
            : CancelRoundedIcon
          : HelpRoundedIcon
      }
      gradientColor={
        hasScore
          ? isQualified
            ? 'success.horizontal'
            : 'error.horizontal'
          : 'disabled.horizontal'
      }
      sx={{ width: 24, height: 24 }}
    />
  );
};
