import type { PlainSearchParameters } from 'algoliasearch-helper';
import { stripEmptyNullish } from '../../stripEmptyNullish';
import { sortedHash } from '../../hash/sortedHash';

const STATE_KEYS_TO_REMOVE = [
  'highlightPreTag',
  'highlightPostTag',
  'page',
] as const;

export const cleanAlgoliaState = (state: PlainSearchParameters) => {
  const stateNew = Object.entries(state).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof key !== 'string' || !STATE_KEYS_TO_REMOVE.includes(key as any)) {
      // eslint-disable-next-line security/detect-object-injection
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, unknown>) as PlainSearchParameters;
  return stripEmptyNullish(stateNew);
};

const HASH_PREFIX = 'algolia';

export const hashAlgoliaParams = ({
  page,
  query = '',
  ...restState
}: PlainSearchParameters = {}) => {
  const cleanedState = cleanAlgoliaState({
    ...restState,
    query,
  });
  return `${HASH_PREFIX}${sortedHash(cleanedState)}`;
};
