import { Timestamp } from 'firebase-admin/firestore';
import { Bracket } from '../../../types/firestore/Game/Tournament/Bracket';
import { BracketChunker } from './BracketChunker';

export const chunkAll = <T = Timestamp>(bracket: Bracket<T>) => {
  const bracketChunker = new BracketChunker<T>();
  const cohorts = bracket.bracket
    ? bracketChunker.chunkIntoCohorts(bracket.bracket)
    : undefined;

  const loserBracketChunker = new BracketChunker<T>(undefined, undefined, {
    bracketLoser: true,
    grandFinal: false,
  });
  const cohortsLoser = bracket.bracketLoser
    ? loserBracketChunker.chunkIntoCohorts(bracket.bracketLoser)
    : undefined;

  const grandFinalBracketChunker = new BracketChunker<T>(undefined, undefined, {
    bracketLoser: false,
    grandFinal: true,
  });
  const cohortsGrandFinal = bracket.grandFinal
    ? grandFinalBracketChunker.chunkIntoCohorts(bracket.grandFinal)
    : undefined;

  return {
    cohorts,
    cohortsLoser,
    cohortsGrandFinal,
  };
};
