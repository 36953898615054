import { createRef, useMemo, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { toKebabCase } from '../../../functions/src/util/Strings';
import { GameRegisterField } from '../../components/tournaments/registration/dialogs/GameRegisterField';
import { useValidateOption } from './useValidateOption';
import { customOptionsOf } from '../../../functions/src/util/tournaments/customOptionsOf';

export const useCustomRegistrationForm = () => {
  const { userData } = useAuth();
  const { gameTitle, registrationOptions: options } =
    useTournamentRegistration();

  const { validate, optionsValidity, isLoading, validateAsyncMap } =
    useValidateOption();

  const detailsPrefilled = useMemo(() => {
    const prefilledLatest = userData?.['latest'] || {};
    const prefilledGame = userData?.[`${toKebabCase(gameTitle)}`] || {};
    return { ...prefilledLatest, ...prefilledGame };
  }, [gameTitle, userData]);

  const optionsFiltered = useMemo(() => {
    return customOptionsOf(options);
  }, [options]);

  const refs = useRef(
    optionsFiltered.reduce((prev, curr) => {
      prev[curr.name] = createRef();
      return prev;
    }, {}),
  );

  // When we have prefill data, refresh the validation state of each field
  useEffect(() => {
    RegisterFields.filter((option) => {
      return !!option.props.prefillData;
    }).forEach((option) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      option.props.validator(option.props.prefillData!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSubmittable = optionsFiltered
    .filter((option) => {
      return !option.optional;
    })
    .every((option) => {
      return optionsValidity[option.id]?.valid;
    });

  const detailsInput = optionsFiltered.reduce((prev, curr) => {
    const inputRef = refs.current[curr.name];
    prev[curr.name] = inputRef.current?.value;
    return prev;
  }, {});

  const RegisterFields = useMemo(() => {
    return optionsFiltered
      .map((option) => {
        const { id, regex, regexExplained, name } = option;
        const fieldProps = {
          ...option,
          inputRef: refs.current[String(name)],
          prefillData: detailsPrefilled[String(id)],
          validator: (value: string) => {
            return validate(id, regex, value, regexExplained);
          },
          error: !!optionsValidity[String(id)]?.error,
          errorMsg: optionsValidity[String(id)]?.message,
          isLoading: id in validateAsyncMap ? isLoading[String(id)] : false,
        };
        return <GameRegisterField key={id} {...fieldProps} />;
      })
      .sort((a, b) => {
        return Number(a.props.optional) - Number(b.props.optional);
      });
  }, [
    optionsFiltered,
    detailsPrefilled,
    optionsValidity,
    validateAsyncMap,
    isLoading,
    validate,
  ]);

  return {
    detailsInput,
    isSubmittable,
    RegisterFields,
  };
};
