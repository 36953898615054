import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { AuthenticationOptions } from './AuthenticationOptions';
import { useAuthProviders } from '../../../hooks/auth/useAuthProviders';

export const AuthenticationStack = memo(
  function AuthenticationStackUnmemoized() {
    const { providerOptions } = useAuthProviders();
    const { top = [], generic = [], custom = [] } = providerOptions;
    const hasAdditionalOptions = generic.length > 0 || custom.length > 0;
    const bottomOptions = useMemo(() => {
      return [...generic, ...custom];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom.length, generic.length]);
    return (
      <Stack spacing={4} alignItems={'center'} justifyContent={'center'} py={2}>
        {hasAdditionalOptions && (
          <>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <AuthenticationOptions options={bottomOptions} />
            </Stack>
          </>
        )}
        <Typography
          color={'text.secondary'}
          textTransform={'uppercase'}
          textAlign={'center'}
        >
          Or
        </Typography>
        <AuthenticationOptions options={top} />
      </Stack>
    );
  },
);
