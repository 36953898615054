import { useCallback, useState } from 'react';
import { SignInGuard } from 'src/components/guards/SignInGuard';
import { useAuth } from 'src/contexts/AuthContext';
import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';

const GUARD_SIGN_IN_ID = 'GUARD_SIGN_IN' as const;

export function useGuardSignIn() {
  const { union, remove } = useGlobalComponentsContext();
  const { uid } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeSignInGuard = useCallback(() => {
    setIsOpen(false);
    remove(GUARD_SIGN_IN_ID);
  }, [remove]);

  const onCloseGuard = useCallback(
    (onClose?: () => void) => {
      onClose?.();
      closeSignInGuard();
    },
    [closeSignInGuard],
  );

  const openSignInGuard = useCallback(
    (title: string, onClose?: () => void) => {
      union(
        GUARD_SIGN_IN_ID,
        <SignInGuard
          title={title}
          open={true}
          onCloseAuth={() => {
            return onCloseGuard(onClose);
          }}
          onClose={() => {
            return onCloseGuard(onClose);
          }}
        />,
      );
      setIsOpen(true);
    },
    [union, onCloseGuard],
  );

  const guardSignIn = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    <T extends Function>(
      func: T,
      ...paramsOpenSignInGuard: Parameters<typeof openSignInGuard>
    ): T => {
      return ((...paramsFunc: unknown[]) => {
        if (isOpen) {
          return;
        }
        if (!uid) {
          openSignInGuard(...paramsOpenSignInGuard);
          return;
        }
        return func(paramsFunc);
      }) as unknown as T;
    },
    [uid, isOpen, openSignInGuard],
  );

  return {
    openSignInGuard,
    closeSignInGuard,
    guardSignIn,
    isOpen,
  };
}
