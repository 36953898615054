import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { ThumbnailCard } from './ThumbnailCard';
import { GradientIcon } from '../../gradients/GradientIcon';
import AddIcon from '@mui/icons-material/AddRounded';

export type ThumbnailCardPlaceholderProps = {
  asPlus?: boolean;
};

export const ThumbnailCardPlaceholder = memo(
  function ThumbnailCardPlaceholderUnmemoized({
    asPlus = false,
  }: ThumbnailCardPlaceholderProps) {
    return (
      <ThumbnailCard
        // TODO: dashed border for asPlus
        gradientColor={'primary.vertical'}
        sx={{
          borderRadius: '10px',
          // borderStyle: 'dashed',
          width: '128px',
          height: '128px',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height={'124px'}
          width={'124px'}
          borderRadius={'10px'}
        >
          {asPlus && (
            <GradientIcon
              gradientColor="primary.vertical"
              IconComponent={AddIcon}
              sx={{ width: 32, height: 32, cursor: 'pointer' }}
            />
          )}
        </Stack>
      </ThumbnailCard>
    );
  },
);
