import { memo } from '../../../util/memo';
import { Round } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { BracketBackground } from './BracketBackground';
import { Required } from 'utility-types';
import { ColumnLeft } from './column/ColumnLeft';
import { ColumnCenter } from './column/ColumnCenter';
import { ColumnRight } from './column/ColumnRight';
import { useRenderColumns } from './column/useRenderColumns';
import { useBracketColumns } from '../../../hooks/tournaments/useBracketColumns';

export type BracketProps = {
  rounds: Required<Round<Date>, 'matches'>[];
};

export function BracketUnmemoized({ rounds }: BracketProps) {
  const { MobileColumns, LeftColumns, CenterColumns, RightColumns } =
    useBracketColumns({
      rounds,
      useRenderColumns,
      columnComponents: {
        Left: ColumnLeft,
        Center: ColumnCenter,
        Right: ColumnRight,
      },
    });

  return (
    <BracketBackground roundsLength={rounds.length}>
      {MobileColumns}
      {LeftColumns}
      {CenterColumns}
      {RightColumns}
    </BracketBackground>
  );
}

export const Bracket = memo(BracketUnmemoized);
