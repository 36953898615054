import { FC, useRef } from 'react';
import { buildPreemptSearch } from '../../util/algolia/preemptSearch';
import { useSetPreemptState } from '../../contexts/algolia/PreemptStateContext';
import { memo } from '../../util/memo';
import {
  CachedInstantSearch,
  CachedInstantSearchProps,
} from './CachedInstantSearch';

export type PreemptedInstantSearchProps = Omit<
  CachedInstantSearchProps,
  'searchFunction' | 'searchClient'
>;

const WORKAROUND_MS = 5;

const PreemptedInstantSearchUnmemoized: FC<PreemptedInstantSearchProps> = ({
  indexName,
  ...rest
}) => {
  const setPreemptState = useSetPreemptState();
  const searchFunction = useRef(
    buildPreemptSearch((state) => {
      const { filters } = state;
      if (!filters) {
        return;
      }
      // Note: this is a necessary workaround to prevent setting the state
      // inside the render phase, which causes a warning.
      setTimeout(() => {
        setPreemptState(state);
      }, WORKAROUND_MS);
    }),
  );

  return (
    <CachedInstantSearch
      indexName={indexName}
      /* Ignore the deprecation warning. This is known to be deprecated. */
      searchFunction={searchFunction.current}
      {...rest}
    />
  );
};

export const PreemptedInstantSearch = memo(PreemptedInstantSearchUnmemoized);
