export const BASE_64_CHARS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

export const BITS_PER_CHAR = 6;

export function intToBase64(num: number, charsPerNum = 5) {
  const totalBits = charsPerNum * BITS_PER_CHAR;
  const paddedNum = num.toString(2).padStart(totalBits, '0');

  return Array.from({ length: charsPerNum }).reduce(
    (result: string, _, i: number) => {
      const index = parseInt(
        paddedNum.substr(i * BITS_PER_CHAR, BITS_PER_CHAR),
        2,
      );
      return result + BASE_64_CHARS[Number(index)];
    },
    '',
  );
}
