import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Chip from '@mui/material/Chip';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useFriendRequestDialog } from '../../../hooks/friends/useFriendRequestDialog';
import { useAuth } from '../../../contexts/AuthContext';
import { User } from '../../../../functions/src/types/firestore/User';
import { useRemoveFriendDialog } from '../../../hooks/friends/useRemoveFriendDialog';
import { useCancelFriendRequestDialog } from '../../../hooks/friends/useCancelFriendRequestDialog';
import { FriendListItemLayout } from './FriendListItemLayout';

export type FriendCardAddProps = Pick<
  User,
  'imgUrl' | 'username' | 'id' | 'friends'
> & {
  friendRequests?: {
    sent: string[];
    received: string[];
  };
};

const FriendCardAddUnmemoized: FC<FriendCardAddProps> = ({
  imgUrl,
  username,
  id,
  friends,
}) => {
  const theme = useTheme();

  const { userData } = useAuth();
  const { open: openAddFriendDialog } = useFriendRequestDialog({
    username,
    imgUrl,
    userId: id,
  });
  const { open: openRemoveFriendDialog } = useRemoveFriendDialog({
    username,
    imgUrl,
    userId: id,
  });
  const { open: openCancelFriendRequestDialog } = useCancelFriendRequestDialog({
    username,
    imgUrl,
    userId: id,
  });

  const { friends: myFriends, hidden: myHidden } = userData || {};
  const { friendRequests: myFriendRequests } = myHidden || {};

  const mutualFriendsCountEstimate = useMemo(() => {
    if (!myFriends || !friends) {
      return 0;
    }
    const mutualFriends = myFriends.filter((friendId) => {
      return friends.includes(friendId);
    });
    return mutualFriends.length;
  }, [friends, myFriends]);

  const friendshipButtonStatus = useMemo(() => {
    if (myFriends?.includes(id)) {
      return 'REMOVE';
    }
    if (myFriendRequests?.sent?.includes(id)) {
      return 'SENT';
    }
    return 'ADD';
  }, [id, myFriends, myFriendRequests?.sent]);

  const FriendshipStatus = useMemo(() => {
    return (
      <GradientTypography variant="subtitle1">
        {friendshipButtonStatus}
      </GradientTypography>
    );
  }, [friendshipButtonStatus]);

  const confirmAction = useCallback(() => {
    if (friendshipButtonStatus === 'ADD') {
      openAddFriendDialog();
    } else if (friendshipButtonStatus === 'REMOVE') {
      openRemoveFriendDialog();
    } else if (friendshipButtonStatus === 'SENT') {
      openCancelFriendRequestDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendshipButtonStatus]);

  return (
    <FriendListItemLayout
      {...{ imgUrl, username, mutualFriendsCountEstimate, userId: id }}
    >
      <Chip
        label={FriendshipStatus}
        onClick={confirmAction}
        sx={{
          backgroundColor: theme.palette.background.elevation[14],
          width: '100px',
          height: '42px',
          '.MuiChip-label': {
            p: 0,
          },
        }}
      />
    </FriendListItemLayout>
  );
};

export const FriendCardAdd = memo(FriendCardAddUnmemoized);
