import { Timestamp } from 'firebase-admin/firestore';
import {
  Round,
  MatchAggregated,
} from '../../types/firestore/Game/Tournament/Bracket';
import { pair } from '../pair';

export function pairSubsequentMatches<TTime = Timestamp>(
  rounds: Pick<Round<TTime>, 'matches'>[],
) {
  return rounds
    .filter((_, index) => {
      return index % 2 === 0 && rounds[index + 1];
    })
    .map(({ matches = [] }, index) => {
      const matchesNext = rounds[index * 2 + 1]?.matches || [];
      return { pairs: pair<MatchAggregated<TTime>>(matches, matchesNext) };
    })
    .filter(({ pairs }) => {
      return pairs.length > 0;
    });
}
