import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NoSearchResultsUnmemoized = () => {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
        No search results.
      </Typography>
    </Stack>
  );
};

export const NoSearchResults = memo(NoSearchResultsUnmemoized);
