import { BracketType } from '../../types/firestore/Game/Tournament';

const BASE_INDEX = 2;

export const prizeMultipleOf = (index: number, bracketType?: BracketType) => {
  if (!bracketType) {
    return 1;
  }
  if (bracketType === 'single-elimination') {
    return index < BASE_INDEX ? 1 : Math.pow(2, index - 1);
  }
  if (bracketType === 'double-elimination') {
    if (index < BASE_INDEX * 2) {
      return 1;
    }
    const step = Math.floor((index - 4) / 2);
    return Math.pow(2, step + 1);
  }
  return 1;
};
