import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { useCurrency } from '../../contexts/CurrencyContext';
import { PayoutSummary, PayoutSummaryProps } from './PayoutSummary';
import { PRIZES_DEFAULT } from '../../../functions/src/types/firestore/Game/Competition';
import { RequireAtLeastOne } from '../../../functions/src/types/utility-types';
import { BracketType } from '../../../functions/src/types/firestore/Game/Tournament';
import { Token } from '../../../functions/src/types/firestore/User/Payout';

export type FormattedPayoutSummaryProps = RequireAtLeastOne<{
  payoutSingle: Token[];
  payoutsTotal: { tokens: Token[] }[];
  bracketType?: BracketType;
}> &
  Omit<PayoutSummaryProps, 'amountFiat' | 'countIlliquid' | 'countUnassured'>;

export const FormattedPayoutSummary = memo(
  function FormattedPayoutSummaryUnmemoized(
    props: FormattedPayoutSummaryProps,
  ) {
    const { payoutSingle, payoutsTotal, bracketType } = props;
    const { formatPayout, formatTotalPayout } = useCurrency();
    const payoutFormatted = useMemo(() => {
      return payoutSingle
        ? formatPayout(payoutSingle)
        : payoutsTotal
        ? formatTotalPayout(payoutsTotal, bracketType)
        : PRIZES_DEFAULT;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payoutSingle, payoutsTotal]);

    return <PayoutSummary {...payoutFormatted} {...props} />;
  },
);
