import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { generatePlacementsFrom } from '../../../util/tournaments/generatePlacementsFrom';
import { SelectInputBasic } from '../../data-entry-ui/generic/SelectInputBasic';
import { useMemo } from 'react';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { useScoring } from '../../../contexts/ScoringContext';

export const PlacementAction = memo(function PlacementActionUnmemoized() {
  const { numberOfTeams = 0, selectPlacement } = useScoring();

  const placements = useMemo(() => {
    return generatePlacementsFrom(numberOfTeams);
  }, [numberOfTeams]);

  return (
    <Stack gap={3} direction={'row'} alignItems={'center'}>
      <TournamentPanelV3
        title={<Typography variant={'h6'}>Placement:</Typography>}
        Content={
          <SelectInputBasic
            onChange={selectPlacement}
            values={generatePlacementsFrom(numberOfTeams)}
            defaultValue={placements[0]}
          />
        }
      />
    </Stack>
  );
});
