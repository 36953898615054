import { useCallback } from 'react';
import { useDialog } from '../useDialog';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';
import { UserProviderInfo } from '../../../functions/src/types/firestore/User';
import { unlinkProvider } from '../../util/auth/unlinkProvider';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { toTitleCase } from '../../../functions/src/util/Strings';

export const UNLINK_DIALOG_ID = 'UNLINK_DIALOG';

export type UnlinkProviderDialogParams = Omit<UserProviderInfo, 'photoURL'>;

export const useUnlinkDialog = () => {
  const { open: openUnlinkDialog, close } = useDialog(UNLINK_DIALOG_ID);

  const unlink = useCallback(
    async (providerId: string, providerUid: string) => {
      const { auth } = await import('../../config/firebase-client/auth');
      try {
        await unlinkProvider({
          providerId,
          providerUid,
        });
        close();
      } catch (e) {
        await auth.currentUser?.reload();
        close();
      }
    },
    [close],
  );
  const open = useCallback(
    ({
      email,
      phoneNumber,
      displayName,
      providerId,
      uid: providerUid,
    }: UnlinkProviderDialogParams) => {
      const isFirebaseProvider = isFirebaseOAuthProvider(providerId);
      const isPhoneProvider =
        providerId.toLocaleLowerCase().includes('phone') && !!phoneNumber;
      const label = isFirebaseProvider
        ? email
        : isPhoneProvider
        ? phoneNumber
        : displayName || email || providerUid;
      openUnlinkDialog({
        title: `Unlink ${label}?`,
        description: `Are you sure you would like to remove your ${toTitleCase(
          extractProviderId(providerId),
        )}, ${label}, from your account?`,
        children: (
          <LoadingButton
            onClick={() => {
              return unlink(providerId, providerUid);
            }}
            variant="contained"
          >
            unlink
          </LoadingButton>
        ),
      });
    },

    [openUnlinkDialog, unlink],
  );
  return { open, close };
};
