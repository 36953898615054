import { useCallback, useContext } from 'react';
import { useScoring } from '../../contexts/ScoringContext';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { useWizard } from '../../components/wizard/Wizard';
import { useErrorAlert } from '../useErrorAlert';
import { SELF_MODERATION_ERROR_LIBRARY } from '../../util/tournaments/selfModerationErrorLibrary';
import { useActiveMatchRouter } from './useActiveMatchRouter';
import { useActiveSessionStatus } from './useActiveSessionStatus';
import { useMatch } from '../../components/tournaments/match/MatchProvider';

export const useScoreSession = () => {
  const { id } = useMatch();
  const { outcome, teamToScore } = useScoring();
  const { sessionIndex } = useActiveMatchRouter();
  const { id: tournamentId, gameId } = useContext(TournamentContext);
  const { catchError } = useErrorAlert(SELF_MODERATION_ERROR_LIBRARY);
  const { go } = useWizard();
  const { hasTeamUploadedScreenshot } = useActiveSessionStatus();

  const goScreenshotUpload = useCallback(() => {
    go(!hasTeamUploadedScreenshot ? 'screenshot' : 'successfullyScored');
  }, [go, hasTeamUploadedScreenshot]);

  const scoreSessionUnsafe = useCallback(
    async (onSubmit?: () => void) => {
      if (!outcome || !teamToScore) {
        return;
      }

      const { scoreSession: score } = await import(
        '../../firebaseCloud/tournament/moderation/scoreSession'
      );
      await score({
        gameId,
        matchId: id,
        tournamentId,
        claim: outcome,
        teamId: teamToScore.id,
        sessionIndex: sessionIndex,
      });
      onSubmit?.();
      goScreenshotUpload();
    },
    [
      gameId,
      goScreenshotUpload,
      id,
      outcome,
      sessionIndex,
      teamToScore,
      tournamentId,
    ],
  );

  const scoreSession = useCallback(
    async (onSubmit?: () => void) => {
      await catchError(
        () => {
          return scoreSessionUnsafe(onSubmit);
        },
        (e) => {
          console.error(e);
        },
      );
    },
    [catchError, scoreSessionUnsafe],
  );

  return { scoreSession };
};
