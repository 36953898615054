import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Button, { ButtonProps } from '@mui/material/Button';
import { GradientIcon } from './GradientIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Overwrite } from 'utility-types';
import { capitalizeFirstLetter } from '../../util/string';
import { Variant } from '@mui/material/styles/createTypography';
import { forwardRef, useMemo, useState } from 'react';
import { GradientCircularProgress } from './GradientCircularProgress';
import { GradientTypography } from './GradientTypography';
import { SxProps } from '@mui/material/styles';

type AsyncProps = {
  asLoadingButton: boolean;
  loadingPosition: 'start' | 'end';
};

export type GradientButtonProps = Overwrite<
  ButtonProps,
  {
    startIcon?: OverridableComponent<SvgIconTypeMap<'any object', 'svg'>>;
    endIcon?: OverridableComponent<SvgIconTypeMap<'any object', 'svg'>>;
  }
> & { asyncProps?: AsyncProps; iconSx?: SxProps };

export const GradientButton = forwardRef<
  HTMLButtonElement,
  GradientButtonProps
>(function GradientButton(props, ref) {
  const gradientColorHorizontal = `${props.color || 'primary'}.horizontal`;
  const gradientColorVertical = `${props.color || 'primary'}.vertical`;
  const variantTypography = (
    props.size ? `button${capitalizeFirstLetter(props.size)}` : 'buttonMedium'
  ) as Variant;
  const [loading, setLoading] = useState(false);
  const asyncHandler = async (e) => {
    if (loading) {
      return;
    }
    setLoading(true);
    await Promise.resolve(props?.onClick?.(e));
    setLoading(false);
  };
  const startIcon = useMemo(() => {
    if (!props.startIcon) {
      return null;
    }
    return loading && props?.asyncProps?.loadingPosition === 'start' ? (
      <GradientCircularProgress
        sx={{ ml: 4 }}
        gradientColor={gradientColorHorizontal}
        size="1.5rem"
      />
    ) : (
      <GradientIcon
        sx={{ ml: 4, ...props.iconSx }}
        gradientColor={gradientColorHorizontal}
        IconComponent={props.startIcon}
      />
    );
  }, [
    props?.startIcon,
    loading,
    props?.asyncProps?.loadingPosition,
    gradientColorHorizontal,
  ]);

  const endIcon = useMemo(() => {
    if (!props.endIcon) {
      return null;
    }
    return loading && props?.asyncProps?.loadingPosition === 'end' ? (
      <GradientCircularProgress
        sx={{ mr: 4 }}
        gradientColor={gradientColorHorizontal || 'primary.vertical'}
        size="1.5rem"
      />
    ) : (
      <GradientIcon
        sx={{ mr: 4, ...props.iconSx }}
        gradientColor={gradientColorHorizontal}
        IconComponent={props.endIcon}
      />
    );
  }, [
    props?.endIcon,
    loading,
    props?.asyncProps?.loadingPosition,
    gradientColorHorizontal,
  ]);

  const plTypography = props.startIcon ? 0 : 4;
  const prTypography = props.endIcon ? 0 : 4;

  return (
    <Button
      variant="outlined"
      {...props}
      onClick={
        props?.asyncProps?.asLoadingButton ? asyncHandler : props?.onClick
      }
      color={props.color}
      ref={ref}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        ...props.sx,
        ...(props.disabled ? { boxShadow: 'none' } : {}),
      }}
    >
      <GradientTypography
        gradientColor={gradientColorVertical}
        variant={variantTypography}
        sx={{
          py: 1.5,
          pl: plTypography,
          pr: prTypography,
        }}
      >
        {props.children}
      </GradientTypography>
    </Button>
  );
});
