export function swap<
  K extends string | number | symbol,
  V extends string | number | symbol,
>(flatObj: Record<K, V>) {
  const swappedObj = {} as Record<V, K>;
  (Object.entries(flatObj) as [K, V][]).forEach(([key, val]) => {
    swappedObj[val] = key;
  });
  return swappedObj;
}
