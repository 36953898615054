import { useCallback } from 'react';
import { useAlertDialog, OpenAlertDialogSettings } from '../useAlertDialog';

const SIGNATURE_REJECTED_DIALOG = 'SIGNATURE_REJECTED_DIALOG' as const;

export const useSignatureRejectedDialog = () => {
  const { open: openDialog, close } = useAlertDialog(SIGNATURE_REJECTED_DIALOG);

  const open = useCallback(
    (settings: Omit<OpenAlertDialogSettings, 'title' | 'description'> = {}) => {
      openDialog({
        title: 'Signature Request Rejected.',
        description:
          'You have rejected the signature request sent to your wallet. You must accept the signature request in order to proceed.',
        ...settings,
      });
    },
    [openDialog],
  );

  return {
    open,
    close,
  };
};
