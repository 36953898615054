import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export type DialogBodyStandardProps = {
  title?: string;
  description?: string | ReactNode;
  children?: ReactNode | ReactNode[];
  gradientColor?: string;
};

export const DialogBodyStandard = memo(function DialogBodyStandardUnmemoized({
  title,
  description,
  children,
  gradientColor,
}: DialogBodyStandardProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: !!title || !!description ? 4 : undefined,
        width: '100% !important',
      }}
    >
      <Stack spacing={{ xs: 2, md: 4 }} sx={{ alignItems: 'center' }}>
        {title && (
          <GradientTypography
            variant="h5"
            gradientColor={!!gradientColor ? gradientColor : 'primary.vertical'}
            sx={{
              fontWeight: '600',
              textTransform: !!description ? 'uppercase' : 'capitalize',
            }}
          >
            {title}
          </GradientTypography>
        )}
        {typeof description === 'string' ? (
          <Typography variant="body1" textAlign="center" textOverflow="wrap">
            {description}
          </Typography>
        ) : (
          description
        )}
      </Stack>
      {children}
    </Box>
  );
});
