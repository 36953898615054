import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PhoneNumberInput } from 'src/components/authentication/PhoneNumberInput';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { useAuthSubmit } from '../../../contexts/AuthSubmitContext';
import { useSendConfirmationCode } from '../../../hooks/auth/useSendConfirmationCode';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';
import { StoredPhoneNumberData } from '../../../hooks/auth/useAuthFlowBase';
import { useKeypressListener } from '../../../hooks/useKeypressListener';
import Box from '@mui/material/Box';

export type AuthenticationEnterPhonePageUserData = {
  country: string;
  number: string;
};

export type StoredPhoneNumberDataWithValidity = StoredPhoneNumberData & {
  validFormat: boolean;
};

export const AuthenticationEnterPhoneDialogUnmemoized = () => {
  const theme = useTheme();
  const { hasUserTyped, errorMessage, isValidated, isLoading } =
    useAuthSubmit();
  const { sendConfirmationCode } = useSendConfirmationCode({
    firstCode: true,
  });

  const attemptSubmit = useCallback(async () => {
    await sendConfirmationCode();
  }, [sendConfirmationCode]);

  useKeypressListener(['Enter', 'NumpadEnter'], attemptSubmit);

  const loadingButton = useMemo(() => {
    return (
      <LoadingButton
        variant="contained"
        id="recaptcha"
        size="large"
        type="submit"
        disabled={
          !isValidated.phoneNumber || !hasUserTyped.phoneNumber || isLoading
        }
        onClick={attemptSubmit}
        sx={{ width: '100%' }}
      >
        Send Code
      </LoadingButton>
    );
  }, [
    attemptSubmit,
    hasUserTyped.phoneNumber,
    isValidated.phoneNumber,
    isLoading,
  ]);

  return (
    <Stack sx={{ width: '100%' }}>
      <DialogAuthenticationNavBar />
      <DialogBodyStandard
        title={'Enter Phone Number'}
        description={
          <Stack spacing={4} alignItems="center">
            <Typography variant="body1" color={'text.secondary'}>
              Please enter your phone number in full. Remember to key in your
              country code so that your country&apos;s flag appears. Do NOT omit
              your area code.
            </Typography>
            <Box>
              <PhoneNumberInput />
            </Box>
          </Stack>
        }
      >
        <Stack spacing={4} alignItems="center">
          {!!errorMessage.phoneNumber && (
            <Typography
              variant="body2"
              color={theme.palette.error.main}
              textAlign={'center'}
              mb={2}
            >
              {errorMessage.phoneNumber}
            </Typography>
          )}
          {loadingButton}
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEnterPhoneDialog = memo(
  AuthenticationEnterPhoneDialogUnmemoized,
);
