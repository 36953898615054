import { useActiveSessionStatus } from '../../hooks/tournaments/useActiveSessionStatus';
import { memo } from '../../util/memo';
import { outcomeOf } from '../../util/tournaments/outcomeOf';
import { GradientTypography } from '../gradients/GradientTypography';
import { Status } from '../Status';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';

export type CompetitorOutcomeProps = {
  status: MatchAggregated['status'];
  outcome?: Outcome;
  score?: number;
};

export const CompetitorOutcome = memo(function CompetitorOutcomeUnmemoized(
  props: CompetitorOutcomeProps,
) {
  const { outcome, score, status } = props;
  const { isActiveSessionScoredByTeam, isActiveSessionCompleted } =
    useActiveSessionStatus();

  const isScoringActive =
    !isActiveSessionCompleted && !isActiveSessionScoredByTeam;

  const { outcomeFormatted } = outcomeOf(outcome, score, isScoringActive);

  if (outcomeFormatted !== undefined) {
    return (
      <GradientTypography
        variant="h6"
        gradientColor={
          outcome === 'win' || outcome === 'bypass'
            ? 'secondary.horizontal'
            : outcome === 'loss'
            ? 'error.horizontal'
            : 'warning.main'
        }
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {outcomeFormatted}
      </GradientTypography>
    );
  }

  return (
    <Status
      kind="Icon"
      statusEnum={status === 'active' ? 'online' : 'away'}
      sx={{ width: 16, height: 16 }}
    />
  );
});
