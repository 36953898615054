import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import {
  SearchDropdown,
  SearchDropdownProps,
} from '../catalogs/SearchDropdown';
import { User } from '../../../../functions/src/types/firestore/User';
import { AddTeammateListItem } from '../../cards/tournament/AddTeammateListItem';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { renderHits } from '../../../util/algolia/renderHits';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';

export type UserDropdownSearchProps = Omit<SearchDropdownProps, 'Content'> & {
  hits: OrNode<Hit<User<Date>>>[];
};

const UserDropdownSearchUnmemoized = ({
  hits,
  ...props
}: UserDropdownSearchProps) => {
  const Users = useMemo(() => {
    return renderHits(hits, (hit) => {
      return <AddTeammateListItem {...hit} />;
    });
  }, [hits]);

  return (
    <SearchDropdown
      Content={Users}
      placeholder={'Search for a teammate...'}
      {...props}
    />
  );
};

export const UserDropdownSearch = memo(UserDropdownSearchUnmemoized);

export const useUserDropdownSearchAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: UserDropdownSearch,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
    });
  }, [containerId]);
};
