import { OAuthCredential } from 'firebase/auth';

export const connectWithCredential = async (credential: OAuthCredential) => {
  const { auth } = await import('../../config/firebase-client/auth');
  const firebaseAuth = await import('firebase/auth');
  const { currentUser } = auth;
  const { signInWithCredential, linkWithCredential } = firebaseAuth;
  return currentUser && !currentUser.isAnonymous
    ? linkWithCredential(currentUser, credential)
    : signInWithCredential(auth, credential);
};
