import { FC, useCallback, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import Typography from '@mui/material/Typography';
import { useMatch } from '../MatchProvider';
import { Member } from '../../../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { ChipUser } from '../../../ChipUser';
import { UserContentOverlay } from '../../../UserContentOverlay';
import { useHover } from '../../../../contexts/HoverContext';

export type UploadedByProps = { uploaderUid: string };

const UploadedByUnmemoized: FC<UploadedByProps> = ({ uploaderUid }) => {
  const { team1, team2 } = useMatch();
  const { isHovered } = useHover();
  const findUploader = useCallback(
    (members: Member[] | undefined) => {
      return members?.find(({ userId }) => {
        return userId === uploaderUid;
      });
    },
    [uploaderUid],
  );

  const uploader = useMemo(() => {
    return findUploader(team1?.members) || findUploader(team2?.members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team1?.members, team2?.members]);

  return (
    <>
      {isHovered && (
        <UserContentOverlay direction="row" alignItems="center" sx={{ p: 2 }}>
          <Typography>
            UPLOADED BY:{' '}
            <ChipUser
              username={uploader?.username || 'Admin'}
              avatarUrl={
                uploader?.imgUrl ||
                '/assets/images/mascots/clipped/bunny-content.png'
              }
            />
          </Typography>
        </UserContentOverlay>
      )}
    </>
  );
};
//TODO: we should display username and imgUrls of the roles and admins that uplaod the photo
export const UploadedBy = memo(UploadedByUnmemoized);
