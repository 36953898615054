export const generatePlacementsFrom = (numberOfTeams: number) => {
  if (numberOfTeams < 1) {
    return ['No teams'];
  }
  const placements: string[] = [];
  for (let i = 1; i <= numberOfTeams; i++) {
    placements.push(i.toString());
  }
  return placements;
};
