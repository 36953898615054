export function isHorizontallyScrollable(element: HTMLElement) {
  return element.scrollWidth > element.clientWidth;
}

export function firstScrollableParent(element: HTMLElement | null) {
  while (element) {
    if (isHorizontallyScrollable(element)) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
}

export function scrollToHorizontally(
  element: HTMLElement,
  elementParent: HTMLElement,
) {
  const elementLeft = element.getBoundingClientRect().left;
  const parentLeft = elementParent.getBoundingClientRect().left;
  const scrollLeftOffset = elementLeft - parentLeft + elementParent.scrollLeft;

  elementParent.scrollTo({
    left: scrollLeftOffset,
    behavior: 'smooth',
  });
}
