import CancelIcon from '@mui/icons-material/CancelRounded';
import IconButton from '@mui/material/IconButton';
import { GradientIcon } from '../gradients/GradientIcon';
import { memo } from '../../util/memo';

export type DialogCloseButtonProps = {
  onClose: () => void;
};
export const DialogCloseButton = memo(function DialogCloseButtonUnmemoized({
  onClose,
}: DialogCloseButtonProps) {
  return (
    <IconButton onClick={onClose} edge="end">
      <GradientIcon
        IconComponent={CancelIcon}
        gradientColor={'primary.horizontal'}
      />
    </IconButton>
  );
});
