import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { useIndexingHits } from '../../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { FriendListItemLayout } from './FriendListItemLayout';
import { ResponseButtons } from '../../ResponseButtons';
import { acceptShipRequest } from '../../../util/ship/acceptShipRequest';
import { rejectShipRequest } from '../../../util/ship/rejectShipRequest';

export type FriendRequestCardProps = FriendHit;

const FriendCardRequestUnmemoized = (hit: FriendRequestCardProps) => {
  const { uid } = useAuth();
  const { remove, add } = useIndexingHits();
  const { objectID, sender, receiver, mutualFriendsCountEstimate = 0 } = hit;
  const friendToRender = sender.id === uid ? receiver : sender;
  const { username, imgUrl, id, friendRequest } = friendToRender;
  const message = friendRequest?.message;

  const acceptRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    await acceptShipRequest({
      userId: uid,
      groupId: id,
      groupType: 'Friendship',
    });
    const friendLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid),
    );
    const friendRequestLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid),
    );
    remove(friendRequestLayout, objectID);
    add(friendLayout, hit as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit, objectID, uid, id]);

  const denyRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    await rejectShipRequest({
      userId: uid,
      groupId: id,
      groupType: 'Friendship',
    });
    const friendRequestLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid),
    );
    remove(friendRequestLayout, objectID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectID, uid, id]);

  return (
    <FriendListItemLayout
      {...{ username, imgUrl, userId: id, message, mutualFriendsCountEstimate }}
    >
      <ResponseButtons onAccept={acceptRequest} onReject={denyRequest} />
    </FriendListItemLayout>
  );
};

export const FriendCardRequest = memo(FriendCardRequestUnmemoized);
