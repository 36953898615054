import {
  Announcement,
  Timed,
} from 'functions/src/types/firestore/Game/Announcement';
import { DateTimeFormatterUtc } from './DateTimeFormatterUtc';
import { NUMERIC_TIMEZONE } from './presets/times';
import { NUMERIC_NOYEAR } from './presets/dates';

export const DEFAULT_SEPARATOR = ' ';
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD HH:mm:00';
export const DATE_NOW = new Date(Date.now());

export type DateDifference = {
  date1LaterThanDate2: boolean;
  datesAreSame: boolean;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
  months: number;
  years: number;
};

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export type UnitDifferences = {
  [key in Intl.RelativeTimeFormatUnit]: number;
};
export const YEAR_IN_MS = 365.25 * 24 * 60 * 60 * 1000;
export const MONTH_IN_MS = (365.25 / 12) * 24 * 60 * 60 * 1000;
export const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const HOUR_IN_MS = 60 * 60 * 1000;
export const MINUTE_IN_MS = 60 * 1000;
export const SECOND_IN_MS = 1000;
export const ONE_HOUR_MINS = 60;
export const ONE_HOUR = 1;

export function getTimeBetweenDates(date1: Date, date2: Date): DateDifference {
  const timeUntilDate = Math.abs(Number(date1) - Number(date2));
  const date1LaterThanDate2 = Number(date1) - Number(date2) > 0;
  // eslint-disable-next-line no-unused-vars, prefer-const
  let differenceRemainingMs = timeUntilDate;

  const yearsPassed = Math.floor(differenceRemainingMs / YEAR_IN_MS);
  differenceRemainingMs -= yearsPassed * YEAR_IN_MS;
  const monthsPassed = Math.floor(differenceRemainingMs / MONTH_IN_MS);
  differenceRemainingMs -= monthsPassed * MONTH_IN_MS;
  const weeksPassed = Math.floor(differenceRemainingMs / WEEK_IN_MS);
  differenceRemainingMs -= weeksPassed * WEEK_IN_MS;
  const daysPassed = Math.floor(differenceRemainingMs / DAY_IN_MS);
  differenceRemainingMs -= daysPassed * DAY_IN_MS;
  const hoursPassed = Math.floor(differenceRemainingMs / HOUR_IN_MS);
  differenceRemainingMs -= hoursPassed * HOUR_IN_MS;
  const minutesPassed = Math.floor(differenceRemainingMs / MINUTE_IN_MS);
  differenceRemainingMs -= minutesPassed * MINUTE_IN_MS;
  const secondsPassed = Math.floor(differenceRemainingMs / SECOND_IN_MS);
  differenceRemainingMs -= secondsPassed * SECOND_IN_MS;
  const datesAreSame = timeUntilDate === 0;

  return {
    date1LaterThanDate2: date1LaterThanDate2,
    datesAreSame: datesAreSame,
    seconds: secondsPassed,
    minutes: minutesPassed,
    hours: hoursPassed,
    days: daysPassed,
    weeks: weeksPassed,
    months: monthsPassed,
    years: yearsPassed,
  };
}

function difference(relativeTo: Date, date: Date): UnitDifferences {
  const dateDifference = getTimeBetweenDates(relativeTo, date);
  const coefficient = dateDifference.date1LaterThanDate2 ? -1 : 1; // 1 if date in the past
  return {
    second: (coefficient * dateDifference.seconds) % 60,
    minute: (coefficient * dateDifference.minutes) % 60,
    hour: (coefficient * dateDifference.hours) % 24,
    day: (coefficient * dateDifference.days) % 7,
    week: (coefficient * dateDifference.weeks) % 4,
    month: coefficient * dateDifference.months,
    year: coefficient * dateDifference.years,
  };
}

const UNITS_LARGE_TO_SMALL: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export function formatRelative(
  date: Date,
  relativeTo: Date = new Date(),
  unitsCount = 1,
  options?: Intl.RelativeTimeFormatOptions,
): string {
  const differences = difference(relativeTo, date);
  const unitsFiltered = UNITS_LARGE_TO_SMALL.filter((unit) => {
    return differences[`${unit}`] !== 0;
  });
  if (unitsFiltered.length === 0) {
    return 'just now';
  }
  const units = unitsFiltered.slice(
    0,
    Math.min(unitsCount, unitsFiltered.length),
  );

  const rtf = new Intl.RelativeTimeFormat('en', options);
  return units
    .map((unit, i) => {
      let formatted = rtf.format(differences[`${unit}`], unit);
      if (i > 0) {
        formatted = formatted.replace('in ', '');
      }
      if (i < units.length - 1) {
        formatted = formatted.replace(' ago', '');
      }
      return formatted;
    })
    .join(' ');
}

export function getDateLineContent({
  date,
  endDate,
  checkInDate,
  type,
}: Timed<Date> & { type: Announcement['type'] }) {
  const isBeforeCheckIn = checkInDate && Date.now() < checkInDate.getTime(); // TODO: useServerTime
  const isAfterEndDate = endDate && Date.now() > endDate.getTime(); // TODO: useServerTime

  const dateChosen = isBeforeCheckIn
    ? checkInDate
    : isAfterEndDate
    ? endDate
    : date;

  const formatted = formatRelative(dateChosen, undefined, 2);
  const isPast = formatted.includes('ago');

  if (type === 'asset' || type === 'patch') {
    return `Release${isPast ? 'd' : 's'} ${formatted}`;
  }

  if (isBeforeCheckIn) {
    return `Check-in starts ${formatted}`;
  }
  if (isAfterEndDate) {
    return `Ended ${formatted}`;
  }
  return `Start${isPast ? 'ed' : 's'} ${formatted}`;
}

export type FormatDateTimeOptions = {
  dateFormat?: Intl.DateTimeFormatOptions | false;
  timeFormat?: Intl.DateTimeFormatOptions | false;
  separator?: string;
};

export function formatDateTime(
  date: Date,
  options: FormatDateTimeOptions = {},
  dateOverrides?: string[],
): string {
  options = {
    dateFormat: NUMERIC_NOYEAR,
    timeFormat: NUMERIC_TIMEZONE,
    separator: DEFAULT_SEPARATOR,
    ...options,
  };
  const { timeFormat, dateFormat, separator } = options;

  const time = timeFormat
    ? new DateTimeFormatterUtc(timeFormat).format(date)
    : '';

  const timeFormatted =
    timeFormat !== false
      ? separator === DEFAULT_SEPARATOR && dateFormat !== false
        ? `${separator}(${time})`
        : `${separator}${time}`
      : '';

  const dateFormatted =
    dateFormat !== false
      ? new DateTimeFormatterUtc(dateFormat).format(date)
      : '';

  return `${
    !!dateOverrides?.length
      ? replaceDay(date, dateFormatted, dateOverrides)
      : dateFormatted
  }${timeFormatted}`;
}

// Expand when needed (e.g. 'Last Week': -7)
export const DATE_OFFSETS = {
  Yesterday: -1,
  Today: 0,
  Tomorrow: 1,
};

export function replaceDay(
  date: Date,
  dateFormatted: string,
  dateOverrides: string[] = [],
) {
  const dateCurrent = new Date();
  dateCurrent.setHours(0, 0, 0, 0);
  const offset = Math.floor(
    (date.getTime() - dateCurrent.getTime()) / DAY_IN_MS,
  );
  const replacement = dateOverrides.find((override) => {
    return DATE_OFFSETS[String(override)] === offset;
  });
  return replacement
    ? dateFormatted.replace(DAY_NAMES[date.getDay()], replacement)
    : dateFormatted;
}

export type FormatDateRangeOptions = {
  dateFormat?: Intl.DateTimeFormatOptions;
  timeFormat?: Intl.DateTimeFormatOptions;
  separator?: string;
};

export function formatDateRange(
  date1: Date,
  date2: Date,
  optionsDefault: FormatDateRangeOptions = {},
) {
  const options = {
    dateFormat: NUMERIC_NOYEAR,
    timeFormat: NUMERIC_TIMEZONE,
    separator: DEFAULT_SEPARATOR,
    ...optionsDefault,
  };
  const { timeFormat, dateFormat, separator } = options;
  if (date1.getDate() === date2.getDate()) {
    const date1Time = new DateTimeFormatterUtc({
      ...timeFormat,
      timeZoneName: undefined,
    }).format(date1, true);
    const date2Time = new DateTimeFormatterUtc(timeFormat).format(date2);
    const timeFormatted =
      separator === DEFAULT_SEPARATOR
        ? `${separator}(${date1Time} - ${date2Time})`
        : `${separator}${date1Time} - ${date2Time}`;
    const dateFormatted = new DateTimeFormatterUtc(dateFormat).format(
      date1,
      true,
    );
    return `${dateFormatted}${timeFormatted}`;
  }

  return `${formatDateTime(date1, options)} - ${formatDateTime(
    date2,
    options,
  )}`;
}

export const dateMonthYearTime = (registrationTime: Date): string => {
  const date = new Date(registrationTime);

  const day = date.getDate().toString().padStart(2, '0');
  const month = date
    .toLocaleString('default', { month: 'short' })
    .toUpperCase();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours || 12;

  return `${day}TH ${month} ${year}, ${hours}:${minutes}${period}`;
};
