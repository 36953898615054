import { FC, ChangeEvent } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GradientRadio } from 'src/components/gradients/GradientRadio';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

export type GradientRadioGroupProps = {
  items: {
    key: string;
    label: JSX.Element;
    contentOnSelect?: JSX.Element;
    disabled?: boolean;
  }[];
  selectedIndex: number | null;
  onValueChange: (index: number) => void;
  gradientColor?: string;
};

export const GradientRadioGroup: FC<GradientRadioGroupProps> = memo(
  function GradientRadioGroupUnmemoized({
    items,
    selectedIndex,
    onValueChange,
    gradientColor = 'primary.verticalInverse',
  }) {
    const theme = useTheme();
    return (
      <RadioGroup
        value={selectedIndex}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onValueChange(parseInt(e.target.value));
        }}
        sx={{ width: '100%' }}
      >
        <Stack gap={4}>
          {items.map((item, index) => {
            const isDisabled = item.disabled;
            return (
              <>
                <FormControlLabel
                  disabled={!!isDisabled}
                  key={item.key}
                  value={index}
                  label={item.label}
                  control={
                    <GradientRadio
                      gradientColor={
                        isDisabled ? 'disabled.vertical' : gradientColor
                      }
                      disabled={isDisabled}
                      sx={{ mr: 2 }}
                    />
                  }
                  sx={{ width: '100%', ...theme.panels[1], mx: 0 }}
                />
                {
                  <Collapse in={index === selectedIndex}>
                    {item.contentOnSelect}
                  </Collapse>
                }
              </>
            );
          })}
        </Stack>
      </RadioGroup>
    );
  },
);
