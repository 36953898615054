import { Children } from 'react';
import { memo } from '../../../../util/memo';
import { ColumnDesktop } from './ColumnDesktop';
import { ColumnProps } from './ColumnWrapper';

function ColumnRightUnmemoized({ children }: ColumnProps) {
  return (
    <ColumnDesktop
      pr={Children.count(children) === 4 ? 4 : 0}
      justifyContent="space-between"
    >
      {children}
    </ColumnDesktop>
  );
}

export const ColumnRight = memo(ColumnRightUnmemoized);
