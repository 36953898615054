import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import { ChipUser } from '../../ChipUser';
import { TeammateProps, TeammatesTable } from './teammates/TeammatesTable';
import Button from '@mui/material/Button';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { useRegisterDialogs } from './dialogs/useRegisterDialogs';
import { LoadingButton } from '../../buttons/LoadingButton';

export type PendingInviteProps = {
  inviter: { username: string; imgUrl: string; userId: string };
  teamId: string;
  teammates: TeammateProps[];
  teamName: string;
  onRsvp?: () => void | Promise<void>;
  headerSx?: Omit<StackProps, 'direction' | 'spacing' | 'alignItems'>;
};

function PendingInviteUnmemoized({
  inviter,
  teamId,
  teamName,
  teammates,
  onRsvp,
  headerSx,
}: PendingInviteProps) {
  const { rejectTeamInvite } = useRegistrationFunctions();
  const { open: openRegisterDialog } = useRegisterDialogs();
  const { username, imgUrl } = inviter;
  const processRSVP = useCallback(async () => {
    if (!!onRsvp) {
      await onRsvp();
    }
  }, [onRsvp]);
  const openInvite = useCallback(async () => {
    openRegisterDialog({
      registrationType: 'team',
      teamId,
    });
    await processRSVP();
  }, [openRegisterDialog, processRSVP, teamId]);
  const rejectInvite = useCallback(async () => {
    await rejectTeamInvite(teamId);
    await processRSVP();
  }, [processRSVP, rejectTeamInvite, teamId]);

  return (
    <Stack gap="8px">
      <Stack direction="row" alignItems="center" spacing={2} {...headerSx}>
        <ChipUser username={username} avatarUrl={imgUrl} />
        <Typography variant="h6" color="text.primary">
          has invited you to team {teamName}!
        </Typography>
      </Stack>
      <TeammatesTable teammates={teammates} />
      <Stack flexDirection="row" gap="8px">
        <Button
          variant="outlined"
          color="success"
          onClick={openInvite}
          size="large"
          sx={{
            flexGrow: 3,
          }}
        >
          ACCEPT
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={rejectInvite}
          size="large"
          sx={{
            flexGrow: 1,
          }}
        >
          REJECT
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export const PendingInvite = memo(PendingInviteUnmemoized);
