import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { memo } from '../../../../util/memo';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import { getStatusText } from '../../../../util/teammates/getStatusText';
import { TeammateProps } from './TeammatesTable';
import {
  TournamentPhase,
  Tournament,
} from '../../../../../functions/src/types/firestore/Game/Tournament';
import { truncateIfTooLong } from '../../../../util/truncate';
import { StyledTableRow } from 'src/components/StyledTable/StyledTableRow';
import { StyledTableCell } from 'src/components/StyledTable/StyledTableCell';
import { ChipUser } from '../../../ChipUser';
import { GradientIcon } from '../../../gradients/GradientIcon';
import { LoadingIconButton } from '../../../buttons/LoadingIconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { isPreReady } from '../../../../../functions/src/types/firestore/Game/Tournament/util';
import { useAuth } from '../../../../contexts/AuthContext';
import { CheckInButton } from '../../check-in/CheckInButton';

export type TeammateRowProps = Pick<Tournament, 'continuousRegistration'> &
  TeammateProps & {
    phase: TournamentPhase;
    checkIn: (entireTeam: boolean, userId?: string) => Promise<void>;
    isCaptain?: boolean;
    uninvite?: (userId: string) => Promise<void>;
  };

const TeammateRowUnmemoized = ({
  username,
  status,
  imgUrl,
  userId,
  checkedIn,
  isCurrentUser,
  phase,
  isCaptain,
  uninvite,
  continuousRegistration,
}: TeammateRowProps) => {
  const statusText = getStatusText(status);
  const { uid } = useAuth();
  const uninviteOther = useCallback(() => {
    return !isCurrentUser && uninvite ? uninvite(userId) : Promise.resolve();
  }, [isCurrentUser, uninvite, userId]);

  const isSelf = uid === userId;
  const { text: textStatus, color, usernameStyles } = statusText;
  const showCheckIn =
    phase === 'checkIn' &&
    (status === 'accepted' || status === 'captain') &&
    !checkedIn &&
    (isCaptain || isSelf);
  const showUninvite =
    !!uninvite &&
    (isPreReady(phase) || (continuousRegistration && status !== 'accepted'));

  return (
    <StyledTableRow key={userId}>
      <StyledTableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={usernameStyles}>
            <ChipUser
              username={truncateIfTooLong(username)}
              avatarUrl={imgUrl}
            />
          </Box>
          <Typography
            color={color}
            variant="subtitle1"
            fontWeight="400"
            sx={{ pl: 2 }}
          >
            {textStatus}
          </Typography>
        </Box>
      </StyledTableCell>
      {showUninvite && (
        <StyledTableCell>
          <Stack alignItems="center" justifyContent="center">
            <LoadingIconButton
              sx={{ width: 44, my: -1 }}
              disabled={isCurrentUser || status === 'captain'}
              color="error"
              onClick={uninviteOther}
            >
              <CancelRoundedIcon />
            </LoadingIconButton>
          </Stack>
        </StyledTableCell>
      )}
      {phase === 'checkIn' && (
        <StyledTableCell>
          <Stack alignItems="center" justifyContent="center">
            {checkedIn && (
              <GradientIcon
                gradientColor="secondary.horizontal"
                IconComponent={CheckCircleIcon}
              />
            )}
            {showCheckIn && <CheckInButton idToCheckIn={userId} size="small" />}
            {!showCheckIn && !checkedIn && (
              <Typography color="text.secondary">{`N/A`}</Typography>
            )}
          </Stack>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export const TeammateRow = memo(TeammateRowUnmemoized);
