import {
  RegistrationOption,
  RegistrationOptionAuto,
} from '../../types/firestore/Game/Tournament';

export const fetchedOptionsOf = (
  registrationOptions: RegistrationOption[],
  idToMatch: string,
) => {
  return registrationOptions.find(
    (option): option is RegistrationOptionAuto => {
      return (
        'providers' in option &&
        option.providers === 'fetched' &&
        option.id === idToMatch
      );
    },
  );
};
