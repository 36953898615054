import { FC, useContext, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { NUMERIC_TIMEZONE } from 'src/util/dates/presets/times';
import { findIntermissionAround } from '../../../util/findIntermissionAround';
import { ELLIPSIS_SX } from '../../../util/string';
import { GradientIcon } from '../../gradients/GradientIcon';
import LockIcon from '@mui/icons-material/Lock';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useUserGroups } from '../../../hooks/group/useUserGroups';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const TournamentCardPhaseUnmemoized: FC = () => {
  const { phase, date, endDate, readyTime, intermissions, skipCheckIn, roles } =
    useContext(TournamentContext);

  const groups = useUserGroups();

  const theme = useTheme();

  const format = useDateFormatter({
    dateFormat: false,
    timeFormat: NUMERIC_TIMEZONE,
    separator: ' ',
  });

  const currentIntermission = intermissions
    ? findIntermissionAround(intermissions)
    : undefined;

  const getPhaseTextAndTime = () => {
    if (currentIntermission) {
      const { end } = currentIntermission;
      return { timePrefix: 'INTERMISSION UNTIL', time: end };
    }

    switch (phase) {
      case 'ready':
      case 'review':
      case 'unpublished':
        return { timePrefix: 'STARTS AT', time: date };
      case 'live':
        return { timePrefix: 'LIVE SINCE', time: date };
      case 'finished':
        return { timePrefix: 'ENDED AT', time: endDate };
      default:
        return skipCheckIn
          ? { timePrefix: 'STARTS AT', time: date }
          : { timePrefix: 'CHECK-IN BY', time: readyTime };
    }
  };

  const { timePrefix, time } = getPhaseTextAndTime();

  const registrationInfo = useMemo(() => {
    if (!roles.registrant || roles.registrant.includes('anyone')) {
      return { LockIconComponent: null, tooltipText: null };
    }

    const registrantGroups = roles.registrant.map((role) => {
      return role === 'anyone' ? 'anyone' : Number(role);
    });

    const userCanRegister =
      registrantGroups.includes('anyone') ||
      registrantGroups.some((group) => {
        return group === 'anyone' || groups.includes(group);
      });

    const LockIconComponent = userCanRegister ? LockOpenIcon : LockIcon;

    const tooltipText = userCanRegister
      ? 'You are eligible to register for this event'
      : 'Registration is restricted to specific group members';

    return { LockIconComponent, tooltipText };
  }, [roles.registrant, groups]);

  return (
    <Stack
      borderRadius="8px !important;"
      padding={1}
      sx={{
        background: theme.palette.background.elevation[15],
        justifyContent: 'space-between',
      }}
      direction="row"
    >
      <Typography
        sx={{
          fontWeight: '600',
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
          ...ELLIPSIS_SX,
        }}
      >
        {`${
          ['unpublished', 'review'].includes(phase)
            ? `(${phase.toUpperCase()}) `
            : ''
        }${timePrefix}: ${format(time)}`}
      </Typography>
      {registrationInfo.LockIconComponent && (
        <Tooltip title={registrationInfo.tooltipText}>
          <Stack>
            <GradientIcon
              gradientColor="primary.horizontal"
              IconComponent={registrationInfo.LockIconComponent}
              sx={{ width: 20, height: 20 }}
            />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};

export const TournamentCardPhase = memo(TournamentCardPhaseUnmemoized);
