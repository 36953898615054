import { useCallback } from 'react';
import { useUnreadMessageCount } from '../../contexts/UnreadMessageCountContext';

export const useFindChannelId = () => {
  const { unreadMessageCount } = useUnreadMessageCount();

  const findMatchChannelId = useCallback(
    (matchId: string) => {
      return Object.values(unreadMessageCount)
        .map(({ channels }) => {
          return Object.keys(channels).find((channelId) => {
            const channel = channels[String(channelId)];
            return channel.matchId === matchId;
          });
        })
        .find((channelId) => {
          return !!channelId;
        });
    },
    [unreadMessageCount],
  );

  const findGeneralChannelId = useCallback(
    (tournamentId: string) => {
      return Object.values(unreadMessageCount)
        .map(({ channels }) => {
          return Object.keys(channels).find((channelId) => {
            const channel = channels[String(channelId)];
            return channel.tournamentId === tournamentId && !channel.matchId;
          });
        })
        .find((channelId) => {
          return !!channelId;
        });
    },
    [unreadMessageCount],
  );

  return { findGeneralChannelId, findMatchChannelId };
};
