import { FC, ReactNode, useMemo } from 'react';
import { memo, withDeepCompareOf } from '../../util/memo';
import { Configure, UseConfigureProps } from 'react-instantsearch';
import { CustomHitsPreempted } from './CustomHitsPreempted';
import { RefreshHits } from './RefreshHits';
import Box from '@mui/material/Box';
import type { Required } from 'utility-types';
import { PreemptStateProvider } from '../../contexts/algolia/PreemptStateContext';
import { PreemptedInstantSearch } from './PreemptedInstantSearch';
import { OrNode } from '../../../functions/src/types/Hit';

export type CatalogWrapperProps<THit> = {
  hits: THit[];
  /**
   *
   * @returns the additional loaded hits
   */
  onNearEnd: () => Promise<number>;
  header?: ReactNode;
  transformHits?: (hits: THit[]) => OrNode<THit>[];
  isLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RenderCatalogWrapper = FC<CatalogWrapperProps<any>>;

export type AlgoliaLayoutProps = {
  CatalogWrapper: RenderCatalogWrapper;
  configureOptions: Required<UseConfigureProps, 'filters'>;
  index: string;
  skipHits?: string[];
  onHitsChange?: (hits: Record<string, unknown>[]) => void;
};

const clean = (configureOptions: Required<UseConfigureProps, 'filters'>) => {
  const { index: __, ...cleaned } = configureOptions;
  return cleaned;
};

const AlgoliaLayoutUnmemoized = ({
  CatalogWrapper,
  configureOptions,
  index,
  skipHits,
  onHitsChange,
}: AlgoliaLayoutProps) => {
  const configureOptionsCleaned = useMemo(() => {
    return clean(configureOptions);
  }, [configureOptions]);
  const { filters } = configureOptions;

  return (
    <PreemptStateProvider>
      <PreemptedInstantSearch indexName={index}>
        <RefreshHits filters={filters}>
          <Configure {...configureOptionsCleaned} />
          <Box
            sx={{
              height: '100%',
              animation: 'fadeIn ease 500ms',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '3%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          >
            <CustomHitsPreempted
              CatalogWrapper={CatalogWrapper}
              skipHits={skipHits}
              onHitsChange={onHitsChange}
            />
          </Box>
        </RefreshHits>
      </PreemptedInstantSearch>
    </PreemptStateProvider>
  );
};

export const AlgoliaLayout = memo(
  AlgoliaLayoutUnmemoized,
  withDeepCompareOf('configureOptions'),
) as typeof AlgoliaLayoutUnmemoized;
