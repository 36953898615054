import type { QuerySnapshot, DocumentData } from 'firebase/firestore';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import { FirestoreHandler, useFirestore } from './useFirestore';
import { useCallback, useMemo } from 'react';

/**
 * Custom hook to retrieve a collection snapshot from Firestore
 *
 * @param collectionPath Path to the collection in Firestore
 * @param initialData Initial data loaded from Firestore SSR
 * @returns realtime array data of the associated collection
 */
export function useCollectionSnapshot<TData extends DocumentData>(
  collectionPath?: string,
  initialData: TData[] = [],
): TData[] {
  const handler = useCallback<FirestoreHandler<TData[]>>(
    (firestoreModule, firebaseFirestoreModule, setData) => {
      if (!collectionPath) {
        return;
      }
      const { firestore } = firestoreModule;
      const { collection, query, onSnapshot } = firebaseFirestoreModule;

      const collectionRef = collection(firestore, collectionPath);
      const collectionQuery = query(
        collectionRef.withConverter<TData>(
          ConverterFactory.buildDateConverter(),
        ),
      );

      return onSnapshot(
        collectionQuery,
        (snapshot: QuerySnapshot<TData>) => {
          const docsData = snapshot.docs.map((docSnap) => {
            return docSnap.data();
          });
          setData(docsData);
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [collectionPath],
  );

  const data = useFirestore<TData[]>(handler, initialData);
  return useMemo(() => {
    return data || [];
  }, [data]);
}
