import Chip, { ChipProps } from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { forwardRef, ForwardedRef } from 'react';
import { GradientTypography } from './gradients/GradientTypography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../util/memo';

export type ChipTabProps = Omit<ChipProps, 'varant'> & {
  isActive: boolean;
  label?: string;
  IconComponent?: JSX.Element;
  variant?: 'large' | 'small';
};

const ChipTabUnmemoized = forwardRef(
  (props: ChipTabProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { IconComponent, label, isActive, sx, variant, ...rest } = props;
    const theme = useTheme();
    const isLarge = variant === 'large';

    const typographySx = {
      variant: isLarge ? 'subtitle1' : 'body3',
      textTransform: 'uppercase',
      fontWeight: '600',
    };

    return (
      <Chip
        ref={ref}
        label={
          isLarge ? (
            <GradientTypography
              gradientColor="primary.vertical"
              sx={typographySx}
            >
              {label}
            </GradientTypography>
          ) : (
            <Typography
              variant="body1"
              color={'text.secondary'}
              sx={typographySx}
            >
              {label}
            </Typography>
          )
        }
        icon={IconComponent}
        sx={{
          height: {
            xs: isLarge ? '40px' : '32px',
            md: isLarge ? '44px' : '36px',
          },
          borderRadius: '100px',
          px: 2,
          background: isActive
            ? theme.palette.background.elevation[22]
            : theme.palette.background.elevation[9],
          '&:hover': {
            backgroundColor: `${theme.palette.background.elevation[18]}`,
          },
          boxShadow:
            isActive && isLarge
              ? '0px 0px 2px 1px rgba(57, 156, 250, 0.20)'
              : 'none',
          border: `1px solid ${
            isActive && isLarge ? theme.palette.primary.dark : 'transparent'
          }`,
          'MuiButtonBase-root-MuiChip-root': {
            justifyContent: 'center',
          },
          '.MuiChip-label': {
            pl: label ? undefined : '0px',
          },
          alignItems: 'center',
          ...sx,
        }}
        {...rest}
      />
    );
  },
);

export const ChipTab = memo(ChipTabUnmemoized);
ChipTabUnmemoized.displayName = 'ChipTabUnmemoized';
