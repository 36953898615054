import { useState } from 'react';
import { memo } from '../../../util/memo';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useNumberFormatter } from 'src/hooks/useNumberFormatter';
import { useTheme } from '@mui/material/styles';
import { formatEther, parseUnits } from 'viem';
const E18_STR = '1000000000000000000';

export type InputCryptoProps = {
  coin?: Uppercase<string>;
  weiMax?: bigint;
  weiMin?: bigint;
  onChange: (wei: bigint | 'ERROR' | undefined) => void;
  helperText?: string;
} & Omit<TextFieldProps, 'onChange'>;

const InputCryptoUnmemoized = ({
  coin,
  weiMax = BigInt(E18_STR) * BigInt(Number.MAX_SAFE_INTEGER - 1),
  weiMin = BigInt(0),
  onChange,
  color = 'primary',
  disabled = false,
  helperText = 'Please enter a price. This can be zero.',
  ...props
}: InputCryptoProps) => {
  const theme = useTheme();
  const [error, setError] = useState<string | undefined>('');
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const formatter = useNumberFormatter({ minimumFractionDigits: 2 });
  const minFormatted = `${formatter.format(
    Number(formatEther(weiMin, 'wei')),
  )} ${coin}`;
  const maxFormatted = `${formatter.format(
    Number(formatEther(weiMax, 'wei')),
  )} ${coin}`;

  return (
    <TextField
      type="number"
      error={!!error}
      disabled={disabled}
      helperText={isEmpty ? helperText : error}
      color={color}
      // defaultValue={defaultValue}
      variant="outlined"
      sx={{
        '.MuiInputBase-root': {
          backgroundColor: `${theme.palette[`${color}`].dark}16`,
        },
        height: '75px',
      }}
      fullWidth
      onChange={(event) => {
        if (
          event.target.value === '' ||
          event.target.value === null ||
          event.target.value === undefined
        ) {
          setIsEmpty(true);
          setError(helperText);
          onChange(undefined);
        } else {
          const valuewei = parseUnits(event.target.value, 18);
          if (valuewei < weiMin) {
            setError(`Must be at least ${minFormatted}.`);
            onChange('ERROR');
          } else if (valuewei > weiMax) {
            setError(`Must be at most ${maxFormatted}.`);
            onChange('ERROR');
          } else {
            setError(undefined);
            onChange(valuewei);
          }
        }
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <GradientTypography variant="h6" gradientColor={`${color}.vertical`}>
            {coin}
          </GradientTypography>
        ),
      }}
      {...props}
    />
  );
};

export const InputCrypto = memo(InputCryptoUnmemoized);
