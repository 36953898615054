import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { useDisplayedRank } from '../../../hooks/useDisplayedRank';

export type RankProps = {
  rank: number;
};

export const Rank = memo(function RankUnmemoized({ rank }: RankProps) {
  const theme = useTheme();
  const rankDisplayed = useDisplayedRank({ rank });
  return (
    <Typography
      color={
        rank === 1
          ? theme.palette.warning.main
          : rank === 2
          ? '#C0C0C0'
          : rank === 3
          ? theme.palette.error.main
          : theme.palette.primary.main
      }
      variant={rank > 3 ? 'body2' : 'body1'}
      {...(rank <= 3 && { sx: { fontWeight: 700 } })}
    >
      {rankDisplayed}
    </Typography>
  );
});
