import { useAuth } from 'src/contexts/AuthContext';
import { useAccountLowercase } from './useAccountLowercase';
import { useMemo } from 'react';

export const useSelectedAddress = () => {
  const { userData } = useAuth();

  const account = useAccountLowercase();

  return useMemo(() => {
    const addresses = userData?.addresses;

    const address = account?.address;

    const isConnectedAddress =
      !!addresses && address && addresses.includes(address);

    return {
      address: isConnectedAddress ? address : undefined,
    };
  }, [account, userData?.addresses]);
};
