import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getApp } from './app';

const app = getApp();

const functions = getFunctions(app);

let connectedEmulator = false;
if (!connectedEmulator && process.env.NEXT_PUBLIC_EMULATOR_MODE === 'true') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectedEmulator = true;
}

export { functions };
