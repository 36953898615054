import type { ChannelData, ChannelFilters } from 'stream-chat';
import { isPersonalType } from '../../types/firestore/User/ChannelGroup/util/isPersonal';
import { sortedHash } from '../hash/sortedHash';
import { toChannelFilters } from './toChannelFilters';
import { toChannelData } from './toChannelData';
import {
  ChannelGroup,
  FilterType,
  GroupFilterMap,
} from '../../types/firestore/User/ChannelGroup';

export const toChannelId = (
  filters: ChannelFilters & { type: FilterType },
  channelData?: Omit<ChannelData, 'members'>,
) => {
  const filtersToHash =
    isPersonalType(filters.type) && !!channelData
      ? { ...filters, ...channelData }
      : filters;
  return sortedHash(filtersToHash);
};

export const toChannelIdParams = (
  type: FilterType,
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
) => {
  const { groupFilter } = channelGroup;
  const channelFilters = toChannelFilters(groupFilter);
  const channelData = toChannelData(channelGroup);

  return {
    filters: { ...channelFilters, type },
    channelData,
  };
};

// NOTE: this will return undefined for non-personal channelGroups
export const toCid = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
) => {
  const type = channelGroup.groupFilter[0].type;
  const isPersonalChannelGroup = isPersonalType(type);
  const { filters, channelData } = toChannelIdParams(type, channelGroup);

  return isPersonalChannelGroup
    ? `${type}:${toChannelId(filters, channelData)}`
    : undefined;
};
