import { ComponentType, MemoExoticComponent, FC } from 'react';
import { memo } from '../memo';
import {
  useAdInjection,
  UseAdInjectionProps,
} from '../../hooks/ads/useAdInjection';

export type HitsProps<THit> = {
  hits: THit[];
};

export type AdsInjectableComponent<THit, TProps extends HitsProps<THit>> =
  | ComponentType<TProps>
  | MemoExoticComponent<ComponentType<TProps>>;

/**
 * @remarks
 * You should avoid putting this inside a React Component body and instead put it
 * in the global scope.
 *
 * If you must use it inside a React Component body, you should useMemo.
 */
export type WithAdInjectionType<THit, TProps extends HitsProps<THit>> = {
  WrappedComponent: AdsInjectableComponent<THit, TProps>;
} & UseAdInjectionProps;

export const withAdInjection = <THit, TProps extends HitsProps<THit>>({
  WrappedComponent,
  ...props
}: WithAdInjectionType<THit, TProps>) => {
  const ComponentWithAdInjectionUnmemoized: FC<TProps> = ({
    hits,
    ...rest
  }) => {
    const injectHits = useAdInjection(props);

    const injectedHits = injectHits(hits);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propsNew = { hits: injectedHits, ...rest } as any;
    return <WrappedComponent {...propsNew} />;
  };

  return memo(ComponentWithAdInjectionUnmemoized);
};
