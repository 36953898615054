import { ReactNode } from 'react';
import { useWrapWithTooltips } from '../organizer/useWrapWithTooltips';

export type ScreenshotAction = 'report' | 'replace' | 'delete';

export const SCREENSHOT_BUTTON_TOOLTIP_MAP: Partial<
  Record<ScreenshotAction, ReactNode>
> = {
  report:
    'Report this screenshot. When you do, you will be redirected to our support team.',
  replace: 'Replace your screenshot with another image.',
  delete: 'Delete this screenshot.',
};

export const useScreenshotTooltips = (
  components: Record<string, JSX.Element>,
) => {
  return useWrapWithTooltips(components, SCREENSHOT_BUTTON_TOOLTIP_MAP);
};
