import { Token } from '../../types/firestore/User/Payout';

export const isPrizePoolDivisible = (
  payouts: Token[],
  maxTeamSize: number,
): boolean => {
  const erc721Count = payouts.filter((payout) => {
    return payout.type === 'ERC721';
  }).length;
  const erc1155Amounts = payouts
    .filter((payout) => {
      return payout.type === 'ERC1155';
    })
    .map((payout) => {
      return Number(payout.amount);
    });
  const erc721Divisible = erc721Count % maxTeamSize === 0;
  const erc1155Divisible = erc1155Amounts.every((amount) => {
    return amount % maxTeamSize === 0;
  });

  return erc721Divisible && erc1155Divisible;
};
