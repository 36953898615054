import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import { useTheme } from '@mui/material/styles';

export const CompetitorCheckedIn: React.FC<Record<string, unknown>> = () => {
  const theme = useTheme();
  return (
    <CheckCircleIcon
      sx={{
        p: 0.5,
        alignSelf: 'center',
        color: `${theme.palette.secondary.main}`,
        width: { xs: 24, md: undefined },
        height: { xs: 24, md: undefined },
      }}
    />
  );
};
