import { FC } from 'react';
import { memo } from '../../../util/memo';
import {
  TournamentCardBase,
  TournamentCardBaseProps,
} from './TournamentCardBase';
import { TournamentCardPhase } from './TournamentCardPhase';

export type TournamentCardProps = TournamentCardBaseProps;

const TournamentCardUnmemoized: FC<TournamentCardProps> = ({
  children,
  ...tournament
}) => {
  return (
    <TournamentCardBase {...tournament}>
      <TournamentCardPhase />
      {children}
    </TournamentCardBase>
  );
};

export const TournamentCard = memo(TournamentCardUnmemoized);
