import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useInfiniteScroll } from './useInfiniteScroll';
import type {
  DocumentData,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { UseFetchDocInfiniteScrollProps } from './useFetchDocInfiniteScroll';

export type SnapshotPage<TDoc extends DocumentData> = Record<
  string,
  {
    hasMore: boolean;
    docs: TDoc[];
    index: number;
    lastSnapshot?: DocumentSnapshot<TDoc>;
  }
>;

export type UseOnSnapshotInfiniteScrollProps<TDoc extends DocumentData> = Omit<
  UseFetchDocInfiniteScrollProps<TDoc>,
  'onLoadMore' | 'query'
> & { query?: Promise<Query<TDoc> | undefined> | Query<TDoc> };

export const FIRST_KEY = 'first';

export function useOnSnapshotInfiniteScroll<TDoc extends DocumentData>({
  query,
  pageSize,
  initialDocuments = [],
  startAfter: startAfterValue,
  updateInPlace = true,
  ...props
}: UseOnSnapshotInfiniteScrollProps<TDoc>) {
  const [pages, setPages] = useState<SnapshotPage<TDoc>>({});

  useEffect(() => {
    if (!!Object.keys(pages).length) {
      setPages({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const lastDocumentSnapshot = useMemo(() => {
    const pageValues = Object.values(pages);
    if (pageValues.length === 0) {
      return startAfterValue;
    }
    const { lastSnapshot } = pageValues.sort((pageA, pageB) => {
      return pageB.index - pageA.index;
    })[0];
    return lastSnapshot;
  }, [pages, startAfterValue]);

  const appendPage = useCallback(
    (pageSizeLimit: number, snapshot?: QuerySnapshot<TDoc>) => {
      const docs = !snapshot
        ? []
        : snapshot.docs.map((doc) => {
            return doc.data();
          });
      const snapDocs = !snapshot ? [] : snapshot.docs;

      // eslint-disable-next-line no-shadow
      const hasMore = docs.length === pageSizeLimit;
      const pageKey = lastDocumentSnapshot
        ? lastDocumentSnapshot.id
        : FIRST_KEY;

      const lastSnapshot =
        snapDocs.length > 0 ? snapDocs[snapDocs.length - 1] : undefined;

      const index = Object.keys(pages).length;

      setPages((prevPages) => {
        return {
          ...prevPages,
          [pageKey]: { hasMore, docs, index, lastSnapshot },
        };
      });
    },
    [lastDocumentSnapshot, pages],
  );

  const fetchUnsubscribeRef = useRef<Unsubscribe>();

  const fetchDocuments = useCallback(
    async (pageSizeLimit: number) => {
      if (fetchUnsubscribeRef.current) {
        fetchUnsubscribeRef.current();
        fetchUnsubscribeRef.current = undefined;
      }
      const {
        query: firestoreQuery,
        limit,
        startAfter,
        onSnapshot,
      } = await import('firebase/firestore');

      const queryAwaited = await query;

      if (!queryAwaited) {
        return appendPage(pageSizeLimit);
      }

      const queryFull = firestoreQuery(
        queryAwaited,
        limit(pageSizeLimit),
        ...(lastDocumentSnapshot ? [startAfter(lastDocumentSnapshot)] : []),
      );

      fetchUnsubscribeRef.current = onSnapshot(
        queryFull,
        (snapshot) => {
          appendPage(pageSizeLimit, snapshot);
        },
        (error) => {
          appendPage(pageSizeLimit);
          console.error(error);
        },
      );
    },
    [lastDocumentSnapshot, appendPage, query],
  );

  const documents = useMemo(() => {
    const pageValues = Object.values(pages);
    if (pageValues.length === 0) {
      return initialDocuments;
    }
    const idToDocIndex = pageValues
      .flatMap(({ docs, index }) => {
        return docs.map((doc) => {
          return { doc, index };
        });
      })
      .reduce((acc, docIndex) => {
        const { id } = docIndex.doc;
        acc[String(id)] = docIndex;
        return acc;
      }, {} as Record<string, { doc: TDoc; index: number }>);
    return Object.values(idToDocIndex)
      .sort((docIndexA, docIndexB) => {
        return docIndexA.index - docIndexB.index;
      })
      .map(({ doc }) => {
        return doc;
      });
  }, [initialDocuments, pages]);

  const hasMore = useMemo(() => {
    const pageKeys = Object.keys(pages);
    if (pageKeys.length === 0) {
      return 'undetermined';
    }
    return Object.values(pages).sort((pageA, pageB) => {
      return pageB.index - pageA.index;
    })[0].hasMore;
  }, [pages]);

  useEffect(() => {
    if (hasMore === 'undetermined') {
      fetchDocuments(pageSize);
      //setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore]);

  const nextPage = useCallback(async () => {
    if (hasMore) {
      await fetchDocuments(pageSize);
    }
  }, [hasMore, fetchDocuments, pageSize]);

  const containerRef = useInfiniteScroll({
    ...props,
    onLoadMore: nextPage,
    hasMore: hasMore === 'undetermined' ? true : hasMore,
  });

  return {
    containerRef,
    documents: updateInPlace ? documents : initialDocuments,
  };
}
