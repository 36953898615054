import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { TournamentPhase } from 'functions/src/types/firestore/Game/Tournament';
import { isPreReady } from 'functions/src/types/firestore/Game/Tournament/util';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { TableBackbone } from 'src/components/StyledTable/TableBackbone';
import { StyledTableCell } from 'src/components/StyledTable/StyledTableCell';
import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';
import { TeammateRow } from './TeammateRow';
import { TeammateRowRandom } from './TeammateRowRandom';
import { v4 as uuidv4 } from 'uuid';
import { useRegistrationFunctions } from '../../../../hooks/tournaments/useRegistrationFunctions';
import { AddTeammateAutocomplete } from './AddTeammateAutocomplete';
import Table from '@mui/material/Table';
import { useAuth } from '../../../../contexts/AuthContext';

export type MemberTournamentStatus =
  | 'accepted'
  | 'declined'
  | 'pending'
  | 'captain'
  | 'reassigned'
  | 'solo';
export type TeammateProps = {
  username: string;
  status: MemberTournamentStatus;
  isCurrentUser: boolean;
  imgUrl: string;
  userId: string;
  checkedIn: boolean;
};
export type TeammateRandom = {
  username: 'random';
  status?: undefined;
  isCurrentUser?: undefined;
  imgUrl?: undefined;
  userId?: undefined;
  checkedIn?: undefined;
};

export type TeammatesTableMemberProps = {
  teammates: TeammateProps[];
  isCaptain: boolean;
  phase: TournamentPhase;
};

export type TeammatesTableOwnerProps = TeammatesTableMemberProps & {
  uninvite: (username: string) => void;
};

export type TeammatesTableProps = {
  teammates: TeammateProps[];
};

const isRandom = (
  teammate: TeammateProps | TeammateRandom,
): teammate is TeammateRandom => {
  return !teammate.userId;
};

const TeammatesTableUnmemoized = ({ teammates }: TeammatesTableProps) => {
  const {
    phase,
    isUserCaptain,
    continuousRegistration,
    maxTeamSize,
    isAcceptedMyTeam,
    isMemberCaptain,
  } = useTournamentRegistration();
  const { uninvite, checkIn } = useRegistrationFunctions();

  const teammatesAvailable: (TeammateRandom | TeammateProps)[] = Array(
    maxTeamSize,
  ).fill({
    username: 'random',
  });
  teammates?.forEach((member, index) => {
    teammatesAvailable[Number(index)] = member;
  }); //TODO: Can we refactor TeammateProps to accept TeammateRandom as well? That will get rid of the whole teammateRowRandom component

  const { uid } = useAuth();

  const canUninvite = useMemo(() => {
    return !!uid && isAcceptedMyTeam(uid);
  }, [uid, isAcceptedMyTeam]);

  const canInvite = useMemo(() => {
    return canUninvite && teammates.length < maxTeamSize;
  }, [canUninvite, teammates.length, maxTeamSize]);

  const AddFriendAutoComplete = useMemo(() => {
    if (!canInvite) {
      return null;
    }
    return (
      <Table>
        <TableRow sx={{ height: '100%' }}>
          <AddTeammateAutocomplete />
        </TableRow>
      </Table>
    );
  }, [canInvite]);

  return (
    <TableBackbone outerChildren={canInvite && AddFriendAutoComplete}>
      <TableHead sx={{ height: '40px', border: 'none' }}>
        <TableRow>
          <StyledTableCell>
            <GradientTypography gradientColor="primary.vertical">
              Username
            </GradientTypography>
          </StyledTableCell>
          {(isPreReady(phase) || continuousRegistration) && (
            <StyledTableCell
              sx={{
                textAlign: 'center',
                width: 40,
              }}
            />
          )}
          {phase === 'checkIn' && (
            <StyledTableCell
              sx={{
                textAlign: 'center',
                width: 100,
              }}
            >
              <GradientTypography gradientColor="primary.vertical">
                Checked In
              </GradientTypography>
            </StyledTableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {teammatesAvailable.map((teammate) => {
          if (isRandom(teammate)) {
            return (
              <TeammateRowRandom
                key={uuidv4()}
                isCaptain={isUserCaptain}
                phase={phase}
              />
            );
          }
          const { userId } = teammate;
          return (
            <TeammateRow
              key={userId}
              {...teammate}
              phase={phase}
              isCaptain={isUserCaptain}
              uninvite={
                canUninvite || isMemberCaptain(userId) ? uninvite : undefined
              }
              checkIn={checkIn}
              continuousRegistration={continuousRegistration}
            />
          );
        })}
      </TableBody>
    </TableBackbone>
  );
};

export const TeammatesTable = memo(TeammatesTableUnmemoized);
