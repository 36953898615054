import { setupConsoleSuppression } from '../supressConsole';

export const UNBLOCKABLE_HOSTNAMES = [
  'cdn.adapex.io',
  // 'cmp.inmobi.com',
  // 'cat3.hbwrapper.com',
  // 'www.google-analytics.com',
  // 'p2.gcprivacy.com',
];

const FAILED_TO_LOAD_PATTERN = 'cf0527bb07f3 Failed to load' as const;
const ERROR_PATTERNS = [
  'net::ERR_BLOCKED_BY_CLIENT',
  FAILED_TO_LOAD_PATTERN,
] as const;

const isAdBlock = (errorMsg: string) => {
  return (
    ERROR_PATTERNS.some((pattern) => {
      return errorMsg.includes(pattern);
    }) &&
    UNBLOCKABLE_HOSTNAMES.some((hostname) => {
      return errorMsg.includes(hostname);
    })
  );
};

const setAdBlockDetected = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).abd = true;
  }
};

export const isAdBlockDetected = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof window !== 'undefined' && (window as any).abd === true;
};

const screenAdBlock = (errorMsg: string) => {
  if (isAdBlock(errorMsg)) {
    setAdBlockDetected();
  }
};

const setupConsoleHandlers = () => {
  const customHandlers = {
    warn: screenAdBlock,
    error: screenAdBlock,
  };
  setupConsoleSuppression(customHandlers);
};

const setupPerformanceObserver = () => {
  if ('PerformanceObserver' in window) {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.entryType === 'resource' && !entry.responseStatus) {
          screenAdBlock(`${FAILED_TO_LOAD_PATTERN}: ${entry.name}`);
        }
      });
    });
    observer.observe({ entryTypes: ['resource'] });
  }
};

export function setupAdBlockDetection() {
  if (
    typeof window === 'undefined' ||
    process.env.NEXT_PUBLIC_DEPLOYMENT_URL !== 'blumint.com'
  )
    return;

  setupConsoleHandlers();
  setupPerformanceObserver();
}

// Initialize the ad block detection
setupAdBlockDetection();
