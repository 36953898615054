import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { AlertStandard } from '../../AlertStandard';
import { Link } from '../../Link';

export type PostambleAlertProps = {
  text: string;
  link?: string;
};

export const PostambleAlert = memo(function PostambleAlertUnmemoized({
  text,
  link,
}: PostambleAlertProps) {
  return (
    <AlertStandard
      message={
        <Typography color={'warning.light'}>
          {text}{' '}
          {link && (
            <Link href={link} target="_blank" rel="noreferrer">
              {link}
            </Link>
          )}
        </Typography>
      }
      severity="warning"
    />
  );
});
