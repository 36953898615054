import { useState, useEffect, useRef } from 'react';
import { ConverterFactory } from '../../functions/src/util/firestore/ConverterFactory';
import { RealtimeAlgoliaConfiguration } from '../../functions/src/types/firestore/RealtimeAlgoliaConfiguration';
import { REFRESH_BUFFER } from '../contexts/algolia/IndexingHitsContext';

export type UseWatchAlgoliaReindex = {
  onRefresh: () => void;
  templateFilledHash?: string;
};

export const useWatchAlgoliaReindex = ({
  onRefresh,
  templateFilledHash,
}: UseWatchAlgoliaReindex) => {
  const [lastFetched, setLastFetched] = useState<number | undefined>();
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    isMounted.current = true;

    const handler = async () => {
      if (!templateFilledHash) {
        return;
      }

      const { doc, getDoc, onSnapshot, setDoc } = await import(
        'firebase/firestore'
      );
      const { firestore } = await import('../config/firebase-client/firestore');

      const docRef = doc(
        firestore,
        `RealtimeAlgoliaConfiguration/${templateFilledHash}`,
      ).withConverter<RealtimeAlgoliaConfiguration<Date>>(
        ConverterFactory.buildDateConverter(),
      );

      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        const { Timestamp } = await import('firebase/firestore');
        await setDoc(docRef, {
          id: templateFilledHash,
          lastUpdated: Timestamp.now(),
          reindexingTasks: [],
        });
      }

      unsubscribe = onSnapshot(docRef, (document) => {
        if (document.exists() && isMounted.current) {
          const data = document.data();
          const { lastUpdated } = data;
          const lastUpdateTime = lastUpdated.getTime();
          if (
            lastFetched !== undefined &&
            lastUpdateTime > lastFetched + REFRESH_BUFFER
          ) {
            onRefresh();
          }
          setLastFetched(lastUpdateTime);
        }
      });
    };

    handler();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      isMounted.current = false;
    };
  }, [templateFilledHash, onRefresh, lastFetched]);
};
