import { ReactNode } from 'react';
import {
  EventHit,
  RenderCard,
  RenderWrapper,
} from '../algolia/catalog-wrappers/EventsCalendar';

export const transformToEventKeyed = <THit extends EventHit<Date>>(
  hit: THit,
  Card: RenderCard<EventHit<Date>, Date>,
  Wrapper?: RenderWrapper<EventHit<Date>, Date>,
): { key: string; Node: ReactNode } => {
  const CardRendered: ReactNode = <Card {...hit} />;

  return {
    key: hit.objectID,
    Node: Wrapper ? <Wrapper hit={hit}>{CardRendered}</Wrapper> : CardRendered,
  };
};
