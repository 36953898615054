import { useCallback } from 'react';
// import Typography from '@mui/material/Typography';
// import Alert from '@mui/material/Alert';
import { OpenDialogSettings, useDialog } from '../useDialog';

const SIGNATURE_PENDING_DIALOG = 'SIGNATURE_PENDING_DIALOG' as const;

export const useSignaturePendingDialog = () => {
  const { open: openDialog, close } = useDialog(SIGNATURE_PENDING_DIALOG);

  const open = useCallback(
    (
      settings: Omit<
        OpenDialogSettings,
        'title' | 'description' | 'children'
      > = {},
    ) => {
      openDialog({
        title: 'Signature Request Sent.',
        description:
          'Please open your wallet and approve the signature request to sign in.',
        // children: (
        //   <Alert severity="warning" variant="outlined" sx={{ mt: 4 }}>
        //     <Typography
        //       color="warning.light"
        //       sx={{ my: -1, textTransform: 'none', fontWeight: '500' }}
        //     >
        //       There is an ongoing issue with Trezor + MetaMask that makes
        //       signature request popups in MetaMask immediately disappear. The
        //       MetaMask & Trezor developer teams are aware of the bug and are
        //       likely to resolve it soon. If you are affected, until then, we
        //       recommend you use a different solution.
        //     </Typography>
        //   </Alert>
        // ),
        ...settings,
      });
    },
    [openDialog],
  );

  return {
    open,
    close,
  };
};
