import { Member, Team } from '.';
import { Outcome } from '../Bracket';

export function toName<TTime = Date>(team: Team<TTime>, anyLength = false) {
  const { userIds, name, members } = team;
  return (((anyLength || userIds.length > 1) && name) ||
    members.find(({ status }) => {
      return status === 'captain';
    })?.username ||
    members.find(({ username }) => {
      return !!username;
    })?.username) as string;
}

export function toAvatar(team: Team<Date>, teamLength = 1): string | undefined {
  const { avatarUrl, members } = team;
  if (teamLength > 1) {
    return avatarUrl;
  }
  return (
    members.find(({ status }) => {
      return status === 'captain';
    })?.imgUrl ||
    members.find(({ imgUrl }) => {
      return !!imgUrl;
    })?.imgUrl // TODO: Remove ||ed value
  );
}

export function toOutcome(
  forTeam?: Team<Date> | null,
  otherTeam?: Team<Date> | null,
  winner?: Team<Date> | null,
): Outcome | undefined {
  if (!winner || !forTeam) {
    return undefined;
  }
  if (winner.id === forTeam.id) {
    return otherTeam ? 'win' : 'bypass';
  }
  return 'loss';
}

export const NOT_ON_TEAM_STATUSES = ['pending', 'declined'] as const;

export function isOnTeam({ status }: Pick<Member, 'status'>) {
  return !NOT_ON_TEAM_STATUSES.includes(
    status as typeof NOT_ON_TEAM_STATUSES[number],
  );
}
