import { Heat } from 'functions/src/types/firestore/Game/Tournament/Heat';
import { HeatRules } from './HeatRules';
import {
  LeaderboardContainer,
  LeaderboardContainerProps,
} from 'src/components/leaderboards/LeaderboardContainer';
import { memo } from '../../../util/memo';
import { HeatResults } from './HeatResults';

export type HeatLeaderboardProps = Pick<LeaderboardContainerProps, 'sponsors'> &
  Pick<
    Heat<Date>,
    'blurb' | 'endTime' | 'qualification' | 'results' | 'scoreOptions'
  >;

const HeatLeaderboardUnmemoized = ({
  blurb,
  endTime,
  sponsors,
  qualification,
  results = [],
  scoreOptions,
}: HeatLeaderboardProps) => {
  return (
    <LeaderboardContainer
      {...{ endDate: endTime, sponsors }}
      RightChildren={
        results.length > 0 && (
          <HeatResults
            scoreOptions={scoreOptions}
            results={results}
            qualification={qualification}
          />
        )
      }
    >
      {blurb && <HeatRules blurb={blurb} isQualifier={!!qualification} />}
    </LeaderboardContainer>
  );
};

export const HeatLeaderboard = memo(HeatLeaderboardUnmemoized);
