import { memo } from '../util/memo';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { truncateIfTooLong } from 'src/util/truncate';
import { useMemo } from 'react';

export type TypographyTruncatedProps = {
  textMaxLength?: number;
  text?: string;
} & TypographyProps;

export const TypographyTruncated = memo(function TypographyTruncatedUnmemoized({
  textMaxLength = 20,
  text = '',
  ...rest
}: TypographyTruncatedProps) {
  const typography = useMemo(() => {
    return (
      <Typography {...rest}>
        {truncateIfTooLong(text, textMaxLength)}
      </Typography>
    );
  }, [text, textMaxLength, rest]);

  if (text.length <= textMaxLength) return typography;

  return (
    <Tooltip title={text} arrow disableInteractive>
      {typography}
    </Tooltip>
  );
});
