import { FC } from 'react';
import { memo } from '../../util/memo';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useWizard } from './Wizard';

export type OutcomeElementProps = {
  image?: React.ReactNode;
  message?: string;
  prompt?: React.ReactNode;
  Alerts?: React.ReactNode;
};

const OutcomeElementUnmemoized: FC<OutcomeElementProps> = ({
  image,
  message,
  prompt,
  Alerts,
}) => {
  const { go } = useWizard();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Stack
        spacing={4}
        alignItems="center"
        sx={{ height: '100%', mt: 4, textAlign: 'center' }}
      >
        {!!image && image}
        <Stack alignItems="center" spacing={4} pt={4}>
          {message && <Typography variant="h5">{message}</Typography>}
          {!!prompt && prompt}
        </Stack>
        {Alerts}
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            go(undefined);
          }}
        >
          Close
        </Button>
      </Stack>
    </Box>
  );
};

export const OutcomeElement = memo(OutcomeElementUnmemoized);
