import { UnreadMessageCountGroup } from '../../contexts/UnreadMessageCountContext';

export const hasUnseenChannel = (
  channel: UnreadMessageCountGroup['channels'][string],
): boolean => {
  return !!channel.unseen;
};

export const isSomeChannelUnseen = (group: UnreadMessageCountGroup) => {
  return Object.values(group.channels).some(hasUnseenChannel);
};

export const isChannelUnseen = (
  group: UnreadMessageCountGroup,
  channelId: string,
) => {
  return (
    channelId &&
    group.channels[`${channelId}`] &&
    hasUnseenChannel(group.channels[`${channelId}`])
  );
};

export const isSomeTournamentChannelUnseen = (
  group: UnreadMessageCountGroup,
  tournamentId: string,
) => {
  return Object.values(group.channels).some((channel) => {
    return channel.tournamentId === tournamentId && hasUnseenChannel(channel);
  });
};

export const isMatchChannelUnseen = (
  group: UnreadMessageCountGroup,
  matchId: string,
) => {
  return Object.values(group.channels).some((channel) => {
    return channel.matchId === matchId && hasUnseenChannel(channel);
  });
};
