/* eslint-disable security/detect-object-injection */
import type { Dispatch, SetStateAction } from 'react';
import type { DocumentData } from 'firebase/firestore';
import type { EditStepPreFirestore } from '../../../functions/src/types/firestore/EditStep/Step';
import { mutateDocumentWithStep } from '../../../functions/src/util/firestore/editing/applyStep';

export const applyStep = <T extends DocumentData>(
  step: EditStepPreFirestore,
  setDocument: Dispatch<SetStateAction<T>>,
) => {
  setDocument((prevDocument) => {
    return mutateDocumentWithStep(prevDocument, step);
  });
};
