import { getSessionId } from './getSessionId';
import { updateSessionStatus } from './updateSessionStatus';

export const setUserSessionOffline = async (userId?: string) => {
  if (!userId) {
    return;
  }
  const sessionId = getSessionId();
  await updateSessionStatus(userId, 'offline', sessionId);
};
