import { useCallback } from 'react';
import {
  ChannelGroup,
  GroupFilter,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useTemporaryChannelGroups } from './useTemporaryChannelGroups';

export const useFilterTemporaryChannelGroups = () => {
  const { temporaryChannelGroups = [] } = useTemporaryChannelGroups();
  const isTemporaryForSession = useCallback(
    (groupFilter: GroupFilter) => {
      return temporaryChannelGroups.some((identifier) => {
        const [identifierKey] = Object.keys(identifier || {});
        return (
          !!identifier &&
          identifier[`${identifierKey}`] === groupFilter[0][`${identifierKey}`]
        );
      });
    },
    [temporaryChannelGroups],
  );

  const filterTemporaryChannelGroups = useCallback(
    (channelGroups: ChannelGroup<keyof GroupFilterMap, Date>[]) => {
      return channelGroups.filter(({ permanence, groupFilter }) => {
        return permanence !== 'temporary' || isTemporaryForSession(groupFilter);
      });
    },
    [isTemporaryForSession],
  );

  return {
    filterTemporaryChannelGroups,
  };
};
