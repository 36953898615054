import { BigNumber, ethers } from 'ethers';
import { TOURNAMENT_RNG_ABI } from '../../../abis/TournamentRNG';
//import { log } from '../../../util/log';

const TOURNAMENT_CONTRACT_ADDRESS =
  '0x62E4a2652f42ff14977fc37547712392404bbFcf';
const TOURNAMENT_RNGER_PK = process.env['TOURNAMENT_RNGER_PK'];
const RPC_URL = 'https://api.avax-test.network/ext/bc/C/rpc';
const NETWORK = 43113;
const TEST_SEED = '123456789';

const isTest = () => {
  return (
    (process.env.NODE_ENV === 'test' ||
      process.env.NEXT_PUBLIC_EMULATOR_MODE === 'true') &&
    !process.env?.TOURNAMENT_RNG_UNIT_TEST
  );
};

//@log
export class TournamentRNG {
  private readonly contract: ethers.Contract;
  constructor(private readonly tournamentId: string) {
    try {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL, NETWORK);
      const wallet = new ethers.Wallet(TOURNAMENT_RNGER_PK as string, provider);
      this.contract = new ethers.Contract(
        TOURNAMENT_CONTRACT_ADDRESS,
        TOURNAMENT_RNG_ABI,
        wallet,
      );
    } catch (error) {
      console.warn(`TournamentRNG failed to initialize`, error);
    } finally {
      this.contract = null as unknown as ethers.Contract;
    }
  }

  public async getRandomSeed(): Promise<string> {
    if (isTest()) {
      return TEST_SEED;
    }
    try {
      let res = await this.viewRandomSeed();
      if (!res?.length || !res[0]) {
        res = await this.createRandomSeed();
      }
      return res[0]!.toString();
    } catch (error) {
      console.warn(
        // this will error will be caught if the VRF router is down
        'TournamentRNG contract failed to generate a random seed. Defaulting to centralized random seed',
      );
      return Math.round(Math.random() * 1e10).toString();
    }
  }

  private async createRandomSeed(): Promise<BigNumber[]> {
    const tx = await this.contract.functions.generateSeed?.(
      this.tournamentId,
      2,
    );
    await tx.wait(2);
    return await this.viewRandomSeed();
  }

  private async viewRandomSeed(): Promise<BigNumber[]> {
    const res: BigNumber[] = await this.contract.seedOf(this.tournamentId);
    return res;
  }
}
