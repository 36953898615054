import { useContext, createContext, ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import { Round } from 'functions/src/types/firestore/Game/Tournament/Bracket';

export type MatchDetailsContextType = {
  rounds: Round<Date>[];
};

export const MatchDetailsContext =
  createContext<MatchDetailsContextType | null>(null);

export const useMatchDetails = (): MatchDetailsContextType => {
  const context = useContext(MatchDetailsContext);
  if (!context) {
    throw new Error(
      'useMatchDetails must be used within a MatchDetailsProvider',
    );
  }
  return context;
};

export const MatchDetailsProvider = memo(function MatchDetailsProvider({
  children,
  rounds,
}: {
  rounds: Round<Date>[];
  children: ReactNode;
}) {
  return (
    <MatchDetailsContext.Provider value={{ rounds }}>
      {children}
    </MatchDetailsContext.Provider>
  );
});
