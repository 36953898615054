import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';

const UnregisterGuardDialogUnmemoized = () => {
  const { go } = useWizard();
  const { gameId, id: tournamentId, foundTeam } = useTournamentRegistration();
  const { id: teamId } = foundTeam!;
  const { uid } = useAuth();
  const unregister = async () => {
    try {
      if (!uid) {
        throw new Error();
      }
      const { exitTeam } = await import(
        '../../../firebaseCloud/tournament/exitTeam'
      );
      await exitTeam({
        gameId,
        userId: uid,
        tournamentId,
        teamId,
      });
      go('UnregisteredDialog');
    } catch (e) {
      go(undefined);
      console.error(e);
    }
  };

  return (
    <DialogBodyStandard
      title="Are You Sure?"
      description={
        <Typography variant="h6">
          This will remove you from the tournament. <br /> You will NOT be able
          to re-register.
        </Typography>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            children: 'Unregister',
            isAsync: true,
            onClick: unregister,
            color: 'error',
          },
          {
            children: 'Cancel',
            isAsync: false,
            onClick: () => {
              return go('EntryDialog');
            },
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const UnregisterGuardDialog = memo(UnregisterGuardDialogUnmemoized);
