import { Token, TokenPriceable } from '../../types/firestore/User/Payout';
import { ToFiat } from './CryptoToFiatConverter';
import { PriceableToken } from './PriceableToken';

export type PayoutFormatter = typeof formatPayout;

const isTokenPriceable = (token: Token): token is TokenPriceable => {
  return token.type === 'native' || token.type === 'ERC20';
};

const TOKEN_TYPE_TO_AMOUNT_KEY = {
  ERC721: 'countIlliquid',
  ERC1155: 'countIlliquid',
  offchain: 'countUnassured',
} as const;

export const formatPayout = (payout: Token[] = [], fiatConverter: ToFiat) => {
  return payout.reduce(
    (acc, token) => {
      if (isTokenPriceable(token)) {
        const tokenPriced = new PriceableToken(token, {
          formatFiat: fiatConverter,
        });
        acc.amountFiat += Number(tokenPriced.amountFiat);
      } else {
        const { type, amount } = token as Token<
          'offchain' | 'ERC721' | 'ERC1155'
        >;
        acc[TOKEN_TYPE_TO_AMOUNT_KEY[type]] += parseInt(amount);
      }
      return acc;
    },
    { amountFiat: 0, countIlliquid: 0, countUnassured: 0 },
  );
};
