import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { functions as firebaseFunctions } from '../config/firebase-client/functions';
import { ExponentialBackoff } from '../../functions/src/util/apis/ExponentialBackoff';

export type DirectHttpsCallable<TParams, TResponse> = (
  ...args: Parameters<HttpsCallable<TParams, TResponse>>
) => Promise<TResponse>;

export const CF_REQUEST_ABORTED =
  'The request was aborted because there was no available instance.';
export const CF_MAX_RETRIES = 3;
export const CF_WAIT_TIME_MS = 1000;

const functionsBackoff = new ExponentialBackoff({
  doRetry: (error) => {
    const errorMessage =
      !!error &&
      typeof error === 'object' &&
      'details' in error &&
      !!error.details
        ? typeof error.details === 'object' && 'textPayload' in error.details
          ? String(error.details.textPayload)
          : String(error.details)
        : String(error);

    return errorMessage.toLowerCase().includes(CF_REQUEST_ABORTED);
  },
  maxRetries: CF_MAX_RETRIES,
  initialWaitTimeMs: CF_WAIT_TIME_MS,
});

export function callableFactory(
  prefix: (functionName: string) => string,
): <TParams, TResponse>(
  functionName: string,
) => DirectHttpsCallable<TParams, Awaited<TResponse>> {
  return <TParams, TResponse>(functionName: string) => {
    const baseCallable = httpsCallable<TParams, Awaited<TResponse>>(
      firebaseFunctions,
      prefix(functionName),
    );

    const retryableCallable = functionsBackoff.withExponentialBackoff()(
      async (params: TParams) => {
        const { data } = await baseCallable(params);
        return data;
      },
    );

    return async (data: TParams) => {
      const { datadogRum } = await import('@datadog/browser-rum');
      const sessionId = datadogRum.getInternalContext()?.session_id;
      const extendedData = { ...data, datadogSessionId: sessionId };

      return retryableCallable(extendedData);
    };
  };
}
