import { memo } from '../../../util/memo';
import { AllSignInMethod } from '../../../../functions/src/types/firestore/User';
import { SelectableRegistrationOption } from './SelectableRegistrationOption';

export type AuthOptionsUnconnectedProps = {
  providers: AllSignInMethod[];
};

export const AuthOptionsUnconnected = memo(
  function AuthOptionsUnconnectedUnmemoized({
    providers,
  }: AuthOptionsUnconnectedProps) {
    return (
      <>
        {providers.map((provider) => {
          return (
            <SelectableRegistrationOption key={provider} provider={provider} />
          );
        })}
      </>
    );
  },
);
