import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ExpandedGradientCheckbox } from '../gradients/ExpandedGradientCheckbox';
import { Link } from '../Link';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { memo } from '../../util/memo';
import { useWizard } from '../wizard/Wizard';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import { AuthFlowStore } from '../../hooks/auth/useAuthFlowBase';

export type AuthenticationAgreementsFormValues = {
  agreedPrivacyPolicy: boolean;
  agreedTermsOfUse: boolean;
  agreed13OrOlder: boolean;
};

export const CHECKBOX_DATA: {
  [prop in keyof AuthenticationAgreementsFormValues]: JSX.Element;
} = {
  agreedPrivacyPolicy: (
    <Typography variant="body2">
      I have read and accepted the privacy policy and allow my personal data to
      be processed by BluMint.
    </Typography>
  ),
  agreedTermsOfUse: (
    <Typography variant="body2">
      I have read and accepted the{' '}
      <Link href="/assets/legal/terms-of-use.pdf" target="_blank">
        Terms of Use
      </Link>
      .
    </Typography>
  ),
  agreed13OrOlder: (
    <Typography variant="body2">I am 13 years or older.</Typography>
  ),
};

const AuthenticationAgreementsUnmemoized: FC = () => {
  const { get } = useWizard<AuthFlowStore>();
  const { setErrorCodeOf } = useAuthSubmit();

  const startingFormValues = {
    agreedTermsOfUse: get('agreedTermsOfUse'),
    agreedPrivacyPolicy: get('agreedPrivacyPolicy'),
    agreed13OrOlder: get('agreed13OrOlder'),
  };
  const [formValues, setFormValues] =
    useState<AuthenticationAgreementsFormValues>(startingFormValues);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (formValues.hasOwnProperty(id))
      setFormValues({ ...formValues, [id]: checked });
  };

  useEffect(() => {
    const allTermsAgreed = Object.values(formValues).every(Boolean);
    const errorCode = !allTermsAgreed ? 'conditions-not-met' : undefined;
    setErrorCodeOf('agreements', errorCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <Stack>
      <FormControl>
        <Stack spacing={2} alignItems={'center'}>
          {Object.entries(CHECKBOX_DATA).map(([id, label]) => {
            return (
              <FormControlLabel
                control={
                  <ExpandedGradientCheckbox
                    checkboxProps={{
                      id,
                      checked: formValues[`${id}`],
                      onChange: onChange,
                    }}
                    color={'primaryHorizontal'}
                  />
                }
                labelPlacement="start"
                label={label}
                sx={{
                  mx: 0,
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
                key={id}
              />
            );
          })}
        </Stack>
      </FormControl>
    </Stack>
  );
};

export const AuthenticationAgreements = memo(
  AuthenticationAgreementsUnmemoized,
);
