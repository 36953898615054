import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GradientButton } from '../../gradients/GradientButton';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { MessageInput } from './MessageInput';
import { useCallback, useEffect, useRef } from 'react';

export const MESSAGE_EDITING_INPUT_ID = 'message-editing-input' as const;

const MessageEditingUnmemoized = () => {
  const { message, clearEditingState } = useMessage();

  const inputRef = useRef<HTMLElement | null>(null);

  const buttonSx = {
    boxShadow: 'none',
    flex: 1,
    fontSize: '14px',
    p: 0,
  };

  const confirmEdit = useCallback(() => {
    const messageInputElement = inputRef.current?.querySelector(
      '.str-chat__message-textarea',
    ) as HTMLTextAreaElement | undefined;

    if (!messageInputElement) {
      return;
    }

    const enterEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
      code: 'Enter',
    });
    messageInputElement.dispatchEvent(enterEvent);

    clearEditingState();
  }, [clearEditingState]);

  useEffect(() => {
    const messageInputElement = inputRef.current?.querySelector(
      '.str-chat__message-textarea',
    ) as HTMLTextAreaElement | undefined;

    if (!messageInputElement) {
      return;
    }

    messageInputElement.setSelectionRange(
      messageInputElement.value.length,
      messageInputElement.value.length,
    );
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="flex-end"
      width="100%"
      pt={4}
      pb={2}
      sx={{
        '.str-chat__message-input': {
          background: 'transparent !important',
          pb: '0px !important',
        },
      }}
    >
      <Box ref={inputRef} sx={{ width: '100%' }}>
        <MessageInput
          id={MESSAGE_EDITING_INPUT_ID}
          grow={true}
          message={message}
          clearEditingState={clearEditingState}
        />
      </Box>
      <Stack direction="row" pr={4}>
        <Button
          variant="text"
          onClick={clearEditingState}
          sx={{ color: 'text.secondary', ...buttonSx }}
        >
          Cancel
        </Button>
        <GradientButton
          variant="text"
          onClick={confirmEdit}
          sx={{
            ...buttonSx,
            '.MuiTypography-root': {
              fontSize: '14px',
            },
          }}
        >
          Send
        </GradientButton>
      </Stack>
    </Stack>
  );
};

export const MessageEditing = memo(MessageEditingUnmemoized);
