import { useCallback } from 'react';
import {
  AUTH_STORE_DEFAULT,
  AuthFlowId,
  BASE_AUTH_DEPENDENCY_IDS,
  useAuthFlowBase,
} from './useAuthFlowBase';

export const ENTRY_DIALOG_DEFAULT = 'Sign In Options';

export type AuthFlowProps = {
  entryDialog?: AuthFlowId;
  onClose?: () => void;
};

export const useAuthFlowDialog = () => {
  const { open: openAuthFlowBase, close, isOpen } = useAuthFlowBase();

  const open = useCallback(
    (props: AuthFlowProps = {}) => {
      const { entryDialog = ENTRY_DIALOG_DEFAULT, onClose } = props;
      const elementIds: AuthFlowId[] = [
        'Sign In Options',
        'Email Sign Up',
        'Reset Password',
        'Reset Password Success',
        'Registration End',
        ...BASE_AUTH_DEPENDENCY_IDS,
      ];
      openAuthFlowBase({
        elementIds,
        storeDefault: AUTH_STORE_DEFAULT,
        elementIdEntry: entryDialog,
        onClose,
      });
    },
    [openAuthFlowBase],
  );

  return { open, close, isOpen };
};
