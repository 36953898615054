import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { memo } from '../../../../util/memo';
import { useReferee } from 'src/contexts/RefereeContext';
import { useLeaderboardDetails } from 'src/hooks/referee/useLeaderboardDetails';
import { DetailsTeam } from './DetailsTeam';
import { DetailsSolo } from './DetailsSolo';
import { LottieLoader } from 'src/components/LottieLoader';

export type DetailsProps = {
  userIds: string[];
  captainId: string;
};

export const DetailsUnmemoized = ({ userIds, captainId }: DetailsProps) => {
  const { detailsOptions } = useReferee();
  const [expanded, setExpanded] = useState<string | null>(captainId);
  const { details, summaries, isLoading } = useLeaderboardDetails({
    userIds,
  });
  const shouldDisplayDetails = details && details?.length > 0;
  const shouldDisplayDetailsTeam =
    details && details?.length > 1 && !detailsOptions?.captainOnly;

  return (
    <Stack direction="column">
      {isLoading ? (
        <LottieLoader sx={{ height: '24px', width: '24px' }} />
      ) : shouldDisplayDetails ? (
        shouldDisplayDetailsTeam ? (
          <DetailsTeam
            details={details}
            summaries={summaries}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ) : (
          <DetailsSolo details={details[0]} summary={summaries[0]} />
        )
      ) : null}
    </Stack>
  );
};

// Prevent re-renders when results update - default array comparison is unsufficient
// We are confident that both userIds & captainId are immutable once the Result doc
// has been created
export const Details = memo(DetailsUnmemoized, (prevProps, nextProps) => {
  return (
    prevProps.userIds.length === nextProps.userIds.length &&
    prevProps.captainId === nextProps.captainId
  );
});
