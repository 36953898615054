import { memo } from '../../../util/memo';
import Button from '@mui/material/Button';
import { AlertStandard } from '../../AlertStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useRegisterDialogs } from './dialogs/useRegisterDialogs';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import Stack from '@mui/material/Stack';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';

export const REGISTER_SOLO_TITLE = 'REGISTER SOLO';
export const REGISTER_SOLO_DESCRIPTION =
  'You’ll be placed on an incomplete team or a team of all solo players at the end of check-in.';

function RegisterSoloPanelUnmemoized() {
  const { maxTeamSize, skipCheckIn } = useTournamentRegistration();
  const { open: openRegisterDialog, isOpen } = useRegisterDialogs();
  const { checkRegistrationPermission } = useRegistrationFunctions();

  const canRegister = checkRegistrationPermission();

  return (
    <TournamentPanelV3
      title={REGISTER_SOLO_TITLE}
      description={maxTeamSize > 1 ? REGISTER_SOLO_DESCRIPTION : undefined}
      wrapperSx={{ p: 4, maxWidth: MAX_TOURNAMENT_PANE_WIDTH }}
      Content={
        <Stack gap={2}>
          {!skipCheckIn && (
            <AlertStandard
              severity="warning"
              message={
                'You will still need to check in during the check-in window.'
              }
            />
          )}

          <Button
            onClick={() => {
              if (isOpen) {
                return;
              }
              openRegisterDialog({
                registrationType: 'solo',
              });
            }}
            variant="contained"
            sx={{ width: '100%', mt: 2 }}
            disabled={!canRegister}
          >
            REGISTER SOLO
          </Button>
        </Stack>
      }
    />
  );
}

export const RegisterSoloPanel = memo(RegisterSoloPanelUnmemoized);
