import { useState, useEffect } from 'react';
import { Membership } from '../../../functions/src/types/firestore/Membership';
import { useAuth } from '../../contexts/AuthContext';
import { useDocSnapshot } from '../firestore/useDocSnapshot';
import { useGroupBasic } from './useGroupBasic';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { Subgroup } from '../../../functions/src/types/firestore/Guild/Subgroup';

const fetchSubgroupDoc = async (
  groupType: string,
  groupId: string,
  subgroupId: string,
) => {
  const { firestore } = await import('../../config/firebase-client/firestore');
  const { doc, getDoc } = await import('firebase/firestore');
  const path = `${groupType}/${groupId}/Subgroup/${groupId}-${subgroupId}`;
  const docRef = doc(firestore, path);
  const docSnap = await getDoc(
    docRef.withConverter<Subgroup>(ConverterFactory.buildDateConverter()),
  );

  return docSnap.exists() ? docSnap.data() : null;
};

export const useSubgroupPermissions = () => {
  const { user } = useAuth();
  const userId = user?.uid;
  const { groupId, groupType } = useGroupBasic();
  const path = `Membership/${groupType}-${groupId}-${userId}`;
  const membershipDoc = useDocSnapshot<Membership>(path);

  const [subgroupPermissions, setSubgroupPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (
        !membershipDoc ||
        !membershipDoc.subgroupDetails ||
        !groupType ||
        !groupId
      ) {
        setSubgroupPermissions([]);
        return;
      }

      const subgroupPromises = membershipDoc.subgroupDetails.map((subgroup) => {
        return fetchSubgroupDoc(groupType, groupId, subgroup.username);
      });

      const subgroupDocs = await Promise.all(subgroupPromises);
      const allPermissions = subgroupDocs.flatMap((doc) => {
        return doc?.permissions || [];
      });
      setSubgroupPermissions(Array.from(new Set(allPermissions)));
    };

    fetchPermissions();
  }, [membershipDoc, groupType, groupId]);

  return subgroupPermissions;
};
