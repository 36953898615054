import Chip from '@mui/material/Chip';
import { FC } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { Link, LinkProps } from '../../Link';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Stack from '@mui/material/Stack';

export type ViewMoreChipProps = LinkProps;
const ViewMoreChipUnmemoized: FC<ViewMoreChipProps> = (props) => {
  const theme = useTheme();
  return (
    <Link style={{ textDecoration: 'none' }} {...props}>
      <Chip
        sx={{
          backgroundColor: theme.palette.other.filledInputBackground,
          borderRadius: '100px',
          height: '40px',
        }}
        label={
          <Stack direction="row">
            <KeyboardArrowRightIcon
              sx={{ ml: -2, color: theme.palette.disabled.main }}
            />
            <Typography variant="subtitle1" color={'disabled.main'}>
              VIEW MORE
            </Typography>
          </Stack>
        }
        clickable
      />
    </Link>
  );
};

export const ViewMoreChip = memo(ViewMoreChipUnmemoized);
