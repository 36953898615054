import { TabRouted } from '../components/routing/TabsRouted';

export function normalizeTab(tab: TabRouted<string>) {
  if (typeof tab === 'string') {
    return {
      id: `${tab}-tab`,
      value: tab,
      onClick: undefined,
      customization: {},
      sx: {},
      Wrapper: undefined,
    };
  }

  const { value, onClick, customization, Wrapper } = tab;
  const { sx = {}, onClick: _, ...restCustomization } = customization || {};
  return {
    id: `${value}-tab`,
    value,
    onClick,
    customization: restCustomization,
    sx,
    Wrapper,
  };
}
