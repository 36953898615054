import { memo } from '../../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../../dialog/DialogActionsStandard';
import Stack from '@mui/material/Stack';
import { useTournamentRegistrationForm } from '../../../../hooks/tournaments/useTournamentRegistrationForm';
import { REGISTRATION_AD } from '../../../../../functions/src/util/ads/adIds';
import { Ad } from '../../../ads/Ad';
import { LARGE_RECTANGLE } from '../../../../../functions/src/util/ads/AdDimension';

const GameRegisterDialogUnmemoized = () => {
  const { FormFields, submitForm, isFormSubmittable } =
    useTournamentRegistrationForm();

  return (
    <DialogBodyStandard
      title="Registration Details"
      description={
        <Stack spacing={6} alignItems={'center'}>
          {FormFields}
          <Ad id={REGISTRATION_AD} {...LARGE_RECTANGLE} />
        </Stack>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            isAsync: false,
            onClick: submitForm,
            sx: { flex: 2 },
            children: 'Confirm',
            disabled: !isFormSubmittable,
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const GameRegisterDialog = memo(GameRegisterDialogUnmemoized);
