import { useCallback } from 'react';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { useOAuthCustom } from './useOAuthCustom';
import {
  CustomSignInMethod,
  GenericSignInMethod,
} from '../../../functions/src/types/firestore/User';
import { isCustomOAuthProvider } from '../../../functions/src/util/auth/isCustomOAuthProvider';

export const useOAuthSignIn = () => {
  const { connectOAuthCustom } = useOAuthCustom();
  const { signInOAuthGeneric } = useAuthSubmit();
  const signInOAuth = useCallback(
    async (provider: GenericSignInMethod | CustomSignInMethod) => {
      if (isCustomOAuthProvider(provider)) {
        await connectOAuthCustom(provider);
        return;
      }
      await signInOAuthGeneric(provider);
    },
    [connectOAuthCustom, signInOAuthGeneric],
  );
  return { signInOAuth };
};
