import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { GradientIcon } from './gradients/GradientIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { SxProps } from '@mui/material/styles';
import { useState } from 'react';
import { memo } from '../util/memo';
import { LottieLoader } from './LottieLoader';
import { PromiseOrValue } from '../../functions/src/types/utility-types';

export const MENU_MAX_WIDTH = 400 as const;

export type MenuOptionProps = {
  label: string;
  IconComponent: OverridableComponent<SvgIconTypeMap<SxProps & object, 'svg'>>;
  gradientColor?: string;
  onActionComplete?: () => void;
  onClick: (
    event?: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => PromiseOrValue<void>;
  sx?: SxProps;
};

const MenuOptionUnmemoized = ({
  label,
  onClick,
  IconComponent,
  onActionComplete,
  gradientColor = 'primary.horizontal',
  sx = { justifyContent: 'space-between' },
}: MenuOptionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const performAction = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    setIsLoading(true);
    await onClick(event);
    onActionComplete?.();
    setIsLoading(false);
  };

  return (
    <MenuItem onClick={performAction} sx={{ maxWidth: MENU_MAX_WIDTH }}>
      <Stack
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
        sx={sx}
      >
        <Typography variant="subtitle2" color="text.primary">
          {label}
        </Typography>
        {isLoading ? (
          <LottieLoader sx={{ height: '24px', width: '20px' }} />
        ) : (
          <GradientIcon
            IconComponent={IconComponent}
            gradientColor={gradientColor}
            sx={{
              height: '20px',
              width: '20px',
            }}
          />
        )}
      </Stack>
    </MenuItem>
  );
};

export const MenuOption = memo(MenuOptionUnmemoized);
