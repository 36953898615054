import {
  OAuthSignInMethod,
  O_AUTH_SIGN_IN_METHODS,
} from '../../types/firestore/User';

export const isOAuthProvider = (
  providerId: string,
): providerId is OAuthSignInMethod => {
  return O_AUTH_SIGN_IN_METHODS.some((method) => {
    return providerId.includes(method);
  });
};
