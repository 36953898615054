import {
  cloneElement,
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type GlobalComponentsContextType = {
  union: (id: string, component: ReactElement) => void;
  remove: (id: string) => void;
};

const defaultState: GlobalComponentsContextType = {
  union: () => {
    /* */
  },
  remove: () => {
    /* */
  },
};

const GlobalComponentsContext = createContext(defaultState);

// export function useGlobalComponentsContext(...contexts: Context<unknown>[]) {
//   const { union: unionUnprovided, remove } = useContext(
//     GlobalComponentsContext,
//   );

//   const contextValues = contexts.map(useContext);

//   const union = useCallback(
//     (id: string, component: ReactElement) => {
//       if (contexts.length === 0) {
//         return unionUnprovided(id, component);
//       }

//       const componentProvided = contexts.reduce((acc, context, index) => {
//         const value = contextValues[Number(index)];
//         return <context.Provider value={value}>{acc}</context.Provider>;
//       }, component);
//       unionUnprovided(id, componentProvided);
//     },
//     [contextValues, contexts, unionUnprovided],
//   );

//   return { union, remove };
// }

export function useGlobalComponentsContext() {
  return useContext(GlobalComponentsContext);
}

export type GlobalComponentsProviderProps = {
  children: ReactElement;
};

export function GlobalComponentsUnmemoized({
  components,
}: {
  components: { [key: string]: ReactElement };
}) {
  return (
    <>
      {Object.entries(components).map(([id, component]) => {
        return cloneElement(component, { key: id });
      })}
    </>
  );
}

export const GlobalComponents = memo(GlobalComponentsUnmemoized);

export const GlobalComponentsProvider = memo(function GlobalComponentsProvider({
  children,
}: GlobalComponentsProviderProps) {
  const [components, setComponents] = useState<{ [key: string]: ReactElement }>(
    {},
  );

  const union = useCallback((id: string, component: ReactElement) => {
    setComponents((prevComponents) => {
      if (prevComponents[String(id)]) {
        return prevComponents;
      }
      return { ...prevComponents, [id]: component };
    });
  }, []);

  const remove = useCallback((id: string) => {
    setComponents((prevComponents) => {
      if (!prevComponents[String(id)]) {
        return prevComponents;
      }
      const { [id]: removed, ...rest } = prevComponents;
      return rest;
    });
  }, []);

  const value = useMemo(() => {
    return { union, remove };
  }, [union, remove]);

  return (
    <GlobalComponentsContext.Provider value={value}>
      {children}
      <GlobalComponents components={components} />
    </GlobalComponentsContext.Provider>
  );
});
