import { useWizardDialog } from 'src/hooks/wizard/useWizardDialog';
import { GameRegisterDialog } from './GameRegisterDialog';
import { RulesRegisterDialog } from './RulesRegisterDialog';
import { useAuth } from 'src/contexts/AuthContext';
import { useGuardSignIn } from 'src/hooks/useGuardSignIn';
import {
  TournamentRegistrationProvider,
  useTournamentRegistration,
} from '../../../../contexts/TournamentRegistrationContext';
import { useEffect } from 'react';
import {
  AUTH_STORE_DEFAULT,
  AuthFlowStore,
} from '../../../../hooks/auth/useAuthFlowBase';
import { AuthenticationFlowCompleteDialog } from '../../../authentication/flow-dialogs/AuthenticationFlowCompleteDialog';
import { AuthenticationEmailSignInDialog } from '../../../authentication/flow-dialogs/AuthenticationEmailSignInDialog';
import { AuthenticationEmailSignUpDialog } from '../../../authentication/flow-dialogs/AuthenticationEmailSignUpDialog';
import { AuthenticationEnterPhoneDialog } from '../../../authentication/flow-dialogs/AuthenticationEnterPhoneDialog';
import { AuthenticationEnterConfirmationCodeDialog } from '../../../authentication/flow-dialogs/AuthenticationEnterConfirmationCodeDialog';
import { AuthSubmitProvider } from '../../../../contexts/AuthSubmitContext';
import { AuthenticationEnterEmailDialog } from '../../../authentication/flow-dialogs/AuthenticationEnterEmailDialog';

export const REGISTRATION_DIALOG_WIDTH = '700px';
export type UseRegisterDialogsParams = {
  registrationType: 'team' | 'solo';
  teamId?: string;
  onCancel?: () => void | Promise<void>;
  onSubmit?: () => void | Promise<void>;
};

export type RegisterStore = {
  details: Record<string, unknown>;
} & AuthFlowStore;

export function useRegisterDialogs() {
  const { open: openWizardDialog, close, isOpen } = useWizardDialog();
  const { openSignInGuard } = useGuardSignIn();
  const { uid } = useAuth();
  const tournament = useTournamentRegistration();
  const { isAcceptedAnyTeam, registrationOptions } = tournament;

  useEffect(() => {
    if (!uid || isAcceptedAnyTeam(uid)) {
      close();
    }
  }, [uid, isAcceptedAnyTeam, close]);

  const open = ({
    onCancel,
    teamId,
    onSubmit,
    registrationType,
  }: UseRegisterDialogsParams) => {
    const storeDefault: RegisterStore = {
      details: {},
      ...AUTH_STORE_DEFAULT,
    };
    const openRegisterDialog = () => {
      openWizardDialog({
        storeDefault,
        elements: {
          'Enter Details': <GameRegisterDialog />,
          'Rules And Regulations': (
            <RulesRegisterDialog
              onCancel={onCancel}
              teamId={teamId}
              registrationType={registrationType}
              onSubmit={onSubmit}
            />
          ),
          'Link Successful': (
            <AuthenticationFlowCompleteDialog
              title="NEW SIGN IN METHOD ADDED!"
              description="You have successfully added a new sign in method to your account."
              buttonText="Continue"
              next="Enter Details"
            />
          ),
          'Email Sign In': <AuthenticationEmailSignInDialog />,
          'Link Email': (
            <AuthenticationEmailSignUpDialog
              title="Link Email"
              description="This email is new! Link now?"
            />
          ),
          'Enter Email': <AuthenticationEnterEmailDialog />,
          'Enter Phone Number': <AuthenticationEnterPhoneDialog />,
          'Enter Confirmation Code': (
            <AuthenticationEnterConfirmationCodeDialog />
          ),
        },
        elementIdEntry: !!registrationOptions.length
          ? 'Enter Details'
          : 'Rules And Regulations',
        Wrapper: (
          <AuthSubmitProvider>
            <TournamentRegistrationProvider
              tournament={tournament}
            ></TournamentRegistrationProvider>
          </AuthSubmitProvider>
        ),
        wrapperProps: {
          showCloseIcon: false,
          PaperProps: { width: REGISTRATION_DIALOG_WIDTH },
        },
      });
    };
    if (!isOpen) {
      if (!uid) {
        openSignInGuard(
          `Register ${
            registrationType === 'team' ? 'a' : ''
          } ${registrationType}?`,
        );
      } else {
        openRegisterDialog();
      }
    }
  };

  return { open, close, isOpen };
}
