import {
  NULL_ADDRESS_EVM,
  TokenPriceable,
} from '../../types/firestore/User/Payout';
import { PriceCrypto } from './CryptoToFiatConverter';
import { HttpsError } from '../errors/HttpsError';
import {
  CompositeTokenId,
  PRICEABLE_TOKENS_DETAILS_MAP,
  TokenDetails,
} from './priceableTokenDetails';
import { CryptoChain } from '../web3/CryptoChain';
import { PriceablePrizePoolToken } from '../../types/firestore/PrizePool';

export type PriceFormatters = {
  formatFiat: (price: PriceCrypto) => string | number;
};

const BASE_ICONS_URL = '/assets/icons/';

export type EvmAddress = `0x${string}`;
export class PriceableToken<
  TUserAddress extends Lowercase<EvmAddress> | undefined = undefined,
> {
  public compositeId: CompositeTokenId;
  private tokenDetails: TokenDetails;
  constructor(
    public token: TokenPriceable,
    private formatters: PriceFormatters,
    public userAddress?: TUserAddress,
  ) {
    this.compositeId = `${this.chainId}-${
      this.address.toLowerCase() as Lowercase<EvmAddress>
    }`;
    const details = PRICEABLE_TOKENS_DETAILS_MAP[this.compositeId];
    if (!details) {
      throw new HttpsError(
        'failed-precondition',
        `PriceableToken not implemented for this token`,
        `Token composite id: ${this.compositeId}`,
      );
    }
    this.tokenDetails = details;
  }

  public get chainId() {
    return this.token.chainId;
  }

  public get address() {
    return this.token.address;
  }

  public static wrappedToNative(
    compositeId: CompositeTokenId,
  ): CompositeTokenId {
    const chainId = compositeId.split('-')[0] as `${number}`;
    return `${chainId}-${NULL_ADDRESS_EVM}`;
  }

  public get amountFiatAndCrypto() {
    return `${this.amountCrypto} (${this.amountFiat})`;
  }

  public get amountCrypto() {
    const amountEther = Number(this.amountWei) / 1e18;
    const formatter = new Intl.NumberFormat(
      typeof window !== 'undefined' ? navigator?.language : undefined,
      {
        minimumSignificantDigits: 2,
        maximumSignificantDigits: 2,
      },
    );
    return `${formatter.format(amountEther)} ${this.tokenDetails.displayName}`;
  }

  public get amountWei() {
    return this.token.amount;
  }

  public get amountFiat() {
    return this.formatters.formatFiat(this.priceCrypto);
  }

  private get priceCrypto(): PriceCrypto {
    return {
      cryptocurrency: this.compositeId,
      wei: this.amountWei,
      ...this.tokenDetails.priceMetadata,
    };
  }

  public get iconUrl() {
    return BASE_ICONS_URL + this.tokenDetails.iconUrlSuffix;
  }

  public get symbol() {
    return this.tokenDetails.displayName;
  }

  public get fullName() {
    return this.tokenDetails.fullName;
  }

  public get chainName() {
    return CryptoChain.from(this.chainId)?.name;
  }

  public toPrizePoolToken({
    contributor,
    amount,
  }: {
    contributor: PriceablePrizePoolToken['contributor'];
    amount?: string;
  }): PriceablePrizePoolToken {
    return {
      ...this.token,
      amount: amount || this.token.amount,
      chainId: this.chainId,
      contributor,
    };
  }
}
