import { useSnackbarCountdown } from './useSnackbarCountdown';
import { useEffect, useCallback } from 'react';
import { useVoiceChannel } from './useVoiceChannel';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { FieldValue } from 'firebase/firestore';

export const CHANNEL_MOVER_SNACKBAR_ID = 'channel-mover';
export const CHANNEL_MOVER_MESSAGE = '🚨 MOVING CHANNELS 🚨';
export const CHANNEL_MOVER_COUNTDOWN_MS = 10000;

export const useChannelMover = () => {
  const theme = useTheme();
  const { voiceChannel, setVoiceChannel } = useVoiceChannel();
  const { openChannelGroup } = useActiveChannelGroup();
  const { open, close } = useSnackbarCountdown(CHANNEL_MOVER_SNACKBAR_ID);

  const joinChannelWithVoice = useCallback(async () => {
    const { next } = voiceChannel || {};

    if (!next || next instanceof FieldValue) {
      return;
    }

    const [channelGroupId, channelType, channelId] = (next as string).split(
      '-',
    );

    setVoiceChannel({ current: next });

    openChannelGroup(channelGroupId, `${channelType}:${channelId}`);
  }, [voiceChannel, openChannelGroup, setVoiceChannel]);

  useEffect(() => {
    const { current, next } = voiceChannel || {};

    if (!next || (!!next && current === next)) {
      return;
    }

    open({
      onComplete: joinChannelWithVoice,
      message: CHANNEL_MOVER_MESSAGE,
      countdownMs: CHANNEL_MOVER_COUNTDOWN_MS,
      action: (
        <Button
          variant="text"
          onClick={close}
          sx={{
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: '10px',
            color: theme.palette.text.primary,
            ':hover': {
              background: theme.palette.other.filledInputBackground,
            },
          }}
        >
          Cancel
        </Button>
      ),
    });
  }, [close, joinChannelWithVoice, open, voiceChannel?.next]);
};
