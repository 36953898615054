import { useCallback, useMemo } from 'react';
import { InteractionTrackingData } from './useClickTracking';
import { useEngagement } from './useEngagement';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import {
  StopwatchCallbackMap,
  useVisibilityStopwatch,
} from '../visibility/useVisibilityStopwatch';
import { trackEvent } from '../../util/vercel/trackEvent';

export const MIN_IMPRESSION_MS = 2000 as const;

const VISIBILITY_THRESHOLD = 0.5 as const;

export const useImpressionTracking = <
  TDataCustom extends Record<string, string>,
>(
  data: InteractionTrackingData & TDataCustom,
  target: HTMLElement | null,
  callbacks?: StopwatchCallbackMap,
  eventName = 'impression',
) => {
  const isEngaged = useEngagement();
  const stopwatchOptions = useMemo(() => {
    return {
      pauseManually: !isEngaged,
      threshold: VISIBILITY_THRESHOLD,
    } as const;
  }, [isEngaged]);

  const trackImpression = useCallback(() => {
    trackEvent(eventName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedHash(data)]);

  const stopwatchCallbackMap = useMemo(() => {
    return {
      [MIN_IMPRESSION_MS]: trackImpression,
      ...(callbacks || {}),
    };
  }, [callbacks, trackImpression]);

  return useVisibilityStopwatch(target, stopwatchOptions, stopwatchCallbackMap);
};
