import { useMemo } from 'react';
import { useIndexingHits } from '../../contexts/algolia/IndexingHitsContext';
import { toRealtimeTemplateHash } from '../../../functions/src/util/algoliaRealtime/toRealtimeTemplateHash';
import { Hit } from '../../../functions/src/types/Hit';

export const useMergeIndexingHits = (
  hitsConverted: Hit[],
  filters?: string,
) => {
  const templateFilledHash = toRealtimeTemplateHash(filters);

  const { indexingHits } = useIndexingHits();

  return useMemo(() => {
    const hitsCoalesced = [...hitsConverted];
    if (!!templateFilledHash && !!indexingHits?.[String(templateFilledHash)]) {
      const { hitsBeingAdded, hitsBeingRemoved } =
        indexingHits[String(templateFilledHash)];
      if (hitsBeingRemoved.length > 0) {
        hitsBeingRemoved.forEach((hitToRemove) => {
          const index = hitsCoalesced.findIndex((hit) => {
            return hit.objectID === hitToRemove;
          });
          if (index !== -1) {
            hitsCoalesced.splice(index, 1);
          }
        });
      }

      if (Object.keys(hitsBeingAdded).length > 0) {
        Object.values(hitsBeingAdded).forEach((hitToAdd: Hit) => {
          const index = hitsCoalesced.findIndex((hit) => {
            return hit.objectID === hitToAdd.objectID;
          });
          if (index === -1) {
            hitsCoalesced.push(hitToAdd);
          } else {
            hitsCoalesced[Number(index)] = hitToAdd;
          }
        });
      }
    }

    return hitsCoalesced;
  }, [templateFilledHash, indexingHits, hitsConverted]);
};
