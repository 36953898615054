import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../gradients/GradientTypography';
import { useDisplayedRank } from '../../hooks/useDisplayedRank';
import { PayoutSummary } from '../tournaments/PayoutSummary';
import { PayoutFormatted } from '../../../functions/src/types/firestore/Game/Competition';

export type PayoutRowHeaderProps = {
  ranks: number[];
} & PayoutFormatted;

export const PayoutRowHeader = memo(function PayoutRowHeaderUnmemoized({
  ranks,
  ...payoutFormatted
}: PayoutRowHeaderProps) {
  const [rankLower, rankUpper] = ranks;
  const rankLowerDisplayed = useDisplayedRank({ rank: rankLower });
  const rankUpperDisplayed = useDisplayedRank({ rank: rankUpper - 1 });
  return (
    <Stack direction="row" justifyContent="space-between" mb={4}>
      {rankLower === rankUpper ? (
        <GradientTypography
          gradientColor="primary.vertical"
          textTransform="uppercase"
          variant="h5"
        >
          {rankLowerDisplayed}
        </GradientTypography>
      ) : (
        <Stack>
          <GradientTypography
            gradientColor="primary.vertical"
            textTransform="uppercase"
            variant="h5"
          >
            {rankLowerDisplayed} - {rankUpperDisplayed}
          </GradientTypography>
        </Stack>
      )}

      <PayoutSummary showIcons {...payoutFormatted} />
    </Stack>
  );
});
