export const resetPasswordWith = async (
  password: string,
  actionCode: string,
) => {
  const authImport = import('../../config/firebase-client/auth');
  const { auth } = await authImport;

  const firebaseAuthImport = import('firebase/auth');
  const { confirmPasswordReset } = await firebaseAuthImport;
  await confirmPasswordReset(auth, actionCode, password);
};
