import { useCallback } from 'react';
import {
  AUTH_STORE_DEFAULT,
  AuthFlowId,
  BASE_AUTH_DEPENDENCY_IDS,
  useAuthFlowBase,
} from './useAuthFlowBase';

export const LINK_DIALOG_START = 'Link More Methods';

export type LinkAdditionalAuthProps = {
  entryDialog?: AuthFlowId;
  onClose?: () => void;
};

export const useLinkAdditionalAuthDialog = () => {
  const { open: openAuthFlowBase, close } = useAuthFlowBase();

  const open = useCallback(
    (props: LinkAdditionalAuthProps = {}) => {
      const { entryDialog = LINK_DIALOG_START, onClose } = props;
      const elementIds: AuthFlowId[] = [
        'Link More Methods',
        'Link Email',
        'Link Successful',
        'Re-Authenticate',
        ...BASE_AUTH_DEPENDENCY_IDS,
      ];
      openAuthFlowBase({
        storeDefault: AUTH_STORE_DEFAULT,
        elementIds,
        elementIdEntry: entryDialog,
        onClose,
      });
    },
    [openAuthFlowBase],
  );

  return { open, close };
};
