import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';
import { useRouter as originalUseRouter } from 'next/router';
import { segmentsOf } from '../../util/routing/segmentsOf';
import { toSegmentKey } from '../../util/routing/toSegmentKey';
import { useQueue } from '../../hooks/useQueue';
import { useRouterModifyOptimistic } from '../../hooks/routing/useRouterModifyOptimistic';

export type LocationType = 'queryParam' | 'segment';

export type UrlModification = {
  location: LocationType;
  name: string;
  value?: string;
  silent?: boolean;
};

export type UrlModificationsContextType = {
  appendModifications: (modifications: UrlModification[]) => void;
};

const UrlModificationsContext = createContext<UrlModificationsContextType>(
  null as unknown as UrlModificationsContextType,
);

const UrlModificationsProviderUnmemoized: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const router = originalUseRouter();
  const {
    asPath,
    replace: replaceOriginal,
    pathname: pathnameOriginal,
  } = router;

  const [urlModifications, setUrlModifications] = useState<UrlModification[]>(
    [],
  );

  const replaceBatch = useCallback(
    async (modifications: UrlModification[]) => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const keys = segmentsOf(pathnameOriginal);
      const values = segmentsOf(asPath);

      const allSilent = modifications.reduce(
        // eslint-disable-next-line no-shadow
        (allSilent, { location, name, value, silent = false }) => {
          if (location === 'queryParam') {
            if (value === undefined || value === '') {
              params.delete(name);
            } else {
              params.set(name, value);
            }
          } else if (location === 'segment') {
            const keyIndex = keys.indexOf(toSegmentKey(name));
            if (keyIndex !== -1 && keyIndex < keys.length) {
              if (value !== undefined) {
                values[Number(keyIndex)] = encodeURIComponent(value);
              } else {
                values.splice(keyIndex, 2);
              }
            } else if (value !== undefined) {
              values.push(name, encodeURIComponent(value));
            }
          }
          return silent && allSilent;
        },
        true,
      );

      if (allSilent) {
        window.history.replaceState({}, '', `${url.pathname}?${params}`);
        return;
      }

      await replaceOriginal(values.join('/'), `${url.pathname}?${params}`, {
        shallow: true,
      });
    },
    [pathnameOriginal, asPath, replaceOriginal],
  );

  const { enqueue } = useQueue();
  useEffect(() => {
    if (urlModifications.length > 0) {
      const task = () => {
        return replaceBatch(urlModifications);
      };
      enqueue(task);
      setUrlModifications([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceBatch, urlModifications]);

  const modifyOptimistic = useRouterModifyOptimistic();
  const appendModifications = useCallback(
    (modifications: UrlModification[]) => {
      setUrlModifications((current) => {
        return [...current, ...modifications];
      });
      modifyOptimistic(...modifications);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useMemo(() => {
    return (
      <UrlModificationsContext.Provider value={{ appendModifications }}>
        {children}
      </UrlModificationsContext.Provider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);
};

export const UrlModificationsProvider = memo(
  UrlModificationsProviderUnmemoized,
);

export const useAppendModifications = () => {
  const context = useContext(UrlModificationsContext);
  if (!context) {
    throw new Error(
      'useUrlModifications must be used within a UrlModificationsProvider',
    );
  }
  return useMemo(() => {
    return context.appendModifications;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
