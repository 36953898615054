import { useMatch } from '../components/tournaments/match/MatchProvider';
import {
  MatchCompetitor,
  MatchCompetitorProps,
} from '../components/tournaments/match/MatchCompetitor';
import { useMemo } from 'react';
import { matchCompetitorsOf } from '../util/tournaments/matchCompetitorsOf';

export type UseMatchCompetitorsOptions = {
  CompetitorComponent?: React.ComponentType<
    MatchCompetitorProps & { index?: number }
  >;
};

export const useMatchCompetitors = ({
  CompetitorComponent = MatchCompetitor,
}: UseMatchCompetitorsOptions = {}) => {
  const { team1Score, team2Score, winner, team1, team2 } = useMatch();
  const competitors = matchCompetitorsOf({
    team1Score,
    team2Score,
    winner,
    team1,
    team2,
  });

  const MatchCompetitors = useMemo(() => {
    return competitors.map((props, index) => {
      const { competitor } = props;
      return (
        <CompetitorComponent
          {...props}
          index={index}
          key={`${competitor}-${index}`}
        />
      );
    });
  }, [CompetitorComponent, competitors]);
  return { MatchCompetitors };
};
