//adapted from https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed

export function shuffleSeeded<T>(arr: T[], seed: string) {
  const { shuffle } = randomSeeded<T>(seed);
  shuffle(arr);
  return arr;
}

function randomSeeded<T>(seed: string) {
  const rng = mulberry32(cyrb128(seed)[0]!);

  const rnd = (min: number, max?: number, defaultMax?: number) => {
    if (max === undefined) {
      max = min === undefined ? defaultMax || 1 : min;
      min = 0;
    }

    return rng() * (max - min) + min;
  };

  const rndInt = (min: number, max?: number) => Math.floor(rnd(min, max, 2));

  function shuffle(a: T[]) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = rndInt(i + 1);
      const x = a[Number(i)];
      const y = a[Number(j)];
      if (x === undefined || y === undefined) {
        return;
      }
      a[Number(i)] = y;
      a[Number(j)] = x;
    }
  }

  return { rnd, rndInt, shuffle };
}

function cyrb128(str: string) {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [
    (h1 ^ h2 ^ h3 ^ h4) >>> 0,
    (h2 ^ h1) >>> 0,
    (h3 ^ h1) >>> 0,
    (h4 ^ h1) >>> 0,
  ];
}

function mulberry32(a: number) {
  return function () {
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}
