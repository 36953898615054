import { RecaptchaParameters } from 'firebase/auth';

export const initRecaptchaVerifier = async (
  params: RecaptchaParameters = {},
) => {
  const { auth } = await import('../../config/firebase-client/auth');
  const { RecaptchaVerifier } = await import('firebase/auth');

  const verifier = new RecaptchaVerifier(auth, 'recaptcha', params || {});
  return verifier;
};
