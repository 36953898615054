import { memo } from '../../../util/memo';
import Stack from '@mui/system/Stack';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { useRemoveFriendDialog } from '../../../hooks/friends/useRemoveFriendDialog';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendListItemLayout } from './FriendListItemLayout';

const FriendCardEditUnmemoized = ({ ...hit }) => {
  const { uid } = useAuth();
  const { sender, receiver } = hit;
  const friendToRender = sender.id === uid ? receiver : sender;
  const { username, imgUrl, userId, status } = friendToRender;
  const { open: openRemoveFriendDialog } = useRemoveFriendDialog({
    imgUrl,
    username,
    userId,
  });

  return (
    <FriendListItemLayout {...{ username, imgUrl, userId, status }}>
      <Stack direction="row">
        <GradientIconButton
          IconComponent={HighlightOffIcon}
          onClick={() => {
            return openRemoveFriendDialog();
          }}
        />
      </Stack>
    </FriendListItemLayout>
  );
};

export const FriendCardEdit = memo(FriendCardEditUnmemoized);
