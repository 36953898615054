/* eslint-disable security/detect-object-injection */
import { PrizePoolToken } from '../../types/firestore/PrizePool';
import {
  Token,
  TokenTransfer,
  TokenTransferStage,
} from '../../types/firestore/User/Payout';

export const hasSameContract = (existingToken: Token, token: Token) => {
  return (['address', 'identifier', 'chainId'] as const).every((key) => {
    return existingToken[`${key}`] === token[`${key}`];
  });
};
/**
 * @remarks
 * address-identifier-chainId-stage
 */
type TokenKey = `0x${string}-${string}-${string}-${TokenTransferStage}`;
export type TokenAggregated = Token & {
  userIdsContributors: string[];
  stage: TokenTransferStage;
};

export type TokenAggregatedWithContributor = Token & {
  contributor: TokenTransfer;
};

export class TokenAggregator<TToken extends Token = Token | PrizePoolToken> {
  constructor(public tokensUnaggregated: TToken[]) {}

  public get tokensAggregatedWithContributor(): TokenAggregatedWithContributor[] {
    const tokenMap = (
      this.tokensUnaggregated as unknown as PrizePoolToken[]
    ).reduce((acc, token) => {
      const key = `${TokenAggregator.tokenKeyOf(token)}-${
        token.contributor.address
      }`;

      const foundToken = acc?.[key];
      if (
        foundToken &&
        Object.keys(foundToken.contributor).every((key) => {
          return (
            token.contributor[key as keyof PrizePoolToken['contributor']] ===
            foundToken.contributor[key as keyof PrizePoolToken['contributor']]
          );
        })
      ) {
        foundToken.amount = (
          BigInt(foundToken.amount) + BigInt(token.amount)
        ).toString();
      } else {
        acc[key] = {
          ...token,
        };
      }
      return acc;
    }, {} as Record<string, TokenAggregatedWithContributor>);
    return Object.values(tokenMap);
  }

  public get tokensAggregated(): TokenAggregated[] {
    const tokenMap = this.tokensUnaggregated.reduce((acc, token) => {
      const key: TokenKey = TokenAggregator.tokenKeyOf(token);

      const userIdsContributors = TokenAggregator.isPrizePoolToken(token)
        ? [token.contributor.userId]
        : [];
      const foundToken = acc?.[key];
      if (
        foundToken &&
        foundToken.stage ===
          (TokenAggregator.isPrizePoolToken(token)
            ? token.contributor.stage
            : 'uninitiated')
      ) {
        foundToken.amount = (
          BigInt(foundToken.amount) + BigInt(token.amount)
        ).toString();
        foundToken.userIdsContributors = Array.from(
          new Set(foundToken.userIdsContributors.concat(userIdsContributors)),
        );
      } else {
        const { contributor, ...tokenWithoutContributor } =
          token as unknown as PrizePoolToken;
        acc[key] = {
          ...tokenWithoutContributor,
          userIdsContributors,
          stage: TokenAggregator.isPrizePoolToken(token)
            ? token.contributor.stage
            : 'uninitiated',
        };
      }
      return acc;
    }, {} as Record<TokenKey, TokenAggregated>);
    return Object.values(tokenMap);
  }

  private static isPrizePoolToken(
    token: PrizePoolToken | Token,
  ): token is PrizePoolToken {
    return 'contributor' in token;
  }

  public static tokenKeyOf(token: Token) {
    const keyBase = `${token.address}-${token.identifier}-${token.chainId}`;
    return TokenAggregator.isPrizePoolToken(token)
      ? (`${keyBase}-${token.contributor.stage}` as TokenKey)
      : (`${keyBase}-uninitiated` as TokenKey);
  }
}
