import Box from '@mui/material/Box';
import { UniversalAppStatus } from 'src/components/error/UniversalAppStatus';
import { useInAppParking } from '../hooks/useInAppParking';
import Stack from '@mui/material/Stack';
import { ClipboardShareButton } from '../components/ClipboardShareButton';

export const AUTO_REDIRECT = [
  'facebook',
  'telegram',
  'discord',
  'line',
  'instagram',
  'messenger',
];
export const BUTTON_REDIRECT = ['snapchat'];
export const NON_REDIRECT = ['twitter', 'whatsapp', 'tiktok', 'wechat'];

export const SWITCH_BROWSER_TITLE = 'Switch Browser';
export const SWITCH_BROWSER_DESCRIPTION =
  'In order to maximize your experience on this app, kindly open up the site on the default browser of your device.';

function InAppParkingPage() {
  const { browserButton, intentUrl } = useInAppParking();

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100dvh - 190px)',
        mt: 24,
        mx: 16,
        gap: 20,
      }}
    >
      <UniversalAppStatus
        imgUrl="/assets/images/mascots/mascot-building.png"
        title={SWITCH_BROWSER_TITLE}
        description={SWITCH_BROWSER_DESCRIPTION}
        prefetchImage={true}
        showButton={false}
        subText={false}
      >
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          pt={2}
        >
          {browserButton}
          {intentUrl && (
            <ClipboardShareButton value={intentUrl}>
              Copy URL To Clipboard
            </ClipboardShareButton>
          )}
        </Stack>
      </UniversalAppStatus>
    </Box>
  );
}

export { InAppParkingPage as default };
