// useRenderColumns.ts
import { useCallback, FC } from 'react';
import { Required } from 'utility-types';
import { Round } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { ColumnProps, ColumnWrapper } from './ColumnWrapper';

export type RenderColumnsProps<TProps> = {
  rounds: Required<Round<Date>, 'matches'>[];
  ColumnComponent: FC<TProps>;
  hasFinal?: boolean;
};

export type UseRenderColumns<TProps> = (
  props: RenderColumnsProps<TProps>,
) => () => JSX.Element[];

export const useRenderColumns: UseRenderColumns<ColumnProps> = ({
  rounds,
  ColumnComponent,
  hasFinal = false,
}) => {
  return useCallback(() => {
    return rounds.map(({ matches }, indexRound) => {
      return (
        <ColumnWrapper
          key={matches[0].id}
          matches={matches}
          ColumnComponent={ColumnComponent}
          hasFinal={hasFinal && indexRound === rounds.length - 1}
        />
      );
    });
  }, [rounds, ColumnComponent, hasFinal]);
};
