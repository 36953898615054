import { GroupFilter } from '..';

export function getMainUser(groupFilter: GroupFilter) {
  const firstElement = groupFilter[0];
  const secondElement = groupFilter[1];

  if (
    firstElement.type === 'support' ||
    firstElement.type === 'dm' ||
    firstElement.type === 'personalGroup'
  ) {
    return firstElement.members.$eq[0];
  }

  if (firstElement.type === 'group') {
    return secondElement?.members?.$in[0];
  }

  if (groupFilter.length === 2 && groupFilter[1].type === 'match') {
    return groupFilter[1].members?.$in[0];
  }

  return undefined;
}
