import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';

export type PayoutRowLayoutProps = {
  PrizeGrid: ReactNode;
  Header: ReactNode;
  showWarning?: boolean;
  WarningContent?: ReactNode;
};

export const PayoutRowLayout = memo(function PayoutRowLayoutUnmemoized({
  PrizeGrid,
  Header,
  WarningContent,
  showWarning = false,
}: PayoutRowLayoutProps) {
  return (
    <Stack
      width="100%"
      sx={{
        '& .MuiGrid-root': {
          'overflow-y': 'hidden',
        },
      }}
    >
      {Header}
      {PrizeGrid}
      {showWarning && WarningContent}
    </Stack>
  );
});
