import { GridColDef } from '@mui/x-data-grid';
import { ScoreOption } from 'functions/src/types/firestore/Game/Competition';
import { QualificationConditions } from 'functions/src/types/firestore/Game/Tournament';
import { useMemo } from 'react';
import { EditLeaderboardCell } from 'src/components/tournaments/heats-leaderboards/EditLeaderboardCell';
import { Qualified } from 'src/components/tournaments/heats-leaderboards/Qualified';
import { Rank } from 'src/components/tournaments/heats-leaderboards/Rank';
import { Username } from 'src/components/tournaments/heats-leaderboards/Username';
import { optionalColumns } from 'src/components/tournaments/heats-leaderboards/optionalColumns';
import { Details } from 'src/components/tournaments/heats-leaderboards/referee/Details';
import { useReferee } from 'src/contexts/RefereeContext';
import { FormattedPayoutSummary } from '../../components/tournaments/FormattedPayoutSummary';
import { ResultRanked } from '../../../functions/src/types/firestore/Game/Tournament/Heat/Result';

export type UseLeaderboardColumnsProps = {
  qualification?: QualificationConditions;
  scoreOptions?: ScoreOption[];
  results: ResultRanked[];
};

export const useLeaderboardColumns = ({
  qualification,
  scoreOptions,
  results,
}: UseLeaderboardColumnsProps) => {
  const { isReferee } = useReferee();

  const isTeamCompetition = useMemo(() => {
    return results.some((result) => {
      return result.members && result.members.length > 1;
    });
  }, [results]);

  const prizeColumn: GridColDef = useMemo(() => {
    return {
      field: 'prize',
      headerName: 'PRIZE',
      minWidth: 30,
      sortable: false,
      editable: false,
      headerAlign: 'right',
      flex: 1,
      resizable: true,
      align: 'right',
      renderCell: ({ row }) => {
        return <FormattedPayoutSummary payoutSingle={row.payout} />;
      },
    };
  }, []);

  const rankColumn: GridColDef = useMemo(() => {
    return {
      field: 'rank',
      headerName: 'RANK',
      minWidth: 80,
      maxWidth: 100,
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: ({ row }) => {
        return <Rank rank={row.rank} />;
      },
    };
  }, []);
  const usernameColumn: GridColDef = useMemo(() => {
    return {
      field: 'username',
      headerName: isTeamCompetition ? 'TEAM NAME' : 'USERNAME',
      minWidth: 200,
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Username
            imgUrl={row.imgUrl}
            name={row.name}
            rank={row.rank}
            userIdCaptain={row.userIdCaptain}
            members={row.members}
          />
        );
      },
    };
  }, []);

  const detailsColumn: GridColDef = useMemo(() => {
    return {
      field: 'details',
      headerName: 'DETAILS',
      minWidth: 590,
      maxWidth: 600,
      hideable: true,
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return <Details userIds={row.userIds} captainId={row.userIdCaptain} />;
      },
    };
  }, []);
  const qualifiedColumn: GridColDef = useMemo(() => {
    return {
      field: 'qualified',
      headerName: 'QUALIFIED',
      sortable: false,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        return (
          <Qualified
            scoreValue={row.score}
            rank={row.rank}
            resultsLength={results.length}
            qualification={qualification}
          />
        );
      },
    };
  }, [qualification, results.length]);

  const columns = useMemo(() => {
    return [
      rankColumn,
      usernameColumn,
      ...(isReferee ? [detailsColumn] : []),
      ...(!!scoreOptions ? optionalColumns({ scoreOptions }) : []),
      ...(!!qualification ? [qualifiedColumn] : []),
      prizeColumn,
    ].map((column) => {
      return {
        ...column,
        editable: !!isReferee ? true : false,
        renderEditCell: (params) => {
          const valueIndex = scoreOptions?.findIndex(({ name }) => {
            return name.toLowerCase() === params.field;
          });
          return (
            <EditLeaderboardCell
              {...params}
              key={params.id}
              id={params.id}
              value={params.row.scores[Number(valueIndex)]}
              valueIndex={valueIndex}
              scores={params.row.scores}
              field={params.field}
            />
          );
        },
      };
    });
  }, [
    detailsColumn,
    isReferee,
    prizeColumn,
    qualification,
    qualifiedColumn,
    rankColumn,
    scoreOptions,
    usernameColumn,
  ]);
  return columns;
};
