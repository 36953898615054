import { EVENT_PUBLISHED, EVENT_UNPUBLISHED } from '.';

export const IMPOSSIBLE_FILTERS = 'userIdsContributors:none' as const;

export const onlyContributor = (uid?: string) => {
  if (!uid) {
    return IMPOSSIBLE_FILTERS;
  }
  return `userIdsContributors:${uid}`;
};

export const onlyPublishedAndContributor = (uid?: string) => {
  return `${onlyContributor(uid)} AND ${EVENT_PUBLISHED}`;
};

export const onlyUnpublishedAndContributor = (uid?: string) => {
  return `${onlyContributor(uid)} AND ${EVENT_UNPUBLISHED}`;
};
