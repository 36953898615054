import { TournamentPhase } from '.';

const PRE_READY_PHASES = ['unpublished', 'registration', 'checkIn'];

export function isPreReady(phase: TournamentPhase) {
  return PRE_READY_PHASES.includes(phase);
}

export function isReadyOrLive(phase: TournamentPhase) {
  return phase === 'ready' || phase === 'live';
}
