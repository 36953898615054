import { ReactNode, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const useWrapWithTooltips = (
  components: Record<string, JSX.Element>,
  libraryToSearch: Record<any, ReactNode>,
) => {
  return useMemo(() => {
    return Object.entries(components).reduce((acc, curr) => {
      const [componentKey, element] = curr;
      const tooltipTitle = libraryToSearch?.[String(componentKey)];

      acc[String(componentKey)] = tooltipTitle ? (
        <Tooltip
          arrow
          title={tooltipTitle}
          placement="top-end"
          leaveTouchDelay={0}
        >
          <Box width={'100%'}>{element}</Box>
        </Tooltip>
      ) : (
        element
      );

      return acc;
    }, {} as Record<any, JSX.Element>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components]);
};
