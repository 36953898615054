type FadeDirection = 'top' | 'bottom' | 'left' | 'right';

export type FadeStylesOptions = {
  backgroundColor: string;
  direction?: FadeDirection;
  fadeHeight?: number;
  startFadePercentage?: number;
  endFadePercentage?: number;
};

export const computeFadeStyles = ({
  backgroundColor,
  direction = 'bottom',
  fadeHeight = 20,
  startFadePercentage = 85,
  endFadePercentage = 100,
}: FadeStylesOptions) => {
  return {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: direction === 'top' ? undefined : 0,
      top: direction === 'bottom' ? undefined : 0,
      left: direction === 'right' ? undefined : 0,
      right: direction === 'left' ? undefined : 0,
      height: ['top', 'bottom'].includes(direction)
        ? `${fadeHeight}px`
        : '100%',
      width: ['left', 'right'].includes(direction) ? `${fadeHeight}px` : '100%',
      background: `linear-gradient(to ${direction}, transparent, ${backgroundColor})`,
      pointerEvents: 'none',
    },
    maskImage: `linear-gradient(to ${direction}, black ${startFadePercentage}%, transparent ${endFadePercentage}%)`,
    WebkitMaskImage: `linear-gradient(to ${direction}, black ${startFadePercentage}%, transparent ${endFadePercentage}%)`,
    overflow: 'hidden',
  } as const;
};
