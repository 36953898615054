import { useAuth } from 'src/contexts/AuthContext';
import { useCallback } from 'react';
import { GuestlistTeam } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { WaitlistTeam } from '../../../functions/src/types/firestore/Game/Tournament/Waitlist';
import { findUserInList } from '../../../functions/src/util/findUserInList';

export type UserStatus = 'registered' | 'not-registered' | 'checked-in';

export const useTournamentRegistrationStatus = () => {
  const { uid } = useAuth();

  const determineStatus = useCallback(
    (
      guestlistAggregated: GuestlistTeam<Date>[] = [],
      waitlistAggregated: WaitlistTeam<Date>[] = [],
    ) => {
      if (!uid) {
        return 'not-registered';
      }

      const userTeam =
        findUserInList(guestlistAggregated, uid) ||
        findUserInList(waitlistAggregated, uid);

      if (!userTeam) {
        return 'not-registered';
      }

      const userMember = userTeam.members.find((member) => {
        return member.userId === uid;
      });

      return userMember?.checkedIn ? 'checked-in' : 'registered';
    },
    [uid],
  );

  return { determineStatus };
};
