import { parseRoundNumber } from '../regex';

export const sortGroups = (groupNameA: string, groupNameB: string) => {
  const isGeneralA = groupNameA === 'general';
  const isGeneralB = groupNameB === 'general';
  if (isGeneralA || isGeneralB) {
    return isGeneralA ? (isGeneralB ? 0 : -1) : 1;
  }
  const isRoundA = groupNameA.includes('Round');
  const isRoundB = groupNameB.includes('Round');

  if (isRoundA && isRoundB) {
    return parseRoundNumber(groupNameA) - parseRoundNumber(groupNameB);
  }

  if (isRoundA) return -1;
  if (isRoundB) return 1;

  return 0;
};
