import Stack from '@mui/material/Stack';
import { useCallback, ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useLinkPrizePoolContext } from '../../../../contexts/organizer/LinkPrizePoolContext';
import { useAuth } from '../../../../contexts/AuthContext';
import { AlgoliaEventsCalendar } from '../../../algolia/AlgoliaEventsCalendar';
import { isPrizePoolDivisible } from '../../../../../functions/src/util/payouts/isPrizePoolDivisible';
import {
  EventHit,
  RenderWrapper,
} from '../../../algolia/catalog-wrappers/EventsCalendar';
import { Selectable } from '../../../Selectable';
import { NewTabTournamentCard } from '../../../cards/tournament/NewTabTournamentCard';
import { EVENT_NO_PRIZE_POOL } from '../../../../../functions/src/util/algolia/config/events';
import { onlyUnpublishedAndContributor } from '../../../../../functions/src/util/algolia/config/events/organizer';

// TODO: ultimately, will this component have to handle diverse Event types?

export type TournamentSelectionWrapperProps = {
  children: ReactNode;
  isSelectable: boolean;
  tooltipTitle:
    | {
        overallDivisibility: string | false;
        payoutDivisiblity: string | false;
      }
    | {
        overallDivisibility?: undefined;
        payoutDivisiblity?: undefined;
      };
} & Record<string, unknown>;

export const TournamentSelectionWrapper = memo(
  function TournamentSelectionWrapperUnmemoized({
    children,
    isSelectable: isTournamentSelectable,
    tooltipTitle,
    ...props
  }: TournamentSelectionWrapperProps) {
    return !isTournamentSelectable ? (
      <Tooltip
        title={`${tooltipTitle.overallDivisibility}. Select a different prize pool or edit this tournament.`}
      >
        <Stack {...props}>{children}</Stack>
      </Tooltip>
    ) : (
      <Stack {...props}>{children}</Stack>
    );
  },
);

export const LinkToTournament = memo(function LinkToTournamentUnmemoized() {
  const { prizePool, selectedEvent, setSelectedEvent } =
    useLinkPrizePoolContext();

  const { uid } = useAuth();
  const filters = `${EVENT_NO_PRIZE_POOL} ${onlyUnpublishedAndContributor(
    uid || undefined,
  )}`;

  const LinkableWrapper = useCallback<RenderWrapper<EventHit<Date>, Date>>(
    ({ hit, children }) => {
      const { minTeamSize, maxTeamSize, id } = hit;
      const teamSizes = Array.from(
        { length: maxTeamSize - minTeamSize + 1 },
        (_, i) => {
          return minTeamSize + i;
        },
      );
      const isDivisible = teamSizes.every((teamSize) => {
        return isPrizePoolDivisible(prizePool.prizes, teamSize);
      });

      if (!isDivisible) {
        const isRange = teamSizes.length > 1;
        const teamSizeRange = `team size ${isRange ? 'range ' : ''}of ${
          isRange ? `${minTeamSize} - ${maxTeamSize}` : maxTeamSize
        }`;
        return (
          <Tooltip
            title={`Your prize pool is not divisible by the tournament's ${teamSizeRange}. Select a different tournament or edit your prize pool.`}
          >
            <Box
              sx={{
                opacity: '50%',
              }}
            >
              {children}
            </Box>
          </Tooltip>
        );
      }

      return (
        <Selectable
          value={id}
          isSelected={id === selectedEvent?.id}
          onChange={(_, isSelected) => {
            if (isSelected) {
              setSelectedEvent(hit);
            }
          }}
          borderRadius="10px"
        >
          {children}
        </Selectable>
      );
    },
    [prizePool.prizes, selectedEvent?.id, setSelectedEvent],
  );

  return (
    <Stack width={'100%'} spacing={4}>
      <AlgoliaEventsCalendar
        Wrapper={LinkableWrapper}
        Card={NewTabTournamentCard}
        configureOptions={{
          filters,
        }}
        Title={'Events Calendar'}
        description="Please select an event to link your prize pool with."
        height="520px"
      />
    </Stack>
  );
});
