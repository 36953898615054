import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import {
  FriendVerticalCarousel,
  // useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { FriendCarouselHeader } from './FriendCarouselHeader';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendCardRequest } from '../../cards/friend/FriendCardRequest';
import {
  // UserHit,
  UserVerticalCarousel,
  UserVerticalCarouselProps,
  // useUserVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/UserVerticalCarousel';
import { NoContent } from '../../profile/header/NoContent';
import { useTheme } from '@mui/material/styles';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
// import { useNotificationCount } from '../../../contexts/NotificationCountContext';
// import { useMobile } from '../../../hooks/useMobile';
// import { FRIENDS_ADD_AD } from '../../../../functions/src/util/ads/adIds';
// import { withoutUser } from '../../../util/algolia/withoutUser';
// import { OrNode } from '../../../../functions/src/types/Hit';
import { USER_CONFIGURE_OPTIONS } from '../../../../functions/src/util/algolia/config/user';
import { useUserNotificationCount } from '../../../contexts/UserNotificationCountContext';

export const FRIEND_TABS_HEIGHT_DESKTOP = 36 as const;
export const FRIEND_TABS_HEIGHT_MOBILE = 64 as const;

const FRIENDS_HITS_PER_PAGE = 50 as const;

const FRIEND_CONFIGURE_OPTIONS = {
  ...USER_CONFIGURE_OPTIONS,
  hitsPerPage: FRIENDS_HITS_PER_PAGE,
} as const;

const FriendsAddUnmemoized = () => {
  const { uid } = useAuth();

  const theme = useTheme();

  const { friendRequests: notificationCount } = useUserNotificationCount();

  // const isMobile = useMobile();

  // NOTE: Algolia has restrictions on the query complexity for performance reasons. That's why the friendRequestFilter is incomplete and we apply the necessary checks before rendering the card later on instead.
  // https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/combining-boolean-operators/

  // const UserVerticalCarouselAds = useUserVerticalCarouselAds(FRIENDS_ADD_AD);

  // const AllUserVerticalCarousel = useMemo(() => {
  //   return withoutUser<OrNode<UserHit>, UserVerticalCarouselProps>(
  //     isMobile ? UserVerticalCarousel : UserVerticalCarouselAds,
  //   );
  // }, [isMobile, UserVerticalCarouselAds]);

  // const FriendVerticalCarouselAds =
  //   useFriendVerticalCarouselAds(FRIENDS_ADD_AD);

  // const AllFriendVerticalCarousel = useMemo(() => {
  //   return isMobile ? FriendVerticalCarousel : FriendVerticalCarouselAds;
  // }, [isMobile, FriendVerticalCarouselAds]);

  const UsersCatalogWrapper = useCallback(
    (props: Omit<UserVerticalCarouselProps, 'header'>) => {
      return (
        <UserVerticalCarousel
          {...props}
          header={
            <FriendCarouselHeader title="ADD FRIENDS" separateSearch={true} />
          }
        />
      );
    },
    [],
  );

  const RequestsCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof FriendVerticalCarousel>,
        'RenderFriendHit' | 'header' | 'NoFriends' | 'containerSx'
      >,
    ) => {
      return (
        <FriendVerticalCarousel
          RenderFriendHit={FriendCardRequest}
          {...props}
          header={
            <FriendCarouselHeader
              title="FRIEND REQUESTS"
              notificationCount={notificationCount}
            />
          }
          noFriends={<NoContent variant="friend requests" isSelf={false} />}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationCount],
  );

  const friendRequestsFilter = uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid)
    : ''; // Undefined would return everyone's friend requests
  const requestsConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendRequestsFilter,
    };
  }, [friendRequestsFilter]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 2,
        pb: { xs: 2, md: 0 },
      }}
    >
      <Stack
        spacing={6}
        justifyContent={'space-between'}
        sx={{
          px: 4,
          height: '100%',
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={UsersCatalogWrapper}
            configureOptions={FRIEND_CONFIGURE_OPTIONS}
            index="CONTENT"
          />
        </Box>
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={RequestsCatalogWrapper}
            configureOptions={requestsConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
    </Stack>
  );
};
export const FriendsAdd = memo(FriendsAddUnmemoized);
