import { useEffect, useRef, useState } from 'react';
import { playAudio } from '../../util/playAudio';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';

export const ANOTHER_ONE_AUDIO = '/assets/audio/another-one-dj-khaled.mp3';

export type SendConfirmationCodeParams = {
  firstCode?: boolean;
};

export const useSendConfirmationCode = ({
  firstCode = false,
}: SendConfirmationCodeParams) => {
  const { signIn } = useAuthSubmit();
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (secondsRemaining > 0) {
      intervalRef.current = window.setInterval(() => {
        setSecondsRemaining((seconds) => {
          return seconds - 1;
        });
      }, 1000);
    } else if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [secondsRemaining]);

  const sendConfirmationCode = async () => {
    setSecondsRemaining(30);
    if (!firstCode) {
      await playAudio(ANOTHER_ONE_AUDIO);
    }
    signIn();
    setTimeout(() => {
      setSecondsRemaining(0);
    }, 30000);
  };

  return { sendConfirmationCode, secondsRemaining };
};
