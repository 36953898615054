import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { memo } from '../../util/memo';
import { Hit } from '../../../functions/src/types/Hit';
import { useInfiniteHits } from 'react-instantsearch';

export type CustomHitsBidirectionalProps = {
  setShowMore: (func: (() => void) | null) => void;
  setHits: Dispatch<SetStateAction<Hit[]>>;
};

const CustomHitsBidirectionalUnmemoized = ({
  setShowMore,
  setHits,
}: CustomHitsBidirectionalProps) => {
  const { items, showMore, isLastPage } = useInfiniteHits();
  // NOTE: IGNORE THE FOLLOWING "bad setState()" REACT ERROR. IT'S A NECESSARY HACK.
  setHits(items);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLastPage && !isLoading && !!showMore) {
      setShowMore(() => {
        return showMore;
      });
    } else {
      setShowMore(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastPage, isLoading, showMore]);

  useEffect(() => {
    if (!isLastPage) {
      setIsLoading(false);
    }
  }, [items.length, isLastPage]);

  return null;
};

export const CustomHitsBidirectional = memo(CustomHitsBidirectionalUnmemoized);
