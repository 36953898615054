import { useCallback } from 'react';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { useRebaseSteps } from './useRebaseSteps';
import { EditStepPreFirestore } from '../../../functions/src/types/firestore/EditStep/Step';

export const useWriteStepsBatch = ({
  stepRef,
  currentStepId,
  setCurrentStepId,
  setPendingSteps,
  setDocument,
  applyStep,
}) => {
  const rebaseSteps = useRebaseSteps({
    currentStepId,
    stepRef,
    setDocument,
    applyStep,
    setCurrentStepId,
  });
  return useCallback(
    async (steps: EditStepPreFirestore[]) => {
      try {
        const { doc, runTransaction } = await import('firebase/firestore');
        const { firestore } = await import(
          '../../config/firebase-client/firestore'
        );

        const stepIncrement = await runTransaction(
          firestore,
          async (transaction) => {
            const stepDocRef = doc(
              firestore,
              stepRef!.path,
              `${currentStepId + 1}`,
            ).withConverter(ConverterFactory.buildDateConverter());
            const stepDoc = await transaction.get(stepDocRef);
            if (stepDoc.exists()) {
              await rebaseSteps();
              return;
            }
            let incrementCount = 0;
            for (let i = 0; i < steps.length; i++) {
              const step = steps[Number(i)];

              const newStepRef = doc(
                firestore,
                stepRef!.path,
                `${currentStepId + i + 1}`,
              );
              transaction.set(newStepRef, {
                ...step,
                stepId: parseInt(newStepRef.id),
              });
              incrementCount += 1;
            }
            return incrementCount;
          },
        );
        if (stepIncrement) {
          setCurrentStepId((prev) => {
            return prev + stepIncrement;
          });
          setPendingSteps([]);
        }
      } catch (error) {
        await rebaseSteps();
      }
    },
    [currentStepId, rebaseSteps, setCurrentStepId, setPendingSteps, stepRef],
  );
};
