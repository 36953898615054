import Stack from '@mui/material/Stack';
import { FC, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { EventTime } from 'src/components/leaderboards/EventTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { FormattedPayoutSummary } from '../FormattedPayoutSummary';
import { toTitleCase } from '../../../../functions/src/util/Strings';

const EntryDialogUnmemoized: FC = () => {
  const { go } = useWizard();
  const { gameTitle, title, date, readyTime, payouts, bracketType } =
    useTournamentRegistration();
  const format = useDateFormatter();
  const dateFormatted = useMemo(() => {
    return format(date);
  }, [date, format]);

  const gameTitleFormatted = useMemo(() => {
    return toTitleCase(gameTitle);
  }, [gameTitle]);

  const titleFormatted = useMemo(() => {
    return (
      <Stack alignItems={'center'}>
        <Stack spacing={1} direction={'row'} sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6">You registered for the </Typography>
          <FormattedPayoutSummary
            payoutsTotal={payouts}
            bracketType={bracketType}
          />
          <GradientTypography
            variant="h6"
            gradientColor="warning.vertical"
            sx={{
              textTransform: 'uppercase',
              display: 'inline',
            }}
          >
            {title} {dateFormatted} {gameTitleFormatted}
          </GradientTypography>
          <Typography variant="h6" display={'inline'}>
            tournament.
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <EventTime date={readyTime} title="Check In By:" />
        </Stack>
      </Stack>
    );
  }, [
    bracketType,
    dateFormatted,
    gameTitleFormatted,
    payouts,
    readyTime,
    title,
  ]);
  return (
    <DialogBodyStandard
      title="Tournament Check-In"
      description={titleFormatted}
    >
      <DialogActionsStandard
        buttons={[
          {
            children: 'Check-in',
            isAsync: false,
            onClick: () => {
              return go('RulesCheckInDialog');
            },
            sx: { flex: 2 },
            endIcon: <CheckCircleOutlineIcon />,
          },
          {
            children: 'Unregister',
            isAsync: false,
            onClick: () => {
              return go('UnregisterGuardDialog');
            },
            sx: { flex: 1 },
            endIcon: <HighlightOffIcon />,
            color: 'error',
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const EntryDialog = memo(EntryDialogUnmemoized);
