import { Link, LinkProps } from '../Link';
import { GradientButton } from '../gradients/GradientButton';
import { memo } from '../../util/memo';

function ContactUsButtonUnmemoized({
  style,
  ...props
}: Omit<LinkProps, 'href'>) {
  return (
    <Link
      href="/contact"
      style={{
        textDecoration: 'none',
        ...style,
      }}
      {...props}
    >
      <GradientButton
        variant="outlined"
        sx={{
          whiteSpace: 'nowrap',
          // display: { xs: 'none', md: 'block' },
        }}
      >
        Contact
      </GradientButton>
      {/* {user && (
        <GradientIconButton
          IconComponent={HelpOutlineIcon}
          sx={{
            // display: {
            //   xs: 'block',
            //   md: 'none',
            // },
            height: '40px',
            width: '40px',
          }}
          sxOuter={{ p: 0 }}
        /> */}
    </Link>
  );
}

export const ContactUsButton = memo(ContactUsButtonUnmemoized);
