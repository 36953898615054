import { isSupport } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isSupport';
import { isGroup } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isGroup';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';

export const sortChannelPreviews = (
  channelPreviews: ChannelGroup<keyof GroupFilterMap, Date>[],
) => {
  return channelPreviews.sort((a, b) => {
    const isTeamA = isGroup(a) && !!a.groupFilter[0].tournamentId;
    const isTeamB = isGroup(b) && !!b.groupFilter[0].tournamentId;
    const isTemporaryA = a.permanence === 'temporary';
    const isTemporaryB = b.permanence === 'temporary';
    const isSupportA = isSupport(a);
    const isSupportB = isSupport(b);

    if (isTemporaryA && !isTemporaryB) {
      return -1;
    }
    if (!isTemporaryA && isTemporaryB) {
      return 1;
    }

    if (a.hasMultipleChannels && !b.hasMultipleChannels) {
      return -1;
    }
    if (!a.hasMultipleChannels && b.hasMultipleChannels) {
      return 1;
    }

    if (isTeamA && !isTeamB) {
      return -1;
    }
    if (!isTeamA && isTeamB) {
      return 1;
    }

    if (isSupportA && !isSupportB) {
      return -1;
    }
    if (!isSupportA && isSupportB) {
      return 1;
    }

    return b.lastUpdated.getTime() - a.lastUpdated.getTime();
  });
};
