import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
export type CheckInOptionProps = {
  SkipCheckInComponent: JSX.Element;
  CheckInDuration: JSX.Element;
  skipCheckIn?: boolean;
};

export const CheckInOption = memo(function CheckInOptionUnmemoized({
  SkipCheckInComponent,
  skipCheckIn,
  CheckInDuration,
}: CheckInOptionProps) {
  return (
    <Stack
      spacing={2}
      sx={{
        borderRadius: '10px',
      }}
    >
      <Box sx={{ mb: -3, ml: 3 }}>{SkipCheckInComponent}</Box>
      {!skipCheckIn && CheckInDuration}
    </Stack>
  );
});
