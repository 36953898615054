import { useEffect, useState } from 'react';
import { memo } from '../../../util/memo';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

type InputType = 'text' | 'number' | 'datetime-local';

export type FieldInputProps<T> = {
  regex?: RegExp;
  regexExplained?: string;
  value?: T;
  onChange: (value: T) => Promise<void>;
  IconComponent?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
} & Omit<TextFieldProps, 'type' | 'value' | 'onChange'>;

export type BasicInputProps<T extends InputType> = {
  inputType: T;
  value?: string;
  onChange?: (value: string) => Promise<void> | void;
} & Omit<
  T extends 'text'
    ? FieldInputProps<string>
    : T extends 'number'
    ? FieldInputProps<number>
    : FieldInputProps<Date>,
  'value' | 'onChange'
>;

export const BasicInput = memo(function BasicInputUnmemoized<
  T extends InputType,
>({
  inputType,
  regex,
  regexExplained,
  value,
  required,
  onChange,
  ...rest
}: BasicInputProps<T>): JSX.Element {
  const [localValue, setLocalValue] = useState<any>(value);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);

  const inputChange: TextFieldProps['onChange'] = (event) => {
    const fieldValue = event.target.value;
    const isValid =
      (!!regex && !!value ? regex.test(fieldValue) : true) &&
      (!required || !!fieldValue);
    setError(!isValid);
    setHelperText(
      isValid
        ? undefined
        : `Invalid input${regexExplained ? `: ${regexExplained}` : ''}`,
    );
    setLocalValue(fieldValue);

    if (onChange) {
      onChange(fieldValue);
    }
  };
  useEffect(() => {
    const isValid =
      (!!regex && !!value ? regex.test(value) : true) && (!required || !!value);
    setError(!isValid);
    setHelperText(
      isValid
        ? undefined
        : `Invalid input${regexExplained ? `: ${regexExplained}` : ''}`,
    );
    setLocalValue(value);
  }, [value]);

  return (
    <TextField
      {...rest}
      color="primary"
      variant="filled"
      label={''}
      value={localValue}
      placeholder={rest.placeholder || rest.label}
      type={inputType}
      onChange={inputChange}
      error={error}
      helperText={rest.helperText || helperText}
      required={required}
      fullWidth
      InputLabelProps={
        inputType === 'datetime-local' ? { shrink: true } : undefined
      }
      inputProps={{
        style: {
          padding: 10,
        },
      }}
    />
  );
});
