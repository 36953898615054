import Box from '@mui/material/Box';
import { useContext, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { teamsParser } from '../../../util/tournaments/teamParser';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import {
  TeamsCarouselWrapper,
  TeamsCarouselWrapperProps,
  // useTeamsCarouselWrapperAds,
} from '../../algolia/catalog-wrappers/TeamsCarouselWrapper';
import { TeamsCarouselHeader } from '../../algolia/headers/TeamsCarouselHeader';
import { PHASES_WITH_PARTICIPANTS_ONLY } from '../../../contexts/TournamentRegistrationContext';
import { useTheme } from '@mui/material/styles';
import { teamFilters } from '../../../../functions/src/util/algolia/config/team';
import { PARTICIPANTS_AD } from '../../../../functions/src/util/ads/adIds';
import { TEAM_INDEX_NAME } from '../../../../functions/src/util/algolia/indexNames';
import { GradientTeamlistSelect } from './GradientTeamlistSelect';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';
import { AdSideRails } from '../../ads/AdSideRails';

export const PARTICIPANTS_PER_PAGE = 50 as const;

const ParticipantsPaneUnmemoized = () => {
  const {
    id,
    phase,
    maxTeamSize,
    maxTeamCount,
    guestlistAggregated,
    waitlistAggregated,
    participantsAggregated,
  } = useContext(TournamentContext);

  const isOnlyParticipants = PHASES_WITH_PARTICIPANTS_ONLY.includes(phase);
  const registrationlistAggregated = (
    isOnlyParticipants ? participantsAggregated : guestlistAggregated
  ).map((team) => {
    return teamsParser(team, maxTeamSize);
  });
  const registeredTotal: number = registrationlistAggregated
    .map((entry) => {
      return entry.currentTeamFill;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const waitlist = isOnlyParticipants
    ? []
    : waitlistAggregated.map((team) => {
        return teamsParser(team, maxTeamSize);
      });
  const waitlistTotal: number = waitlist
    .map((entry) => {
      return entry.currentTeamFill;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const [isWaitlistActive, setIsWaitlistActive] = useState(false);

  const filter = useMemo(() => {
    return teamFilters(phase, id, isWaitlistActive);
  }, [isWaitlistActive, id, phase]);

  const TitleSelect = useMemo(() => {
    return (
      <GradientTeamlistSelect
        value={isWaitlistActive ? 'waitlist' : 'guestlist'}
        onValueChange={(value) => {
          return setIsWaitlistActive(value === 'waitlist');
        }}
        registeredTotal={registeredTotal}
        maxRegistrants={maxTeamSize * maxTeamCount}
        waitlistTotal={waitlistTotal}
        sx={{ width: '250px' }}
      />
    );
  }, [
    isWaitlistActive,
    registeredTotal,
    maxTeamSize,
    maxTeamCount,
    waitlistTotal,
  ]);

  // const TeamsCarouselWrapperAds = useTeamsCarouselWrapperAds(
  //   PARTICIPANTS_AD,
  // );

  const ParticipantsCatalogWrapper = useCallback(
    (props: Omit<TeamsCarouselWrapperProps, 'header' | 'maxTeamSize'>) => {
      return (
        <TeamsCarouselWrapper
          {...props}
          header={<TeamsCarouselHeader Title={TitleSelect} />}
          maxTeamSize={maxTeamSize}
        />
      );
    },
    [TitleSelect, maxTeamSize],
  );

  const theme = useTheme();

  return (
    <AdSideRails
      id={PARTICIPANTS_AD}
      childrenMaxWidth={MAX_TOURNAMENT_PANE_WIDTH}
      pattern="tile"
    >
      <Box
        sx={{
          height: '100%',
          maxHeight: '862px',
          width: '100%',
          maxWidth: MAX_TOURNAMENT_PANE_WIDTH,
          overflowY: 'scroll',
          alignSelf: 'center',
          ...theme.palette.scrollbars.invisible,
        }}
      >
        <AlgoliaLayout
          CatalogWrapper={ParticipantsCatalogWrapper}
          configureOptions={{
            filters: filter,
            hitsPerPage: PARTICIPANTS_PER_PAGE,
          }}
          index={TEAM_INDEX_NAME}
        />
      </Box>
    </AdSideRails>
  );
};
export const ParticipantsPane = memo(ParticipantsPaneUnmemoized);
