import Box from '@mui/material/Box';
import { useHighlightedTeamContext } from 'src/contexts/HighlightedTeamContext';
import { useTheme } from '@mui/material/styles';
import { MatchCompetitor, MatchCompetitorProps } from './MatchCompetitor';
import { useMatch } from './MatchProvider';

export type MatchCompetitorHighlightableProps = MatchCompetitorProps & {
  index?: number;
};

export const MatchCompetitorHighlightable = ({
  index,
  competitor,
  outcome,
  score,
}: MatchCompetitorHighlightableProps) => {
  const theme = useTheme();
  const { status } = useMatch();
  const { highlight, highlightedTeamName } = useHighlightedTeamContext();

  const isTop = index === 0;

  return (
    <Box
      onMouseOver={() => {
        if (competitor?.name && !!highlight) {
          highlight(competitor.name);
        }
      }}
      onMouseLeave={() => {
        !!highlight && highlight(undefined);
      }}
    >
      <MatchCompetitor
        competitor={competitor}
        outcome={outcome}
        score={score}
        status={status}
        sx={{
          borderRadius: isTop ? '8px 8px 0px 0px' : '0px 0px 8px 8px',
          outlineOffset: '1px',
          outline:
            !!highlightedTeamName && competitor?.name === highlightedTeamName
              ? `2px solid ${theme.palette.warning.main}`
              : 'rgba(0,0,0,0)',
          backgroundColor:
            !outcome && isTop
              ? theme.palette.background.elevation[12]
              : 'rgba(0,0,0,0)',
          transition: '0.05s ease-out outline-color',
        }}
      />
    </Box>
  );
};
