import { memo } from '../../../util/memo';
import { TournamentCardInfo } from './TournamentCardInfo';
import { TournamentCardImagery } from './TournamentCardImagery';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Tournament } from 'functions/src/types/firestore/Game/Tournament';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';

export const TOURNAMENT_CARD_BORDER_RADIUS = '10px' as const;

export type TournamentCardBaseProps = Tournament<Date> & {
  children?: React.ReactNode;
};

const TournamentCardBaseUnmemoized: React.FC<TournamentCardBaseProps> = ({
  children,
  ...tournament
}) => {
  const theme = useTheme();

  return (
    <TournamentContext.Provider value={tournament}>
      {/* Reintroduce this once we have different event types: <SideBarAccent gradientColor={'primary.vertical'}> */}
      <Stack
        sx={{
          p: 1,
          background: theme.palette.background.elevationSolid[11],
          borderRadius: `${TOURNAMENT_CARD_BORDER_RADIUS} ${TOURNAMENT_CARD_BORDER_RADIUS}`,
        }}
      >
        <TournamentCardImagery />
        <TournamentCardInfo />
        {children}
      </Stack>
      {/* </SideBarAccent> */}
    </TournamentContext.Provider>
  );
};

export const TournamentCardBase = memo(TournamentCardBaseUnmemoized);
