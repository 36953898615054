import { PRIZES_DEFAULT } from '../../types/firestore/Game/Competition';
import { BracketType } from '../../types/firestore/Game/Tournament';
import { Token } from '../../types/firestore/User/Payout';
import { prizeMultipleOf } from '../tournaments/prizeMultipleOf';
import { ToFiat } from './CryptoToFiatConverter';
import { PayoutFormatter } from './formatPayout';

export const formatTotalPayout = (
  formatPayout: PayoutFormatter,
  fiatConverter: ToFiat,
  payouts: {
    tokens: Token[];
  }[] = [],
  bracketType?: BracketType,
) => {
  if (payouts.length === 0) {
    return PRIZES_DEFAULT;
  }
  const totalPayout = payouts
    .flatMap((payout, index) => {
      const { amountFiat, countIlliquid, countUnassured } = formatPayout(
        payout.tokens,
        fiatConverter,
      );
      const numberToMultiply = prizeMultipleOf(index, bracketType);

      return {
        amountFiat: amountFiat * numberToMultiply,
        countIlliquid: countIlliquid * numberToMultiply,
        countUnassured: countUnassured * numberToMultiply,
      };
    })
    .reduce(
      (acc, curr) => {
        return {
          amountFiat: curr.amountFiat + acc.amountFiat,
          countIlliquid: curr.countIlliquid + acc.countIlliquid,
          countUnassured: curr.countUnassured + acc.countUnassured,
        };
      },
      {
        amountFiat: 0,
        countIlliquid: 0,
        countUnassured: 0,
      },
    );

  return totalPayout;
};
