import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { TeamDisplay, TeamDisplayProps } from './TeamDisplay';
import Box from '@mui/material/Box';
import { useMobile } from 'src/hooks/useMobile';

export type TeamDisplayTooltipProps = {
  children: ReactNode;
  teamDisplayProps: TeamDisplayProps;
} & Omit<TooltipProps, 'title'>;

export const TeamDisplayTooltip = memo(function TeamDisplayTooltipUnmemoized({
  children,
  teamDisplayProps,
  ...props
}: TeamDisplayTooltipProps) {
  const isMobile = useMobile();
  return (
    <Tooltip
      title={<TeamDisplay {...teamDisplayProps} />}
      arrow
      enterTouchDelay={isMobile ? 0 : undefined}
      PopperProps={{
        sx: () => {
          return {
            '& .MuiTooltip-tooltip': {
              p: 0,
            },
          };
        },
      }}
      {...props}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
});
