import { useMemo, FC, useRef } from 'react';
import { SxProps, Stack } from '@mui/material';
import { useParentDimensions } from '../../hooks/useParentDimensions';
import { memo } from '../../util/memo';
import {
  LARGE_SHORT_BANNER,
  LARGE_BANNER,
} from '../../../functions/src/util/ads/AdDimension';
import { AdProps, Ad } from './Ad';

export type AdHeight = 50 | 100;

export type AdHorizontalFillProps = Omit<AdProps, 'width' | 'height'> & {
  height: AdHeight;
  pattern?: 'tile' | 'one';
  sticky?: boolean;
  sx?: SxProps;
};

const AdHorizontalRailUnmemoized: FC<AdHorizontalFillProps> = ({
  height,
  justifyContent = 'center',
  alignItems = 'center',
  pattern = 'tile',
  sticky = true,
  sx,
  ...adProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const parentDimensions = useParentDimensions('100%', height, containerRef);

  const adConfig = useMemo(() => {
    return height === 100 ? LARGE_BANNER : LARGE_SHORT_BANNER;
  }, [height]);

  const ads = useMemo(() => {
    if (pattern === 'one') {
      return <Ad {...adProps} {...adConfig} sticky={sticky} />;
    }

    const numberOfAds = Math.floor(parentDimensions.width / adConfig.width);
    return Array.from({ length: numberOfAds }).map((_, index) => {
      return <Ad key={`${index}-ad`} {...adProps} {...adConfig} />;
    });
  }, [parentDimensions.width, adConfig, pattern, adProps, sticky]);

  const stackSx = useMemo(() => {
    return {
      ...(sticky && {
        position: 'sticky',
        top: 0,
      }),
      ...(sx || {}),
    } as SxProps;
  }, [sticky, sx]);

  return (
    <Stack
      ref={containerRef}
      direction="row"
      width="100%"
      justifyContent={justifyContent}
      alignItems={alignItems}
      height={`${height}px`}
      sx={stackSx}
    >
      {ads}
    </Stack>
  );
};

export const AdHorizontalRail = memo(AdHorizontalRailUnmemoized);
