import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { LoadingButton, LoadingButtonProps } from '../buttons/LoadingButton';
import Button, { ButtonProps } from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

export type AsyncronousActionButtonProps = LoadingButtonProps & {
  isAsync: true;
};

export type SyncronousActionButtonProps = ButtonProps & {
  isAsync: false;
};

export type ActionButtonProps =
  | AsyncronousActionButtonProps
  | SyncronousActionButtonProps;

export type DialogActionsStandardProps = {
  buttons: ActionButtonProps[];
};

function DialogActionsStandardUnmemoized({
  buttons,
}: DialogActionsStandardProps) {
  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {buttons.map((button) => {
        const key = uuidv4();
        const { isAsync, ...other } = button;
        return isAsync ? (
          <LoadingButton
            key={key}
            size="large"
            fullWidth
            variant="contained"
            sx={{ flex: 1, width: '100%' }}
            {...button}
          />
        ) : (
          <Button
            key={key}
            size="large"
            fullWidth
            variant="contained"
            sx={{ flex: 1, width: '100%' }}
            {...other}
          />
        );
      })}
    </Stack>
  );
}

export const DialogActionsStandard = memo(DialogActionsStandardUnmemoized);
