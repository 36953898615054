//import { log } from './log';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
//@log
export class Factory<T, G extends (...args: any[]) => T = () => T> {
  public readonly settings: G;
  public constructor(settings: T | G = {} as T) {
    this.settings =
      typeof settings === 'function'
        ? (settings as G)
        : ((() => {
            return settings;
          }) as G);
  }
}
