import { useMemo } from 'react';
import { base64ToInt } from '../../../functions/src/util/base64/base64ToInt';
import { useAuth } from '../../contexts/AuthContext';

const getGroupsFromCustomClaims = (customClaims?: string) => {
  if (!customClaims) return [];

  const chunks = customClaims.match(/.{1,5}/g) || [];
  return chunks
    .map((base64) => {
      return base64ToInt(base64);
    })
    .filter((num): num is number => {
      return num !== undefined;
    });
};

const combineGroups = (groups1: number[], groups2: number[]) => {
  return Array.from(new Set([...groups1, ...groups2]));
};

export function useUserGroups() {
  const { user, userData } = useAuth();

  const groups = useMemo(() => {
    if (!user) return [];

    const initialGroups = getGroupsFromCustomClaims(
      user?.claims?.groupNumbers as string,
    );

    if (!userData) return initialGroups;

    const overflowGroups = getGroupsFromCustomClaims(userData.customClaims);

    return combineGroups(initialGroups, overflowGroups);
  }, [user, userData]);

  return groups;
}
