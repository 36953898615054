import {
  DialogBodyStandard,
  DialogBodyStandardProps,
} from './DialogBodyStandard';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { GradientIcon } from '../gradients/GradientIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DialogActionsStandard } from './DialogActionsStandard';
import { memo } from '../../util/memo';

export type DialogSuccessfulStandardProps = DialogBodyStandardProps & {
  message?: string;
  onFinish?: () => void;
};

export const DialogSuccessfulStandard = memo(
  function DialogSuccessfulStandardUnmemoized({
    title,
    description,
    onFinish,
    message = 'Close',
  }: DialogSuccessfulStandardProps) {
    return (
      <Stack width="100%">
        <DialogBodyStandard title={title} description={description}>
          <Box textAlign={'center'}>
            <GradientIcon
              IconComponent={CheckCircleIcon}
              gradientColor="secondary.horizontal"
              sx={{ fontSize: '75px' }}
            />
          </Box>
          <DialogActionsStandard
            buttons={[
              {
                isAsync: false,
                onClick: onFinish,
                children: message,
              },
            ]}
          />
        </DialogBodyStandard>
      </Stack>
    );
  },
);
