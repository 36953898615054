import { PLACEHOLDER_REGEX } from './templates';

export const matchesTemplate = (template: string, filter: string) => {
  const templatePattern = template
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(PLACEHOLDER_REGEX, '.*')
    .replace(/\s+/g, '\\s+');

  // eslint-disable-next-line security/detect-non-literal-regexp
  const templateRegex = new RegExp(`^${templatePattern}$`);
  return templateRegex.test(filter);
};
