import Stack from '@mui/material/Stack';
import { EventTime } from 'src/components/leaderboards/EventTime';
import { useMobile } from 'src/hooks/useMobile';
import { ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import { TournamentDetail } from 'functions/src/types/firestore/Game/Tournament';
// import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';
import { Ad } from '../../../ads/Ad';
// import { AccordionInfo } from '../../../AccordionInfo';
// import {
//   ACCORDION_TITLE_LIVESTREAM,
//   AccordionBodyLivestream,
// } from '../../../livestream/AccordionBodyLivestream';
import { REGISTRATION_AD } from '../../../../../functions/src/util/ads/adIds';
import { useCountdownDisplay } from '../../../../hooks/tournaments/useCountdownDisplay';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../../TournamentTabs';
// import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';

// import { findIntermissionAround } from '../../../../util/findIntermissionAround';
// import { AccordionInfo } from '../../../AccordionInfo';
// import {
//   ACCORDION_TITLE_LIVESTREAM,
//   AccordionBodyLivestream,
// } from '../../../livestream/AccordionBodyLivestream';
import { RULES_TRANSLATIONS } from '../../../../../functions/src/types/country';

export const MAX_PANEL_WIDTH = '656px';

export const getTournamentRules = (
  tournamentDetails: TournamentDetail[],
): string | undefined => {
  const detail = tournamentDetails?.find((tournamentDetail) => {
    const { title } = tournamentDetail;
    return RULES_TRANSLATIONS.some((rule) => {
      return title.toLowerCase().includes(rule);
    });
  });
  return detail ? detail.body : undefined;
};

export type RegistrationContainerProps = {
  children?: ReactNode;
  SideComponent?: ReactNode;
};

export const RegistrationContainer = memo(
  function RegistrationContainerUnmemoized({
    children,
    SideComponent,
  }: RegistrationContainerProps) {
    const isMobile = useMobile();

    const countdownDisplay = useCountdownDisplay();

    return (
      <Stack alignItems="center" sx={{ justifyContent: 'center' }}>
        <Stack
          gap={4}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Stack
            gap={4}
            flexDirection="column"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
            width="100%"
          >
            <Ad id={REGISTRATION_AD} width="100%" height={100} />
            <Stack maxWidth={MAX_TOURNAMENT_PANE_WIDTH} gap={4}>
              <EventTime {...countdownDisplay} />
              {/* <AccordionInfo
                title={ACCORDION_TITLE_LIVESTREAM}
                body={
                  <AccordionBodyLivestream tournamentTitle={tournamentTitle} />
                }
                openInDialog={false}
              /> */}
              {SideComponent}
            </Stack>
            {!isMobile && children}
          </Stack>
          {isMobile && children}
        </Stack>
      </Stack>
    );
  },
);
