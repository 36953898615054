import { useCallback, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTemporaryChannelGroups } from './useTemporaryChannelGroups';
import { useRouter } from '../routing/useRouter';
import { toIdentifiers } from '../../../functions/src/util/messaging/channel-group/temporaryChannelGroupUrls';
import isEqual from 'fast-deep-equal';

export const useTemporaryChannelGroupSyncer = () => {
  const { temporaryChannelGroups = [], setTemporaryChannelGroups } =
    useTemporaryChannelGroups();
  const router = useRouter();
  const { uidFull } = useAuth();

  const updateTemporaryChannelGroup = useCallback(
    (url: string) => {
      if (!uidFull) {
        return;
      }

      const identifiers = toIdentifiers(url);

      if (isEqual(temporaryChannelGroups, identifiers)) {
        return;
      }

      setTemporaryChannelGroups(identifiers);
    },
    [setTemporaryChannelGroups, temporaryChannelGroups, uidFull],
  );

  useEffect(() => {
    updateTemporaryChannelGroup(window.location.href);

    const routeChange = (url: string) => {
      updateTemporaryChannelGroup(url);
    };

    router.events.on('routeChangeComplete', routeChange);
    return () => {
      router.events.off('routeChangeComplete', routeChange);
    };
  }, [router, updateTemporaryChannelGroup]);
};
