import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type PictureInPictureContextProps = {
  isPictureInPicture: boolean;
  setIsPictureInPicture: (state: boolean) => void;
};

export const PictureInPictureContext = createContext<
  PictureInPictureContextProps | undefined
>(undefined);

export type PictureInPictureProviderProps = {
  children: ReactNode;
};

export const usePictureInPicture = () => {
  const context = useContext(PictureInPictureContext);
  if (!context) {
    throw new Error(
      'usePictureInPicture must be used within a PictureInPictureProvider',
    );
  }
  return context;
};

const PictureInPictureProviderUnmemoized: FC<PictureInPictureProviderProps> = ({
  children,
}) => {
  const [isPictureInPicture, setIsPictureInPicture] = useState(false);

  const memoizedValue = useMemo(() => {
    return { isPictureInPicture, setIsPictureInPicture };
  }, [isPictureInPicture]);

  return (
    <PictureInPictureContext.Provider value={memoizedValue}>
      {children}
    </PictureInPictureContext.Provider>
  );
};

export const PictureInPictureProvider = memo(
  PictureInPictureProviderUnmemoized,
);
