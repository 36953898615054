import axios from 'axios';

export type VerifyAssetsParams = {
  _id: string; // We're including this as in the future we may want to check other chains.
  walletAddress: string;
  requirementTokens: Record<
    string,
    { collectionName: string; templateId: string; count: number }[]
  >;
};

export const verifyAssets = async ({
  _id,
  walletAddress,
  requirementTokens,
}: VerifyAssetsParams) => {
  for (const asset of requirementTokens[String(_id)]) {
    const { collectionName, templateId, count } = asset;
    const response = await axios.get(
      'https://wax.api.atomicassets.io/atomicassets/v1/accounts',
      {
        params: {
          owner: walletAddress,
          template_id: templateId,
          collection_name: collectionName,
        },
      },
    );
    const responseData = response.data.data;
    if (!responseData[0] || Number(responseData[0].assets) < count) {
      return false;
    }
  }
  return true;
};
