import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../../../util/memo';
import {
  MessageContextValue as StreamMessageContextValue,
  useMessageContext as useStreamMessageContext,
  useChannelStateContext as useStreamChannelStateContext,
} from 'stream-chat-react';
import type { UserResponse } from 'stream-chat';
import { useAuth } from '../../../contexts/AuthContext';
import { useGetStreamId } from '../../../hooks/messaging/useGetStreamId';

export type LinkConfig = {
  header: string;
  description: string;
  title: string;
  link: string;
  linkText: string;
};

export type MessageContextProps = Omit<
  StreamMessageContextValue,
  'isMyMessage' | 'readBy'
> & {
  readBy?: UserResponse[];
  isMine: boolean;
  actionAnchor: (EventTarget & SVGSVGElement) | null;
  setActionAnchor: Dispatch<
    SetStateAction<(EventTarget & SVGSVGElement) | null>
  >;
  openMenu: (event: React.MouseEvent<SVGSVGElement>) => void;
  replyCount: number;
  isUserInfoShown: boolean;
  isInteractionsShown: boolean;
  isStatusShown: boolean;
  isBottomOrSingle: boolean;
  isDm: boolean;
  linkConfig: LinkConfig;
  parentId?: string;
  setParentId: Dispatch<SetStateAction<string | undefined>>;
};

export const MessageContext = createContext<MessageContextProps | undefined>(
  undefined,
);

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
};

const MessageProviderUnmemoized = ({ children }) => {
  const { uid } = useAuth();
  const streamMessageContext = useStreamMessageContext();
  const { channel } = useStreamChannelStateContext();
  const { message, groupStyles, readBy: streamReadBy } = streamMessageContext;
  const userIdGetStream = useGetStreamId();

  const [actionAnchor, setActionAnchor] = useState<
    (EventTarget & SVGSVGElement) | null
  >(null);
  const [parentId, setParentId] = useState<string>();

  const {
    type,
    reply_count,
    deleted_reply_count,
    user: messageUser,
    attachments,
  } = message;

  const isMine = useMemo(() => {
    return userIdGetStream === messageUser?.id;
  }, [messageUser?.id, userIdGetStream]);

  const isInteractionsShown = useMemo(() => {
    return !!uid && type !== 'deleted';
  }, [type, uid]);

  const isDm = useMemo(() => {
    return channel?.type === 'dm';
  }, [channel?.type]);

  const isBottomOrSingle = useMemo(() => {
    return groupStyles
      ? groupStyles.includes('bottom') || groupStyles.includes('single')
      : false;
  }, [groupStyles]);

  const isUserInfoShown = useMemo(() => {
    return !isMine && isBottomOrSingle && !isDm;
  }, [isMine, isBottomOrSingle, isDm]);

  const isStatusShown = useMemo(() => {
    return isMine && type !== 'deleted' && isBottomOrSingle;
  }, [isMine, type, isBottomOrSingle]);

  const readBy = useMemo(() => {
    return streamReadBy?.filter(({ id }) => {
      return id !== userIdGetStream;
    });
  }, [streamReadBy, userIdGetStream]);

  const replyCount = useMemo(() => {
    return (
      ((reply_count ?? 0) as number) - ((deleted_reply_count ?? 0) as number)
    );
  }, [deleted_reply_count, reply_count]);

  const openMenu = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    const { currentTarget } = event;
    setActionAnchor(currentTarget);
  }, []);

  const { title, text, author_name, title_link, og_scrape_url } =
    attachments?.[0] || {};
  const linkConfig = useMemo(() => {
    return !!attachments &&
      attachments.some((attachment) => {
        return !!attachment.title_link;
      })
      ? {
          header: author_name!,
          description: text!,
          title: title!,
          link: title_link!,
          linkText: og_scrape_url!,
        }
      : ({} as LinkConfig);
  }, [attachments, author_name, og_scrape_url, text, title, title_link]);

  return (
    <MessageContext.Provider
      value={{
        ...streamMessageContext,
        readBy,
        replyCount,
        isUserInfoShown,
        isInteractionsShown,
        isStatusShown,
        isBottomOrSingle,
        isMine,
        openMenu,
        setActionAnchor,
        actionAnchor,
        isDm,
        linkConfig,
        parentId,
        setParentId,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const MessageProvider = memo(MessageProviderUnmemoized);
