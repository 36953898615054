import Menu from '@mui/material/Menu';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { useMessageActions } from '../../../hooks/messaging/useMessageActions';

// eslint-disable-next-line react-memo/require-memo
const MessageActionsMenuUnmemoized = () => {
  const { actionAnchor, setActionAnchor, message } = useMessage();
  const { activeActions } = useMessageActions({
    message,
  });

  return (
    <Menu
      id="basic-menu"
      anchorEl={actionAnchor}
      open={!!actionAnchor}
      onClose={() => {
        setActionAnchor(null);
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ mt: 6 }}
    >
      {activeActions}
    </Menu>
  );
};

export const MessageActionsMenu = memo(MessageActionsMenuUnmemoized);
