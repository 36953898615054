import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { GradientIcon } from '../../gradients/GradientIcon';
import { useAuthMethodsDisplay } from '../../../hooks/auth/useAuthMethodsDisplay';
import { AllSignInMethod } from '../../../../functions/src/types/firestore/User';
import { useAuthSubmit } from '../../../contexts/AuthSubmitContext';
import { useSignIn } from '../../../hooks/auth/useSignIn';

export type AuthenticationOptionProps = {
  providerId: AllSignInMethod;
};

export const AuthenticationOption = memo(
  function AuthenticationOptionUnmemoized({
    providerId,
  }: AuthenticationOptionProps) {
    const theme = useTheme();
    const { errorMessage, clearAllErrors } = useAuthSubmit();
    const signIn = useSignIn();
    const authOptionsDisplay = useAuthMethodsDisplay();
    const authMethodData = authOptionsDisplay[`${providerId}`];
    const { icon, continueWithMessage } = authMethodData;
    const authenticateUser = useCallback(async () => {
      if (!errorMessage.agreements) {
        clearAllErrors();
        await signIn(providerId);
      }
    }, [clearAllErrors, errorMessage.agreements, providerId, signIn]);
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={!!continueWithMessage ? 'flex-start' : 'center'}
        spacing={8}
        px="16px"
        py="6px"
        borderRadius={'4px'}
        sx={{
          background: theme.palette.other.textSecondaryShades.opacity8,
          cursor: 'pointer',
          height: '64px',
          width: !!continueWithMessage ? '100%' : undefined, //stretch these out to max out the stack
          flexGrow: 1,
        }}
        onClick={authenticateUser}
      >
        <GradientIcon IconComponent={icon} gradientColor="primary.horizontal" />
        {!!continueWithMessage && (
          <Typography variant="body1" textTransform={'uppercase'}>
            {continueWithMessage}
          </Typography>
        )}
      </Stack>
    );
  },
);
