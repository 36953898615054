import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { AudioDeviceSelect } from './AudioDeviceSelect';

const MediaPreferencesDialogUnmemoized = () => {
  return (
    <Stack spacing={4}>
      <Box id="audioInput" sx={{ flex: 1 }}>
        <AudioDeviceSelect
          kind="audioinput"
          label="Select Microphone Input"
          fieldName="audioInput"
        />
      </Box>
      <Box id="audioOutput" sx={{ flex: 1 }}>
        <AudioDeviceSelect
          kind="audiooutput"
          label="Select Audio Output"
          fieldName="audioOutput"
        />
      </Box>
      {/* <VideoDevicesSelect /> */}
    </Stack>
  );
};

export const MediaPreferencesDialog = memo(MediaPreferencesDialogUnmemoized);
