import { NUMERIC, NUMERIC_NOYEAR, WEEKDAY_YEAR } from './dates';
import { NUMERIC_NOTIMEZONE } from './times';

export const NUMERIC_NOZONE_COMMA = {
  dateFormat: NUMERIC,
  timeFormat: NUMERIC_NOTIMEZONE,
  separator: ', ',
} as const;

export const NUMERIC_NOZONE_NOCOMMA = {
  dateFormat: NUMERIC_NOYEAR,
  timeFormat: NUMERIC_NOTIMEZONE,
  separator: '  ',
} as const;

export const WEEKDAY_NOZONE_DASH = {
  dateFormat: WEEKDAY_YEAR,
  timeFormat: NUMERIC_NOTIMEZONE,
  separator: ' - ',
};
