import { useCallback, ReactNode } from 'react';
import { memo } from '../../util/memo';
import { useWatchAlgoliaReindex } from '../../hooks/useWatchAlgloliaReindex';
import { useInstantSearch } from 'react-instantsearch';
import { useAlgoliaCache } from '../../hooks/algolia/useAlgoliaCache';
import { toRealtimeTemplateHash } from '../../../functions/src/util/algoliaRealtime/toRealtimeTemplateHash';

export type RefreshHitsProps = {
  filters?: string;
  children: ReactNode;
};

const RefreshHitsUnmemoized = ({ filters, children }: RefreshHitsProps) => {
  const templateFilledHash = toRealtimeTemplateHash(filters);

  const {
    cache: { refresh: refreshInfiniteHits },
  } = useAlgoliaCache();

  const { refresh: refreshInstantSearch } = useInstantSearch();

  const onRealtimeRefresh = useCallback(() => {
    refreshInfiniteHits();
    refreshInstantSearch();
  }, [refreshInfiniteHits, refreshInstantSearch]);

  useWatchAlgoliaReindex({
    onRefresh: onRealtimeRefresh,
    templateFilledHash,
  });

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{children}</>;
};

export const RefreshHits = memo(RefreshHitsUnmemoized);
