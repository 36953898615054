import Stack from '@mui/material/Stack';
import { FC } from 'react';
import { memo } from '../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { AuthenticationInput } from '../AuthenticationInput';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import { ValidateEmailButton } from 'src/components/buttons/ValidateEmailButton';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';

const AuthenticationEnterEmailDialogUnmemoized: FC = () => {
  const { onInputChange, userCredentials, enterEmail, errorMessage } =
    useAuthSubmit();
  const { email: emailErrorMessage } = errorMessage;

  return (
    <Stack width="100%">
      <DialogAuthenticationNavBar />
      <DialogBodyStandard
        title={'Enter Email Address'}
        description={`Let's see if you already have an account with us...`}
      >
        <Stack
          width="100%"
          maxWidth="440px"
          alignSelf="center"
          spacing={6}
          pb={4}
        >
          <AuthenticationInput
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              return onInputChange('email', event.currentTarget.value);
            }}
            helperText={!!emailErrorMessage ? emailErrorMessage : undefined}
            label={'Email Address'}
            value={userCredentials.email}
            type={'email'}
            name={'email'}
            autoFocus={true}
            onSubmit={enterEmail}
          />

          {/* TODO <Box textAlign={'left'}>
            <Link href={'#'} color={theme.palette.text.secondary}>
              Forgot your email?
            </Link>
          </Box> */}

          <ValidateEmailButton />
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEnterEmailDialog = memo(
  AuthenticationEnterEmailDialogUnmemoized,
);
