import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useRoom } from '../../contexts/RoomContext';
import { Required } from 'utility-types';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
import {
  Caller,
  CallerStatus,
} from '../../../functions/src/types/realtimeDb/Room';
import { randomAvatarUrl } from '../../../functions/src/util/user/defaultAvatar';
import {
  adjectives,
  colors,
  animals,
  uniqueNamesGenerator,
} from 'unique-names-generator';

export const useCallerConnection = () => {
  const { userData } = useAuth();
  const { mode } = useVoiceChat();
  const { rooms } = useRoom();

  const setCallerData = useCallback(
    async (callerData: Required<Partial<Caller>, 'id'>) => {
      const { roomPath } = rooms[mode as string];

      if (!roomPath) {
        return;
      }
      const { database } = await import(
        '../../config/firebase-client/database'
      );
      const { get, ref, update } = await import('firebase/database');

      const callerRef = ref(database, `${roomPath}/callers/${callerData.id}`);
      const callerSnapshot = await get(callerRef);
      const caller = callerSnapshot.val() || {};

      await update(callerRef, { ...caller, ...callerData });
    },
    [mode, rooms],
  );

  const request = useCallback(async () => {
    if (!userData?.id || !userData?.username || !userData?.imgUrl) {
      return;
    }

    const caller = {
      id: userData?.id,
      username: userData?.username,
      imgUrl: userData?.imgUrl,
      status: 'requested' as CallerStatus,
    };

    await setCallerData(caller);
  }, [setCallerData, userData?.id, userData?.imgUrl, userData?.username]);

  const invite = useCallback(
    async (caller: Omit<Caller, 'status'>) => {
      await setCallerData({ ...caller, status: 'invited' as CallerStatus });
    },
    [setCallerData],
  );

  const accept = useCallback(
    async (userId: string) => {
      await setCallerData({ id: userId, status: 'accepted' });
    },
    [setCallerData],
  );

  const reject = useCallback(
    async (userId: string) => {
      await setCallerData({ id: userId, status: 'rejected' });
    },
    [setCallerData],
  );

  // THIS IS ONLY FOR TESTING
  const populate = useCallback(async () => {
    const callerPromises = Array.from({ length: 100 }).map(async (_, index) => {
      const caller = {
        id: `DUMMY-${index}`,
        username: uniqueNamesGenerator({
          dictionaries: [adjectives, colors, animals],
          separator: ' ',
          length: 2,
          style: 'capital',
        }),
        imgUrl: randomAvatarUrl(),
        status: 'accepted' as CallerStatus,
      };

      await setCallerData(caller);
    });
    await Promise.all(callerPromises);
  }, [setCallerData]);

  return { request, invite, accept, reject, populate };
};
