import { useEffect, useRef } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useRouter } from '../routing/useRouter';
import type { PluginListenerHandle } from '@capacitor/core';

export const useAppUrlOpen = () => {
  const { push } = useRouter();
  const initialUrlProcessed = useRef(false);

  useEffect(() => {
    const deepNavigate = (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.app').pop();
      if (slug && !initialUrlProcessed.current) {
        initialUrlProcessed.current = true;
        push(slug);
      }
    };

    let appUrlOpenListener: PluginListenerHandle;
    const setupDeepLinks = async () => {
      const launchUrl = await App.getLaunchUrl();
      if (launchUrl && !initialUrlProcessed.current) {
        const { url } = launchUrl;
        deepNavigate({ url });
      }

      appUrlOpenListener = await App.addListener('appUrlOpen', deepNavigate);
    };

    setupDeepLinks();

    return () => {
      appUrlOpenListener?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
