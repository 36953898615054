import { memo } from '../../util/memo';
import { TournamentPanelV3 } from './TournamentPanelV3';
import Typography from '@mui/material/Typography';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';

export type TournamentPanelDefaultProps = {
  title: string;
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  description: string;
};

export const TournamentPanelDefault = memo(
  function TournamentPanelDefaultUnmemoized({
    title,
    IconComponent,
    description,
  }: TournamentPanelDefaultProps) {
    return (
      <TournamentPanelV3
        title={title}
        TitleIconComponent={IconComponent}
        Content={
          <Typography
            textTransform={'uppercase'}
            variant="subtitle1"
            fontWeight={'500'}
          >
            {description}
          </Typography>
        }
      />
    );
  },
);
