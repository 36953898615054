import React, {
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { memo } from '../../util/memo';
import { diff } from 'deep-object-diff';
import { RealtimeAlgoliaConfiguration } from '../../../functions/src/types/firestore/RealtimeAlgoliaConfiguration';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { Hit } from '../../../functions/src/types/Hit';

export const REFRESH_BUFFER = 1000;

export const areHitsEqual = (hit1: Hit, hit2: Hit) => {
  const differences = diff(hit1, hit2);
  return Object.keys(differences).length === 0;
};

export type TemplateFilledHash = string;

export type IndexingHits = Record<
  TemplateFilledHash,
  {
    hitsBeingAdded: Record<string, Hit>;
    hitsBeingRemoved: string[];
  }
>;

export type CacheClearingListener = {
  startedListening: Date;
  unsubscribe: () => void;
};

export type IndexingHitsContextType = {
  add: (templateFilledHash: string, newHit: Hit) => void;
  remove: (templateFilledHash: string, objectID: string) => void;
  indexingHits: IndexingHits;
};

export const IndexingHitsContext =
  createContext<IndexingHitsContextType | null>(null);

export const useIndexingHits = () => {
  const context = useContext(IndexingHitsContext);
  if (!context) {
    throw new Error(
      'useAlgoliaHits must be used within an AlgoliaHitsProvider',
    );
  }
  return context;
};
const IndexingHitsProviderUnmemoized = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [indexingHits, setIndexingHits] = useState<IndexingHits>({});
  const add = (templateFilledHash: string, newHit: Hit) => {
    setIndexingHits((prevHits) => {
      const currentHit = prevHits[String(templateFilledHash)] || {
        hitsBeingAdded: {},
        hitsBeingRemoved: [],
      };
      const updatedHits = { ...currentHit.hitsBeingAdded };
      const existingHit = updatedHits[`${newHit.objectID}`];

      if (!existingHit || !areHitsEqual(existingHit, newHit)) {
        updatedHits[`${newHit.objectID}`] = newHit;
      }

      return {
        ...prevHits,
        [templateFilledHash]: { ...currentHit, hitsBeingAdded: updatedHits },
      };
    });
  };

  const remove = (templateFilledHash: string, objectID: string) => {
    setIndexingHits((prevHits) => {
      const currentHit = prevHits[String(templateFilledHash)] || {
        hitsBeingAdded: {},
        hitsBeingRemoved: [],
      };
      return {
        ...prevHits,
        [templateFilledHash]: {
          ...currentHit,
          hitsBeingRemoved: [...currentHit.hitsBeingRemoved, objectID],
        },
      };
    });
  };

  const clearCache = (templateFilledHash: string) => {
    setIndexingHits((prevHits) => {
      const resetHits = { ...prevHits };
      if (resetHits[String(templateFilledHash)]) {
        delete resetHits[String(templateFilledHash)];
      }
      return resetHits;
    });
  };

  const listeners = useRef<Record<TemplateFilledHash, CacheClearingListener>>(
    {},
  );

  useEffect(() => {
    const handler = async () => {
      await Promise.all(
        Object.keys(indexingHits).map(async (templateFilledHash) => {
          const listener = listeners.current[String(templateFilledHash)];
          if (listener) {
            return;
          }
          const startedListening = new Date();

          const { doc, onSnapshot } = await import('firebase/firestore');
          const { firestore } = await import(
            '../../config/firebase-client/firestore'
          );

          const docRef = doc(
            firestore,
            `RealtimeAlgoliaConfiguration/${templateFilledHash}`,
          ).withConverter<RealtimeAlgoliaConfiguration<Date>>(
            ConverterFactory.buildDateConverter(),
          );

          const unsubscribe = onSnapshot(
            docRef,
            (document) => {
              if (document.exists()) {
                const { lastUpdated } = document.data();
                if (
                  lastUpdated.getTime() >
                  startedListening.getTime() + REFRESH_BUFFER
                ) {
                  clearCache(templateFilledHash);
                  unsubscribe();
                  delete listeners.current[String(templateFilledHash)];
                }
              }
            },
            (error) => {
              console.error(error);
            },
          );

          listeners.current[String(templateFilledHash)] = {
            startedListening,
            unsubscribe,
          };
        }),
      );
    };

    handler();
  }, [indexingHits]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(listeners.current).forEach(({ unsubscribe }) => {
        unsubscribe();
      });
    };
  }, []);

  const contextValue = useMemo(() => {
    return {
      indexingHits,
      add,
      remove,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexingHits]);

  return (
    <IndexingHitsContext.Provider value={contextValue}>
      {children}
    </IndexingHitsContext.Provider>
  );
};

export const IndexingHitsProvider = memo(IndexingHitsProviderUnmemoized);
