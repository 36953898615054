import { BanDetails } from '../../../functions/src/types/firestore/User/BanStatus';
import { ONE_MINUTE_MILLIS } from '../../../functions/src/util/conversions';
import { useGetStreamId } from './useGetStreamId';
import { useCallback } from 'react';
import type { Channel } from 'stream-chat';
import { useSetBanStatus } from './useSetBanStatus';

export type BanParams = Pick<BanDetails, 'timeout' | 'reason' | 'channelId'> & {
  channel?: Channel;
};

//  NOTE: Perform channel bans on the front end because the channel object is not serializable for our cloud function.
//  We could pass the channel ID as an argument, but this would require an extra query to retrieve the channel object.
export const useBanHammer = (target_user_id: string) => {
  const bannedById = useGetStreamId();
  const { setBanStatus } = useSetBanStatus();

  const ban = useCallback(
    async ({ timeout, reason, channel }: BanParams) => {
      if (!bannedById) {
        return;
      }

      const banOptionsBase = {
        tournamentId: channel?.data?.tournamentId as string,
        banned_by_id: bannedById,
        target_user_id,
        timeout,
        reason,
      };

      if (channel) {
        await channel.banUser(target_user_id, {
          ...banOptionsBase,
          timeout: timeout ? timeout / ONE_MINUTE_MILLIS : undefined,
        });
      }

      await setBanStatus({
        operation: 'ban',
        details: {
          ...banOptionsBase,
          channelId: channel?.cid,
        },
      });
      // TODO: send notification to banned user
    },
    [bannedById, setBanStatus, target_user_id],
  );

  const removeBan = useCallback(
    async (channel?: Channel) => {
      if (channel) {
        await channel.unbanUser(target_user_id);
      }

      await setBanStatus({
        operation: 'unban',
        details: {
          target_user_id,
          tournamentId: channel?.data?.tournamentId as string,
          channelId: channel?.cid,
        },
      });
      // TODO: send notification to unbanned user
    },
    [setBanStatus, target_user_id],
  );

  return { ban, removeBan };
};
