import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { SelectableRegistrationOptions } from '../../components/tournaments/registration/SelectableRegistrationOptions';
import { FieldContainer } from '../../components/tournaments/registration/FieldContainer';
import { fetchedOptionsOf } from '../../../functions/src/util/tournaments/fetchedOptionsOf';
import { useFetchInGameId } from './useFetchInGameId';

export const useSelectInGameId = () => {
  const { gameId, registrationOptions } = useTournamentRegistration();
  const { connectedIds = [], availableProviders = [] } = useFetchInGameId({
    gameId,
  });

  const [selectedInGameId, setSelectedInGameId] = useState<
    string | undefined
  >();

  const firstConnectedId = useMemo(() => {
    return connectedIds[0]?.value;
  }, [connectedIds]);

  useEffect(() => {
    if (firstConnectedId) {
      setSelectedInGameId(firstConnectedId);
    }
  }, [firstConnectedId]);

  const optionFiltered = useMemo(() => {
    return fetchedOptionsOf(registrationOptions, 'inGameId');
  }, [registrationOptions]);

  const selectInGameId = useCallback((inGameId?: string) => {
    setSelectedInGameId(inGameId);
  }, []);

  const providerOptions = useMemo(() => {
    return [...connectedIds, ...availableProviders].map((detail) => {
      const { value } = detail;
      return {
        ...detail,
        isSelected: !!value && value === selectedInGameId,
        onSelect: () => {
          return selectInGameId(value);
        },
      };
    });
  }, [availableProviders, connectedIds, selectInGameId, selectedInGameId]);
  const FetchedInGameIds = useMemo(() => {
    if (!optionFiltered) {
      return;
    }
    return (
      <FieldContainer
        preamble={optionFiltered.preamble}
        postambleLink={optionFiltered.postambleLink}
        postambleText={optionFiltered.postambleText}
      >
        <SelectableRegistrationOptions providerDetails={providerOptions} />
      </FieldContainer>
    );
  }, [optionFiltered, providerOptions]);

  const isInGameIdSubmittable = useMemo(() => {
    if (!FetchedInGameIds) {
      return true;
    }
    return !!selectedInGameId;
  }, [FetchedInGameIds, selectedInGameId]);

  return { FetchedInGameIds, selectedInGameId, isInGameIdSubmittable };
};
