import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { useRef, createRef, useMemo, useState, useCallback } from 'react';
import { useConfirmDialog } from 'src/hooks/useConfirmDialog';
import { useMatchActions } from 'src/hooks/useMatchActions';
import { MatchHeader } from './MatchHeader';
import { MatchAggregated } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { MatchScorer } from './MatchScorer';
import { useMatch } from '../tournaments/match/MatchProvider';
import { MatchDetailsTeams } from '../tournaments/match/details/MatchDetailsTeams';
import { extractTeamName } from '../../../functions/src/util/tournaments/extractTeamName';
import { MatchControls } from './MatchControls';

export type MatchDrawerMatch = Pick<MatchAggregated<Date>, 'id' | 'startTime'> &
  Required<Pick<MatchAggregated<Date>, 'team1' | 'team2'>>;

export type MatchDrawerProps = {
  gameId: string;
  tournamentId: string;
  onClose: () => void;
};

const MatchDrawerUnmemoized = ({
  onClose,
  gameId,
  tournamentId,
}: MatchDrawerProps) => {
  const {
    team1,
    team2,
    id: matchId,
    bestOf,
    team1Scores,
    team2Scores,
  } = useMatch();

  const { declareWinner } = useMatchActions({
    gameId,
    matchId,
    tournamentId,
  });

  const { open: openConfirmDialog, close: closeConfirmDialog } =
    useConfirmDialog(matchId);

  const teams = useMemo(() => {
    const team1Formatted = team1 ? [team1] : [];
    const team2Formatted = team2 ? [team2] : [];
    return [...team1Formatted, ...team2Formatted];
  }, [team1, team2]);
  const scores = useMemo(() => {
    const team1ScoresFormatted = team1Scores?.length ? [team1Scores] : [];
    const team2ScoresFormatted = team2Scores?.length ? [team2Scores] : [];
    return [...team1ScoresFormatted, ...team2ScoresFormatted];
  }, [team1Scores, team2Scores]);

  const teamRefs = useRef(
    teams.map(() => {
      return createRef<HTMLInputElement>();
    }),
  );
  const sessionScores = teamRefs.current.map((ref) => {
    return parseInt(!!ref.current ? ref.current.value : '0');
  });

  const [scoreInput, setScoreInput] = useState<number[][]>([[], []]);
  const changeScore = useCallback(
    (teamIndex: number, gameIndex: number, score: number) => {
      const updatedScores = [...scoreInput];
      updatedScores[Number(teamIndex)][Number(gameIndex)] = score;
      setScoreInput(updatedScores);
    },
    [scoreInput],
  );
  const duplicateScores = scoreInput[0].some((_, index) => {
    const valuesAtIndex = scoreInput.map((score) => {
      return score[Number(index)];
    });
    const uniqueValues = new Set(valuesAtIndex);
    return uniqueValues.size !== scoreInput.length;
  });

  const setScores = useCallback(() => {
    const scoresFromRefs = sessionScores.map((teamGameScore, index) => {
      return [teamGameScore, ...(scores[Number(index)] ?? [])];
    });
    const winnerIndex = sessionScores.indexOf(Math.max(...sessionScores));
    const winnerId = teams[Number(winnerIndex)]?.id;
    if (!winnerId) {
      console.warn('Could not determine a winner from these scores');
      return;
    }

    return openConfirmDialog({
      title: 'Are you sure the scores are correct?',
      confirmFunction: async () => {
        await declareWinner(bestOf, winnerId, scoresFromRefs);
        closeConfirmDialog();
        onClose();
      },
    });
  }, [
    bestOf,
    closeConfirmDialog,
    declareWinner,
    sessionScores,
    onClose,
    openConfirmDialog,
    scores,
    teams,
  ]);

  const teamNames = useMemo(() => {
    if (!teams.length) {
      return;
    }
    return teams.map((team) => {
      return extractTeamName(team);
    });
  }, [teams]);

  return (
    <Stack sx={{ mt: 16 }} spacing={4}>
      {teamNames?.every((team): team is string => {
        return !!team;
      }) && <MatchHeader teamNames={teamNames} />}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <MatchDetailsTeams />
      </Box>
      {teamNames?.every((team): team is string => {
        return !!team;
      }) && (
        <MatchScorer
          teamNames={teamNames}
          teamRefs={teamRefs}
          setSessionScore={setScores}
          onScoreChange={changeScore}
          disabled={duplicateScores}
        />
      )}

      <MatchControls
        teams={teams}
        gameId={gameId}
        tournamentId={tournamentId}
        onClose={onClose}
      />
    </Stack>
  );
};

export const MatchDrawer = memo(MatchDrawerUnmemoized);
