import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { LoadingButton, LoadingButtonProps } from '../../buttons/LoadingButton';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';

export type CheckInButtonProps = Omit<
  LoadingButtonProps,
  'onClick' | 'variant' | 'color' | 'type'
> & {
  idToCheckIn?: string;
};

const CheckInButtonUnmemoized = ({
  idToCheckIn,
  ...props
}: CheckInButtonProps) => {
  const { checkIn } = useRegistrationFunctions();

  const checkInFn = useCallback(async () => {
    if (!idToCheckIn) {
      await checkIn(true);
      return;
    }
    await checkIn(false, idToCheckIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToCheckIn]);

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      color="secondary"
      onClick={checkInFn}
      {...props}
    >
      Check In {!idToCheckIn ? 'Everyone' : undefined}
    </LoadingButton>
  );
};

export const CheckInButton = memo(CheckInButtonUnmemoized);
