import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useReferee } from 'src/contexts/RefereeContext';

export type DetailsTeamProps = {
  details: Record<string, unknown>[];
  summaries: string[];
  expanded: string | null;
  setExpanded: Dispatch<SetStateAction<string | null>>;
};

export const DetailsTeam = ({
  details,
  summaries,
  expanded,
  setExpanded,
}: DetailsTeamProps) => {
  const { detailsOptions } = useReferee();
  const handleChange = useCallback(
    (id: string) => {
      return setExpanded((prev) => {
        return prev === id ? null : id;
      });
    },
    [setExpanded],
  );

  return (
    <Stack direction="column">
      {(detailsOptions?.captainOnly ? [details[0]] : details).map(
        (userDetails, index) => {
          const id = userDetails.userId as string;
          const summary = summaries[Number(index)];
          return (
            <Accordion
              key={id}
              onChange={() => {
                return handleChange(id);
              }}
              expanded={detailsOptions?.summaryOnly ? false : expanded === id}
            >
              <AccordionSummary>{summary}</AccordionSummary>
              {!detailsOptions?.summaryOnly && (
                <AccordionDetails>
                  <Stack>
                    {Object.entries(userDetails).map(([key, value]) => {
                      if (!value) {
                        return null;
                      }
                      return (
                        <Typography
                          key={`${userDetails.id}-${key}`}
                        >{`${key}: ${value}`}</Typography>
                      );
                    })}
                  </Stack>
                </AccordionDetails>
              )}
            </Accordion>
          );
        },
      )}
    </Stack>
  );
};
