import { PriceCrypto } from './CryptoToFiatConverter';

export type TokenDetails = {
  priceMetadata: Pick<PriceCrypto, 'isUsdPegged' | 'isWrapped'>;
  displayName: Uppercase<string>;
  fullName: string;
  iconUrlSuffix: string;
};

export type CompositeTokenId = `${number}-0x${Lowercase<string>}`;

// TODO: Can probably restructure this to remove duplicate information,
// If we agree upon a standard for `fullName` & `iconUrlSuffix`
// e.g., always use `${displayName}.svg` for icon url, always use
export const PRICEABLE_TOKENS_DETAILS_MAP: Record<
  CompositeTokenId,
  TokenDetails
> = {
  // Native + Wrapped ERC20
  '1-0x0000000000000000000000000000000000000000': {
    iconUrlSuffix: 'wallet-ethereum.svg',
    priceMetadata: {
      isUsdPegged: false,
      isWrapped: false,
    },
    displayName: 'ETH',
    fullName: 'Ethereum',
  },
  '1-0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
    iconUrlSuffix: 'wallet-ethereum.svg',
    priceMetadata: {
      isWrapped: true,
      isUsdPegged: false,
    },
    displayName: 'WETH',
    fullName: 'Wrapped Ethereum',
  },
  '137-0x0000000000000000000000000000000000000000': {
    iconUrlSuffix: 'wallet-polygon.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: false,
    },
    displayName: 'MATIC',
    fullName: 'MATIC',
  },
  '137-0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': {
    iconUrlSuffix: 'wallet-polygon.svg',
    priceMetadata: {
      isWrapped: true,
      isUsdPegged: false,
    },
    displayName: 'WMATIC',
    fullName: 'Wrapped Polygon',
  },
  '43114-0x0000000000000000000000000000000000000000': {
    iconUrlSuffix: 'wallet-avalanche.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: false,
    },
    displayName: 'AVAX',
    fullName: 'Avalanche',
  },
  '43114-0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7': {
    iconUrlSuffix: 'wallet-avalanche.svg',
    priceMetadata: {
      isWrapped: true,
      isUsdPegged: false,
    },
    displayName: 'WAVAX',
    fullName: 'Wrapped Avalanche',
  },
  '56-0x0000000000000000000000000000000000000000': {
    iconUrlSuffix: 'wallet-binance.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: false,
    },
    displayName: 'BNB',
    fullName: 'Binance Coin',
  },
  '56-0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': {
    iconUrlSuffix: 'wallet-binance.svg',
    priceMetadata: {
      isWrapped: true,
      isUsdPegged: false,
    },
    displayName: 'WBNB',
    fullName: 'Wrapped Binance Coin',
  },
  // USDC
  '1-0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
    iconUrlSuffix: 'USDC.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDC',
    fullName: 'Ethereum USDC',
  },
  '137-0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': {
    iconUrlSuffix: 'USDC.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDC',
    fullName: 'Polygon USDC',
  },
  '43114-0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e': {
    iconUrlSuffix: 'USDC.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDC',
    fullName: 'Avalanche USDC',
  },
  '56-0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d': {
    iconUrlSuffix: 'USDC.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDC',
    fullName: 'Binance USDC',
  },
  // Tether (USDT)
  '1-0xdac17f958d2ee523a2206206994597c13d831ec7': {
    iconUrlSuffix: 'USDT.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDT',
    fullName: 'Ethereum USDT',
  },
  '137-0xc2132d05d31c914a87c6611c10748aeb04b58e8f': {
    iconUrlSuffix: 'USDT.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDT',
    fullName: 'Polygon USDT',
  },
  // TODO which bridged token?}
  '43114-0xc7198437980c041c805a1edcba50c1ce5db95118': {
    iconUrlSuffix: 'USDT.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'USDT',
    fullName: 'Avalanche USDT',
  },

  // Dai (DAI)
  '1-0x6b175474e89094c44da98b954eedeac495271d0f': {
    iconUrlSuffix: 'DAI.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'DAI',
    fullName: 'Ethereum DAI',
  },
  '137-0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': {
    iconUrlSuffix: 'DAI.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'DAI',
    fullName: 'Polygon DAI',
  },
  '43114-0xd586e7f844cea2f87f50152665bcbc2c279d8d70': {
    iconUrlSuffix: 'DAI.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'DAI',
    fullName: 'Avalanche DAI',
  },
  '56-0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': {
    iconUrlSuffix: 'DAI.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'DAI',
    fullName: 'Binance DAI',
  },
  //TrueUSD (TUSD)
  '1-0x0000000000085d4780b73119b644ae5ecd22b376': {
    iconUrlSuffix: 'TUSD.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'TUSD',
    fullName: 'Ethereum TUSD',
  },
  '137-0x2e1ad108ff1d8c782fcbbb89aad783ac49586756': {
    iconUrlSuffix: 'TUSD.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'TUSD',
    fullName: 'Polygon TUSD',
  },
  '43114-0x1c20e891bab6b1727d14da358fae2984ed9b59eb': {
    iconUrlSuffix: 'TUSD.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'TUSD',
    fullName: 'Avalanche TUSD',
  },
  '56-0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9': {
    iconUrlSuffix: 'TUSD.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'TUSD',
    fullName: 'Binance TUSD',
  },
  // BUSD
  '56-0xe9e7cea3dedca5984780bafc599bd69add087d56': {
    iconUrlSuffix: 'BUSD.svg',
    priceMetadata: {
      isWrapped: false,
      isUsdPegged: true,
    },
    displayName: 'BUSD',
    fullName: 'Binance USD',
  },
};
