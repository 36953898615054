import { SetShipParams } from '../../../functions/src/callable/ship/setShip.f';

export type RejectShipRequestParams = Pick<
  SetShipParams,
  'userId' | 'groupId' | 'groupType' | 'subgroupId'
>;

export const rejectShipRequest = async (props: RejectShipRequestParams) => {
  const { setShip } = await import('../../firebaseCloud/ship/setShip');
  const params: SetShipParams = {
    ...props,
    action: 'rejectRequest',
  };

  await setShip(params);
};
