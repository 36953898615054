import { useState, useEffect, useCallback } from 'react';

export type QueueTask = () => Promise<void> | void;

export function useQueue() {
  const [queue, setQueue] = useState<{
    isProcessing: boolean;
    tasks: QueueTask[];
  }>({ isProcessing: false, tasks: [] });

  useEffect(() => {
    const { tasks, isProcessing } = queue;
    if (tasks.length === 0 || isProcessing) return;

    const task = tasks[0];
    setQueue((prev) => {
      return {
        isProcessing: true,
        tasks: prev.tasks.slice(1),
      };
    });

    Promise.resolve(task()).finally(() => {
      setQueue((prev) => {
        return {
          isProcessing: false,
          tasks: prev.tasks,
        };
      });
    });
  }, [queue]);

  const enqueue = useCallback((task: QueueTask) => {
    setQueue((prev) => {
      return {
        isProcessing: prev.isProcessing,
        tasks: [...prev.tasks, task],
      };
    });
  }, []);

  return {
    ...queue,
    enqueue,
  };
}
