import { MatchCellButton } from './MatchCellIButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useState, useCallback } from 'react';
import { memo } from '../../../../util/memo';
import { MatchDrawer } from '../../../referee-portal/MatchDrawer';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

export type CellButtonRefereeProps = {
  gameId: string;
  tournamentId: string;
};

const CellButtonRefereeUnmemoized: React.FC<CellButtonRefereeProps> = ({
  gameId,
  tournamentId,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const openMatchDrawer = useCallback(() => {
    return setIsOpen(true);
  }, []);

  const closeMatchDrawer = useCallback(() => {
    return setIsOpen(false);
  }, []);

  return (
    <>
      <MatchCellButton Icon={EditRoundedIcon} onClick={openMatchDrawer} />
      {isOpen && (
        <Drawer
          open
          anchor="right"
          PaperProps={{
            sx: {
              background: theme.palette.background.elevationSolid[8],
              p: 8,
              maxWidth: 600,
            },
          }}
          onClose={closeMatchDrawer}
          sx={{ zIndex: theme.zIndex.appBar + 2 }}
        >
          <MatchDrawer
            gameId={gameId}
            tournamentId={tournamentId}
            onClose={closeMatchDrawer}
          />
        </Drawer>
      )}
    </>
  );
};

export const CellButtonReferee = memo(CellButtonRefereeUnmemoized);
