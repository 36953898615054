import { memo } from '../util/memo';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material/styles';
import { StatusEnum } from '../../functions/src/types/firestore/User';

export type Kind = 'Icon' | 'Typography' | 'Badge';

export type StatusProps = {
  kind: Kind;
  statusEnum: StatusEnum;
  children?: JSX.Element;
  sx?: SxProps;
};

const statusColorMap = {
  online: 'secondary',
  offline: 'disabled',
  away: 'warning',
  'do not disturb': 'error',
} as const;

const toColor = (statusEnum: StatusEnum) => {
  return statusColorMap[statusEnum] || 'primary';
};

export const Status = memo(function StatusUnmemoized({
  kind,
  statusEnum,
  children,
  sx = {},
}: StatusProps) {
  const theme = useTheme();

  const color = toColor(statusEnum);

  return (
    <>
      {kind === 'Icon' && (
        <Box sx={{ lineHeight: 0, ...sx }}>
          <svg
            height={sx.height ?? 'auto'}
            width={sx.width ?? 'auto'}
            viewBox="0 0 100 100"
          >
            <circle
              cx="50"
              cy="50"
              r="37"
              stroke="white"
              strokeWidth="13"
              fill={theme.palette[`${color}`].main}
            />
          </svg>
        </Box>
      )}
      {kind === 'Typography' && (
        <Typography
          sx={{ display: 'inline', ...sx }}
          component="span"
          variant="body2"
          color={color}
        >
          {statusEnum}
        </Typography>
      )}
      {kind === 'Badge' && (
        <Badge
          color={color}
          overlap="circular"
          badgeContent=" "
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={sx}
        >
          {children}
        </Badge>
      )}
    </>
  );
});
