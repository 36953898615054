import Menu from '@mui/material/Menu';
import { memo } from '../../util/memo';
import { useOptions } from '../../hooks/voice-chat/useOptions';

export type MoreOptionsMenuProps = {
  anchorElement: HTMLElement | null;
  onClose: () => void;
};

const MoreOptionsMenuUnmemoized = ({
  anchorElement,
  onClose,
}: MoreOptionsMenuProps) => {
  const options = useOptions({ onActionComplete: onClose });

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ mt: 8 }}
    >
      {options}
    </Menu>
  );
};

export const MoreOptionsMenu = memo(MoreOptionsMenuUnmemoized);
