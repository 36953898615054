import Button from '@mui/material/Button';
import { useState } from 'react';
import { DialogCentered } from 'src/components/DialogCentered';
import { DetailsDisplaySettings } from './DetailsDisplaySettings';

export function DetailsDisplaySettingsButton() {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ height: 40, p: 2, m: 1 }}
        onClick={() => {
          return setDialogOpen((prev) => {
            return !prev;
          });
        }}
      >
        details options
      </Button>
      <DialogCentered
        open={dialogOpen}
        onClose={() => {
          return setDialogOpen(false);
        }}
      >
        <DetailsDisplaySettings />
      </DialogCentered>
    </>
  );
}
