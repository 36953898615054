import { useTheme } from '@mui/material/styles';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { TableStatus } from './TableStatus';
import { DetailsDisplaySettingsButton } from './DetailsDisplaySettingsButton';

export const TableToolbar = () => {
  const theme = useTheme();
  return (
    <GridToolbarContainer
      sx={{
        backgroundColor: theme.palette.background.dark,
        borderRadius: '10px',
        p: 1,
        mb: 1,
      }}
    >
      <GridToolbarColumnsButton />
      <DetailsDisplaySettingsButton />
      <TableStatus />
    </GridToolbarContainer>
  );
};
