import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../useWindowDimensions';
import { getItem, setItem } from '../../util/webStorage';

export const NUM_VISIBLE_COLUMNS_SSR = 7;
export const LOCAL_STORAGE_KEY = 'numVisibleColumns';

export function useNumVisibleColumns() {
  const firstEventRef = useRef<HTMLDivElement>();
  const windowWidth = useWindowDimensions();

  const [numVisibleColumns, setNumVisibleColumns] = useState(() => {
    const storedValue = getItem<number>(LOCAL_STORAGE_KEY);
    return storedValue
      ? parseInt(String(storedValue), 10)
      : NUM_VISIBLE_COLUMNS_SSR;
  });

  useEffect(() => {
    if (firstEventRef.current) {
      const calendarDayWidth = firstEventRef.current.offsetWidth;
      const calendarDailyWidth =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        firstEventRef.current.parentElement!.parentElement!.parentElement!
          .parentElement!.parentElement!.offsetWidth;

      if (calendarDayWidth > 0) {
        const calculatedNumVisibleColumns = Math.floor(
          calendarDailyWidth / calendarDayWidth,
        );
        setNumVisibleColumns(calculatedNumVisibleColumns);
        setItem(LOCAL_STORAGE_KEY, calculatedNumVisibleColumns.toString());
      }
    }
  }, [firstEventRef, windowWidth]);

  return { numVisibleColumns, firstEventRef };
}
