import { useCallback } from 'react';
import { FormatDateTimeOptions, formatDateTime } from 'src/util/dates/Dates';

export const useDateFormatter = (options: FormatDateTimeOptions = {}) => {
  return useCallback(
    (date: Date) => {
      return formatDateTime(date, options);
    },
    [options],
  );
};
