import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useLocalStorage } from '../../contexts/LocalStorage';
import { ViewerToken } from '../../pages/api/mux/generateViewerToken';
import { DAY_IN_MS } from '../../util/date/conversions';
import stringify from 'json-stringify-safe';

export type FetchViewerTokenParams = {
  assetId: string;
  assetType?: 'video' | 'asset' | 'playback' | 'live_stream';
};

export const useFetchViewerToken = () => {
  const { getItem, setItem } = useLocalStorage();

  const fetchViewerToken = async ({
    assetId,
    assetType,
  }: FetchViewerTokenParams) => {
    const tokenKey = `muxViewerToken_${assetId}`;
    const storedToken = getItem<ViewerToken>(tokenKey);

    if (!storedToken) {
      const response = await fetch(`/api/mux/generateViewerToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: stringify({ assetId, assetType }),
      });
      if (!response.ok) {
        throw new HttpsError('internal', 'Failed to fetch viewer token', {
          response,
        });
      }

      const data = await response.json();
      // eslint-disable-next-line no-shadow
      const token = data.token;
      // eslint-disable-next-line no-shadow
      const expiresAt = Date.now() + DAY_IN_MS;

      setItem<ViewerToken>(tokenKey, { token, expiresAt });

      return token;
    }

    const { token, expiresAt } = storedToken;

    if (Date.now() >= expiresAt) {
      return;
    }

    return token;
  };

  return { fetchViewerToken };
};
