import { TournamentPhase } from '../../../types/firestore/Game/Tournament';
import { fillTemplate } from '../../algoliaRealtime/fillTemplate';
import { PREEMPTIVE_FILTER_TEMPLATES } from '../preemption/templates';

export function teamFilters(
  phase: TournamentPhase,
  tournamentId: string,
  isWaitlistActive: boolean,
) {
  const template = isWaitlistActive
    ? PREEMPTIVE_FILTER_TEMPLATES.waitlist
    : phase === 'live' ||
      phase === 'finished' ||
      phase === 'payout' ||
      phase === 'ready'
    ? PREEMPTIVE_FILTER_TEMPLATES.participant
    : PREEMPTIVE_FILTER_TEMPLATES.guestlist;
  return fillTemplate(template, tournamentId);
}
