import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../gradients/GradientTypography';
import { useReferee } from 'src/contexts/RefereeContext';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { memo } from '../../util/memo';
import { SwitchPanel } from '../SwitchPanel';
import Tooltip from '@mui/material/Tooltip';

export const USEFUL_INFO = [
  'To declare the winner of a bracket match, please click the edit icon at the center of the match cell and follow the instructions on the drawer that opens up on your right.',
  'To update scores in a leaderboard, double click the score cell to begin making changes. Rankings and prizes will recalculate automatically once you press enter.',
];

export type RefereeHeaderProps = {
  info?: string[];
};

const RefereeHeaderUnmemoized = ({
  info = USEFUL_INFO,
}: RefereeHeaderProps) => {
  const { openReferee, closeReferee, isReferee } = useReferee();

  const toggleReferee = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!!event.target.checked) {
        openReferee();
      } else {
        closeReferee();
      }
    },
    [closeReferee, openReferee],
  );

  const guide = useMemo(() => {
    return (
      <Stack
        direction="column"
        alignItems="center"
        flex={1}
        spacing={2}
        sx={{ pt: 2 }}
      >
        <GradientTypography
          variant="h5"
          gradientColor="primary.vertical"
          textTransform="uppercase"
        >
          How To Use:
        </GradientTypography>
        <Stack justifyContent="center" sx={{ px: 4, py: 3 }} spacing={2}>
          {info.map((point) => {
            return (
              <Typography key={point} component="li">
                {point}
              </Typography>
            );
          })}
        </Stack>
      </Stack>
    );
  }, [info]);

  return (
    <Stack direction="row" justifyContent="center">
      <Tooltip title={guide} placement="top" arrow>
        <div>
          <SwitchPanel
            onChange={toggleReferee}
            defaultChecked={isReferee}
            titleLeft={'Referee View'}
          />
        </div>
      </Tooltip>
    </Stack>
  );
};
export const RefereeHeader = memo(RefereeHeaderUnmemoized);
