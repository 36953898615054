import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { ReactNode, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { useRemoveAuthQueryParams } from 'src/hooks/useRemoveAuthQueryParams';

export type AuthenticationFlowCompleteDialogProps = {
  title: string;
  description?: string | ReactNode;
  buttonText?: string;
  next?: string;
};

const AuthenticationFlowCompleteDialogUnmemoized = ({
  title,
  description,
  buttonText,
  next,
}: AuthenticationFlowCompleteDialogProps) => {
  const { go } = useWizard();
  const { removeAuthQueryParams } = useRemoveAuthQueryParams();
  const { uid } = useAuth();

  const buttonMessage = !!uid
    ? !!buttonText
      ? buttonText
      : 'Close'
    : 'Sign In';
  const takeNextStep = useCallback(() => {
    try {
      if (!uid) {
        go('Enter Email');
        return;
      }
      go(next);
    } finally {
      removeAuthQueryParams();
    }
  }, [go, next, removeAuthQueryParams, uid]);

  return (
    <Stack width="100%">
      <DialogBodyStandard
        title={title}
        description={description}
        gradientColor="primary.vertical"
      >
        <Box textAlign={'center'}>
          <GradientIcon
            IconComponent={CheckCircleIcon}
            gradientColor="secondary.horizontal"
            sx={{ fontSize: '75px' }}
          />
        </Box>
        <Stack width="100%" alignSelf={'center'}>
          <Button variant="contained" fullWidth onClick={takeNextStep}>
            {buttonMessage}
          </Button>
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationFlowCompleteDialog = memo(
  AuthenticationFlowCompleteDialogUnmemoized,
);
