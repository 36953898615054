import { useCallback } from 'react';
import { useWizardDialog } from './useWizardDialog';
import {
  DialogBodyConfirmationWizard,
  DialogBodyConfirmationWizardProps,
} from 'src/components/wizard/DialogBodyConfirmationWizard';
import { OutcomeSubmit } from 'src/components/data-entry-wizard/OutcomeSubmit';

export const useWizardDialogConfirmation = () => {
  const { open: openWizardDialog, close } = useWizardDialog();

  const open = useCallback(
    (props: DialogBodyConfirmationWizardProps) => {
      openWizardDialog({
        storeDefault: {
          message: '',
          success: false,
        },
        elements: {
          DialogBodyConfirmationWizard: (
            <DialogBodyConfirmationWizard {...props} />
          ),
          OutcomeSubmit: <OutcomeSubmit />,
        },
        elementIdEntry: 'DialogBodyConfirmationWizard',
      });
    },
    [openWizardDialog],
  );

  return { open, close };
};
