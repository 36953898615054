import { useState, useRef, useCallback } from 'react';
import { HttpsCallable } from 'firebase/functions';

export type UseSequentialUpdateParams<TInput, TResult> = {
  callableFn: HttpsCallable<TInput, TResult>;
};

export const useSequentialUpdate = <TInput, TResult>({
  callableFn,
}: UseSequentialUpdateParams<TInput, TResult>) => {
  const [value, setValue] = useState<TInput | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const buffer = useRef<TInput | null>(null);

  const updateValue = useCallback(
    async (newValue: TInput) => {
      if (isUpdating) {
        buffer.current = newValue;
        return;
      }

      if (
        value !== newValue ||
        (buffer.current !== null && buffer.current !== value)
      ) {
        setIsUpdating(true);

        try {
          await callableFn(newValue);
          setValue(newValue);
          setIsUpdating(false);

          if (buffer.current !== null && buffer.current !== value) {
            const bufferedValue = buffer.current;
            buffer.current = null;
            await updateValue(bufferedValue);
          }
        } catch (error) {
          setIsUpdating(false);
        }
      } else {
        buffer.current = null;
      }
    },
    [value, isUpdating, callableFn],
  );

  return { value, updateValue };
};
