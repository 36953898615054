import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoadingButton } from '../buttons/LoadingButton';
import { memo } from '../../util/memo';

const MatchControlButtonsUnmemoized = ({ onConfirm }) => {
  return (
    <Box>
      <Typography
        variant="overline"
        color="text.secondary"
        sx={{ fontSize: 18 }}
      >
        Delay/Cancel/Reset Match
      </Typography>
      <Stack direction="row" spacing="8px" height={80}>
        <LoadingButton
          onClick={async () => {
            await onConfirm('delay');
          }}
          variant="outlined"
          color="warning"
          fullWidth
        >
          Delay Match
        </LoadingButton>
        <LoadingButton
          onClick={async () => {
            await onConfirm('cancel');
          }}
          variant="outlined"
          color="error"
          fullWidth
        >
          Cancel Match
        </LoadingButton>
        <LoadingButton
          onClick={async () => {
            await onConfirm('reset');
          }}
          variant="outlined"
          color="secondary"
          fullWidth
        >
          Reset Match
        </LoadingButton>
      </Stack>
    </Box>
  );
};
export const MatchControlButtons = memo(MatchControlButtonsUnmemoized);
