import { AdUnitMap, AdSize, AspectRatio } from './AspectRatio';
import { toConstituents } from './toFraction';

export function findLargestFit(
  adUnitMap: AdUnitMap,
  targetWidth: number,
  targetHeight: number,
) {
  const candidates = Object.entries(adUnitMap) as [AspectRatio, string][];

  const fittingSizes = candidates
    .map(([aspectRatio, adUnitId]): AdSize => {
      const [adWidth, adHeight] = toConstituents(aspectRatio);
      return { width: adWidth, height: adHeight, adUnitId, aspectRatio };
    })
    .filter(({ width: adWidth, height: adHeight }) => {
      return adWidth <= targetWidth && adHeight <= targetHeight;
    });

  if (fittingSizes.length === 0) {
    return undefined;
  }

  return fittingSizes.reduce((largest, current) => {
    return current.width * current.height > largest.width * largest.height
      ? current
      : largest;
  });
}
