import { useEffect } from 'react';
import { PromiseOrValue } from '../../functions/src/types/utility-types';

export const useKeypressListener = <TKey extends string>(
  keys: TKey[],
  onKeypress?: () => PromiseOrValue<void>,
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (keys.includes(event.code as TKey)) {
        event.preventDefault();
        onKeypress?.();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [keys, onKeypress]);
};
