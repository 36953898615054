import { memo } from '../../../../util/memo';
import { ColumnDesktop } from './ColumnDesktop';
import { calcColumnHeightLoser } from './calcColumnHeight';
import Stack from '@mui/material/Stack';
import { ColumnLoserProps } from './ColumnWrapperLoser';

function ColumnCenterLoserUnmemoized({ Pairs }: ColumnLoserProps) {
  return (
    <ColumnDesktop
      columnHeightCalculator={calcColumnHeightLoser}
      alignSelf="flex-start"
      alignItems="unset"
      spacing={0}
      justifyContent="space-between"
      width="unset"
    >
      {Pairs.map(([prior, latter], index) => {
        return (
          <Stack
            key={`column-center-loser-${index}`}
            width={124}
            height="100%"
            alignItems="center"
            spacing={4}
          >
            {latter}
            {prior}
          </Stack>
        );
      })}
    </ColumnDesktop>
  );
}

export const ColumnCenterLoser = memo(ColumnCenterLoserUnmemoized);
