/* eslint-disable @typescript-eslint/no-explicit-any */
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';
import { ChangeEvent, MouseEvent } from 'react';
import { memo } from '../../util/memo';

export type InputPropsAsync = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange'
> & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export type ButtonPropsAsync = Omit<ButtonProps, 'onClick'> & {
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => Promise<any>;
};

export type LoadingButtonProps = ButtonPropsAsync & {
  inputProps?: InputPropsAsync;
  isLoading?: boolean;
  loadingTitle?: string;
};

const LoadingButtonUnmemoized = ({
  isLoading: isLoadingExternal,
  inputProps,
  loadingTitle = '',
  ...props
}: LoadingButtonProps) => {
  const { onClick, color, sx: sxExternal } = props;

  const [isLoadingHandler, asyncHandler] = useLoadingHandler<any>(
    inputProps?.onChange || onClick,
  );
  const isLoading = isLoadingExternal || isLoadingHandler;

  const propsLoading: ButtonProps = {
    ...props,
    endIcon: undefined,
    startIcon: undefined,
    children: undefined,
  };
  const propsConditional = isLoading ? propsLoading : props;
  const propsWithComponent = {
    ...propsConditional,
    component: inputProps ? 'label' : undefined,
  } as ButtonProps;

  return (
    <Button
      {...propsWithComponent}
      sx={{
        ...sxExternal,
      }}
      onClick={inputProps ? undefined : asyncHandler}
    >
      {!isLoading ? (
        <span style={{ lineHeight: 1.5 }}>{props.children}</span>
      ) : (
        <Stack direction="row" alignItems="center" gap={2}>
          {loadingTitle}
          <CircularProgress size="1.5rem" color={color} />
        </Stack>
      )}
      {inputProps && <input {...inputProps} />}
    </Button>
  );
};
export const LoadingButton = memo(LoadingButtonUnmemoized);
