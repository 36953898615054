export function isLoading<TVal>(value: TVal | undefined): value is undefined {
  return value === undefined;
}

/**
 * @remarks
 * undefined is 'loading'.
 * null is no longer loading and known to be non-existent.
 */
export type Loadable<TVal> = TVal | null | undefined;
