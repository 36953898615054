import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { memo } from '../../../../util/memo';

export type CompetitorPositionProps = {
  position: number;
};

const CompetitorPositionUnmemoized: React.FC<CompetitorPositionProps> = ({
  position,
}) => {
  return (
    <GradientTypography
      gradientColor="primary.vertical"
      sx={{
        alignSelf: 'center',
        width: `calc(24px + ${Math.ceil(
          Math.log10((position || 0) + 1),
        )} * 4.5px)`,
      }}
    >
      #{position}
    </GradientTypography>
  );
};

export const CompetitorPosition = memo(CompetitorPositionUnmemoized);
