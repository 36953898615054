import { BasicInput2, FieldInputProps2 } from './BasicInput2';

// eslint-disable-next-line @blumintinc/blumint/require-memo
export const NumberInput2 = ({
  value,
  onChange,
  ...rest
}: FieldInputProps2<number>): JSX.Element => {
  const handleNumberChange = (fieldValue: string) => {
    if (onChange) {
      onChange(parseFloat(fieldValue));
    }
  };

  return (
    <BasicInput2
      inputType="number"
      {...rest}
      value={value !== undefined ? value.toString() : undefined}
      onChange={handleNumberChange}
    />
  );
};
