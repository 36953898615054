import { useCallback } from 'react';
import { useRouterState } from './routing/useRouterState';

export const useDeleteAccountRouter = () => {
  const [deleteAccount, setDeleteAccount] = useRouterState({
    key: 'delete-account',
    location: 'queryParam',
  });

  const open = useCallback(() => {
    setDeleteAccount('true');
  }, [setDeleteAccount]);

  const close = useCallback(() => {
    setDeleteAccount(undefined);
  }, [setDeleteAccount]);

  return { deleteAccount, open, close };
};
