import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';
import stringify from 'json-stringify-safe';
import { ExponentialBackoff } from '../../../../functions/src/util/apis/ExponentialBackoff';

export const fetchTotalViews = async (playbackId: string) => {
  const fetchViewsRateLimited = ExponentialBackoff.for429(async () => {
    return await fetch(`/api/mux/fetchTotalViews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: stringify({ playbackId }),
    });
  });

  const response = await fetchViewsRateLimited();
  if (!response.ok) {
    throw new HttpsError('internal', 'Failed to fetch total views', {
      response,
    });
  }

  const { data } = await response.json();
  return data[0]?.total_row_count;
};
