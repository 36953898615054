import {
  Context,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { memo } from '../../util/memo';
import { PrizePool } from '../../../functions/src/types/firestore/PrizePool';
import { EventDocument } from './create-event/CreateEventContext';
import { EditableStepsContextType } from './EditableStepsContext';

type LinkValidator<TEvent> = (
  event: TEvent,
  prizes: PrizePool['prizes'],
) => { isValid: boolean; errorMessage?: string };

export type LinkEventContextType<TEvent extends EventDocument> = {
  linkPrizePool: () => Promise<void>;
  selectedPrizePool: PrizePool | undefined;
  setSelectedPrizePool: Dispatch<SetStateAction<PrizePool | undefined>>;
  event: TEvent;
  prizePools: PrizePool[];
  linkValidator: LinkValidator<TEvent>;
};

// this 'any' is overwritten when the hook is used with a generic
const LinkEventContext = createContext<LinkEventContextType<any> | undefined>(
  undefined,
);

export type LinkEventProps<TEvent extends EventDocument> = {
  event: TEvent;
  prizePools: PrizePool[];
  isChangingLink: boolean;
  updateField?: EditableStepsContextType<TEvent>['updateField'];
  linkValidator: LinkValidator<TEvent>;
};

export const LinkEventProvider: FC<
  LinkEventProps<any> & { children: ReactNode }
> = memo(function LinkEventProviderUnmemoized({
  children,
  prizePools,
  event,
  updateField,
  // TODO:
  // isChangingLink,
  linkValidator,
}) {
  const [selectedPrizePool, setSelectedPrizePool] = useState<PrizePool>();

  const linkPrizePool = useCallback(async () => {
    if (!selectedPrizePool?.id) {
      return;
    }
    const { linkPrizePool } = await import(
      '../../firebaseCloud/tournament/organizer/linkPrizePool'
    );
    await linkPrizePool({
      prizePoolId: selectedPrizePool.id,
      tournamentId: event.id,
    });
    updateField &&
      (await updateField([
        {
          field: 'prizePoolId',
          value: selectedPrizePool.id,
          action: 'overwrite',
        },
        {
          field: 'payouts',
          value: [],
          action: 'overwrite',
        },
      ]));
  }, [event.id, selectedPrizePool?.id, updateField]);

  return (
    <LinkEventContext.Provider
      value={{
        linkPrizePool,
        selectedPrizePool,
        setSelectedPrizePool,
        event,
        prizePools,
        linkValidator,
      }}
    >
      {children}
    </LinkEventContext.Provider>
  );
});

export function useLinkEventContext<TEvent extends EventDocument>() {
  const context = useContext<LinkEventContextType<TEvent>>(
    LinkEventContext as unknown as Context<LinkEventContextType<TEvent>>,
  );
  if (!context) {
    throw new Error('useLinkEventContext must be used under LinkEventProvider');
  }
  return context;
}
