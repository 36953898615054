import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTournamentCheckIn } from './useTournamentCheckIn';
import { useCheckInDialog } from '../../components/tournaments/check-in/useCheckInDialog';
import { useAuth } from '../../contexts/AuthContext';
import { useSnackbarAlert } from '../useSnackbarAlert';
import Button from '@mui/material/Button';

export const CHECK_IN_ALERT_SNACKBAR_ID = 'CHECK_IN_ALERT_SNACKBAR_ID' as const;

export const usePendingCheckIns = () => {
  const { uid } = useAuth();
  const { tournamentsToCheckIn } = useTournamentCheckIn();
  const {
    open: openCheckInDialog,
    close: closeCheckInDialog,
    isOpen,
  } = useCheckInDialog();
  const [tournamentsSeen, setTournamentsSeen] = useState<string[]>([]);
  const { open: openSnackbarAlert, close: closeSnackBarAlert } =
    useSnackbarAlert(CHECK_IN_ALERT_SNACKBAR_ID);

  const viewButton = useMemo(() => {
    return (
      <Button
        onClickCapture={() => {
          if (!tournamentsToCheckIn?.length) {
            return;
          }
          setTournamentsSeen((prev) => {
            return prev.filter((id) => {
              return (
                id !== tournamentsToCheckIn[tournamentsToCheckIn.length - 1].id
              );
            });
          });
        }}
      >
        View
      </Button>
    );
  }, [tournamentsToCheckIn]);

  const closeUnnecessary = useCallback(() => {
    if (isOpen) {
      closeCheckInDialog();
      closeSnackBarAlert();
    }
  }, [closeCheckInDialog, closeSnackBarAlert, isOpen]);

  useEffect(() => {
    if (!tournamentsToCheckIn.length) {
      closeUnnecessary();
      return;
    }
    const unseenTournaments = tournamentsToCheckIn.filter((tournament) => {
      return !tournamentsSeen.includes(tournament.id);
    });
    if (!isOpen && !!uid && unseenTournaments.length > 0) {
      openCheckInDialog(unseenTournaments[0], () => {
        setTournamentsSeen((prev) => {
          return [...prev, unseenTournaments[0].id];
        });
      });
    }
    openSnackbarAlert({
      open: tournamentsToCheckIn.length > 0,
      autoHideDuration: 20000,
      message: `You have ${tournamentsToCheckIn.length} tournament${
        tournamentsToCheckIn.length > 1 ? 's' : ''
      } to check into.`,
      action: viewButton,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tournamentsToCheckIn.length,
    tournamentsSeen.length,
    isOpen,
    uid,
    viewButton,
    closeUnnecessary,
    openCheckInDialog,
    openSnackbarAlert,
  ]);
};
