import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { SxProps, useTheme } from '@mui/material/styles';
import { ImageOptimized, ImageOptimizedProps } from './image/ImageOptimized';
import {
  MASCOT_BACKGROUNDS,
  BACKGROUND_URL_PARAM,
} from '../../functions/src/util/user/defaultAvatar';
import { memo } from '../util/memo';

export const PLACEHOLDER_AVATAR_URL = '/assets/images/avatar-default.svg';

const extractBackgroundIndex = (src: string | undefined) => {
  if (!src || !src.includes(BACKGROUND_URL_PARAM)) return undefined;
  const params = new URLSearchParams(src.split('?')[1]);
  return parseInt(params.get(BACKGROUND_URL_PARAM) || '0');
};

export type AvatarNextProps = Omit<AvatarProps, 'sx'> &
  Pick<ImageOptimizedProps, 'objectFit' | 'width' | 'height'> & {
    sx?: SxProps & object;
  };

export const AvatarNext = memo(function AvatarNextUnmemoized({
  src,
  width = 37,
  height = 37,
  alt,
  objectFit = 'cover',
  sx = {},
  children,
  ...props
}: AvatarNextProps) {
  const theme = useTheme();

  if (!!children) {
    return (
      <Avatar {...props} sx={sx}>
        {children}
      </Avatar>
    );
  }

  const srcBackgroundIndex = extractBackgroundIndex(src);
  const background = srcBackgroundIndex
    ? MASCOT_BACKGROUNDS[Number(srcBackgroundIndex)]
    : theme.palette.background.elevation[16];

  return (
    <Avatar
      {...props}
      sx={{
        background,
        border: `1.5px solid ${theme.palette.background.elevationSolid[16]}`,
        '& img': {
          borderRadius: '50%',
        },
        height,
        width,
        ...sx,
      }}
    >
      <ImageOptimized
        src={src && src !== 'DEFAULT_IMAGE' ? src : PLACEHOLDER_AVATAR_URL}
        alt={alt}
        objectFit={objectFit}
        width={width}
        height={height}
      />
    </Avatar>
  );
});
