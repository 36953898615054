import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { LottieLoader } from '../../LottieLoader';
import { GradientIcon } from '../../gradients/GradientIcon';
import CheckIcon from '@mui/icons-material/CheckRounded';

export type LoadingCheckmarkProps = {
  state: 'loading' | 'checkmark' | null;
};

const LoadingCheckmarkUnmemoized: React.FC<LoadingCheckmarkProps> = ({
  state,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <LottieLoader
        sx={{
          height: '20px',
          width: '20px',
          display: state === 'loading' ? 'block' : 'none',
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
      <GradientIcon
        IconComponent={CheckIcon}
        sx={{
          height: '25px',
          width: '25px',
          display: state === 'checkmark' ? 'block' : 'none',
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </Stack>
  );
};

export const LoadingCheckmark = memo(LoadingCheckmarkUnmemoized);
