import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { useTournamentPermissions } from './useTournamentPermissions';
import { userInMatch } from '../../util/tournaments/userInMatch';
import { useActiveSessionStatus } from './useActiveSessionStatus';

export const useMatchPermissions = () => {
  const { uid } = useAuth();
  const { isActiveSessionCompleted } = useActiveSessionStatus();
  const { team1, team2, winner } = useMatch();

  const { isTournamentAdmin } = useTournamentPermissions();

  const isCompetitiveMatch = useMemo(() => {
    const teams = [team1, team2].filter(Boolean);
    return teams.length > 1;
  }, [team1, team2]);

  const canAccessMatch = useMemo(() => {
    return (
      isCompetitiveMatch &&
      (isTournamentAdmin || userInMatch([team1, team2], uid))
    );
  }, [isTournamentAdmin, team1, team2, uid, isCompetitiveMatch]);

  const canUploadScreenshot = useMemo(() => {
    return userInMatch([team1, team2], uid) || isTournamentAdmin;
  }, [uid, team1, team2, isTournamentAdmin]);

  const canScore = useMemo(() => {
    return (
      !winner && userInMatch([team1, team2], uid) && !isActiveSessionCompleted
    );
  }, [winner, team1, team2, uid, isActiveSessionCompleted]);

  return {
    isTournamentAdmin,
    canAccessMatch,
    canScore,
    canUploadScreenshot,
  };
};
