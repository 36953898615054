import {
  UnreadMessageCountGroup,
  UnreadMessageCountStructure,
} from '../../contexts/UnreadMessageCountContext';
import { Optional } from 'utility-types';
import { RequireOnlyOne } from '../../../functions/src/types/utility-types';
import {
  TOURNAMENT_PHASE_PRESENT,
  TournamentPhasePresent,
} from '../../../functions/src/types/firestore/Game/Tournament';
import {
  hasUnseenChannel,
  isSomeChannelUnseen,
  isSomeTournamentChannelUnseen,
  isChannelUnseen,
  isMatchChannelUnseen,
} from './findChannelUnseen';

export type HasUnreadMatchChannelParams = Optional<
  RequireOnlyOne<{
    groupId: string;
    tournamentId: string;
    channelId: string;
    matchId: string;
  }>
> & {
  unreadMessageCount: UnreadMessageCountStructure;
};

export const hasUnreadMatchChannel = (props: HasUnreadMatchChannelParams) => {
  const { tournamentId, groupId, channelId, matchId, unreadMessageCount } =
    props;

  const idNotGiven = !tournamentId && !matchId && !channelId && !groupId;

  if (Object.keys(props).length > 1 && idNotGiven) {
    return false;
  }

  const isPresentMatchChannel = (
    channel: UnreadMessageCountGroup['channels'][string],
  ): boolean => {
    return (
      TOURNAMENT_PHASE_PRESENT.includes(
        channel.phase as TournamentPhasePresent,
      ) && !!channel.matchId
    );
  };

  const matchGroups = Object.fromEntries(
    Object.entries(unreadMessageCount).filter(([_, group]) => {
      return Object.values(group.channels).some(isPresentMatchChannel);
    }),
  );

  if (!!groupId) {
    const matchGroup = unreadMessageCount[`${groupId}`];
    return (
      matchGroup && Object.values(matchGroup.channels).some(hasUnseenChannel)
    );
  }

  return Object.values(matchGroups).some((group) => {
    return (
      (idNotGiven && isSomeChannelUnseen(group)) ||
      (!!channelId && isChannelUnseen(group, channelId)) ||
      (!!tournamentId && isSomeTournamentChannelUnseen(group, tournamentId)) ||
      (!!matchId && isMatchChannelUnseen(group, matchId))
    );
  });
};
