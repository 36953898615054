import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../../gradients/GradientTypography';

export type PayoutInputWarningProps = {
  teamSize: number;
  countWinners: number;
};

export const PayoutInputWarning = memo(function PayoutInputWarningUnmemoized({
  teamSize,
  countWinners,
}: PayoutInputWarningProps) {
  return (
    <>
      <GradientTypography variant="h6" gradientColor="warning.vertical">
        {`This payout is not divisible for the tournament's team size (${teamSize}) and number of winning teams (${countWinners})`}
      </GradientTypography>
      <Typography color="text.secondary">
        All payouts need to be valid for a tournament to be published.
      </Typography>
    </>
  );
});
