import { memo } from '../../util/memo';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InfoIconNoBorder = memo(function InfoIconNoBorderUnmemoized(
  props: SvgIconProps,
) {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 2 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M7.79169 5.66675C7.79169 5.27555 8.10882 4.95841 8.50002 4.95841V4.95841C8.89122 4.95841 9.20835 5.27555 9.20835 5.66675V5.66675C9.20835 6.05795 8.89122 6.37508 8.50002 6.37508V6.37508C8.10882 6.37508 7.79169 6.05795 7.79169 5.66675V5.66675ZM7.79169 8.50008C7.79169 8.10888 8.10882 7.79175 8.50002 7.79175V7.79175C8.89122 7.79175 9.20835 8.10888 9.20835 8.50008V11.3334C9.20835 11.7246 8.89122 12.0417 8.50002 12.0417V12.0417C8.10882 12.0417 7.79169 11.7246 7.79169 11.3334V8.50008ZM8.50002 1.41675C4.59002 1.41675 1.41669 4.59008 1.41669 8.50008C1.41669 12.4101 4.59002 15.5834 8.50002 15.5834C12.41 15.5834 15.5834 12.4101 15.5834 8.50008C15.5834 4.59008 12.41 1.41675 8.50002 1.41675ZM8.50002 14.1667C5.37627 14.1667 2.83335 11.6238 2.83335 8.50008C2.83335 5.37633 5.37627 2.83341 8.50002 2.83341C11.6238 2.83341 14.1667 5.37633 14.1667 8.50008C14.1667 11.6238 11.6238 14.1667 8.50002 14.1667Z"
        fill="url(#paint1_linear_12720_181852)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12720_181852"
          x1="8.5"
          y1="2"
          x2="8.5"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#000E21" />
          <stop offset="1" stopColor="#001533" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12720_181852"
          x1="-3.63207"
          y1="-0.944349"
          x2="17.5376"
          y2="-2.75969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4DC8FF" />
          <stop offset="1" stopColor="#0064E9" />
        </linearGradient>
      </defs> */}
      <path
        d="M0.291748 1.25026C0.291748 0.859063 0.60888 0.541931 1.00008 0.541931V0.541931C1.39128 0.541931 1.70841 0.859063 1.70841 1.25026V1.25026C1.70841 1.64147 1.39128 1.9586 1.00008 1.9586V1.9586C0.60888 1.9586 0.291748 1.64147 0.291748 1.25026V1.25026ZM0.291748 4.0836C0.291748 3.6924 0.60888 3.37526 1.00008 3.37526V3.37526C1.39128 3.37526 1.70841 3.6924 1.70841 4.0836V6.91693C1.70841 7.30813 1.39128 7.62526 1.00008 7.62526V7.62526C0.60888 7.62526 0.291748 7.30813 0.291748 6.91693V4.0836Z"
        fill="url(#paint0_linear_16709_326743)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16709_326743"
          x1="-0.213128"
          y1="-0.638617"
          x2="1.91878"
          y2="-0.67518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4DC8FF" />
          <stop offset="1" stopColor="#0064E9" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
});
