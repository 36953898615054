import { Stack, Box } from '@mui/material';
import { ImageOptimized } from 'src/components/image/ImageOptimized';
import { useMobile } from 'src/hooks/useMobile';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { BracketRoundsKeys } from 'functions/src/types/firestore/Game/Tournament/Bracket';

export type BracketBackgroundProps = {
  children: ReactNode;
  roundsLength: number;
  variant?: BracketRoundsKeys;
};

function BracketBackgroundUnmemoized({
  children,
  roundsLength,
  variant,
}: BracketBackgroundProps) {
  const isLoserBracket = variant === 'bracketLoser';
  const isGrandFinal = variant === 'grandFinal';
  const isMobile = useMobile();

  const roundsToSize = {
    2: 4,
    3: 8,
    4: isLoserBracket ? 8 : 16,
    5: 32,
    6: 16,
    8: 32,
  };

  const calcImageSrc = (): string => {
    if (isGrandFinal) {
      return `/assets/images/bracket-connectors/bracket-loser-${
        isMobile ? 'mobile-' : ''
      }4.svg`;
    }
    return `/assets/images/bracket-connectors/bracket-${
      isLoserBracket ? 'loser-' : ''
    }${isMobile ? 'mobile-' : ''}${roundsToSize[Number(roundsLength)]}.svg`;
  };

  const imageSrc = calcImageSrc();

  return (
    <Box position="relative">
      <Stack
        direction={isGrandFinal && !isMobile ? 'column-reverse' : 'row'}
        alignItems="stretch"
        justifyContent="center"
        spacing={isMobile ? 6 : 4}
      >
        {children}
      </Stack>
      {roundsLength > 1 && (
        <Box
          key={imageSrc}
          position="absolute"
          top={0}
          ml={isMobile ? (isGrandFinal ? 6 : isLoserBracket ? -2.75 : 0) : 0}
          height="100%"
          width="100%"
        >
          <ImageOptimized
            layout="fill"
            src={imageSrc}
            alt="bracket connector"
          />
        </Box>
      )}
    </Box>
  );
}

export const BracketBackground = memo(BracketBackgroundUnmemoized);
