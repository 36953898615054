import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { DETAILS_SUMMARY_KEY_PRIORITY } from 'src/hooks/referee/useDetailsSummary';
import { useReferee } from 'src/contexts/RefereeContext';

export const SummaryKeySelect = () => {
  const { detailsOptions, setDetailsOptions, keys } = useReferee();
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Typography variant="body1" textTransform="uppercase">
        Summary key
      </Typography>
      <Select
        size="small"
        value={detailsOptions?.keySummary || keys?.[0]}
        sx={{ textTransform: 'none' }}
        onChange={(e) => {
          if (setDetailsOptions) {
            setDetailsOptions((prev) => {
              return {
                ...prev,
                keySummary: e.target.value,
              };
            });
          }
        }}
      >
        {(keys.length ? keys : DETAILS_SUMMARY_KEY_PRIORITY).map((key) => {
          return (
            <MenuItem key={key} value={key} sx={{ textTransform: 'none' }}>
              {key}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};
