import { useEffect, MutableRefObject, useCallback } from 'react';

export type UseClickAwayListenerParams = {
  containerRefs: MutableRefObject<HTMLDivElement | null>[];
  onClickAway: () => void;
};

export const useClickAwayListener = ({
  containerRefs,
  onClickAway,
}: UseClickAwayListenerParams) => {
  const processClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      const isClickOutside = containerRefs.every((ref) => {
        return ref.current && !ref.current.contains(target);
      });
      if (isClickOutside) {
        onClickAway();
      }
    },
    [containerRefs, onClickAway],
  );

  useEffect(() => {
    document.addEventListener('mousedown', processClick);
    return () => {
      document.removeEventListener('mousedown', processClick);
    };
  }, [processClick]);
};
