/* eslint-disable security/detect-non-literal-fs-filename */
import { useCallback, useMemo } from 'react';
import { useAccountLowercase } from './useAccountLowercase';
import { useWagmiFunc } from './useWagmiFunc';
import { useWeb3 } from '../../contexts/web3/Web3Context';
import type { Web3Modal } from '@web3modal/wagmi/dist/types/src/client';
import { useSignatureRequest } from '../../contexts/web3/SignatureRequestContext';

export const useWalletAuth = () => {
  const { web3Modal, onFinishImport } = useWeb3();

  const { onClose: onSignatureRequestClose } = useSignatureRequest();

  const openWeb3Modal = useMemo(() => {
    if (!web3Modal) {
      return (...options: Parameters<Web3Modal['open']>) => {
        return new Promise<void>((resolve, reject) => {
          onFinishImport(async ({ web3Modal: web3ModalImported }) => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              await web3ModalImported!.open(...options);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        });
      };
    }
    return (...options: Parameters<Web3Modal['open']>) => {
      return web3Modal.open(...options);
    };
  }, [onFinishImport, web3Modal]);

  const disconnect = useWagmiFunc('disconnect');

  const account = useAccountLowercase();
  const connectedStatus = useMemo(() => {
    return account?.status || 'importing';
  }, [account?.status]);
  const signInWallet = useCallback(
    async ({ onFinish }: { onFinish?: () => void } = {}) => {
      if (connectedStatus === 'connected') {
        return;
      }
      await openWeb3Modal();
      if (onFinish) {
        onSignatureRequestClose(onFinish);
      }
    },
    [connectedStatus, onSignatureRequestClose, openWeb3Modal],
  );

  const resetWallet = useCallback(
    async ({ onFinish }: { onFinish?: () => void } = {}) => {
      if (connectedStatus === 'connected') {
        await disconnect();
      }
      await openWeb3Modal();
      if (onFinish) {
        onSignatureRequestClose(onFinish);
      }
    },
    [connectedStatus, openWeb3Modal, disconnect, onSignatureRequestClose],
  );

  return {
    signInWallet,
    connectedStatus,
    resetWallet,
  };
};
