import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { memo } from '../../util/memo';
import { GradientIconButton } from '../gradients/GradientIconButton';
import {
  GradientButton,
  GradientButtonProps,
} from '../gradients/GradientButton';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

export type ImageUploadButtonProps = {
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void | string | undefined>;
} & Omit<GradientButtonProps, 'onClick' | 'onChange'> & {
    IconComponent?: OverridableComponent<SvgIconTypeMap>;
  };

export const ImageUploadButton = memo(function ImageUploadButtonUnmemoized({
  onChange,
  IconComponent,
  ...rest
}: ImageUploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const openFileInput = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const RenderButton = useMemo(() => {
    if (IconComponent) {
      return (
        <GradientIconButton
          {...rest}
          onClick={openFileInput}
          IconComponent={IconComponent}
        />
      );
    }
    return <GradientButton {...rest} onClick={openFileInput} />;
  }, [IconComponent, openFileInput, rest]);

  return (
    <>
      {RenderButton}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </>
  );
});
