import { useRoledUser } from '../../hooks/messaging/useRoledUser';
import { useRoomRequestCount } from '../../hooks/voice-chat/useRoomRequestCount';
import { memo } from '../../util/memo';
import { ReactNode } from 'react';
import Badge from '@mui/material/Badge';

export type RequestCountUnmemoizedProps = {
  children?: ReactNode;
};

const RequestCountUnmemoized = ({ children }) => {
  const { requestCount } = useRoomRequestCount();
  const { hasAnyRole } = useRoledUser();
  const requestCountDisplayed =
    hasAnyRole && requestCount !== 'unknown' ? requestCount : 0;

  return (
    <Badge
      badgeContent={requestCountDisplayed}
      sx={{
        width: '100%',
        display: 'flex',
        zIndex: 1000,
      }}
    >
      {children}
    </Badge>
  );
};

export const RequestCount = memo(RequestCountUnmemoized);
