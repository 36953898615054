import Box from '@mui/material/Box';
import { HighlightedTeamProvider } from 'src/contexts/HighlightedTeamContext';
import { LeaderboardTitle } from 'src/components/leaderboards/LeaderboardTitle';
import { MatchDetailsProvider } from 'src/components/tournaments/match/details/MatchDetailsProvider';
import { BracketBoard } from 'src/components/tournaments/brackets/BracketBoard';
import { calculateActiveRound } from 'src/util/bracket/calculateActiveRound';
import { memo } from '../../../util/memo';
import { Cohort } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { BracketWidthProvider } from '../../../contexts/BracketWidthContext';

export type BracketBoardContainerProps = {
  bracket?: Cohort<Date>;
  bracketLoser?: Cohort<Date>;
} & Pick<Tournament<Date>, 'sponsors'>;

export const BracketBoardContainer = memo(
  function BracketBoardContainerUnmemoized({
    bracket,
    bracketLoser,
    sponsors,
  }: BracketBoardContainerProps) {
    const { title, rounds } = bracket || {};
    const roundsLoser = bracketLoser?.rounds;
    const roundActive = calculateActiveRound(rounds);
    const roundActiveLoser = calculateActiveRound(roundsLoser);

    const roundPrizes = !!rounds
      ? [...rounds]?.reverse().map((round) => {
          const { payout } = round;
          if (!payout) {
            return [];
          }
          return payout;
        })
      : [];

    return (
      <HighlightedTeamProvider>
        <BracketWidthProvider>
          {title && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <LeaderboardTitle title={title} />
            </Box>
          )}
          {!!rounds?.length && (
            <MatchDetailsProvider rounds={rounds}>
              <BracketBoard
                {...{
                  rounds,
                  activeRound: roundActive?.activeRoundIndex,
                  activeRoundProgress: roundActive?.activeRoundProgress,
                  roundPrizes,
                  sponsors,
                  variant: title === 'Grand Final' ? 'grandFinal' : 'bracket',
                }}
              />
            </MatchDetailsProvider>
          )}

          {!!roundsLoser?.length && (
            <MatchDetailsProvider rounds={roundsLoser}>
              <BracketBoard
                {...{
                  rounds: roundsLoser,
                  activeRound: roundActiveLoser?.activeRoundIndex,
                  activeRoundProgress: roundActiveLoser?.activeRoundProgress,
                  sponsors,
                  roundPrizes,
                  variant: 'bracketLoser',
                }}
              />
            </MatchDetailsProvider>
          )}
        </BracketWidthProvider>
      </HighlightedTeamProvider>
    );
  },
);
