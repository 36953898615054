import { useTheme } from '@mui/material/styles';
import { FC, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendCardAdd } from '../../cards/friend/FriendCardAdd';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../../algolia/catalogs/VerticalCarousel';
import { PLACEHOLDER_KEY } from 'src/hooks/content/useNearEndContent';
import { NoSearchResults } from '../search/NoSearchResults';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { renderHits } from '../../../util/algolia/renderHits';
import { usePreemptState } from '../../../contexts/algolia/PreemptStateContext';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';

export type UserHit = Hit<{
  status: string;
  username: string;
  imgUrl: string;
  id: string;
  friends: string[];
  friendRequests: {
    received: string[];
    sent: string[];
  };
  memberRequests: {
    received: string[];
    sent: string[];
  };
}>;

export type ContentCardProp = (user: UserHit) => ReactNode;

export type UserVerticalCarouselProps = Omit<
  VerticalCarouselProps,
  'Content'
> & {
  hits: OrNode<UserHit>[];
  noHits?: ReactNode;
  ContentCard?: FC<UserHit>;
};

const UserVerticalCarouselUnmemoized = ({
  hits,
  noHits,
  ContentCard,
  ...props
}: UserVerticalCarouselProps) => {
  const theme = useTheme();
  const { uid } = useAuth();
  const state = usePreemptState();
  const query = state?.query;

  const Content = useMemo(() => {
    if (hits.length === 0 && !query) {
      return [
        {
          key: PLACEHOLDER_KEY,
          Node: noHits,
        },
      ];
    }

    if (hits.length === 0) {
      return [
        {
          key: PLACEHOLDER_KEY,
          Node: <NoSearchResults />,
        },
      ];
    }

    return renderHits(hits, (hit) => {
      if (ContentCard) {
        return <ContentCard {...hit} />;
      }
      return <FriendCardAdd {...hit} />;
    });
  }, [hits, noHits, ContentCard, query]);

  if (!uid) {
    return null;
  }

  return (
    <VerticalCarousel
      Content={Content}
      containerSx={{ background: theme.palette.background.elevation[2] }}
      cardSpacing="8px"
      {...props}
    />
  );
};

export const UserVerticalCarousel = memo(UserVerticalCarouselUnmemoized);

export const useUserVerticalCarouselAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: UserVerticalCarousel,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
    });
  }, [containerId]);
};
