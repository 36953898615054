import { setCookie } from 'cookies-next';
import { publicEncrypt, randomBytes, createCipheriv } from 'crypto';
import { escapedParse } from '../../../functions/src/util/escapedParse';

const encryptWithPublicKey = (publicKey: string, data: string) => {
  const buffer = Buffer.from(data, 'utf8');
  return publicEncrypt(publicKey, buffer).toString('base64');
};

const encryptAes256Cbc = (data: string, secretKey: Buffer) => {
  const iv = randomBytes(16);
  const cipher = createCipheriv('aes-256-cbc', secretKey, iv);
  return {
    iv: iv.toString('base64'),
    encryptedData:
      cipher.update(data, 'utf8', 'base64') + cipher.final('base64'),
  };
};
export const unsetEncryptedRefreshToken = () => {
  setCookie('firebase_refresh_token_encrypted', '', { path: '/' });
};

export const setEncryptedRefreshToken = () => {
  const authUser = Object.keys(window.localStorage).filter((item) => {
    return item.startsWith('firebase:authUser');
  })[0];

  if (authUser) {
    const authUserParsed = escapedParse(
      localStorage.getItem(authUser) as string,
    );

    const { publicKey } = escapedParse(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      process.env.NEXT_PUBLIC_AUTH_PUBLIC_KEY!,
    );

    // Generate a symmetric key
    const symmetricKey = randomBytes(32); // 256-bit key for AES-256

    // Encrypt the refresh token with symmetric key
    const { iv, encryptedData } = encryptAes256Cbc(
      authUserParsed.stsTokenManager.refreshToken,
      symmetricKey,
    );

    // Encrypt the symmetric key with RSA public key
    const encryptedKey = encryptWithPublicKey(
      publicKey as string,
      symmetricKey.toString('base64'),
    );

    // Combine the IV, encrypted symmetric key, and encrypted data into one string
    const combinedEncrypted = `${iv}:${encryptedKey}:${encryptedData}`;
    setCookie('firebase_refresh_token_encrypted', combinedEncrypted, {
      path: '/',
    });
  }
};
