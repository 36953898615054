import { insertInnermostChild } from '../../util/insertInnermostChild';
import { DialogCentered, DialogCenteredProps } from '../DialogCentered';
import { useWizard } from './Wizard';
import { ReactElement, ReactNode, useMemo } from 'react';
import { memo } from '../../util/memo';

export type WizardDialogProps = DialogCenteredProps & {
  Wrapper?: ReactElement<{ children?: ReactNode }>;
};

const WizardDialogUnmemoized = ({
  onClose: onCloseInner,
  Wrapper,
  showCloseIcon,
  ...rest
}: WizardDialogProps) => {
  const { go } = useWizard();

  const DialogRendered = useMemo(() => {
    return (
      <DialogCentered
        onClose={() => {
          onCloseInner?.();
          return go(undefined);
        }}
        showCloseIcon={showCloseIcon}
        {...rest}
      />
    );
  }, [go, onCloseInner, rest, showCloseIcon]);

  if (!!Wrapper) {
    return insertInnermostChild(Wrapper, DialogRendered);
  }
  return DialogRendered;
};

export const WizardDialog = memo(WizardDialogUnmemoized);
