import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import Chip, { ChipProps } from '@mui/material/Chip';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useAuth } from '../../../contexts/AuthContext';
import { User } from '../../../../functions/src/types/firestore/User';
import { FriendListItemLayout } from './FriendListItemLayout';
import { useChannelMember } from '../../messaging/ChannelMembersContext';

export type UserCardAddProps = ChipProps &
  Pick<User, 'imgUrl' | 'username' | 'id' | 'friends'>;

const UserCardAddUnmemoized = (props: UserCardAddProps) => {
  const theme = useTheme();
  const { imgUrl, username, id, friends, ...rest } = props;

  const { option, toggle } = useChannelMember({
    userId: id,
    username,
    image: imgUrl,
  });

  const { userData } = useAuth();
  const { friends: myFriends } = userData || {};
  const mutualFriendsCountEstimate = useMemo(() => {
    if (!myFriends || !friends) {
      return 0;
    }
    const mutualFriends = myFriends.filter((friendId) => {
      return friends.includes(friendId);
    });
    return mutualFriends.length;
  }, [friends, myFriends]);

  return (
    <FriendListItemLayout
      {...{ username, imgUrl, mutualFriendsCountEstimate, userId: id }}
    >
      <Chip
        {...rest}
        label={
          <GradientTypography variant="subtitle1">{option}</GradientTypography>
        }
        onClick={toggle}
        sx={{
          backgroundColor: theme.palette.background.elevation[14],
          width: '100px',
          height: '42px',
          '.MuiChip-label': {
            padding: 0,
          },
        }}
      />
    </FriendListItemLayout>
  );
};

export const UserCardAdd = memo(UserCardAddUnmemoized);
