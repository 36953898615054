import { useEffect, useRef, useState } from 'react';
import isEqual from 'fast-deep-equal/react';

export function useChangedCount<T>(value: T): number {
  const [changedCount, setChangedCount] = useState(0);
  const previousValueRef = useRef<T | undefined>(undefined);

  useEffect(() => {
    if (!previousValueRef.current) {
      previousValueRef.current = value;
      return;
    }

    if (!isEqual(value, previousValueRef.current)) {
      setChangedCount((prev) => {
        return prev + 1;
      });
      previousValueRef.current = value;
    }
  }, [value]);

  return changedCount;
}
