import { useEffect, useState } from 'react';
import { FetchInGameIdsParams } from '../../../functions/src/callable/apis/fetchInGameIds.f';
import { ProviderDetail } from '../../components/tournaments/registration/SelectableRegistrationOption';
import { useAuth } from '../../contexts/AuthContext';
import { AllSignInMethod } from '../../../functions/src/types/firestore/User';

export const useFetchInGameId = ({ gameId }: FetchInGameIdsParams) => {
  const { user, userData } = useAuth();
  const [connectedIds, setConnectedIds] = useState<ProviderDetail[]>([]);
  const [availableProviders, setAvailableProviders] = useState<
    ProviderDetail[]
  >([]);
  useEffect(() => {
    if (!user || !userData) {
      return;
    }
    const handler = async () => {
      const { fetchInGameIds } = await import(
        '../../firebaseCloud/apis/fetchInGameIds'
      );
      try {
        const inGameData = await fetchInGameIds({ gameId });
        if (!inGameData) {
          return;
        }
        const providers = Object.keys(inGameData).map((provider) => {
          return { provider };
        }) as ProviderDetail[];
        setAvailableProviders(providers);
        const connectedIdsFetched: ProviderDetail[] = Object.entries(
          inGameData,
        ).flatMap(([providerId, inGameIds]) => {
          return inGameIds.map((inGameId) => {
            return {
              provider: providerId as AllSignInMethod,
              value: inGameId,
            };
          });
        });
        setConnectedIds(connectedIdsFetched);
      } catch (e) {
        console.error(e);
      }
    };
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gameId,
    user?.providerData.length,
    userData?.hidden.customProviders?.length,
  ]);

  return { connectedIds, availableProviders };
};
