import { GradientTypography } from '../gradients/GradientTypography';
import { memo } from '../../util/memo';

export type FormItemProps = {
  title: string;
  gradientColor?: string;
  children: React.ReactNode;
};

const FormItemUnmemoized = ({
  title,
  gradientColor = 'primary.vertical',
  children,
}: FormItemProps) => {
  return (
    <>
      <GradientTypography
        variant="subtitle1"
        gradientColor={gradientColor}
        textTransform="uppercase"
      >
        {title}
      </GradientTypography>
      {children}
    </>
  );
};

export const FormItem = memo(FormItemUnmemoized);
