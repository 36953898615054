import { useCallback, MouseEvent, useMemo } from 'react';
import { useWizard } from '../../components/wizard/Wizard';
import { useCustomRegistrationForm } from './useCustomRegistrationForm';
import { useSelectConnectedProviders } from './useSelectConnectedProviders';
import { useSelectInGameId } from './useSelectInGameId';
import { RegisterStore } from '../../components/tournaments/registration/dialogs/useRegisterDialogs';

export const useTournamentRegistrationForm = () => {
  const { go, set } = useWizard<RegisterStore>();
  const { FetchedInGameIds, selectedInGameId, isInGameIdSubmittable } =
    useSelectInGameId();
  const { AuthProviders, selectedAuthProviders, isAuthSubmittable } =
    useSelectConnectedProviders();
  const { RegisterFields, isSubmittable, detailsInput } =
    useCustomRegistrationForm();

  const isFormSubmittable = useMemo(() => {
    return isSubmittable && isInGameIdSubmittable && isAuthSubmittable;
  }, [isAuthSubmittable, isInGameIdSubmittable, isSubmittable]);

  const submitForm = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      if (!isFormSubmittable) {
        return;
      }
      const inputAll = {
        ...detailsInput,
        ...selectedAuthProviders,
        ...(!!selectedInGameId ? { inGameId: selectedInGameId } : {}),
      };
      set('details', inputAll);
      go('Rules And Regulations');
    },
    [
      detailsInput,
      isFormSubmittable,
      go,
      selectedAuthProviders,
      selectedInGameId,
      set,
    ],
  );

  const FormFields = useMemo(() => {
    return (
      <>
        {AuthProviders}
        {FetchedInGameIds}
        {RegisterFields}
      </>
    );
  }, [AuthProviders, FetchedInGameIds, RegisterFields]);

  return { FormFields, submitForm, isFormSubmittable };
};
