import { useMemo, ReactNode } from 'react';
import { memo } from '../../util/memo';
import { RouterStateGetter } from '../../hooks/routing/useRouterState';

export type Pane<TValue extends string> = {
  value: TValue;
  component: ReactNode;
};

export type PanesRoutedProps<TValue extends string> = {
  panes: Pane<TValue>[];
  state: RouterStateGetter;
};

function PanesRoutedUnmemoized<TValue extends string>({
  panes,
  state,
}: PanesRoutedProps<TValue>) {
  const [activePane] = state;

  const ActivePane = useMemo(() => {
    const pane =
      panes.find(({ value }) => {
        return value === activePane;
      }) || panes[0];
    return pane?.component;
  }, [panes, activePane]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{ActivePane}</>;
}

export const PanesRouted = memo(PanesRoutedUnmemoized);
