import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useState, ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { LoadingButton } from 'src/components/buttons/LoadingButton';

export const MAX_MESSAGE_CHARS = 46;

export type DialogFriendRequestProps = {
  onSubmit: (message: string) => Promise<void>;
  UserChip: ReactNode;
};

export const DialogFriendRequest = memo(function DialogFriendRequestUnmemoized({
  onSubmit,
  UserChip,
}: DialogFriendRequestProps) {
  const [message, setMessage] = useState<string>('');
  const editMessage = (e) => {
    setMessage(e.target.value);
  };
  return (
    <Stack spacing={2} alignItems="center" pb={6}>
      <Stack direction="row" spacing={1} alignItems="center" mb={2}>
        <Typography>Include message to {UserChip} below</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        width="100%"
        height="42px"
        sx={{
          '.MuiOutlinedInput-root': {
            height: '42px',
          },
        }}
      >
        <TextField
          id="standard-helperText"
          label="Message (Optional)"
          variant="outlined"
          color="primary"
          focused
          fullWidth
          onChange={editMessage}
          inputProps={{ maxLength: MAX_MESSAGE_CHARS }}
          helperText={`${message.length}/${MAX_MESSAGE_CHARS} characters`}
          sx={{
            flex: 4,
            '.MuiFormHelperText-root': {
              textAlign: 'right',
              mr: 1,
            },
          }}
        />

        <LoadingButton
          variant="contained"
          color="secondary"
          size="large"
          onClick={async () => {
            await onSubmit(message);
          }}
          fullWidth
          sx={{ flex: 1 }}
        >
          Send
        </LoadingButton>
      </Stack>
    </Stack>
  );
});
