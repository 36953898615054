import { useMatch } from '../MatchProvider';
import { MatchCellButton } from './MatchCellIButton';
import { useMatchDetails } from '../details/MatchDetailsProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { useActiveMatchRouter } from '../../../../hooks/tournaments/useActiveMatchRouter';
import {
  EntryIdsMatch,
  useMatchDetailsWizard,
} from '../../../../hooks/tournaments/useMatchDetailsWizard';

export type CellButtonDetailsProps = {
  isMatchDisabled: boolean;
};

export const CellButtonDetails = memo(function CellButtonDetailsUnmemoized({
  isMatchDisabled,
}: CellButtonDetailsProps) {
  const { id } = useMatch();
  const { close, matchId, sessionIndex, open, validatedMatchEntry } =
    useActiveMatchRouter();
  const { open: openMatchDetails } = useMatchDetailsWizard(isMatchDisabled);
  const { rounds } = useMatchDetails();

  const openWizard = useCallback(() => {
    open(id, sessionIndex);
  }, [open, id, sessionIndex]);

  const isOpen = useMemo(() => {
    return sessionIndex !== undefined && matchId === id;
  }, [matchId, sessionIndex, id]);

  useEffect(() => {
    if (isOpen) {
      openMatchDetails({
        rounds,
        onClose: close,
        entryId: validatedMatchEntry,
      });
    }
  }, [
    close,
    isMatchDisabled,
    isOpen,
    openMatchDetails,
    rounds,
    validatedMatchEntry,
  ]);

  return (
    <>
      <MatchCellButton onClick={openWizard} />
    </>
  );
});
