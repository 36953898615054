import { useCallback, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { useSearchBox } from 'react-instantsearch';

const DELAY_MILLIS_DEFAULT = 350;

export type UseDebouncedSearchProps<T extends string> = {
  initialValue?: T;
  delay?: number;
};

export const useDebouncedSearch = <T extends string>({
  initialValue = '' as T,
  delay = DELAY_MILLIS_DEFAULT,
}: UseDebouncedSearchProps<T> = {}) => {
  const { refine } = useSearchBox();
  const [inputValue, setInputValue] = useState<T>(initialValue);

  const debouncedRefine = useDebounceCallback(
    (value: T) => {
      if (value.trim() !== '') {
        refine(value);
      } else {
        refine('');
      }
    },
    delay,
    true,
  );

  const applyQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value as T;
      setInputValue(newValue);
      debouncedRefine(newValue);
    },
    [debouncedRefine],
  );

  return {
    inputValue,
    applyQueryChange,
  };
};
