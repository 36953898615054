import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { memo } from '../util/memo';
import Slide, { SlideProps } from '@mui/material/Slide';

export const SlideTransition = memo(function SlideTransitionUnmemoized(
  props: SlideProps,
) {
  return <Slide {...props} direction="up" />;
});

export type SnackbarAlertProps = {
  message: string | ReactNode;
} & SnackbarProps;

const SnackbarAlertUnmemoized = ({
  message,
  action,
  ...props
}: SnackbarAlertProps) => {
  const theme = useTheme();

  return (
    <Snackbar
      sx={{ zIndex: theme.zIndex.dialog + 1 }}
      TransitionComponent={SlideTransition}
      {...props}
    >
      {/* <AlertStandard severity="info" message={message} /> */}

      <Alert
        severity="info"
        action={action}
        sx={{
          width: { xs: '100%', md: 'auto' },
        }}
      >
        <Typography
          variant={'body1'}
          color={'text.primary'}
          sx={{
            my: action ? 0 : -1,
            fontWeight: 500,
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
export const SnackbarAlert = memo(SnackbarAlertUnmemoized);
