import { BASE_ERROR } from '../baseError';

export const INVALID_CHECK_IN_WINDOW = 'Invalid check-in window' as const;
export const ALREADY_REGISTERED = 'Already Registered' as const;
export const INVITATION_INVALID = 'Invitation Invalid' as const;
export const TOURNAMENT_OR_INVITEE_NOT_FOUND =
  'Tournament or Invitee not found' as const;
export const INVALID_INVITE_TOKEN = 'Invalid invite token' as const;
export const INVITER_NOT_ON_TEAM = 'Inviter not on team' as const;
export const TEAM_IS_FULL = 'Team is full' as const;
export const TEAM_NOT_FOUND = 'Team not found' as const;
export const INVITEE_TAKEN = 'Invitee taken' as const;
export const ALREADY_ON_CURRENT_TEAM = 'Already on current team' as const;
export const TOURNAMENT_HAS_ENDED = 'Tournament has already ended' as const;
export const USER_ACCOUNT_DELETED = 'User account deleted' as const;

export const RSVP_ERROR_LIBRARY = {
  [INVALID_CHECK_IN_WINDOW]:
    'The check-in phase for this tournament has either not started or has ended',
  [ALREADY_REGISTERED]:
    'You have already registered for this tournament. Please remove yourself from your existing team or unregister as a solo player.',
  [INVITATION_INVALID]:
    'It seems the person you are inviting, or the person inviting you, does not exist. Please verify and try again.',
  [TOURNAMENT_OR_INVITEE_NOT_FOUND]:
    'No tournament was found matching your criteria. Please try again.',
  [INVALID_INVITE_TOKEN]:
    'Your invite URL appears to be invalid. Please check the link and try again.',
  [INVITER_NOT_ON_TEAM]:
    'The person who invited you seems to have left the team. Please consider joining another team or creating your own.',
  [TEAM_IS_FULL]:
    'Unfortunately, the team you are trying to join is currently full. Consider joining a different team.',
  [TEAM_NOT_FOUND]:
    'The team you are trying to join does not appear to exist. Please try to join a different team.',
  [INVITEE_TAKEN]:
    'The person you invited is already on a team or is a team captain.',
  [ALREADY_ON_CURRENT_TEAM]: 'You are already a member of this team.',
  [TOURNAMENT_HAS_ENDED]:
    'Unfortunately, the tournament you have been invited to has already ended.',
  [USER_ACCOUNT_DELETED]:
    'Unfortunately, the user you are inviting has deleted their account.',
  ...BASE_ERROR,
} as const;
