import Box from '@mui/material/Box';
import { TypographyParagraphed } from './TypographyParagraphed';
import { memo } from '../util/memo';
export type NoticeParagraphed = {
  text: string;
};

export const NoticeParagraphed = memo(function NoticeParagraphedUnmemoized({
  text,
}: NoticeParagraphed) {
  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
        maxHeight: '200px',
        textAlign: 'left',
        backgroundColor: 'background.elevation.12',
        borderRadius: '10px',
        px: 4,
      }}
    >
      <TypographyParagraphed text={text} />
    </Box>
  );
});
