import { Result } from '../../types/firestore/Game/Tournament/Heat/Result';
import { compareSequentialElements } from '../compareSequentialElements';

export const sortHeatResults = (
  results: Result[],
  sortBys: ('asc' | 'desc')[] = ['desc'],
) => {
  return results
    .map((result, index) => {
      return { ...result, index };
    })
    .sort((a, b) => {
      const sortBy = sortBys[Number(a.index)];
      return sortBy === 'asc'
        ? compareSequentialElements(a?.scores || [], b?.scores || [])
        : compareSequentialElements(b?.scores || [], a?.scores || []);
    });
};
