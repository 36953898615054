export const calculateOnlineCount = (
  onlineStatus: Record<string, boolean>,
  friends: string[],
) => {
  const { onlineFriendsCount } = Object.keys(onlineStatus).reduce(
    (counts, userId) => {
      if (onlineStatus[String(userId)]) {
        if (friends.includes(userId)) {
          counts.onlineFriendsCount++;
        }
      }
      return counts;
    },
    { onlineFriendsCount: 0 },
  );

  return { onlineFriendsCount };
};
