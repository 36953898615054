import { useCallback } from 'react';
import { memo } from '../../util/memo';
import { Selectable, SelectableProps } from '../Selectable';
import { RouterState } from '../../hooks/routing/useRouterState';
import { useRouterStateFallback } from '../../hooks/routing/useRouterStateFallback';
import { Optional } from 'utility-types';

export type SelectableRoutedProps = Optional<
  SelectableProps<string>,
  'onChange'
> & {
  state?: RouterState;
  queryParamKey?: string;
};

export const DEFAULT_SEGMENT_NAME = 'selected';

function SelectableRoutedUnmemoized({
  children,
  value,
  state,
  queryParamKey = DEFAULT_SEGMENT_NAME,
  onChange,
  ...props
}: SelectableRoutedProps) {
  const [stateValue, setStateValue] = useRouterStateFallback({
    defaultKey: queryParamKey,
    state,
  });

  const changeState = useCallback<SelectableProps<string>['onChange']>(
    (newValue, newSelected) => {
      onChange?.(newValue, newSelected);
      setStateValue(newSelected ? newValue : undefined);
    },
    [onChange, setStateValue],
  );

  const isSelected = stateValue === value;

  return (
    <Selectable
      {...props}
      value={value}
      isSelected={isSelected}
      onChange={changeState}
    >
      {children}
    </Selectable>
  );
}

export const SelectableRouted = memo(SelectableRoutedUnmemoized);
