import { useCallback } from 'react';
import { useRouter } from '../routing/useRouter';
import { useAuth } from '../../contexts/AuthContext';

export const useCreatePrizePool = () => {
  const { uid } = useAuth();
  const router = useRouter();
  return useCallback(async () => {
    if (router.isReady && uid) {
      const { createPrizePool } = await import(
        '../../firebaseCloud/prize-pool/createPrizePool'
      );
      const prizePoolId = await createPrizePool({});
      router.push(`/${uid}/prize-pool/${prizePoolId}`);
    }
  }, [router, uid]);
};
