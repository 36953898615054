import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { GradientTypography } from '../../gradients/GradientTypography';

export const UnusedPrizes = () => {
  return (
    <Stack>
      <Alert severity="warning" variant="outlined">
        <GradientTypography gradientColor="warning.vertical" variant="h6">
          Some prizes have not been added.
        </GradientTypography>
        <Typography color="text.primary" textTransform="none" mb={2}>
          You must use all of the prizes within a prize pool to publish this
          event. Prizes must be filled in order, from first downwards.
        </Typography>
      </Alert>
    </Stack>
  );
};
