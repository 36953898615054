import { useEffect, useMemo } from 'react';
import { fetchOS } from '../util/fetchOS';
import { useRouterState } from './routing/useRouterState';
import { AUTO_REDIRECT, BUTTON_REDIRECT } from '../pages/in-app-parking';
import { Link } from '../components/Link';
import Button from '@mui/material/Button';

export const useInAppParking = () => {
  const [appKey] = useRouterState({ key: 'appKey', location: 'queryParam' });
  const [subDirectory] = useRouterState({
    key: 'subDirectory',
    location: 'queryParam',
  });

  const os = fetchOS();
  const { name } = os || {};

  const isiOS = name?.startsWith('iOS');

  const intentUrl = useMemo(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!appKey) {
      return;
    }
    const baseUrl = `${window.location.origin}${decodeURIComponent(
      subDirectory || '/',
    )}`;
    return isiOS ? baseUrl : `intent:${baseUrl}#Intent;end`;
  }, [appKey, isiOS, subDirectory]);

  useEffect(() => {
    if (isiOS) {
      return;
    }
    if (
      appKey &&
      AUTO_REDIRECT.includes(appKey) &&
      intentUrl &&
      window.location.href !== intentUrl
    ) {
      window.location.href = intentUrl;
    }
  }, [appKey, intentUrl, isiOS]);

  const isButtonRedirect = useMemo(() => {
    return appKey && BUTTON_REDIRECT.includes(appKey) && !isiOS;
  }, [appKey, isiOS]);

  const browserButton = useMemo(() => {
    return (
      isButtonRedirect &&
      intentUrl && (
        <Link href={intentUrl} target="_blank">
          <Button variant="contained">Go To Browser</Button>
        </Link>
      )
    );
  }, [intentUrl, isButtonRedirect]);

  return { browserButton, intentUrl };
};
