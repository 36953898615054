import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import { GradientButton } from '../../../gradients/GradientButton';
import { useCallback } from 'react';
import { useWizard } from '../../../wizard/Wizard';

export const SCORE_GAME_TEXT = 'SCORE';

export const OpenScoringButton = () => {
  const { go } = useWizard();

  const openScoring = useCallback(() => {
    go('scoring');
  }, [go]);

  return (
    <GradientButton
      startIcon={ScoreboardIcon}
      sx={{ borderRadius: '50px', width: '100%', height: '40px' }}
      onClick={openScoring}
    >
      {SCORE_GAME_TEXT}
    </GradientButton>
  );
};
