import { useCallback } from 'react';
import { useGlobalComponentsContext } from '../contexts/GlobalComponentsContext';
import { useRedirectCallback } from './useRedirectCallback';
import { WizardOptions } from './wizard/createUseWizard';
import { SnackbarAlert, SnackbarAlertProps } from '../components/SnackbarAlert';

export type OpenSnackbarAlertProps = SnackbarAlertProps;
export const useSnackbarAlert = (
  id: string,
  options: WizardOptions = { closeOnRouteChange: true },
) => {
  const { union, remove } = useGlobalComponentsContext();
  const close = useCallback(() => {
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.closeOnRouteChange ? close : undefined);

  const open = useCallback(
    ({
      message,
      open: openSnackbar,
      onClose,
      ...rest
    }: OpenSnackbarAlertProps) => {
      union(
        id,
        <SnackbarAlert
          message={message}
          open={openSnackbar}
          onClose={(event, reason) => {
            if (!!onClose) {
              onClose(event, reason);
            }
            close();
          }}
          {...rest}
        />,
      );
    },
    [close, id, union],
  );

  return { open, close };
};
