import { useEffect, useMemo, useState } from 'react';
import {
  useIntersectionObserver,
  ExtendedIntersectionObserverOptions,
} from './useIntersectionObserver';

export const DEFAULT_INTERSECTION_OPTIONS: ExtendedIntersectionObserverOptions =
  {
    root: 'nearest-scrollable',
    threshold: 0,
  } as const;

export type UseNearEndObserverOptions = {
  onNearEnd?: () => void;
  /**
   * @remarks note that you should manually pad the rootMargin in the
   * direction of scrollDirection if you set intersectionOptions.rootMargin.
   */
  intersectionOptions?: ExtendedIntersectionObserverOptions;
  /**
   * @remarks 'down' indicates the content comes into view as the user scrolls down.
   */
  scrollDirection?: 'down' | 'up' | 'right' | 'left';
};

export const SCROLL_DIRECTION_MARGIN = '1000px' as const;

export function useNearEndObserver({
  onNearEnd,
  intersectionOptions = DEFAULT_INTERSECTION_OPTIONS,
  scrollDirection = 'down',
}: UseNearEndObserverOptions = {}) {
  const [nearEnd, setNearEnd] = useState<HTMLDivElement | null>(null);

  const optionsMargined = useMemo(() => {
    if (intersectionOptions.rootMargin) {
      return intersectionOptions;
    }
    const topMargin =
      scrollDirection === 'up' ? SCROLL_DIRECTION_MARGIN : '0px';
    const rightMargin =
      scrollDirection === 'right' ? SCROLL_DIRECTION_MARGIN : '0px';
    const bottomMargin =
      scrollDirection === 'down' ? SCROLL_DIRECTION_MARGIN : '0px';
    const leftMargin =
      scrollDirection === 'left' ? SCROLL_DIRECTION_MARGIN : '0px';

    return {
      ...intersectionOptions,
      rootMargin: `${topMargin} ${rightMargin} ${bottomMargin} ${leftMargin}`,
    };
  }, [intersectionOptions, scrollDirection]);

  const intersection = useIntersectionObserver(nearEnd, optionsMargined);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      onNearEnd?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection?.isIntersecting]);

  return { setNearEnd };
}
