import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';
import { isOAuthProvider } from '../../../functions/src/util/auth/isOAuthProvider';
import { unlinkFirebaseProvider } from './unlinkFirebaseProvider';

export type UnlinkProviderParams = {
  providerId: string;
  providerUid: string;
};
export const unlinkProvider = async ({
  providerId,
  providerUid,
}: UnlinkProviderParams) => {
  const { auth } = await import('../../config/firebase-client/auth');
  if (isOAuthProvider(providerId)) {
    const providersToReLink = isFirebaseOAuthProvider(providerId)
      ? await unlinkFirebaseProvider(providerId, providerUid)
      : undefined;
    //TODO: ideally open up a PR on Firebase to fix authUpdate functions and not do this anymore.
    const { unlinkOAuthProvider } = await import(
      '../../firebaseCloud/auth/unlinkOAuthProvider'
    );
    await unlinkOAuthProvider({
      providerId,
      providerUid,
      providersToReLink,
    });
    await auth.currentUser?.reload();
    return;
  }
  await unlinkFirebaseProvider(providerId);
  await auth.currentUser?.reload();
};
