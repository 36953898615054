import {
  RegistrationOption,
  RegistrationOptionAuto,
} from '../../types/firestore/Game/Tournament';

export const autoOptionsOf = (registrationOptions: RegistrationOption[]) => {
  return registrationOptions.filter(
    (option): option is RegistrationOptionAuto => {
      return 'providers' in option && option.providers !== 'fetched';
    },
  );
};
