import {
  ChannelGroup,
  GroupFilterMap,
  TOURNAMENT_FILTER_TYPES,
  TournamentFilterType,
  GroupFilter,
} from '..';
import { Timestamp } from 'firebase-admin/firestore';

export function isTournament<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<'Tournament', TTime> {
  const { groupFilter } = channelGroup;
  return (
    groupFilter.length === 2 &&
    (groupFilter as GroupFilter<'Tournament'>).every(({ type }) => {
      return TOURNAMENT_FILTER_TYPES.includes(type as TournamentFilterType);
    })
  );
}
