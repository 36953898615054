import { CustomSignInMethod } from '../../types/firestore/User';
import { deploymentUrlSchemed } from '../deploymentUrl';

export const oAuthRedirectUri = (
  onApp?: boolean,
  provider?: CustomSignInMethod,
) => {
  const subdir = onApp ? '' : '/index.html';
  const queryParam = onApp ? `?customProvider=${provider}` : '';
  const subdomain = onApp ? undefined : 'oauth.';
  return `${deploymentUrlSchemed(subdomain)}/oauth${subdir}${queryParam}`;
};
