import { useEffect, useRef, useMemo, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { hasDatadogUserChanged } from '../util/user/hasDatadogUserChanged';
import {
  DatadogUser,
  extractDatadogUser,
} from '../util/user/extractDatadogUser';
import { datadogRum } from '@datadog/browser-rum';

export function useDatadog() {
  const { userDataFull } = useAuth();
  const datadogRumRef = useRef<typeof datadogRum | null>(null);

  const datadogUser = useMemo(() => {
    return extractDatadogUser(userDataFull);
  }, [userDataFull]);

  const userDataPrevious = useRef<DatadogUser | undefined>(datadogUser);

  const setDatadogUser = useCallback((user?: DatadogUser) => {
    if (!user || !datadogRumRef.current) {
      return;
    }
    datadogRumRef.current.setUser({ ...user, name: user.username });
  }, []);

  const changeDatadogUser = useCallback(
    (user: DatadogUser) => {
      if (!datadogRumRef.current) {
        return;
      }
      datadogRumRef.current.addAction('user-data-change', user);
      setDatadogUser(user);
    },
    [setDatadogUser],
  );

  useEffect(() => {
    if (datadogRumRef.current) {
      return;
    }

    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      site: process.env.NEXT_PUBLIC_DATADOG_SITE,
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      env: process.env.NODE_ENV,
      version: process.env.NEXT_PUBLIC_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();

    datadogRumRef.current = datadogRum;

    setDatadogUser(datadogUser);
  }, [datadogUser, setDatadogUser]);

  useEffect(() => {
    if (
      !datadogUser ||
      !hasDatadogUserChanged(datadogUser, userDataPrevious.current) ||
      !datadogRumRef.current
    ) {
      return;
    }

    changeDatadogUser(datadogUser);

    userDataPrevious.current = datadogUser;
  }, [changeDatadogUser, datadogUser]);
}
