import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';

import {
  SelectableRegistrationOption,
  ProviderDetail,
} from './SelectableRegistrationOption';

export type SelectableRegistrationOptionsProps = {
  providerDetails: ProviderDetail[];
};

export const SelectableRegistrationOptions = memo(
  function SelectableRegistrationOptionsUnmemoized({
    providerDetails,
  }: SelectableRegistrationOptionsProps) {
    const options = useMemo(() => {
      return providerDetails.map((detail) => {
        const { value: inGameId, provider, isSelected, onSelect } = detail;
        return (
          <SelectableRegistrationOption
            provider={provider}
            value={inGameId}
            isSelected={isSelected}
            onSelect={onSelect}
            key={`${inGameId}-${provider}`}
          />
        );
      });
    }, [providerDetails]);
    return (
      <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
        {options}
      </Stack>
    );
  },
);
