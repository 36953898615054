import { useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
import { RoundStep } from './RoundStep';
import { RoundStepperBox, RoundStepperBoxProps } from './RoundStepperBox';
import { useMobile } from 'src/hooks/useMobile';
import { calculateRoundPayouts } from 'src/util/bracket/calculateRoundPayouts';
import { createRoundSteps } from 'src/util/bracket/createRoundSteps';
import { calculateRoundStepWidth } from 'src/util/bracket/calculateRoundStepWidth';
import { useBracketWidth } from '../../contexts/BracketWidthContext';
import { useCurrency } from '../../contexts/CurrencyContext';
import {
  BracketRoundsKeys,
  Round,
} from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { BracketContext } from '../../contexts/docs/BracketContext';

export type RoundStepperProps = Omit<
  RoundStepperBoxProps,
  'children' | 'finalRoundPrize' | 'isMobile'
> & {
  rounds: Pick<Round, 'payout'>[];
  activeRound?: number;
  activeRoundProgress?: number;
  bracketVariant?: BracketRoundsKeys;
};

export const RoundStepper = memo(function RoundStepperUnmemoized({
  rounds,
  activeRound = -1,
  activeRoundProgress,
  bracketVariant,
}: RoundStepperProps) {
  const roundsCount = rounds.length;
  const isMobile = useMobile();
  const { bracketWidth } = useBracketWidth();
  const { formatPayout } = useCurrency();

  const isLoserBracket = bracketVariant === 'bracketLoser';
  const bracket = useContext(BracketContext);
  const targetPayoutRounds = useMemo(() => {
    if (!bracket) {
      return [];
    }
    const grandFinalRounds = bracket.grandFinal;
    const bracketRounds = bracket.bracket;
    const bracketLoserRounds = bracket.bracketLoser;
    if (
      !!grandFinalRounds &&
      !!bracketRounds &&
      bracketVariant === 'grandFinal'
    ) {
      return [bracketRounds[bracketRounds.length - 1], grandFinalRounds[0]];
    }
    if (!!bracketLoserRounds && bracketVariant === 'bracketLoser') {
      return bracketLoserRounds;
    }
    return bracketRounds;
  }, [bracket, bracketVariant]);

  const { payouts, finalRoundPayout } = calculateRoundPayouts({
    roundPayouts: targetPayoutRounds,
  });

  const { roundSteps, roundStepsReversed } = createRoundSteps({
    rounds,
    roundPayouts: payouts,
    activeRound,
    activeRoundProgress,
    isLoserBracket,
    isMobile,
  });

  const roundStepsRendered = [...roundSteps, ...roundStepsReversed];

  return (
    <RoundStepperBox
      finalRoundPrize={finalRoundPayout}
      invert={isLoserBracket}
      isGrandFinal={bracketVariant === 'grandFinal'}
      sx={
        isMobile
          ? {}
          : {
              minWidth:
                roundsCount === 3
                  ? '786px'
                  : roundsCount === 2 && !isLoserBracket
                  ? '642px'
                  : '232px',
              maxWidth: bracketWidth,
            }
      }
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: isLoserBracket ? 'flex-end' : undefined,
          justifyContent: isMobile ? 'flex-start' : 'center',
          width: '100%',
        }}
      >
        {roundStepsRendered.map((roundStep, index) => {
          const { payout, roundNumber, isReversed } = roundStep;

          const roundPayout = payout && formatPayout(payout.payout);
          return (
            <Box
              key={`${isReversed}-${roundNumber}`}
              sx={{
                width: calculateRoundStepWidth({
                  isMobile,
                  roundStep,
                  index,
                  roundsCount,
                }),
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RoundStep
                {...roundStep}
                payout={roundPayout}
                invert={isLoserBracket}
              />
            </Box>
          );
        })}
      </Box>
    </RoundStepperBox>
  );
});
