import { BASE_ERROR } from '../baseError';

export const DUPLICATED_TEAMS = 'Duplicated Teams' as const;
export const MATCH_MISSING_TEAM = 'Match is missing a team' as const;

export const REFEREE_ERROR_LIBRARY = {
  [MATCH_MISSING_TEAM]:
    'You need scores from both teams in order to complete scoring a match.',
  [DUPLICATED_TEAMS]: 'This team is already present in the next match.',
  ...BASE_ERROR,
} as const;
