import { FC } from 'react';
import { memo } from '../../../util/memo';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';

const UnregisteredDialogUnmemoized: FC = () => {
  const { go } = useWizard();

  return (
    <DialogBodyStandard
      title={`You've Unregistered`}
      description={'You left the tournament'}
    >
      <DialogActionsStandard
        buttons={[
          {
            isAsync: false,
            children: 'Close',
            onClick: () => {
              return go(undefined);
            },
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const UnregisteredDialog = memo(UnregisteredDialogUnmemoized);
