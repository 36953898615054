import {
  useRoomContext as useLivekitRoom,
  useConnectionState,
} from '@livekit/components-react';

export const useConnectionStatus = () => {
  const room = useLivekitRoom();
  const connectionState = useConnectionState(room);

  return {
    isConnected: connectionState === 'connected',
    isConnecting: connectionState === 'connecting',
  };
};
