import { Tournament } from '../../../../../functions/src/types/firestore/Game/Tournament';
import { toPlacementDoubleElimination } from '../../../../../functions/src/util/placements/DoubleEliminationPlacementStrategy';
import { toPlacementSingleElimination } from '../../../../../functions/src/util/placements/SingleEliminationPlacementStrategy';

export const PLACER_MAP: {
  [TBracketType in Tournament['bracketType']]: (
    payoutIndex: number,
  ) => number[];
} = {
  heats: (payoutIndex) => {
    return [payoutIndex + 1, payoutIndex + 1];
  },
  'single-elimination': (payoutIndex) => {
    if (payoutIndex === 0 || payoutIndex === 1) {
      return [payoutIndex + 1, payoutIndex + 1];
    }
    return [payoutIndex - 2, payoutIndex - 1].map(toPlacementSingleElimination);
  },
  'double-elimination': (payoutIndex) => {
    if (payoutIndex < 4) {
      return [payoutIndex + 1, payoutIndex + 1];
    }
    return [payoutIndex - 2, payoutIndex - 1].map(toPlacementDoubleElimination);
  },
  'no-bracket': (payoutIndex) => {
    return [payoutIndex + 1, payoutIndex + 1];
  },
};
