import { memo } from '../../util/memo';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { DialogNavbar } from '../dialog/DialogNavbar';
import { useWizard } from '../wizard/Wizard';
import { AuthFlowStore } from '../../hooks/auth/useAuthFlowBase';

export type DialogAuthenticationNavBarProps = {
  onClose?: () => void;
};

const DialogAuthenticationNavBarUnmemoized = ({
  onClose,
}: DialogAuthenticationNavBarProps) => {
  const { clearAllErrors, clearAllCredentials } = useAuthSubmit();
  const { go, prev } = useWizard<AuthFlowStore, string>();
  const back = () => {
    clearAllCredentials();
    clearAllErrors();
    go(prev);
  };
  const close = () => {
    clearAllCredentials();
    clearAllErrors();
    go(undefined);
    onClose?.();
  };
  return <DialogNavbar goBack={back} onClose={close} />;
};

export const DialogAuthenticationNavBar = memo(
  DialogAuthenticationNavBarUnmemoized,
);
