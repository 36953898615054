import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { generateBracketGrandFinal } from 'src/util/bracket/generateBracketGrandFinal';
import { BracketProps } from './Bracket';
import { BracketBackground } from './BracketBackground';
import { memo } from '../../../util/memo';
import { useMemo } from 'react';

export const BracketGrandFinal = memo(function BracketGrandFinalUnmemoized({
  rounds,
}: BracketProps) {
  const { matches } = generateBracketGrandFinal(rounds);
  const cellDisabled = !(
    matches[1]?.match.props.match.team1 || matches[1]?.match.props.match.team2
  );

  const matchElements = useMemo(() => {
    return matches.map(({ match, matchId }, index) => {
      const matchElement = (
        <Box
          key={matchId}
          sx={{
            opacity: index === 1 && cellDisabled ? '40%' : '100%',
          }}
        >
          {match}
        </Box>
      );

      if (index !== 1 || !cellDisabled) return matchElement;

      return (
        <Tooltip
          key={matchId}
          title="A second match is to be played if the competitor from the winner's bracket loses the first match."
          arrow
        >
          {matchElement}
        </Tooltip>
      );
    });
  }, [matches, cellDisabled]);

  return (
    <BracketBackground roundsLength={rounds.length} variant={'grandFinal'}>
      {matchElements}
    </BracketBackground>
  );
});
