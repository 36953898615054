import { memo } from '../../../../util/memo';
import { MatchDetailsScoreline } from './MatchDetailsScoreline';
import Stack from '@mui/material/Stack';
import { SessionActionable } from './SessionActionable';
import { useMemo } from 'react';
import { SessionScreenshotCarousel } from './SessionScreenshotCarousel';

export const SessionDetails = memo(function SessionDetailsUnmemoized() {
  const actionComponent = useMemo(() => {
    return (
      <>
        <MatchDetailsScoreline />
        <SessionActionable />
        <Stack width="100%" justifyContent="center" alignItems="center" mb={4}>
          <SessionScreenshotCarousel />
        </Stack>
      </>
    );
  }, []);

  return (
    <Stack p={4} spacing={4}>
      {actionComponent}
    </Stack>
  );
});
