import { MESSAGE_MAX_WIDTH } from '../../components/messaging/message/Message';

export const ATTACHMENT_MAX_HEIGHT = 470;
export const ATTACHMENT_MAX_HEIGHT_MOBILE = 776;
export const LINK_IMAGE_HEIGHT = 164;

export type CalculateImageDimensionsParams = {
  width: number;
  height: number;
};

export const calculateImageDimensions = ({
  width,
  height,
}: CalculateImageDimensionsParams) => {
  const widthScale = Math.min(1, MESSAGE_MAX_WIDTH / width);
  const heightScale = Math.min(1, ATTACHMENT_MAX_HEIGHT / height);
  const minScale = Math.min(widthScale, heightScale);

  return {
    width: width * minScale,
    height: height * minScale,
  };
};
