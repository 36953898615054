import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { memo } from '../../../util/memo';
import { LoadingButton } from '../../buttons/LoadingButton';
import { FormItem } from '../../form/FormItem';
import { GradientRadio } from '../../gradients/GradientRadio';
import { BasicInput } from '../../data-entry-ui/generic/BasicInput';

export type MuteUserFormProps = {
  onClose: () => void;
  onMuteUser: (muteHours?: number, customMuteHours?: number) => Promise<void>;
};

// eslint-disable-next-line react-memo/require-memo
const MuteFormUnmemoized = ({ onClose, onMuteUser }: MuteUserFormProps) => {
  const [muteHours, setMuteHours] = useState<number | undefined>(undefined);
  const [customMuteHours, setCustomMuteHours] = useState<number | undefined>(
    undefined,
  );

  const selectMuteHours = (hours: number | string) => {
    if (hours === 'textfield') {
      setMuteHours(undefined);
    } else {
      setMuteHours(Number(hours));
    }
  };

  return (
    <Stack direction="column" width="100%" spacing={4} minWidth={480}>
      <FormControl>
        <FormItem title="Duration">
          <RadioGroup
            row
            onChange={(event) => {
              const { value } = event.target;
              selectMuteHours(value);
            }}
          >
            <Stack direction="column">
              <FormControlLabel
                value={60}
                control={<GradientRadio />}
                label="1 hour"
              />
              <FormControlLabel
                value={120}
                control={<GradientRadio />}
                label="2 hours"
              />
              <FormControlLabel
                value={180}
                control={<GradientRadio />}
                label="3 hours"
              />
              <FormControlLabel
                control={<GradientRadio value="textfield" />}
                label={
                  <BasicInput
                    inputType="number"
                    disabled={!!muteHours}
                    label="Other"
                    defaultValue={String(customMuteHours)}
                    value={String(customMuteHours)}
                    onChange={(value) => {
                      return setCustomMuteHours(Number(value));
                    }}
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </FormItem>
      </FormControl>
      <Stack direction="row" spacing={2} width="100%" pt={4}>
        <LoadingButton
          fullWidth
          variant="contained"
          disabled={!muteHours && !customMuteHours}
          onClick={async () => {
            await onMuteUser(muteHours, customMuteHours);
            onClose();
          }}
        >
          Mute
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export const MuteForm = memo(MuteFormUnmemoized);
