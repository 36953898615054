import { useDialog } from '../useDialog';
import { MuteForm } from '../../components/messaging/actions/MuteForm';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { ONE_HOUR_MINS } from '../../util/dates/Dates';
import { ActionButton } from '../../components/messaging/actions/ActionButton';

export type UserMuteUserParams = {
  messageId: string;
  username: string;
  userId: string;
};

export const useMuteUser = ({ messageId, username, userId }) => {
  const { open: openDialog, close: closeDialog } = useDialog(messageId);
  const { client } = useStreamChatContext();

  const muteUser = () => {
    openDialog({
      title: 'Mute User',
      description: `Are you sure you want to mute ${username}?`,
      children: (
        <MuteForm
          onClose={closeDialog}
          onMuteUser={async (muteHours, customMuteHours) => {
            await client.muteUser(userId, undefined, {
              timeout: (muteHours || customMuteHours)! * ONE_HOUR_MINS,
            });
          }}
        />
      ),
    });
  };

  const unmuteUser = () => {
    openDialog({
      title: 'Unmute User',
      description: `Are you sure you want to unmute ${username}?`,
      children: (
        <ActionButton
          label="Unmute"
          onClick={async () => {
            await client.unmuteUser(userId);
            closeDialog();
          }}
        />
      ),
    });
  };
  return { muteUser, unmuteUser };
};
