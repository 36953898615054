import { MediaPreferencesDialog } from '../../components/voice-chat/MediaPreferencesDialog';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import type { Room } from 'livekit-client';
import { useWizardDialog } from '../wizard/useWizardDialog';

export type MediaPreferencesStore = { room: Room };

export const useMediaPreferencesDialog = () => {
  const room = useLivekitRoom();

  const { open: openWizardDialog, close } = useWizardDialog();

  const open = () => {
    const storeDefault: MediaPreferencesStore = { room };

    openWizardDialog({
      storeDefault,
      elements: {
        EntryDialog: <MediaPreferencesDialog />,
      },
      elementIdEntry: 'EntryDialog',
    });
  };

  return { open, close };
};
