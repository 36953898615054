export type GroupIdConfig = {
  key: string;
  location: 'queryParam';
  prepare?: (value: string) => string;
};

export const GROUP_IDS: GroupIdConfig[] = [
  {
    key: 'event',
    location: 'queryParam',
    prepare: (value: string) => {
      return value.split('/').pop() || '';
    },
  },
  {
    key: 'channelGroup',
    location: 'queryParam',
  },
  // {
  //   key: 'channel',
  //   location: 'queryParam',
  // },
  // {
  //   key: 'livestream',
  //   location: 'queryParam',
  // },
] as const;

export type GroupKeys = typeof GROUP_IDS[number]['key'];
export type GroupIdMap = Record<GroupKeys, string>;
