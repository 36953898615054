import { useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { ScoreOption } from 'functions/src/types/firestore/Game/Competition';
import { QualificationConditions } from 'functions/src/types/firestore/Game/Tournament';
import { useLeaderboardEditing } from 'src/hooks/referee/useLeaderboardEditing';
import { useReferee } from 'src/contexts/RefereeContext';
import { useLeaderboardColumns } from 'src/hooks/leaderboards/useLeaderboardColumns';
import { LeaderboardStandard } from '../LeaderboardStandard';
import { correctRoundingError } from '../../../../functions/src/util/correctRoundingError';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Heat/Result';

export type HeatLeaderboardTableProps = {
  results?: ResultRanked[];
  qualification?: QualificationConditions;
  scoreOptions?: ScoreOption[];
};

const HeatLeaderboardTableUnmemoized = ({
  results: resultsUnrounded = [],
  qualification,
  scoreOptions,
}: HeatLeaderboardTableProps) => {
  const { isReferee } = useReferee();
  const leaderboardPropsReferee = useLeaderboardEditing(scoreOptions);

  const results = resultsUnrounded.map((result) => {
    const { scores = [] } = result;
    return {
      ...result,
      scores: scores.map(correctRoundingError),
    };
  });

  const columns = useLeaderboardColumns({
    qualification,
    scoreOptions,
    results,
  });

  const [page, setPage] = useState(0);
  const pageSize = useMemo(() => {
    return results.length < 10 ? results.length : 10;
  }, [results.length]);

  return (
    <LeaderboardStandard
      title={'Leaderboard'}
      rows={
        isReferee
          ? results.map((result) => {
              return { ...result, editable: true, dynamicHeight: true };
            })
          : results
      }
      getRowId={(row) => {
        return `${row.userIdCaptain}-${row.id}`;
      }}
      columns={columns}
      pagination
      page={page}
      getRowSpacing={() => {
        return { top: 5, bottom: 5 };
      }}
      pageSize={pageSize}
      onPageChange={setPage}
      disableColumnFilter
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      {...leaderboardPropsReferee}
    />
  );
};
export const HeatLeaderboardTable = memo(HeatLeaderboardTableUnmemoized);
