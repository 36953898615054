import { ParticipantTeam } from '../../../types/firestore/Game/Tournament/Participant';
import { MatchFactory } from './MatchFactory';
import { DEFAULT_COHORT_SIZE, EliminationPairer } from './EliminationPairer';
import {
  MatchAggregated,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { Required } from 'utility-types';
import { LinkedListUtil } from './LinkedListUtil';
import { shuffleSeeded } from '../../../util/randomSeeded';
//import { log } from '../../../util/log';
import { RoundFactory } from './RoundFactory';
import { EliminationTournament } from './EliminationTournament';
import { Member } from '../../../types/firestore/Game/Tournament/Guestlist';
import { TournamentRNG } from '../web3/TournamentRNG';
import { Tournament } from '../../../types/firestore/Game/Tournament';
import { HttpsError } from '../../../util/errors/HttpsError';

export type SingleEliminationBracketSettings = {
  tournament: Tournament;
  participants: ParticipantTeam[];
};

//@log
export class SingleElimination extends EliminationTournament {
  public constructor(
    matchFactory: MatchFactory = new MatchFactory(),
    roundFactory: RoundFactory = new RoundFactory(),
    private readonly cohortSize: number = DEFAULT_COHORT_SIZE,
  ) {
    super(matchFactory, roundFactory);
  }

  public static async bracket({
    tournament,
    participants: participantsWithDetails,
  }: SingleEliminationBracketSettings) {
    // TODO: remove when we replace Bracket data model with BLU-4200
    const participants = participantsWithDetails.map((team) => {
      const membersNew = team.members.map(
        ({
          username,
          userId,
          tournamentId,
          status,
          imgUrl,
          checkedIn,
          invitedById,
          inGameId,
        }): Member => {
          return {
            username,
            userId,
            tournamentId,
            status,
            imgUrl,
            checkedIn,
            invitedById,
            inGameId,
          };
        },
      );
      return {
        ...team,
        members: membersNew,
      };
    });
    const { bracketType, payouts, id } = tournament;
    if (bracketType === 'heats') {
      return [];
    }

    const tournamentRNG = new TournamentRNG(id);
    const randomSeed = (await tournamentRNG.getRandomSeed()).toString();

    const participantsShuffled = shuffleSeeded(participants, randomSeed);
    if (participantsShuffled.length < 2) {
      return [];
    }
    const singleElimination = new SingleElimination(
      new MatchFactory({ bestOf: tournament.bestOf }),
      new RoundFactory({
        payouts,
      }),
    );
    return singleElimination.buildBracket(participantsShuffled);
  }

  public buildBracket(
    participants: ParticipantTeam[],
  ): Required<Round, 'matches'>[] {
    const initialRound = this.buildInitialRound(participants);
    return SingleElimination.tieRounds(
      this.germinateBracket(
        initialRound,
        Math.log2(initialRound.matches.length) + 1,
      ),
    );
  }

  public buildInitialRound(
    participants: ParticipantTeam[],
  ): Required<Round, 'matches'> {
    const pairer = new EliminationPairer(participants, this.matchFactory, {
      cohortSize: this.cohortSize,
    });
    const matchesInitial = pairer.pair();

    return this.roundFactory.build(matchesInitial);
  }

  protected nextMatches(matches: MatchAggregated[]): MatchAggregated[] {
    return [...Array(matches.length / 2).keys()]
      .map((i) => {
        return i * 2;
      })
      .map((i) => {
        const match1 = matches[Number(i)];
        const match2 = matches[i + 1];

        if (!match1 || !match2) {
          throw new HttpsError('internal', 'Unable to tie undefined matches', `Match 1: ${match1}, Match 2: ${match2}`);
        }

        const matchNext = this.matchFactory.build(match1.winner, match2.winner);

        LinkedListUtil.tieCoalesce(match1, match2, matchNext);

        return matchNext;
      });
  }
}
