import Box from '@mui/material/Box';
import { memo } from '../../util/memo';
import { useTracks, AudioTrack } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { useMemo } from 'react';

const AudioTracksUnmemoized = () => {
  const tracks = useTracks([
    Track.Source.Microphone,
    Track.Source.ScreenShareAudio,
    Track.Source.Unknown,
  ]);

  const trackRefs = useMemo(() => {
    return tracks.filter((ref) => {
      return (
        !ref.participant.isLocal && ref.publication.kind === Track.Kind.Audio
      );
    });
  }, [tracks]);

  const audioTracks = useMemo(() => {
    return trackRefs.map((trackRef) => {
      return (
        <AudioTrack
          key={trackRef.participant.identity}
          trackRef={trackRef}
          volume={1}
          muted={false}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackRefs.length]);

  return <Box sx={{ display: 'none' }}>{audioTracks}</Box>;
};

export const AudioTracks = memo(AudioTracksUnmemoized);
