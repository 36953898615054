import { useTheme } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import { FC, ReactNode } from 'react';
import { memo } from '../../util/memo';

export type InstancePanelHeaderedProps = {
  Header: ReactNode;
  PanelContent?: ReactNode;
};

export const InstancePanelHeadered: FC<InstancePanelHeaderedProps> = memo(
  function InstancePanelHeaderedUnmemoized({ Header, PanelContent }) {
    const theme = useTheme();
    return (
      // TODO: adjust CSS to get the panel colours right.
      // Allow user to pick panel colours?
      <Stack direction="column" gap={0} spacing={0}>
        <Stack
          sx={{ ...theme.panels[1], p: 4, borderRadius: '10px 10px 0px 0px' }}
        >
          {Header}
        </Stack>
        {PanelContent && (
          <Stack
            sx={{
              ...theme.panels[0],
              p: 4,
              borderRadius: '0px 0px 10px 10px',
              // TODO: we should fix the relationship between figma panels and our theme panels
              filter: 'brightness(1.05)',
            }}
          >
            {PanelContent}
          </Stack>
        )}
      </Stack>
    );
  },
);
