import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { ImageOptimized } from '../../image/ImageOptimized';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { ChangeEvent, MutableRefObject, useMemo, useRef } from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVertRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import Typography from '@mui/material/Typography';

export type ImageInputBasicProps = {
  imageProperties: {
    width: number;
    height: number;
  };
  value: string;
  label?: string;
  required: boolean;
  onUpload: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void | string | undefined>;
};

export const ImageInputBasic = memo(function ImageInputBasicUnmemoized({
  imageProperties,
  value,
  label,
  required,
  onUpload,
}: ImageInputBasicProps) {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | undefined>();
  const { width, height } = imageProperties;
  const UploadIcon = useMemo(() => {
    return value ? SwapVertIcon : AddRounded;
  }, [value]);

  const showError = useMemo(() => {
    return required && !value;
  }, [required, value]);

  return (
    <Stack gap={4}>
      <Stack
        sx={{
          ...theme.panels[1],
          padding: 0,
          position: 'relative',
          width: '100%',
          'aspect-ratio': `${width / height}`,
          border: `2px dashed ${
            showError ? theme.palette.error.main : theme.palette.primary.dark
          }`,
        }}
        direction={'column'}
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <ImageOptimized
          src={value}
          layout="fill"
          style={{ opacity: 0.2 }}
          objectFit="cover"
          objectPosition="center"
        />
        <Stack
          direction="column"
          sx={{
            justifyContent: 'center',
            position: 'relative',
            width,
            height,
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            <GradientIconButton
              sxOuter={{ width: 75, height: 75, alignSelf: 'center' }}
              sx={{
                width: 75,
                height: 75,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              IconComponent={UploadIcon}
              onClick={() => {
                return fileInput.current && fileInput.current.click();
              }}
              gradientColor={
                showError ? 'error.horizontal' : 'primary.horizontal'
              }
            />

            <input
              ref={fileInput as MutableRefObject<HTMLInputElement>}
              type="file"
              accept={'image/*'}
              style={{ display: 'none' }}
              onChange={onUpload}
            />

            <Typography
              color="text.secondary"
              textTransform="uppercase"
              fontWeight={600}
            >
              {label}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
