import { MatchAggregated } from '../../types/firestore/Game/Tournament/Bracket';
import { DUPLICATED_TEAMS } from '../error/library/referee';
import { HttpsError } from '../errors/HttpsError';

export function assertNoDuplicatesOf<TTime>(
  teamId?: string,
  match?: MatchAggregated<TTime>,
) {
  const exists =
    !!teamId && (match?.team1?.id === teamId || match?.team2?.id === teamId);
  if (exists) {
    throw new HttpsError('permission-denied', DUPLICATED_TEAMS, `${teamId}`);
  }
}
