import { SetPersonalChannelGroupParams } from '../../../functions/src/callable/messaging/setPersonalChannelGroup.f';
import { ExitChannelGroupExternalParams } from '../../../functions/src/callable/messaging/exitChannelGroupExternal.f';
import { useCallback } from 'react';

export const usePersonalChannelGroup = () => {
  const set = useCallback(
    async ({
      imgUrl,
      title,
      subtitle,
      type,
      friendIds,
      messagesInitial,
    }: SetPersonalChannelGroupParams) => {
      const { setPersonalChannelGroup } = await import(
        'src/firebaseCloud/messaging/setPersonalChannelGroup'
      );
      const channelGroup = await setPersonalChannelGroup({
        imgUrl,
        title,
        subtitle,
        type,
        friendIds,
        messagesInitial,
      });
      return channelGroup;
    },
    [],
  );

  const remove = useCallback(
    async ({ groupFilter }: ExitChannelGroupExternalParams) => {
      const { exitChannelGroupExternal } = await import(
        'src/firebaseCloud/messaging/exitChannelGroup'
      );
      await exitChannelGroupExternal({ groupFilter });
    },
    [],
  );

  return { set, remove };
};
