import { useMemo } from 'react';
import { useReferee } from 'src/contexts/RefereeContext';

export const DETAILS_SUMMARY_KEY_PRIORITY = [
  'inGameName',
  'inGameId',
  'ethWallet',
  'waxWallet',
  'userId',
];
export const getDetailsSummary = (details: Record<string, unknown>): string => {
  for (const key of DETAILS_SUMMARY_KEY_PRIORITY) {
    if (key in details) {
      return `${key}: ${details[`${key}`]}`;
    }
  }
  return '';
};
export const useDetailsSummary = (details?: Record<string, unknown>[]) => {
  const { detailsOptions } = useReferee();
  const summaries = useMemo(() => {
    if (!details) {
      return [];
    }
    if (detailsOptions && detailsOptions?.keySummary) {
      const key = detailsOptions?.keySummary;
      return details.map((userDetails) => {
        return key in userDetails && !!userDetails[`${key}`]
          ? `${key}: ${userDetails[`${key}`]}`
          : `No ${key} found`;
      });
    }
    return details.map(getDetailsSummary);
  }, [details, detailsOptions]);
  return summaries;
};
