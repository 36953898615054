import { useCallback, useState } from 'react';
import { useSessionStorage } from '../contexts/SessionStorageContext';
import { IndividualSessionStorage } from '../../functions/src/types/firestore/User/SessionStorage';

export const useSessionState = <TKey extends keyof IndividualSessionStorage>(
  key: TKey,
): [
  IndividualSessionStorage[TKey],
  ReturnType<typeof useState<IndividualSessionStorage[TKey] | undefined>>[1],
] => {
  const { sessionStorage, updateSessionStorage } = useSessionStorage();
  const data = sessionStorage?.[key as string];

  const setData = useCallback(
    async (
      update:
        | IndividualSessionStorage[TKey]
        | undefined
        | ((
            prevState: IndividualSessionStorage[TKey] | undefined,
          ) => IndividualSessionStorage[TKey] | undefined),
    ) => {
      const updateResolved =
        typeof update === 'function' ? update(data) : update;

      await updateSessionStorage({
        [key]: updateResolved,
      });
    },
    [key, data, updateSessionStorage],
  );

  return [data, setData];
};
