/* eslint-disable security/detect-object-injection */
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  ReactNode,
  ReactElement,
} from 'react';
import { memo } from '../../util/memo';
import { insertInnermostChild } from '../../util/insertInnermostChild';

export type WizardStore = Record<string, unknown>;
export type WizardStoreGet<TStore extends WizardStore> = <
  TKey extends keyof TStore,
>(
  key: TKey,
) => TStore[TKey];
export type WizardStoreSet<TStore extends WizardStore> = <
  TKey extends keyof TStore,
>(
  key: TKey,
  valueNew: TStore[TKey],
) => void;

export type WizardContextType<
  TStore extends WizardStore,
  TElementId extends string,
> = {
  get: WizardStoreGet<TStore>;
  set: WizardStoreSet<TStore>;
  reset: () => void;
  go: (elementId?: TElementId) => void;
  prev?: TElementId;
  curr?: TElementId;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WizardContext = createContext<WizardContextType<any, any> | undefined>(
  undefined,
);

export function useWizard<
  TStore extends WizardStore,
  TElementId extends string = string,
>(): WizardContextType<TStore, TElementId> {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context as unknown as WizardContextType<TStore, TElementId>;
}

export type WizardElements = Record<string, ReactElement>;

export type WizardProps<
  TStore extends WizardStore,
  TElements extends WizardElements,
> = {
  Wrapper: ReactElement<{ children?: ReactNode }>;
  storeDefault: TStore;
  elements: TElements;
  elementIdEntry: keyof TElements & string;
  onClose?: () => void;
};

export const Wizard = memo(function WizardUnmemoized<
  TStore extends WizardStore,
  TElements extends WizardElements,
>({
  Wrapper,
  storeDefault,
  elements,
  elementIdEntry,
  onClose = () => {
    /* */
  },
}: WizardProps<TStore, TElements>) {
  const [store, setStore] = useState(storeDefault);

  const [elementPrevIds, setElementPrevIds] = useState<
    (keyof TElements & string)[]
  >([]);
  const elementPrevId = useMemo(() => {
    return elementPrevIds.length
      ? elementPrevIds[elementPrevIds.length - 1]
      : undefined;
  }, [elementPrevIds]);

  const [elementId, setElementId] = useState(elementIdEntry);
  const [element, setElement] = useState(elements[elementIdEntry]);

  const set: WizardStoreSet<TStore> = useCallback(
    (key, valueNew) => {
      const contextNew = store;
      contextNew[key] = valueNew;
      setStore(contextNew);
    },
    [store],
  );

  const get: WizardStoreGet<TStore> = useCallback(
    (key) => {
      return store[key];
    },
    [store],
  );

  const reset = useCallback(() => {
    setStore(storeDefault);
  }, [storeDefault]);

  const go = useCallback(
    (elementIdNew: (keyof TElements & string) | undefined) => {
      if (!elementIdNew) {
        onClose();
        return;
      }
      const indexNew = elementPrevIds.findIndex((id) => {
        return id === elementIdNew;
      });
      setElementPrevIds((prevs) => {
        return indexNew !== -1
          ? prevs.slice(0, indexNew)
          : [...prevs, elementId];
      });
      setElementId(elementIdNew);
      setElement(elements[elementIdNew]);
    },
    [elementId, elementPrevIds, elements, onClose],
  );

  const wizardContext: WizardContextType<TStore, keyof TElements & string> =
    useMemo(() => {
      return {
        set,
        get,
        go,
        reset,
        prev: elementPrevId,
        curr: elementId,
      };
    }, [set, get, reset, go, elementPrevId, elementId]);

  return (
    <WizardContext.Provider value={wizardContext}>
      {insertInnermostChild(Wrapper, element)}
    </WizardContext.Provider>
  );
});
