import { memo } from '../util/memo';
import MarkdownToJsx from 'markdown-to-jsx';
import { useLocalizedMarkdownDate } from 'src/hooks/useLocalizedMarkdownDate';
import Box from '@mui/material/Box';

export type MarkdownProps = {
  text: string;
};
export const Markdown = memo(function ReactMarkdownStyleUnmemoized({
  text,
}: MarkdownProps) {
  const textModified = useLocalizedMarkdownDate(text);
  return (
    <>
      {(textModified?.split?.('\\n') || []).map((paragraph) => {
        return (
          <Box
            key={`${paragraph.slice(0, Math.min(100, paragraph.length))}`}
            className="markdown-links"
          >
            <MarkdownToJsx>{paragraph}</MarkdownToJsx>
          </Box>
        );
      })}
    </>
  );
});
