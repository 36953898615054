import { useEffect, useRef } from 'react';

export function useOnChange<T>(
  value: T,
  onChange: (newValue: T, oldValue?: T | undefined) => void,
) {
  const previousValueRef = useRef<T | undefined>(undefined);

  useEffect(() => {
    if (value !== previousValueRef.current) {
      onChange(value, previousValueRef.current);
      previousValueRef.current = value;
    }
  }, [value, onChange]);
}
