const WINDOW_LEN = 4;

export function truncate(
  address: string,
  settings: { windowLen: number } = { windowLen: WINDOW_LEN },
) {
  return `${address.substring(0, settings.windowLen)}...${address.substring(
    address.length - 2,
    address.length,
  )}`;
}

export function truncateIfTooLong(text: string, maxLength = 13) {
  if (text && text.length > maxLength) {
    return truncate(text, { windowLen: maxLength - 3 });
  }
  return text;
}
