import { uuidv4Base62 } from '../../../functions/src/util/uuidv4Base62';

export const SESSION_STORAGE_KEY = 'sessionId';

export const getSessionId = () => {
  if (typeof sessionStorage === 'undefined') {
    return;
  }

  const sessionIdExisting = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const sessionId = sessionIdExisting || uuidv4Base62();

  if (!sessionIdExisting) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);
  }

  return sessionId;
};
