import { memo } from '../../util/memo';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { PasswordResetButton } from './PasswordResetButton';

const FORGOT_PASSWORD_BUTTON_TEXT = 'Forgot password?';

export const ForgotPassword = memo(function ForgotPasswordUnmemoized() {
  const {
    userCredentials: { email },
  } = useAuthSubmit();

  return (
    <PasswordResetButton
      email={email}
      text={FORGOT_PASSWORD_BUTTON_TEXT}
      sx={{ textAlign: 'right' }}
    />
  );
});
