import { callable } from '.';
import {
  SetGroupChannelParams,
  SetGroupChannelResponse,
} from '../../../functions/src/callable/messaging/setGroupChannel';

export type { SetGroupChannelParams, SetGroupChannelResponse };

export const setGroupChannel = callable<
  SetGroupChannelParams,
  SetGroupChannelResponse
>('setGroupChannel');
