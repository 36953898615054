import { ReactNode } from 'react';
import { memo } from '../util/memo';
import { Pulsate } from './animations/Pulsate';
import {
  hasUnreadMatchChannel,
  HasUnreadMatchChannelParams,
} from '../util/messaging/hasUnreadMatchChannel';
import { useTheme, SxProps } from '@mui/material/styles';
import { useUnreadMessageCount } from '../contexts/UnreadMessageCountContext';

export type PulsateUnreadMatchProps = Omit<
  HasUnreadMatchChannelParams,
  'unreadMessageCount'
> & {
  sx?: SxProps;
  children: ReactNode;
};

const PulsateUnreadMatchUnmemoized = ({
  children,
  sx = {},
  ...rest
}: PulsateUnreadMatchProps) => {
  const theme = useTheme();
  const { unreadMessageCount } = useUnreadMessageCount();

  const isLatestMatchChannelUnread = hasUnreadMatchChannel({
    unreadMessageCount,
    ...rest,
  });

  return (
    <Pulsate
      pulsate={isLatestMatchChannelUnread}
      color={theme.palette.error.main}
      sx={{ ...sx }}
    >
      {children}
    </Pulsate>
  );
};

export const PulsateUnreadMatch = memo(PulsateUnreadMatchUnmemoized);
