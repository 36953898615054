import { PlaybacksDownloadable } from '../../types/firestore/Game/Tournament';

export const extractPlaybackIds = (
  playbacksDownloadable?: PlaybacksDownloadable,
) => {
  // TODO: In the future, sort active playbackIds based on asset with most viewers

  if (!playbacksDownloadable) {
    return [];
  }

  const { active, inactive } = Object.keys(playbacksDownloadable).reduce(
    (result, id) => {
      if (playbacksDownloadable[id as string] === 'still-live') {
        result.active.push(id);
      } else {
        result.inactive.push(id);
      }
      return result;
    },
    { active: [] as string[], inactive: [] as string[] },
  );

  const playbacksOrdered = active.sort().concat(inactive);

  return playbacksOrdered;
};
