import { useEffect } from 'react';
import { GroupIdMap } from '../../util/routing/groupIds';
import { trackEvent } from '../../util/vercel/trackEvent';

export type InteractionTrackingData = Partial<GroupIdMap> & {
  id: string;
  hostname: string;
  browser: string;
  os: string;
  userId: string;
};

export const useClickTracking = <TDataCustom extends Record<string, string>>(
  data: InteractionTrackingData & TDataCustom,
  target: HTMLElement | null,
) => {
  useEffect(() => {
    if (!target) return;

    const trackClick = () => {
      trackEvent('click', data);
    };

    const trackFocus = () => {
      const activeElement = document.activeElement;
      if (activeElement && target.contains(activeElement)) {
        trackClick();
      }
    };

    target.addEventListener('click', trackClick, true);
    window.addEventListener('blur', trackFocus);

    return () => {
      target.removeEventListener('click', trackClick, true);
      window.removeEventListener('blur', trackFocus);
    };
  }, [data, target]);
};
