import type { DocumentData } from 'firebase/firestore';
import {
  Dispatch,
  EffectCallback,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDynamic } from '../useDynamic';

export type FirestoreModule =
  typeof import('/workspace/agora/src/config/firebase-client/firestore');
export type FirebaseFirestoreModule = typeof import('firebase/firestore');

export type FirestoreHandler<T> = (
  firestoreModule: FirestoreModule,
  firebaseFirestoreModule: FirebaseFirestoreModule,
  setData: Dispatch<SetStateAction<T | undefined>>,
) => ReturnType<EffectCallback>;

/**
 * @remarks
 * Argument handler should be memoized!
 */
export function useFirestore<T extends DocumentData>(
  handler: FirestoreHandler<T>,
  initialData?: T,
): T | undefined {
  const firestoreModule = useDynamic(
    import('../../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  const [data, setData] = useState<T | undefined>(initialData);

  useEffect(() => {
    if (!firestoreModule || !firebaseFirestoreModule) {
      return;
    }
    return handler(firestoreModule, firebaseFirestoreModule, setData);
  }, [firestoreModule, firebaseFirestoreModule, handler]);

  return data;
}
