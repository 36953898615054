import React, {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { memo } from '../util/memo';

export type ChannelListContextProps = {
  isChannelListVisible: boolean;
  showChannelList: () => void;
  hideChannelList: () => void;
};

export const ChannelListContext = createContext<
  ChannelListContextProps | undefined
>(undefined);

export type ChannelListProviderProps = {
  children: ReactNode;
  defaultOpen: boolean;
};

export const useChannelList = () => {
  const context = useContext(ChannelListContext);
  if (!context) {
    throw new Error('useChannelList must be used within a ChannelListProvider');
  }
  return context;
};

const ChannelListProviderUnmemoized: FC<ChannelListProviderProps> = ({
  children,
  defaultOpen,
}) => {
  const [isChannelListVisible, setIsChannelListVisible] =
    useState<boolean>(defaultOpen);

  useEffect(() => {
    setIsChannelListVisible(defaultOpen);
  }, [defaultOpen]);

  const showChannelList = useCallback(() => {
    setIsChannelListVisible(true);
  }, []);

  const hideChannelList = useCallback(() => {
    setIsChannelListVisible(false);
  }, []);

  const memoizedValue = useMemo(() => {
    return { isChannelListVisible, showChannelList, hideChannelList };
  }, [isChannelListVisible, showChannelList, hideChannelList]);

  return (
    <ChannelListContext.Provider value={memoizedValue}>
      {children}
    </ChannelListContext.Provider>
  );
};

export const ChannelListProvider = memo(ChannelListProviderUnmemoized);
