import Stack from '@mui/material/Stack';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { MatchActions } from './MatchActions';
import { useCallback, useMemo } from 'react';
import { useScoring } from '../../../contexts/ScoringContext';
import { EventFlowBase } from '../../organizer/new-event-wizard/EventFlowBase';
import { useActiveMatchRouter } from '../../../hooks/tournaments/useActiveMatchRouter';
import { useScoreSession } from '../../../hooks/tournaments/useScoreSession';
import { useActiveSessionStatus } from '../../../hooks/tournaments/useActiveSessionStatus';
import { memo } from '../../../util/memo';

export const SCORE_MATCH_TITLE = 'Score Game';
export const SCORING_INSTRUCTIONS =
  'Enter your match results below. Dishonesty will lead to a lifetime ban from BluMint.';
export const ALREADY_SCORED =
  'This game has already been scored by your team. Score again?';

export const GAME_COMPLETED = 'This game has already been completed.';

export type ScoringProps = {
  onSubmit?: () => void;
};

export const SessionScoringPortal = memo(
  function SessionScoringPortalUnmemoized({ onSubmit }: ScoringProps) {
    const { outcome } = useScoring();
    const { isActiveSessionCompleted, isActiveSessionScoredByTeam } =
      useActiveSessionStatus();
    const { sessionIndex } = useActiveMatchRouter();
    const { scoreSession } = useScoreSession();
    const score = useCallback(async () => {
      await scoreSession(onSubmit);
    }, [scoreSession, onSubmit]);

    const description = useMemo(() => {
      return isActiveSessionCompleted
        ? GAME_COMPLETED
        : isActiveSessionScoredByTeam
        ? ALREADY_SCORED
        : SCORING_INSTRUCTIONS;
    }, [isActiveSessionCompleted, isActiveSessionScoredByTeam]);

    const matchActions = useMemo(() => {
      return !isActiveSessionCompleted && <MatchActions />;
    }, [isActiveSessionCompleted]);

    const buttons = useMemo(() => {
      return (
        !isActiveSessionCompleted && (
          <DialogActionsStandard
            buttons={[
              {
                isAsync: true,
                children: 'Submit',
                color: 'primary',
                disabled: !outcome,
                onClick: score,
              },
            ]}
          />
        )
      );
    }, [isActiveSessionCompleted, outcome, score]);

    const content = useMemo(() => {
      return (
        <Stack gap={6}>
          {matchActions}
          {buttons}
        </Stack>
      );
    }, [buttons, matchActions]);

    return (
      <EventFlowBase
        title={`${SCORE_MATCH_TITLE} ${sessionIndex + 1}`}
        subtitle={description}
        Content={content}
      />
    );
  },
);
