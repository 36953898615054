import { memo } from '../../../util/memo';
import { TeammateProps } from './teammates/TeammatesTable';
import { AlertStandard } from '../../AlertStandard';

export type AlertSufficientMinTeamProps = {
  minTeamSize: number;
  teammates: TeammateProps[];
};

export const AlertSufficientMinTeamUnmemoized = ({
  minTeamSize,
  teammates,
}: AlertSufficientMinTeamProps) => {
  const teammatesNeeded = minTeamSize - teammates.length;
  return teammates.length < minTeamSize ? (
    <AlertStandard
      severity="warning"
      message={`In order to participate in this tournament, your team must have at minimum ${minTeamSize} players. Please invite at least ${teammatesNeeded} more friend${
        teammatesNeeded > 1 ? 's' : ''
      } onto your team to be eligible for scoring.`}
    />
  ) : (
    <AlertStandard
      severity="success"
      message={
        'Your team has sufficient members to participate in the tournament. You should now see your team on the heats and result leaderboards. Go for the gold!'
      }
    />
  );
};

export const AlertSufficientMinTeam = memo(AlertSufficientMinTeamUnmemoized);
