/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { InteractionTrackingData, useClickTracking } from './useClickTracking';
import { useImpressionTracking } from './useImpressionTracking';
import { extractBrowserAndOs } from '../../../functions/src/util/ads/header/extractBrowserAndOs';
import { StopwatchCallbackMap } from '../visibility/useVisibilityStopwatch';
import { useGroupIdMap, DEFAULT_DATA_VALUE } from '../routing/useGroupIdMap';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';

export type EngagementTrackingProps = Omit<
  InteractionTrackingData,
  'hostname' | 'browser' | 'os' | 'userId' | 'groupId' | 'data'
> & {
  target: HTMLElement | null;
  idealTarget: HTMLElement | null;
  callbacks?: StopwatchCallbackMap;
  idealCallbacks?: StopwatchCallbackMap;
  id: string;
  data?: Record<string, string | null>;
};

const DEFAULT_HOSTNAME = 'unknown' as const;
const DEFAULT_USERID = 'anonymous' as const;

export const useEngagementTracking = ({
  target,
  idealTarget,
  callbacks,
  idealCallbacks,
  data: customData = {},
  ...data
}: EngagementTrackingProps) => {
  const { uid } = useAuth();

  const { browser, os } = extractBrowserAndOs();
  const hostname = process.env.NEXT_PUBLIC_DEPLOYMENT_URL || DEFAULT_HOSTNAME;

  const groupIdMap = useGroupIdMap();

  const trackingData = useMemo(() => {
    const customDataTrimmed = Object.entries(customData).reduce(
      (acc, [key, value]) => {
        acc[String(key)] = value || DEFAULT_DATA_VALUE;
        return acc;
      },
      {},
    );

    return {
      hostname,
      browser,
      os,
      userId: uid || DEFAULT_USERID,
      ...groupIdMap,
      ...customDataTrimmed,
      ...data,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    browser,
    sortedHash(customData),
    sortedHash(data),
    groupIdMap,
    hostname,
    os,
    uid,
  ]);

  useClickTracking(trackingData, target);
  useImpressionTracking(trackingData, target, callbacks);

  useImpressionTracking(
    trackingData,
    idealTarget,
    idealCallbacks,
    'idealImpression',
  );
};
