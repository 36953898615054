import { isPreReady } from '../../../../functions/src/types/firestore/Game/Tournament/util';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { LoadingButton } from '../../buttons/LoadingButton';

export function LeaveTeamButton() {
  const { phase, foundTeam } = useTournamentRegistration();
  const { leaveTeam } = useRegistrationFunctions();
  const { id: teamId } = foundTeam!;
  return (
    <>
      {isPreReady(phase) && (
        <LoadingButton
          onClick={() => {
            return leaveTeam(teamId);
          }}
          fullWidth
          size="large"
          variant="contained"
          color="error"
        >
          Leave Team
        </LoadingButton>
      )}
    </>
  );
}
