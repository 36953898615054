import { memo } from '../../util/memo';
import type { Sponsor } from '../../../functions/src/types/firestore/Game/Competition';
import { SponsorsLayoutV3 } from './SponsorsLayoutV3';
import { SponsorV3 } from './SponsorV3';

export type SponsorsLayoutProps = {
  sponsors: Sponsor[];
};

export const SponsorsV3 = memo(function SponsorsV3Unmemoized({
  sponsors,
}: SponsorsLayoutProps) {
  return <SponsorsLayoutV3 sponsors={sponsors} SponsorComponent={SponsorV3} />;
});
