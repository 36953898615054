import SettingsIcon from '@mui/icons-material/Settings';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { MenuOption } from '../../components/MenuOption';
import { useMemo } from 'react';
import { useMediaPreferencesDialog } from './useMediaPreferencesDialog';

export type UseOptionsParams = {
  onActionComplete: () => void;
};

export const useOptions = ({ onActionComplete }: UseOptionsParams) => {
  const { open: openMediaPreferences } = useMediaPreferencesDialog();
  // const {open: openInviteFriend} = useInviteFriendDialog();

  const optionsList = useMemo(() => {
    return [
      {
        label: 'PREFERENCES',
        IconComponent: SettingsIcon,
        onClick: openMediaPreferences,
      },
      // {
      //   label: 'INVITE FRIENDS',
      //   IconComponent: PersonAddAlt1Icon,
      //   onClick: () => {
      //     console.log('open add user dialog');
      //   },
      // },
    ];
  }, [openMediaPreferences]);

  const options = useMemo(() => {
    return optionsList.map((option) => {
      return (
        <MenuOption
          key={option.label}
          onActionComplete={onActionComplete}
          sx={{
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            gap: 2,
          }}
          {...option}
        />
      );
    });
  }, [onActionComplete, optionsList]);

  return options;
};
