import { Timestamp } from 'firebase-admin/firestore';
import { Item } from '../Game/Collection/Item';
import { RentalFulfillment } from '../Game/Collection/Item/RentalFulfillment';
import { UserInfo } from 'firebase-admin/auth';
import { RemoveReadonly, Resolve } from '../../utility-types';
import { GroupInfo } from '../Guild';

export type UserProviderInfo = Omit<RemoveReadonly<UserInfo>, 'toJSON'>;

export const GENERIC_SIGN_IN_METHODS = [
  'google',
  // 'twitter',
  // 'facebook',
  'apple',
] as const;
export type GenericSignInMethod = typeof GENERIC_SIGN_IN_METHODS[number];
export const CUSTOM_SIGN_IN_METHODS = [
  'discord',
  'epic',
  'twitch',
  // 'fractal',
] as const;
export type CustomSignInMethod = typeof CUSTOM_SIGN_IN_METHODS[number];
export const NON_REDIRECT_SIGN_IN_METHODS = [
  'phone',
  'password',
  'wallet',
] as const;
export type NonRedirectSignInMethod =
  typeof NON_REDIRECT_SIGN_IN_METHODS[number];
export const ALL_SIGN_IN_METHODS = [
  ...CUSTOM_SIGN_IN_METHODS,
  ...GENERIC_SIGN_IN_METHODS,
  ...NON_REDIRECT_SIGN_IN_METHODS,
];
export type AllSignInMethod = typeof ALL_SIGN_IN_METHODS[number];

export const EMAIL_AUTH_METHOD = [
  'google',
  'apple',
  'discord',
  'password',
] as const;
export type EmailAuthMethod = typeof EMAIL_AUTH_METHOD[number];

export const O_AUTH_SIGN_IN_METHODS = [
  ...CUSTOM_SIGN_IN_METHODS,
  ...GENERIC_SIGN_IN_METHODS,
] as const;

export type OAuthSignInMethod = typeof O_AUTH_SIGN_IN_METHODS[number];

export type StatusEnum = 'offline' | 'online' | 'away' | 'do not disturb';

export type RecentGame<T = Timestamp> = {
  //needed for url although this does not appear
  title: string;
  imgUrl: string;
  favouriteImgUrl?: string;
  lastPlayed: T;
  hoursPlayed: number;
};

export type RecentCompetition<T = Timestamp> = {
  title: string;
  gameTitle: string;
  imgUrl: string;
  registrationTime: T;
};

export type UserAchievement<T = Timestamp> = {
  title: string;
  imgUrl: string;
  date: T;
  xpReward: number;
};

export namespace User {
  export type Privacy = 'public' | 'friends' | 'private';

  export type AuthMethodWallet = {
    method: 'wallet';
    address: string;
    chainId: string;
    provider: string;
  };

  // export type AuthMethod = AuthMethodEmail | AuthMethodOAuth | AuthMethodWallet;

  export type SelectedInventoryItems<T = Timestamp> = Record<string, Item<T>[]>;
}

export type LivestreamConfig = {
  livestreamId?: string;
  livestreamFor?: string;
  streamKey?: string[];
  playbackId?: string;
};

export type TournamentUserData = {
  tournamentId?: string;
  currentMatch?: string;
};

//only accessible if userProfileId===userPrivateId
export type User<T = Timestamp> = Resolve<
  GroupInfo<T> & {
    achievementTitles?: string[];
    fullName?: string;
    usernameLowercase: string;
    email?: string;
    backgroundImgUrl?: string;
    level: number;
    xp: number;
    xpNextLevel: number;
    likedPaths: string[];
    activity?: string;
    achievements?: UserAchievement<T>[];
    showcase?: {
      // TODO: 'we probably need the whole object for these cards'
      // --> Refactor ItemCard props so that we can refactor this not to use the entire Item type
      items: Item<T>[];
      achievements: UserAchievement<T>[];
      recentGames: RecentGame<T>[];
      favoriteGame: RecentGame<T>;
      recentCompetition: RecentCompetition<T>;
    };
    itemIdsOwned: string[];
    selectedInventoryItems?: User.SelectedInventoryItems<T>;
    addresses?: string[];
    hidden: {
      notificationsId: string[];
      auth: User.AuthMethodWallet[];
      customProviders?: UserProviderInfo[];
      customProviderIds?: string[];
      phone?: string; //@remarks: right now it stores like this '+15168384181
      chatToken?: string;
      roomId?: string;
      friendRequests?: {
        sent: string[];
        received: string[];
      };
      membershipRequests?: {
        sent: string[];
        received: string[];
      };
      secret: string;
    } & LivestreamConfig;
    dateCreated: Timestamp;
    friends?: string[];
    groups?: string[];
    fam?: string[];
    fulfillmentsRecentPathed?: (RentalFulfillment<T> & {
      itemPath: string;
    })[];
    isAnonymous: boolean;
    roomRequests?: string[];
    customClaims?: string;
    unseen?: string[];
    nextVoiceChannel?: string;
    currentTournament?: TournamentUserData;
  }
>;
