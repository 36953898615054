import { ReactNode, useState } from 'react';
import { memo } from '../../../util/memo';
import { usePrizePoolSelection } from '../../../contexts/organizer/PrizePoolSelectionContext';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { PrizeGrid } from '../../organizer/prize-pools/PrizeGrid';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useCurrency } from '../../../contexts/CurrencyContext';
import { isToken } from '../../organizer/prize-pools/UserPrizePools/PrizePoolPanel';
import { GradientCheckbox } from '../../gradients/GradientCheckbox';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '../../buttons/LoadingButton';
import { Token } from '../../../../functions/src/types/firestore/User/Payout';
import { hasSameContract } from '../../../../functions/src/util/payouts/findTokenInPayout';
import { InputCrypto } from '../../payment/v2/InputCrypto';
import { PayoutSummary } from '../../tournaments/PayoutSummary';
import { ThumbnailCardToken } from '../../cards/thumbnail-prize/ThumbnailCardToken';

type WithSelectionBoxProps = {
  children: ReactNode;
  isChecked: boolean;
  onCheckboxClick: () => void;
};

const WithSelectionBox = memo(function WithSelectionBoxUnmemoized({
  children,
  isChecked,
  onCheckboxClick,
}: WithSelectionBoxProps) {
  return (
    <Box position="relative">
      <GradientCheckbox
        onClick={onCheckboxClick}
        checked={isChecked}
        sx={{ position: 'absolute', top: 2, left: 2, zIndex: 1 }}
      />
      {children}
    </Box>
  );
});

export type SelectPrizesForPayoutProps = {
  confirmSelection: (selectedToken: Token) => Promise<void>;
  close: () => void;
};

export const SelectPrizesForPayout = memo(
  function SelectPrizesForPayoutUnmemoized({
    confirmSelection,
    close,
  }: SelectPrizesForPayoutProps) {
    const {
      prizePool,
      selectToken,
      prizesAggregatedWithAmounts,
      selectedToken,
      removeFromSelection,
    } = usePrizePoolSelection();
    const { formatPayout } = useCurrency();

    const payoutFormatted = formatPayout(prizePool?.prizes || []);
    const theme = useTheme();
    const [tokenAmountSelected, setTokenAmountSelected] = useState<
      bigint | undefined
    >(undefined);
    return (
      <Stack>
        <Stack direction="row" alignItems="center" gap={2} sx={theme.panels[1]}>
          <GradientTypography
            variant="h6"
            gradientColor="primary.vertical"
            textTransform="uppercase"
            fontWeight={700}
          >
            {/* TODO: can remove id once prize pools cleared up. */}
            Prize Pool {prizePool!.title || prizePool!.id}
          </GradientTypography>
          <PayoutSummary showIcons {...payoutFormatted} />
        </Stack>
        <Stack sx={theme.panels[0]} mb={8}>
          <PrizeGrid
            items={prizesAggregatedWithAmounts.filter((token) => {
              return token && token.amount !== '0';
            })}
            renderCard={(prize, token) => {
              const isChecked =
                !!selectedToken &&
                !!token &&
                hasSameContract(selectedToken, token);
              return (
                <WithSelectionBox
                  isChecked={isChecked}
                  onCheckboxClick={() => {
                    if (isChecked) {
                      return removeFromSelection();
                    }
                    return selectToken(token);
                  }}
                >
                  <Box
                    onClick={() => {
                      if (isChecked) {
                        return removeFromSelection();
                      }
                      return selectToken(token);
                    }}
                  >
                    <ThumbnailCardToken token={prize} />
                  </Box>
                </WithSelectionBox>
              );
            }}
          />
          {selectedToken &&
            isToken(selectedToken) &&
            ['native', 'ERC20', 'ERC1155'].includes(selectedToken.type) && (
              <InputCrypto
                onChange={(value) => {
                  value && value !== 'ERROR' && setTokenAmountSelected(value);
                }}
                weiMax={
                  selectedToken.type === 'ERC1155'
                    ? BigInt(selectedToken.amount) * BigInt(1e18)
                    : BigInt(selectedToken.amount)
                }
                weiMin={
                  selectedToken.type === 'ERC1155'
                    ? BigInt(1 * 1e18)
                    : BigInt(1)
                }
                helperText={
                  'Please enter an amount for this prize, in units of ether or number of tokens.'
                }
              />
            )}
        </Stack>
        <LoadingButton
          variant="contained"
          disabled={!selectedToken}
          onClick={async () => {
            if (!!selectedToken && !!tokenAmountSelected) {
              await confirmSelection({
                ...selectedToken,
                amount: (selectedToken.type === 'ERC1155'
                  ? tokenAmountSelected / BigInt(1e18)
                  : tokenAmountSelected
                ).toString(),
              });
            }
            close();
          }}
        >
          Confirm Selection
        </LoadingButton>
      </Stack>
    );
  },
);
