import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { init, SearchIndex } from 'emoji-mart';
import data from '@emoji-mart/data';
import { useChannelStateContext as useStreamChannelStateContext } from 'stream-chat-react';
import { useMessage } from './MessageContext';
import { useTheme } from '@mui/material/styles';

export const EMOJI_TOP_3_DEFAULT = ['heart', '+1', 'joy'] as const;

const QuickReactionsUnmemoized = () => {
  const [emojiTop3, setEmojiTop3] = useState<any[]>([]);
  const { channel } = useStreamChannelStateContext();
  const { message } = useMessage();
  const { own_reactions, id } = message;
  const theme = useTheme();

  useEffect(() => {
    init({ data });
  }, []);

  const top3 = useMemo(() => {
    const emojisFrequent: Record<string, number> = JSON.parse(
      localStorage.getItem('emoji-mart.frequently') || '[]',
    );
    return !!Object.keys(emojisFrequent).length
      ? Object.entries(emojisFrequent)
          .sort((a, b) => {
            const countA = a[1];
            const countB = b[1];
            return countB - countA;
          })
          .slice(0, 3)
          .map((emoji) => {
            return emoji[0];
          })
      : EMOJI_TOP_3_DEFAULT;
  }, []);

  useEffect(() => {
    const handler = async () => {
      const emojis: any[] = [];

      for (const type of top3) {
        const results = await SearchIndex.search(type);
        emojis.push({ type, emoji: results[0].skins[0].native });
      }

      setEmojiTop3(emojis);
    };
    handler();
  }, [top3]);

  const toggleReaction = useCallback(
    async (type: string) => {
      const hasReacted =
        !!own_reactions?.length &&
        !!own_reactions.some((reactionOwn) => {
          return reactionOwn.type === type;
        });

      if (hasReacted) {
        await channel.deleteReaction(id, type);
        return;
      }
      await channel.sendReaction(id, { type });
    },
    [channel, id, own_reactions],
  );

  const top3Emojis = useMemo(() => {
    return emojiTop3.map((emojiObj) => {
      const { emoji, type } = emojiObj;

      return (
        <Stack
          key={emoji}
          alignItems="center"
          justifyContent="center"
          sx={{
            height: '28px',
            width: '28px',
            ':hover': {
              background: theme.palette.action.hover,
              cursor: 'pointer',
            },
          }}
        >
          <Typography
            variant="h6"
            onClick={() => {
              return toggleReaction(type);
            }}
          >
            {emoji}
          </Typography>
        </Stack>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiTop3, toggleReaction]);

  return (
    <Stack direction="row" alignItems="center">
      {top3Emojis}
    </Stack>
  );
};

export const QuickReactions = memo(QuickReactionsUnmemoized);
