import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import {
  GradientBorder,
  GradientBorderProps,
} from '../../gradients/GradientBorder';

export type ThumbnailCardProps = {
  children: ReactNode;
} & GradientBorderProps;

export const ThumbnailCard = memo(function ThumbnailCardUnmemoized({
  children,
  ...gradientBorderProps
}: ThumbnailCardProps) {
  return <GradientBorder {...gradientBorderProps}>{children}</GradientBorder>;
});
