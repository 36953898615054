import { ReactNode, useMemo } from 'react';
import Box from '@mui/material/Box';
import { ClipboardShareUrl } from '../ClipboardShareUrl';
import { ShareButtonBase } from '../buttons/ShareButtonBase';
import { memo } from '../../util/memo';
import { useInAppParking } from '../../hooks/useInAppParking';
import { useMobile } from '../../hooks/useMobile';
import { CLIPBOARD_PREFIX_MOBILE } from '../ClipboardShare';
import { useRouter } from '../../hooks/routing/useRouter';
import { deploymentUrlSchemed } from '../../../functions/src/util/deploymentUrl';

export const SHARE_URL_INFO_TOOLTIP =
  'This shared link will allow the recipient to see the same page that you are viewing right now. If you have a public messaging chat open, this link will open the recipientʹs messaging sidebar to that chat as well.';

export type ShareUrlButtonProps = {
  customUrl?: string;
  children?: string | ReactNode;
};

export const ShareUrlButtonUnmemoized = ({
  customUrl,
  children,
}: ShareUrlButtonProps) => {
  const { intentUrl } = useInAppParking();
  const { asPath } = useRouter();
  const currentUrl = useMemo(() => {
    return `${deploymentUrlSchemed()}${asPath}`;
  }, [asPath]);
  const isMobile = useMobile();

  const urlCopied = useMemo(() => {
    if (intentUrl) {
      return intentUrl;
    }
    if (!customUrl) {
      return currentUrl;
    }
    return customUrl;
  }, [currentUrl, customUrl, intentUrl]);

  const description = useMemo(() => {
    if (!isMobile) {
      return;
    }
    return `${CLIPBOARD_PREFIX_MOBILE} ${SHARE_URL_INFO_TOOLTIP}`;
  }, [isMobile]);

  const guide = useMemo(() => {
    return isMobile ? undefined : SHARE_URL_INFO_TOOLTIP;
  }, [isMobile]);

  const shareUrlButton = useMemo(() => {
    return (
      <ClipboardShareUrl value={urlCopied} description={description}>
        <ShareButtonBase guide={guide}>{children}</ShareButtonBase>
      </ClipboardShareUrl>
    );
  }, [children, description, guide, urlCopied]);

  return <Box>{shareUrlButton}</Box>;
};

export const ShareUrlButton = memo(ShareUrlButtonUnmemoized);
