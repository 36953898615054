import { useMemo } from 'react';

/* Must use Intl.NumberFormat inside a React hook because window is only defined client-side */
export const useNumberFormatter = (
  options?: Intl.NumberFormatOptions | undefined,
) => {
  return useMemo(() => {
    return new Intl.NumberFormat(
      typeof window !== 'undefined' ? navigator?.language : undefined,
      options,
    );
  }, [options]);
};
