import { ChangeEvent, useCallback } from 'react';
import { MessageToSend, useChannelStateContext } from 'stream-chat-react';
import stringify from 'json-stringify-safe';

export const STORAGE_KEY = '@chat/drafts';

const getDrafts = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
};

const removeDraft = (key: string) => {
  const drafts = getDrafts();

  if (drafts[String(key)]) {
    delete drafts[String(key)];
    localStorage.setItem(STORAGE_KEY, stringify(drafts));
  }
};

const updateDraft = (key: string, value: string) => {
  const drafts = getDrafts();

  if (!value) {
    delete drafts[String(key)];
  } else {
    drafts[String(key)] = value;
  }

  localStorage.setItem(STORAGE_KEY, stringify(drafts));
};

export const useMessageDraft = () => {
  const { channel, thread } = useChannelStateContext();

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      updateDraft(channel.cid, e.target.value);
    },
    [channel.cid],
  );

  const onThreadInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!thread) return;
      updateDraft(`${channel.cid}:${thread.id}`, e.target.value);
    },
    [channel.cid, thread],
  );

  const getMainInputDraft = useCallback(() => {
    const drafts = getDrafts();
    return drafts[channel.cid] || '';
  }, [channel.cid]);

  const getThreadInputDraft = useCallback(() => {
    if (!thread) return;
    const drafts = getDrafts();
    return drafts[`${channel.cid}:${thread.id}`] || '';
  }, [channel.cid, thread]);

  const deleteDraft = useCallback(
    (message: MessageToSend, channelCid: string) => {
      const key = message.parent
        ? `${channelCid}:${message.parent.id}`
        : channelCid;
      removeDraft(key);
    },
    [],
  );

  return {
    getMainInputDraft,
    getThreadInputDraft,
    onInputChange,
    onThreadInputChange,
    deleteDraft,
  };
};
