import { FC, useContext } from 'react';
import { memo } from '../../../util/memo';
import Collapse from '@mui/material/Collapse';
import { GradientButton } from '../../gradients/GradientButton';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { useHover } from '../../../contexts/HoverContext';
import { useMobile } from '../../../hooks/useMobile';
import { useTournamentRegistrationStatus } from 'src/hooks/tournaments/useTournamentRegistrationStatus';
import { LinkEvent } from '../../calendar/LinkEvent';

const BUTTON_CONFIG = {
  'not-registered': { color: 'secondary', label: 'Register' },
  'checked-in': { color: 'primary', label: 'Check in' },
} as const;

const UNPUBLISHED_CONFIG = { color: 'warning', label: 'Edit' } as const;

export type TournamentCardRedirectProps = { target?: string };

const TournamentCardRedirectUnmemoized: FC<TournamentCardRedirectProps> = ({
  target,
}) => {
  const tournament = useContext(TournamentContext);
  const { phase, guestlistAggregated, waitlistAggregated, skipCheckIn } =
    tournament;

  const { isHovered } = useHover();
  const isMobile = useMobile();

  const { determineStatus } = useTournamentRegistrationStatus();
  const status = determineStatus(guestlistAggregated, waitlistAggregated);

  const configDefault =
    status === 'registered'
      ? { color: 'primary', label: 'View (Registered)' }
      : { color: 'primary', label: 'View' };

  const { color, label } = (() => {
    if (phase === 'unpublished') {
      return UNPUBLISHED_CONFIG;
    }
    if (phase === 'finished') {
      return configDefault;
    }
    if ((phase === 'ready' || phase === 'live') && !skipCheckIn) {
      return configDefault;
    }
    return BUTTON_CONFIG[String(status)] || configDefault;
  })();

  return (
    <Collapse in={isMobile || isHovered}>
      <LinkEvent hit={tournament} target={target}>
        <GradientButton
          color={color}
          sx={{
            mt: 2,
            width: '100%',
          }}
        >
          {label}
        </GradientButton>
      </LinkEvent>
    </Collapse>
  );
};

export const TournamentCardRedirect = memo(TournamentCardRedirectUnmemoized);
