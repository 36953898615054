// ColumnWrapper.tsx
import { ReactNode, FC } from 'react';
import { memo } from '../../../../util/memo';
import { MatchBracket } from '../../match/MatchBracket';
import { MatchAggregated } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { WayfinderWrapper } from '../../match/WayfinderWrapper';

export type ColumnProps = {
  children: ReactNode;
};

export type ColumnWrapperProps = {
  matches: MatchAggregated<Date>[];
  ColumnComponent: FC<ColumnProps>;
  hasFinal?: boolean;
};

const ColumnWrapperUnmemoized: FC<ColumnWrapperProps> = ({
  matches,
  ColumnComponent,
  hasFinal = false,
}) => {
  return (
    <ColumnComponent key={matches[0].id}>
      {matches.map((match) => {
        const isFinal = hasFinal && matches.length === 1;
        return (
          <WayfinderWrapper key={match.id} match={match} isFinal={isFinal}>
            <MatchBracket match={match} isLarge={isFinal} />
          </WayfinderWrapper>
        );
      })}
    </ColumnComponent>
  );
};

export const ColumnWrapper = memo(ColumnWrapperUnmemoized);
