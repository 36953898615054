/* eslint-disable @typescript-eslint/no-explicit-any */
import { sleep } from '../sleep';
import type { AxiosError } from 'axios';

export type ExponentialBackoffOptions<TError> = {
  doRetry: (error: TError) => boolean;
  maxRetries: number;
  initialWaitTimeMs: number;
};

const CONFIG_429: ExponentialBackoffOptions<AxiosError> = {
  doRetry: (error) => {
    const { response, code, message } = error;
    const isTimeout = code === 'ECONNABORTED' || message.includes('timeout');
    const isRateLimit = !response || response.status === 429;
    return isRateLimit || isTimeout;
  },
  maxRetries: 7,
  initialWaitTimeMs: 1000,
};

const CONFIG_GENERIC: ExponentialBackoffOptions<unknown> = {
  doRetry: () => {
    return true;
  },
  maxRetries: 5,
  initialWaitTimeMs: 1000,
};

export class ExponentialBackoff<TError> {
  public static for429 = new ExponentialBackoff(
    CONFIG_429,
  ).withExponentialBackoff();

  public static generic = new ExponentialBackoff(
    CONFIG_GENERIC,
  ).withExponentialBackoff();

  constructor(private readonly options: ExponentialBackoffOptions<TError>) {}
  public withExponentialBackoff() {
    const { doRetry, maxRetries, initialWaitTimeMs } = this.options;

    return <TFunc extends (...args: any[]) => Promise<any>>(func: TFunc) => {
      const funcWrapped = async function (
        this: any,
        ...args: Parameters<TFunc>
      ): Promise<Awaited<ReturnType<TFunc>>> {
        // Having to write Promise<Awaited is a fluke of the type system.
        let retryCount = 0;
        while (true) {
          try {
            return await func.apply(this, args);
          } catch (error) {
            if (doRetry(error as TError) && retryCount < maxRetries) {
              console.warn(error);
              const waitTimeMs =
                initialWaitTimeMs * Math.pow(2, retryCount + 1);
              await sleep(waitTimeMs);
              retryCount++;
            } else {
              console.error(error);
              throw error;
            }
          }
        }
      };
      return funcWrapped;
    };
  }
}
