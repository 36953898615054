/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import localForage from 'localforage';

export type StorageContextType = {
  /**
   * @remarks
   * undefined indicates that the value may be
   * in IndexDB but IndexDB has not finished initializing.
   */
  getItem<TValue>(key: string): TValue | null | undefined;
  /**
   * @remarks
   * undefined indicates that the value may be
   * in IndexDB but IndexDB has not finished initializing.
   */
  findItem<TValue>(pattern: RegExp): TValue | null | undefined;
  setItem<TValue>(key: string, value: TValue): void;
  removeItem(key: string): void;
  clear(): void;
  /**
   * @remarks
   * This is a method to prevent unnecessary re-renders.
   */
  length(): number;
  key(keyIndex: number): string | null;
  /**
   * @remarks
   * This is a method to prevent unnecessary re-renders.
   */
  keys(): string[];
};

const LocalStorageContext = createContext<StorageContextType | null>(null);

const LocalStorageUnmemoized: FC<{ children: ReactNode }> = ({ children }) => {
  const memory = useRef<Record<string, any>>({});
  const isDiskAvailable = useRef<boolean>(false);
  const isIndexDBReady = useRef<boolean>(false);

  useEffect(() => {
    if (!window) {
      isIndexDBReady.current = true;
      return;
    }
    const initializeLocalForage = async () => {
      try {
        const setPromise = await Promise.all(
          Object.entries(memory.current).map(([memoryKey, memoryValue]) => {
            return localForage.setItem(memoryKey, memoryValue);
          }),
        );
        const getPromise = async () => {
          await localForage.iterate((diskValue, diskKey) => {
            if (memory.current[String(diskKey)] === undefined) {
              memory.current[String(diskKey)] = diskValue;
            }
          });
        };

        await Promise.all([setPromise, getPromise]);
        isDiskAvailable.current = true;
      } catch (error) {
        console.error('Error initialize localForage', error);
        isDiskAvailable.current = false;
      } finally {
        isIndexDBReady.current = true;
      }
    };
    initializeLocalForage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItem = useCallback(
    <TValue,>(key: string): TValue | null | undefined => {
      const value = memory.current[String(key)] as TValue | undefined;

      if (value === undefined && isIndexDBReady.current) {
        return null;
      }
      return value;
    },
    [],
  );

  const findItem = useCallback(
    <TValue,>(pattern: RegExp): TValue | null | undefined => {
      const foundKey = Object.keys(memory.current).find((key) => {
        return pattern.test(key);
      });
      const value = foundKey
        ? (memory.current[String(foundKey)] as TValue | undefined)
        : undefined;

      if (value === undefined && isIndexDBReady.current) {
        return null;
      }
      return value;
    },
    [],
  );

  const setItem = useCallback(<TValue,>(key: string, value: TValue) => {
    memory.current[String(key)] = value;
    if (isDiskAvailable.current) {
      localForage.setItem(key, value).catch((error) => {
        console.error('Error writing to localForage', error);
      });
    }
  }, []);

  const removeItem = useCallback((key: string) => {
    delete memory.current[String(key)];
    if (isDiskAvailable.current) {
      localForage.removeItem(key).catch((error) => {
        console.error('Error removing from localForage', error);
      });
    }
  }, []);

  const clear = useCallback(() => {
    memory.current = {};
    if (isDiskAvailable.current) {
      localForage.clear().catch((error) => {
        console.error('Error clearing localForage', error);
      });
    }
  }, []);

  const key = useCallback((index: number) => {
    return Object.keys(memory.current)[Number(index)] || null;
  }, []);

  const length = useCallback(() => {
    return Object.keys(memory.current).length;
  }, []);

  const keys = useCallback(() => {
    return Object.keys(memory.current);
  }, []);

  const valueMemoed = useMemo(() => {
    return {
      getItem,
      findItem,
      setItem,
      removeItem,
      clear,
      key,
      length,
      keys,
    };
  }, [getItem, findItem, setItem, removeItem, clear, key, length, keys]);

  return (
    <LocalStorageContext.Provider value={valueMemoed}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const LocalStorageProvider = memo(LocalStorageUnmemoized);

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useStorage must be used within a StorageProvider');
  }
  return context;
};
