import { FC, useMemo } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';

export type ContainedIconButtonProps = Omit<IconButtonProps, 'color'> & {
  color?: string;
  iconColor?: string;
  tooltipTitle?: string;
};

export const ContainedIconButtonUnmemozied: FC<ContainedIconButtonProps> = ({
  color = 'primary',
  iconColor = '#08264a',
  tooltipTitle,
  children,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const button = useMemo(() => {
    return (
      <IconButton
        {...props}
        className="visible"
        sx={{
          background: theme.palette[String(color)].horizontal,
          color: iconColor,
          ...sx,
        }}
      >
        {children}
      </IconButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, color, iconColor, sx, children]);

  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{button}</Tooltip>;
  }

  return button;
};

export const ContainedIconButton = memo(ContainedIconButtonUnmemozied);
