import React, { ReactNode } from 'react';
import { memo } from '../../../util/memo';
//import { SwitchFilterChip } from '../filters/SwitchFilterChip';
import Stack from '@mui/material/Stack';
import { SearchAlgolia } from '../search/SearchAlgolia';

export type TeamsCarouselHeaderProps = {
  Title: ReactNode;
  children?: ReactNode;
};

const TeamsCarouselHeaderUnmemoized: React.FC<TeamsCarouselHeaderProps> = ({
  Title,
  children,
}) => {
  return (
    <Stack
      width="100%"
      direction={{ xs: 'column', lg: 'row' }}
      spacing={2}
      sx={{ mb: 4 }}
    >
      {Title}
      {children}
      <SearchAlgolia collapsable={false} />
    </Stack>
  );
};

export const TeamsCarouselHeader = memo(TeamsCarouselHeaderUnmemoized);
