import { useCallback } from 'react';
import { LinkToPrizePool } from '../../components/organizer/prize-pools/UserPrizePools/LinkToPrizePool';
import { useAuth } from '../../contexts/AuthContext';
import { useLinkDialog } from './useLinkDialog';
import type { EditableStepsContextType } from '../../contexts/organizer/EditableStepsContext';
import type { DocumentData } from 'firebase/firestore';
import type { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export type UseLinkToPrizePoolParams<TEvent extends DocumentData = Tournament> =
  {
    event: TEvent;
    isChangingLink: boolean;
    updateField?: EditableStepsContextType<TEvent>['updateField'];
  };

export const useLinkToPrizePool = ({
  event,
  isChangingLink,
  updateField,
}: UseLinkToPrizePoolParams<Tournament<Date>>) => {
  const { linkToPrizePool } = useLinkDialog({
    isChangingLink,
    linkComponents: {
      prizePool: <LinkToPrizePool />,
    },
    updateField,
  });
  const { uid } = useAuth();
  return useCallback(async () => {
    if (!uid) {
      return;
    }
    const { getUnlinkedPrizePoolsForUser } = await import(
      '../../components/data-entry-ui/payouts/utils/getUnlinkedPrizePoolsForUser'
    );
    const prizePools = await getUnlinkedPrizePoolsForUser(uid);
    linkToPrizePool({
      event,
      isChangingLink: false,
      prizePools,
      linkValidator: () => {
        return {
          isValid: true,
        };
      },
    });
  }, [event, linkToPrizePool, uid]);
};
