import { useMemo } from 'react';
import { useCurrency } from 'src/contexts/CurrencyContext';

export type Prize = {
  amount: number;
  currency: string;
};

export const usePrizeFormat = (prize?: Prize): string => {
  const { format } = useCurrency();

  return useMemo(() => {
    return prize
      ? format(prize.amount, prize.currency, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : '';
  }, [prize, format]);
};
