import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../util/memo';
import { PlaybacksDownloadable } from '../../functions/src/types/firestore/Game/Tournament';

export type LivestreamContextProps = {
  playbackId: string;
  downloadUrl?: string;
  isLive: boolean;
};

export const LivestreamContext = createContext<
  LivestreamContextProps | undefined
>(undefined);

export type LivestreamProviderProps = {
  children: ReactNode;
  playbackId: string;
  playbacksDownloadable?: PlaybacksDownloadable;
};

export const useLivestream = () => {
  const context = useContext(LivestreamContext);
  if (!context) {
    throw new Error('useLivestream must be used within a LivestreamProvider');
  }
  return context;
};

const LivestreamProviderUnmemoized: FC<LivestreamProviderProps> = ({
  children,
  playbackId,
  playbacksDownloadable,
}) => {
  const downloadUrl = playbacksDownloadable?.[playbackId as string];
  const isLive = downloadUrl === 'still-live';

  const valueMemoized = useMemo(() => {
    return {
      playbackId,
      downloadUrl,
      isLive,
    };
  }, [playbackId, downloadUrl, isLive]);

  return (
    <LivestreamContext.Provider value={valueMemoized}>
      {children}
    </LivestreamContext.Provider>
  );
};

export const LivestreamProvider = memo(LivestreamProviderUnmemoized);
