import { useEffect } from 'react';

export type UseAutoplayVideoElementParams = {
  videoElement?: HTMLVideoElement | null;
};

export const useAutoplayVideoElement = ({
  videoElement,
}: UseAutoplayVideoElementParams) => {
  useEffect(() => {
    if (!videoElement) {
      return;
    }

    videoElement.muted = true;
    videoElement.autoplay = true;

    videoElement.play();

    return () => {
      videoElement.pause();
    };
  }, [videoElement]);
};
