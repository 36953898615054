import { memo } from '../../util/memo';
import { useTrackToggle } from '@livekit/components-react';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import { GradientIconButton } from '../gradients/GradientIconButton';
import { useCallback } from 'react';
import { Track, LocalAudioTrack } from 'livekit-client';

export type ToggleMicButtonProps = {
  audioTrack: LocalAudioTrack;
};

const ToggleMicButtonUnmemoized = ({ audioTrack }: ToggleMicButtonProps) => {
  const { toggle, enabled, pending } = useTrackToggle({
    ...audioTrack,
    source: Track.Source.Microphone,
  });

  const toggleAudio = useCallback(() => {
    return toggle();
  }, [toggle]);

  return (
    <GradientIconButton
      IconComponent={enabled && !pending ? MicIcon : MicOffIcon}
      onClick={toggleAudio}
    />
  );
};

export const ToggleMicButton = memo(ToggleMicButtonUnmemoized);
