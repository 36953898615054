import { useCallback, useMemo } from 'react';
import { BanStatus } from '../../../functions/src/types/firestore/User/BanStatus';
import { toBanId } from '../../util/ban/toBanId';
import { useDocSnapshot } from '../firestore/useDocSnapshot';

export const useBanStatus = (userId?: string) => {
  const banStatus = useDocSnapshot<BanStatus>(
    `User/${userId}/BanStatus/BanStatus`,
  );

  const activeBans = useMemo(() => {
    return banStatus?.active || [];
  }, [banStatus]);

  const isBannedFromApp = useMemo(() => {
    return activeBans.some((ban) => {
      return !ban.channelId;
    });
  }, [activeBans]);

  const isBannedFromChannel = useCallback(
    (channelId?: string) => {
      if (!userId) {
        return;
      }
      const banId = toBanId({ userId, channelId });
      return activeBans.some((ban) => {
        return ban.id === banId;
      });
    },
    [activeBans, userId],
  );

  return useMemo(() => {
    return !banStatus
      ? 'loading'
      : { activeBans, isBannedFromChannel, isBannedFromApp };
  }, [activeBans, banStatus, isBannedFromApp, isBannedFromChannel]);
};
