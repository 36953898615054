import { Stack } from '@mui/material';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { SummaryKeySelect } from './SummaryKeySelect';
import { CaptainOnlySwitch } from './CaptainOnlySwitch';
import { SummaryOnlySwitch } from './SummaryOnlySwitch';
import { SummaryKeyInput } from './SummaryKeyInput';
import { memo } from '../../../../util/memo';

const DetailsDisplaySettingsUnmemoized = () => {
  return (
    <>
      <GradientTypography
        variant="h5"
        gradientColor="primary.vertical"
        textTransform={'uppercase'}
        sx={{ pb: 2 }}
      >
        Change details display
      </GradientTypography>
      <CaptainOnlySwitch />
      <SummaryOnlySwitch />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{ width: '100%' }}
      >
        <SummaryKeySelect />
        <SummaryKeyInput />
      </Stack>
    </>
  );
};
export const DetailsDisplaySettings = memo(DetailsDisplaySettingsUnmemoized);
