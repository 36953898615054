export const MS_PER_HOUR = 3600000 as const;
export const MS_PER_DAY = 24 * MS_PER_HOUR;

export function parseParamsUtcOffset(params?: { utc?: string }) {
  const utc = params?.utc;
  return utc && typeof utc === 'string'
    ? parseInt(utc.replace('UTC', ''), 10)
    : 0;
}

export function adjustAsInUTC(date: Date, utcOffsetInHours: number): Date {
  date.setTime(date.getTime() - utcOffsetInHours * MS_PER_HOUR);
  return date;
}

export function toLocalMidnightDate(dateStr: string): Date {
  const [year, month, day] = dateStr.split('-').map((str) => {
    return parseInt(str, 10);
  });

  // Months in JavaScript Date are 0-based (0 = January, 1 = February, ...)
  return new Date(year!, month! - 1, day, 0, 0, 0);
}
