import { stableHash } from '../hash/stableHash';

export const areEqual = <TElement>(
  prevHits: TElement[],
  currentHits: TElement[],
) => {
  if (currentHits.length !== prevHits.length) {
    return false;
  }

  return currentHits.every((hit, index) => {
    return stableHash(hit) === stableHash(prevHits[Number(index)]);
  });
};
