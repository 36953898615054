import { useContext, useMemo } from 'react';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { findIntermissionAround } from '../../util/findIntermissionAround';

export const useCountdownDisplay = () => {
  const {
    phase,
    skipCheckIn,
    date,
    checkInTime,
    readyTime,
    endDate,
    intermissions,
  } = useContext(TournamentContext);
  const currentIntermission = intermissions
    ? findIntermissionAround(intermissions)
    : null;

  return useMemo(() => {
    if (currentIntermission) {
      const { end } = currentIntermission;
      return {
        title: 'INTERMISSION UNTIL:',
        date: end,
      };
    }
    switch (phase) {
      case 'unpublished':
      case 'review':
      case 'registration':
        return skipCheckIn
          ? {
              title: 'Starts In:',
              date: date,
            }
          : {
              title: 'Check In Starts:',
              date: checkInTime,
            };
      case 'checkIn':
        return {
          title: 'Check In By:',
          date: readyTime,
        };
      case 'ready':
        return {
          title: 'Starts In:',
          date,
        };
      case 'live':
        return {
          title: 'Live now! Ends In:',
          date: endDate,
        };
      case 'finished':
        return {
          title: 'Finished!',
          date: new Date(Date.now()),
        };
      default:
        return {
          title: 'Time Remaining:',
          date: new Date(Date.now()),
        };
    }
  }, [
    currentIntermission,
    phase,
    skipCheckIn,
    date,
    checkInTime,
    readyTime,
    endDate,
  ]);
};
