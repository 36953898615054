import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { Reaction } from './Reaction';
import { useChannelStateContext as useStreamChannelStateContext } from 'stream-chat-react';
import { useAuth } from '../../../contexts/AuthContext';

const ReactionsListUnmemoized = () => {
  const { message, isMine } = useMessage();
  const { channel } = useStreamChannelStateContext();
  const { userData } = useAuth();
  const { latest_reactions, own_reactions, id } = message;

  const reactionsGrouped = useMemo(() => {
    if (!latest_reactions) {
      return {};
    }
    return latest_reactions.reduce((prev, curr) => {
      const {
        type,
        user: { name },
      } = curr;
      if (!prev[String(type)]) {
        prev[String(type)] = {
          count: 1,
          reactedBy: [userData?.username === name ? 'You' : name],
          isOwnReaction:
            !isMine &&
            !!own_reactions?.length &&
            !!own_reactions.some((reactionOwn) => {
              return reactionOwn.type === type;
            }),
        };
      } else {
        prev[String(type)].count++;
        const reactedByUnique = [
          ...new Set([...prev[String(type)].reactedBy, name]),
        ];
        prev[String(type)].reactedBy = reactedByUnique;
      }
      return prev;
    }, {} as Record<string, { count: number; isOwnReaction: boolean; reactedBy: string[] }>);
  }, [isMine, latest_reactions, own_reactions, userData?.username]);

  const reactions = useMemo(() => {
    return Object.keys(reactionsGrouped).map((type) => {
      const { count, isOwnReaction, reactedBy } =
        reactionsGrouped[String(type)];

      return (
        <Reaction
          key={type}
          type={type}
          count={count}
          reactedBy={reactedBy}
          isOwnReaction={isOwnReaction}
          onClick={async () => {
            if (isMine) {
              return;
            }
            if (isOwnReaction) {
              await channel.deleteReaction(id, type);
              return;
            }
            await channel.sendReaction(id, { type });
          }}
        />
      );
    });
  }, [channel, id, isMine, reactionsGrouped]);

  return (
    <Stack
      direction="row"
      justifyContent={isMine ? 'flex-end' : 'flex-start'}
      sx={{ pt: 1, flexWrap: 'wrap' }}
    >
      {reactions}
    </Stack>
  );
};

export const ReactionsList = memo(ReactionsListUnmemoized);
