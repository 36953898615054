import { useEffect, useState } from 'react';
import { useWindowFocus } from './useWindowFocus';
import type IdleJs from 'idle-js';

/**
 * @remarks
 * 5 minutes
 */
export const DEFAULT_IDLE_TIME = 10_000 as const; //300_000 as const;

export const useEngagement = (idleTime = DEFAULT_IDLE_TIME) => {
  const [isEngaged, setIsEngaged] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (!window) {
      return;
    }

    let tracker: IdleJs | undefined = undefined;
    const handler = async () => {
      const { default: IdleJsTracker } = await import('idle-js');

      tracker = new IdleJsTracker({
        idle: idleTime,
        events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
        onIdle: () => {
          if (isVideoPlaying) {
            return;
          }
          setIsEngaged(false);
        },
        onActive: () => {
          return setIsEngaged(true);
        },
        onHide: () => {
          setIsEngaged(false);
        },
        onShow: () => {
          return setIsEngaged(true);
        },
      });

      tracker.start();
    };
    handler();

    const respondVideoPlay = () => {
      setIsVideoPlaying(true);
      setIsEngaged(true);
    };
    const respondVideoPause = () => {
      setIsVideoPlaying(false);
      tracker?.reset();
    };

    document.addEventListener('play', respondVideoPlay, true);
    document.addEventListener('pause', respondVideoPause, true);

    return () => {
      tracker?.stop();
      document.removeEventListener('play', respondVideoPlay, true);
      document.removeEventListener('pause', respondVideoPause, true);
    };
  }, [idleTime, isVideoPlaying]);

  const isWindowFocused = useWindowFocus();
  return isEngaged && isWindowFocused;
};
