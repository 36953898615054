import { findScrollableAncestor } from './findScrollableAncestor';

export function findAllScrollableAncestors(element: HTMLElement | null) {
  const scrollableAncestors: (HTMLElement | null)[] = [];
  let currentElement = element;

  while (currentElement) {
    const scrollableAncestor = findScrollableAncestor(currentElement);
    if (!scrollableAncestor) {
      break;
    }
    scrollableAncestors.push(scrollableAncestor);
    currentElement = scrollableAncestor.parentElement;
  }

  // null represents the viewport in IntersectionObserver
  scrollableAncestors.push(null);

  return scrollableAncestors;
}
