export const toGroupId = (
  channelGroupId: string,
  groupName?: string,
  matchId?: string,
) => {
  const groupId =
    !!matchId && !!groupName
      ? `${groupName}:${channelGroupId}`
      : channelGroupId;
  return groupId;
};
