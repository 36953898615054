import { useContext, useMemo } from 'react';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { useAuth } from '../../contexts/AuthContext';
import { isAdmin } from '../../../functions/src/types/firestore/User/util';

export const useTournamentPermissions = () => {
  const { uid, userData } = useAuth();
  const { roles, userIdsContributors } = useContext(TournamentContext);
  const { contributor, moderator } = roles;

  const isModerator = useMemo(() => {
    return !!uid && moderator.includes(uid);
  }, [uid, moderator]);

  const isContributor = useMemo(() => {
    return (
      !!uid && (contributor.includes(uid) || userIdsContributors.includes(uid))
    );
  }, [contributor, uid, userIdsContributors]);

  const isTournamentAdmin = useMemo(() => {
    return isContributor || isModerator || isAdmin(userData?.email);
  }, [isContributor, isModerator, userData?.email]);

  return { isContributor, isModerator, isTournamentAdmin };
};
