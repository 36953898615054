import { memo } from '../../util/memo';
import { useEffect, useMemo } from 'react';
import { TabsRouted, TabsRoutedProps, TabRoutedFull } from './TabsRouted';
import { PanesRouted, Pane } from './PanesRouted';
import { SxProps } from '@mui/material/styles';

export type TabPane<TValue extends string> = TabRoutedFull<TValue> &
  Pane<TValue>;

export type TabPanesProps<TValue extends string> = {
  tabPanes: TabPane<TValue>[];
  sxTabs?: SxProps;
} & Pick<TabsRoutedProps<TValue>, 'variant' | 'state'>;

function TabPanesUnmemoized<TValue extends string>({
  tabPanes,
  variant,
  state,
  sxTabs = {},
}: TabPanesProps<TValue>) {
  const [stateValue, setStateValue] = state;
  const isOnDisabled = useMemo(() => {
    return tabPanes.some((tabPane) => {
      return tabPane.customization?.disabled && stateValue === tabPane.value;
    });
  }, [stateValue, tabPanes]);

  useEffect(() => {
    if (isOnDisabled) {
      setStateValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDisabled]);

  const tabs = useMemo(() => {
    return tabPanes.map(({ component, ...rest }) => {
      return rest;
    });
  }, [tabPanes]);
  const panes = useMemo(() => {
    return tabPanes.map(({ customization, ...rest }) => {
      return rest;
    });
  }, [tabPanes]);

  const panesRouted = useMemo(() => {
    if (isOnDisabled) {
      return;
    }
    return <PanesRouted panes={panes} state={state} />;
  }, [isOnDisabled, panes, state]);

  return (
    <>
      <TabsRouted tabs={tabs} variant={variant} state={state} sx={sxTabs} />
      {panesRouted}
    </>
  );
}

export const TabPanes = memo(TabPanesUnmemoized);
