import Box from '@mui/material/Box';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useTeamLockStatus } from '../../../hooks/registration/useTeamLockStatus';
import { useAuth } from '../../../contexts/AuthContext';
import { SwitchPanel } from '../../SwitchPanel';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useCallback, useMemo } from 'react';
import { LockTeamTitle } from './LockTeamTitle';

export function LockTeamCheck() {
  const { uid } = useAuth();
  const {
    preventMerging,
    gameId,
    foundTeam,
    id: tournamentId,
  } = useTournamentRegistration();
  const { id: teamId, isLocked = false } = foundTeam!;
  const { isTeamLocked, setIsTeamLocked } = useTeamLockStatus({
    gameId,
    tournamentId,
    teamId,
    userId: uid || undefined,
    isLockedDefault: isLocked,
  });

  const toggleLock = useCallback(() => {
    setIsTeamLocked((prev) => {
      return !prev;
    });
  }, [setIsTeamLocked]);

  const lockOpenIcon = useMemo(() => {
    return <LockOpenIcon sx={{ width: '20px', height: '20px' }} />;
  }, []);

  const lockIcon = useMemo(() => {
    return <LockIcon sx={{ width: '20px', height: '20px' }} />;
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SwitchPanel
        titleLeft={<LockTeamTitle />}
        disabled={preventMerging}
        defaultChecked={isTeamLocked}
        onChange={toggleLock}
        icon={lockOpenIcon}
        checkedIcon={lockIcon}
      />
    </Box>
  );
}
