import Stack from '@mui/material/Stack';
import { useReferee } from 'src/contexts/RefereeContext';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecordRounded';
import { useTheme } from '@mui/material/styles';

export function TableStatus() {
  const { isUpdating } = useReferee();
  const theme = useTheme();
  return (
    <Stack p={1} direction="row" alignItems="center">
      <FiberManualRecordIcon
        fontSize="small"
        sx={{
          mr: 1,
          color: isUpdating
            ? theme.palette.warning.main
            : theme.palette.success.main,
        }}
      />
      Status: {isUpdating ? 'Updating' : 'Synced'}
    </Stack>
  );
}
