import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { EventTime } from './EventTime';
import { Sponsors } from './Sponsors';
import { Competition } from 'functions/src/types/firestore/Game/Competition';
import { Optional } from 'utility-types';
import { useMobile } from 'src/hooks/useMobile';
import { useReferee } from 'src/contexts/RefereeContext';
import { BRACKET_AD } from '../../../functions/src/util/ads/adIds';
import { Ad } from '../ads/Ad';

export type LeaderboardContainerProps = Optional<
  Pick<Competition<Date>, 'endDate' | 'sponsors'>,
  'endDate'
> & {
  children?: ReactNode;
  RightChildren?: ReactNode;
};

export const LeaderboardContainer = memo(
  function LeaderboardContainerUnmemoized({
    endDate,
    sponsors,
    children,
    RightChildren,
  }: LeaderboardContainerProps) {
    const { isReferee } = useReferee();
    const isMobile = useMobile();

    return (
      <Stack mb="48px" sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Ad id={BRACKET_AD} width="100%" height={100} />
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          gap="32px"
          justifyContent="center"
          alignItems={isMobile ? 'center' : undefined}
          sx={{ mt: 4 }}
        >
          {!isReferee && (
            <Stack
              gap="16px 0"
              sx={{ width: isMobile ? '100%' : '500px' }}
              alignItems="center"
            >
              {endDate && (
                <EventTime date={endDate} title="Live Now! Ends In:" />
              )}
              {children}
              {!!sponsors && (
                <Sponsors
                  sponsors={sponsors}
                  direction="row"
                  sx={{
                    width: 'min(586px, 100%)',
                    minHeight: '278px',
                    height: '100%',
                    position: 'relative',
                    mb: 9,
                  }}
                />
              )}
            </Stack>
          )}
          <Stack
            gap="16px 0"
            sx={{ width: isMobile || isReferee ? '100%' : '50%' }}
          >
            {RightChildren}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);
