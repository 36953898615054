import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { MessageTimestamp as StreamMessageTimestamp } from 'stream-chat-react';
import { useMessage } from './MessageContext';
import { MessageStatus } from './MessageStatus';
import { MessageRepliesPreview } from './MessageRepliesPreview';
import { GradientTypography } from '../../gradients/GradientTypography';
import { truncateIfTooLong } from '../../../util/truncate';
import { ReactionsList } from './ReactionsList';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

const MessageFooterUnmemoized = () => {
  const {
    message: { user: messageUser },
    isMine,
    replyCount,
    threadList: isThreadMessage,
    isUserInfoShown,
    isStatusShown,
    isBottomOrSingle,
    isDm,
  } = useMessage();
  const theme = useTheme();

  const username = useMemo(() => {
    return (
      isUserInfoShown &&
      messageUser && (
        <GradientTypography
          variant="caption"
          gradientColor="primary.vertical"
          sx={{ pl: 2 }}
        >
          {truncateIfTooLong(messageUser.name!, 17)}
        </GradientTypography>
      )
    );
  }, [isUserInfoShown, messageUser]);

  const timestamp = useMemo(() => {
    return (
      !isMine &&
      isBottomOrSingle && (
        <Box
          sx={{
            time: {
              color: theme.palette.text.secondary,
            },
            fontSize: '12px',
            pt: 1,
          }}
        >
          <StreamMessageTimestamp />
        </Box>
      )
    );
  }, [isBottomOrSingle, isMine, theme.palette.text.secondary]);

  const replies = useMemo(() => {
    return !!replyCount && !isThreadMessage && <MessageRepliesPreview />;
  }, [isThreadMessage, replyCount]);

  const deliverStatus = useMemo(() => {
    return isStatusShown && <MessageStatus />;
  }, [isStatusShown]);

  return (
    <Stack
      direction="column"
      sx={{
        pl: isUserInfoShown || !isDm ? 8 : 0,
        alignItems: isMine ? 'flex-end' : 'flex-start',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="baseline">
        {username}
        {timestamp}
      </Stack>
      {replies}
      <Stack direction={'column'} spacing={1}>
        {deliverStatus}
        <Box
          sx={{
            pl: !isMine && !isDm ? 2 : 0,
          }}
        >
          <ReactionsList />
        </Box>
      </Stack>
    </Stack>
  );
};

export const MessageFooter = memo(MessageFooterUnmemoized);
