import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoadingButton } from '../buttons/LoadingButton';
import { FC } from 'react';
import { memo } from '../../util/memo';

export const MatchAutomationControls: FC<{
  handleConfirm: (action: 'delay' | 'cancel' | 'recreate') => void;
}> = memo(function MatchAutomationControlsUnmemoized({ handleConfirm }) {
  return (
    <Box>
      <Typography
        variant="overline"
        color="text.secondary"
        sx={{ fontSize: 18 }}
      >
        Automated tournament controls
      </Typography>
      <Stack direction="row" spacing="8px" height={80}>
        <LoadingButton
          onClick={async () => {
            return handleConfirm('recreate');
          }}
          variant="outlined"
          color="warning"
          fullWidth
        >
          Recreate match url
        </LoadingButton>
      </Stack>
    </Box>
  );
});
