import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { VerticalCarousel, VerticalCarouselProps } from './VerticalCarousel';
import {
  TextFieldDropdown,
  TextFieldDropdownProps,
} from '../../TextFieldDropdown';
import { useDebouncedSearch } from '../../../hooks/algolia/useDebouncedSearch';
import { NoSearchResults } from '../search/NoSearchResults';
import { useTheme } from '@mui/material/styles';

export type SearchDropdownProps = Pick<
  VerticalCarouselProps,
  'Content' | 'onNearEnd'
> &
  Omit<TextFieldDropdownProps, 'PopoverContent'>;

const SearchDropdownUnmemoized: React.FC<SearchDropdownProps> = ({
  Content,
  onNearEnd,
  ...props
}) => {
  const { applyQueryChange } = useDebouncedSearch();

  const theme = useTheme();

  const ContentCarousel = useMemo(() => {
    if (Content.length === 0) {
      return <NoSearchResults />;
    }
    return (
      <VerticalCarousel
        Content={Content}
        onNearEnd={onNearEnd}
        containerSx={{ background: theme.palette.background.elevation[11] }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Content, onNearEnd]);

  return (
    <TextFieldDropdown
      PopoverContent={ContentCarousel}
      {...props}
      onChange={(e) => {
        props.onChange?.(e);
        applyQueryChange(e);
      }}
    />
  );
};

export const SearchDropdown = memo(SearchDropdownUnmemoized);
