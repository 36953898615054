import { LoadingButton } from './LoadingButton';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { memo } from '../../util/memo';

export const ValidateEmailButton = memo(
  function ValidateEmailButtonUnmemoized() {
    const { isLoading, isValidated, enterEmail } = useAuthSubmit();

    return (
      <LoadingButton
        variant="contained"
        size="large"
        onClick={enterEmail}
        isLoading={isLoading}
        disabled={!isValidated.email}
        fullWidth
      >
        Sign Up / Sign In
      </LoadingButton>
    );
  },
);
