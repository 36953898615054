import { Team } from '../../types/firestore/Game/Tournament/Guestlist';

export type IsUserCheckedInParams = {
  userId?: string;
  team?: Team<Date>;
};

export const isUserCheckedIn = ({ userId, team }: IsUserCheckedInParams) => {
  if (!team || !userId) {
    return;
  }
  const { members } = team;
  const teamMember = members.find((member) => {
    const { userId: memberUserId } = member;
    return memberUserId === userId;
  });
  if (!teamMember) {
    return;
  }
  const { checkedIn } = teamMember;
  return checkedIn;
};
