import { useMemo } from 'react';
import { useRouter } from '../routing/useRouter';
import { GroupType } from '../../../functions/src/types/firestore/Guild';

export function useGroupBasic() {
  const router = useRouter();

  const pathSegments = useMemo(() => {
    return router.asPath.split('/');
  }, [router.asPath]);

  const groupBasic = useMemo(() => {
    const utcIndex = pathSegments.findIndex((segment) => {
      return segment.startsWith('UTC');
    });

    const groupType = utcIndex !== -1 ? pathSegments[utcIndex + 1] : undefined;
    const groupId =
      utcIndex !== -1 ? pathSegments[utcIndex + 2]?.split('?')[0] : undefined;

    return {
      groupType: (groupType
        ? groupType.charAt(0).toUpperCase() + groupType.slice(1).toLowerCase()
        : undefined) as GroupType,
      groupId: groupId ?? '',
    };
  }, [pathSegments]);

  return groupBasic;
}
