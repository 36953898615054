import { useCallback } from 'react';
import { OpenAlertDialogSettings, useAlertDialog } from '../useAlertDialog';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { useSignOut } from '../auth/useSignOut';

const ADDRESS_TAKEN_DIALOG = 'ADDRESS_TAKEN_DIALOG' as const;

export const useAddressTakenDialog = () => {
  const { open: openDialog, close } = useAlertDialog(ADDRESS_TAKEN_DIALOG);

  const { signOut } = useSignOut();

  const open = useCallback(
    (settings: Omit<OpenAlertDialogSettings, 'title' | 'description'> = {}) => {
      openDialog({
        title: 'Wallet Address Taken',
        description:
          'This wallet address is currently being used in another account. In order to sign in via the wallet address to the BluMint account associated with it, please sign out first.',
        children: (
          <Stack direction={'row'} sx={{ width: '100%', p: 4 }}>
            <LoadingButton
              onClick={async () => {
                await signOut();
                close();
              }}
              variant="contained"
              color="error"
              sx={{ width: '100%' }}
            >
              Sign Out
            </LoadingButton>
          </Stack>
        ),
        ...settings,
      });
    },
    [openDialog, signOut, close],
  );

  return {
    open,
    close,
  };
};
