import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { connectWithCredential } from './connectWithCredential';

export const appAuthWithApple = async () => {
  const firebaseAuth = await import('firebase/auth');
  const { OAuthProvider } = firebaseAuth;
  const { signInWithApple } = FirebaseAuthentication;
  const result = await signInWithApple();
  const provider = new OAuthProvider('apple.com');
  const credential = provider.credential({
    idToken: result.credential?.idToken,
    rawNonce: result.credential?.nonce,
  });
  return connectWithCredential(credential);
};
