import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import Typography from '@mui/material/Typography';

export type MatchHeaderProps = {
  teamNames: string[];
};

export const MatchHeader = memo(function MatchHeaderUnmemoized({
  teamNames,
}: MatchHeaderProps) {
  return (
    <GradientTypography
      variant="h4"
      sx={{ textTransform: 'uppercase', textAlign: 'center' }}
      gradientColor="primary.vertical"
    >
      {teamNames.map((teamName, index) => {
        return (
          <>
            <GradientTypography
              key={teamName}
              variant="h4"
              component="span"
              sx={{ textTransform: 'uppercase', wordBreak: 'break-all' }}
              gradientColor={
                index % 2 === 0 ? 'success.vertical' : 'warning.vertical'
              }
            >
              {teamName}
            </GradientTypography>
            <Typography variant="h4" component="span">
              {index !== teamNames.length - 1 && ` VS `}
            </Typography>
          </>
        );
      })}
    </GradientTypography>
  );
});
