import { useCallback, useEffect, useState } from 'react';
import { memo } from '../../../util/memo';
import { Token } from '../../../../functions/src/types/firestore/User/Payout';
import {
  EditableStepsContextType,
  useEditable,
} from '../../../contexts/organizer/EditableStepsContext';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { useCurrency } from '../../../contexts/CurrencyContext';
import { NoPrizePoolLinked } from './NoPrizePoolLinked';
import { hasSameContract } from '../../../../functions/src/util/payouts/findTokenInPayout';
import { PLACER_MAP } from './utils';
import { PayoutsAvailableSummary } from './PayoutsAvailableSummary';
import { PrizePool } from '../../../../functions/src/types/firestore/PrizePool';
import type { DocumentReference } from 'firebase/firestore';
import { usePayoutSelection } from '../../../hooks/prize-pool/usePayoutSelection';
import { TournamentPayoutsLayout } from '../../payouts/TournamentPayoutsLayout';
import { TournamentPayoutHeader } from '../../payouts/TourmanentPayoutHeader';
import { PayoutRow } from './PayoutRow';
import ClearIcon from '@mui/icons-material/ClearRounded';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import {
  PrizePoolSelectionProvider,
  usePrizePoolSelection,
} from '../../../contexts/organizer/PrizePoolSelectionContext';
import { TokenAggregator } from '../../../../functions/src/util/payouts/TokenAggregator';

export type PayoutsInputProps = {
  maxTeamSize: number;
  value: { tokens: Token[] }[];
  bracketType: Tournament['bracketType'];
  eventId: string;
  // TODO: flexibility for other events?
  onChange: EditableStepsContextType<Tournament<Date>>['updateField'];
  fieldName: string;
  prizePoolId: string;
};

export type PayoutsInputWithPrizePoolProps = {
  maxTeamSize: number;
  value: { tokens: Token[] }[];
  bracketType: Tournament['bracketType'];
  // TODO: flexibility for other events?
  eventId: string;
  prizePool?: PrizePool;
  onChange: EditableStepsContextType<Tournament<Date>>['updateField'];
  fieldName: string;
};

const DEFAULT_ROWS_DISPLAY = 3;
const DEFAULT_CARDS_PER_ROW = 3;

export const PayoutsInputWithPrizePool = memo(
  function PayoutsInputWithPrizePoolUnmemoized({
    value: payoutsCurrent,
    bracketType,
    maxTeamSize,
    onChange,
    fieldName,
    eventId,
    prizePool,
  }: PayoutsInputWithPrizePoolProps) {
    const payoutsAvailable = prizePool?.prizes || [];

    const { prizesAggregatedWithAmounts } = usePrizePoolSelection();
    const { formatPayout } = useCurrency();
    const payoutFormatted = formatPayout(payoutsAvailable);
    const { updateField } = useEditable();

    const placer = useCallback(
      (payoutIndex: number) => {
        return PLACER_MAP[bracketType](payoutIndex);
      },
      [bracketType],
    );

    const payoutsWithPlaceholders = payoutsCurrent.concat(
      new Array(Math.max(0, DEFAULT_ROWS_DISPLAY - payoutsCurrent.length)).fill(
        { tokens: [] },
      ),
    );

    const selectPayout = usePayoutSelection({
      eventId,
      payoutsCurrent,
      prizePool,
      onChange,
      fieldName,
    });

    const payoutFilled = prizesAggregatedWithAmounts.every((token) => {
      return token && token.amount === '0';
    });

    const handleRemove = useCallback(
      (payoutIndex: number, tokenIndex: number) => {
        //find all tokens matching by contract; remove these indices
        const currentToken = payoutsCurrent[payoutIndex].tokens[tokenIndex];
        const foundTokenIndices = payoutsCurrent[payoutIndex].tokens.reduce(
          (acc, curr, index) => {
            if (hasSameContract(currentToken, curr)) {
              acc.push(index);
            }
            return acc;
          },
          [] as number[],
        );

        onChange(
          foundTokenIndices.map((index) => {
            return {
              field: `${fieldName}.${payoutIndex}.tokens`,
              action: 'arrayDelete',
              value: {
                insert: undefined,
                index,
              },
            };
          }),
        );
      },
      [fieldName, onChange, payoutsCurrent],
    );

    const unlinkPrizePool = useCallback(async () => {
      if (!eventId) {
        return;
      }
      if (!prizePool?.id) {
        return;
      }
      const { unlinkPrizePool: unlink } = await import(
        '../../../firebaseCloud/tournament/organizer/unlinkPrizePool'
      );

      await unlink({
        prizePoolId: prizePool.id,
        tournamentId: eventId,
      });

      await updateField([
        {
          field: 'prizePoolId',
          value: null,
          action: 'overwrite',
        },
        {
          field: 'payouts',
          value: [],
          action: 'overwrite',
        },
      ]);
    }, [eventId, prizePool?.id, updateField]);

    return (
      <TournamentPayoutsLayout
        Header={
          <TournamentPayoutHeader
            {...payoutFormatted}
            showAction={!!prizePool?.id}
            ActionComponent={
              <GradientIconButton
                gradientColor="primary.horizontal"
                onClick={unlinkPrizePool}
                IconComponent={ClearIcon}
              />
            }
          />
        }
        Summary={
          !payoutFilled && (
            <PayoutsAvailableSummary
              payoutsAvailable={payoutsAvailable}
              payoutsSelected={payoutsCurrent}
              payoutUnfilled={!payoutFilled}
            />
          )
        }
        PayoutRows={payoutsWithPlaceholders.map((payout, index, payouts) => {
          return (
            <PayoutRow
              key={index}
              ranks={placer(index)}
              tokens={new TokenAggregator(payout.tokens).tokensAggregated}
              countPlaceholders={Math.max(
                0,
                DEFAULT_CARDS_PER_ROW - payout?.tokens?.length || 0,
              )}
              onAddTokens={
                payoutFilled
                  ? undefined
                  : index === 0 || payouts[index - 1].tokens.length > 0
                  ? () => {
                      return selectPayout(index);
                    }
                  : undefined
              }
              onRemoveToken={(tokenIndex: number) => {
                return handleRemove(index, tokenIndex);
              }}
              teamSize={maxTeamSize}
            />
          );
        })}
        showAltContent={!payoutsAvailable?.length}
        AltContent={<NoPrizePoolLinked />}
      />
    );
  },
);

export const TournamentPayoutsInput = memo(
  function TournamentPayoutsInputUnmemoized({
    value: payoutsSelected,
    maxTeamSize,
    prizePoolId,
    bracketType,
    onChange,
    fieldName,
    eventId,
  }: PayoutsInputProps) {
    const [prizePool, setPrizePool] = useState<PrizePool | undefined>();
    useEffect(() => {
      const handler = async () => {
        if (!prizePoolId) {
          setPrizePool(undefined);
        }
        const { firestore } = await import(
          '../../../config/firebase-client/firestore'
        );
        const { getDoc, doc } = await import('firebase/firestore');
        const prizePoolSnap = await getDoc(
          doc(
            firestore,
            `PrizePool/${prizePoolId}`,
          ) as DocumentReference<PrizePool>,
        );
        setPrizePool(prizePoolSnap.data());
      };
      handler();
    }, [prizePoolId]);
    return (
      <PrizePoolSelectionProvider
        prizePool={prizePool}
        payoutsCurrent={payoutsSelected}
        eventId={eventId}
      >
        <PayoutsInputWithPrizePool
          eventId={eventId}
          prizePool={prizePool}
          maxTeamSize={maxTeamSize}
          onChange={onChange}
          fieldName={fieldName}
          bracketType={bracketType}
          value={payoutsSelected}
        />
      </PrizePoolSelectionProvider>
    );
  },
);
