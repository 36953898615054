import { memo } from '../../../util/memo';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import {
  CompetitorOutcome,
  CompetitorOutcomeProps,
} from '../CompetitorOutcome';
import { Optional } from 'utility-types';
import { AvatarNext } from '../../AvatarNext';
import { TypographyTruncated } from 'src/components/TypographyTruncated';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';

export type Competitor = {
  name?: string;
  avatarUrl?: string;
  inGameId?: string;
};

export type MatchCompetitorProps = Optional<
  CompetitorOutcomeProps,
  'status'
> & {
  competitor?: Competitor;
  sx?: SxProps;
};

export const MatchCompetitor = memo(function MatchCompetitorUnmemoized(
  props: MatchCompetitorProps,
) {
  const { competitor, outcome, status, score, sx } = props;

  const content = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundImage:
            outcome === 'win' || outcome === 'bypass'
              ? 'linear-gradient(0deg, rgb(21, 219, 207, 0.09) 0%, rgb(21, 219, 207, 0.09) 90%)'
              : undefined,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1.5,
            p: 1,
          }}
        >
          <AvatarNext
            height={30}
            width={30}
            sx={{
              visibility: !!competitor?.avatarUrl ? 'visible' : 'hidden',
              backgroundColor: '#bdbdbd',
            }}
            src={competitor?.avatarUrl}
          />
          <TypographyTruncated
            variant="body2"
            color="text.primary"
            text={competitor?.name}
            textMaxLength={10}
            sx={{
              width: '98px',
              whiteSpace: 'nowrap',
              fontWeight: '500',
              lineHeight: 1.43,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeft: `2px solid rgba(77, 200, 255, 0.5)`,
            py: 1,
            px: 3,
            width: '36px',
          }}
        >
          {(status || outcome || score) && !!competitor && (
            <CompetitorOutcome
              {...{ outcome, status: status || 'inactive', score }}
            />
          )}
        </Box>
      </Box>
    );
  }, [competitor, outcome, status, score, sx]);

  if (!competitor?.name || competitor.name.length <= 10) return content;

  return (
    <Tooltip title={competitor.name}>
      <Box>{content}</Box>
    </Tooltip>
  );
});
