import CircularProgress from '@mui/material/CircularProgress';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';
import { MouseEvent, useCallback } from 'react';
import { memo } from '../../util/memo';
import {
  GradientIconButton,
  GradientIconButtonProps,
} from '../gradients/GradientIconButton';

export type LoadingGradientIconButtonProps = Omit<
  GradientIconButtonProps,
  'onClick'
> & {
  loading?: boolean;
  loadingColor?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const LoadingGradientIconButtonUnmemoized = (
  props: LoadingGradientIconButtonProps,
) => {
  const { loadingColor = 'primary', IconComponent, ...otherProps } = props;
  const [loading, asyncHandler] = useLoadingHandler(props.onClick);
  const loadingInternal = props.loading || loading;

  const circularProgress = useCallback(() => {
    return <CircularProgress size="1.5rem" color={loadingColor} />;
  }, [loadingColor]);

  return (
    <GradientIconButton
      {...otherProps}
      IconComponent={!loadingInternal ? IconComponent : circularProgress}
      onClick={asyncHandler}
    />
  );
};

export const LoadingGradientIconButton = memo(
  LoadingGradientIconButtonUnmemoized,
);
