import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { ReactNode } from 'react';
import { memo } from '../../../../util/memo';

export type MatchCellLayoutProps = {
  Competitors: ReactNode[];
  Overlay?: ReactNode;
  Interaction?: ReactNode;
  sx: SxProps & { width: number };
};
export const MatchCellLayout = memo(function MatchCellLayoutUnmemoized({
  sx,
  ...props
}: MatchCellLayoutProps) {
  const { Competitors, Overlay, Interaction } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 10px 10px 10px',
        border: `1px double transparent`,
        background: `linear-gradient(${theme.palette.background.elevationSolid[10]}, 
          ${theme.palette.background.elevationSolid[10]}), ${theme.palette.primary.vertical}`,
        backgroundClip: 'content-box, border-box',
        backgroundOrigin: 'border-box',
        transition: 'background 0.5s ease',
        position: 'relative',
        ...sx,
      }}
      zIndex={1}
    >
      {Overlay}
      {Competitors[0]}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        }}
      >
        <Divider
          sx={{
            alignItems: 'center',
            width: '100%',
            background: `${theme.palette.primary.dark}`,
            paddingLeft: `${sx.width - (36 + 36)}px`,
          }}
        >
          {Interaction}
        </Divider>
      </Box>
      {Competitors[1]}
    </Box>
  );
});
