import { memo } from '../../util/memo';
import type { SxProps } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

export type TableBackboneProps = {
  children: JSX.Element | JSX.Element[];
  outerChildren?: React.ReactNode | React.ReactElement;
  sxContainer?: SxProps;
  sxTable?: SxProps;
};
export const TableBackbone = memo(function TableBackboneUnmemoized(
  props: TableBackboneProps,
) {
  const { children, outerChildren, sxContainer, sxTable } = props;
  return (
    <TableContainer
      sx={{
        borderRadius: '5px',
        border: 'solid 1px transparent',
        backgroundImage: (theme) => {
          return `linear-gradient(${theme.palette.background.elevationSolid[8]},
              ${theme.palette.background.elevationSolid[8]}),
              ${theme.palette.primary.vertical}`;
        },
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        '&:hover': {
          boxShadow: (theme) => {
            return theme.shadows[4];
          },
        },
        ...sxContainer,
      }}
    >
      <Table
        sx={{ tableLayout: 'fixed', ...sxTable }}
        aria-label="customized table"
      >
        {children}
      </Table>
      {outerChildren}
    </TableContainer>
  );
});
