import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { useReferee } from 'src/contexts/RefereeContext';

export type ParticipantAdminDetailsProps = Omit<
  Member,
  'checkedIn' | 'userId' | 'username' | 'imgUrl' | 'status' | 'tournamentId'
> & { sx?: SxProps };

export const ParticipantAdminDetails = memo(
  function ParticipantAdminDetailsUnmemoized(
    props: ParticipantAdminDetailsProps,
  ) {
    const { sx, ...rest } = props;
    const { detailsOptions } = useReferee();
    return (
      <>
        {Object.entries(rest)
          .sort()
          .map(([key, value]) => {
            return key === detailsOptions?.keySummary ||
              !detailsOptions?.keySummary ||
              !detailsOptions?.summaryOnly ? (
              <Typography
                color="text.secondary"
                textTransform="none"
                flexWrap="nowrap"
                sx={{ px: 2, ...sx }}
              >
                {`${key}: ${value !== undefined ? value : `no ${key}`}`}
              </Typography>
            ) : null;
          })}
      </>
    );
  },
);
