import Select, { SelectChangeEvent } from '@mui/material/Select';
import { memo } from '../../../util/memo';
import { ParticipantFill } from './ParticipantFill';
import MenuItem from '@mui/material/MenuItem';
import { useCallback } from 'react';
import { SxProps } from '@mui/material/styles';

export type TeamlistSelectValue = 'waitlist' | 'guestlist';

export type GradientTeamlistSelectProps = {
  value: TeamlistSelectValue;
  onValueChange: (value: TeamlistSelectValue) => void;
  registeredTotal: number;
  maxRegistrants: number;
  waitlistTotal: number;
  sx?: SxProps;
};

function GradientTeamlistSelectUnmemoized({
  value,
  onValueChange,
  registeredTotal,
  maxRegistrants,
  waitlistTotal,
  sx,
}: GradientTeamlistSelectProps) {
  const onSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      onValueChange(event.target.value as TeamlistSelectValue);
    },
    [onValueChange],
  );

  return (
    <Select
      value={value}
      onChange={onSelectChange}
      variant="outlined"
      disableUnderline
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
        },
        ...sx,
      }}
    >
      <MenuItem value="guestlist">
        <ParticipantFill
          name={'guestlist'}
          currentFill={registeredTotal}
          maxFill={maxRegistrants}
        />
      </MenuItem>
      <MenuItem value="waitlist">
        <ParticipantFill name={'waitlist'} currentFill={waitlistTotal} />
      </MenuItem>
    </Select>
  );
}

export const GradientTeamlistSelect = memo(GradientTeamlistSelectUnmemoized);
