import { memo } from '../../util/memo';
import { GradientIconButton } from '../gradients/GradientIconButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { RemoteParticipant } from 'livekit-client';
import { useCallback, useState } from 'react';

export type MuteCallerButtonProps = {
  participant: RemoteParticipant;
};

const MuteCallerButtonUnmemoized = ({ participant }: MuteCallerButtonProps) => {
  const [isMuted, setIsMuted] = useState(participant.getVolume() === 0);

  const mute = useCallback(() => {
    participant.setVolume(0);
    return setIsMuted(true);
  }, [participant]);

  const unmute = useCallback(() => {
    participant.setVolume(1);
    return setIsMuted(false);
  }, [participant]);

  return (
    <GradientIconButton
      IconComponent={isMuted ? MicOffIcon : MicIcon}
      onClick={isMuted ? unmute : mute}
    />
  );
};

export const MuteCallerButton = memo(MuteCallerButtonUnmemoized);
