import {
  useRoomContext as useLivekitRoom,
  useParticipants,
} from '@livekit/components-react';
import { useVoiceChannel } from './useVoiceChannel';
import { useEffect } from 'react';
import { useConnectionStatus } from './useConnectionStatus';


export const useLeaveCall = () => {
  const { disconnect } = useLivekitRoom();
  const { voiceChannel } = useVoiceChannel();
  const participants = useParticipants();
  const { isConnected } = useConnectionStatus();

  useEffect(() => {
    const handler = async () => {
      if (voiceChannel?.current || !isConnected) {
        return;
      }

      const stopTracks = participants.length === 1;

      return await disconnect(stopTracks);
    };

    handler();
  }, [voiceChannel?.current]);
};
