import { FC, useMemo, useEffect } from 'react';
import { memo } from '../../util/memo';
import { CatalogWrapperProps } from './AlgoliaLayout';
import type { DocumentDataEverywhere } from '../../../functions/src/types/DocumentDataEverywhere';
import { buildPreemptionPathSort } from '../../../functions/src/util/algolia/preemption/buildPreemptionPathSort';
import { useFirestoreHits } from '../../hooks/algolia/useFirestoreHits';
import { useCustomHits } from '../../hooks/algolia/useCustomHits';
import { useInfiniteHits } from 'react-instantsearch';
import { usePreemptState } from '../../contexts/algolia/PreemptStateContext';

export type CustomHitsPreemptedProps = {
  CatalogWrapper: FC<
    Omit<CatalogWrapperProps<DocumentDataEverywhere>, 'header'>
  >;
  skipHits?: string[];
  onHitsChange?: (hits: Record<string, unknown>[]) => void;
};

export const CustomHitsPreemptedUnmemoized = ({
  CatalogWrapper,
  skipHits,
  onHitsChange,
}: CustomHitsPreemptedProps) => {
  const state = usePreemptState();

  const query = useMemo(() => {
    return state?.query || '';
  }, [state?.query]);

  const filters = useMemo(() => {
    return state?.filters;
  }, [state?.filters]);

  const hitsPerPage = useMemo(() => {
    return state?.hitsPerPage;
  }, [state?.hitsPerPage]);

  const preemptionPathSort = useMemo(() => {
    const isNoQuery = query === '';
    return isNoQuery && filters ? buildPreemptionPathSort(filters) : undefined;
  }, [query, filters]);

  const firestoreHits = useFirestoreHits(
    preemptionPathSort,
    hitsPerPage,
    filters,
  );

  const infiniteHits = useInfiniteHits();
  const algoliaHits = useCustomHits(infiniteHits, skipHits);

  const isFromFirestore =
    preemptionPathSort || (firestoreHits.hits.length && algoliaHits.isLoading);

  const { hits, loadMore, isLoading } = useMemo(() => {
    return isFromFirestore ? firestoreHits : algoliaHits;
  }, [algoliaHits, firestoreHits, isFromFirestore]);

  useEffect(() => {
    onHitsChange?.(hits);
  }, [hits, onHitsChange]);

  return (
    <CatalogWrapper hits={hits} onNearEnd={loadMore} isLoading={isLoading} />
  );
};

export const CustomHitsPreempted = memo(CustomHitsPreemptedUnmemoized);
