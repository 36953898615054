import Stack from '@mui/material/Stack';
import { LoadingButton } from '../../buttons/LoadingButton';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';

export type ActionButtonProps = {
  label: string;
  onClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void>;
};
const ActionButtonUnmemoized = ({ label, onClick }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2} width="100%" pt={4}>
      <LoadingButton
        fullWidth
        variant="contained"
        sx={{
          background: theme.palette.primary.horizontal,
          borderRadius: '100px',
        }}
        onClick={async (
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) => {
          await onClick(event);
        }}
      >
        {label}
      </LoadingButton>
    </Stack>
  );
};
export const ActionButton = memo(ActionButtonUnmemoized);
