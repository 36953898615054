import Button from '@mui/material/Button';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { useMemo, useCallback } from 'react';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { useActiveTournamentChannel } from './useActiveTournamentChannel';
import { useAuth } from '../../contexts/AuthContext';

export const useChannelNavigationButton = () => {
  const { userData } = useAuth();
  const { channel } = useStreamChatContext();
  const { openTeamChannel, openMatchChannel, teamChannelGroup } =
    useActiveTournamentChannel(userData?.currentTournament?.tournamentId);

  const hasActiveMatch = useMemo(() => {
    return !!userData?.currentTournament?.currentMatch;
  }, [userData?.currentTournament?.currentMatch]);

  const openChannel = useCallback(
    (type?: string) => {
      if (!type) {
        return;
      }

      if (type === 'match' || type === 'general') {
        return openTeamChannel();
      }

      const matchId = userData?.currentTournament?.currentMatch;
      if (!matchId) {
        return;
      }

      openMatchChannel(matchId);
    },
    [
      openMatchChannel,
      openTeamChannel,
      userData?.currentTournament?.currentMatch,
    ],
  );

  const navigationButton = useMemo(() => {
    const { type, data } = channel || {};
    const label =
      (type === 'match' || type === 'general') && !!teamChannelGroup
        ? 'Team Chat'
        : type === 'personalGroup' && !!data?.tournamentId && hasActiveMatch
        ? 'Match Lobby'
        : undefined;

    return (
      !!label && (
        <Button
          variant="contained"
          endIcon={<ArrowOutwardRoundedIcon />}
          onClick={() => {
            return openChannel(type);
          }}
          sx={{
            py: 0,
            px: 2,
            mr: 2,
            width: 'fit-content',
            borderRadius: '4px',
          }}
        >
          {label}
        </Button>
      )
    );
  }, [channel, hasActiveMatch, openChannel, teamChannelGroup]);

  return { navigationButton };
};
