import { useEffect, useState } from 'react';
import { generateParticipantToken } from '../../util/voice-chat/generateParticipantToken';
import { useConnectionStatus } from './useConnectionStatus';
import { useRoom } from '../../contexts/RoomContext';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useAuth } from '../../contexts/AuthContext';
import { useVoiceChannel } from './useVoiceChannel';
import {
  useAudioPlayback,
  useParticipants,
  useRoomContext as useLivekitRoom,
} from '@livekit/components-react';

export const useJoinCall = () => {
  const { userData } = useAuth();
  const [triggerAudio, setTriggerAudio] = useState(false);
  const { channelGroupActive } = useActiveChannelGroup();
  const { disconnect } = useLivekitRoom();
  const participants = useParticipants();
  const { voiceChannel } = useVoiceChannel();
  const { setRoomAccessToken } = useRoom();
  const room = useLivekitRoom();
  const { canPlayAudio, startAudio } = useAudioPlayback(room);
  const { isConnected, isConnecting } = useConnectionStatus();

  useEffect(() => {
    const handler = async () => {
      if (!canPlayAudio || !isConnected || !triggerAudio) {
        return;
      }

      await startAudio();

      const { setTournamentChannelGroupExternal } = await import(
        '../../firebaseCloud/messaging/setTournamentChannelGroupExternal'
      );
      const { permanence, groupFilter } = channelGroupActive || {};
      const tournamentId = groupFilter?.[0]?.tournamentId;

      if (!tournamentId || permanence !== 'temporary') {
        return;
      }

      await setTournamentChannelGroupExternal({
        tournamentId,
        permanence: 'pinned',
      });
    };
    handler();
  }, [canPlayAudio, channelGroupActive, startAudio, isConnected, triggerAudio]);

  useEffect(() => {
    const handler = async () => {
      const { current } = voiceChannel || {};
      const isAlreadyConnected =
        isConnected && !!room.name && room.name === current;

      const { FieldValue } = await import('firebase/firestore');

      if (
        !current ||
        !userData ||
        isAlreadyConnected ||
        isConnecting ||
        current instanceof FieldValue
      ) {
        return;
      }

      if (isConnected) {
        await disconnect(participants.length === 1);
      }

      const { id: userId, username, imgUrl } = userData;

      const response = await generateParticipantToken({
        roomName: current as string,
        username,
        userId,
        imgUrl,
      });

      setRoomAccessToken(response.token);
      setTriggerAudio(true);
    };
    handler();
  }, [
    room.name,
    participants.length,
    voiceChannel,
    userData?.id,
    userData?.username,
    userData?.imgUrl,
    isConnected,
    setRoomAccessToken,
    disconnect,
  ]);
};
