import {
  ChannelGroup,
  GroupFilterMap,
} from '../../types/firestore/User/ChannelGroup';
import { getMainUser } from '../../types/firestore/User/ChannelGroup/util/getMainUser';
import { isSupport } from '../../types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../types/firestore/User/ChannelGroup/util/isTournament';
import { isDm } from '../../types/firestore/User/ChannelGroup/util/isDm';

export type ActiveChannelGroup = Omit<
  ChannelGroup<keyof GroupFilterMap, Date>,
  'imgUrl'
> & {
  imgUrls: string[];
  isDmChannel: boolean;
  isSupportChannel: boolean;
  isTournamentChannel: boolean;
};

export const toActiveChannelGroup = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
): ActiveChannelGroup => {
  const { imgUrl, ...rest } = channelGroup;
  const { groupFilter, members } = rest;
  const uid = getMainUser(groupFilter);
  const withoutSelf =
    members?.filter(({ userId }) => {
      return userId !== uid;
    }) || [];

  const imgUrls =
    withoutSelf.length === 0 || isSupport(channelGroup)
      ? [imgUrl]
      : withoutSelf.map(({ image }) => {
          return image;
        });

  const isDmChannel = isDm(channelGroup);
  const isSupportChannel = isSupport(channelGroup);
  const isTournamentChannel = isTournament(channelGroup);

  return {
    ...rest,
    imgUrls,
    isDmChannel,
    isSupportChannel,
    isTournamentChannel,
  };
};
