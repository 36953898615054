import { useContext, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { Stack } from '@mui/material';
import { chunkAll } from '../../../../functions/src/callable/tournament/cohort/chunkAll';
import { TabPanes, TabPane } from '../../routing/TabPanes';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { BracketBoardContainer } from './BracketBoardContainer';
import { Bracket } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket';

export type BracketPaneProps = {
  bracket: Bracket<Date>;
};

const BracketPaneUnmemoized = ({ bracket }: BracketPaneProps) => {
  const { sponsors } = useContext(TournamentContext);

  const { cohorts, cohortsLoser, cohortsGrandFinal } = chunkAll<Date>(bracket);

  const tabPanes: TabPane<string>[] = useMemo(() => {
    const qualifiersWinner = cohorts?.filter((cohort) => {
      return cohort.title !== 'Final';
    });
    const qualifiersLoser = cohortsLoser?.filter((cohort) => {
      return cohort.title !== 'Final';
    });
    const winnerFinal = cohorts?.find((cohort) => {
      return cohort.title === 'Final';
    });
    const loserFinal = cohortsLoser?.find((cohort) => {
      return cohort.title === 'Final';
    });
    const grandFinal = cohortsGrandFinal?.[0];

    const tabPanesArray: TabPane<string>[] = [];

    if (qualifiersWinner?.length) {
      tabPanesArray.push({
        value: 'qualifiers',
        component: (
          <Stack spacing={0} direction="column">
            {qualifiersWinner.map((qualifierWinner, index) => {
              const qualifierLoser = qualifiersLoser?.[Number(index)];
              return (
                <BracketBoardContainer
                  key={qualifierWinner.title}
                  bracket={qualifierWinner}
                  bracketLoser={qualifierLoser}
                  sponsors={sponsors}
                />
              );
            })}
          </Stack>
        ),
        customization: {
          label: 'qualifiers',
        },
      });
    }

    if (winnerFinal) {
      tabPanesArray.push({
        value: 'final',
        component: (
          <BracketBoardContainer
            bracket={winnerFinal}
            bracketLoser={loserFinal}
            sponsors={sponsors}
          />
        ),
      });
    }

    if (grandFinal) {
      tabPanesArray.push({
        value: 'grand final',
        component: (
          <BracketBoardContainer bracket={grandFinal} sponsors={sponsors} />
        ),
      });
    }

    return tabPanesArray;
  }, [cohorts, cohortsGrandFinal, cohortsLoser, sponsors]);

  const tabPanesState = useRouterState({
    key: 'heat',
    defaultValue: tabPanes[0].value,
  });

  return (
    <TabPanes
      tabPanes={tabPanes}
      state={tabPanesState}
      variant="chip"
      sxTabs={{ mb: 2 }}
    />
  );
};

export const BracketPane = memo(BracketPaneUnmemoized);
