import { Round } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';

export type CalculatePrizesParams = {
  roundPayouts: Pick<Round, 'payout'>[];
};

export function calculateRoundPayouts({
  roundPayouts = [],
}: CalculatePrizesParams) {
  const roundsCount = roundPayouts.length;
  if (roundsCount < 2) {
    return { payouts: [], finalRoundPayout: { win: [], loss: [] } };
  }
  const roundPayoutsReversed = [...roundPayouts].reverse();
  const { payout: winnerPayout } = roundPayoutsReversed[0];
  const { payout: loserPayout } = roundPayoutsReversed[1];
  const finalRoundPayout = {
    win: winnerPayout,
    loss: loserPayout,
  };
  const payouts = [
    ...new Array<Pick<Round, 'payout'>>(roundsCount).fill(
      undefined as unknown as Pick<Round, 'payout'>,
    ),
  ];
  roundPayoutsReversed
    .slice(1, Math.min(roundPayouts.length, roundsCount))
    .map((roundPayout, i) => {
      payouts[payouts.length - 1 - i] = roundPayout;
    });

  return { payouts, finalRoundPayout };
}
