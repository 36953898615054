import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Diversity3Rounded from '@mui/icons-material/Diversity3Rounded';
import Diversity1Rounded from '@mui/icons-material/Diversity1Rounded';
import { GradientIcon } from '../../gradients/GradientIcon';
import { truncateIfTooLong } from '../../../util/truncate';
import { memo } from '../../../util/memo';
import { FC } from 'react';

type NoContentProps = {
  variant: string;
  isSelf: boolean;
  username?: string;
  extra?: string;
};

const textLookup = (username = 'This user', extra = 'group') => {
  const truncatedUsername = truncateIfTooLong(username, 20);

  return {
    friends: {
      self: 'Add some friends above.',
      other: 'Add as a friend?',
    },
    fam: {
      self: 'Move your friends to fam in the edit tab.',
      other: 'Add as a friend?',
    },
    'friend requests': {
      self: 'You can send friend requests instead.',
      other: 'No pending requests.',
    },
    'membership requests': {
      self: 'You can send membership requests or invite others to join your group.',
      other: 'Share your group with friends to get more members.',
    },
    groups: {
      self: 'Join or create a group to get started!',
      other: `${truncatedUsername} hasn't joined any ${extra}s yet.`,
    },
  };
};

const NoContentUnmemoized: FC<NoContentProps> = ({
  variant,
  isSelf,
  username,
  extra,
}) => {
  const getTitleText = () => {
    if (isSelf || !username) {
      return `No ${variant.replace('_', ' ')}!`;
    }
  };

  const getBodyText = () => {
    const textForVariant = textLookup(username, extra)[String(variant)];
    return isSelf ? textForVariant.self : textForVariant.other;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 1,
        }}
      >
        <GradientIcon
          IconComponent={
            variant === 'fam' ? Diversity1Rounded : Diversity3Rounded
          }
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 150,
            maxHeight: 150,
            p: 4,
          }}
        />
      </Box>
      <Box sx={{ width: '60%', textAlign: 'center' }}>
        <Typography variant="h6">{getTitleText()}</Typography>
        <Typography variant="body1">{getBodyText()}</Typography>
      </Box>
    </Box>
  );
};

export const NoContent = memo(NoContentUnmemoized);
