import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';
import { DialogProps } from '@mui/material/Dialog';
import { useCallback } from 'react';
import { WizardOptions } from './wizard/createUseWizard';
import { useRedirectCallback } from './useRedirectCallback';
import { AlertStandardDialog } from '../components/AlertStandardDialog';
import { AlertColor } from '@mui/material/Alert';

export type OpenAlertDialogSettings = {
  title: string;
  description?: string;
  severity?: AlertColor;
} & Omit<DialogProps, 'open'>;

export function useAlertDialog(
  id: string,
  options: WizardOptions = { closeOnRouteChange: true },
) {
  const { union, remove } = useGlobalComponentsContext();

  const close = useCallback(() => {
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.closeOnRouteChange ? close : undefined);

  const open = ({
    title,
    description,
    children,
    severity,
    ...props
  }: OpenAlertDialogSettings) => {
    union(
      id,
      <AlertStandardDialog
        title={title}
        id={id}
        severity={severity}
        description={description}
        {...props}
      />,
    );
  };
  return { open, close };
}
