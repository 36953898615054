import { DATADOG_USER, DatadogUser } from './extractDatadogUser';

export const hasDatadogUserChanged = (
  userData: DatadogUser,
  userDataPrevious?: DatadogUser,
) => {
  return (
    !userDataPrevious ||
    DATADOG_USER.some((key) => {
      return (
        userData[key as keyof DatadogUser] !==
        userDataPrevious[key as keyof DatadogUser]
      );
    })
  );
};
