import { stripScheme } from './stripScheme';

export const DEPLOYMENT_URL = !!process.env.NEXT_PUBLIC_DEPLOYMENT_URL
  ? process.env.NEXT_PUBLIC_DEPLOYMENT_URL
  : process.env.NEXT_PUBLIC_VERCEL_URL || ('blumint.com' as const);

export const DOMAIN = stripScheme(DEPLOYMENT_URL) as string;

export const deploymentUrlSchemed = (subdomain = '') => {
  if (DOMAIN?.includes('localhost')) {
    return `http://${subdomain}${DOMAIN}`;
  }
  return `https://${subdomain}${DOMAIN}`;
};
