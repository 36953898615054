import { MutableRefObject, FC, useRef, MouseEventHandler } from 'react';
import { memo } from '../../util/memo';
import { ChipTab, ChipTabProps } from '../ChipTab';

export type RenderChipTabProps = Omit<ChipTabProps, 'onClick'> & {
  onClick?: (ref: MutableRefObject<Element | null>, go: () => void) => void;
  go: MouseEventHandler<HTMLDivElement>;
};

const RenderChipTabUnmemoized: FC<RenderChipTabProps> = ({
  onClick,
  go,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <ChipTab
      ref={ref}
      onClick={(event) => {
        if (onClick) {
          onClick(ref, () => {
            return go(event);
          });
        } else {
          return go(event);
        }
      }}
      {...rest}
    />
  );
};

export const RenderChipTab = memo(RenderChipTabUnmemoized);
