import { useMemo, useCallback } from 'react';
import {
  useChatContext as useStreamChatContext,
  useChannelStateContext as useStreamChannelStateContext,
  useChannelActionContext as useStreamChannelActionContext,
  StreamMessage,
} from 'stream-chat-react';
import { useAuth } from 'src/contexts/AuthContext';
import { useReply } from '../../components/messaging/ReplyContext';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import ReplyAllIcon from '@mui/icons-material/ReplyAllRounded';
import ReplyIcon from '@mui/icons-material/ReplyRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import LiveHelpIcon from '@mui/icons-material/LiveHelpRounded';
import VolumeOffIcon from '@mui/icons-material/VolumeOffRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useMessage } from '../../components/messaging/message/MessageContext';
import { useDeleteMessage } from './useDeleteMessage';
import { useBanUser } from './useBanUser';
import { useMuteUser } from './useMuteUser';
import { MenuOption } from '../../components/MenuOption';
import { useBanStatus } from './useBanStatus';
import { usePersonalChannelGroup } from './usePersonalChannelGroup';
import { toUserId } from '../../../functions/src/util/messaging/mapId';
import { extractIds } from '../../../functions/src/util/roles/extractIds';
import { RoleMap } from '../../../functions/src/types/Roles';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useGetStreamId } from './useGetStreamId';

export type UseMessagePermissionsParams = {
  message: StreamMessage;
};

export const useMessageActions = ({ message }: UseMessagePermissionsParams) => {
  const { userData } = useAuth();
  const { id: uid } = userData || {};
  const { mutes } = useStreamChannelStateContext();
  const { channel } = useStreamChatContext();
  const { setReplyingTo } = useReply();
  const { deleteMessage } = useDeleteMessage();
  const { updateMessage } = useStreamChannelActionContext();
  const {
    handleOpenThread: openThread,
    setActionAnchor,
    setEditingState,
    setParentId,
  } = useMessage();
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();

  const resetAnchorElement = useCallback(() => {
    return setActionAnchor(null);
  }, [setActionAnchor]);

  const {
    text,
    attachments,
    user: messageUser,
    parent_id,
    hasBeenForwarded,
    id: messageId,
  } = message;

  const userIdGetStream = useGetStreamId();
  const { id: messageUserId, name: messageUsername } = messageUser || {};

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const banStatus = useBanStatus(toUserId(messageUserId!));

  const { banUser, unbanUser } = useBanUser({
    messageId,
    messageUserId,
    messageUsername,
    channel,
  });
  const { muteUser, unmuteUser } = useMuteUser({
    messageId,
    username: messageUsername,
    userId: messageUserId,
  });

  const isUserBanned =
    banStatus !== 'loading' &&
    (banStatus.isBannedFromChannel(channel?.cid) || banStatus.isBannedFromApp);

  const roledIds = !!channel?.data?.roles
    ? extractIds(
        channel.data.roles as RoleMap<'contributor' | 'moderator' | 'admin'>,
      )
    : [];

  const isRoledUserOrAdmin = !!uid && roledIds.includes(uid);
  const isRoledUserOrAdminMessage =
    !!messageUserId && roledIds.includes(toUserId(messageUserId));

  // TODO: incorporate matchPublic once 4200 is merged
  const isMatch = channel?.type === 'match';
  const isOwner = messageUserId === userIdGetStream;
  const isMuted = mutes?.some(({ target: { id: mutedUserId } }) => {
    return mutedUserId === messageUserId;
  });
  const canManageBans =
    !isOwner && isRoledUserOrAdmin && !isRoledUserOrAdminMessage;

  const permissionConditions = {
    edit: isOwner,
    reply: !isOwner,
    replyThread: !parent_id && !isOwner,
    forwardToSupport: !hasBeenForwarded,
    mute: !isMuted && !isOwner && !isRoledUserOrAdminMessage && !isMatch,
    unmute: isMuted && !isOwner,
    ban: canManageBans && !isUserBanned,
    unban: canManageBans && isUserBanned,
    delete: isRoledUserOrAdmin || isOwner,
  };

  const activePermissions = !uid
    ? []
    : Object.keys(permissionConditions).filter((permission) => {
        return permissionConditions[String(permission)];
      });

  const messageActions = {
    reply: {
      label: 'REPLY',
      IconComponent: ReplyIcon,
      onClick: () => {
        setReplyingTo({
          id: messageId,
          username: messageUsername!,
        });
      },
    },
    replyThread: {
      label: 'REPLY IN THREAD',
      IconComponent: ReplyAllIcon,
      onClick: async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => {
        setParentId(messageId);
        await openThread(event);
      },
    },
    edit: {
      label: 'EDIT',
      IconComponent: EditIcon,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditingState(event);
      },
    },
    forwardToSupport: {
      label: 'FORWARD TO SUPPORT',
      IconComponent: LiveHelpIcon,
      onClick: async () => {
        if (!uid) {
          return;
        }
        updateMessage({ ...message, hasBeenForwarded: true });

        const channelGroup = await setPersonalChannelGroup({
          type: 'support',
          friendIds: [],
          messagesInitial: [
            {
              text: `CONCERN FORWARDED BY: ${userData?.username}${
                text ? `\n "${text}"` : ''
              }`,
              attachments,
            },
          ],
        });

        openChannelGroup(channelGroup.id);
      },
    },
    mute: {
      label: 'MUTE (FOR YOURSELF)',
      IconComponent: VolumeOffIcon,
      gradientColor: 'warning.horizontal',
      onClick: () => {
        muteUser();
      },
    },
    unmute: {
      label: 'UNMUTE',
      IconComponent: VolumeOffIcon,
      gradientColor: 'warning.horizontal',
      onClick: () => {
        unmuteUser();
      },
    },
    ban: {
      label: 'BAN USER',
      IconComponent: DoNotDisturbAltIcon,
      gradientColor: 'error.horizontal',
      onClick: () => {
        banUser();
      },
    },
    unban: {
      label: 'UNBAN USER',
      IconComponent: DoNotDisturbAltIcon,
      gradientColor: 'error.horizontal',
      onClick: () => {
        unbanUser();
      },
    },
    delete: {
      label: 'DELETE MESSAGE',
      IconComponent: DeleteIcon,
      gradientColor: 'error.horizontal',
      onClick: () => {
        deleteMessage();
      },
    },
  };

  const activeActions = useMemo(() => {
    return activePermissions.map((actionType) => {
      const actionItem = messageActions[`${actionType}`];
      return (
        <MenuOption
          key={actionItem.label}
          onActionComplete={resetAnchorElement}
          {...actionItem}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePermissions]);

  return { activeActions };
};
