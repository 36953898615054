import { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getSessionId } from '../util/session/getSessionId';
import { updateSessionStatus } from '../util/session/updateSessionStatus';

export const POLLING_INTERVAL = 30000;

export const useSessionId = () => {
  const { uidFull } = useAuth();
  const sessionId = getSessionId();
  const prevSessionId = useRef<string>();
  const prevUserId = useRef<string>();
  const intervalId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const trackSessionPresence = async () => {
      if (
        prevUserId.current === uidFull &&
        prevSessionId.current === sessionId
      ) {
        return;
      }

      await updateSessionStatus(uidFull!, 'online', sessionId);
      prevUserId.current = uidFull;
      prevSessionId.current = sessionId;
    };

    const startPolling = () => {
      intervalId.current = setInterval(async () => {
        await updateSessionStatus(uidFull!, 'online', sessionId);
      }, POLLING_INTERVAL);
    };

    if (uidFull) {
      trackSessionPresence();
      startPolling();
    }

    return () => {
      const handler = async () => {
        clearInterval(intervalId.current);

        if (!uidFull || prevUserId.current !== uidFull) {
          return;
        }
        await updateSessionStatus(uidFull, 'offline', prevSessionId.current);
      };

      handler();
    };
  }, [sessionId, uidFull]);

  return sessionId;
};
