import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { memo } from '../../util/memo';

export type OverlayAvatarProps = {
  children: ReactNode;
  sx?: SxProps;
};

const OverlayAvatarUnmemoized = ({ children, sx = {} }: OverlayAvatarProps) => {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: 'none',
        borderRadius: '50%',
        background:
          'radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 80%)',
        color: 'white',
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export const OverlayAvatar = memo(OverlayAvatarUnmemoized);
