import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from '../../../util/memo';
import { SupportChatButton } from '../../buttons/SupportChatButton';
import { INPUT_AREA_HEIGHT } from '../channel/ChannelInner';

const NoticeBannedUnmemoized = () => {
  return (
    <Stack
      height={INPUT_AREA_HEIGHT}
      justifyContent="center"
      alignItems="center"
      direction="row"
      spacing={1}
      pb={1}
    >
      <GradientTypography
        variant="body1"
        gradientColor="primary.vertical"
      >{`You've been banned.`}</GradientTypography>
      <Typography variant="body1" color="text.secondary">
        Contact
      </Typography>
      <SupportChatButton sx={{ boxShadow: 'none', px: 1 }} />
      <Typography variant="body1" color="text.secondary">
        for more information.
      </Typography>
    </Stack>
  );
};
export const NoticeBanned = memo(NoticeBannedUnmemoized);
