import {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  OutcomeClaim,
  PlacementClaim,
} from '../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useAuth } from './AuthContext';
import { findCaseInsensitiveIn } from '../../functions/src/util/findCaseInsensitiveIn';
import { useMatch } from '../components/tournaments/match/MatchProvider';

type ScoringContextType = {
  outcome?: OutcomeClaim;
  placement?: number;
  selectOutcome: (selected: OutcomeClaim) => void;
  selectPlacement: (event: SelectChangeEvent<unknown>) => void;
  teamScores: (number | undefined)[];
  teamSessionScores: PlacementClaim[][];
  numberOfTeams: number;
  teamToScore?: { id: string; acceptedUserIds?: string[] };
};

type ScoringProviderProps = {
  children: ReactElement;
};

const ScoringContext = createContext<ScoringContextType>(
  {} as ScoringContextType,
);

export const useScoring = () => {
  return useContext(ScoringContext);
};

const ScoringProviderUnmemoized = ({ children }: ScoringProviderProps) => {
  const { uid } = useAuth();
  const {
    team1Scores = [],
    team2Scores = [],
    team1,
    team2,
    team1Score,
    team2Score,
  } = useMatch();

  const [outcome, setOutcome] = useState<OutcomeClaim>();
  const [placement, setPlacement] = useState<number>();

  const selectOutcome = useCallback((selected: OutcomeClaim) => {
    setOutcome((prev) => {
      if (prev === selected) {
        return undefined;
      }
      return selected;
    });
  }, []);

  const selectPlacement = useCallback((event: SelectChangeEvent<unknown>) => {
    setPlacement(event.target.value as number);
  }, []);

  const teamUserIds = useMemo(() => {
    return [team1?.acceptedUserIds, team2?.acceptedUserIds];
  }, [team1, team2]);

  const numberOfTeams = useMemo(() => {
    return teamUserIds.length;
  }, [teamUserIds]);

  const teamSessionScores = useMemo(() => {
    return [team1Scores, team2Scores];
  }, [team1Scores, team2Scores]);

  const teamScores = useMemo(() => {
    return [team1Score, team2Score];
  }, [team1Score, team2Score]);

  const teamIndex = useMemo(() => {
    return teamUserIds.findIndex((userIds) => {
      return !!uid && findCaseInsensitiveIn(uid, userIds);
    });
  }, [teamUserIds, uid]);

  const teamToScore = useMemo(() => {
    return teamIndex === 0 ? team1 : team2;
  }, [teamIndex, team1, team2]);

  //TODO:@shaffy9633 BLU-4200 code
  // const resultToSubmit = useMemo(() => {
  //   if (results.length > 2) {
  //     return placement;
  //   }
  //   return outcome;
  // }, [outcome, placement, teamIndex, teamSessionScores]);

  const value = useMemo(() => {
    return {
      outcome,
      placement,
      selectOutcome,
      selectPlacement,
      teamScores,
      teamSessionScores,
      numberOfTeams,
      teamToScore,
    };
  }, [
    outcome,
    placement,
    selectOutcome,
    selectPlacement,
    teamScores,
    teamSessionScores,
    numberOfTeams,
    teamToScore,
  ]);

  return (
    <ScoringContext.Provider value={value}>{children}</ScoringContext.Provider>
  );
};

export const ScoringProvider = memo(ScoringProviderUnmemoized);
