import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from '../../../util/memo';

export type ParticipantFillProps = {
  name?: string;
  currentFill: number;
  maxFill?: number;
};

function ParticipantFillUnmemoized({
  name = 'Participants',
  currentFill,
  maxFill,
}: ParticipantFillProps) {
  const ratio = maxFill ? `${currentFill}/${maxFill}` : currentFill;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <GradientTypography
        variant="h6"
        gradientColor="primary.vertical"
        sx={{
          textTransform: 'uppercase',
          pr: 2,
        }}
      >
        {name}
      </GradientTypography>
      <Typography variant="h6">{ratio}</Typography>
    </Box>
  );
}

export const ParticipantFill = memo(ParticipantFillUnmemoized);
