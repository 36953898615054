import { FC, useMemo } from 'react';
import {
  UseRenderColumns,
  useRenderColumns as useRenderColumnsMobile,
} from '../../components/tournaments/brackets/column/useRenderColumns';
import { useMobile } from '../useMobile';
import { splitRounds } from '../../../functions/src/util/tournaments/splitRounds';
import { Round } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { Required } from 'utility-types';
import { ColumnMobile } from '../../components/tournaments/brackets/column/ColumnMobile';

export type BracketColumnsProps<TProps> = {
  rounds: Required<Round<Date>, 'matches'>[];
  useRenderColumns: UseRenderColumns<TProps>;
  columnComponents: {
    Left: FC<TProps>;
    Right: FC<TProps>;
    Center: FC<TProps>;
  };
};

export const useBracketColumns = <TProps,>({
  rounds,
  useRenderColumns,
  columnComponents,
}: BracketColumnsProps<TProps>) => {
  const { Left, Right, Center } = columnComponents;

  const isMobile = useMobile();

  const { center, left, right } = useMemo(() => {
    return splitRounds(rounds);
  }, [rounds]);

  const renderMobileColumns = useRenderColumnsMobile({
    rounds,
    ColumnComponent: ColumnMobile,
    hasFinal: true,
  });
  const MobileColumns = useMemo(() => {
    return isMobile && renderMobileColumns();
  }, [isMobile, renderMobileColumns]);

  const renderLeftColumns = useRenderColumns({
    rounds: left,
    ColumnComponent: Left,
  });
  const LeftColumns = useMemo(() => {
    return !isMobile && renderLeftColumns();
  }, [isMobile, renderLeftColumns]);

  const renderCenterColumns = useRenderColumns({
    rounds: center,
    ColumnComponent: Center,
    hasFinal: true,
  });
  const CenterColumns = useMemo(() => {
    return !isMobile && renderCenterColumns();
  }, [isMobile, renderCenterColumns]);

  const renderRightColumns = useRenderColumns({
    rounds: right,
    ColumnComponent: Right,
  });
  const RightColumns = useMemo(() => {
    return !isMobile && renderRightColumns();
  }, [isMobile, renderRightColumns]);

  return { MobileColumns, LeftColumns, CenterColumns, RightColumns };
};
