import { ELEVATIONS_SOLID } from '../../elevations';
import { PALETTE } from '../../palette';
import { SHADOWS_HARD, SHADOWS_HARD_COLOR } from '../../shadows';

const BUTTON_PALETTE_COLORS = [
  'primary',
  'secondary',
  'warning',
  'error',
  'success',
  'info',
  'royal',
];

const buildContainedVariant = (color: string, direction: string) => {
  return {
    props: {
      variant: 'contained' as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: color as any,
      disabled: false,
    },
    style: {
      background: PALETTE[`${color}`][`${direction}`],
      boxShadow: SHADOWS_HARD[`${color}Elevation2`],
      '&:hover': {
        filter: 'brightness(110%)',
        boxShadow: SHADOWS_HARD[`${color}Elevation2`],
      },
      transition: '0.15s ease-out all',
    },
  };
};

const buildOutlinedVariant = (color: string) => {
  return {
    props: {
      variant: 'outlined' as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: color as any,
      disabled: false,
    },
    style: {
      lineHeight: 0,
      border: 'double 1px transparent',
      borderRadius: '5px',
      backgroundImage: `linear-gradient(${ELEVATIONS_SOLID['8']}, ${
        ELEVATIONS_SOLID['8']
      }), ${PALETTE[`${color}`].vertical}`,
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      boxShadow: SHADOWS_HARD[`${color}Elevation1`],
      //backdropFilter: BACKDROP_FILTERS[0],
      padding: '0px',
      '&:hover': {
        boxShadow: `0px 3px 0.5px ${SHADOWS_HARD_COLOR[`${color}`]}`,
        transform: 'translateY(-1px)',
      },
      transition: '0.15s ease-out all',
    },
  };
};

export const BUTTON_VARIANTS_CONTAINED = [
  ...BUTTON_PALETTE_COLORS.map((color) => {
    return buildContainedVariant(color, 'horizontal');
  }),
];

export const BUTTON_VARIANTS_OUTLINED = BUTTON_PALETTE_COLORS.map((color) => {
  return buildOutlinedVariant(color);
});
