// ColumnWrapper.tsx
import { ReactNode, FC, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { MatchBracket } from '../../match/MatchBracket';
import { MatchAggregated } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { WayfinderWrapper } from '../../match/WayfinderWrapper';

export type ColumnLoserProps = {
  Pairs: [ReactNode, ReactNode][];
};

export type ColumnWrapperLoserProps = {
  matchPairs: [MatchAggregated<Date>, MatchAggregated<Date>][];
  ColumnComponent: FC<ColumnLoserProps>;
  hasFinal?: boolean;
};

const ColumnWrapperLoserUnmemoized: FC<ColumnWrapperLoserProps> = ({
  matchPairs,
  ColumnComponent,
  hasFinal,
}) => {
  const Pairs = useMemo(() => {
    return matchPairs.map(([matchFirst, matchSecond]) => {
      const isFinal = hasFinal && matchPairs.length === 1;
      return [
        <WayfinderWrapper key={matchFirst.id} match={matchFirst}>
          <MatchBracket match={matchFirst} />
        </WayfinderWrapper>,
        <WayfinderWrapper
          key={matchSecond.id}
          match={matchSecond}
          isFinal={isFinal}
        >
          <MatchBracket match={matchSecond} isLarge={isFinal} />
        </WayfinderWrapper>,
      ] as [ReactNode, ReactNode];
    });
  }, [matchPairs, hasFinal]);

  return <ColumnComponent key={matchPairs[0][0].id} Pairs={Pairs} />;
};

export const ColumnWrapperLoser = memo(ColumnWrapperLoserUnmemoized);
