import { useMemo, FC } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { normalizeTab } from '../../util/normalizeTab';
import { TabsVariantProps } from './TabsRouted';
import { memo } from '../../util/memo';

export const BUTTON_MAX_WIDTH = 304;

const TabsButtonGroupUnmemoized: FC<TabsVariantProps> = ({
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const tabsNormalized = useMemo(() => {
    return tabs.map(normalizeTab);
  }, [tabs]);

  const tabsRendered = useMemo(() => {
    return (
      <ToggleButtonGroup
        value={activeTab}
        exclusive
        onChange={routeTab}
        size="small"
        sx={{
          width: '100%',
          maxWidth: `calc(${tabsNormalized.length} * ${BUTTON_MAX_WIDTH}px)`,
        }}
      >
        {tabsNormalized.map(({ id, value, sx: sxTab, customization }) => {
          return (
            <ToggleButton
              id={id}
              key={value}
              value={value}
              sx={{
                ...sxTab,
                textTransform: 'uppercase',
                width: '100%',
                maxWidth: BUTTON_MAX_WIDTH,
              }}
              {...customization}
            >
              {customization?.label || value}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  }, [activeTab, routeTab, tabsNormalized]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {tabsRendered}
    </Box>
  );
};
export const TabsButtonGroup = memo(TabsButtonGroupUnmemoized);
