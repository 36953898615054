import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { useTheme, SxProps } from '@mui/material/styles';
import { FC } from 'react';
import { CALENDAR_DAY_WIDTH } from './CalendarDay';

export type HighlightWrapperProps = {
  sx?: SxProps;
  children?: React.ReactNode;
};

const HighlightWrapperUnmemoized: FC<HighlightWrapperProps> = ({
  sx,
  children,
}) => {
  const theme = useTheme();

  const defaultSx = {
    width: `${CALENDAR_DAY_WIDTH + 12}px`,
    backgroundColor: theme.palette.background.elevation[9],
    paddingLeft: '4px',
    ml: '-4px',
    ...sx,
  };

  return <Stack sx={defaultSx}>{children}</Stack>;
};

export const HighlightWrapper = memo(HighlightWrapperUnmemoized);
