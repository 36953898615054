import { useChatContext as useStreamChatContext } from 'stream-chat-react';

export const useMuteStatus = () => {
  const { mutes } = useStreamChatContext();

  const calculateMuteStatus = (created_at: Date, userId?: string) => {
    if (!userId) {
      return false;
    }
    const muteFound = mutes?.find(({ target }) => {
      return target.id === userId;
    });

    if (!muteFound) {
      return false;
    }
    const muteDate = new Date(muteFound.created_at);
    return created_at > muteDate;
  };

  return { calculateMuteStatus };
};
