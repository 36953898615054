import { DialogCentered } from '../DialogCentered';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { memo } from '../../util/memo';
import { useAuth } from '../../contexts/AuthContext';
import { useAuthFlowDialog } from '../../hooks/auth/useAuthFlowDialog';
import { DialogBodyStandard } from '../dialog/DialogBodyStandard';
import {
  DialogActionsStandard,
  SyncronousActionButtonProps,
} from '../dialog/DialogActionsStandard';

export const GUARD_DIALOG_PAPEP_PROPS = {
  width: 'auto',
  minWidth: '300px',
};
export const LOG_IN_BUTTON_DESCRIPTION = 'Sign Up / Sign In';
export type SignInGuardProps = {
  open: boolean;
  onClose?: () => void;
  onCloseAuth?: () => void;
  title: string;
};

export const SignInGuard = memo(function SignInGuardUnmemoized({
  onClose,
  onCloseAuth,
  open: openExternal,
  title,
}: SignInGuardProps) {
  const [open, setOpen] = useState(false);
  const { uid } = useAuth();
  const { open: openAuthDialog } = useAuthFlowDialog();

  useEffect(() => {
    setOpen(openExternal);
  }, [openExternal]);

  useEffect(() => {
    if (uid) {
      onClose?.();
    }
  }, [uid, onClose]);

  const goToAuth = useCallback(() => {
    openAuthDialog({ onClose: onCloseAuth });
    setOpen(false);
    onClose?.();
  }, [onClose, onCloseAuth, openAuthDialog]);

  const buttons = useMemo(() => {
    return [
      {
        isAsync: false,
        color: 'primary' as const,
        startIcon: <AccountBalanceWalletOutlinedIcon />,
        onClick: goToAuth,
        children: LOG_IN_BUTTON_DESCRIPTION,
      } as SyncronousActionButtonProps,
    ];
  }, [goToAuth]);

  return (
    <DialogCentered
      open={open}
      onClose={onClose}
      PaperProps={GUARD_DIALOG_PAPEP_PROPS}
    >
      <DialogBodyStandard title={title.toUpperCase()}>
        <DialogActionsStandard buttons={buttons} />
      </DialogBodyStandard>
    </DialogCentered>
  );
});
