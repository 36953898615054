/**
 * The following is necessary because we only define wagmiCoreModule from useWeb3() once we have
 * initialized wagmi & web3 modal appropriately. Not only once we've finished importing wagmiCoreModule.
 * Otherwise, we would more simply await import('@wagmi/core') and execute the desired method within the same
 * async function.
 */
import { useMemo } from 'react';
import { useWeb3 } from '../../contexts/web3/Web3Context';
import type { AsyncWrapper, FuncKeys } from 'functions/src/types/utility-types';

export type WagmiCoreModule =
  typeof import('/workspace/agora/node_modules/@wagmi/core/dist/index');

export type WagmiFuncKey = FuncKeys<WagmiCoreModule>;

export type UseWagmiFunc = <TFuncName extends WagmiFuncKey>(
  funcName: TFuncName,
) => AsyncWrapper<WagmiCoreModule[TFuncName]>;

export const useWagmiFunc: UseWagmiFunc = <TFuncName extends WagmiFuncKey>(
  funcName: TFuncName,
) => {
  const { wagmiCoreModule, onFinishImport } = useWeb3();

  const funcAwaiting = useMemo((): AsyncWrapper<WagmiCoreModule[TFuncName]> => {
    if (!wagmiCoreModule || !(funcName in wagmiCoreModule)) {
      return (...params: Parameters<WagmiCoreModule[TFuncName]>) => {
        return new Promise<Awaited<ReturnType<WagmiCoreModule[TFuncName]>>>(
          (resolve, reject) => {
            onFinishImport(
              async ({ wagmiCoreModule: wagmiCoreModuleImported }) => {
                try {
                  // eslint-disable-next-line security/detect-object-injection, @typescript-eslint/no-non-null-assertion
                  const func = wagmiCoreModuleImported![funcName] as (
                    ...args: unknown[]
                  ) => ReturnType<WagmiCoreModule[TFuncName]>;
                  const result = await func(...params);
                  resolve(result);
                } catch (error) {
                  reject(error);
                }
              },
            );
          },
        );
      };
    }
    // eslint-disable-next-line require-await
    return async (...params: Parameters<WagmiCoreModule[TFuncName]>) => {
      // eslint-disable-next-line security/detect-object-injection
      const func = wagmiCoreModule[funcName] as (
        ...args: unknown[]
      ) => ReturnType<WagmiCoreModule[TFuncName]>;
      return await func(...params);
    };
  }, [wagmiCoreModule, funcName, onFinishImport]);

  return funcAwaiting;
};
