import {
  DialogBodyStandard,
  DialogBodyStandardProps,
} from './DialogBodyStandard';
import { DialogActionsStandard } from './DialogActionsStandard';
import { memo } from '../../util/memo';

export type DialogBodyConfirmationProps = DialogBodyStandardProps & {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

export const DialogBodyConfirmationUnmemoized = ({
  title,
  description,
  children,
  onCancel,
  onConfirm,
}: DialogBodyConfirmationProps) => {
  return (
    <DialogBodyStandard title={title} description={description}>
      {children}
      <DialogActionsStandard
        buttons={[
          {
            children: 'Confirm',
            isAsync: true,
            onClick: onConfirm,
            color: 'secondary',
          },
          {
            children: 'Cancel',
            color: 'error',
            isAsync: false,
            onClick: onCancel,
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const DialogBodyConfirmation = memo(DialogBodyConfirmationUnmemoized);
