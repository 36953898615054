import { useDialog } from '../useDialog';
import { useMessage } from '../../components/messaging/message/MessageContext';
import { ActionButton } from '../../components/messaging/actions/ActionButton';

export const useDeleteMessage = () => {
  const { open: openDialog, close: closeDialog } = useDialog('delete-message');
  const { handleDelete: streamDeleteMessage } = useMessage();
  const deleteMessage = () => {
    openDialog({
      title: 'Delete Message',
      description: 'Are you sure you want to delete the selected message?',
      children: (
        <ActionButton
          label="Delete"
          onClick={async (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => {
            await streamDeleteMessage(event);
            closeDialog();
          }}
        />
      ),
    });
  };

  return { deleteMessage };
};
