import { useCallback, useMemo, useState } from 'react';
import { AllSignInMethod } from '../../../functions/src/types/firestore/User';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { FieldContainer } from '../../components/tournaments/registration/FieldContainer';
import { SelectableAuthOptions } from '../../components/tournaments/registration/SelectableAuthOptions';
import { formatConnectedProviders } from '../../util/tournaments/formatConnectedProviders';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { autoOptionsOf } from '../../../functions/src/util/tournaments/autoOptionsOf';
import { useAuthProviders } from '../auth/useAuthProviders';

export const useSelectConnectedProviders = () => {
  const { providersConnected = [] } = useAuthProviders();
  const { registrationOptions } = useTournamentRegistration();
  const autoOptions = useMemo(() => {
    return autoOptionsOf(registrationOptions);
  }, [registrationOptions]);

  const firstOptionSelected = useMemo(() => {
    const firstOptionProviders = autoOptions[0]?.providers;
    if (firstOptionProviders === 'fetched') {
      return;
    }
    const firstProvider = providersConnected.find(({ providerId }) => {
      return firstOptionProviders?.includes(providerId as AllSignInMethod);
    });
    return firstProvider
      ? {
          [firstProvider.providerId]: {
            providerId: firstProvider.providerId,
            uid: firstProvider.uid,
          },
        }
      : undefined;
  }, [autoOptions, providersConnected]);

  const [authProviderDetails, setAuthProviderDetails] = useState<
    Record<string, { providerId: string; uid: string }> | undefined
  >(firstOptionSelected);

  const selectAuthProvider = useCallback(
    (providerId: string, uid: string, optionId: string) => {
      setAuthProviderDetails((prev) => {
        if (
          prev &&
          prev[String(optionId)]?.providerId === providerId &&
          prev[String(optionId)]?.uid === uid
        ) {
          const { [optionId]: _, ...updatedPrev } = prev;
          return updatedPrev;
        }
        return { ...prev, [optionId]: { providerId, uid } };
      });
    },
    [],
  );

  const checkSelected = useCallback(
    (providerId: string, uid: string, optionId: string) => {
      return (
        authProviderDetails?.[String(optionId)]?.providerId ===
          extractProviderId(providerId) &&
        authProviderDetails?.[String(optionId)]?.uid === uid
      );
    },
    [authProviderDetails],
  );

  const connectedProviders = useMemo(() => {
    return autoOptions.reduce((acc, option) => {
      const currentlyConnected = providersConnected.filter(({ providerId }) => {
        return option.providers.includes(
          extractProviderId(providerId) as AllSignInMethod,
        );
      });
      acc[option.id] = currentlyConnected;
      return acc;
    }, {} as Record<string, typeof providersConnected>);
  }, [autoOptions, providersConnected]);

  const AuthProviders = useMemo(() => {
    if (!autoOptions.length) {
      return;
    }
    return autoOptions.map((option) => {
      const { id, postambleLink, preamble, postambleText, providers } = option;
      const currentlyConnected = connectedProviders[String(id)] || [];
      return (
        <FieldContainer
          key={id}
          preamble={preamble}
          postambleLink={postambleLink}
          postambleText={postambleText}
        >
          <SelectableAuthOptions
            currentlyConnected={currentlyConnected}
            providers={providers as AllSignInMethod[]}
            selectAuthProvider={(providerId, uid) => {
              return selectAuthProvider(providerId, uid, id);
            }}
            checkSelected={(providerId, uid) => {
              return checkSelected(providerId, uid, id);
            }}
          />
        </FieldContainer>
      );
    });
  }, [autoOptions, checkSelected, connectedProviders, selectAuthProvider]);

  const selectedAuthProviders = useMemo(() => {
    if (!authProviderDetails) {
      return;
    }
    const selectedUids = Object.values(authProviderDetails).map(({ uid }) => {
      return uid;
    });
    return formatConnectedProviders(providersConnected, selectedUids);
  }, [authProviderDetails, providersConnected]);

  const isAuthSubmittable = useMemo(() => {
    if (!AuthProviders?.length) {
      return true;
    }
    return (
      selectedAuthProviders && Object.keys(selectedAuthProviders).length > 0
    );
  }, [AuthProviders?.length, selectedAuthProviders]);

  return { AuthProviders, selectedAuthProviders, isAuthSubmittable };
};
