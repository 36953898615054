import { memo } from '../../../util/memo';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useTheme } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import { FC } from 'react';

export type RelationshipChipProps = ChipProps;

const RelationshipChipUnmemoized: FC<RelationshipChipProps> = ({
  label,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Chip
      label={
        <GradientTypography variant="subtitle1">{label}</GradientTypography>
      }
      sx={{
        backgroundColor: theme.palette.background.elevation[14],
        width: '100px',
        height: '42px',
        '.MuiChip-label': {
          padding: 0,
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.action.hover,
        },
      }}
      {...props}
    />
  );
};

export const RelationshipChip = memo(RelationshipChipUnmemoized);
