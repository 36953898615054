import { memo } from '../../util/memo';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { GradientIcon } from './GradientIcon';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';

const colorToGradient = {
  primary: 'primary.vertical',
  primaryHorizontal: 'primary.horizontal',
  primaryHorizontalInverse: 'primary.horizontalInverse',
  secondary: 'secondary.vertical',
  error: 'error.verticalInverse',
  warning: 'warning.vertical',
  info: 'info.vertical',
  success: 'success.verticalInverse',
};

type ExpandedGradientCheckboxProps = {
  checkboxProps: CheckboxProps;
  color: keyof typeof colorToGradient;
};

// This is just a clone of GradientCheckbox that has been adapted to support more colors
// in colorToGradient to have more control over direction
export const ExpandedGradientCheckbox = memo(
  function ExpandedGradientCheckboxUnmemoized({
    checkboxProps,
    color = 'primary',
  }: ExpandedGradientCheckboxProps) {
    const gradientColor = colorToGradient[`${color}`];
    return (
      <Checkbox
        {...checkboxProps}
        size={checkboxProps.size}
        sx={{ mt: '-6px' }}
        icon={
          <GradientIcon
            gradientColor={gradientColor}
            IconComponent={CheckBoxOutlineBlankOutlinedIcon}
          />
        }
        checkedIcon={
          <GradientIcon
            gradientColor={gradientColor}
            IconComponent={CheckBoxIcon}
          />
        }
        indeterminateIcon={
          <GradientIcon
            gradientColor={gradientColor}
            IconComponent={IndeterminateCheckBoxIcon}
          />
        }
      />
    );
  },
);
