export const SQUARE_300_AD_INJECTION_CONFIG = {
  adInterval: 4,
  width: 300,
  height: 250,
} as const;

export const SQUARE_336_AD_INJECTION_CONFIG = {
  adInterval: 4,
  width: 336,
  height: 280,
} as const;

export const SHORT_VERTICAL_AD_INJECTION_CONFIG = {
  adInterval: 4,
  width: '100%',
  height: 100,
} as const;
