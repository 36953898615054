import { memo } from '../../util/memo';
import Menu from '@mui/material/Menu';
import { SignInMenuItem } from './SignInMenuItem';
import { useSignInItems } from '../../hooks/useSignInItems';
import { useMemo } from 'react';

export type SignInMenuProps = {
  open: boolean;
  anchorEl: Element;
  onMenuClose: () => void;
};

export const SignInMenu = memo(function SignInMenuUnmemoized({
  open,
  anchorEl,
  onMenuClose,
}: SignInMenuProps) {
  const menuItems = useSignInItems(onMenuClose);

  const menuItemElements = useMemo(() => {
    return menuItems.map((item) => {
      const { label, IconComponent } = item;
      return (
        <SignInMenuItem key={`${label}-${IconComponent?.name}`} {...item} />
      );
    });
  }, []);

  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onMenuClose} sx={{ mt: 2 }}>
      {menuItemElements}
    </Menu>
  );
});
