import { AdUnitMap } from './AspectRatio';

export const AD_UNIT_IDS_STICKY: AdUnitMap = {
  '320x100': '/22181265/blumint_sticky_footer',
  '120x600': '/22181265/blumint_sticky_leftrail',
} as const;

export const AD_UNIT_IDS_STICKY_MOBILE: AdUnitMap = {
  '320x50': '/22181265/blumint_mob_stickyfooter',
} as const;

export const AD_UNIT_IDS: AdUnitMap = {
  '970x90': '/22181265/blumint_970v_1',
  '728x90': '/22181265/blumint_728v_1',
  '300x600': '/22181265/blumint_300_600v_1',
  '336x280': '/22181265/blumint_336v_1',
  '300x250': '/22181265/blumint_300v_1',
  '320x100': '/22181265/blumint_320v_1',
  '320x50': '/22181265/blumint_320_50v_1',
  '300x100': '/22181265/blumint_300_100v_1',
  '300x50': '/22181265/blumint_300_50v_1',
  '120x600': '/22181265/blumint_sticky_120_600',
  '160x600': '/22181265/blumint_sticky_160-600',
} as const;

export const AD_UNIT_IDS_MOBILE: AdUnitMap = {
  '336x280': '/22181265/blumint_mob_336v_1',
  '300x250': '/22181265/blumint_mob_300v_1',
  '320x100': '/22181265/blumint_mob_320_100v_1',
  '300x100': '/22181265/blumint_mob_300_100v_1',
  '320x50': '/22181265/blumint_mob_320_50v_1',
  '300x50': '/22181265/blumint_mob_300_50v_1',
} as const;
