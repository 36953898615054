import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import { UploadedBy } from './UploadedBy';
import { ScreenshotImageHoverProps } from './ScreenshotImageHover';
import { ScreenshotActionButtons } from './ScreenshotActionButtons';

export type ScreenshotHoverButtons = Pick<
  ScreenshotImageHoverProps,
  'screenshotUrl'
> & {
  uploaderUid: string;
};

const ScreenshotHoverButtonsUnmemoized = ({
  uploaderUid,
  screenshotUrl,
}: ScreenshotHoverButtons) => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        top: 0,
        bottom: 0,
        right: 0,
        p: 1,
      }}
    >
      <ScreenshotActionButtons
        uploaderUid={uploaderUid}
        screenshotUrl={screenshotUrl}
      />
      <UploadedBy uploaderUid={uploaderUid} />
    </Stack>
  );
};

export const ScreenshotHoverButtons = memo(ScreenshotHoverButtonsUnmemoized);
