import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';

export const useUpdatePhoneNumber = () => {
  const { uid } = useAuth();
  const updatePhoneNumber = useCallback(
    async (phoneNumber: string) => {
      if (!uid) {
        return;
      }
      const { updateUserPhoneNumber } = await import(
        '../../firebaseCloud/user/updateUserPhoneNumber'
      );
      await updateUserPhoneNumber({ phoneNumber });
    },
    [uid],
  );
  return { updatePhoneNumber };
};
