import { Loadable, isLoading } from '../../util/isLoading';

type Anonymousable = Loadable<{ isAnonymous?: boolean }>;
export function onlyIdentified<TVal extends Anonymousable>(value: TVal) {
  if (isLoading(value)) {
    return undefined;
  }
  if (value?.isAnonymous) {
    return null;
  }
  return value;
}
