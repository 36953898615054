import { memo } from '../../util/memo';
export type CSSLinearGradient =
  `linear-gradient(${number}deg, ${string} ${number}%, ${string} ${number}%)`;

export type LinearGradientProps = {
  gradient: string;
  id: string;
};

const STOP_COLORS_REGEX = /#[0-9a-f]{6}/gi;
const OFFSET_REGEX = /-*([0-9]*)(\.)*([0-9]*)%/gi;
const ROTATION_REGEX = /[0-9]+/gi;

/* This resulting gradient may be retrieved in the DOM via url(#${gradientName})
 */
export const LinearGradient = memo(function LinearGradientUnmemoized({
  gradient,
  id,
}: LinearGradientProps) {
  const existingGradient = document.getElementById(gradient);
  if (existingGradient) {
    //console.error(`${gradient} already generated in the DOM.`);
    return <></>;
  }

  const stopColors = gradient.match(STOP_COLORS_REGEX);
  if (!stopColors) {
    console.error(`${gradient} does not include valid constituent colors.`);
    return <></>;
  }

  const offsets = gradient.match(OFFSET_REGEX)?.map((offset) => {
    return Number(offset.substring(0, offset.length - 1)) / 100;
  });
  if (!offsets) {
    console.error(
      `${gradient} does not include a % offsets for each of its constituent colors.`,
    );
    return <></>;
  }

  const rotation = gradient
    .slice(16, 23)
    .match(ROTATION_REGEX)
    ?.map((rotation) => {
      return Number(rotation) * (Math.PI / 180) + Math.PI;
    })[0];
  if (!rotation) {
    console.error(`${gradient} does not include a proper deg rotation.`);
    return <></>;
  }

  return (
    <svg width="0" height="0">
      <defs>
        <linearGradient
          id={`${id}`}
          x2={`${Math.round(Math.sin(rotation) * 50 + 50)}%`}
          y2={`${Math.round(Math.cos(rotation) * 50 + 50)}%`}
          x1={`${Math.round(Math.sin(rotation + Math.PI) * 50 + 50)}%`}
          y1={`${Math.round(Math.cos(rotation + Math.PI) * 50 + 50)}%`}
        >
          <stop offset={offsets[0]} stopColor={stopColors[1]} />
          <stop offset={offsets[1]} stopColor={stopColors[0]} />
        </linearGradient>
      </defs>
    </svg>
  );
});
