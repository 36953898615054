export const BACKGROUND_URL_PARAM = 'blu-background-index';

export const BASE_URL = '/assets/images/mascots/clipped/';

export const AVATAR_NAMES = [
  'blubot-content.png',
  'blubot-tilted.png',
  'alienboy-content.png',
  'alienboy-tilted.png',
  'aliengirl-content.png',
  'aliengirl-tilted.png',
  'bunny-content.png',
  'bunny-tilted.png',
  'nanobots-content.png',
  'nanobots-tough.png',
];

export const MASCOT_BACKGROUNDS = [
  'linear-gradient(90deg, #4DC8FF -50%, #0064E9 200%)',
  'linear-gradient(90deg, #C999FF -33%, #661AFF 200%)',
  'linear-gradient(90deg, #FFAB31 -25%, #ED7103 150%)',
  'linear-gradient(90deg, #FE512B 0%, #b44444 200%)',
  'linear-gradient(90deg, #15DBCF -50%, #0DA690 150%)',
  'linear-gradient(90deg, #10bdff -5%, #8768b0 105%)',
  'linear-gradient(90deg, #bcc4ff -50%, #4c54b4 200%)',
  'linear-gradient(90deg, #ff7cb4 -5%, #d199ff 105%)',
  'linear-gradient(90deg, #dc45e3 -5%, #79ee4b 105%)',
  'linear-gradient(90deg, #ffb673 -5%, #57b7ff 105%)',
];

export const DEFAULT_AVATAR_URLS = AVATAR_NAMES.flatMap((avatarName) => {
  return Array.from({ length: MASCOT_BACKGROUNDS.length }, (_, index) => {
    return `${BASE_URL}${avatarName}?${BACKGROUND_URL_PARAM}=${index}`;
  });
});

export const randomAvatarUrl = () => {
  const indexRandom = Math.floor(Math.random() * DEFAULT_AVATAR_URLS.length);
  return DEFAULT_AVATAR_URLS[Number(indexRandom)]!;
};
