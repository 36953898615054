import { memo } from '../../util/memo';
import { SelectInput } from '../data-entry-ui/generic/SelectInput';
import { useMediaDeviceSelect } from '@livekit/components-react';
import { toDeviceIds } from '../../util/voice-chat/toDeviceIds';
import { toDeviceDisplayMap } from '../../util/voice-chat/toDeviceDisplayMap';
import { useCallback } from 'react';
import { useWizard } from '../wizard/Wizard';
import type { MediaPreferencesStore } from '../../hooks/voice-chat/useMediaPreferencesDialog';

export type AudioDeviceSelectProps = {
  kind: 'audioinput' | 'audiooutput';
  label: string;
  fieldName: string;
};

const AudioDeviceSelectUnmemoized = ({
  kind,
  label,
  fieldName,
}: AudioDeviceSelectProps) => {
  const { get } = useWizard<MediaPreferencesStore>();

  const { devices, activeDeviceId, setActiveMediaDevice } =
    useMediaDeviceSelect({ kind, room: get('room') });

  const setDevice = useCallback(
    async (updates: any) => {
      const deviceId = updates[0].value;
      if (deviceId !== activeDeviceId) {
        return await setActiveMediaDevice(deviceId);
      }
    },
    [activeDeviceId, setActiveMediaDevice],
  );

  const values = toDeviceIds(devices);
  const valueDisplayedMap = toDeviceDisplayMap(devices);

  return (
    <SelectInput
      label={label}
      fieldName={fieldName}
      values={values}
      value={activeDeviceId || 'default'}
      valueDisplayedMap={valueDisplayedMap}
      onChange={setDevice}
    />
  );
};

export const AudioDeviceSelect = memo(AudioDeviceSelectUnmemoized);
