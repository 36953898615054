//import { log } from '../../../util/log';

//@log
export class LinkedListUtil {
  public static tieCoalesce<T>(
    prev1: { next?: T; id: T },
    prev2: { next?: T; id: T },
    next: { previous1?: T; previous2?: T; id: T },
  ): void {
    next.previous1 = prev1.id;
    next.previous2 = prev2.id;
    prev1.next = next.id;
    prev2.next = next.id;
  }

  public static tieCoalesceMany<T>(
    prev: { next?: T; id: T },
    next: { previous?: T[]; id: T },
  ): void {
    next.previous = [...(next.previous || []), prev.id];
    prev.next = next.id;
  }

  public static tie<T>(
    previous: { next?: T; id: T },
    next: { previous?: T; id: T },
  ) {
    previous.next = next.id;
    next.previous = previous.id;
  }

  /**
   * @remarks assumes there will be a previous2 populated later
   */
  public static tieOptimistic<T>(
    previous: { next?: T; id: T },
    next: { previous1?: T; id: T },
  ) {
    previous.next = next.id;
    next.previous1 = previous.id;
  }
}
