import { FC, useMemo } from 'react';
import { InstantSearch, InstantSearchProps } from 'react-instantsearch';
import { prefixIndex } from '../../../functions/src/util/algolia/prefixIndex';
import { useAlgoliaClientCached } from '../../hooks/algolia/useAlgoliaClientCached';
import { memo } from '../../util/memo';
import algoliasearch from 'algoliasearch';

export type CachedInstantSearchProps = { caching?: boolean } & Omit<
  InstantSearchProps,
  'searchClient'
>;

const FUTURE = {
  preserveSharedStateOnUnmount: false,
} as const;

const CachedInstantSearchUnmemoized: FC<CachedInstantSearchProps> = ({
  indexName,
  caching = true,
  ...rest
}) => {
  const searchClientCached = useAlgoliaClientCached();
  const searchClient = useMemo(() => {
    return caching
      ? searchClientCached
      : algoliasearch(
          process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
          process.env.NEXT_PUBLIC_FRONTEND_ALGOLIA_API_KEY as string,
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caching]);

  const indexPrefixed = indexName ? prefixIndex(indexName) : undefined;

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexPrefixed}
      future={FUTURE}
      {...rest}
    />
  );
};

export const CachedInstantSearch = memo(CachedInstantSearchUnmemoized);
