/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, ReactNode, FC, useMemo } from 'react';
import { memo } from '../util/memo';
import { createContext, useContextSelector } from 'use-context-selector';

export type OptimisticValueContextType = {
  values: { [key: string]: any };
  setOptimisticValue: (key: string, value: any) => void;
};

const OptimisticValueContext = createContext<
  OptimisticValueContextType | undefined
>(undefined);

export const useOptimisticValue = <TValue,>(key: string) => {
  return useContextSelector(OptimisticValueContext, (contextInstance) => {
    if (!contextInstance) {
      throw new Error(
        'useOptimisticValue must be used within a OptimisticValueContext',
      );
    }
    return contextInstance.values[String(key)] as TValue;
  });
};

export const useSetOptimisticValue = () => {
  return useContextSelector(OptimisticValueContext, (contextInstance) => {
    if (!contextInstance) {
      throw new Error(
        'useSetOptimisticValue must be used within a OptimisticValueContext',
      );
    }
    return contextInstance.setOptimisticValue;
  });
};

export const OptimisticValueProviderUnmemoized: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [values, setValues] = useState<{
    [key: string]: any;
  }>({});

  const setOptimisticValue = useCallback((key: string, value: any) => {
    setValues((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  }, []);

  const providerValue = useMemo(() => {
    return { values, setOptimisticValue };
  }, [values, setOptimisticValue]);

  return (
    <OptimisticValueContext.Provider value={providerValue}>
      {children}
    </OptimisticValueContext.Provider>
  );
};

export const OptimisticValueProvider = memo(OptimisticValueProviderUnmemoized);
