import { MatchFactory } from './MatchFactory';
import { RoundFactory } from './RoundFactory';
import { Required } from 'utility-types';
import {
  MatchAggregated,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { LinkedListUtil } from './LinkedListUtil';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase-admin/firestore';
import { HttpsError } from '../../../util/errors/HttpsError';

// eslint-disable-next-line @blumintinc/blumint/class-methods-read-top-to-bottom
export abstract class EliminationTournament {
  protected constructor(
    protected readonly matchFactory: MatchFactory = new MatchFactory(),
    protected readonly roundFactory: RoundFactory = new RoundFactory(),
  ) {}

  public static tieRounds = <T = Timestamp>(
    rounds: Required<Round<T>, 'matches'>[],
  ): Required<Round<T>, 'matches'>[] => {
    const firstRound = rounds[0];
    if (!firstRound) {
      throw new HttpsError('internal', 'Initial round is not defined', rounds);
    }
    const firstRoundTied = { ...firstRound, next: uuidv4() };
    const newRounds = [firstRoundTied, ...rounds.slice(1, rounds.length)];
    return newRounds.map((round, i) => {
      if (i !== newRounds.length - 1) {
        LinkedListUtil.tie(round, newRounds[i + 1]!);
      }
      return round;
    });
  };

  protected get initialFactory(): MatchFactory {
    return new MatchFactory((team1, team2) => {
      const baseSettings = this.matchFactory.settings();
      const isBypass = (!team1 && !!team2) || (!!team1 && !team2);
      return isBypass
        ? {
            winner: team1 || team2,
            ...baseSettings,
          }
        : baseSettings;
    });
  }

  protected nextRound(
    round: Required<Round, 'matches'>,
    roundIndex?: number,
  ): Required<Round, 'matches'> {
    const matchesNext = this.nextMatches(round.matches, roundIndex);
    return this.roundFactory.build(matchesNext);
  }

  protected germinateBracket(
    initialRound: Required<Round, 'matches'>,
    roundCount: number,
  ) {
    const rounds = [initialRound];
    for (let i = 0; i < roundCount - 1; i++) {
      rounds.push(this.nextRound(rounds[Number(i)]!, i));
    }
    return EliminationTournament.tieRounds(rounds);
  }

  protected abstract nextMatches(
    matches: MatchAggregated[],
    roundIndex?: number,
  ): MatchAggregated[];
}
