import { Token } from '../../types/firestore/User/Payout';

export const hasSameContract = (existingToken: Token, token: Token) => {
  return (['address', 'identifier', 'chainId'] as const).every((key) => {
    return existingToken[`${key}`] === token[`${key}`];
  });
};

export const firstTokenIn = <T extends Token = Token>(
  tokens: T[],
  token: T,
): T | undefined => {
  return tokens.find((existingToken) => {
    return hasSameContract(existingToken, token);
  });
};

export const indexOfFirstTokenIn = <T extends Token = Token>(
  tokens: T[],
  token: T,
): number => {
  return tokens.findIndex((existingToken) => {
    return hasSameContract(existingToken, token);
  });
};
