import { Outcome } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';

export const PENDING_OUTCOME = '?';

export function outcomeOf(
  outcome?: Outcome,
  score?: number,
  scoringActive?: boolean,
) {
  if (!!score || score === 0) {
    return { outcomeFormatted: score };
  }
  if (scoringActive) {
    return { outcomeFormatted: PENDING_OUTCOME };
  }
  return {
    outcomeFormatted: format(outcome),
  };
}

function format(outcome?: Outcome) {
  return outcome?.slice(0, 1);
}
