import {
  ReactNode,
  CSSProperties,
  useMemo,
  useRef,
  useCallback,
  FC,
} from 'react';
import { SxProps, Stack } from '@mui/material';
import { useParentDimensions } from '../../hooks/useParentDimensions';
import { memo } from '../../util/memo';
import {
  SKYSCRAPER,
  WIDE_SKYSCRAPER,
  HALF_PAGE,
  MEDIUM_RECTANGLE,
} from '../../../functions/src/util/ads/AdDimension';
import { AdProps, Ad } from './Ad';

export type AdSideRailsProps = Omit<
  AdProps,
  'justifyContent' | 'width' | 'height'
> & {
  leftJustify?: CSSProperties['justifyContent'];
  rightJustify?: CSSProperties['justifyContent'];
  fillAdsFrom?: 'left' | 'right';
  children: ReactNode;
  childrenMaxWidth: number | `${number}px`;
  sx?: SxProps;
  pattern?: 'sticky' | 'tile' | 'one';
};

const SIDERAIL_GAP = 16;
const MIN_WIDTH_FOR_ADS = SKYSCRAPER.width + SIDERAIL_GAP;
const MIN_WIDTH_FOR_WIDE_SKYSCRAPER = WIDE_SKYSCRAPER.width + SIDERAIL_GAP;
const MIN_WIDTH_FOR_DUAL_SKYSCRAPERS = SKYSCRAPER.width * 2 + SIDERAIL_GAP * 2;
const MIN_WIDTH_FOR_DUAL_WIDE_SKYSCRAPERS =
  WIDE_SKYSCRAPER.width * 2 + SIDERAIL_GAP * 2;
const MIN_WIDTH_FOR_DUAL_HALF_PAGES = HALF_PAGE.width * 2 + SIDERAIL_GAP * 2;

const AdSideRailsUnmemoized: FC<AdSideRailsProps> = ({
  leftJustify = 'flex-end',
  rightJustify = 'flex-start',
  fillAdsFrom = 'right',
  children,
  childrenMaxWidth,
  sx,
  pattern = 'one',
  ...adProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const parentDimensions = useParentDimensions('100%', '100%', containerRef);

  const calculateAds = useCallback(
    (availableWidth: number) => {
      if (availableWidth < MIN_WIDTH_FOR_ADS) return {};
      if (availableWidth < MIN_WIDTH_FOR_WIDE_SKYSCRAPER)
        return { [fillAdsFrom]: SKYSCRAPER.width };
      if (availableWidth < MIN_WIDTH_FOR_DUAL_SKYSCRAPERS)
        return { [fillAdsFrom]: WIDE_SKYSCRAPER.width };
      if (availableWidth < MIN_WIDTH_FOR_DUAL_WIDE_SKYSCRAPERS)
        return { left: SKYSCRAPER.width, right: SKYSCRAPER.width };
      if (availableWidth < MIN_WIDTH_FOR_DUAL_HALF_PAGES)
        return { left: WIDE_SKYSCRAPER.width, right: WIDE_SKYSCRAPER.width };
      return { left: HALF_PAGE.width, right: HALF_PAGE.width };
    },
    [fillAdsFrom],
  );

  const { left, right } = useMemo(() => {
    const childrenWidth = childrenRef.current?.offsetWidth || 0;
    const availableWidth = parentDimensions.width - childrenWidth;
    return calculateAds(availableWidth);
  }, [parentDimensions.width, calculateAds]);

  const computeAdStyle = useCallback(
    (justifyContent: CSSProperties['justifyContent']) => {
      switch (pattern) {
        case 'sticky':
          return {
            justifyContent,
            position: 'sticky',
            top: 0,
            maxHeight: '100vh',
            overflowY: 'auto',
          } as SxProps;
        case 'tile':
        case 'one':
          return {
            justifyContent,
          } as SxProps;
      }
    },
    [pattern],
  );

  const leftAdStyle = useMemo(() => {
    return computeAdStyle(leftJustify);
  }, [computeAdStyle, leftJustify]);
  const rightAdStyle = useMemo(() => {
    return computeAdStyle(rightJustify);
  }, [computeAdStyle, rightJustify]);

  const renderAds = useCallback(
    (width: number, adSx: SxProps) => {
      if (pattern === 'one') {
        return <Ad {...adProps} width={width} height="100%" sx={adSx} />;
      }

      const adHeight =
        width === HALF_PAGE.width ? HALF_PAGE.height : SKYSCRAPER.height;
      const numberOfAds = Math.floor(parentDimensions.height / adHeight);

      const ads = Array.from({ length: numberOfAds }).map((_, index) => {
        return (
          <Ad
            key={`${index}-ad`}
            {...adProps}
            width={width}
            height={adHeight}
            sx={adSx}
          />
        );
      });

      const remainingHeight = parentDimensions.height - numberOfAds * adHeight;
      if (
        remainingHeight >= MEDIUM_RECTANGLE.height &&
        width === HALF_PAGE.width
      ) {
        ads.push(
          <Ad
            key="remainder-ad"
            {...adProps}
            {...MEDIUM_RECTANGLE}
            sx={adSx}
          />,
        );
      }

      return (
        <Stack direction="column" spacing={3}>
          {ads}
        </Stack>
      );
    },
    [pattern, parentDimensions.height, adProps],
  );

  const leftAds = useMemo(() => {
    if (!left) {
      return undefined;
    }
    return renderAds(left, leftAdStyle);
  }, [left, leftAdStyle, renderAds]);

  const rightAds = useMemo(() => {
    if (!right) {
      return undefined;
    }
    return renderAds(right, rightAdStyle);
  }, [right, rightAdStyle, renderAds]);

  return (
    <Stack
      ref={containerRef}
      direction="row"
      justifyContent="center"
      spacing={`${SIDERAIL_GAP}px`}
      sx={sx}
      height="100%"
    >
      {leftAds}
      <Stack
        ref={childrenRef}
        style={{ maxWidth: childrenMaxWidth, width: '100%' }}
      >
        {children}
      </Stack>
      {rightAds}
    </Stack>
  );
};

export const AdSideRails = memo(AdSideRailsUnmemoized);
