import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { getApp } from './app';

const app = getApp();

const database = getDatabase(app);

let connectedEmulator = false;
if (!connectedEmulator && process.env.NEXT_PUBLIC_EMULATOR_MODE === 'true') {
  connectDatabaseEmulator(database, 'localhost', 9000);
  connectedEmulator = true;
}

export { database };
