import { CustomSignInMethod } from 'functions/src/types/firestore/User';
import { oAuthRedirectUri } from '../../../functions/src/util/oauth/oAuthRedirectUri';
import { isOnApp } from '../isOnApp';

export const O_AUTH_INFO = ['authorization', 'access'] as const;
export type OAuthInfo = typeof O_AUTH_INFO[number];
export type AccessParams = {
  source: `${CustomSignInMethod}-oauth`;
  link?: (code: string) => Promise<void>;
};
export type AuthorizationParams = {
  url: URL;
} & Record<string, unknown>;

export const constructOAuthUrl = (
  base: URL,
  options: Record<string, unknown>,
  encode = true,
  provider?: CustomSignInMethod,
) => {
  const baseUrl = new URL(base.toString());

  for (const [key, value] of Object.entries(options)) {
    if (key !== 'baseUrl') {
      const encoded = !encode
        ? (value as string)
        : encodeURIComponent(value as string);
      baseUrl.searchParams.append(key, encoded);
    }
  }
  const redirectUri = oAuthRedirectUri(isOnApp(), provider);

  baseUrl.searchParams.append('redirect_uri', redirectUri);
  return baseUrl;
};

export const CUSTOM_OAUTH_INFO: Record<
  CustomSignInMethod,
  { authorization: AuthorizationParams; access: AccessParams }
> = {
  discord: {
    authorization: {
      response_type: 'code',
      client_id: process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID,
      scope: 'identify',
      url: constructOAuthUrl(
        new URL(`https://discord.com/oauth2/authorize`),
        {
          response_type: 'code',
          client_id: process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID,
          scope: 'identify',
        },
        true,
        'discord',
      ),
    },
    access: {
      source: 'discord-oauth',
    },
  },
  epic: {
    authorization: {
      response_type: 'code',
      client_id: process.env.NEXT_PUBLIC_EPIC_CLIENT_ID,
      scope: 'basic_profile',
      url: constructOAuthUrl(
        new URL(`https://www.epicgames.com/id/authorize`),
        {
          response_type: 'code',
          client_id: process.env.NEXT_PUBLIC_EPIC_CLIENT_ID,
          scope: 'basic_profile',
        },
        false,
        'epic',
      ),
    },
    access: {
      source: 'epic-oauth',
    },
  },
  twitch: {
    authorization: {
      response_type: 'code',
      client_id: process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID,
      scope: 'user:read:email',
      url: constructOAuthUrl(
        new URL(`https://id.twitch.tv/oauth2/authorize`),
        {
          response_type: 'code',
          client_id: process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID,
          scope: 'user:read:email',
        },
        false,
        'twitch',
      ),
    },
    access: {
      source: 'twitch-oauth',
    },
  },
  // fractal: {
  //   authorization: {
  //     response_type: 'code',
  //     client_id:
  //       getParentDomain().base === 'localhost'
  //         ? process.env.NEXT_PUBLIC_FRACTAL_CLIENT_ID_TEST
  //         : process.env.NEXT_PUBLIC_FRACTAL_CLIENT_ID,
  //     scope:
  //       'contact:read verification.basic:read verification.basic.details:read',
  //     url: constructOAuthUrl(
  //       'fractal',
  //       new URL(
  //         getParentDomain().base === 'localhost'
  //           ? `https://app.next.fractal.id/authorize`
  //           : `https://app.fractal.id/authorize`,
  //       ),
  //       {
  //         response_type: 'code',
  //         client_id:
  //           getParentDomain().base === 'localhost'
  //             ? process.env.NEXT_PUBLIC_FRACTAL_CLIENT_ID_TEST
  //             : process.env.NEXT_PUBLIC_FRACTAL_CLIENT_ID,
  //         scope: 'contact:read uid:read',
  //       },
  //       false,
  //     ),
  //   },
  //   access: {
  //     source: 'fractal-oauth',
  //   },
  // },
} as const;
