import { memo } from '../../../util/memo';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { PendingInvitesHeader } from './PendingInvitesHeader';
import { PendingInvitesBody } from './PendingInvitesBody';
import { useMemo } from 'react';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';

// TODO: reintroduce notifications
function PendingInvitesPanelUnmemoized() {
  const { pendingInvites } = useTournamentRegistration();

  const description = useMemo(() => {
    return !!pendingInvites?.length
      ? 'You’ve been invited to a team! See all your invites below:'
      : 'You have not been invited to a team yet. When you are, your invites will appear here.';
  }, [pendingInvites?.length]);

  return (
    <TournamentPanelV3
      title={<PendingInvitesHeader contentLength={pendingInvites?.length} />}
      description={description}
      wrapperSx={{ p: 4, maxWidth: MAX_TOURNAMENT_PANE_WIDTH }}
      Content={<PendingInvitesBody />}
    />
  );
}

export const PendingInvitesPanel = memo(PendingInvitesPanelUnmemoized);

// {
//   /* TODO: Show once Tournament Alerts are ready. */
// }
// {
//   /* <SubscribeUpdatesButton
// {...{ notifyMe, isSubscribed, unsubscribe }}
// /> */
// }
