import { MS_PER_HOUR } from '../date/paramsUtc';
import { Utc, parseUtc } from './timezone';

export function offsetUtc(date: Date, utcOffset = 0) {
  return new Date(date.getTime() + utcOffset * MS_PER_HOUR);
}

export function offsetUtcFormatted(date: Date, utcFormatted: Utc = 'UTC+0') {
  return offsetUtc(date, parseUtc(utcFormatted));
}
