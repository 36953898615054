import { useMemo } from 'react';
import { useDynamic } from '../useDynamic';

export const useEditingConnectionSentinel = (
  documentPath?: string,
  userId?: string,
) => {
  const editableDocConnectorModule = useDynamic(
    import('../../util/realtime/EditableDocConnector'),
  );

  const connector = useMemo(() => {
    if (!documentPath || !userId || !editableDocConnectorModule) {
      return;
    }
    const { EditableDocConnector } = editableDocConnectorModule;
    return new EditableDocConnector(documentPath, userId);
  }, [documentPath, editableDocConnectorModule, userId]);
  return connector;
};
