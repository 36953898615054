/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/ban-types
type WindowAAw = typeof window & { aaw: { cmd: Function[] } };

const lazyInvokeAdapex = (method: string, args?: any[]): Promise<void> => {
  if ('aaw' in window && method in (window.aaw as any)) {
    if (args) {
      (window as any).aaw[method](...args);
    } else {
      (window as any).aaw[method]();
    }
    return Promise.resolve();
  }
  return new Promise<void>((resolve, reject) => {
    if (!('aaw' in window)) {
      (window as WindowAAw).aaw = { cmd: [] };
    }
    (window as WindowAAw).aaw.cmd.push(() => {
      try {
        if (args) {
          (window as any).aaw[method](...args);
        } else {
          (window as any).aaw[method]();
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  });
};

export class Adapex {
  static refresh = async () => {
    await lazyInvokeAdapex('refresh');
  };

  static refreshAdUnits = async (...adUnits: string[]) => {
    await lazyInvokeAdapex('refreshAdunits', [...adUnits]);
  };

  static refreshIds = async (...divIds: string[]) => {
    await lazyInvokeAdapex('refreshIds', [...divIds]);
  };

  static destroy = async () => {
    await lazyInvokeAdapex('destroy');
  };

  static destroyAdUnits = async (...adUnits: string[]) => {
    await lazyInvokeAdapex('destroyAdunits', [...adUnits]);
  };

  static destroyIds = async (...divIds: string[]) => {
    await lazyInvokeAdapex('destroyIds', [...divIds]);
  };

  static processAdsOnPage = async () => {
    await lazyInvokeAdapex('processAdsOnPage');
  };
}
