import {
  CUSTOM_SIGN_IN_METHODS,
  CustomSignInMethod,
} from '../../types/firestore/User';

export const isCustomOAuthProvider = (
  providerId: string,
): providerId is CustomSignInMethod => {
  return CUSTOM_SIGN_IN_METHODS.some((method) => {
    return providerId.includes(method);
  });
};
