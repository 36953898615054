import Box from '@mui/material/Box';
import { memo } from '../../util/memo';
import { LottieSpeech } from './LottieSpeech';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { RemoteParticipant, LocalParticipant } from 'livekit-client';
import { TrackReference } from '@livekit/components-core';
import { useIsMuted, useIsSpeaking } from '@livekit/components-react';

export type SpeakerOverlayProps = {
  audioTrack: TrackReference;
  participant: RemoteParticipant | LocalParticipant;
  children: ReactNode;
};

const SpeakerOverlayUnmemoized = ({
  audioTrack,
  participant,
  children,
}: SpeakerOverlayProps) => {
  const theme = useTheme();
  const hasDisabledMic = useIsMuted(audioTrack);
  const isSpeaking = useIsSpeaking(participant);

  const backgroundSx = {
    position: 'absolute',
    bottom: -3,
    borderRadius: '10px',
    background: theme.palette.background.shades.opacity60,
  };

  const speakerIcon = useMemo(() => {
    return isSpeaking ? (
      <Box
        sx={{
          right: -8,
          height: 16,
          width: 24,
          ...backgroundSx,
        }}
      >
        <LottieSpeech sx={{ height: 24, width: 24, mt: -1 }} />
      </Box>
    ) : hasDisabledMic ? (
      <Box
        sx={{
          right: -6,
          height: 22,
          width: 16,
          ...backgroundSx,
        }}
      >
        <MicOffIcon
          sx={{
            height: 16,
            width: 16,
            color: theme.palette.error.main,
          }}
        />
      </Box>
    ) : (
      <></>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpeaking, hasDisabledMic]);

  return (
    <Box position="relative">
      {children}
      {speakerIcon}
    </Box>
  );
};

export const SpeakerOverlay = memo(SpeakerOverlayUnmemoized);
