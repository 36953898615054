import { track } from '@vercel/analytics';
import { isOnApp } from '../isOnApp';

export const trackEvent = (
  name: string,
  properties?: Record<string, string | number | boolean | null>,
) => {
  track(name, {
    isOnApp: isOnApp(),
    ...properties,
  });
};
