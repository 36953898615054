import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { ReactNode, useState } from 'react';
import { memo } from '../../../../util/memo';
import CancelFilled from '@mui/icons-material/CancelRounded';
import { GradientCircularProgress } from 'src/components/gradients/GradientCircularProgress';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';
import { useMobile } from '../../../../hooks/useMobile';

export type WithCancelIconProps = {
  onRemove: () => Promise<void>;
  children?: ReactNode;
};

export const WithCancelIcon = memo(function WithCancelIconUnmemoized({
  onRemove,
  children,
}: WithCancelIconProps) {
  const isMobile = useMobile();
  const [isHovered, setHovered] = useState(isMobile);
  const [loading, asyncHandler] = useLoadingHandler(onRemove);

  return (
    <Box position="relative" display="inline-block">
      <Box
        onMouseEnter={() => {
          return setHovered(true);
        }}
        onMouseLeave={() => {
          return setHovered(false);
        }}
        position="relative"
        width="100%"
        height="100%"
      >
        {children}
        <Fade in={isHovered}>
          <Box
            position="absolute"
            top={'-8px'}
            right={'-8px'}
            onClick={asyncHandler}
            sx={{ cursor: 'pointer' }}
          >
            {loading ? (
              <GradientCircularProgress
                gradientColor="error.verticalInverse"
                sx={{ height: 40, width: 40 }}
              />
            ) : (
              <CancelFilled sx={{ width: 40, height: 40 }} color="error" />
            )}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
});
