import { RoleMap } from '../../types/Roles';

export const extractIds = <TRole extends string>(roles: RoleMap<TRole>) => {
  const roleIds = Object.values(roles) as string[][];
  return [
    ...new Set(
      roleIds.reduce((userIds, ids) => {
        return userIds.concat(ids ?? []);
      }, [] as string[]),
    ),
  ];
};
