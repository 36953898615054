import { useCallback } from 'react';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import { memo } from '../../util/memo';
import { useMatch } from '../tournaments/match/MatchProvider';
import { MatchAutomationControls } from './MatchAutomationControls';
import { MatchControlButtons } from './MatchControlButtons';
import { useMatchActions } from '../../hooks/useMatchActions';
import { extractTeamName } from '../../../functions/src/util/tournaments/extractTeamName';
import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';

export type MatchControlsProps = {
  teams: Team<Date>[];
  gameId: string;
  tournamentId: string;
  onClose: () => void;
};

export const MatchControls = memo(function MatchControlsUnmemoized({
  teams,
  gameId,
  tournamentId,
  onClose,
}: MatchControlsProps) {
  const { id: matchId, matchUrl } = useMatch();

  const { delayMatch, cancelMatch, recreateMatch, resetMatch } =
    useMatchActions({
      gameId,
      matchId,
      tournamentId,
    });

  const { open: openConfirmDialog, close: closeConfirmDialog } =
    useConfirmDialog(matchId);

  const confirm = useCallback(
    (action: 'delay' | 'cancel' | 'recreate' | 'reset') => {
      const matchup = teams.map(extractTeamName).join(' and ') + '?';

      const confirmActions = {
        delay: {
          title: 'Confirming delay',
          description: `Are you sure you want to delay the match between ${matchup}?`,
          confirmFunction: async () => {
            await delayMatch();
          },
        },
        cancel: {
          title: 'Confirming cancellation',
          description: `Are you sure you want to cancel the match between ${matchup}}?`,
          confirmFunction: async () => {
            await cancelMatch();
          },
        },
        recreate: {
          title: 'Recreating match',
          description: `Are you sure you want to recreate the match between ${matchup}? The existing url is ${matchUrl}.`,
          confirmFunction: async () => {
            await recreateMatch();
            closeConfirmDialog();
            onClose();
          },
        },
        reset: {
          title: 'Resetting match',
          description: `Are you sure you want to reset the match between ${matchup}?`,
          confirmFunction: async () => {
            await resetMatch();
            closeConfirmDialog();
            onClose();
          },
        },
      };

      // eslint-disable-next-line security/detect-object-injection
      return openConfirmDialog(confirmActions[action]);
    },
    [
      cancelMatch,
      closeConfirmDialog,
      delayMatch,
      matchUrl,
      onClose,
      openConfirmDialog,
      recreateMatch,
      resetMatch,
      teams,
    ],
  );

  return (
    <>
      <MatchControlButtons onConfirm={confirm} />
      {matchUrl && <MatchAutomationControls handleConfirm={confirm} />}
    </>
  );
});
