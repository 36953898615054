import algoliasearch from 'algoliasearch';
import { useMemo } from 'react';
import { createLocalStorageCache } from '../../../functions/src/util/algolia/instantsearch/createLocalStorageCache';
import { useLocalStorage } from '../../contexts/LocalStorage';

export const SECONDS_TO_LIVE = 60;

export const RESPONSE_CACHE_KEY = `response`;
export const REQUEST_CACHE_KEY = `request`;

export const TIMEOUTS_LONG = {
  connect: 30,
  read: 60,
  write: 60,
} as const;

export const useAlgoliaClientCached = () => {
  const localStorage = useLocalStorage();
  return useMemo(() => {
    return algoliasearch(
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      process.env.NEXT_PUBLIC_FRONTEND_ALGOLIA_API_KEY as string,
      {
        responsesCache: createLocalStorageCache({
          key: RESPONSE_CACHE_KEY,
          timeToLive: SECONDS_TO_LIVE,
          localStorage,
        }),
        requestsCache: createLocalStorageCache({
          key: REQUEST_CACHE_KEY,
          timeToLive: SECONDS_TO_LIVE,
          localStorage,
        }),
        timeouts: TIMEOUTS_LONG,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
