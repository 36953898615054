import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../../gradients/GradientTypography';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { useNumberFormatter } from 'src/hooks/useNumberFormatter';
import { FeaturedCompetitor } from 'src/components/leaderboards/FeaturedCompetitor';
import { Link } from 'src/components/Link';
import { useTheme } from '@mui/material/styles';
import { useMobile } from 'src/hooks/useMobile';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TeamDisplayTooltip } from './TeamDisplayTooltip';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Heat/Result';
import { formatTimeTaken } from './Score';
import { useCallback } from 'react';

export type HeatTop3Props = {
  top3Results: Omit<ResultRanked, 'userIds'>[];
  units?: string;
};

export const HeatTop3 = memo(function HeatTop3Unmemoized({
  top3Results,
  units = 'pts',
}: HeatTop3Props) {
  const theme = useTheme();
  const isMobile = useMobile();
  const formatterStandard = useNumberFormatter({
    notation: 'standard',
  });

  const format = useCallback(
    (score = 0): string => {
      if (units === 'time') {
        return formatTimeTaken(score);
      }
      return `${formatterStandard.format(score)} ${units?.toUpperCase()}`;
    },
    [units, formatterStandard],
  );
  const screenSizeDrop = useMediaQuery(theme.breakpoints.down(1650));

  const renderCompetitor = useCallback(
    ({
      imgUrl,
      name,
      payout,
      scores,
      rank,
      userIdCaptain,
      members,
    }: Omit<ResultRanked, 'userIds'>) => {
      const isSolo = !members || members.length === 1;
      const competitor = (
        <FeaturedCompetitor
          imgUrl={isSolo ? members?.[0].imgUrl : imgUrl}
          name={name}
          rank={rank}
          payout={payout}
          isSolo={isSolo}
          score={format(scores?.[0])}
        />
      );

      if (!isSolo) {
        return (
          <TeamDisplayTooltip
            key={`${userIdCaptain}-${rank}`}
            teamDisplayProps={{
              avatarUrl: imgUrl!,
              members: members!,
              name,
            }}
            sx={{ width: '100%' }}
          >
            {competitor}
          </TeamDisplayTooltip>
        );
      }

      if (!userIdCaptain || userIdCaptain === '') {
        return <div key={`${userIdCaptain}-${rank}`}>{competitor}</div>;
      }

      return (
        <Link
          key={`${userIdCaptain}-${rank}`}
          href={`/profile/${userIdCaptain}`}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          {competitor}
        </Link>
      );
    },
    [format],
  );

  return (
    <GradientBorder
      disableBorder
      borderRadius={'10px'}
      gradientBackground={`linear-gradient(${theme.palette.background.elevation[7]}, 
      ${theme.palette.background.elevation[7]})`}
    >
      <GradientTypography
        gradientColor="primary.vertical"
        variant="h6"
        sx={{ mt: 2, ml: 3 }}
      >
        TOP {top3Results.length}
      </GradientTypography>

      <Stack
        flexDirection="row"
        justifyContent={'center'}
        alignItems="center"
        flexWrap={isMobile || screenSizeDrop ? 'wrap' : 'inherit'}
        sx={{ px: 3, pb: 3 }}
      >
        {top3Results?.map(renderCompetitor)}
      </Stack>
    </GradientBorder>
  );
});
