import { RoundStepperProps } from 'src/components/tournaments/RoundStepper';
import { RoundKind } from 'src/components/tournaments/RoundStep';

export type CalculateRoundStepWidthParams = Pick<
  RoundStepperProps,
  'roundsCount'
> & {
  index: number;
  isMobile: boolean;
  roundStep: {
    kind: RoundKind;
  };
};

export function calculateRoundStepWidth({
  isMobile,
  roundStep,
}: CalculateRoundStepWidthParams) {
  if (isMobile) {
    return '100%';
  }

  const { kind } = roundStep;

  switch (kind) {
    case 'final':
      return 'auto';
    case 'loser':
      return '100px';
    default:
      return '212px';
  }
}
