import { CompetitorPosition } from './CompetitorPosition';
import { CompetitorInfo } from './CompetitorInfo';
import { CompetitorTime } from './CompetitorTime';
import { CompetitorCheckedIn } from './CompetitorCheckedIn';
import Stack from '@mui/material/Stack';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import { useCompetitor } from './AccordionCompetitor';
export type AccordionSummaryCompetitorProps = {
  LeftChildren?: ReactNode;
  RightChildren?: ReactNode;
} & AccordionSummaryProps;

const AccordionSummaryCompetitorUnmemoized: React.FC<
  AccordionSummaryCompetitorProps
> = ({ LeftChildren, RightChildren, sx, ...props }) => {
  const {
    waitlistPosition,
    teamImage,
    teamName,
    currentTeamFill,
    teamMax,
    participants,
    registrationTime,
    isSoloTeam,
  } = useCompetitor();
  const name = isSoloTeam ? participants[0].username : teamName;
  const teamCheckedIn = participants.every((participant) => {
    return participant.checkedIn;
  });

  return (
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
        backgroundColor: 'background.elevation.10',
        borderBottom: 'none',
        ...sx,
      }}
      {...props}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        flexWrap="wrap"
        rowGap={2}
        sx={{ width: '100%' }}
      >
        <Stack direction="row" spacing={2}>
          {!!waitlistPosition && (
            <CompetitorPosition position={waitlistPosition} />
          )}
          <CompetitorInfo
            avatarSrc={teamImage}
            name={name}
            countFill={currentTeamFill}
            countMax={teamMax}
            isSoloTeam={isSoloTeam}
          />
          {!!LeftChildren && LeftChildren}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          {!!RightChildren && RightChildren}
          <Stack sx={{ display: { xs: 'none', md: 'unset' } }}>
            {!!registrationTime && <CompetitorTime time={registrationTime} />}
          </Stack>
          {teamCheckedIn && <CompetitorCheckedIn />}
        </Stack>
      </Stack>
    </AccordionSummary>
  );
};

export const AccordionSummaryCompetitor = memo(
  AccordionSummaryCompetitorUnmemoized,
) as typeof AccordionSummaryCompetitorUnmemoized;
