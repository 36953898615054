import { useContext, createContext, FC, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { ParticipantProps } from '../Participant';
import { AccordionCompetitorSolo } from './AccordionCompetitorSolo';
import { AccordionCompetitorTeam } from './AccordionCompetitorTeam';

export type AccordionCompetitorProps = {
  entryId: string;
  isCheckedIn?: boolean;
  currentTeamFill: number;
  teamMax: number;
  teamName: string;
  teamImage: string;
  isSoloTeam: boolean;
  waitlistPosition?: number;
  participants: ParticipantProps[];
  registrationTime?: Date;
  isWaitlisted?: boolean;
};

export const CompetitorContext = createContext<AccordionCompetitorProps | null>(
  null,
);

export const useCompetitor = () => {
  const context = useContext(CompetitorContext);
  if (!context) {
    throw new Error('useCompetitor must be used within an AccordionCompetitor');
  }
  return context;
};

const AccordionCompetitorUnmemoized: FC<AccordionCompetitorProps> = (props) => {
  const { participants: participantsUnfiltered, isSoloTeam = false } = props;
  const participants = useMemo(() => {
    return participantsUnfiltered.filter((participant) => {
      return !!participant;
    });
  }, [participantsUnfiltered]); // TODO: Find root cause

  const body = useMemo(() => {
    return isSoloTeam ? (
      <AccordionCompetitorSolo />
    ) : (
      <AccordionCompetitorTeam />
    );
  }, [isSoloTeam]);

  return (
    <CompetitorContext.Provider value={{ ...props, participants }}>
      {body}
    </CompetitorContext.Provider>
  );
};

export const AccordionCompetitor = memo(AccordionCompetitorUnmemoized);
