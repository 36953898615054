import { useAuth } from '../../contexts/AuthContext';
import { extractGetStreamId } from '../../util/messaging/extractGetStreamId';

export const useGetStreamId = () => {
  const { userFull } = useAuth();
  const { uid, isAnonymous } = userFull || {};
  return extractGetStreamId({
    uid,
    isAnonymous,
  });
};
