import { ReactNode } from 'react';
import { ClipboardShare, ClipboardShareProps } from './ClipboardShare';
import {
  ShareButtonBase,
  ShareButtonBaseProps,
} from './buttons/ShareButtonBase';
import { memo } from '../util/memo';

export type ClipboardShareButtonProps = Pick<ClipboardShareProps, 'value'> & {
  children?: string | ReactNode;
  onClick?: () => void | Promise<void>;
} & Omit<ShareButtonBaseProps, 'children'>;

export const ClipboardShareButton = memo(
  function ClipboardShareButtonUnmemoized({
    value,
    children,
    onClick,
    guide,
  }: ClipboardShareButtonProps) {
    return (
      <ClipboardShare value={value} onClick={onClick}>
        <ShareButtonBase guide={guide}>{children}</ShareButtonBase>
      </ClipboardShare>
    );
  },
);
