import Chip from '@mui/material/Chip';
import { formatDateTime } from 'src/util/dates/Dates';
import { NUMERIC_TIMEZONE } from 'src/util/dates/presets/times';
import { NUMERIC } from 'src/util/dates/presets/dates';
import { memo } from '../util/memo';

export type ChipTimeProps = {
  time: Date;
};

const ChipTimeUnmemoized = ({ time }: ChipTimeProps) => {
  const startTimeFormatted = formatDateTime(time, {
    dateFormat: NUMERIC,
    timeFormat: NUMERIC_TIMEZONE,
    separator: ', ',
  });

  return <Chip label={startTimeFormatted} />;
};

export const ChipTime = memo(ChipTimeUnmemoized);
