export const TOURNAMENT_RNG_ABI = [
  {
    inputs: [],
    stateMutability: 'payable',
    type: 'constructor',
    payable: true,
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: '_nonce',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'string',
        name: '_tournamentId',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: '_seeds',
        type: 'uint256[]',
      },
    ],
    name: 'SeedGeneration',
    type: 'event',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'tournamentId',
        type: 'string',
      },
      {
        internalType: 'uint8',
        name: '_rngCount',
        type: 'uint8',
      },
    ],
    name: 'generateSeed',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'nonce',
        type: 'uint256',
      },
      {
        internalType: 'uint256[]',
        name: 'rngList',
        type: 'uint256[]',
      },
    ],
    name: 'rngCallback',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'tournamentId',
        type: 'string',
      },
    ],
    name: 'seedOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true,
  },
];
