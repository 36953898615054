import Stack from '@mui/material/Stack';
import { GradientTypography } from '../../gradients/GradientTypography';
import { isPreReady } from '../../../../functions/src/types/firestore/Game/Tournament/util';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
// import { ShareInviteButton } from '../ShareInviteButton';
import { ShareInviteButtonOld } from '../ShareInviteButtonOld';

export const TEAM_REGISTERED_TEXT = `YOUR TEAM IS REGISTERED!`;
export const TEAM_READY_TEXT = `YOUR TEAM IS READY!`;

export function RegisteredTeamPanelHeader() {
  const { phase } = useTournamentRegistration();

  return (
    <Stack flexDirection="row" justifyContent="space-between" width={'100%'}>
      <GradientTypography gradientColor="primary.vertical" variant="h5">
        {isPreReady(phase) ? TEAM_REGISTERED_TEXT : TEAM_READY_TEXT}
      </GradientTypography>
      {/* <ShareInviteButton /> Revert back once fixed*/}
      <ShareInviteButtonOld />
    </Stack>
  );
}
