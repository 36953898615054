import { UnreadMessageCountStructure } from '../contexts/UnreadMessageCountContext';

export type FindExistingUnreadCountParams = {
  unreadMessageCount: UnreadMessageCountStructure;
  groupId: string;
  cid: string;
};

export const findExistingUnreadCount = ({
  unreadMessageCount,
  groupId,
  cid,
}: FindExistingUnreadCountParams) => {
  const unreadCount = unreadMessageCount[groupId as string];
  return unreadCount?.channels[cid as string];
};
