import { useState, useCallback, useEffect } from 'react';
import { EditableStepsContextType } from '../../../contexts/organizer/EditableStepsContext';
import { GradientTypography } from '../../gradients/GradientTypography';
import { Sponsor } from '../../../../functions/src/types/firestore/Game/Competition';
import type { DocumentData } from 'firebase/firestore';
import type { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { SponsorsLayoutV3 } from '../../tournaments/SponsorsLayoutV3';
import { SPONSOR_PLACEHOLDER_NAME, SponsorInput } from './SponsorInput';
import { useMobile } from '../../../hooks/useMobile';

export const SPONSOR_IMAGES_TITLE = 'Brought to You By';

type SponsorsInputProps<T extends DocumentData = Tournament<Date>> = {
  value: Sponsor[];
  onChange: EditableStepsContextType<T>['updateField'];
  fieldName: string;
};

const SponsorsInputUnmemoized = ({
  value: items = [],
  onChange,
  fieldName,
}: SponsorsInputProps) => {
  const [localItems, setLocalItems] = useState<Sponsor[]>(items);
  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleDelete = useCallback(
    (index: number) => {
      setLocalItems((prev) => {
        return prev.filter((_, idx) => {
          return idx !== index;
        });
      });
      onChange([
        {
          field: fieldName,
          action: 'arrayDelete',
          value: { insert: undefined, index },
        },
      ]);
    },
    [fieldName, onChange],
  );

  const handleChange = useCallback(
    (index: number, newUrl: string) => {
      const updatedItems = [...items];
      // eslint-disable-next-line security/detect-object-injection
      updatedItems[index] = { ...updatedItems[index], imgUrl: newUrl };
      setLocalItems(updatedItems);
      onChange([
        {
          field: `${fieldName}.${index}`,
          action: 'overwrite',
          value: updatedItems[Number(index)],
        },
      ]);
    },
    [fieldName, items, onChange],
  );

  const isMobile = useMobile();
  return (
    <>
      <SponsorsLayoutV3
        // TODO: type errors
        SponsorComponent={SponsorInput}
        sponsors={
          localItems.length < 5
            ? [...localItems, { name: SPONSOR_PLACEHOLDER_NAME, imgUrl: '' }]
            : localItems
        }
        sponsorComponentProps={(index) => {
          return {
            // eslint-disable-next-line require-await
            onChange: async (url: string) => {
              return handleChange(index, url);
            },
            // eslint-disable-next-line require-await
            onRemove: async () => {
              return handleDelete(index);
            },
          };
        }}
        Title={
          !isMobile && (
            <GradientTypography
              gradientColor="primary.vertical"
              variant="h6"
              fontWeight={600}
              textTransform={'uppercase'}
            >
              {SPONSOR_IMAGES_TITLE}
            </GradientTypography>
          )
        }
      />
    </>
  );
};

export const SponsorsInput = SponsorsInputUnmemoized;
