import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { ChipUser } from '../../ChipUser';
import { useTheme } from '@mui/material/styles';

export type MemberListItemProps = {
  imgUrl: string;
  username: string;
  userId: string;
  onRemove: (toRemoveId: string) => void;
  isAdmin?: boolean;
};

const MemberListItemUnmemoized = ({
  imgUrl,
  username,
  userId,
  onRemove,
  isAdmin = false,
}: MemberListItemProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      mb={3}
      sx={{
        background: theme.palette.background.elevationSolid[8],
        borderRadius: '10px',
        height: '52px',
        width: '100%',
      }}
    >
      <ChipUser
        username={username}
        avatarUrl={imgUrl}
        href={`/profile/${userId}`}
      />
      <Stack direction="row" spacing={1}>
        {isAdmin ? (
          <Typography
            color="text.secondary"
            variant="subtitle2"
            sx={{ fontStyle: 'italic', pr: 2 }}
          >
            Admin
          </Typography>
        ) : (
          <GradientIconButton
            IconComponent={HighlightOffIcon}
            onClick={() => {
              onRemove(userId);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const MemberListItem = memo(MemberListItemUnmemoized);
