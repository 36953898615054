import { memo } from '../util/memo';
import Stack from '@mui/material/Stack';
import { formatDateTime } from 'src/util/dates/Dates';
import { UniversalAppStatus } from './error/UniversalAppStatus';
import Box from '@mui/material/Box';

export type MaintenceModeProps = {
  children: React.ReactNode;
};

export const MaintenanceMode = memo(function MaintenanceModeUnmemoized({
  children,
}: MaintenceModeProps) {
  const untilMillis = process.env.NEXT_PUBLIC_MAINTENANCE_UNTIL;
  if (!untilMillis || untilMillis === 'false') {
    // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
    return <>{children}</>;
  }

  const until =
    Number(untilMillis) > Date.now() && new Date(Number(untilMillis));
  const untilFormatted = until
    ? formatDateTime(until, { separator: '  ' })
    : 'soon';

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          px: 16,
          height: '100%',
          textAlign: 'center',
        }}
      >
        <UniversalAppStatus
          imgUrl="/assets/images/mascots/mascot-building.png"
          title="BluMint is under maintenance."
          description={`We'll be back ${untilFormatted} with new features!`}
          prefetchImage={true}
          showButton={false}
          subText={false}
        />
      </Stack>
    </Box>
  );
});
