import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';
import { ParticipantTeam } from '../../../types/firestore/Game/Tournament/Participant';
import { MatchFactory } from './MatchFactory';
import { CohortPairer } from './CohortPairer';

export const DEFAULT_COHORT_SIZE = 16 as const;

//@log
export class EliminationPairer {
  private readonly cohortSize: number;
  public constructor(
    private readonly participants: ParticipantTeam[],
    private readonly factory: MatchFactory,
    settings: {
      cohortSize?: number;
    } = {},
  ) {
    this.cohortSize = settings.cohortSize || DEFAULT_COHORT_SIZE;
  }

  public pair(): MatchAggregated[] {
    if (this.initialMatchesCount < 1) {
      return [];
    }

    const matchesPerCohort =
      2 ** Math.ceil(Math.log2(Math.max(...this.teamsPerCohort) / 2));

    return this.cohorts.flatMap((cohort) => {
      return CohortPairer.pair(cohort, matchesPerCohort, this.factory);
    });
  }

  private get teamsPerCohort() {
    const teamsCount = this.participants.length;
    const baseTeamsPerCohort = Math.floor(teamsCount / this.cohortsCount);
    const extraTeams = teamsCount % this.cohortsCount;
    return new Array(this.cohortsCount).fill('').map((_, i) => {
      return baseTeamsPerCohort + (i < extraTeams ? 1 : 0);
    });
  }

  private get cohorts() {
    let startIndex = 0;
    return this.teamsPerCohort.map((currentCohortSize) => {
      const endIndex = startIndex + currentCohortSize;
      const cohort = this.participants.slice(startIndex, endIndex);
      startIndex = endIndex;
      return cohort;
    });
  }

  public get cohortsCount(): number {
    return Math.floor(this.initialMatchesCount / this.cohortSize) || 1;
  }

  public get initialMatchesCount(): number {
    return 2 ** Math.ceil(Math.log2(this.participants.length / 2));
  }
}
