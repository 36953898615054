import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useCountViewers } from '../../../hooks/mux/useCountViewers';
import { useMemo } from 'react';
import { LiveBadge } from '../../LiveBadge';
import { UserContentOverlay } from '../../UserContentOverlay';
import { useTheme } from '@mui/material/styles';

export type ViewerCountProps = {
  isVisible?: boolean;
};

const ViewerCountUnmemoized = ({ isVisible = true }: ViewerCountProps) => {
  const { viewerCount, viewsTotal } = useCountViewers();
  const theme = useTheme();
  const viewsIndicator = useMemo(() => {
    return !!viewerCount ? (
      <VisibilityOutlinedIcon sx={{ height: '18px', mt: '2px' }} />
    ) : !!viewsTotal ? (
      <Typography>{`VIEW${viewsTotal !== 1 ? 'S' : ''}`}</Typography>
    ) : (
      <></>
    );
  }, [viewerCount, viewsTotal]);

  const count = useMemo(() => {
    return <Typography>{viewerCount || viewsTotal}</Typography>;
  }, [viewerCount, viewsTotal]);

  const liveBadge = useMemo(() => {
    return !!viewerCount && <LiveBadge />;
  }, [viewerCount]);

  return (
    <>
      {isVisible && (
        <UserContentOverlay
          direction="row"
          alignItems="center"
          sx={{
            pr: 2,
            background:
              !!viewerCount || !!viewsTotal
                ? theme.palette.background.shades.opacity60
                : 'transparent',
          }}
        >
          {liveBadge}
          <Stack
            alignItems="center"
            direction={!!viewerCount ? 'row' : 'row-reverse'}
            spacing={!!viewerCount ? 0 : 1}
            sx={{ height: '20px' }}
          >
            {viewsIndicator}
            {count}
          </Stack>
        </UserContentOverlay>
      )}
    </>
  );
};

export const ViewerCount = memo(ViewerCountUnmemoized);
