import {
  ReactNode,
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';
import { PrizePool } from '../../../functions/src/types/firestore/PrizePool';
import { Token } from '../../../functions/src/types/firestore/User/Payout';
import { useAggregatedTokens } from '../../hooks/prize-pool/useAggregatedTokens';
import {
  TokenAggregated,
  TokenAggregator,
} from '../../../functions/src/util/payouts/TokenAggregator';

const PrizePoolSelectionContext = createContext<
  PrizePoolSelectionContextType | undefined
>(undefined);

type TokenAggregatedWithAmounts = TokenAggregated & {
  amountInitial: bigint;
  amountUsed: bigint;
};

export type PrizePoolSelectionContextType = {
  prizePool?: PrizePool;
  prizesAggregatedWithAmounts: TokenAggregatedWithAmounts[];
  eventId?: string;
  selectToken: (token: Token) => void;
  selectedToken: Token | undefined;
  setTokenAmount: (amount: bigint) => void;
  removeFromSelection: () => void;
};

export type PrizePoolSelectionProviderProps = {
  children: ReactNode;
  prizePool?: PrizePool;
  payoutsCurrent: { tokens: Token[] }[];
  eventId?: string;
};

export const PrizePoolSelectionProvider = memo(
  function PrizePoolSelectionProviderUnmemoized({
    prizePool,
    payoutsCurrent = [],
    children,
    eventId,
  }: PrizePoolSelectionProviderProps) {
    const prizesAggregated = useAggregatedTokens(prizePool?.prizes || [], {
      withContributors: false,
    });

    const payoutTokensAggregated = useAggregatedTokens(
      payoutsCurrent.flatMap((payout) => {
        return payout.tokens;
      }),
      { withContributors: false },
    );
    const tokenKeyToAmountUsed = useMemo(() => {
      return payoutTokensAggregated.reduce((acc, curr) => {
        const key = TokenAggregator.tokenKeyOf(curr);
        acc[key] = `${(acc[key] || BigInt(0)) + BigInt(curr.amount)}`;
        return acc;
      }, {});
    }, [payoutTokensAggregated]);

    const prizesAggregatedWithAmounts = useMemo(() => {
      return prizesAggregated.map((prize) => {
        const key = TokenAggregator.tokenKeyOf(prize);
        const amountInitial = BigInt(prize.amount);
        const amountUsed = BigInt(tokenKeyToAmountUsed?.[key] || '0');
        return {
          ...prize,
          amount: `${amountInitial - amountUsed}`,
        } as TokenAggregatedWithAmounts;
      });
    }, [prizesAggregated, tokenKeyToAmountUsed]);

    const [selectedToken, setSelectedToken] = useState<Token | undefined>();

    const selectToken = useCallback((token: Token) => {
      setSelectedToken(token);
    }, []);

    const setTokenAmount = useCallback((amount: bigint) => {
      setSelectedToken((prev) => {
        return prev ? { ...prev, amount: amount.toString() } : undefined;
      });
    }, []);

    const removeFromSelection = useCallback(() => {
      setSelectedToken(undefined);
    }, []);

    return (
      <PrizePoolSelectionContext.Provider
        value={{
          prizePool,
          prizesAggregatedWithAmounts,
          eventId,
          selectToken,
          selectedToken,
          setTokenAmount,
          removeFromSelection,
        }}
      >
        {children}
      </PrizePoolSelectionContext.Provider>
    );
  },
);

export const usePrizePoolSelection = () => {
  const context = useContext(PrizePoolSelectionContext);
  if (context === undefined) {
    throw new Error(
      'usePrizePoolSelection must be used within a PrizePoolSelectionProvider',
    );
  }
  return context;
};
