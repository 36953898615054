import {
  GRPC_ERROR_CODE_TO_HTTP_STATUS_CODE,
  HttpErrorCode,
  gRPCErrorCode,
} from './errorCodes';

export class HttpsError extends Error {
  public httpErrorCode: HttpErrorCode;
  constructor(
    public readonly code: gRPCErrorCode,
    public readonly message: string,
    public readonly details?: unknown,
  ) {
    super(message);
    // eslint-disable-next-line security/detect-object-injection
    this.httpErrorCode = GRPC_ERROR_CODE_TO_HTTP_STATUS_CODE[code];
    Object.setPrototypeOf(this, HttpsError.prototype);
  }
}
