import { useDialog } from '../useDialog';
import { BanForm } from '../../components/messaging/actions/BanForm';
import { useBanHammer } from './useBanHammer';
import { useBanStatus } from './useBanStatus';
import type { Channel } from 'stream-chat';
import { isAdmin } from '../../../functions/src/types/firestore/User/util';
import { useAuth } from '../../contexts/AuthContext';
import { RemoveBanForm } from '../../components/messaging/actions/RemoveBanForm';
import { toUserId } from '../../../functions/src/util/messaging/mapId';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { useMemo } from 'react';

export type UseBanUserParams = {
  messageId: string;
  messageUsername: string;
  messageUserId: string;
  channel: Channel;
};

export const useBanUser = ({
  messageId,
  messageUsername,
  messageUserId,
  channel,
}) => {
  const { open: openDialog, close: closeDialog } = useDialog(messageId);
  const { userData } = useAuth();
  const { ban, removeBan } = useBanHammer(messageUserId);
  const isUserAdmin = isAdmin(userData?.email);
  const banStatus = useBanStatus(toUserId(messageUserId));

  const isUserBanned =
    banStatus !== 'loading' && !!banStatus.isBannedFromChannel(channel.cid);
  const isUserBannedFromApp =
    banStatus !== 'loading' && banStatus.isBannedFromApp;
  const isSelectionShown = isUserAdmin && isUserBanned && isUserBannedFromApp;

  const removeBanForm = useMemo(() => {
    return (
      <LoadingWrapper isLoading={banStatus === 'loading'}>
        <RemoveBanForm
          onClose={closeDialog}
          onSubmitRemoveBanDetails={removeBan}
          isSelectionShown={isSelectionShown}
          isUserBanned={isUserBanned}
          channel={channel}
        />
      </LoadingWrapper>
    );
  }, [
    banStatus,
    channel,
    closeDialog,
    isSelectionShown,
    isUserBanned,
    removeBan,
  ]);

  const banUser = () => {
    openDialog({
      title: `Ban User ${!isUserAdmin ? 'From Channel' : ''}`,
      description: `Are you sure you want to ban ${messageUsername}?`,
      children: (
        <BanForm
          onClose={closeDialog}
          onSubmitBanDetails={ban}
          displayBanType={isUserAdmin}
          channel={channel}
        />
      ),
    });
  };

  const unbanUser = () => {
    openDialog({
      title: `Unban User ${!isUserAdmin ? 'From Channel' : 'Blu'}`,
      description: `Are you sure you want to unban ${messageUsername}?`,
      children: removeBanForm,
    });
  };

  return { banUser, unbanUser };
};
