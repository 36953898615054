/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cache, CacheEvents } from '@algolia/cache-common';
import stringify from 'json-stringify-safe';

export type LocalStorageCacheItem = {
  /**
   * The cache item creation timestamp.
   */
  readonly timestamp: number;

  /**
   * The cache item value
   */
  readonly value: any;
};

export type LocalStorage = Pick<Storage, 'setItem' | 'removeItem'> & {
  readonly getItem: (key: string) => string | null | undefined;
};

export type LocalStorageOptions = {
  /**
   * The cache key.
   */
  readonly key: string;

  /**
   * The time to live for each cached item in seconds.
   */
  readonly timeToLive?: number;

  /**
   * The native local storage implementation.
   */
  readonly localStorage?: LocalStorage;
};

export function createLocalStorageCache(options: LocalStorageOptions): Cache {
  const namespaceKey = `algoliasearch-client-js-${options.key}`;

  let storage: LocalStorage;
  const getStorage = () => {
    if (storage === undefined) {
      storage = options.localStorage || window.localStorage;
    }

    return storage;
  };

  const getNamespace = <TValue>(): Record<string, TValue> => {
    return JSON.parse(getStorage().getItem(namespaceKey) || '{}');
  };

  const setNamespace = (namespace: Record<string, any>) => {
    getStorage().setItem(namespaceKey, stringify(namespace));
  };

  const removeOutdatedCacheItems = () => {
    const timeToLive = options.timeToLive ? options.timeToLive * 1000 : null;
    const namespace = getNamespace<LocalStorageCacheItem>();

    const filteredNamespaceWithoutOldFormattedCacheItems = Object.fromEntries(
      Object.entries(namespace).filter(([, cacheItem]) => {
        return cacheItem.timestamp !== undefined;
      }),
    );

    setNamespace(filteredNamespaceWithoutOldFormattedCacheItems);

    if (!timeToLive) return;

    const filteredNamespaceWithoutExpiredItems = Object.fromEntries(
      Object.entries(filteredNamespaceWithoutOldFormattedCacheItems).filter(
        ([, cacheItem]) => {
          const currentTimestamp = new Date().getTime();
          const isExpired = cacheItem.timestamp + timeToLive < currentTimestamp;

          return !isExpired;
        },
      ),
    );

    setNamespace(filteredNamespaceWithoutExpiredItems);
  };

  return {
    async get<TValue>(
      key: object | string,
      defaultValue: () => Readonly<Promise<TValue>>,
      events: CacheEvents<TValue> = {
        miss: () => {
          return Promise.resolve();
        },
      },
    ) {
      await Promise.resolve();
      removeOutdatedCacheItems();
      const keyAsString = stringify(key);
      const value_3 = await getNamespace<Promise<LocalStorageCacheItem>>()[
        String(keyAsString)
      ];
      const [value_4, exists] = await Promise.all([
        value_3 ? value_3.value : defaultValue(),
        value_3 !== undefined,
      ]);
      const [value_6] = await Promise.all([
        value_4,
        exists || events.miss(value_4),
      ]);
      return value_6;
    },

    async set<TValue>(key: object | string, value: TValue) {
      await Promise.resolve();
      const namespace = getNamespace();
      namespace[stringify(key)] = {
        timestamp: new Date().getTime(),
        value,
      };
      getStorage().setItem(namespaceKey, stringify(namespace));
      return value;
    },

    async delete(key: object | string) {
      await Promise.resolve();
      const namespace = getNamespace();
      delete namespace[stringify(key)];
      getStorage().setItem(namespaceKey, stringify(namespace));
    },

    async clear() {
      await Promise.resolve();
      getStorage().removeItem(namespaceKey);
    },
  };
}
