import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import { GradientIcon } from '../gradients/GradientIcon';
import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';

const NoCallersUnmemoized = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        background: theme.palette.background.elevation[12],
        borderRadius: '10px 10px 0 0',
      }}
    >
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 1,
        }}
      >
        <GradientIcon
          IconComponent={PeopleIcon}
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 150,
            maxHeight: 150,
            p: 4,
          }}
        />
      </Box>
      <Box sx={{ width: '60%', textAlign: 'center' }}>
        <Typography variant="h6">{`No one's here :(`}</Typography>
        <Typography variant="body1">
          {'Join to get the party started!'}
        </Typography>
      </Box>
    </Box>
  );
};

export const NoCallers = memo(NoCallersUnmemoized);
