import { useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { ContentCarousel } from '../../../ContentCarousel';
import { useMatch } from '../MatchProvider';
import {
  SCREENSHOT_HEIGHT_DETAILS,
  SCREENSHOT_WIDTH_DETAILS,
  ScreenshotImageHover,
} from './ScreenshotImageHover';
import { useActiveMatchRouter } from '../../../../hooks/tournaments/useActiveMatchRouter';

export const SessionScreenshotCarouselUnmemoized = () => {
  const { screenshots } = useMatch();
  const { sessionIndex: activeSessionIndex } = useActiveMatchRouter();

  const screenshotsRendered = useMemo(() => {
    if (!screenshots) {
      return [];
    }

    return screenshots
      .filter(({ sessionNumber }) => {
        return sessionNumber === activeSessionIndex;
      })
      .map((screenshot) => {
        const { screenshotUrl, uploaderUid } = screenshot;
        return (
          <ScreenshotImageHover
            key={`screenshot-${screenshotUrl}-${uploaderUid}`}
            screenshotUrl={screenshotUrl}
            uploaderUid={uploaderUid}
          />
        );
      });
  }, [activeSessionIndex, screenshots]);

  return (
    <>
      {screenshotsRendered.length >= 1 && (
        <ContentCarousel
          content={screenshotsRendered}
          sx={{
            width: SCREENSHOT_WIDTH_DETAILS,
            height: SCREENSHOT_HEIGHT_DETAILS,
          }}
          shiftToLatest
        />
      )}
    </>
  );
};

export const SessionScreenshotCarousel = memo(
  SessionScreenshotCarouselUnmemoized,
);
//TODO: Concern with shift to latest is that this might flip screenshots for users when someone uploads a screenshot
