import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { ELLIPSIS_SX } from '../../../util/string';

const LinkPreviewTextUnmemoized = () => {
  const { linkConfig } = useMessage();
  const { title, description, header } = linkConfig!;

  const textSx = {
    color: 'text.secondary',
    ...ELLIPSIS_SX,
  };

  return (
    <Stack pb={2}>
      <Typography variant="h6">{header}</Typography>
      <Typography variant="subtitle1" sx={textSx}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={textSx}>
        {description}
      </Typography>
    </Stack>
  );
};

export const LinkPreviewText = memo(LinkPreviewTextUnmemoized);
