import { FC } from 'react';
import { memo } from '../../../util/memo';
import { TournamentCardProps } from './TournamentCard';
import { RedirectingTournamentCard } from './RedirectingTournamentCard';

const NewTabTournamentCardUnmemoized: FC<TournamentCardProps> = (props) => {
  return (
    <RedirectingTournamentCard
      {...props}
      redirectProps={{ target: '_blank' }}
    />
  );
};

export const NewTabTournamentCard = memo(NewTabTournamentCardUnmemoized);
