import { memo } from '../../util/memo';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {
  GradientTypography,
  GradientTypographyProps,
} from '../gradients/GradientTypography';
import { Optional } from 'utility-types';

type LeaderboardTitleProps = {
  title: string;
  gradientColor?: string;
  typographyProps?: Optional<GradientTypographyProps>;
};

export const LeaderboardTitle = memo(function LeaderboardTitleUnmemoized({
  title,
  gradientColor = 'warning.vertical',
  typographyProps,
}: LeaderboardTitleProps) {
  return (
    <Stack
      width="100%"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Divider sx={{ flexGrow: 1 }} />
      <GradientTypography
        gradientColor={gradientColor}
        variant={'h4'}
        sx={{
          marginInline: '32px',
        }}
        {...typographyProps}
      >
        {title.toUpperCase()}
      </GradientTypography>
      <Divider sx={{ flexGrow: 1 }} />
    </Stack>
  );
});
