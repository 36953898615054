import { useEffect, useState } from 'react';

const TEST_URLS = ['https://cdn.adapex.io/hb/aaw.blumint.js'] as const;

const FETCH_OPTIONS = {
  method: 'HEAD',
  mode: 'no-cors',
  cache: 'no-cache',
} as const;

const DEFAULT_DELAY = 5000 as const;

const nextTestUrl = () => {
  const randomIndex = Math.floor(Math.random() * TEST_URLS.length);
  return TEST_URLS[Number(randomIndex)];
};

export const useRandomFetchAdBlock = (enabled = true) => {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const detectAdBlock = async () => {
      try {
        const testUrl = nextTestUrl();
        await new Promise((resolve) => {
          timeoutId = setTimeout(resolve, DEFAULT_DELAY);
        });
        await fetch(testUrl, FETCH_OPTIONS);
        setIsBlocked(false);
      } catch {
        setIsBlocked(true);
      }
    };

    if (enabled) {
      detectAdBlock();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [enabled]);

  return enabled && isBlocked;
};
