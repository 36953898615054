export type RegistrationDisplayType =
  | 'unregistered'
  | 'solo'
  | 'captain'
  | 'accepted';

export const parseStatus = (status?: string): RegistrationDisplayType => {
  if (status === 'accepted' || status === 'captain') return status;
  return 'unregistered';
};
