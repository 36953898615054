import { memo } from '../../../util/memo';
import { ImageOptimized } from '../../image/ImageOptimized';
import Stack from '@mui/material/Stack';
import { ThumbnailCard } from './ThumbnailCard';
import { PriceablePrizePoolToken } from '../../../../functions/src/types/firestore/PrizePool';
import { GradientTypography } from '../../gradients/GradientTypography';
import { usePriceableToken } from '../../../hooks/web3/usePriceableToken';

export type ThumbnailCardPriceableTokenProps = {
  token: PriceablePrizePoolToken;
};

export const ThumbnailCardPriceableToken = memo(
  function ThumbnailCardPriceableTokenUnmemoized({
    token,
  }: ThumbnailCardPriceableTokenProps) {
    const tokenPriceable = usePriceableToken(token);
    const { iconUrl, amountFiatAndCrypto } = tokenPriceable;
    return (
      <ThumbnailCard
        gradientColor={'warning.vertical'}
        sx={{
          borderRadius: '10px',
          width: '128px',
          height: '128px',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height={'124px'}
          width={'124px'}
          borderRadius={'10px'}
        >
          <ImageOptimized
            src={iconUrl}
            width={'64px'}
            height={'64px'}
            style={{ borderRadius: '10px' }}
            objectFit="contain"
          />
          <GradientTypography
            sx={{ alignSelf: 'center', textAlign: 'center' }}
            variant="h6"
            gradientColor="warning.vertical"
          >
            {amountFiatAndCrypto}
          </GradientTypography>
        </Stack>
      </ThumbnailCard>
    );
  },
);
