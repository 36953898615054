export const TOURNAMENT_QUERY_PARAMS = [
  'tournamentId',
  'inviteToken',
  'inviterId',
] as const;
export const getInviteUrl = (
  tournamentId?: string,
  inviteToken?: string,
  inviterId?: string,
) => {
  const url = new URL(window.location.href);
  if (tournamentId && inviteToken && inviterId) {
    url.searchParams.append('tournamentId', tournamentId);
    url.searchParams.append('inviteToken', inviteToken);
    url.searchParams.append('inviterId', inviterId);
  }
  return url;
};
