import { UserProviderInfo } from '../../../functions/src/types/firestore/User';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { formatProviderData } from '../../../functions/src/util/auth/formatProviderData';
import { isCustomOAuthProvider } from '../../../functions/src/util/auth/isCustomOAuthProvider';
import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';

export const unlinkFirebaseProvider = async (
  providerId: string,
  uid?: string,
) => {
  if (isCustomOAuthProvider(providerId)) {
    return;
  }
  const authImport = import('../../config/firebase-client/auth');
  const { auth } = await authImport;
  const firebaseAuthImport = import('firebase/auth');
  const { unlink } = await firebaseAuthImport;
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return;
  }
  const { providerData } = currentUser;
  const providerDataFormatted = formatProviderData(providerData);
  const providersToReLink: UserProviderInfo[] = providerDataFormatted.filter(
    ({ providerId: currentProviderId, uid: currentUid }) => {
      return (
        currentProviderId.includes(providerId) &&
        currentUid !== uid &&
        isFirebaseOAuthProvider(extractProviderId(currentProviderId))
      );
    },
  );
  await unlink(currentUser, providerId);
  return providersToReLink;
};
