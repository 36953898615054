import { useCallback } from 'react';
import { findErrorFrom } from '../util/findErrorFrom';
import { useAlertDialog } from './useAlertDialog';

export const ERROR_DIALOG = 'ERROR_DIALOG_ID' as const;

export const useErrorAlert = (library: Record<string, string>) => {
  const { open: openErrorDialog } = useAlertDialog(ERROR_DIALOG);

  const catchError = useCallback(
    async <T,>(
      callback: () => Promise<T>,
      onError?: (error?: unknown) => T | Promise<T>,
    ) => {
      try {
        const output = await callback();
        return output;
      } catch (error) {
        console.error(error);
        const errorOutput = await onError?.(error);
        const { title, message } = findErrorFrom(error, library);
        openErrorDialog({ title, description: message });
        return errorOutput;
      }
    },
    [library, openErrorDialog],
  );

  return { catchError };
};
