import { useMemo } from 'react';
import { useRouterState } from './useRouterState';
import { GROUP_IDS, GroupIdMap } from '../../util/routing/groupIds';

export const DEFAULT_DATA_VALUE = 'none' as const;

export const useGroupIdMap = () => {
  const routerStates = GROUP_IDS.map(({ key, location }) => {
    {
      // This is okay because the order of a global static
      // constant array (GROUP_IDS) is guaranteed to be stable
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useRouterState({ key, location });
    }
  });

  return useMemo(() => {
    return GROUP_IDS.reduce((acc, { key, prepare }, index) => {
      const [value] = routerStates[Number(index)];
      acc[String(key)] = value
        ? prepare
          ? prepare(value)
          : value
        : DEFAULT_DATA_VALUE;

      return acc;
    }, {} as GroupIdMap);
  }, [routerStates]);
};
