import { useEffect, useState, useCallback } from 'react';
import { fetchTotalViews } from '../../util/mux/livestream/fetchTotalViews';
import { usePollLiveViewers } from './usePollLiveViewers';
import { useLivestream } from '../../contexts/LivestreamContext';

export const useCountViewers = () => {
  const [viewerCount, setViewerCount] = useState<number>();
  const [viewsTotal, setViewsTotal] = useState<number>();
  const { isLive, playbackId } = useLivestream();
  const { onPoll } = usePollLiveViewers(playbackId);

  const fetchViews = useCallback(async (playbackIdInternal: string) => {
    const totalViews = await fetchTotalViews(playbackIdInternal);
    setViewsTotal(totalViews);
  }, []);

  useEffect(() => {
    if (!playbackId) {
      return;
    }

    let unsubscribe: (() => void) | undefined;

    if (isLive) {
      unsubscribe = onPoll(setViewerCount);
    } else {
      fetchViews(playbackId);
    }

    return () => {
      unsubscribe?.();
    };
  }, [playbackId, fetchViews, onPoll, isLive]);

  return { viewerCount, viewsTotal };
};
