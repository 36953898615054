import { useState, useEffect, useMemo } from 'react';
import { useRoom } from '../../contexts/RoomContext';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';

export const useRoomRequestCount = () => {
  const { rooms } = useRoom();
  const { mode } = useVoiceChat();
  const [requestCount, setRequestCount] = useState<number | 'unknown'>(
    'unknown',
  );

  const roomPath = useMemo(() => {
    return rooms[mode as string]?.roomPath;
  }, [rooms, mode]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (!roomPath) {
      return;
    }

    const handler = async () => {
      const { database } = await import(
        '../../config/firebase-client/database'
      );

      const { ref, query, orderByChild, equalTo, onValue } = await import(
        'firebase/database'
      );

      const requestedCallersRef = query(
        ref(database, `${roomPath}/callers`),
        orderByChild('status'),
        equalTo('requested'),
      );

      unsubscribe = onValue(requestedCallersRef, (snapshot) => {
        setRequestCount(snapshot.size);
      });
    };

    handler();
    return () => {
      unsubscribe?.();
    };
  }, [roomPath]);

  return { requestCount };
};
