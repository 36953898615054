import React, {
  FC,
  useState,
  Dispatch,
  ReactNode,
  useContext,
  createContext,
  SetStateAction,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type UserStatusContextParams = {
  userStatuses: Record<string, boolean>;
  setUserStatuses: Dispatch<SetStateAction<Record<string, boolean>>>;
};

export const UserStatusContext = createContext<
  UserStatusContextParams | undefined
>(undefined);

export type UserStatusContextProps = {
  children: ReactNode;
};

export const useUserStatus = () => {
  const context = useContext(UserStatusContext);
  if (!context) {
    throw new Error('useUserStatus must be used within a UseStatusProvider');
  }

  return context;
};

const UserStausProviderUnmemoized: FC<UserStatusContextProps> = ({
  children,
}) => {
  const [userStatuses, setUserStatuses] = useState<Record<string, boolean>>({});

  const memoizedValue = useMemo(() => {
    return {
      userStatuses,
      setUserStatuses,
    };
  }, [userStatuses]);

  return (
    <UserStatusContext.Provider value={memoizedValue}>
      {children}
    </UserStatusContext.Provider>
  );
};

export const UserStatusProvider = memo(UserStausProviderUnmemoized);
