export function joinWithConjunction(items: string[], conjunction = 'and') {
  if (items.length === 0) return '';

  if (items.length === 1) return items[0];

  if (items.length === 2) return `${items[0]} ${conjunction} ${items[1]}`;

  const allButLast = items.slice(0, -1).join(', ');

  return `${allButLast}, ${conjunction} ${items[items.length - 1]}`;
}
