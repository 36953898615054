import { useGlobalInterval } from '../../contexts/GlobalIntervalContext';
import { useFetchLiveViewers } from './useFetchLiveViewers';
import { useCallback } from 'react';

export const POLLING_INTERVAL_MS = 15000 as const;

export const usePollLiveViewers = (playbackId?: string) => {
  const { setGlobalInterval } = useGlobalInterval();
  const { fetchLiveViewers } = useFetchLiveViewers();

  const onPoll = useCallback(
    (callback: (viewers: number) => void) => {
      if (!playbackId) {
        return;
      }
      return setGlobalInterval({
        id: playbackId,
        intervalMs: POLLING_INTERVAL_MS,
        callback: async () => {
          const viewers = await fetchLiveViewers({
            assetId: playbackId,
            assetType: 'video',
          });
          callback(viewers);
        },
      });
    },
    [fetchLiveViewers, playbackId, setGlobalInterval],
  );

  return { onPoll };
};
