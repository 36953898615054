import { useState, useEffect } from 'react';

/**
 * @example const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));
 * @warning This hook will only run on mount. It will NOT re-run
 * if the promise dynamicImportPromise changes.
 */
export function useDynamic<TModule>(dynamicImportPromise: Promise<TModule>) {
  const [moduleCode, setModuleCode] = useState<TModule | undefined>();

  useEffect(() => {
    const importModule = async () => {
      const importedModule = await dynamicImportPromise;
      setModuleCode(importedModule);
    };

    importModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return moduleCode;
}
