import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material/styles';

export type GradientBorderProps = {
  children: ReactNode;
  sx?: SxProps;
  innerSx?: SxProps;
  gradientBackground?: string;
  backgroundColor?: string;
  borderRadius?: string;
  borderWidth?: number;
  disableBorder?: boolean;
  disableGlow?: boolean;
  gradientColor?: string;
} & BoxProps;

export const GradientBorder = memo(function GradientBorderUnmemoized({
  children,
  sx,
  gradientBackground,
  borderRadius,
  backgroundColor,
  borderWidth = 2,
  disableBorder,
  disableGlow,
  gradientColor,
  innerSx,
  ...props
}: GradientBorderProps) {
  const theme = useTheme();
  const gradient = gradientColor
    ? theme.palette[`${gradientColor.split('.')[0]}`][
        `${gradientColor.split('.')[1]}`
      ]
    : theme.palette.primary.vertical;
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage:
          gradientBackground ??
          `linear-gradient(${theme.palette.background.elevationSolid[10]}, 
            ${theme.palette.background.elevationSolid[10]})${
            disableBorder
              ? ''
              : `, 
            ${gradient}`
          }`,
        border: disableBorder ? `unset` : `double ${borderWidth}px transparent`,
        backgroundClip: 'content-box, border-box',
        backgroundOrigin: 'border-box',
        borderRadius,
        ...(!disableBorder && !disableGlow && { ...theme.glow.panel }),
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          backgroundColor,
          borderRadius,
          height: '100%',
          width: '100%',
          ...innerSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
});
