import { Timestamp } from 'firebase-admin/firestore';
import { Optional, Required } from 'utility-types';
import { Waitlistable } from '..';
import { Social } from '../../../../Social';
import { Team } from '../Guestlist';
import { Token } from '../../../User/Payout';
import { Identifiable } from '../../../../Identifiable';
import { Resolve } from '../../../../utility-types';

export type Session = `Session-${number}`;
export const OUTCOME_CLAIMS = ['win', 'loss'] as const;
export type OutcomeClaim = typeof OUTCOME_CLAIMS[number];
export type PlacementClaim = 0 | 1;
export type Screenshot = {
  screenshotUrl: string;
  uploaderUid: string;
  sessionNumber: number;
};

export type MatchAggregated<T = Timestamp> = Resolve<
  Identifiable & {
    status: 'delayed' | 'inactive' | 'active';
    /**
     * @remarks
     * This is only set to 'null' when the game requires manual coordination between the players
     */
    inGameMatchId?: string | null;
    /**
     * @remarks
     * This is only set to 'null' when the game requires manual coordination between the players
     */
    matchUrl?: string | null;
    team1Score?: number;
    team2Score?: number;
    team1Scores?: PlacementClaim[];
    team2Scores?: PlacementClaim[];
    team1?: Team<T>;
    team2?: Team<T>;
    winner?: Team<T>;
    social?: Social;
    next?: string;
    nextLoser?: string;
    previous1?: string;
    previous2?: string;
    startTime?: T;
    latestStartTime?: T;
    streamUrl?: string;
    bestOf: number;
    /**
     * @remarks in ms
     */
    maxDelay?: number;
    hidden?: boolean;
    countTiebreaks?: number;
    lastRestartRequest?: T;
    screenshots?: Screenshot[];
  } & Optional<Waitlistable>
>;

export type MatchFinished = Required<
  MatchAggregated,
  'winner' | 'team1' | 'team2'
>;

export type Outcome = 'win' | 'loss' | 'bypass';

export type Round<T = Timestamp> = Resolve<
  Identifiable & {
    startTime?: T;
    endTime?: T;
    maxMatchCount: number;
    finishedMatchCount: number;
    next?: string;
    previous?: string;
    payout: Token[];
    matches?: MatchAggregated<T>[];
  }
>;

//each Cohort has up to 32 participants and 5 rounds
export type Cohort<T = Timestamp> = Resolve<
  Identifiable & {
    title?: string;
    startTime?: T;
    endTime?: T;
    finishedRoundCount?: number;
    next?: string;
    previous?: string[];
    rounds?: Round<T>[];
    payouts?: { tokens: Token[] }[];
  }
>;

export const BRACKET_ROUNDS_FIELDS = [
  'bracket',
  'bracketLoser',
  'grandFinal',
] as const;

export type BracketRoundsKeys = typeof BRACKET_ROUNDS_FIELDS[number];

export type BracketRounds<T = Timestamp> = Resolve<
  Required<
    {
      [key in BracketRoundsKeys]?: Round<T>[];
    },
    'bracket'
  >
>;

export type Bracket<T = Timestamp> = Resolve<Identifiable & BracketRounds<T>>;
