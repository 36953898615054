export const NUMERIC: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
} as const;

export const NUMERIC_TIMEZONE: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const WEEKDAY_MONTH: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: undefined,
};

export const WEEKDAY_YEAR: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const NUMERIC_NOYEAR: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: undefined,
} as const;
