import { useEffect, useState, useCallback, useRef } from 'react';
import { EXPLORE_ELEMENT_ID } from '../../components/Explore';
import { useDebounceCallback } from '@react-hook/debounce';

export type VerticalLocation = 'above' | 'below' | 'visible';
export type HorizontalLocation = 'left' | 'right' | 'visible';

export type LocateViewportProps = {
  target?: HTMLElement | null;
  scrollableParent?: HTMLElement | null;
};

export function useLocateViewport({
  target,
  scrollableParent,
}: LocateViewportProps): {
  vertical: VerticalLocation;
  horizontal: HorizontalLocation;
} {
  const [visibility, setVisibility] = useState({
    vertical: 'visible' as VerticalLocation,
    horizontal: 'visible' as HorizontalLocation,
  });
  const prevVisibilityRef = useRef(visibility);

  const checkVisibility = useCallback(() => {
    if (!target) return;

    const rect = target.getBoundingClientRect();

    const newVisibility = {
      vertical:
        rect.bottom < 0
          ? 'above'
          : rect.top > window.innerHeight
          ? 'below'
          : 'visible',
      horizontal:
        rect.right < 0
          ? 'left'
          : rect.left > window.innerWidth
          ? 'right'
          : 'visible',
    } as const;

    if (
      newVisibility.vertical !== prevVisibilityRef.current.vertical ||
      newVisibility.horizontal !== prevVisibilityRef.current.horizontal
    ) {
      setVisibility(newVisibility);
      prevVisibilityRef.current = newVisibility;
    }
  }, [target]);

  const debouncedCheckVisibility = useDebounceCallback(checkVisibility, 200);

  useEffect(() => {
    const exploreElement = document.getElementById(EXPLORE_ELEMENT_ID);

    if (!target || !exploreElement) {
      return;
    }

    const eventListeners = [
      { element: exploreElement, event: 'scroll' },
      { element: window, event: 'resize' },
    ];

    if (scrollableParent) {
      eventListeners.push({ element: scrollableParent, event: 'scroll' });
    }

    eventListeners.forEach(({ element, event }) => {
      element.addEventListener(event, debouncedCheckVisibility);
    });

    checkVisibility();

    return () => {
      eventListeners.forEach(({ element, event }) => {
        element.removeEventListener(event, debouncedCheckVisibility);
      });
    };
  }, [target, scrollableParent, debouncedCheckVisibility, checkVisibility]);

  return visibility;
}
