import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';

export type HighlightedTeamContextType = {
  highlightedTeamName: string | undefined;
  highlight: Dispatch<SetStateAction<string | undefined>>;
};

const defaultState: HighlightedTeamContextType = {
  highlightedTeamName: undefined,
  highlight: () => {
    /** */
  },
};

const HighlightedTeamContext = createContext(defaultState);

export function useHighlightedTeamContext() {
  return useContext(HighlightedTeamContext);
}

type HighlightedTeamContextProps = {
  children: ReactElement;
};

export const HighlightedTeamProvider = memo(
  function HighlightedTeamProviderUnmemoized({
    children,
  }: HighlightedTeamContextProps) {
    const [highlightedTeamName, highlight] = useState<
      typeof defaultState['highlightedTeamName']
    >(defaultState.highlightedTeamName);

    const memoizedValue = useMemo(() => {
      return {
        highlightedTeamName,
        highlight,
      };
    }, [highlightedTeamName]);

    //check if token is valid. provide the token if so, if not, update the token
    return (
      <HighlightedTeamContext.Provider value={memoizedValue}>
        {children}
      </HighlightedTeamContext.Provider>
    );
  },
);
