import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { PREEMPTIVE_FILTER_TEMPLATES } from '../../../functions/src/util/algolia/preemption/templates';
import { fillTemplate } from '../../../functions/src/util/algoliaRealtime/fillTemplate';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
import { useRoom } from '../../contexts/RoomContext';
import { AlgoliaLayout } from '../algolia/AlgoliaLayout';
import { FriendCarouselHeader } from '../social-drawer/friends/FriendCarouselHeader';
import { CallerCard } from './CallerCard';
import { NoCallers } from './NoCallers';
import {
  CallersVerticalCarouselProps,
  CallersVerticalCarousel,
} from './CallersVerticalCarousel';

export const CALLERS_HITS_PER_PAGE = 50 as const;

const CallersUnmemoized = () => {
  const theme = useTheme();
  const { rooms } = useRoom();
  const { mode } = useVoiceChat();
  const { roomId } = rooms[mode as string] || {};

  const CallersCatalogWrapper = useCallback(
    (
      props: Omit<
        CallersVerticalCarouselProps,
        'RenderCallerHit' | 'header' | 'noCallers' | 'containerSx'
      >,
    ) => {
      return (
        <CallersVerticalCarousel
          {...props}
          RenderCallerHit={CallerCard}
          noCallers={<NoCallers />}
          cardSpacing="12px"
          header={<FriendCarouselHeader title="" separateSearch={true} />}
          containerSx={{
            background: theme.palette.background.elevationSolid[12],
            height: '212px',
            borderRadius: '10px 10px 0px 0px',
          }}
        />
      );
    },
    [theme.palette.background.elevationSolid],
  );

  const callersFilter = fillTemplate(
    PREEMPTIVE_FILTER_TEMPLATES['caller'],
    roomId,
  );

  const callersConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: CALLERS_HITS_PER_PAGE,
      filters: callersFilter,
    };
  }, [callersFilter]);

  return (
    <AlgoliaLayout
      CatalogWrapper={CallersCatalogWrapper}
      configureOptions={callersConfigureOptions}
      index="CONTENT"
    />
  );
};

export const Callers = memo(CallersUnmemoized);
