import { Intermission } from '../../functions/src/types/firestore/Game/Competition';

export const findIntermissionAround = (
  intermissions: Intermission<Date>[],
  date = new Date(),
) => {
  const dateMs = date.getTime();
  return intermissions.find(({ start, end }) => {
    const startMs = start.getTime();
    const endMs = end.getTime();

    return startMs < dateMs && dateMs < endMs;
  });
};
