import { FC } from 'react';
import { memo } from '../../../util/memo';
import {
  AuthenticationAgreements,
  AuthenticationAgreementsFormValues,
} from '../AuthenticationAgreements';
import { AuthenticationStackBase } from '../AuthenticationStackBase';

export type AuthenticationStartingPageUserData =
  AuthenticationAgreementsFormValues;

const AuthenticationRegistrationStartDialogUnmemoized: FC = () => {
  return (
    <AuthenticationStackBase
      title="Sign Up / Sign In"
      footer={<AuthenticationAgreements />}
    />
  );
};

export const AuthenticationRegistrationStartDialog = memo(
  AuthenticationRegistrationStartDialogUnmemoized,
);
