import { useState, useCallback, useMemo } from 'react';
import { memo } from '../util/memo';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import AddCommentIcon from '@mui/icons-material/AddCommentRounded';
import { useAuth } from '../contexts/AuthContext';
import { useRouterState } from '../hooks/routing/useRouterState';
import { ChannelGroupSidebarList } from './messaging/channel/ChannelGroupSidebarList';
import { ChatMenu } from './messaging/ChatMenu';
import { useSocialDrawer } from '../hooks/useSocialDrawer';
import { useActiveChannelGroup } from '../contexts/ActiveChannelGroupContext';
import { useGuardSignIn } from '../hooks/useGuardSignIn';
import { MESSAGING_SIGN_IN_TITLE } from './AppNavigationTabs';
import { SideDrawerToggle } from './SideDrawerToggle';
import { FriendPaneOption } from './social-drawer/friends/FriendsPane';
import { ContainedIconButton } from './gradients/ContainedIconButton';
import { computeFadeStyles } from '../styles/util/computeFadeStyle';

export const SIDEBAR_WIDTH = 76 as const;
export const SIDEBAR_WIDTH_MOBILE = 54 as const;

const SidebarUnmemoized = () => {
  const theme = useTheme();
  const { uid } = useAuth();
  const [_, setFriendsTab] = useRouterState({ key: 'friends' });
  const [__, setAppNavigationTab] = useRouterState({ key: 'nav' });
  const { closeChannelGroup } = useActiveChannelGroup();
  const { openSignInGuard } = useGuardSignIn();
  const { openSocialDrawer } = useSocialDrawer();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const openMenuGuarded = useCallback(
    ({ currentTarget }) => {
      if (!uid) {
        return openSignInGuard(MESSAGING_SIGN_IN_TITLE);
      }
      return setAnchorEl(currentTarget);
    },
    [openSignInGuard, uid],
  );

  const closeMenu = useCallback(() => {
    return setAnchorEl(null);
  }, []);

  const create = useCallback(() => {
    closeChannelGroup();
    openSocialDrawer();
    setAppNavigationTab('Friends');
    setFriendsTab('Chat' as FriendPaneOption);
    closeMenu();
  }, [
    closeChannelGroup,
    closeMenu,
    openSocialDrawer,
    setFriendsTab,
    setAppNavigationTab,
  ]);

  const sidebarlistSx = useMemo(() => {
    return {
      flexGrow: 1,
      minHeight: 0,
      ...computeFadeStyles({
        backgroundColor: theme.palette.background.elevationSolid[4],
      }),
    };
  }, [theme.palette.background.elevationSolid]);

  return (
    <Stack
      width={{
        xs: `${SIDEBAR_WIDTH_MOBILE}px`,
        md: `${SIDEBAR_WIDTH}px`,
      }}
      height="100%"
      alignItems="center"
      justifyContent={!uid ? 'flex-end' : 'space-between'}
      sx={{
        background: theme.palette.background.elevationSolid[4],
        borderLeft: {
          xs: 'none',
          md: `1px solid ${theme.palette.primary.dark}`,
        },
        boxShadow: { xs: theme.shadows[4], md: 'none' },
        pb: 3,
      }}
    >
      <SideDrawerToggle />
      <Box height="100%" maxHeight="100%" sx={sidebarlistSx}>
        <ChannelGroupSidebarList />
      </Box>
      <Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <ContainedIconButton
            onClick={openMenuGuarded}
            size="large"
            tooltipTitle="Create Chat"
          >
            <AddCommentIcon sx={{ pt: '2px' }} />
          </ContainedIconButton>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <ContainedIconButton
            onClick={openMenuGuarded}
            tooltipTitle="Create Chat"
          >
            <AddCommentIcon sx={{ pt: '2px' }} />
          </ContainedIconButton>
        </Box>
      </Box>
      {anchorEl && (
        <ChatMenu
          anchorEl={anchorEl}
          createChat={create}
          onClose={closeMenu}
          onCreateSupport={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        />
      )}
    </Stack>
  );
};

export const Sidebar = memo(SidebarUnmemoized);
