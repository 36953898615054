import { memo } from '../../../../util/memo';
import { TableBackbone } from 'src/components/StyledTable/TableBackbone';
import { StyledTableRow } from 'src/components/StyledTable/StyledTableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TeamMemberDetails } from './TeamMemberDetails';
import { createRowKey } from 'src/util/bracket/createRowKey';
import { useTheme } from '@mui/material/styles';
import { useMatch } from '../MatchProvider';
import { generateRowData } from '../../../../util/bracket/generateRowData';

export const CELL_STYLES = {
  left: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    width: '50%',
    border: 'none',
  },
  right: {
    p: 2,
    textAlign: 'right',
    direction: 'revert',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    width: '50%',
    border: 'none',
  },
};

export const MatchDetailsTeams = memo(function MatchDetailsTeamsUnmemoized() {
  const { team1, team2 } = useMatch();
  const theme = useTheme();
  const rowsData = generateRowData(team1, team2);
  return (
    <>
      {!!rowsData.length && (
        <TableBackbone
          sxContainer={{
            border: undefined,
            height: '100%',
            '&:hover': {
              border: 'none',
            },
            background: theme.palette.background.elevation[10],
          }}
        >
          <TableBody>
            {rowsData.map(({ team1Member, team2Member }) => {
              return (
                <StyledTableRow
                  key={createRowKey(team1Member, team2Member)}
                  sx={{ display: 'flex', flex: 1 }}
                >
                  <TableCell sx={CELL_STYLES.left}>
                    {!!team1Member && (
                      <TeamMemberDetails member={team1Member} />
                    )}
                  </TableCell>
                  <TableCell sx={CELL_STYLES.right}>
                    {!!team2Member && (
                      <TeamMemberDetails member={team2Member} />
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </TableBackbone>
      )}
    </>
  );
});
//TODO: @shaffy9633 check if this works on all instances of where this component is used.
