import { useContext, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useServerTime } from 'src/contexts/useServerTime';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { HeatsContext } from '../../../contexts/docs/HeatsContext';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { TabPanes } from '../../routing/TabPanes';
import { HeatLeaderboard } from './HeatLeaderboard';

const HeatsPaneUnmemoized = () => {
  const { sponsors } = useContext(TournamentContext);

  const allHeats = useContext(HeatsContext);
  const heats = useMemo(() => {
    const seenTitles = new Set<string>();
    return allHeats
      ?.filter((heat) => {
        const title = heat.title;
        if (title && !seenTitles.has(title)) {
          seenTitles.add(title);
          return true;
        }
        return false;
      })
      .sort((heatA, heatB) => {
        if (heatA.startTime && heatB.startTime) {
          return heatA.startTime.valueOf() - heatB.startTime.valueOf();
        }
        return 0;
      });
  }, [allHeats]);

  const { now } = useServerTime();
  const currentTime = now();

  const state = useRouterState({
    key: 'heat',
    defaultValue: heats?.[0]?.title || 'Heat 1',
  });

  const tabPanes = useMemo(() => {
    return heats?.map((heat, index) => {
      const hasStarted =
        heat?.startTime && heat?.startTime?.valueOf() < currentTime;
      const tabValue = heat.title || `Heat ${index + 1}`;
      return {
        value: tabValue,
        component: <HeatLeaderboard {...heat} sponsors={sponsors} />,
        customization: {
          label: tabValue,
          disabled: index !== 0 && !hasStarted,
        },
      };
    });
  }, [currentTime, heats, sponsors]);

  return <TabPanes tabPanes={tabPanes} state={state} variant="chip" />;
};

export const HeatsPane = memo(HeatsPaneUnmemoized);
