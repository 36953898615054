import { useMemo } from 'react';
import UAParser from 'ua-parser-js';

export const useMobileAgent = () => {
  return useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    const parser = new UAParser();
    const result = parser.getResult();
    return result.device.type === 'mobile' || result.device.type === 'tablet';
  }, []);
};
