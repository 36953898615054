import { memo } from '../../util/memo';
import { ImageOptimized } from '../image/ImageOptimized';
import Box from '@mui/material/Box';
import {
  GradientTypography,
  GradientTypographyProps,
} from '../gradients/GradientTypography';
import { SxProps } from '@mui/material/styles';
import { Competition } from 'functions/src/types/firestore/Game/Competition';
import { Link } from '../Link';
import { GradientBorder } from '../gradients/GradientBorder';
import { Variant } from '@mui/material/styles/createTypography';
import { useMobile } from 'src/hooks/useMobile';
import Stack from '@mui/material/Stack';

const SPONSOR_GAP = 8;

export type SponsorsProps = Required<Pick<Competition, 'sponsors'>> & {
  sx?: SxProps;
  TitleProps?: Partial<GradientTypographyProps>;
  direction?: 'column' | 'row';
  titleVariant?: Variant;
};

export const Sponsors = memo(function SponsorsUnmemoized({
  sponsors,
  direction = 'row',
  sx,
  TitleProps = {},
  titleVariant = 'h5',
}: SponsorsProps) {
  const isMobile = useMobile();
  const sponsorHeight = `calc(${
    100 / (direction === 'row' ? sponsors.length : 1)
  }% - 32px)`;
  const sponsorWidth = `calc(${
    100 / (direction === 'column' ? sponsors.length : 1)
  }% - 32px)`;

  const sponsorHeights = sponsors.map((sponsor) => {
    const { height } = sponsor;
    if (!height) {
      return 0;
    }
    return height;
  });
  const outerBoxHeight = sponsorHeights.reduce((acc, curr) => {
    return acc + curr + SPONSOR_GAP;
  }, 0);
  return (
    <Stack sx={{ ...sx }}>
      <GradientTypography
        variant={titleVariant}
        gradientColor="primary.vertical"
        sx={{ pb: 1, pl: 3, ...TitleProps }}
      >
        BROUGHT TO YOU BY:
      </GradientTypography>
      <GradientBorder
        sx={{
          borderRadius: '10px',
          height:
            isMobile && direction === 'row' ? `${outerBoxHeight}px` : '100%',
        }}
        disableBorder={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: `${direction} wrap`,
            alignItems: 'center',
            justifyContent: 'center',
            gap: SPONSOR_GAP,
            p: 8,
            height: '100%',
            width: '100%',
          }}
        >
          {sponsors
            .filter((sponsor) => {
              return !!sponsor.imgUrl;
            })
            .map(({ imgUrl, name, redirectUrl }, i) => {
              return (
                <Box
                  sx={{
                    position: 'relative',
                    height: sponsorHeight,
                    width: sponsorWidth,
                  }}
                  key={`sponsor-${i}`}
                >
                  <Link href={redirectUrl || ''}>
                    <ImageOptimized
                      src={imgUrl!}
                      alt={name}
                      layout="fill"
                      objectFit="contain"
                    />
                  </Link>
                </Box>
              );
            })}
        </Box>
      </GradientBorder>
    </Stack>
  );
});
