import { useContext } from 'react';
import { memo } from '../../../util/memo';
import { TournamentOverviewLayout } from './TournamentOverviewLayout';
import { useDateFormatter } from '../../../hooks/useDateFormatter';
import { DATE_FORMAT } from '../match/details/MatchDetails';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import Stack from '@mui/material/Stack';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTreeRounded';
import { TournamentDetailsPanel } from '../registration/TournamentDetailsPanel';
import { TournamentPanelDefault } from '../TournamentPanelDefault';
import { TournamentDate } from '../TournamentDate';
import { SponsorsV3 } from '../SponsorsV3';
// import { TournamentPayouts } from '../../payouts/TournamentPayouts';
import { useTheme } from '@mui/material/styles';
import { ImageOptimized } from '../../image/ImageOptimized';
import { BRACKET_TYPE_TO_DISPLAY } from '../../data-entry-ui/organizer/fieldOverrides';
import { formatDateRange } from '../../../util/dates/Dates';
import Diversity2Icon from '@mui/icons-material/Diversity2Rounded';
import PublicIcon from '@mui/icons-material/PublicRounded';
import { AdSideRails } from '../../ads/AdSideRails';
import { OVERVIEW_AD } from '../../../../functions/src/util/ads/adIds';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';

const DATE_FORMAT_OPTIONS = {
  dateFormat: DATE_FORMAT,
  separator: ', ',
};
export const MIN_TEAM_COUNT = 4;

const OverviewPaneUnmemoized = () => {
  const {
    checkInTime,
    phase,
    date,
    endDate,
    title,
    skipCheckIn,
    readyTime,
    tournamentDetails,
    prizePoolId,
    sponsors = [],
    bracketType,
    maxTeamSize,
    maxTeamCount,
    minTeamSize,
    region,
    squareImgUrl,
  } = useContext(TournamentContext);
  const formatDate = useDateFormatter(DATE_FORMAT_OPTIONS);
  const theme = useTheme();

  return (
    <AdSideRails
      id={OVERVIEW_AD}
      childrenMaxWidth={MAX_TOURNAMENT_PANE_WIDTH}
      pattern="tile"
    >
      <TournamentOverviewLayout
        phase={phase}
        tournamentTitle={title}
        skipCheckIn={skipCheckIn}
        components={{
          tournamentDetails: (
            <TournamentDetailsPanel tournamentDetails={tournamentDetails} />
          ),
          squareImgUrl: !!squareImgUrl ? (
            <Stack
              sx={{
                ...theme.panels[1],
                padding: 0,
                position: 'relative',
                width: '100%',
                maxWidth: '500px',
                height: '100%',
                minHeight: { xs: '318px' },
                maxHeight: '500px',
                'aspect-ratio': `1`,
              }}
              direction={'column'}
              alignItems="center"
              justifyContent="center"
            >
              <ImageOptimized
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                src={squareImgUrl}
                style={{ borderRadius: '10px', width: '100%', height: '100%' }}
              />
            </Stack>
          ) : (
            <></>
          ),
          bracketType: (
            <TournamentPanelDefault
              title={'Bracket'}
              IconComponent={AccountTreeIcon}
              description={BRACKET_TYPE_TO_DISPLAY[String(bracketType)]}
            />
          ),
          maxTeamSize: (
            <TournamentPanelDefault
              title={'Team Size'}
              IconComponent={GroupsRoundedIcon}
              description={`${maxTeamSize} players`}
            />
          ),
          minTeamSize: (
            <TournamentPanelDefault
              title={'Min Team Size'}
              IconComponent={GroupsRoundedIcon}
              description={`${minTeamSize} players`}
            />
          ),
          teamSizeRange: (
            <TournamentPanelDefault
              title={'Team Size'}
              IconComponent={GroupsRoundedIcon}
              description={(() => {
                const minTeamSizePrefix =
                  minTeamSize && minTeamSize !== maxTeamSize
                    ? `${minTeamSize}-`
                    : '';
                const pluralize = maxTeamSize > 1;
                const unit = `Player${pluralize ? 's' : ''}`;
                return `${minTeamSizePrefix}${maxTeamSize} ${unit}`;
              })()}
            />
          ),
          region: (
            <TournamentPanelDefault
              title={'Region'}
              IconComponent={PublicIcon}
              description={region || 'GLOBAL'}
            />
          ),
          checkInDuration: !skipCheckIn ? (
            <TournamentDate
              title={'Check In'}
              dateFormatted={formatDateRange(checkInTime, readyTime)}
            />
          ) : (
            <></>
          ),
          date: (
            <TournamentDate title={'starts'} dateFormatted={formatDate(date)} />
          ),
          endDate: (
            <TournamentDate
              title={'ends'}
              dateFormatted={formatDate(endDate)}
            />
          ),
          checkInTime: (
            <TournamentDate
              title="Check In Starts"
              dateFormatted={formatDate(checkInTime)}
            />
          ),
          // payouts: <TournamentPayouts />,
          ...(!!sponsors.length && {
            sponsors: <SponsorsV3 sponsors={sponsors} />,
          }),
          payoutDistributionType: (
            <TournamentPanelDefault
              title={'Payout'}
              IconComponent={MonetizationOnIcon}
              description={!!prizePoolId ? 'Automated' : 'Manual'}
            />
          ),
          // skipCheckIn: (
          //   <TournamentPanelV3
          //     Content={<Typography>{checkInMessage}</Typography>}
          //   />
          // ),
          maxTeamCount: (
            <TournamentPanelDefault
              title={'Team Count'}
              IconComponent={Diversity2Icon}
              description={`${MIN_TEAM_COUNT}-${maxTeamCount} Teams`}
            />
          ),
        }}
      />
    </AdSideRails>
  );
};

export const OverviewPane = memo(OverviewPaneUnmemoized);
