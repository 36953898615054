import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { useMatchPermissions } from './useMatchPermissions';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { uuidv4Base62 } from '../../../functions/src/util/uuidv4Base62';
import { useErrorAlert } from '../useErrorAlert';
import { SCREENSHOT_ERROR_LIBRARY } from '../../../functions/src/util/error/library/matchScreenshots';
import { useActiveMatchRouter } from './useActiveMatchRouter';

export const useSetMatchScreenshot = () => {
  const { uid } = useAuth();
  const { id: tournamentId } = useContext(TournamentContext);
  const { id: matchId } = useMatch();
  const { canUploadScreenshot } = useMatchPermissions();
  const { catchError } = useErrorAlert(SCREENSHOT_ERROR_LIBRARY);
  const { sessionIndex } = useActiveMatchRouter();

  const hasPermissions = useMemo(() => {
    return uid && canUploadScreenshot && sessionIndex !== undefined;
  }, [uid, canUploadScreenshot, sessionIndex]);

  const warnPermissions = () => {
    console.warn('useSetMatchScreenshot invoked without all parameters valid.');
  };

  const uploadFileUnsafe = useCallback(
    async (file: File) => {
      if (!hasPermissions) {
        warnPermissions();
        return undefined;
      }

      const { uploadImage } = await import('src/util/uploadImage');
      const newScreenshotUrl = await uploadImage({
        file,
        storagePath: `Ugc/${uid}/${matchId}/${sessionIndex}/${uuidv4Base62()}`,
      });
      return newScreenshotUrl;
    },
    [hasPermissions, matchId, sessionIndex, uid],
  );

  const uploadFile = useCallback(
    async (file: File) => {
      return await catchError<string | undefined>(
        async () => {
          return await uploadFileUnsafe(file);
        },
        () => {
          return undefined;
        },
      );
    },
    [catchError, uploadFileUnsafe],
  );

  const updateScreenshotFirestore = useCallback(
    async (url?: string, previousUrl?: string) => {
      if (!hasPermissions || !url) {
        warnPermissions();
        return;
      }
      const { updateMatchScreenshot } = await import(
        '../../firebaseCloud/tournament/updateMatchScreenshot'
      );
      await updateMatchScreenshot({
        matchId,
        tournamentId,
        sessionIndex: sessionIndex,
        screenshotUrl: url,
        previousUrl,
      });
    },
    [hasPermissions, matchId, sessionIndex, tournamentId],
  );

  const set = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, previousUrl?: string) => {
      const { currentTarget } = event;
      if (!currentTarget || !currentTarget.files?.length) return;
      const file = currentTarget.files[0];
      try {
        const newUrl = await uploadFile(file);
        await updateScreenshotFirestore(newUrl, previousUrl);
        return newUrl;
      } catch (error) {
        console.error('Screenshot upload failed', error);
      }
      currentTarget.value = '';
    },
    [updateScreenshotFirestore, uploadFile],
  );

  const deleteScreenshot = useCallback(
    async (url: string) => {
      await updateScreenshotFirestore(url);
    },
    [updateScreenshotFirestore],
  );

  const addScreenshot = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      return await set(event);
    },
    [set],
  );

  const replaceScreenshot = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, previousUrl: string) => {
      return await set(event, previousUrl);
    },
    [set],
  );

  return {
    addScreenshot,
    deleteScreenshot,
    replaceScreenshot,
  };
};
