import Stack from '@mui/material/Stack';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { AuthenticationPasswordStack } from '../AuthenticationPasswordStack';

import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { LinkEmailButton } from '../../buttons/LinkEmailButton';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';

export type AuthenticationEmailSignUpDialogProps = {
  title: string;
  description?: string | ReactNode;
};

const AuthenticationEmailSignUpDialogUnmemoized = ({
  title,
  description = "You're new! Create a BluMint account now?",
}: AuthenticationEmailSignUpDialogProps) => {
  return (
    <Stack width="100%">
      <DialogAuthenticationNavBar />
      <DialogBodyStandard title={title} description={description}>
        <Stack
          spacing={12}
          alignSelf="center"
          direction="column"
          pb={4}
          maxWidth="440px"
        >
          <AuthenticationPasswordStack autoFocus={false} />
          <LinkEmailButton />
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEmailSignUpDialog = memo(
  AuthenticationEmailSignUpDialogUnmemoized,
);
