import {
  Bracket,
  BracketRoundsKeys,
  BRACKET_ROUNDS_FIELDS,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { HttpsError } from '../../../util/errors/HttpsError';
import { RoundsSecretary } from './RoundsSecretary';

type RoundsSecretaries<TTime> = Record<
  BracketRoundsKeys,
  RoundsSecretary<TTime>
>;

export class RoundsSecretaryIndex<TTime> {
  private readonly secretaries: RoundsSecretaries<TTime>;

  public constructor(bracket: Bracket<TTime>) {
    this.secretaries = Object.keys(bracket).reduce((acc, curr) => {
      if (RoundsSecretaryIndex.isBracketRoundsKeys(curr)) {
        // eslint-disable-next-line security/detect-object-injection
        acc[curr] = new RoundsSecretary<TTime>(bracket[curr] || []);
      }
      return acc;
    }, {} as RoundsSecretaries<TTime>);
  }

  public static isBracketRoundsKeys(value: string): value is BracketRoundsKeys {
    return (BRACKET_ROUNDS_FIELDS as ReadonlyArray<string>).includes(value);
  }

  public secretaryOf(matchId: string) {
    for (const [bracketRoundsKeys, roundsSecretary] of Object.entries(
      this.secretaries,
    )) {
      if (roundsSecretary.findMatch(matchId)) {
        return {
          bracketRoundsKeys: bracketRoundsKeys as BracketRoundsKeys,
          roundsSecretary,
        };
      }
    }
    throw new HttpsError(
      'not-found',
      `Unable to find secretary for match`,
      `Match id: ${matchId}`,
    );
  }
}
