import { STREAM_CHAT_TIMEOUT_MILLIS } from '../../../functions/src/config/get-stream/timeout';
import { StreamChatProxy } from '../../../functions/src/proxies/streamChatProxy';

export const streamChatFrontend = new StreamChatProxy(
  process.env.NEXT_PUBLIC_GETSTREAM_API_KEY as string,
  undefined,
  {
    timeout: STREAM_CHAT_TIMEOUT_MILLIS,
  },
);
