import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import { ColumnProps } from './ColumnWrapper';

function ColumnMobileUnmemoized({ children }: ColumnProps) {
  return (
    <Stack
      direction="column"
      spacing={4}
      alignItems="center"
      justifyContent="space-around"
    >
      {children}
    </Stack>
  );
}

export const ColumnMobile = memo(ColumnMobileUnmemoized);
