import { useState, useEffect, MutableRefObject } from 'react';
import { useQuerySelector } from '../useQuerySelector';

export const MESSAGE_INPUT_DEFAULT_HEIGHT = 45 as const;

export const useMessageInputHeight = (
  parentRef?: MutableRefObject<HTMLElement | null>,
) => {
  const [height, setHeight] = useState<number>(MESSAGE_INPUT_DEFAULT_HEIGHT);

  const textareaContainer = useQuerySelector<HTMLElement>(
    '.str-chat__message-textarea-container',
    {
      root: parentRef?.current || 'suspend-observation',
    },
  );

  useEffect(() => {
    if (!textareaContainer) {
      return;
    }
    const newHeight = textareaContainer.clientHeight;
    setHeight(newHeight);
    const resizeObserver = new ResizeObserver(() => {
      setHeight(textareaContainer.clientHeight);
    });
    resizeObserver.observe(textareaContainer);
    return () => {
      resizeObserver.disconnect();
    };
  }, [textareaContainer]);

  return height;
};
