import { useScreenshotActions } from '../../../../hooks/tournaments/useScreenshotActions';
import { useMatchPermissions } from '../../../../hooks/tournaments/useMatchPermissions';
import { useAuth } from '../../../../contexts/AuthContext';
import { UserContentOverlay } from '../../../UserContentOverlay';
import { useHover } from '../../../../contexts/HoverContext';
import { memo } from '../../../../util/memo';

export type ScreenshotActionButtonsProps = {
  uploaderUid: string;
  screenshotUrl: string;
};

export const ScreenshotActionButtons = memo(
  function ScreenshotActionButtonsUnmemoized({
    uploaderUid,
    screenshotUrl,
  }: ScreenshotActionButtonsProps) {
    const { uid } = useAuth();
    const { isHovered } = useHover();
    const isUserUploader = uid === uploaderUid;

    const { isTournamentAdmin } = useMatchPermissions();

    const renderEditButtons = isUserUploader || isTournamentAdmin;
    const ButtonsExplained = useScreenshotActions(screenshotUrl);
    return (
      <>
        {isHovered && (
          <UserContentOverlay direction="row" alignItems="center">
            {ButtonsExplained['report']}
            {renderEditButtons && (
              <>
                {ButtonsExplained['update']}
                {ButtonsExplained['delete']}
              </>
            )}
          </UserContentOverlay>
        )}
      </>
    );
  },
);
