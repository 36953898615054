import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../../gradients/GradientTypography';
import { HeatBlurb } from 'functions/src/types/firestore/Game/Tournament/Heat';
import { ReactElement } from 'react';
import { memo } from '../../../util/memo';
import { GradientBorder } from 'src/components/gradients/GradientBorder';

type HeatRulesProps = {
  blurb: HeatBlurb;
  isQualifier: boolean;
};

type RulesSectionProps = {
  title: string;
  points: string[];
  endAdornment?: ReactElement | null;
};

const RulesSection = memo(function RulesSection({
  title,
  points,
  endAdornment = null,
}: RulesSectionProps) {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          textAlign: 'justify',
          textTransform: 'uppercase',
          mb: -2,
        }}
      >
        {title}
      </Typography>
      {points?.map((point) => {
        return (
          <Typography
            sx={{ textAlign: 'justify' }}
            color="text.secondary"
            key={point}
          >
            {point}
          </Typography>
        );
      })}
      {endAdornment}
    </>
  );
});

export const HeatRules = memo(function HeatRulesUnmemoized({
  blurb,
  isQualifier,
}: HeatRulesProps) {
  return (
    <Box sx={{ width: 'min(586px, 100%)' }}>
      <GradientBorder disableBorder={true} sx={{ borderRadius: '10px' }}>
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          gap="8px"
          sx={{ px: 8, py: 6 }}
        >
          <GradientTypography
            gradientColor="primary.vertical"
            variant="h5"
            textTransform="uppercase"
          >
            {blurb.title} RULES
          </GradientTypography>
          <RulesSection
            title={'Requirements'}
            points={blurb.minimumRequirements}
          />
          <RulesSection
            title={isQualifier ? 'Qualifying' : 'Prizes'}
            points={blurb.qualifyingRequirements}
          />
          <RulesSection
            title={'Additional Rules'}
            points={blurb.rules}
            endAdornment={
              <Typography color="text.secondary" sx={{ textAlign: 'justify' }}>
                For the full list of rules, please check the Rules & Regulations
                section of the Registration tab.
              </Typography>
            }
          />
        </Stack>
      </GradientBorder>
    </Box>
  );
});
