import { Item } from 'functions/src/types/firestore/Game/Collection/Item';
import { Token } from 'functions/src/types/firestore/User/Payout';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import { useEffect, useState } from 'react';

export const useDisplayedPrizes = (selectedItems: Token[] = []) => {
  const [prizesDisplayed, setPrizesDisplayed] = useState<
    (Token | Item<Date>)[]
  >([]);
  useEffect(() => {
    const handler = async () => {
      const displayed = await Promise.all(
        selectedItems.map(async (token) => {
          if (token.type === 'ERC20' || token.type === 'native') {
            return token;
          }
          const { firestore } = await import(
            'src/config/firebase-client/firestore'
          );
          const { collectionGroup, where, getDocs, query } = await import(
            'firebase/firestore'
          );
          const itemCollection = collectionGroup(firestore, 'Item');
          const itemQuerySnap = await getDocs(
            query(
              itemCollection,
              where('contract.address', '==', token.address),
              where('contract.chainId', '==', token.chainId),
              where('contract.itemId', '==', parseInt(token.identifier)),
            ).withConverter<Item<Date>>(ConverterFactory.buildDateConverter()),
          );

          return itemQuerySnap.docs.map((doc) => {
            return Object.assign(doc.data(), {
              amount: token.amount,
            }) as Item<Date>;
          })[0];
        }),
      );
      setPrizesDisplayed(displayed);
    };
    handler();
  }, [selectedItems]);
  return prizesDisplayed;
};
