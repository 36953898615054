import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { LoadingButton } from '../../buttons/LoadingButton';
import { FormItem } from '../../form/FormItem';
import { GradientRadio } from '../../gradients/GradientRadio';
import { ONE_HOUR_MILLIS } from '../../../../functions/src/util/conversions';
import { StringInput2 } from '../../data-entry-ui/StringInput2';
import { NumberInput2 } from '../../data-entry-ui/NumberInput2';
import type { Channel } from 'stream-chat';
import { BanParams } from '../../../hooks/messaging/useBanHammer';

export type BanFormProps = {
  onClose: () => void;
  onSubmitBanDetails: (props: BanParams) => Promise<void>;
  displayBanType: boolean;
  channel: Channel;
};

const BanFormUnmemoized = ({
  onClose,
  onSubmitBanDetails,
  displayBanType,
  channel,
}: BanFormProps) => {
  const [banHours, setBanHours] = useState<number>();
  const [reason, setReason] = useState<string>();
  const [isChannelBan, setIsChannelBan] = useState(!displayBanType);

  const ban = useCallback(async () => {
    await onSubmitBanDetails({
      timeout: banHours ? banHours * ONE_HOUR_MILLIS : undefined,
      reason,
      channel: isChannelBan ? channel : undefined,
    });
    onClose();
  }, [banHours, channel, isChannelBan, onClose, onSubmitBanDetails, reason]);

  return (
    <Stack direction="column" width="100%" spacing={6} minWidth={480}>
      <FormControl>
        <Stack
          direction="column"
          spacing={3}
          sx={{ '& .MuiFormGroup-root': { mt: 0 } }}
        >
          {displayBanType && (
            <FormItem title="Type">
              <RadioGroup
                row
                onChange={(event) => {
                  const { value } = event.target;
                  setIsChannelBan(value === 'channel');
                }}
              >
                <FormControlLabel
                  value="channel"
                  control={<GradientRadio />}
                  label="Channel"
                />
                <FormControlLabel
                  value="app"
                  control={<GradientRadio checked={!isChannelBan} />}
                  label="App"
                />
              </RadioGroup>
            </FormItem>
          )}
          <NumberInput2
            label="Duration in hours (optional)"
            onChange={(value) => {
              return setBanHours(Number(value));
            }}
          />
          <StringInput2
            label="Reason"
            onChange={(value) => {
              return setReason(value);
            }}
          />
        </Stack>
      </FormControl>
      <LoadingButton
        fullWidth
        variant="contained"
        disabled={!reason}
        onClick={ban}
      >
        Ban
      </LoadingButton>
    </Stack>
  );
};

export const BanForm = memo(BanFormUnmemoized);
