import {
  GENERIC_SIGN_IN_METHODS,
  GenericSignInMethod,
} from '../../types/firestore/User';

export const isFirebaseOAuthProvider = (
  providerId: string,
): providerId is GenericSignInMethod => {
  return GENERIC_SIGN_IN_METHODS.some((method) => {
    return providerId.includes(method);
  });
};
