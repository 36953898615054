import { useTheme } from '@mui/material/styles';
import { LinearGradient } from 'src/components/gradients/LinearGradient';
import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';
import { useEffect } from 'react';

export const SSR_GRADIENTS = [
  'primary',
  'secondary',
  'warning',
  'error',
  'info',
  'success',
  'royal',
];

export function useLinearGradient(gradientColor = 'primary.vertical') {
  const theme = useTheme();

  const inTheme = !gradientColor.includes('linear-gradient');
  const [section, gradient] = inTheme
    ? gradientColor.split('.')
    : [undefined, undefined];
  const gradientCss =
    section && gradient
      ? theme.palette[`${section}`][`${gradient}`]
      : gradientColor;

  const id = gradientCss
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')
    .replaceAll(',', '')
    .replaceAll('#', '')
    .replaceAll('%', '');

  const { union } = useGlobalComponentsContext();

  useEffect(() => {
    if ((!section || !SSR_GRADIENTS.includes(section)) && gradientCss && id) {
      union(gradientCss, <LinearGradient id={id} gradient={gradientCss} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, union]);

  return id;
}
