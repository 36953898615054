import { useDialog } from './useDialog';
import { ReactNode, useCallback } from 'react';
import { DialogActionsStandard } from '../components/dialog/DialogActionsStandard';
import { LoadingButtonProps } from '../components/buttons/LoadingButton';
import { ButtonProps } from '@mui/material/Button';

export type OpenParams = {
  title: string;
  description?: string | ReactNode;
  confirmFunction: () => Promise<void>;
  onClose?: () => void;
  confirmProps?: LoadingButtonProps;
  backProps?: ButtonProps;
};

export const useConfirmDialog = (id: string) => {
  const { open: openDialog, close, isOpen } = useDialog(id);

  const open = useCallback(
    ({
      title,
      description,
      confirmFunction,
      onClose,
      confirmProps,
      backProps,
    }: OpenParams) => {
      return openDialog({
        title,
        description,
        children: (
          <DialogActionsStandard
            buttons={[
              {
                isAsync: true,
                onClick: confirmFunction,
                color: 'success',
                children: 'Confirm',
                ...confirmProps,
              },
              {
                isAsync: false,
                onClick: () => {
                  close();
                  onClose?.();
                },
                color: 'error',
                children: 'Go Back',
                ...backProps,
              },
            ]}
          />
        ),
        onClose,
      });
    },
    [close, openDialog],
  );

  return { open, close, isOpen };
};
