import { isPreReady } from '../../../../functions/src/types/firestore/Game/Tournament/util';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { LoadingButton } from '../../buttons/LoadingButton';

export function DisbandTeamButton() {
  const { phase } = useTournamentRegistration();
  const { disbandTeam } = useRegistrationFunctions();
  return (
    <>
      {isPreReady(phase) && (
        <LoadingButton
          onClick={disbandTeam}
          variant="contained"
          color="error"
          fullWidth
          size="large"
        >
          Leave Team
        </LoadingButton>
      )}
    </>
  );
}
