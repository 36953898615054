import { useMatch } from '../MatchProvider';
import { MatchCellButton } from './MatchCellIButton';
import { memo } from '../../../../util/memo';
import RestoreIcon from '@mui/icons-material/RestoreRounded';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import { useMatchActions } from '../../../../hooks/useMatchActions';
import { Team } from '../../../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { extractTeamName } from '../../../../../functions/src/util/tournaments/extractTeamName';

export type CellButtonRevertProps = {
  gameId: string;
  tournamentId: string;
};

const generateTitle = (team1: Team<Date>, team2?: Team<Date>) => {
  const team1Name = extractTeamName(team1);
  if (!team2) {
    return `Reset scores of ${team1Name}`;
  }
  const team2Name = extractTeamName(team2);
  return `Reset match between ${team1Name} vs ${team2Name}`;
};

const CellButtonRevertUnmemoized: React.FC<CellButtonRevertProps> = ({
  gameId,
  tournamentId,
}) => {
  const { id, team1, team2 } = useMatch();
  const { revertCurrentMatch } = useMatchActions({
    gameId,
    matchId: id,
    tournamentId,
  });
  const { open: openConfirmDialog, close: closeConfirmDialog } =
    useConfirmDialog(id);
  const handleRevert = () => {
    if (!team1) {
      return;
    }

    const title = generateTitle(team1, team2);

    return openConfirmDialog({
      title,
      confirmFunction: async () => {
        await revertCurrentMatch();
        closeConfirmDialog();
      },
    });
  };
  return <MatchCellButton Icon={RestoreIcon} onClick={handleRevert} />;
};

export const CellButtonRevert = memo(CellButtonRevertUnmemoized);
