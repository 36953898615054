import { FC } from 'react';
import { memo } from '../../../util/memo';
import { HoverProvider } from '../../../contexts/HoverContext';
import {
  TournamentCardRedirect,
  TournamentCardRedirectProps,
} from './TournamentCardRedirect';
import { TournamentCard, TournamentCardProps } from './TournamentCard';

const RedirectingTournamentCardUnmemoized: FC<
  TournamentCardProps & {
    redirectProps?: TournamentCardRedirectProps;
  }
> = ({ children, redirectProps = {}, ...tournament }) => {
  return (
    <HoverProvider>
      <TournamentCard {...tournament}>
        <TournamentCardRedirect {...redirectProps} />
      </TournamentCard>
    </HoverProvider>
  );
};

export const RedirectingTournamentCard = memo(
  RedirectingTournamentCardUnmemoized,
);
