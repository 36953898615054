import { BigNumber } from 'ethers';
import { Token } from '../../types/firestore/User/Payout';
import { HttpsError } from '../errors/HttpsError';

export class TokenDivider {
  constructor(private tokens: Token[], private countParts: number) {}

  public assertDivisible() {
    const { payoutsERC721, payoutsOther } = this.split();
    this.assertDivisibleErc721(payoutsERC721);
    payoutsOther.forEach((token) => {
      return this.assertDivisibleOther(token);
    });
  }

  private assertDivisibleErc721(erc721Payouts: Token[]) {
    if (erc721Payouts.length % this.countParts !== 0) {
      throw new HttpsError(
        'failed-precondition',
        'ERC721 tokens cannot be divided evenly among team members',
      );
    }
  }

  private assertDivisibleOther(payoutOther: Token) {
    if (
      payoutOther.type === 'ERC1155' &&
      BigNumber.from(payoutOther.amount).mod(this.countParts).isZero() === false
    ) {
      throw new HttpsError(
        'failed-precondition',
        'ERC1155 tokens cannot be divided evenly among team members',
      );
    }
  }

  public divideEvenly() {
    this.assertDivisible();
    const { payoutsERC721, payoutsOther } = this.split();
    const payoutsMemberERC721 = this.divideErc721Tokens(payoutsERC721);
    const payoutsMemberOther = this.divideOtherTokens(payoutsOther);

    return this.combine(payoutsMemberERC721, payoutsMemberOther);
  }

  // TODO: memoize
  private split() {
    return this.tokens.reduce(
      (acc, token) => {
        if (TokenDivider.isErc721(token)) {
          acc.payoutsERC721.push(token);
        } else {
          acc.payoutsOther.push(token);
        }
        return acc;
      },
      {
        payoutsERC721: [] as Token<'ERC721'>[],
        payoutsOther: [] as Token[],
      },
    );
  }

  private static isErc721(token: Token): token is Token<'ERC721'> {
    return token.type === 'ERC721';
  }

  private divideErc721Tokens(erc721Payouts: Token[]) {
    const erc721PerMember = Math.floor(erc721Payouts.length / this.countParts);
    return Array.from({ length: this.countParts }, (_val, i) => {
      return erc721Payouts.slice(
        i * erc721PerMember,
        (i + 1) * erc721PerMember,
      );
    });
  }

  private divideOtherTokens(payoutsOther: Token[]) {
    return payoutsOther.map((token) => {
      this.assertDivisibleOther(token);
      const amountPerMember = BigNumber.from(token.amount)
        .div(this.countParts)
        .toString();
      return Array.from({ length: this.countParts }, () => {
        return { ...token, amount: amountPerMember };
      });
    });
  }

  private combine(
    payoutsMemberERC721: Token[][],
    payoutsMemberOther: Token[][],
  ) {
    return Array.from({ length: this.countParts }, (_val, i) => {
      return [
        ...(payoutsMemberERC721[Number(i)] || []),
        ...payoutsMemberOther.flat().filter((_token, j) => {
          return j % this.countParts === i;
        }),
      ];
    });
  }
}
