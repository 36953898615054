import { RefObject, useState, useEffect, useCallback, useMemo } from 'react';
import { stableHash } from '../../../functions/src/util/hash/stableHash';
import { findAllScrollableAncestors } from '../../util/findAllScrollableAncestors';

export type VisibilityObserverResult = {
  target: HTMLElement | null;
  intersectionRatio: number;
  isIntersecting: boolean;
};

export const DEFAULT_VISIBILITY_RESULT = {
  target: null,
  intersectionRatio: 0,
  isIntersecting: false,
} as const;

/**
 * @remarks This will NOT return isVisible: false when an element is
 * dismounted from the DOM. This is too expensive performance-wise to
 * encapsulate in this hook.
 */
export function useVisibilityObserver<
  TElement extends HTMLElement = HTMLElement,
>(
  target: RefObject<TElement | null> | TElement | null,
  options: Omit<IntersectionObserverInit, 'root'> = {},
) {
  const element = useMemo(() => {
    if (!target) {
      return null;
    }
    return target instanceof Element ? target : target?.current || null;
  }, [target]);

  const [intersectionStates, setIntersectionStates] = useState<
    IntersectionObserverEntry[]
  >([]);
  const updateIntersectionState = useCallback(
    (index: number, entry: IntersectionObserverEntry) => {
      setIntersectionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[Number(index)] = entry;
        return newStates;
      });
    },
    [],
  );

  const [result, setResult] = useState<VisibilityObserverResult>({
    ...DEFAULT_VISIBILITY_RESULT,
    target: element,
  });

  useEffect(() => {
    if (!element || !document.contains(element)) {
      setResult({ ...DEFAULT_VISIBILITY_RESULT, target: element });
      setIntersectionStates([]);
      return;
    }
    const scrollableAncestors = findAllScrollableAncestors(element);
    const observers: IntersectionObserver[] = [];

    scrollableAncestors.forEach((root, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const firstEntry = entries[0];
          if (firstEntry) {
            updateIntersectionState(index, firstEntry);
          }
        },
        { ...options, root },
      );

      observer.observe(element);
      observers.push(observer);
    });

    setIntersectionStates(new Array(scrollableAncestors.length).fill(null));

    return () => {
      observers.forEach((observer) => {
        return observer.disconnect();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, stableHash(options), updateIntersectionState]);

  useEffect(() => {
    if (
      intersectionStates.some((state) => {
        return state === null;
      }) ||
      intersectionStates.length === 0 ||
      !element
    ) {
      return;
    }

    const lowestRatio = Math.min(
      ...intersectionStates.map(({ intersectionRatio }) => {
        return intersectionRatio;
      }),
    );
    const allIntersecting = intersectionStates.every(({ isIntersecting }) => {
      return isIntersecting;
    });

    setResult({
      target: element,
      intersectionRatio: lowestRatio,
      isIntersecting: allIntersecting,
    });
  }, [intersectionStates, element]);

  return result;
}
