import { useAuth } from '../../contexts/AuthContext';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { RoleMap } from '../../../functions/src/types/Roles';
import { extractIds } from '../../../functions/src/util/roles/extractIds';

export const useRoledUser = <T extends string>(requiredRoles?: T[]) => {
  const { uid } = useAuth();
  const { channel } = useStreamChatContext();

  if (!uid || !channel) {
    return { hasRole: false, hasAnyRole: false };
  }

  const roles = (channel.data?.roles || {}) as RoleMap<T>;

  const hasAnyRole = extractIds<keyof RoleMap<T>>(roles).includes(uid);

  const hasRole = requiredRoles
    ? requiredRoles.some((role) => {
        return (roles[role as string] || []).includes(uid);
      })
    : hasAnyRole;

  return { hasRole, hasAnyRole };
};
