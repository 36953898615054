import { memo } from '../../util/memo';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DiscordIcon = memo(function DiscordIconUnmemoized(
  props: SvgIconProps,
) {
  return (
    <SvgIcon viewBox="0 0 40 31" {...props}>
      <path d="M33.8838 2.58863C31.2945 1.37932 28.526 0.500441 25.6315 0C25.276 0.641547 24.8607 1.50444 24.5744 2.19087C21.4975 1.72896 18.4489 1.72896 15.4286 2.19087C15.1423 1.50444 14.7176 0.641547 14.3589 0C11.4613 0.500441 8.68959 1.38255 6.10032 2.59503C0.877738 10.4731 -0.538019 18.1554 0.169861 25.7287C3.63375 28.3109 6.99068 29.8795 10.291 30.9059C11.1058 29.7864 11.8326 28.5964 12.4586 27.3422C11.2663 26.8899 10.1242 26.3318 9.04512 25.6838C9.3314 25.4721 9.61143 25.2508 9.88197 25.023C16.4637 28.096 23.6148 28.096 30.1179 25.023C30.3916 25.2508 30.6716 25.4721 30.9547 25.6838C29.8725 26.335 28.7273 26.8931 27.5349 27.3454C28.161 28.5964 28.8846 29.7897 29.7026 30.9091C33.0061 29.8826 36.3661 28.3141 39.83 25.7287C40.6606 16.9494 38.4111 9.33754 33.8838 2.58863ZM13.3553 21.0712C11.3795 21.0712 9.75925 19.23 9.75925 16.9878C9.75925 14.7457 11.3449 12.9013 13.3553 12.9013C15.3657 12.9013 16.9859 14.7424 16.9513 16.9878C16.9545 19.23 15.3657 21.0712 13.3553 21.0712ZM26.6446 21.0712C24.6688 21.0712 23.0485 19.23 23.0485 16.9878C23.0485 14.7457 24.6342 12.9013 26.6446 12.9013C28.6549 12.9013 30.2752 14.7424 30.2406 16.9878C30.2406 19.23 28.6549 21.0712 26.6446 21.0712Z" />
    </SvgIcon>
  );
});
