export const WEBSOCKET_ERROR_MESSAGE = 'websocket connection failed';

export function onWebSocketError(callback: () => void): void {
  const originalOnError = window.onerror;

  window.addEventListener('error', ({ message }) => {
    if (String(message).toLowerCase().includes(WEBSOCKET_ERROR_MESSAGE)) {
      callback();
    }
  });

  window.onerror = function (
    event: Event | string,
    source: string | undefined,
    lineno: number | undefined,
    colno: number | undefined,
    error: Error | undefined,
  ) {
    if (originalOnError) {
      originalOnError(event, source, lineno, colno, error);
    }

    if (
      error &&
      String(error.message).toLowerCase().includes(WEBSOCKET_ERROR_MESSAGE)
    ) {
      callback();
    }

    return true;
  };
}
