import Stack from '@mui/material/Stack';
import { useMatchPermissions } from '../../../../hooks/tournaments/useMatchPermissions';
import { OpenMatchLobbyButton } from './OpenMatchLobbyButton';
import { AddScreenshot } from '../../../data-entry-ui/specific/AddScreenshot';
import { OpenScoringButton } from './OpenScoringButton';
import { Fragment, useMemo } from 'react';

export const SessionActionable = () => {
  const { canAccessMatch, canScore, canUploadScreenshot } =
    useMatchPermissions();

  const actions = useMemo(() => {
    return (
      canAccessMatch && (
        <Stack direction="row" spacing={2}>
          <OpenMatchLobbyButton />
          {canUploadScreenshot && <AddScreenshot />}
          {canScore && <OpenScoringButton />}
        </Stack>
      )
    );
  }, [canAccessMatch, canScore, canUploadScreenshot]);

  return <Fragment>{actions}</Fragment>;
};
