import { useState, useEffect, useRef } from 'react';
import stringify from 'json-stringify-safe';

export type UseQuerySelectorOptions = {
  root: HTMLElement | ShadowRoot | 'suspend-observation';
  observeOptions?: MutationObserverInit;
  debounceMs?: number;
};

export const useQuerySelector = <TElement extends HTMLElement>(
  query: string,
  options: UseQuerySelectorOptions,
): TElement | null => {
  const {
    root,
    observeOptions = { childList: true, subtree: true },
    debounceMs = 10,
  } = options;

  const [element, setElement] = useState<TElement | null>(null);
  const observeOptionsRef = useRef(observeOptions);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (stringify(observeOptions) !== stringify(observeOptionsRef.current)) {
      observeOptionsRef.current = observeOptions;
    }
  }, [observeOptions]);

  useEffect(() => {
    if (root === 'suspend-observation') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      return;
    }

    const updateElement = () => {
      const newElement = root.querySelector<TElement>(query);
      if (newElement !== element) {
        setElement(newElement);
      }
    };

    const observer = new MutationObserver(() => {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
      timeoutRef.current = setTimeout(updateElement, debounceMs);
    });

    observer.observe(root, observeOptionsRef.current);
    updateElement();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      observer.disconnect();
    };
  }, [root, query, debounceMs, element]);

  return element;
};
