import { useMemo } from 'react';
import type {
  NonRedirectSignInMethod,
  GenericSignInMethod,
  CustomSignInMethod,
  UserProviderInfo,
} from 'functions/src/types/firestore/User';
import { useAuth } from '../../contexts/AuthContext';
import { connectedProvidersOf } from '../../../functions/src/util/auth/connectedProvidersOf';
import { extractProviderIds } from '../../../functions/src/util/auth/extractProviderIds';
import { filterNonRedirectSignInMethods } from '../../util/auth/signInMethodFilter';

export type ProviderOptions = {
  top: NonRedirectSignInMethod[];
  generic: GenericSignInMethod[];
  custom: CustomSignInMethod[];
};

export const PROVIDER_OPTIONS_ALL: ProviderOptions = {
  top: ['password', 'wallet', 'phone'],
  generic: ['apple', 'google'],
  custom: ['discord', 'twitch', 'epic'],
} as const;

export const useAuthProviders = () => {
  const { user, userData } = useAuth();

  const providersConnected = useMemo<UserProviderInfo[] | undefined>(() => {
    if (!user || !userData) {
      return undefined;
    }
    return connectedProvidersOf(userData, user);
  }, [user, userData]);

  const providerOptions = useMemo<ProviderOptions>(() => {
    if (!user) {
      return PROVIDER_OPTIONS_ALL;
    }

    if (providersConnected) {
      const providerIds = extractProviderIds(providersConnected);
      const idsWithoutWallet = providerIds.filter((id) => {
        return id !== 'wallet';
      });
      const topOptions = filterNonRedirectSignInMethods(idsWithoutWallet);
      const { email, emailVerified } = user;
      const hasNoPassword =
        !!email &&
        !!emailVerified &&
        !idsWithoutWallet.some((id) => {
          return id === 'password';
        });

      return {
        top: hasNoPassword
          ? topOptions.filter((option) => {
              return option !== 'password';
            })
          : topOptions,
        generic: PROVIDER_OPTIONS_ALL.generic,
        custom: PROVIDER_OPTIONS_ALL.custom,
      };
    }

    return PROVIDER_OPTIONS_ALL;
  }, [providersConnected, user]);

  return { providerOptions, providersConnected };
};
