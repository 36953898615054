import { useCallback } from 'react';
import { useWagmiFunc } from '../web3/useWagmiFunc';

export const useSignOut = () => {
  const disconnect = useWagmiFunc('disconnect');

  const signOutFirebase = useCallback(async () => {
    const authImport = import('../../config/firebase-client/auth');
    const { auth } = await authImport;
    const firebaseAuthImport = import('firebase/auth');
    const { signOut: signOutUser } = await firebaseAuthImport;

    return await signOutUser(auth);
  }, []);

  const signOut = useCallback(async () => {
    await Promise.all([disconnect(), signOutFirebase()]);
  }, [disconnect, signOutFirebase]);

  return { signOut };
};
