import { User } from '../../../functions/src/types/firestore/User';

export const DATADOG_USER = ['username', 'email', 'id'] as const;

export type DatadogUser = Record<
  typeof DATADOG_USER[number],
  string | undefined
>;

export const extractDatadogUser = (userData?: User<Date>) => {
  const { username, email, id } = userData || {};
  return userData ? { username, email, id } : undefined;
};
