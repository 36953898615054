import { memo } from '../../util/memo';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsRounded';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { PayoutSummary } from './PayoutSummary';
import {
  PRIZES_DEFAULT,
  PayoutFormatted,
} from '../../../functions/src/types/firestore/Game/Competition';

// export const NumberOfRounds = Math.ceil(
//   Math.log(NumberOfTeams as number) / Math.log(2),
// );

export type RoundKind = 'final' | 'semifinal' | 'normal' | 'loser';

export type RoundStepProps = {
  kind?: RoundKind;
  payout?: PayoutFormatted;
  isActiveRound?: boolean;
  roundNumber: number;
  isReversed?: boolean;
  progress?: number;
  invert?: boolean;
};

export const RoundStep = memo(function RoundStepUnmemoized(
  props: RoundStepProps,
) {
  const {
    kind = 'normal',
    payout = PRIZES_DEFAULT,
    isActiveRound = false,
    roundNumber,
    isReversed = false,
    invert = false,
    progress = 0,
  } = props;
  const theme = useTheme();

  const progressFormatted = progress <= 1 ? progress * 100 : progress;

  const isFinalRound = kind === 'final';
  const isSemiFinalRound = kind === 'semifinal';
  const width = isFinalRound ? undefined : '100%';
  const payoutFormatted = payout;
  // const numberOfMatches = numberOfTeams / 2;
  // const progressValue: number = ((matchesCompleted || 0) / numberOfMatches) * 100;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isReversed ? 'row-reverse' : 'row',
        justifyContent: isFinalRound ? 'center' : !isReversed ? 'start' : 'end',
        alignItems: invert ? 'end' : 'start',
        width,
        maxWidth: isSemiFinalRound ? undefined : '256px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: invert ? 'column-reverse' : 'column',
          justifyContent: isReversed ? 'flex-end' : 'flex-start',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          px: 2,
        }}
      >
        {isFinalRound && (
          <EmojiEventsIcon
            htmlColor={
              isActiveRound
                ? theme.palette.text.primary
                : theme.palette.text.secondary
            }
          />
        )}
        <Typography
          variant="body1"
          color={isActiveRound ? 'text.primary' : 'text.secondary'}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {invert ? 'R' : 'Round '}
          {roundNumber}
        </Typography>
        {!isFinalRound && !!payout && <PayoutSummary {...payoutFormatted} />}
      </Box>
      {!isFinalRound && (
        <Grid
          container
          sx={{
            mt: invert ? 0 : 2.5,
            mb: invert ? 2.5 : 0,
            width: '100%',
            minWidth: '24px',
          }}
        >
          <Grid xs item>
            <LinearProgress
              variant="determinate"
              value={progressFormatted}
              sx={{
                transform: isReversed ? 'scale(-1, 1)' : undefined,
                height: '4px',
                background: theme.palette.background.elevationSolid['16'],
                '& .MuiLinearProgress-barColorPrimary': {
                  background: theme.palette.primary.horizontal,
                  boxShadow: 'none',
                  borderRadius: '4px',
                  width: `${progressFormatted}% !important`,
                  transform: 'translateX(0%) !important',
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: isFinalRound
            ? 'column'
            : isReversed
            ? 'row-reverse'
            : 'row',
          justifyContent: isFinalRound ? 'center' : 'flex-start',
          alignItems: isFinalRound ? 'center' : undefined,
          gap: 2,
        }}
      >
        <Box>{isFinalRound && <EmojiEventsIcon sx={{ ml: 2.5 }} />}</Box>
        <Typography
          variant="body2"
          color={isActiveRound ? 'text.primary' : 'text.secondary'}
          sx={{
            width: !isFinalRound ? '25%' : 'undefined',
            position: isFinalRound ? 'absolute' : undefined,
            mt: isFinalRound ? 12 : undefined,
          }}
        >
          Round {roundNumber}
        </Typography>

        {!isFinalRound && (
          <Box sx={{ width: `75%` }}>
            <BorderLinearProgress
              variant="determinate"
              value={
                isReversed
                  ? 100 -
                    ((matchesCompleted ? matchesCompleted : 0) /
                      numberOfMatches) *
                      100
                  : ((matchesCompleted ? matchesCompleted : 0) /
                      numberOfMatches) *
                    100
              }
              sx={{ mt: 2 }}
            />
          </Box>
        )}
      </Box>
      {!isFinalRound && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: isReversed ? 'flex-end' : 'flex-start',
          }}
        >
          <GradientTypography
            variant="body2"
            gradientColor="secondary.vertical"
          >
            $ {prizeMoney}
          </GradientTypography>
        </Box>
      )} */}
    </Box>
  );
});
