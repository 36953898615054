import { memo } from '../../util/memo';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TwitchIcon = memo(function TwitchIconUnmemoized(
  props: SvgIconProps,
) {
  return (
    <SvgIcon viewBox="-8 -6 50 50" {...props}>
      <path
        width="32px"
        height="32px"
        d="M3.03703 0.759766L2.84703 1.25852L0.567031 7.33852L0.519531 7.45727V32.6798H8.87953V37.2398H14.5083L14.7458 37.026L19.092 32.6798H25.9083L26.1458 32.466L35.4795 23.1323V0.759766H3.03703ZM3.03703 0.759766H35.4795V23.1323L26.1458 32.466L19.092 32.6798L14.7458 37.026L8.87953 37.2398V32.6798H0.519531V7.45727L3.03703 0.759766ZM3.03703 0.759766L0.519531 7.45727V32.6798H8.87953V37.2398L14.7458 37.026L19.092 32.6798L26.1458 32.466L35.4795 23.1323V0.759766H3.03703ZM8.11953 5.31977H30.9195V20.9473L26.787 25.0798H17.6908L17.4533 25.2935L14.1995 28.5473V25.0798H8.11953V5.31977ZM14.1995 9.87977V20.5198H18.7595V9.87977H14.1995ZM21.7995 9.87977V20.5198H26.3595V9.87977H21.7995Z"
      />
    </SvgIcon>
  );
});
