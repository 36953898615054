import { AspectRatio } from './AspectRatio';

export function toConstituents(aspectRatio: AspectRatio) {
  return aspectRatio.split(/[:x]/).map(Number) as [number, number];
}

export function toFraction(aspectRatio: AspectRatio) {
  const [width, height] = toConstituents(aspectRatio);
  return width / height;
}
