import { Round } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { MatchBracket } from 'src/components/tournaments/match/MatchBracket';

export const generateBracketGrandFinal = (rounds: Round<Date>[]) => {
  const matches = (rounds[0].matches || []).map((match) => {
    return {
      matchId: match.id,
      match: <MatchBracket match={match} isLarge={true} />,
    };
  });
  return { matches };
};
