import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Link } from '../Link';
import { GradientTypography } from '../gradients/GradientTypography';
import { memo } from '../../util/memo';
import { ELLIPSIS_SX } from '../../util/string';
import { ReactNode, useCallback, useMemo } from 'react';
import { CallerHit } from './CallersVerticalCarousel';
import { useCallerConnection } from '../../hooks/voice-chat/useCallerConnection';
import { ResponseButtons } from '../ResponseButtons';
import { useRoledUser } from '../../hooks/messaging/useRoledUser';

export type CallerCardBaseProps = CallerHit & {
  children?: ReactNode;
  Figure: ReactNode;
};

const CallerCardBaseUnmemoized = ({
  Figure,
  children,
  ...hit
}: CallerCardBaseProps) => {
  const theme = useTheme();
  const { hasAnyRole } = useRoledUser();
  const { accept, reject } = useCallerConnection();

  const { username, status, id } = hit;

  const acceptRequest = useCallback(async () => {
    return await accept(id);
  }, [accept, id]);

  const rejectRequest = useCallback(async () => {
    return await reject(id);
  }, [reject, id]);

  const requestResponseButton = useMemo(() => {
    return (
      (status === 'requested' || status === 'rejected') &&
      (hasAnyRole ? (
        <ResponseButtons
          onAccept={acceptRequest}
          onReject={rejectRequest}
          disableReject={status === 'rejected'}
          buttonSx={{
            height: '24px',
            width: '24px',
            '.MuiSvgIcon-root': {
              mb: '6px !important',
            },
          }}
          iconSx={{ height: '18px', width: '18px' }}
        />
      ) : (
        <Chip
          label={status}
          sx={{
            backgroundColor: theme.palette.background.elevation[14],
            width: '100px',
            height: '42px',
            '.MuiChip-label': {
              p: 0,
            },
          }}
        />
      ))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, hasAnyRole, acceptRequest, rejectRequest]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ px: 2, height: '48px' }}
    >
      <Link style={{ textDecoration: 'none' }} href={`/profile/${id}`}>
        <Stack direction="row" spacing={4} alignItems="center">
          {Figure}
          <GradientTypography
            variant="subtitle1"
            sx={{ maxWidth: '272px', ...ELLIPSIS_SX }}
          >
            {username}
          </GradientTypography>
        </Stack>
      </Link>
      <Stack direction="row" alignItems="center" spacing={0}>
        {children}
        {requestResponseButton}
      </Stack>
    </Stack>
  );
};

export const CallerCardBase = memo(CallerCardBaseUnmemoized);
