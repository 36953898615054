import type { Team } from '../types/firestore/Game/Tournament/Guestlist';

export const findUserInList = <TTime>(
  userList: Team<TTime>[],
  userId: string,
) => {
  return userList.find((team) => {
    return team.acceptedUserIds?.includes(userId);
  });
};
