import {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../../../util/memo';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/EditRounded';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { LottieLoader } from 'src/components/LottieLoader';
import CheckIcon from '@mui/icons-material/CheckRounded';
import { GradientIconButton } from 'src/components/gradients/GradientIconButton';
import { truncateIfTooLong } from '../../../util/truncate';
import { useAlertDialog } from '../../../hooks/useAlertDialog';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';

const ALREADY_TAKEN_DIALOG_ID = 'TEAM_NAME_ALREADY_TAKEN_DIALOG';

function useHandleClickOutsideForm(
  ref: MutableRefObject<HTMLFormElement>,
  handler: () => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}

export const TeamNameInput = memo(function TeamNameInputUnmemoized() {
  const { phase, foundTeam } = useTournamentRegistration();
  const { name: teamName, id: teamId } = foundTeam!;
  const { setTeamName } = useRegistrationFunctions();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const allowEdit = useMemo(() => {
    return phase === 'checkIn' || phase === 'registration';
  }, [phase]);

  const formRef = useRef() as MutableRefObject<HTMLFormElement>;
  useHandleClickOutsideForm(formRef, () => {
    setEditing(false);
  });

  const { open: openAlreadyTakenDialog } = useAlertDialog(
    ALREADY_TAKEN_DIALOG_ID,
  );

  const submitForm = useCallback(
    async (teamNameValue: string) => {
      setLoading(true);
      const result = await setTeamName(teamNameValue, teamId);
      if (result === 'already-taken') {
        openAlreadyTakenDialog({
          title: 'Team name already taken',
          description:
            'The team name you have chosen has already been taken by another team. Please choose a different name.',
        });
      } else {
        setEditing(false);
      }
      setLoading(false);
    },
    [openAlreadyTakenDialog, setTeamName, teamId],
  );

  return !teamName || teamName.length === 0 || editing ? (
    <>
      <form
        ref={formRef}
        onKeyUp={(e) => {
          if (e.code === 'Escape') {
            if (!editing) {
              return;
            }
            setEditing(false);
          }
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <TextField
            id="team-name"
            name="team-name"
            type="text"
            color="primary"
            placeholder="Enter team name..."
            variant="outlined"
            size="small"
            sx={{
              width: '220px',
              '& *': {
                borderRadius: '4px !important',
              },
            }}
          />
          <GradientIconButton
            onClick={() => {
              return submitForm(formRef.current['team-name'].value);
            }}
            color="primary"
            aria-label="submit team name"
            sx={{ ml: 1 }}
            IconComponent={CheckIcon}
          />
          {loading && (
            <LottieLoader sx={{ height: '24px', width: '24px', ml: 2 }} />
          )}
        </Box>
      </form>
    </>
  ) : (
    <Typography
      display="inline"
      variant="h5"
      color="text.primary"
      onClick={() => {
        return allowEdit && setEditing(true);
      }}
      sx={{
        ':hover': { cursor: 'pointer' },
        pb: 2,
      }}
    >
      {truncateIfTooLong(teamName, 26)}

      {allowEdit && (
        <Box
          sx={{ display: 'inline' }}
          onClick={() => {
            return setEditing(true);
          }}
        >
          <GradientIcon
            sx={{
              fontSize: '16px',
              marginLeft: '8px',
              ':hover': { cursor: 'pointer' },
            }}
            IconComponent={EditIcon}
          />
        </Box>
      )}
    </Typography>
  );
});
