import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
export const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:last-child': {
    borderBottom: 'none',
  },
}));
