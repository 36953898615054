import { useCallback, useRef } from 'react';
import { injectAds, InjectAdsProps } from '../../util/ads/injectAds';
// import { generateNonAdjacentPositions } from '../../../functions/src/util/generateNonAdjacentPositions';
// import { unshiftAdPositions } from '../../util/ads/unshiftAdPositions';
import { areEqual } from '../../../functions/src/util/algolia/areEqual';
// import { didAppendElements } from '../../../functions/src/util/algolia/didAppendElements';
import { OrNode } from '../../../functions/src/types/Hit';
import { stableHash } from '../../../functions/src/util/hash/stableHash';
// import { didUnshiftElements } from '../../../functions/src/util/algolia/didUnshiftElements';
import { ResponsiveAdDimensions } from '../../components/ads/AdContainer';
// import { randomIntInRange } from '../../../functions/src/util/random';
// import { appendAdPositions } from '../../util/ads/appendAdPositions';

export type UseAdInjectionProps = {
  adInterval: number;
} & Omit<InjectAdsProps<unknown>, 'elements' | 'adPositions'> &
  ResponsiveAdDimensions;

/**
 * @remark
 * WARNING: This hook uses shared state internally and is not designed to be used
 * with multiple InstantSearch contexts simultaneously. Using it for more than one
 * InstantSearch context may lead to race conditions and unexpected behavior.
 * If you need to use this hook in multiple contexts, consider creating separate
 * instances of the hook for each context or refactoring your components to avoid
 * shared state issues.
 */
export const useAdInjection = <TElement>(props: UseAdInjectionProps) => {
  const prevElementsRef = useRef<TElement[]>([]);
  const prevElementsHashesRef = useRef<string[]>([]);
  const prevAdPositionsRef = useRef<Set<number>>(new Set());
  const prevInjectedHitsRef = useRef<OrNode<TElement>[]>([]);

  return useCallback(
    (elements: TElement[]) => {
      const { adInterval, ...injectProps } = props;

      // const prevElements = prevElementsRef.current;
      const prevElementsHashes = prevElementsHashesRef.current;
      // const prevAdPositions = prevAdPositionsRef.current;
      const prevInjectedHits = prevInjectedHitsRef.current;

      const currentElementsHashes = elements.map(stableHash);

      if (areEqual(prevElementsHashes, currentElementsHashes)) {
        return prevInjectedHits;
      }

      const adPositions = new Set([]);
      //  (() => {
      //   if (elements.length <= adInterval) {
      //     if (prevAdPositions.size === 0) {
      //       const randomPosition = randomIntInRange(0, elements.length - 1);
      //       return new Set([randomPosition]);
      //     }
      //     return prevAdPositions;
      //   }

      //   if (didUnshiftElements(prevElementsHashes, currentElementsHashes)) {
      //     return unshiftAdPositions({
      //       currentAdPositions: prevAdPositions,
      //       numNewElements: elements.length - prevElements.length,
      //       adInterval,
      //     });
      //   }

      //   if (didAppendElements(prevElementsHashes, currentElementsHashes)) {
      //     const numNewElements = elements.length - prevElements.length;
      //     const totalElements = elements.length;

      //     return appendAdPositions({
      //       currentAdPositions: prevAdPositions,
      //       numNewElements,
      //       totalElements,
      //       adInterval,
      //     });
      //   }
      //   const expectedAdCount = Math.floor(elements.length / adInterval);
      //   return generateNonAdjacentPositions({
      //     maxIndex: elements.length - 1,
      //     numEntities: expectedAdCount,
      //   });
      // })();

      const injectedHits = injectAds({
        elements,
        adPositions,
        ...injectProps,
      });

      prevElementsRef.current = elements;
      prevElementsHashesRef.current = currentElementsHashes;
      prevAdPositionsRef.current = adPositions;
      prevInjectedHitsRef.current = injectedHits;
      return injectedHits;
    },
    [props],
  );
};
