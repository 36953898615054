import { useCallback, useMemo, useState } from 'react';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { useAsyncValidation } from '../registration/useAsyncValidation';

export const useValidateOption = () => {
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const {
    registrationOptions: options,
    gameId,
    requirementTokens,
  } = useTournamentRegistration();

  const defaultValidityState = useMemo(() => {
    return options.reduce((prev, curr) => {
      prev[curr.id] = { error: false, valid: curr.optional };
      return prev;
    }, {});
  }, [options]);

  const [optionsValidity, setOptionsValidity] = useState<{
    [key in string]?: { error: boolean; valid: boolean; message?: string };
  }>(defaultValidityState);

  const validateAsyncMap = useAsyncValidation(gameId);
  const updateValidity = (
    key: string,
    state: { error: boolean; valid: boolean; message?: string },
  ) => {
    return setOptionsValidity((prev) => {
      return {
        ...prev,
        [key]: state,
      };
    });
  };
  const validate = useCallback(
    async (
      id: string,
      regex: string,
      value: string,
      regexExplained?: string,
    ) => {
      if (
        !options.find((option) => {
          return option.id === id;
        })
      ) {
        return;
      }
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regexMatch = !!value.match(new RegExp(regex, 'g'));
      const helperText = regexExplained || 'Invalid input';
      const validation =
        id in validateAsyncMap
          ? (setIsLoading({ [id]: true }),
            await validateAsyncMap[String(id)](value, requirementTokens))
          : {
              valid: regexMatch,
              errorMsg: regexMatch ? undefined : helperText,
            };
      updateValidity(id, {
        error: !validation.valid,
        valid: validation.valid,
        message: !validation.valid ? validation.errorMsg : undefined,
      });
      setIsLoading({ [id]: false });
    },
    [options, requirementTokens, validateAsyncMap],
  );

  return { isLoading, optionsValidity, validate, validateAsyncMap };
};
