import { memo } from '../../../../util/memo';
import { ColumnDesktop } from './ColumnDesktop';
import { ColumnProps } from './ColumnWrapper';

function ColumnCenterUnmemoized({ children }: ColumnProps) {
  return (
    <ColumnDesktop width={354} justifyContent="center">
      {children}
    </ColumnDesktop>
  );
}

export const ColumnCenter = memo(ColumnCenterUnmemoized);
