import Box from '@mui/material/Box';
import { useMemo, ReactNode } from 'react';
import {
  LivestreamPlayer,
  LIVESTREAM_PLAYER_HEIGHT_DESKTOP,
  LIVESTREAM_PLAYER_HEIGHT,
  LIVESTREAM_PLAYER_HEIGHT_MOBILE,
} from '../../components/livestream/mux/LivestreamPlayer';
import { ContentCarousel } from '../../components/ContentCarousel';
import { LivestreamProvider } from '../../contexts/LivestreamContext';
import { PlaybacksDownloadable } from '../../../functions/src/types/firestore/Game/Tournament';
import { extractPlaybackIds } from '../../../functions/src/util/mux/extractPlaybackIds';
import { usePictureInPicture } from '../../contexts/PictureInPictureContext';

const BUTTON_SX = { height: '28px', width: '28px' } as const;

export type UseLivestreamPlayerParams = {
  placeholder?: ReactNode;
  playbacksDownloadable?: PlaybacksDownloadable;
};

export const useLivestreamPlayer = ({
  playbacksDownloadable,
  placeholder,
}: UseLivestreamPlayerParams) => {
  const { isPictureInPicture } = usePictureInPicture();

  const playbackIds = useMemo(() => {
    return extractPlaybackIds(playbacksDownloadable);
  }, [playbacksDownloadable]);

  const livestreamCarousel = useMemo(() => {
    if (!playbackIds.length) {
      return placeholder ?? undefined;
    }

    const carouselContent = playbackIds.map((playbackId) => {
      return (
        <LivestreamProvider
          key={playbackId}
          playbackId={playbackId}
          playbacksDownloadable={playbacksDownloadable}
        >
          <LivestreamPlayer />
        </LivestreamProvider>
      );
    });

    const hasMultipleStreams = playbackIds.length > 1;

    return (
      <Box
        sx={{
          height: '100%',
          display: isPictureInPicture ? 'none' : 'block',
          '#carousel-navigation': { mt: -3 },
        }}
      >
        <ContentCarousel
          content={carouselContent}
          buttonSx={BUTTON_SX}
          sx={{
            height: {
              xs: LIVESTREAM_PLAYER_HEIGHT_MOBILE,
              md: hasMultipleStreams
                ? LIVESTREAM_PLAYER_HEIGHT_DESKTOP
                : LIVESTREAM_PLAYER_HEIGHT,
            },
          }}
        />
      </Box>
    );
  }, [playbackIds, isPictureInPicture, placeholder, playbacksDownloadable]);

  return livestreamCarousel;
};
