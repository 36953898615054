import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useMergeIndexingHits } from '../../hooks/algolia/useMergeIndexingHits';
import { useDynamic } from '../../hooks/useDynamic';
import type { CollectionReference, Query } from 'firebase/firestore';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import {
  HITS_PER_PAGE_DEFAULT,
  PathSort,
} from 'functions/src/util/algolia/preemption/templates';
import { Hit } from '../../../functions/src/types/Hit';
import { useCachedHits } from '../../hooks/algolia/useCachedHits';

export const SORT_DEFAULT = {
  fieldPath: 'id',
  order: 'asc',
} as const;

export const useFirestoreHits = (
  pathSort?: PathSort,
  hitsPerPage: number = HITS_PER_PAGE_DEFAULT,
  filters?: string,
) => {
  const firestoreModule = useDynamic(
    import('../../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  const [totalHits, setTotalHits] = useState(hitsPerPage);

  const preemptionQuery = useMemo(() => {
    if (!firestoreModule || !firebaseFirestoreModule || !pathSort) {
      return undefined;
    }
    const { path, sort = SORT_DEFAULT } = pathSort;

    const { firestore } = firestoreModule;
    const { collection, query, limit, orderBy } = firebaseFirestoreModule;

    return query(
      collection(firestore, path) as CollectionReference<Hit>,
      orderBy(sort.fieldPath, sort.order),
      limit(totalHits),
    ).withConverter(ConverterFactory.buildDateConverter()) as Query<Hit>;
  }, [firebaseFirestoreModule, firestoreModule, totalHits, pathSort]);

  const [isLoading, setIsLoading] = useState(true);
  const canLoadMoreRef = useRef(true);

  const hitsFromCache = useCachedHits();
  const [hitsFirestore, setHitsFirestore] = useState<Hit[]>(
    hitsFromCache || [],
  );

  useEffect(() => {
    if (!firestoreModule || !firebaseFirestoreModule || !preemptionQuery) {
      return;
    }
    const { onSnapshot } = firebaseFirestoreModule;

    setIsLoading(true);

    return onSnapshot(
      preemptionQuery,
      (snapshot) => {
        const newHits = snapshot.docs.map((doc) => {
          return doc.data();
        });

        canLoadMoreRef.current = newHits.length === totalHits;

        setHitsFirestore(newHits);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      },
    );
  }, [firebaseFirestoreModule, firestoreModule, totalHits, preemptionQuery]);

  const hits = useMergeIndexingHits(hitsFirestore, filters);

  const hitsRef = useRef(hits);
  useEffect(() => {
    hitsRef.current = hits;
  }, [hits]);

  const preemptionQueryRef = useRef(preemptionQuery);
  useEffect(() => {
    preemptionQueryRef.current = preemptionQuery;
  }, [preemptionQuery]);

  const loadMore = useCallback(() => {
    if (!canLoadMoreRef.current || !preemptionQueryRef.current) {
      return Promise.resolve(0);
    }

    canLoadMoreRef.current = false;
    const previousHits = hitsRef.current;

    setTotalHits((prevTotalHits) => {
      return prevTotalHits + hitsPerPage;
    });

    return new Promise<number>((resolve) => {
      const checkForNewHits = () => {
        if (hitsRef.current !== previousHits) {
          const newHitsCount = hitsRef.current.length - previousHits.length;
          resolve(newHitsCount);
          return;
        }

        requestAnimationFrame(checkForNewHits);
      };

      checkForNewHits();
    });
  }, [hitsPerPage]);

  return useMemo(() => {
    return { hits, loadMore, isLoading };
  }, [hits, loadMore, isLoading]);
};
