// components/SelectableTournamentDateWrapper.tsx

import { FC, ReactElement, useCallback } from 'react';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { SelectableRouted } from '../../routing/SelectableRouted';
import { memo } from '../../../util/memo';
export type SelectableEventProps = {
  id: string;
  gameId: string;
  date: Date;
};

export type SelectableEventWrapperProps = {
  children: ReactElement<SelectableEventProps>;
};

const SelectableEventWrapperUnmemoized: FC<SelectableEventWrapperProps> = ({
  children,
}) => {
  const { id, gameId, date } = children.props;
  const [_, setCalendarDateParam] = useRouterState({
    key: 'event-date',
    silent: true,
  });

  const changeEventDate = useCallback(
    (__: unknown, isSelected: boolean) => {
      if (isSelected) {
        setCalendarDateParam(new Date(date).getTime().toString());
      }
    },
    [date, setCalendarDateParam],
  );

  return (
    <SelectableRouted
      value={`Game/${gameId}/Tournament/${id}`}
      onChange={changeEventDate}
      queryParamKey="event"
      borderRadius="10px"
    >
      {children}
    </SelectableRouted>
  );
};

export const SelectableEventWrapper = memo(SelectableEventWrapperUnmemoized);
