import stringify from 'json-stringify-safe';

export const findItem = <TValue>(pattern: RegExp): TValue | null => {
  if (typeof window === 'undefined') {
    return null;
  }
  const foundKey = Object.keys(window.localStorage || {}).find((key) => {
    return pattern.test(key);
  });
  if (!foundKey) {
    return null;
  }
  const valueString = window.localStorage[String(foundKey)] as string;
  return JSON.parse(valueString) as TValue;
};

export const getItem = <TValue>(key: string): TValue | null => {
  if (typeof window === 'undefined') {
    return null;
  }
  const valueString = window.localStorage.getItem(key);
  if (!valueString) {
    return null;
  }
  return JSON.parse(valueString) as TValue;
};

export const removeItem = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.removeItem(key);
};

export const setItem = <TValue>(key: string, value: TValue) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.setItem(key, stringify(value));
};

export const removeItemByPattern = (pattern: RegExp) => {
  if (typeof window === 'undefined') {
    return;
  }
  const foundKey = Object.keys(window.localStorage || {}).find((key) => {
    return pattern.test(key);
  });
  if (foundKey) {
    window.localStorage.removeItem(foundKey);
  }
};
