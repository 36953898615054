import { memo } from '../../../util/memo';
import { WithCancelIcon } from '../../organizer/prize-pools/prize-pool-page/WithCancelIcon';
import { SponsorWrapperV3 } from '../../tournaments/SponsorWrapperV3';
import { EventImageInput } from '../generic/EventImageInput';
import { useMemo } from 'react';

export const SPONSOR_PLACEHOLDER_NAME = 'PLACEHOLDER';

export type SponsorInputProps = {
  onRemove: () => Promise<void>;
  onChange: (urlNew: string) => Promise<void>;
  fieldName: string;
  imgUrl: string;
  name: string;
};

export const SponsorInput = memo(function SponsorInputUnmemoized({
  onRemove,
  onChange,
  fieldName,
  imgUrl,
  name,
}: SponsorInputProps) {
  const imageInput = useMemo(() => {
    return (
      <EventImageInput
        fieldName={fieldName}
        value={imgUrl}
        onChange={onChange}
        imageProperties={{ height: 166 * 0.8, width: 166 * 0.8 }}
        required={name !== SPONSOR_PLACEHOLDER_NAME}
      />
    );
  }, [fieldName, imgUrl, onChange, name]);

  return (
    <SponsorWrapperV3>
      {name !== SPONSOR_PLACEHOLDER_NAME ? (
        <WithCancelIcon onRemove={onRemove}>{imageInput}</WithCancelIcon>
      ) : (
        imageInput
      )}
    </SponsorWrapperV3>
  );
});
