import { useCallback } from 'react';
import { memo } from '../../../../util/memo';
import { GradientIconButton } from '../../../gradients/GradientIconButton';
import { LoadingIconButton } from '../../../buttons/LoadingIconButton';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useSetMatchScreenshot } from '../../../../hooks/tournaments/useSetMatchScreenshot';

export type DeleteScreenshotProps = {
  screenshotUrl: string;
};

export const DeleteScreenshot = memo(function DeleteScreenshotUnmemoized({
  screenshotUrl,
}: DeleteScreenshotProps) {
  const { deleteScreenshot } = useSetMatchScreenshot();
  const deleteUrl = useCallback(() => {
    return deleteScreenshot(screenshotUrl);
  }, [deleteScreenshot, screenshotUrl]);

  return (
    <LoadingIconButton
      onClick={deleteUrl}
      sx={{
        p: 0,
      }}
    >
      <GradientIconButton
        IconComponent={DeleteIcon}
        gradientColor="error.horizontal"
      />
    </LoadingIconButton>
  );
});
