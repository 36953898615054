import { memo } from '../../../util/memo';
import { AllSignInMethod } from '../../../../functions/src/types/firestore/User';
import { AuthenticationOption } from './AuthenticationOption';

export type AuthenticationOptionsProps = {
  options: AllSignInMethod[];
};

export const AuthenticationOptions = memo(
  function AuthenticationOptionsUnmemoized({
    options,
  }: AuthenticationOptionsProps) {
    return (
      <>
        {options.map((providerId) => {
          return (
            <AuthenticationOption providerId={providerId} key={providerId} />
          );
        })}
      </>
    );
  },
);
