import { GenericSignInMethod } from '../../../functions/src/types/firestore/User';
import { fetchOAuthProvider } from './genericOAuthSignIn';

export const webSignInWith = async (provider: GenericSignInMethod) => {
  const { auth } = await import('../../config/firebase-client/auth');
  const OAuthProvider = await fetchOAuthProvider(provider);
  const firebaseAuth = await import('firebase/auth');
  const { currentUser } = auth;
  const { signInWithPopup, linkWithPopup } = firebaseAuth;
  if (!currentUser || currentUser.isAnonymous) {
    return signInWithPopup(auth, OAuthProvider);
  }
  // const { providerData: currentProviderData } = currentUser;
  // const isProviderLinked = currentProviderData.some(({ providerId }) => {
  //   return providerId === `${provider}.com`;
  // });
  // if (isProviderLinked) {
  //   const userCredential = await reauthenticateWithPopup(
  //     currentUser,
  //     OAuthProvider,
  //   );
  //   return userCredential;
  // } //TODO: Revisit and refactor this. Right now we sign the user out and request to sign in
  return linkWithPopup(currentUser, OAuthProvider);
};
