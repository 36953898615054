import { memo } from '../../util/memo';
import { GradientIconButton } from '../gradients/GradientIconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MoreOptionsMenu } from './MoreOptionsMenu';
import { useRef, useState, useCallback } from 'react';

const MoreOptionsButtonUnmemoized = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef(null);

  const setAnchor = useCallback(() => {
    return setAnchorEl(anchorRef.current);
  }, []);

  const resetAnchor = useCallback(() => {
    return setAnchorEl(null);
  }, []);

  return (
    <>
      <GradientIconButton
        IconComponent={MoreHorizIcon}
        ref={anchorRef}
        onClick={setAnchor}
      />
      <MoreOptionsMenu anchorElement={anchorEl} onClose={resetAnchor} />
    </>
  );
};

export const MoreOptionsButton = memo(MoreOptionsButtonUnmemoized);
