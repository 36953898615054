import { usePreemptState } from '../../contexts/algolia/PreemptStateContext';
import { useAlgoliaLocalStorage } from './useAlgoliaLocalStorage';
import { useConvertedHits } from './useConvertedHits';

/**
 * @remarks
 * undefined indicates that the cache may be
 * in IndexDB but IndexDB has not finished initializing.
 */
export function useCachedHits() {
  const state = usePreemptState();
  const { readCache } = useAlgoliaLocalStorage();

  const cache = readCache(state);

  const hitsUnconverted = cache?.[0] || [];
  const hitsConverted = useConvertedHits(hitsUnconverted);

  return cache !== undefined ? hitsConverted : undefined;
}
