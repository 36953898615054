import { useCallback, useMemo } from 'react';
import { useFindChannelId } from './useFindChannelId';
import { useFindChannelGroup } from './useFindChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export const useActiveTournamentChannel = (tournamentId?: string) => {
  const { findByTournamentId } = useFindChannelGroup();
  const { findGeneralChannelId, findMatchChannelId } = useFindChannelId();
  const { openChannelGroup } = useActiveChannelGroup();

  const { tournamentChannelGroup, teamChannelGroup } = useMemo(() => {
    return {
      tournamentChannelGroup: findByTournamentId(tournamentId, 'general'),
      teamChannelGroup: findByTournamentId(tournamentId, 'personalGroup'),
    };
  }, [findByTournamentId, tournamentId]);

  const openGeneralChannel = useCallback(() => {
    if (!tournamentId || !tournamentChannelGroup) {
      return;
    }

    const generalChannelId = findGeneralChannelId(tournamentId);

    openChannelGroup(tournamentChannelGroup.id, generalChannelId);
  }, [
    findGeneralChannelId,
    openChannelGroup,
    tournamentChannelGroup,
    tournamentId,
  ]);

  const openMatchChannel = useCallback(
    (matchId: string) => {
      if (!tournamentId || !tournamentChannelGroup) {
        return;
      }

      const matchChannelId = findMatchChannelId(matchId);
      if (!matchChannelId) {
        return;
      }

      openChannelGroup(tournamentChannelGroup.id, matchChannelId);
    },
    [
      findMatchChannelId,
      openChannelGroup,
      tournamentChannelGroup,
      tournamentId,
    ],
  );

  const openTeamChannel = useCallback(() => {
    if (!teamChannelGroup) {
      return;
    }

    openChannelGroup(teamChannelGroup.id);
  }, [openChannelGroup, teamChannelGroup]);

  return {
    openGeneralChannel,
    openMatchChannel,
    openTeamChannel,
    teamChannelGroup,
    tournamentChannelGroup,
  };
};
