import Badge from '@mui/material/Badge';
import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from '../../../util/memo';
export type PendingInvitesHeaderProps = {
  contentLength?: number;
};

export const PendingInvitesHeader = memo(
  function PendingInvitesHeaderUnmemoized({
    contentLength,
  }: PendingInvitesHeaderProps) {
    return (
      <Badge
        badgeContent={contentLength}
        variant="standard"
        // TODO:  uncomment once
        // sx={{ flex: 2 }}
      >
        <GradientTypography
          gradientColor="primary.vertical"
          variant="h6"
          fontWeight={600}
          textTransform="uppercase"
        >
          PENDING INVITES
        </GradientTypography>
      </Badge>
    );
  },
);
