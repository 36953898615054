import { memo } from '../../../util/memo';
import { Token } from '../../../../functions/src/types/firestore/User/Payout';
import { useCurrency } from '../../../contexts/CurrencyContext';
import { PayoutRowHeader } from '../../payouts/PayoutRowHeader';
import { PayoutRowLayout } from '../../payouts/PayoutRowLayout';
import { PayoutInputPrizeGrid } from './PayoutInputPrizeGrid';
import { PayoutInputWarning } from './PayoutInputWarning';
import { usePayoutDivisibility } from '../../../hooks/payouts/usePayoutDivisibility';

export type PayoutRowProps = {
  ranks: number[];
  tokens: Token[];
  countPlaceholders: number;
  teamSize: number;
  onAddTokens?: () => void;
  onRemoveToken: (tokenIndex: number) => void;
};

export const PayoutRow = memo(function PayoutRowUnmemoized({
  ranks,
  tokens,
  countPlaceholders,
  teamSize,
  onAddTokens,
  onRemoveToken,
}: PayoutRowProps) {
  const [rankLower, rankUpper] = ranks;
  const { formatPayout } = useCurrency();
  const payoutFormatted = formatPayout(tokens);
  const countWinners = Math.max(1, rankUpper - rankLower);
  const isDivisible = usePayoutDivisibility({
    tokens,
    divisor: teamSize * countWinners,
  });
  return (
    <PayoutRowLayout
      Header={<PayoutRowHeader ranks={ranks} {...payoutFormatted} />}
      PrizeGrid={
        <PayoutInputPrizeGrid
          tokens={tokens}
          onAddTokens={onAddTokens}
          onRemoveToken={onRemoveToken}
          countPlaceholders={countPlaceholders}
        />
      }
      showWarning={!isDivisible}
      WarningContent={
        <PayoutInputWarning teamSize={teamSize} countWinners={countWinners} />
      }
    />
  );
});
