import { useEffect } from 'react';
import { useRouterState } from '../routing/useRouterState';
import { CustomSignInMethod } from '../../../functions/src/types/firestore/User';
import { isOnApp } from '../../util/isOnApp';
import { useAuth } from '../../contexts/AuthContext';
import { customOAuthSignIn } from '../../util/auth/customOAuthSignIn';
import { useLinkAdditionalAuthDialog } from './useLinkAdditionalAuthDialog';
import { useRemoveAuthQueryParams } from '../useRemoveAuthQueryParams';
import { useRouter } from '../routing/useRouter';

export const useOAuthCustomApp = () => {
  const { uid } = useAuth();
  const { push } = useRouter();
  const { open } = useLinkAdditionalAuthDialog();
  const { removeAuthQueryParams } = useRemoveAuthQueryParams();
  const [code] = useRouterState({
    key: 'code',
    location: 'queryParam',
  });

  const [customProvider] = useRouterState({
    key: 'customProvider',
    location: 'queryParam',
  });

  useEffect(() => {
    const handler = async () => {
      if (!code || !isOnApp() || !customProvider) {
        return;
      }
      try {
        const { issueCustomToken } = await import(
          '../../firebaseCloud/auth/issueCustomToken'
        );
        const { token } = await issueCustomToken({
          code,
          providerId: customProvider as CustomSignInMethod,
          isOnApp: isOnApp(),
        });
        removeAuthQueryParams();
        if (!uid) {
          await customOAuthSignIn(token);
          return;
        }
        open({ entryDialog: 'Link Successful' });
      } catch (e) {
        console.error(e);
        removeAuthQueryParams();
      } finally {
        await push('/');
      }
    };
    handler();
  }, [code, customProvider, open, push, removeAuthQueryParams, uid]);
};
