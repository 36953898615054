import { Team } from '../../types/firestore/Game/Tournament/Guestlist';

export const isAcceptedTeam = (userId: string, teams: Team<Date>[]) => {
  const alreadyInTeam = teams.some((team) => {
    const { userIds: memberIds, members } = team;
    return (
      memberIds.includes(userId) &&
      members.find(({ userId: memberId, status }) => {
        return (
          userId === memberId && status !== 'pending' && status !== 'declined'
        );
      })
    );
  });
  return alreadyInTeam;
};
