import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { ScoreOption } from '../../../../functions/src/types/firestore/Game/Competition';
import { Result } from '../../../../functions/src/types/firestore/Game/Tournament/Heat/Result';
import { HeatTop3 } from './HeatTop3';
import { HeatLeaderboardTable } from './HeatLeaderboardTable';
import { QualificationConditions } from '../../../../functions/src/types/firestore/Game/Tournament';
import { assignRanks } from '../../../../functions/src/util/tournaments/assignRanks';
import { SCORE_OPTION_DEFAULT } from '../../../../functions/src/types/firestore/Game/Tournament/Heat';

export type HeatResultsProps = {
  results: Result[];
  qualification?: QualificationConditions;
  scoreOptions?: ScoreOption[];
};

export const HeatResults = memo(function HeatResultsUnmemoized({
  results,
  qualification,
  scoreOptions = [SCORE_OPTION_DEFAULT],
}: HeatResultsProps) {
  const resultsRanked = useMemo(() => {
    return assignRanks(results, scoreOptions);
  }, [results, scoreOptions]);

  const top3Results = useMemo(() => {
    return resultsRanked.slice(0, 3);
  }, [resultsRanked]);

  return (
    <>
      <HeatTop3
        top3Results={top3Results}
        units={
          !!scoreOptions[0].units ? scoreOptions[0].units : scoreOptions[0].name
        }
      />
      <HeatLeaderboardTable
        results={resultsRanked}
        qualification={qualification}
        scoreOptions={scoreOptions}
      />
    </>
  );
});
