import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GradientIcon } from '../../gradients/GradientIcon';
import { memo } from '../../../util/memo';
import CheckIcon from '@mui/icons-material/CheckRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHighRounded';
import { useMessage } from './MessageContext';
import { AvatarGroupNext, AVATAR_GROUP_MAX } from '../../AvatarGroupNext';
import { useTheme } from '@mui/material/styles';
import { MessageTimestamp as StreamMessageTimestamp } from 'stream-chat-react';
import { useCallback, useMemo } from 'react';

const ICON_SX = {
  height: { xs: '20px', lg: '14px' },
  width: { xs: '20px', lg: '14px' },
};

const MessageStatusUnmemoized = () => {
  const theme = useTheme();
  const { message, readBy } = useMessage();
  const readByImgUrls = readBy
    ?.filter(({ image }) => {
      return !!image;
    })
    .map(({ image }) => {
      return image;
    }) as string[];

  const isSurplus = readByImgUrls.length > AVATAR_GROUP_MAX;

  const surplusAvatar = useCallback(
    (surplus: number) => {
      const surplusText = readByImgUrls.length > 9 ? `9+` : `+${surplus}`;
      return (
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {surplusText}
        </Typography>
      );
    },
    [readByImgUrls.length],
  );

  const status = useMemo(() => {
    return !!readBy?.length ? (
      <AvatarGroupNext
        imgUrls={readByImgUrls}
        avatarProps={{ width: 18, height: 18 }}
        avatarGroupProps={{ width: 18, height: 18 }}
        renderSurplus={surplusAvatar}
        avatarSx={{
          height: { xs: '24px', lg: '18px' },
          width: { xs: '24px', lg: '18px' },
        }}
        sx={{
          '.MuiAvatar-root:nth-of-type(1)': {
            height: 20,
            width: 20,
            ml: isSurplus ? '-4px' : '-8px',
            border: 'none !important',
            background: isSurplus
              ? theme.palette.background.elevation[24]
              : undefined,
          },
        }}
      />
    ) : (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            time: {
              fontSize: { xs: '18px', lg: '12px' },
              color: theme.palette.text.secondary,
            },
          }}
        >
          <StreamMessageTimestamp />
        </Box>
        {message.status === 'received' && (
          <GradientIcon IconComponent={CheckIcon} sx={ICON_SX} />
        )}
        {message.status === 'failed' && (
          <PriorityHighIcon
            sx={{ ...ICON_SX, color: theme.palette.error.main }}
          />
        )}
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurplus, message.status, readBy?.length, readByImgUrls, surplusAvatar]);

  return (
    <Box
      pt={1}
      sx={{
        '.MuiAvatar-root': {
          border: `2px solid ${theme.palette.background.elevationSolid[6]} !important`,
        },
        alignSelf: 'flex-end',
      }}
    >
      {status}
    </Box>
  );
};

export const MessageStatus = memo(MessageStatusUnmemoized);
