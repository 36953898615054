import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { OverlayAvatar } from './OverlayAvatar';

export type TextOverlayAvatarProps = {
  text: string;
  sx?: SxProps;
};

const TextOverlayAvatarUnmemoized = ({
  text,
  sx = {},
}: TextOverlayAvatarProps) => {
  return (
    <OverlayAvatar
      sx={{
        wordSpacing: { xs: '42px', md: '54px' },
        ...sx,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          wordBreak: 'break-word',
          textAlign: 'center',
          fontWeight: 600,
          lineHeight: { xs: '10px', md: '14px' },
          fontSize: { xs: '12px', md: '16px' },
          mt: -2,
          pt: { xs: '5.5px', md: '7px' },
        }}
      >
        {text}
      </Typography>
    </OverlayAvatar>
  );
};

export const TextOverlayAvatar = memo(TextOverlayAvatarUnmemoized);
