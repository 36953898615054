import { ComponentProps } from 'react';
import type { InstantSearch } from 'react-instantsearch';
import { buildPreemptionPathSort } from '../../../functions/src/util/algolia/preemption/buildPreemptionPathSort';
import { AlgoliaSearchParams } from '../../../functions/src/types/AlgoliaSearchParams';

// Note: This does not work for multi-index searches because the helper
// cannot see the state of the sub-indexes.
export const buildPreemptSearch = (
  onSearch?: (state: Partial<AlgoliaSearchParams>) => void,
): NonNullable<ComponentProps<typeof InstantSearch>['searchFunction']> => {
  return (helper) => {
    const { state } = helper;
    onSearch?.(state);

    const { filters, query } = state;
    if (!!filters) {
      const isNoQuery = !query || query === '';
      const preemptionPathSort = buildPreemptionPathSort(filters);
      if (isNoQuery && !!preemptionPathSort) {
        return;
      }
    }

    return helper.search();
  };
};
