import { GridColDef } from '@mui/x-data-grid';
import { Score } from './Score';
import { ScoreOption } from '../../../../functions/src/types/firestore/Game/Competition';

export type optionalColumnsProps = { scoreOptions: ScoreOption[] };

export function optionalColumns({
  scoreOptions,
}: optionalColumnsProps): GridColDef[] {
  return scoreOptions.map(({ name, units }, index) => {
    return {
      field: name.toLowerCase(),
      headerName: name.toUpperCase(),
      minWidth: 130,
      maxWidth: 350,
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Score
            score={`${row.scores[Number(index)] || 0}`}
            rank={row.rank}
            units={units}
          />
        );
      },
    };
  });
}
