export const stripEmptyNullish = <TObj>(obj: TObj): Partial<TObj> => {
  if (
    !obj ||
    typeof obj !== 'object' ||
    (Array.isArray(obj) && obj.length === 0)
  ) {
    return {};
  }

  return Object.entries(obj).reduce(
    (cleanedObj: Record<string, unknown>, [key, value]) => {
      if (value && typeof value === 'object') {
        const cleanedValue = stripEmptyNullish(value);
        if (cleanedValue !== null && Object.keys(cleanedValue).length > 0) {
          // eslint-disable-next-line security/detect-object-injection
          cleanedObj[key] = cleanedValue;
        }
      } else if (value !== null && value !== undefined) {
        // eslint-disable-next-line security/detect-object-injection
        cleanedObj[key] = value;
      }
      return cleanedObj;
    },
    {} as Record<string, unknown>,
  ) as Partial<TObj>;
};
