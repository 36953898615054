import Dialog, { DialogProps } from '@mui/material/Dialog';
import { AlertStandard } from './AlertStandard';
import Typography from '@mui/material/Typography';
import { useGlobalComponentsContext } from '../contexts/GlobalComponentsContext';
import { AlertColor } from '@mui/material/Alert';
import { memo } from '../util/memo';

export type AlertStandardDialogProps = {
  title: string;
  id: string;
  severity?: AlertColor;
  description?: string;
} & Omit<DialogProps, 'open'>;

const AlertStandardDialogUnmemoized = ({
  title,
  description,
  severity = 'error',
  id,
  ...props
}: AlertStandardDialogProps) => {
  const { onClose } = props;
  const { remove } = useGlobalComponentsContext();
  return (
    <Dialog
      open={true}
      sx={{
        //border: 'none !important',
        '& .MuiDialog-paper': { border: 'none !important' },
        '& .MuiPaper-root': { backgroundColor: 'background.elevation.2' },
      }}
      {...props}
      onClose={(...args) => {
        remove(id);
        if (!!onClose) {
          onClose(...args);
        }
      }}
    >
      <AlertStandard
        severity={severity}
        message={
          <>
            <Typography
              variant="h5"
              sx={{ pb: 2, ml: 4, textTransform: 'uppercase' }}
              color={`${severity}.light`}
            >
              {title}
            </Typography>
            {description && (
              <Typography
                sx={{ ml: 4, textTransform: 'none', fontWeight: '500' }}
                color={`${severity}.light`}
              >
                {description}
              </Typography>
            )}
          </>
        }
      />
    </Dialog>
  );
};
export const AlertStandardDialog = memo(AlertStandardDialogUnmemoized);
