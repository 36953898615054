import { memo } from '../../../util/memo';
import { GradientIcon } from '../../gradients/GradientIcon';
import { GradientTypography } from '../../gradients/GradientTypography';
import Stack from '@mui/material/Stack';
import { useMessage } from '../message/MessageContext';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaicRounded';

export type PreviewCountProps = {
  previewsLength: number;
  previewType: string;
};

const PreviewCountUnmemoized = ({
  previewsLength,
  previewType,
}: PreviewCountProps) => {
  const { isMine } = useMessage();
  return (
    <Stack
      direction="row"
      pb={1}
      pr={isMine ? 1 : 0}
      spacing={1}
      justifyContent={isMine ? 'flex-end' : 'flex-start'}
      alignItems="center"
    >
      <GradientIcon
        IconComponent={AutoAwesomeMosaicIcon}
        sx={{ height: '14px', width: '14px' }}
      />
      <GradientTypography gradientColor="primary.vertical" variant="caption">
        {previewsLength} {previewType}
      </GradientTypography>
    </Stack>
  );
};

export const PreviewCount = memo(PreviewCountUnmemoized);
