import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useReferee } from 'src/contexts/RefereeContext';
import { memo } from '../../../../util/memo';

export type DetailsSoloProps = {
  details: Record<string, unknown>;
  summary: string;
};

const DetailsSoloUnmemoized = ({ details, summary }: DetailsSoloProps) => {
  const { detailsOptions } = useReferee();
  return (
    <Stack>
      <Typography key={`summary-${details.userId}`}>{summary}</Typography>
      {!detailsOptions?.summaryOnly &&
        Object.entries(details).map(([key, value]) => {
          if (!value) {
            return null;
          }
          return (
            <Typography
              key={`${details.userId}-${key}`}
            >{`${key}: ${value}`}</Typography>
          );
        })}
    </Stack>
  );
};

export const DetailsSolo = memo(DetailsSoloUnmemoized);
