import UAParser from 'ua-parser-js';

const DEFAULT = 'unknown' as const;

export const extractBrowserAndOs = (): { browser: string; os: string } => {
  if (typeof window !== 'undefined') {
    const parser = new UAParser();
    const { browser, os } = parser.getResult();
    return {
      browser: browser.name || DEFAULT,
      os: os.name || DEFAULT,
    };
  }
  return { browser: DEFAULT, os: DEFAULT };
};
