import Typography from '@mui/material/Typography';
import { convertTime } from 'src/util/numberConversions';
import { memo } from '../../../util/memo';

export type ScoreProps = {
  score: string;
  rank: number;
  units?: string;
};

export const formatTimeTaken = (seconds: number): string => {
  const hours = Math.floor(seconds / convertTime('HOURS', 'SECONDS'));
  const minutes = Math.floor(
    (seconds % convertTime('HOURS', 'SECONDS')) /
      convertTime('MINUTES', 'SECONDS'),
  );
  const remainingSeconds = seconds % 60;
  const unit = hours > 0 ? 'h' : hours === 0 && minutes > 0 ? 'm' : 's';
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')} ${unit}`;
};

const ScoreUnmemoized = ({ score, rank, units }: ScoreProps) => {
  const formattedScore =
    units === 'time' ? formatTimeTaken(parseInt(score)) : score;

  return (
    <Typography
      variant={rank > 3 ? 'body2' : 'body1'}
      {...(rank <= 3 && { sx: { fontWeight: 700 } })}
    >
      {formattedScore}
    </Typography>
  );
};

export const Score = memo(ScoreUnmemoized);
