import { useEffect, useRef } from 'react';
import { useRouter } from 'src/hooks/routing/useRouter';

export function useRedirectCallback(callback?: () => void) {
  const { pathname, events, isReady } = useRouter();
  const isInitialLoad = useRef(true);
  const previousPathname = useRef(pathname);
  useEffect(() => {
    if (!isReady) {
      return;
    }

    const handleRouteChangeStart = (url: string) => {
      if (isInitialLoad.current) {
        isInitialLoad.current = false;
        return;
      }
      if (typeof window === 'undefined') {
        return;
      }

      const newUrl = new URL(url, window.location.origin);

      if (newUrl.pathname !== previousPathname.current) {
        callback?.();
        previousPathname.current = newUrl.pathname;
      }
    };

    events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [callback, events, isReady]);
}
