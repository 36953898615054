import { memo } from '../../util/memo';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { GradientIconButton } from '../gradients/GradientIconButton';
import { useVoiceChannel } from '../../hooks/voice-chat/useVoiceChannel';
import { useCallback } from 'react';

const EndCallButtonUnmemoized = () => {
  const { setVoiceChannel } = useVoiceChannel();

  const removeCurrentVoiceChannel = useCallback(async () => {
    const { deleteField } = await import('firebase/firestore');
    setVoiceChannel({ current: deleteField() });
  }, [setVoiceChannel]);

  return (
    <GradientIconButton
      IconComponent={CallEndIcon}
      gradientColor={'error.vertical'}
      onClick={removeCurrentVoiceChannel}
    />
  );
};

export const EndCallButton = memo(EndCallButtonUnmemoized);
