import Stack from '@mui/material/Stack';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { PendingInvite } from './PendingInvite';

export function PendingInvitesBody() {
  const { pendingInvites } = useTournamentRegistration();
  return (
    <Stack gap={8}>
      {pendingInvites?.map((pendingInvite) => {
        return <PendingInvite key={pendingInvite.teamId} {...pendingInvite} />;
      })}
    </Stack>
  );
}
