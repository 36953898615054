import { memo } from '../../../util/memo';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useTheme } from '@mui/material/styles';
import { useLivestream } from '../../../contexts/LivestreamContext';
import { useHover } from '../../../contexts/HoverContext';
import { useMemo } from 'react';

export type DownloadLivestreamButtonProps = {
  fileName: string;
};

const DownloadLivestreamButtonUnmemoized = ({
  fileName,
}: DownloadLivestreamButtonProps) => {
  const theme = useTheme();
  const { isHovered } = useHover();
  const { isLive, downloadUrl } = useLivestream();
  const downloadUrlComplete = `${downloadUrl}${fileName}`;

  const downloadButton = useMemo(() => {
    return (
      isHovered && (
        <a
          href={downloadUrlComplete}
          download={fileName}
          style={{
            display: !downloadUrl || isLive ? 'none' : 'flex',
          }}
        >
          <DownloadRoundedIcon
            sx={{
              height: '24px',
              color: theme.palette.text.primary,
              filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))',
            }}
          />
        </a>
      )
    );
  }, [
    downloadUrl,
    downloadUrlComplete,
    fileName,
    isHovered,
    isLive,
    theme.palette.text.primary,
  ]);

  return (
    // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
    <>{downloadButton}</>
  );
};

export const DownloadLivestreamButton = memo(
  DownloadLivestreamButtonUnmemoized,
);
