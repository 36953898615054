import Stack, { StackProps } from '@mui/material/Stack';
import { ReactNode } from 'react';
import { memo } from '../util/memo';
import { useTheme } from '@mui/material/styles';

export type SelectableOptionProps = {
  children: ReactNode;
  isSelected?: boolean;
} & StackProps;

export const SelectableOption = memo(function SelectableOptionUnmemoized({
  children,
  isSelected,
  ...props
}: SelectableOptionProps) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        px: 4,
        py: 2,
        backgroundColor: theme.palette.background.elevation[14],
        borderRadius: '8px',
        border: isSelected
          ? `2px solid ${theme.palette.primary.dark}`
          : undefined,
        ...theme.glow.selectable,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
});
