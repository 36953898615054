import { LoadingButton } from './LoadingButton';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { memo } from '../../util/memo';
import { useAuth } from '../../contexts/AuthContext';

export const LinkEmailButton = memo(function SignUpButtonUnmemoized() {
  const { signUp, isLoading, isValidated, signIn } = useAuthSubmit();
  const { password, confirmPassword } = isValidated;
  const { uid } = useAuth();

  return (
    <LoadingButton
      variant="contained"
      size="large"
      fullWidth
      onClick={!!uid ? signIn : signUp}
      isLoading={isLoading}
      disabled={!(confirmPassword && password)}
    >
      {!!uid ? 'Link Email' : 'Sign Up'}
    </LoadingButton>
  );
});
