import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { ResultAction } from './ResultAction';
import { PlacementAction } from './PlacementAction';
import { useMemo } from 'react';
import { useScoring } from '../../../contexts/ScoringContext';
import Box from '@mui/material/Box';

export const MatchActions = memo(function MatchActionsUnmemoized() {
  const { numberOfTeams } = useScoring();
  const actions = useMemo(() => {
    return (
      <Box>{numberOfTeams === 2 ? <ResultAction /> : <PlacementAction />}</Box>
    );
  }, [numberOfTeams]);

  return <Stack gap={2}>{actions}</Stack>;
});
