import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { PrizeGrid } from '../../organizer/prize-pools/PrizeGrid';
import { Item } from '../../../../functions/src/types/firestore/Game/Collection/Item';
import {
  Token,
  TokenStandard,
} from '../../../../functions/src/types/firestore/User/Payout';
import {
  PriceablePrizePoolToken,
  PrizePoolToken,
} from '../../../../functions/src/types/firestore/PrizePool';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useCurrency } from '../../../contexts/CurrencyContext';
import { UnusedPrizes } from './UnusedPrizes';
import { usePrizePoolSelection } from '../../../contexts/organizer/PrizePoolSelectionContext';
import { PayoutSummary } from '../../tournaments/PayoutSummary';
import { ThumbnailCardToken } from '../../cards/thumbnail-prize/ThumbnailCardToken';

export type PayoutsAvailableSummaryProps = {
  payoutsAvailable: PrizePoolToken<TokenStandard>[];
  payoutsSelected: { tokens: Token[] }[];
  payoutUnfilled: boolean;
};

export const PayoutsAvailableSummary = memo(
  function PayoutsAvailableSummaryUnmemoized({
    payoutsAvailable,
    payoutUnfilled,
  }: PayoutsAvailableSummaryProps) {
    const { formatPayout } = useCurrency();
    const { prizesAggregatedWithAmounts } = usePrizePoolSelection();
    const payoutsFlattened = useMemo(() => {
      return payoutsAvailable.flat();
    }, [payoutsAvailable]);

    const payoutFormatted = formatPayout(payoutsFlattened);
    return (
      <Stack
        sx={{
          '& .MuiGrid-root': {
            'overflow-y': 'hidden',
            // 'overflow-x': 'scroll',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <GradientTypography
            variant="h6"
            gradientColor="primary.vertical"
            textTransform={'uppercase'}
            fontWeight={700}
          >
            Available Prizes
          </GradientTypography>
          <PayoutSummary showIcons {...payoutFormatted} />
        </Stack>
        <PrizeGrid
          items={prizesAggregatedWithAmounts.filter((token) => {
            return token.amount !== '0';
          })}
          renderCard={(
            payout: PriceablePrizePoolToken | Item<Date>,
            token: PrizePoolToken,
          ) => {
            const isPayoutUsed = (token: Token) => {
              return token && token.amount === '0';
            };
            const opacity = isPayoutUsed(token) ? 0.5 : 1;
            return (
              <div style={{ opacity }}>
                <ThumbnailCardToken token={payout} />
              </div>
            );
          }}
        />
        {payoutUnfilled && <UnusedPrizes />}
      </Stack>
    );
  },
);
