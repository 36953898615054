import { memo } from '../../../util/memo';
import { Item } from '../../../../functions/src/types/firestore/Game/Collection/Item';
import { Token } from '../../../../functions/src/types/firestore/User/Payout';
import { isPriceableToken } from '../../organizer/prize-pools/UserPrizePools/PrizePoolPanel';
import { ThumbnailCardItem } from './ThumbnailCardItem';
import { ThumbnailCardPriceableToken } from './ThumbnailCardPriceableToken';

export type ThumbnailCardTokenProps = {
  token: Token | Item<Date>;
};

export const ThumbnailCardToken = memo(function ThumbnailCardTokenUnmemoized({
  token,
}: ThumbnailCardTokenProps) {
  return isPriceableToken(token) ? (
    <ThumbnailCardPriceableToken token={token} />
  ) : (
    <ThumbnailCardItem
      imgUrl={(token as Item<Date>).imgUrl || ''}
      amount={`${token.amount}`}
    />
  );
});
