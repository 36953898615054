import { swap } from '../util/object';
import { ValueOf } from './utility-types';

export const COUNTRY_TO_CODE = {
  USA: 'US',
  Canada: 'CA',
  UK: 'GB',
  Australia: 'AU',
  Singapore: 'SG',
  Afghanistan: 'AF',
  Albania: 'AL',
  Algeria: 'DZ',
  Andorra: 'AD',
  Angola: 'AO',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahrain: 'BH',
  Bahamas: 'BS',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivia: 'BO',
  'Bosnia And Herzegovina': 'BA',
  Botswana: 'BW',
  Brazil: 'BR',
  Brunei: 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cambodia: 'KH',
  Cameroon: 'CM',
  'Cape Verde': 'CV',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  Colombia: 'CO',
  Comoros: 'KM',
  Congo: 'CG',
  'Costa Rica': 'CR',
  'Ivory Coast': 'CI',
  Croatia: 'HR',
  Cuba: 'CU',
  Cyprus: 'CY',
  'Czech Republic': 'CZ',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Greece: 'GR',
  Grenada: 'GD',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  Vatican: 'VA',
  Honduras: 'HN',
  'Hong Kong': 'HK',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  Iran: 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  'North Korea': 'KP',
  'South Korea': 'KR',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  Laos: 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mexico: 'MX',
  Micronesia: 'FM',
  Moldova: 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  Palestine: 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Romania: 'RO',
  Russia: 'RU',
  Rwanda: 'RW',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  Swaziland: 'SZ',
  Sweden: 'SE',
  Switzerland: 'CH',
  Syria: 'SY',
  Tajikistan: 'TJ',
  Tanzania: 'TZ',
  Thailand: 'TH',
  'Timor Leste': 'TL',
  Togo: 'TG',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  UAE: 'AE',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  Venezuela: 'VE',
  Vietnam: 'VN',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
} as const;

export type Country = keyof typeof COUNTRY_TO_CODE;
export type CountryCode = ValueOf<typeof COUNTRY_TO_CODE>;

//TODO: maybe add phone number prefix to this and have our own phone number input textfield
export const COUNTRY_CODE_TO_CURRENCY = {
  US: { name: 'USD', symbol: '$' },
  CA: { name: 'CAD', symbol: '$' },
  GB: { name: 'GBP', symbol: '£' },
  SG: { name: 'SGD', symbol: '$' },
  AU: { name: 'AUD', symbol: '$' },
  MY: { name: 'MYR', symbol: 'RM' },
  EU: { name: 'EUR', symbol: '€' },
  AL: { name: 'ALL', symbol: 'L' },
  AF: { name: 'AFN', symbol: '؋' },
  AR: { name: 'ARS', symbol: '$' },
  AZ: { name: 'AZN', symbol: '₼' },
  BS: { name: 'BSD', symbol: '$' },
  BB: { name: 'BBD', symbol: '$' },
  BD: { name: 'BDT', symbol: '৳' },
  BY: { name: 'BYN', symbol: 'Br' },
  BZ: { name: 'BZD', symbol: 'BZ$' },
  BM: { name: 'BMD', symbol: '$' },
  BO: { name: 'BOB', symbol: '$b' },
  BA: { name: 'BAM', symbol: 'KM' },
  BW: { name: 'BWP', symbol: 'P' },
  BG: { name: 'BGN', symbol: 'лв' },
  BR: { name: 'BRL', symbol: 'R$' },
  BN: { name: 'BND', symbol: '$' },
  KH: { name: 'KHR', symbol: '៛' },
  KY: { name: 'KYD', symbol: '$' },
  CL: { name: 'CLP', symbol: '$' },
  CN: { name: 'CNY', symbol: '¥' },
  CO: { name: 'COP', symbol: '$' },
  CR: { name: 'CRC', symbol: '₡' },
  HR: { name: 'HRK', symbol: 'kn' },
  CU: { name: 'CUP', symbol: '₱' },
  CZ: { name: 'CZK', symbol: 'Kč' },
  DK: { name: 'DKK', symbol: 'kr' },
  DO: { name: 'DOP', symbol: 'RD$' },
  XC: { name: 'XCD', symbol: '$' },
  EG: { name: 'EGP', symbol: '£' },
  SV: { name: 'SVC', symbol: '$' },
  FK: { name: 'FKP', symbol: '£' },
  FJ: { name: 'FJD', symbol: '$' },
  GH: { name: 'GHS', symbol: 'GH₵' },
  GI: { name: 'GIP', symbol: '£' },
  GT: { name: 'GTQ', symbol: 'Q' },
  GG: { name: 'GGP', symbol: '£' },
  GY: { name: 'GYD', symbol: '$' },
  HN: { name: 'HNL', symbol: 'L' },
  HK: { name: 'HKD', symbol: '$' },
  HU: { name: 'HUF', symbol: 'Ft' },
  IS: { name: 'ISK', symbol: 'kr' },
  IN: { name: 'INR', symbol: '₹' },
  ID: { name: 'IDR', symbol: 'Rp' },
  IR: { name: 'IRR', symbol: '﷼' },
  IM: { name: 'IMP', symbol: '£' },
  IL: { name: 'ILS', symbol: '₪' },
  JM: { name: 'JMD', symbol: 'J$' },
  JP: { name: 'JPY', symbol: '¥' },
  JE: { name: 'JEP', symbol: '£' },
  KZ: { name: 'KZT', symbol: '₸' },
  KP: { name: 'KPW', symbol: '₩' },
  KR: { name: 'KRW', symbol: '₩' },
  KG: { name: 'KGS', symbol: 'лв' },
  LA: { name: 'LAK', symbol: '₭' },
  LB: { name: 'LBP', symbol: '£' },
  LR: { name: 'LRD', symbol: '$' },
  MK: { name: 'MKD', symbol: 'ден' },
  MU: { name: 'MUR', symbol: '₨' },
  MX: { name: 'MXN', symbol: '$' },
  MN: { name: 'MNT', symbol: '₮' },
  MZ: { name: 'MZN', symbol: 'MT' },
  NA: { name: 'NAD', symbol: '$' },
  NP: { name: 'NPR', symbol: '₨' },
  NZ: { name: 'NZD', symbol: '$' },
  NI: { name: 'NIO', symbol: 'C$' },
  NG: { name: 'NGN', symbol: '₦' },
  NO: { name: 'NOK', symbol: 'kr' },
  OM: { name: 'OMR', symbol: '﷼' },
  PK: { name: 'PKR', symbol: 'K' },
  PA: { name: 'PAB', symbol: 'B/.' },
  PY: { name: 'PYG', symbol: 'Gs' },
  PE: { name: 'PEN', symbol: 'S/.' },
  PH: { name: 'PHP', symbol: '₱' },
  PL: { name: 'PLN', symbol: 'zł' },
  QA: { name: 'QAR', symbol: '﷼' },
  RO: { name: 'RON', symbol: 'lei' },
  RU: { name: 'RUB', symbol: '₽' },
  SH: { name: 'SHP', symbol: '£' },
  SA: { name: 'SAR', symbol: '﷼' },
  RS: { name: 'RSD', symbol: 'Дин.' },
  SC: { name: 'SCR', symbol: '₨' },
  SB: { name: 'SBD', symbol: '$' },
  SO: { name: 'SOS', symbol: 'S' },
  ZA: { name: 'ZAR', symbol: 'R' },
  LK: { name: 'LKR', symbol: '₨' },
  SE: { name: 'SEK', symbol: 'kr' },
  CH: { name: 'CHF', symbol: 'CHF' },
  SR: { name: 'SRD', symbol: '$' },
  SY: { name: 'SYP', symbol: '£' },
  TW: { name: 'TWD', symbol: 'NT$' },
  TH: { name: 'THB', symbol: '฿' },
  TT: { name: 'TTD', symbol: 'TT$' },
  TR: { name: 'TRY', symbol: '₺' },
  TV: { name: 'TVD', symbol: '$' },
  UA: { name: 'UAH', symbol: '₴' },
  UY: { name: 'UYU', symbol: '$U' },
  UZ: { name: 'UZS', symbol: 'лв' },
  VE: { name: 'VES', symbol: 'Bs' },
  VN: { name: 'VND', symbol: '₫' },
  YE: { name: 'YER', symbol: '﷼' },
  ZW: { name: 'ZWL', symbol: 'Z$' },
} as const;

export type Currency = ValueOf<typeof COUNTRY_CODE_TO_CURRENCY>;
export type CurrencyName = Currency['name'];
export type CurrencySymbol = Currency['symbol'];

export const CURRENCY_TO_SYMBOL = new Map<CurrencyName, CurrencySymbol>(
  Object.values(COUNTRY_CODE_TO_CURRENCY).map(({ name, symbol }) => {
    return [name, symbol];
  }),
);

export const CODE_TO_COUNTRY = swap(COUNTRY_TO_CODE);

export const EUROPEAN_COUNTRIES: CountryCode[] = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const RULES_TRANSLATIONS = [
  'rules', // English
  'reglas', // Spanish
  'règles', // French
  'regeln', // German
  'regras', // Portuguese
  '規則', // Japanese
  '규칙', // Korean
  'правила', // Russian
  'regole', // Italian
  'regler', // Danish
  'regler', // Swedish
  'säännöt', // Finnish
  '规则', // Chinese Simplified
  '規則', // Chinese Traditional
  'regulamin', // Polish
  'szabályok', // Hungarian
  'قوانين', // Arabic
  'regulile', // Romanian
  'правила', // Bulgarian
  'यम', // Hindi
  'niyam', // Nepali
  'กติกา', // Thai
  'aturan', // Indonesian
  'quy tắc', // Vietnamese
  'kural', // Turkish
  '규칙들', // Korean (plural form)
  'reglas', // Spanish (plural form)
  'regole', // Italian (plural form)
  'regler', // Norwegian
  'pravila', // Croatian
  'pravidlá', // Slovak
  'pravila', // Slovenian
  'قواعد', // Persian
  'zakoni', // Bosnian
];
