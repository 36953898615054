import Grid from '@mui/material/Grid';
import { Item } from 'functions/src/types/firestore/Game/Collection/Item';
import { FC, ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { useDisplayedPrizes } from 'src/hooks/prize-pool/useDisplayedPrizes';
import {
  PayoutToken,
  PriceablePayoutToken,
  Token,
} from 'functions/src/types/firestore/User/Payout';
import { ThumbnailCardPlaceholder } from '../../cards/thumbnail-prize/ThumbnailCardPlaceholder';
import { TokenAggregated } from '../../../../functions/src/util/payouts/TokenAggregator';
import {
  PriceablePrizePoolToken,
  PrizePoolToken,
} from '../../../../functions/src/types/firestore/PrizePool';
import { PriceableToken } from '../../../../functions/src/util/currencies/PriceableToken';
import { ThumbnailCardToken } from '../../cards/thumbnail-prize/ThumbnailCardToken';

export type PrizeTokenUnion = PriceablePrizePoolToken | PriceableToken;

type RenderCardBase<TTokenDisplayed, TTokenBase> = (
  prize: TTokenDisplayed | Item<Date>,
  selectedPrize: TTokenBase,
) => ReactNode;
export type RenderCard =
  | RenderCardBase<PriceableToken, Token>
  | RenderCardBase<PriceablePrizePoolToken, PrizePoolToken>
  | RenderCardBase<PriceablePayoutToken, PayoutToken>
  | RenderCardBase<TokenAggregated, TokenAggregated>;

export type PrizeGridProps = {
  items: Token[];
  renderCard?: RenderCard;
  countPlaceholders?: number;
  onPlaceholderClick?: () => void;
};

const getPrizeKey = (prize: Token | Item<Date>, selectedPrize: Token) => {
  return selectedPrize
    ? `${selectedPrize.chainId}-${selectedPrize.type}-${selectedPrize.address}-${selectedPrize.identifier}`
    : 'contract' in prize
    ? `${prize.contract.chainId}-${prize.contract.type}-${prize.contract.address}-${prize.contract.itemId}`
    : `${prize.chainId}-${prize.type}-${prize.address}-${prize.identifier}`;
};

export const PrizeGrid: FC<PrizeGridProps> = memo(function PrizeGridUnmemoized({
  items,
  renderCard = (prize, token) => {
    if (!prize || !token) {
      return null;
    }
    return <ThumbnailCardToken token={prize} />;
  },
  countPlaceholders = 0,
  onPlaceholderClick,
}) {
  const prizesDisplayed = useDisplayedPrizes(items);
  const placeholders = new Array(countPlaceholders).fill('').map(() => {
    return 'PLACEHOLDER';
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
    >
      {prizesDisplayed?.length > 0 &&
        prizesDisplayed.map((prize, index) => {
          const selectedPrize = items[Number(index)];
          return (
            <Grid
              key={getPrizeKey(prize, selectedPrize)}
              item
              xs={'auto'}
              md={'auto'}
              p={4}
            >
              {renderCard(prize, selectedPrize)}
            </Grid>
          );
        })}
      {placeholders.map((_, index) => {
        return (
          <Grid
            key={index}
            item
            xs={'auto'}
            md={'auto'}
            p={4}
            onClick={onPlaceholderClick}
          >
            <ThumbnailCardPlaceholder
              asPlus={onPlaceholderClick && index === 0}
            />
          </Grid>
        );
      })}
    </Grid>
  );
});
