import { ReactNode, memo as reactMemo, ComponentType } from 'react';
import isEqual from 'fast-deep-equal/react';

export type Props<T extends object = object> = {
  [key: string]: Date | T | ReactNode;
  sx?: T;
};

function isObject(
  value: unknown,
): value is Record<string | number | symbol, unknown> {
  return value !== null && typeof value === 'object';
}

export type ShouldDeepCompareKey<TProps> = (
  key: keyof TProps & string,
) => boolean;

export const withDeepCompareMatching = <TProps extends Record<string, unknown>>(
  shouldDeepCompare: ShouldDeepCompareKey<TProps>,
) => {
  return (prevProps: TProps, nextProps: TProps) => {
    if (!isObject(prevProps) || !isObject(nextProps)) {
      return prevProps === nextProps;
    }

    const keys = Object.keys({
      ...prevProps,
      ...nextProps,
    }) as (keyof TProps & string)[];

    return keys.every((key) => {
      const prevValue = prevProps[String(key)];
      const nextValue = nextProps[String(key)];

      if (
        typeof prevValue === 'object' &&
        typeof nextValue === 'object' &&
        shouldDeepCompare(key)
      ) {
        return isEqual(prevValue, nextValue);
      }

      if (prevValue instanceof Date && nextValue instanceof Date) {
        return prevValue.getTime() === nextValue.getTime();
      }

      return prevValue === nextValue;
    });
  };
};

/**
 * @remarks all other props beside the ones specified in keysToCompareDeeply
 * will be compared shallowly.
 */
export const withDeepCompareOf = <TProps extends Record<string, unknown>>(
  ...keysToCompareDeeply:
    | readonly (keyof TProps & string)[]
    | (keyof TProps & string)[]
) => {
  return withDeepCompareMatching((key) => {
    return keysToCompareDeeply.includes(key);
  });
};

function blumintAreEqual<TProps>(prevProps: TProps, nextProps: TProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return withDeepCompareMatching<any>((key) => {
    return key === 'sx' || key.endsWith('Sx');
  })(prevProps, nextProps);
}

const memo = <TProps>(
  Component: ComponentType<TProps>,
  areEqual?: (prevProps: TProps, nextProps: TProps) => boolean,
) => {
  const eitherEqual = (prevProps: TProps, nextProps: TProps) => {
    return (
      blumintAreEqual(prevProps, nextProps) ||
      areEqual?.(prevProps, nextProps) ||
      false
    );
  };

  return reactMemo(Component, eitherEqual);
};

export { memo };
