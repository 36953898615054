import { Dispatch, SetStateAction, useCallback } from 'react';
import type { EditStep } from '../../../functions/src/types/firestore/EditStep/Step';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';

export const useRebaseSteps = <T,>({
  currentStepId,
  setCurrentStepId,
  stepRef,
  setDocument,
  applyStep,
}) => {
  return useCallback(async () => {
    if (!stepRef) {
      return;
    }
    const { getDocs, orderBy, query, where } = await import(
      'firebase/firestore'
    );

    const stepsQuery = query(
      stepRef,
      orderBy('stepId', 'asc'),
      where('stepId', '>', currentStepId),
    ).withConverter<EditStep>(ConverterFactory.buildDateConverter());
    const newSteps = await getDocs(stepsQuery);
    if (!newSteps.size) {
      return;
    }
    let newStepId = '1';
    newSteps.forEach((doc) => {
      const step = doc.data();
      if (!step) {
        return;
      }
      newStepId = String(step.stepId);
      applyStep(step, setDocument as Dispatch<SetStateAction<T>>);
    });

    setCurrentStepId(parseInt(newStepId));
  }, [applyStep, currentStepId, setCurrentStepId, setDocument, stepRef]);
};
