import type { DocumentData, DocumentReference } from 'firebase/firestore';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import { FirestoreHandler, useFirestore } from './useFirestore';
import { useCallback } from 'react';

/**
 * Custom hook to retrieve a document snapshot from Firestore
 *
 * @param docPath Path to the document in Firestore
 * @param initialData Initial data loaded from Firestore SSR
 * @returns realtime data of the associated document
 */
export function useDocSnapshot<TData extends DocumentData>(
  docPath?: string,
  initialData?: TData,
): TData | undefined {
  const handler = useCallback<FirestoreHandler<TData>>(
    (firestoreModule, firebaseFirestoreModule, setData) => {
      if (!docPath) {
        return;
      }
      const { firestore } = firestoreModule;
      const { doc, onSnapshot } = firebaseFirestoreModule;

      const docRef = doc(firestore, docPath) as DocumentReference<TData>;

      return onSnapshot(
        docRef.withConverter<TData>(ConverterFactory.buildDateConverter()),
        (snap) => {
          setData(snap.data());
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [docPath],
  );

  return useFirestore(handler, initialData);
}
