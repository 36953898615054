import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { CallerHit } from './CallersVerticalCarousel';
import { CallerCardActive } from './CallerCardActive';
import { useCallerStatus } from '../../hooks/voice-chat/useCallerStatus';
import { CallerCardBase } from './CallerCardBase';
import { AvatarGroupNext } from '../AvatarGroupNext';
import { Track } from 'livekit-client';
import {
  useRemoteParticipant,
  useLocalParticipant,
  useParticipantTracks,
} from '@livekit/components-react';

export type CallerCardProps = CallerHit;

const CallerCardUnmemoized = (hit: CallerCardProps) => {
  const { id: userId, imgUrl } = hit;
  const { status } = useCallerStatus(userId);
  const remoteParticipant = useRemoteParticipant(userId);
  const { localParticipant } = useLocalParticipant();

  const participant = useMemo(() => {
    return remoteParticipant || localParticipant;
  }, [remoteParticipant, localParticipant]);

  const participantTracks = useParticipantTracks(
    [Track.Source.Microphone],
    userId,
  );

  const audioTrack = useMemo(() => {
    return participantTracks[0];
  }, [participantTracks.length]);

  const callerCard = useMemo(() => {
    return status === 'accepted' && !!participant && !!audioTrack ? (
      <CallerCardActive
        participant={participant}
        audioTrack={audioTrack}
        {...hit}
      />
    ) : (
      <CallerCardBase
        Figure={
          <AvatarGroupNext
            imgUrls={[imgUrl]}
            avatarProps={{ height: 36, width: 36 }}
            userId={userId}
            showStatus={false}
          />
        }
        {...hit}
      />
    );
  }, [status, participant, audioTrack, hit, imgUrl, userId]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{callerCard}</>;
};

export const CallerCard = memo(CallerCardUnmemoized);
