import { ReactNode, useCallback, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { memo } from '../util/memo';

export const CLIPBOARD_PREFIX_MOBILE = 'Copied to clipboard!';

export type ClipboardShareProps = {
  value: string | (() => string);
  children: ReactNode;
  onClick?: () => void | Promise<void>;
  description?: string;
} & Omit<TooltipProps, 'title' | 'onClick'>;

function ClipboardShareUnmemoized({
  value,
  children,
  sx,
  onClick,
  description = CLIPBOARD_PREFIX_MOBILE,
  ...props
}: ClipboardShareProps) {
  const [isOpen, setIsOpen] = useState(false);
  const hide = () => {
    setIsOpen(false);
  };

  const show = useCallback(async () => {
    navigator.clipboard.writeText(typeof value === 'string' ? value : value());
    setIsOpen(true);
    setTimeout(hide, 2000);
    if (onClick) {
      await onClick();
    }
  }, [onClick, value]);

  return (
    <ClickAwayListener onClickAway={hide}>
      <Tooltip
        open={isOpen}
        onClose={hide}
        disableFocusListener
        disableHoverListener
        title={description}
        arrow
        placement="bottom"
        sx={{ ...sx, '&:hover': { cursor: 'pointer' } }}
        {...props}
      >
        <Box onClick={show}>{children}</Box>
      </Tooltip>
    </ClickAwayListener>
  );
}

export const ClipboardShare = memo(ClipboardShareUnmemoized);
