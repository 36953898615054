import { useContext, createContext, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { CompetitorWithMembers } from './details/MatchDetails';
import { matchCompetitorsOf } from '../../../util/tournaments/matchCompetitorsOf';
import { useAuth } from '../../../contexts/AuthContext';

export type MatchProps = MatchAggregated<Date> & {
  isLarge?: boolean;
  outcome?: Outcome;
  competitor1?: CompetitorWithMembers;
  competitor2?: CompetitorWithMembers;
  numberOfTeams?: number;
  games: number[];
  lastSessionIndex: number;
  matchFinished?: boolean;
};

export const MatchContext = createContext<
  (MatchProps & { isMatchDisabled: boolean }) | null
>(null);

export const useMatch = () => {
  const context = useContext(MatchContext);
  if (!context) {
    throw new Error('useMatch must be used within a MatchProvider');
  }
  return context;
};

export const MatchProvider = memo(function MatchProviderUnmemoized({
  children,
  isMatchDisabled,
  ...match
}: MatchProps & {
  children: ReactNode;
  isMatchDisabled: boolean;
}) {
  const { uid } = useAuth();
  const {
    team1Scores = [],
    team2Scores = [],
    bestOf = 1,
    team1Score = 0,
    team2Score = 0,
    team1: team1Original,
    team2: team2Original,
    ...rest
  } = match;
  const [team1, team2] = matchCompetitorsOf(match);

  const { competitor: competitorTeam1, ...restTeam1 } = team1;
  const { competitor: competitorTeam2, ...restTeam2 } = team2;

  const userTeam = useMemo(() => {
    if (!uid) {
      return;
    }
    const team1UserIds = team1Original?.acceptedUserIds || [];
    const team2UserIds = team2Original?.acceptedUserIds || [];
    if (team1UserIds.includes(uid)) {
      return team1Original;
    }
    if (team2UserIds.includes(uid)) {
      return team2Original;
    }
    return undefined;
  }, [team1Original, team2Original, uid]);

  const competitor1 = useMemo(() => {
    return {
      ...restTeam1,
      ...competitorTeam1,
      members: match.team1?.members || [],
    };
  }, [competitorTeam1, match.team1?.members, restTeam1]);

  const competitor2 = useMemo(() => {
    return {
      ...restTeam2,
      ...competitorTeam2,
      members: match.team2?.members || [],
    };
  }, [competitorTeam2, match.team2?.members, restTeam2]);

  const outcome = competitor1?.outcome && competitor2?.outcome;

  const numberOfTeams = useMemo(() => {
    return [team1Original, team2Original].filter(Boolean).length;
  }, [team1Original, team2Original]);

  const games = useMemo(() => {
    return Array.from({ length: bestOf }, (_, index) => {
      return index + 1;
    });
  }, [bestOf]);

  const winsNeeded = useMemo(() => {
    return Math.ceil(bestOf / 2);
  }, [bestOf]);

  const matchNotStarted = useMemo(() => {
    return team1Scores.length === 0 && team2Scores.length === 0;
  }, [team1Scores.length, team2Scores.length]);

  const matchFinished = useMemo(() => {
    return team1Score >= winsNeeded || team2Score >= winsNeeded;
  }, [team1Score, team2Score, winsNeeded]);

  const sessionIsBeingScored = useMemo(() => {
    const scoreArrays = [team1Scores, team2Scores];
    const uniqueLengths = new Set(
      scoreArrays.map((arr) => {
        return arr.length;
      }),
    );
    return uniqueLengths.size > 1;
  }, [team1Scores, team2Scores]);

  const lastSessionIndex = useMemo(() => {
    if (matchNotStarted) {
      return 0;
    }

    if (!sessionIsBeingScored) {
      return team1Scores.length;
    }
    return Math.max(team1Scores.length, team2Scores.length) - 1;
  }, [
    sessionIsBeingScored,
    matchNotStarted,
    team1Scores.length,
    team2Scores.length,
  ]);

  const matchData = useMemo(() => {
    return {
      outcome,
      competitor1,
      competitor2,
      numberOfTeams,
      team1Scores,
      team2Scores,
      bestOf,
      team1Score,
      team2Score,
      matchFinished,
      team1: team1Original,
      team2: team2Original,
      ...rest,
      games,
      isMatchDisabled,
      userTeam,
      lastSessionIndex,
    };
  }, [
    outcome,
    competitor1,
    competitor2,
    numberOfTeams,
    team1Scores,
    team2Scores,
    bestOf,
    team1Score,
    team2Score,
    matchFinished,
    team1Original,
    team2Original,
    rest,
    games,
    isMatchDisabled,
    userTeam,
    lastSessionIndex,
  ]);

  return (
    <MatchContext.Provider value={{ ...matchData }}>
      {children}
    </MatchContext.Provider>
  );
});
//TODO: @shaffy9633: Refactor
