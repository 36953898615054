import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { MatchDetailsCompetitor } from './MatchDetailsCompetitor';
import { useMatch } from '../MatchProvider';
import { useActiveMatchRouter } from '../../../../hooks/tournaments/useActiveMatchRouter';

export const MatchDetailsScoreline = memo(
  function MatchDetailsScorelineUnmemoized() {
    const { competitor1, competitor2, team1Scores, team2Scores } = useMatch();
    const { sessionIndex } = useActiveMatchRouter();
    const showStatus = !!competitor1 && !!competitor2;
    const theme = useTheme();
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        p="8px 12px !important"
        sx={{
          borderRadius: `10px`,
          background: theme.palette.background.elevationSolid[14],
          mb: 4,
        }}
      >
        {!!competitor1 && (
          <MatchDetailsCompetitor
            showStatus={showStatus}
            name={competitor1.name}
            avatarUrl={competitor1.avatarUrl}
            outcome={competitor1.outcome}
            score={team1Scores?.[Number(sessionIndex)]}
            sx={{ pr: 4 }}
          />
        )}
        <Divider orientation="vertical" flexItem sx={{ ml: -4 }} />
        {!!competitor2 && (
          <MatchDetailsCompetitor
            showStatus={showStatus}
            name={competitor2.name}
            avatarUrl={competitor2.avatarUrl}
            outcome={competitor2.outcome}
            score={team2Scores?.[Number(sessionIndex)]}
            sx={{ flexDirection: 'row-reverse' }}
            innerSx={{ flexDirection: 'row-reverse' }}
            typographySx={{ pr: '8px !important' }}
          />
        )}
      </Stack>
    );
  },
);
