import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
} from 'react';
import { AlgoliaSearchParams } from '../../../functions/src/types/AlgoliaSearchParams';
import { memo } from '../../util/memo';
import { useInfiniteHits } from 'react-instantsearch';
import isEqual from 'fast-deep-equal/react';

const PreemptStateContext = createContext<
  | [
      Partial<AlgoliaSearchParams> | undefined,
      Dispatch<SetStateAction<Partial<AlgoliaSearchParams> | undefined>>,
    ]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(undefined as any);

export type PreemptStateProviderProps = {
  children: ReactNode;
};

const PreemptStateProviderUnmemoized: FC<PreemptStateProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<Partial<AlgoliaSearchParams> | undefined>(
    undefined,
  );

  const valueMemoed = useMemo(() => {
    return [state, setState] as [
      Partial<AlgoliaSearchParams> | undefined,
      Dispatch<SetStateAction<Partial<AlgoliaSearchParams> | undefined>>,
    ];
  }, [state]);

  return (
    <PreemptStateContext.Provider value={valueMemoed}>
      {children}
    </PreemptStateContext.Provider>
  );
};

export const PreemptStateProvider = memo(PreemptStateProviderUnmemoized);

export const usePreemptState = () => {
  const infiniteHits = useInfiniteHits();
  return useContext(PreemptStateContext)[0] || infiniteHits?.results?._state;
};

export const useSetPreemptState = () => {
  const [stateOld, setState] = useContext(PreemptStateContext);
  return (stateNew: Partial<AlgoliaSearchParams> | undefined) => {
    if (isEqual(stateOld, stateNew)) {
      return;
    }
    setState(stateNew);
  };
};
