import { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type InputType2 = 'text' | 'number' | 'datetime-local';

export type FieldInputProps2<T> = {
  regex?: RegExp;
  regexExplained?: string;
  value?: T;
  onChange: (value: T) => void;
} & Omit<TextFieldProps, 'type' | 'value' | 'onChange'>;

export type BasicInputProps2<T extends InputType2> = {
  inputType: T;
  value?: string;
  onChange?: (value: string) => void;
} & Omit<
  T extends 'text'
    ? FieldInputProps2<string>
    : T extends 'number'
    ? FieldInputProps2<number>
    : FieldInputProps2<Date>,
  'value' | 'onChange'
>;

// eslint-disable-next-line @blumintinc/blumint/require-memo
export const BasicInput2 = <T extends InputType2>({
  inputType,
  regex,
  regexExplained,
  value,
  required,
  onChange,
  ...rest
}: BasicInputProps2<T>): JSX.Element => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);

  const handleChange: TextFieldProps['onChange'] = (event) => {
    const fieldValue = event.target.value;
    const isValid =
      (!!regex && !!value ? regex.test(fieldValue) : true) &&
      (!required || !!fieldValue);

    setError(!isValid);
    setHelperText(
      isValid
        ? undefined
        : `Invalid input${regexExplained ? `: ${regexExplained}` : ''}`,
    );

    if (isValid) {
      onChange && onChange(fieldValue);
    }
  };
  return (
    <TextField
      {...rest}
      id="outlined-textarea"
      variant="outlined"
      type={inputType}
      defaultValue={value}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      required={required}
      InputLabelProps={
        inputType === 'datetime-local' ? { shrink: true } : undefined
      }
    />
  );
};
