import { createContext } from 'use-context-selector';
import { ChannelGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup';
import {
  MutateSetContextType,
  MutateSetProvider,
  useManageEntity,
} from '../../contexts/MutateSetContext';
import { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { memo } from '../../util/memo';

export const ChannelMembersContext = createContext<
  MutateSetContextType<ChannelGroupUser>
>(undefined as unknown as MutateSetContextType<ChannelGroupUser>);

export type ChannelMembersProviderProps = {
  membersState: [
    ChannelGroupUser[],
    Dispatch<SetStateAction<ChannelGroupUser[]>>,
  ];
  children: React.ReactNode;
};

const ChannelMembersProviderUnmemoized = ({
  membersState,
  children,
}: ChannelMembersProviderProps) => {
  return (
    <MutateSetProvider
      context={ChannelMembersContext}
      setState={membersState}
      identifierKey="userId"
    >
      {children}
    </MutateSetProvider>
  );
};
export const ChannelMembersProvider = memo(ChannelMembersProviderUnmemoized);

export const CHANNEL_MEMBER_IDENTIFIER_KEY = 'userId' as const;
export const useChannelMember = (user: ChannelGroupUser) => {
  const { option, union, remove } = useManageEntity<ChannelGroupUser>({
    identifier: user.userId,
    identifierKey: CHANNEL_MEMBER_IDENTIFIER_KEY,
    context: ChannelMembersContext,
  });

  const toggle = useCallback(() => {
    if (option === 'ADD') {
      union(user);
    } else {
      // eslint-disable-next-line security/detect-object-injection
      remove(user[CHANNEL_MEMBER_IDENTIFIER_KEY]);
    }
  }, [option, remove, union, user]);

  return useMemo(() => {
    return { option, toggle };
  }, [option, toggle]);
};
