import {
  createContext,
  useCallback,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { LiveKitRoom } from '@livekit/components-react';
import type { DatabaseReference } from 'firebase/database';
import { toRoomId } from '../../functions/src/util/liveKit/toRoomId';
import { AudioTracks } from '../components/voice-chat/AudioTracks';

export const LEAVE_CALL_AUDIO = '/assets/audio/leave-call.mp3';
export const JOIN_CALL_AUDIO = '/assets/audio/join-call.mp3';

export type VoiceChatMode = 'arena' | 'studio';

export type RoomState = {
  roomPath: string;
  roomRef: DatabaseReference;
  roomId: string;
} | null;

export type RoomContextProps = {
  setRoomAccessToken: (token: string) => void;
  initialize: (roomPath: string, mode: VoiceChatMode) => Promise<void>;
  rooms: Record<VoiceChatMode, RoomState>;
};

export const RoomContext = createContext<RoomContextProps | undefined>(
  undefined,
);

export const useRoom = () => {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error('useRoom must be used within a RoomProvider');
  }
  return context;
};

const RoomProviderUnmemoized = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>();
  const [rooms, setRooms] = useState<Record<VoiceChatMode, RoomState>>({
    arena: null,
    studio: null,
  });

  const setRoomAccessToken = useCallback((tokenResponse: string) => {
    setToken(tokenResponse);
  }, []);

  const initialize = useCallback(
    async (newRoomPath: string, mode: VoiceChatMode) => {
      const { database } = await import('../config/firebase-client/database');
      const { get, set, ref, query, limitToFirst } = await import(
        'firebase/database'
      );

      const roomRef = ref(database, newRoomPath);
      const roomSnapshot = await get(query(roomRef, limitToFirst(1)));

      if (!roomSnapshot.exists()) {
        await set(roomRef, {
          id: toRoomId(newRoomPath),
          callers: {},
          callerCount: 0,
        });
      }

      setRooms((prev) => {
        return {
          ...prev,
          [mode]: {
            roomPath: newRoomPath,
            roomId: toRoomId(newRoomPath),
            roomRef,
          },
        };
      });
    },
    [],
  );

  const memoizedValue = useMemo(() => {
    return {
      setRoomAccessToken,
      initialize,
      rooms,
    };
  }, [setRoomAccessToken, initialize, rooms]);

  return (
    <RoomContext.Provider value={memoizedValue}>
      {/* @ts-expect-error typescript complains a ReactNode isn't a valid JSX element */}
      <LiveKitRoom
        audio
        video={false}
        token={token}
        serverUrl={process.env.NEXT_PUBLIC_LIVEKIT_HOST as string}
        options={{ disconnectOnPageLeave: false }}
        onConnected={async () => {
          await new Promise((resolve) => {
            return setTimeout(resolve, 300);
          });
          const audio = new Audio(JOIN_CALL_AUDIO);
          await audio.play();
        }}
        onDisconnected={async () => {
          const audio = new Audio(LEAVE_CALL_AUDIO);
          await audio.play();
        }}
      >
        <AudioTracks />
        {children}
      </LiveKitRoom>
    </RoomContext.Provider>
  );
};

export const RoomProvider = memo(RoomProviderUnmemoized);
