import type { DocumentData } from 'firebase/firestore';
import { Dispatch, SetStateAction, useCallback } from 'react';
import type {
  EditStep,
  EditStepPreFirestore,
} from '../../../functions/src/types/firestore/EditStep/Step';
import { useAuth } from '../../contexts/AuthContext';

export const useOptimisticFieldUpdate = <T extends DocumentData>({
  addNewSteps,
  applyStep,
  setDocument,
}) => {
  const { uid } = useAuth();
  return useCallback(
    async <TKey extends keyof T>(
      updates: {
        field: TKey;
        value: T[TKey] | T[string] | { insert?: T[TKey]; index: number };
        action: EditStep['action'];
      }[],
    ) => {
      if (!uid) {
        return;
      }
      const newSteps = updates.map(({ field, ...rest }) => {
        return {
          clientId: uid,
          path: String(field),
          ...rest,
        };
      }) as EditStepPreFirestore[];
      addNewSteps(newSteps);
      newSteps.forEach((step) => {
        return applyStep(step, setDocument as Dispatch<SetStateAction<T>>);
      });
    },
    [addNewSteps, applyStep, setDocument, uid],
  );
};
