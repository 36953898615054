import { useRealtimeChannelGroups } from '../../contexts/RealtimeChannelGroupContext';
import { useCallback } from 'react';

export const useFindChannelGroup = () => {
  const { realtimeChannelGroups } = useRealtimeChannelGroups();

  const findByChannelGroupId = useCallback(
    (channelGroupId?: string) => {
      return realtimeChannelGroups.find((channelGroup) => {
        return channelGroup.id === channelGroupId;
      });
    },
    [realtimeChannelGroups],
  );

  const findByTournamentId = useCallback(
    (
      tournamentId: string | undefined,
      filterType: 'general' | 'personalGroup',
    ) => {
      return realtimeChannelGroups.find((channelGroup) => {
        const { groupFilter } = channelGroup;
        return groupFilter.find((filter) => {
          return (
            filter.type === filterType && filter.tournamentId === tournamentId
          );
        });
      });
    },
    [realtimeChannelGroups],
  );

  return {
    findByChannelGroupId,
    findByTournamentId,
  };
};
