// ColumnWrapper.tsx
import { ReactNode, FC } from 'react';
import { memo } from '../../../util/memo';
import { MatchAggregated } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useWayfinder } from '../../../hooks/tournaments/useWayfinder';

export type WayfinderWrapperProps = {
  match: MatchAggregated<Date>;
  isFinal?: boolean;
  children: ReactNode;
};

const WayfinderWrapperUnmemoized: FC<WayfinderWrapperProps> = ({
  match,
  isFinal = false,
  children,
}) => {
  const targetWayfinder = useWayfinder({ match, isFinal });
  return <div ref={targetWayfinder}>{children}</div>;
};

export const WayfinderWrapper = memo(WayfinderWrapperUnmemoized);
