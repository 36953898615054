import { UserProviderInfo } from '../../../functions/src/types/firestore/User';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { extractProviderLabel } from '../../../functions/src/util/tournaments/extractProviderLabel';

export const formatConnectedProviders = (
  providersConnected: UserProviderInfo[],
  selectedIds: string[],
) => {
  return providersConnected
    .filter(({ uid }) => {
      return selectedIds.includes(uid);
    })
    .reduce((acc, curr) => {
      const { providerId } = curr;
      const providerValue = extractProviderLabel(curr);
      acc[extractProviderId(providerId)] = providerValue;
      return acc;
    }, {} as Record<string, string>);
};
