import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../gradients/GradientTypography';
import { PayoutFormatted } from '../../../functions/src/types/firestore/Game/Competition';
import { PayoutSummary } from '../tournaments/PayoutSummary';

export type TournamentPayoutHeaderProps = Required<PayoutFormatted> &
  Partial<{
    ActionComponent: ReactNode;
    showPayout: boolean;
    showAction: boolean;
  }>;

export const TournamentPayoutHeader = memo(
  function TournamentPayoutHeaderUnmemoized({
    ActionComponent,
    showPayout = true,
    showAction = false,
    ...payoutFormatted
  }: TournamentPayoutHeaderProps) {
    return (
      <Stack direction="row" justifyContent="space-between" width="100%" mb={4}>
        <GradientTypography
          textTransform="uppercase"
          variant="h6"
          gradientColor="primary.vertical"
          fontWeight={700}
        >
          Prize Pool
        </GradientTypography>
        <Stack direction="row" gap={4} alignItems="center">
          {showPayout && <PayoutSummary showIcons {...payoutFormatted} />}
          {showAction && ActionComponent}
        </Stack>
      </Stack>
    );
  },
);
