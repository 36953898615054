import { memo } from '../../../../util/memo';
import { HoverProvider } from '../../../../contexts/HoverContext';
import { ImageOptimized } from '../../../image/ImageOptimized';
import { ScreenshotHoverButtons } from './ScreenshotHoverButtons';
import Stack from '@mui/material/Stack';

export const SCREENSHOT_WIDTH_DETAILS = 720;
export const SCREENSHOT_HEIGHT_DETAILS = 405;

export type ScreenshotImageHoverProps = {
  screenshotUrl: string;
  uploaderUid: string;
};

const ScreenshotImageHoverUnmemoized = ({
  screenshotUrl,
  uploaderUid,
}: ScreenshotImageHoverProps) => {
  return (
    <HoverProvider>
      <Stack
        sx={{
          position: 'relative',
          width: SCREENSHOT_WIDTH_DETAILS,
          height: SCREENSHOT_HEIGHT_DETAILS,
        }}
      >
        <ImageOptimized
          layout="fill"
          style={{ borderRadius: '10px' }}
          src={screenshotUrl}
        />
        <ScreenshotHoverButtons
          uploaderUid={uploaderUid}
          screenshotUrl={screenshotUrl}
        />
      </Stack>
    </HoverProvider>
  );
};

export const ScreenshotImageHover = memo(ScreenshotImageHoverUnmemoized);
