import { useCallback } from 'react';
import {
  AllSignInMethod,
  NonRedirectSignInMethod,
} from '../../../functions/src/types/firestore/User';
import { useWizard } from '../../components/wizard/Wizard';
import { useAuth } from '../../contexts/AuthContext';
import { useWalletAuth } from '../web3/useWalletAuth';
import { AuthFlowStore } from './useAuthFlowBase';
import { useOAuthSignIn } from './useOAuthSignIn';
import { isOAuthProvider } from '../../../functions/src/util/auth/isOAuthProvider';

export const WALLET_ADD_REMOVE_ID = 'WALLET_ADD_REMOVE_ID';

export const useSignIn = () => {
  const { signInWallet, resetWallet } = useWalletAuth();
  const { signInOAuth } = useOAuthSignIn();
  const { uid } = useAuth();
  const { go } = useWizard<AuthFlowStore>();

  const signInNonRedirect = useCallback(
    async (provider: NonRedirectSignInMethod) => {
      if (provider === 'password') {
        return go('Enter Email');
      }
      if (provider === 'phone') {
        return go('Enter Phone Number');
      }
      if (provider === 'wallet') {
        go(undefined);
        if (!uid) {
          await signInWallet();
          return;
        }
        await resetWallet();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [go, resetWallet, signInWallet, !!uid],
  );

  const signIn = useCallback(
    async (provider: AllSignInMethod) => {
      if (isOAuthProvider(provider)) {
        await signInOAuth(provider);
        return;
      }
      await signInNonRedirect(provider);
    },
    [signInNonRedirect, signInOAuth],
  );

  return signIn;
};
