import { StreamChat, type StreamChatOptions } from 'stream-chat';
import { ExponentialBackoff } from '../util/apis/ExponentialBackoff';

export class StreamChatProxy extends StreamChat {
  public deleteUser = ExponentialBackoff.for429(super.deleteUser.bind(this));

  public deleteUsers = ExponentialBackoff.for429(super.deleteUsers.bind(this));

  public deactivateUser = ExponentialBackoff.for429(
    super.deactivateUser.bind(this),
  );

  public deactivateUsers = ExponentialBackoff.for429(
    super.deactivateUsers.bind(this),
  );

  public channel = super.channel.bind(this);

  public createToken = super.createToken.bind(this);

  public deleteChannels = ExponentialBackoff.for429(
    super.deleteChannels.bind(this),
  );

  public queryChannels = ExponentialBackoff.for429(
    super.queryChannels.bind(this),
  );

  public partialUpdateUser = ExponentialBackoff.for429(
    super.partialUpdateUser.bind(this),
  );

  public partialUpdateUsers = ExponentialBackoff.for429(
    super.partialUpdateUsers.bind(this),
  );

  public upsertUser = ExponentialBackoff.for429(super.upsertUser.bind(this));

  public upsertUsers = ExponentialBackoff.for429(super.upsertUsers.bind(this));

  public banUser = ExponentialBackoff.for429(super.banUser.bind(this));

  public unbanUser = ExponentialBackoff.for429(super.unbanUser.bind(this));

  constructor(apiKey: string, apiSecret?: string, options?: StreamChatOptions) {
    super(apiKey, apiSecret, options);
  }
}
