import { Required } from 'utility-types';
import {
  MatchAggregated,
  Round,
} from '../../types/firestore/Game/Tournament/Bracket';
import { Timestamp } from 'firebase-admin/firestore';

export function splitRound<TTime = Timestamp>(
  matches: MatchAggregated<TTime>[] = [],
) {
  if (!matches.length) {
    return {};
  }
  const indexHalf = Math.ceil(matches.length / 2);

  if (matches.length === 1) {
    return {
      center: matches,
    };
  }

  return {
    left: matches.slice(0, indexHalf),
    right: matches.slice(indexHalf),
  };
}

export function splitRounds<TTime = Timestamp>(rounds: Round<TTime>[]) {
  const roundsSplit = rounds.reduce(
    (acc, round) => {
      const { center, left, right } = splitRound(round.matches);
      if (center) {
        acc.center.push({ ...round, matches: center });
      }
      if (left) {
        acc.left.push({ ...round, matches: left });
      }
      if (right) {
        acc.right.push({ ...round, matches: right });
      }
      return acc;
    },
    {
      center: [] as Required<Round<TTime>, 'matches'>[],
      left: [] as Required<Round<TTime>, 'matches'>[],
      right: [] as Required<Round<TTime>, 'matches'>[],
    },
  );
  roundsSplit.right.reverse();
  return roundsSplit;
}
