import { ALPHABET } from './Strings';

export const base62Encode = (hexString: string) => {
  const numberInitial = BigInt('0x' + hexString);

  const encode = (num: bigint, acc = ''): string => {
    if (num === BigInt(0)) {
      return acc || '0';
    }
    const remainder = Number(num % BigInt(62));
    const nextChar = ALPHABET[Number(remainder)];
    const nextNum = num / BigInt(62);

    return nextNum > 0 ? encode(nextNum, nextChar + acc) : nextChar + acc;
  };

  return encode(numberInitial);
};
