export const SCROLLABLE_VALUES = ['auto', 'scroll', 'overlay'] as const;
export type ScrollableValue = typeof SCROLLABLE_VALUES[number];

class ScrollableAncestorFinder {
  constructor(private currentElement: HTMLElement | null) {}

  public find(): HTMLElement | null {
    while (this.currentElement && !this.isDocumentElement) {
      if (this.isScrollable) {
        return this.currentElement;
      }
      this.currentElement = this.parentElement;
    }
    return null;
  }

  private get isDocumentElement() {
    return this.currentElement === document.documentElement;
  }

  private get isScrollable() {
    return this.relevantStyles.some(ScrollableAncestorFinder.isScrollableValue);
  }

  private get relevantStyles() {
    if (!this.currentElement) return [];
    const { overflow, overflowY, overflowX } = window.getComputedStyle(
      this.currentElement,
    );
    return [overflow, overflowY, overflowX];
  }

  private static isScrollableValue(value: string): value is ScrollableValue {
    return SCROLLABLE_VALUES.includes(value as ScrollableValue);
  }

  private get parentElement() {
    return this.currentElement?.parentElement ?? null;
  }
}

export function findScrollableAncestor(element: HTMLElement | null) {
  return new ScrollableAncestorFinder(element).find();
}
