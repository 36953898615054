import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { DialogSuccessfulStandard } from '../../dialog/DialogSuccessfulStandard';
import { useWizard } from '../../wizard/Wizard';

export const SCORING_SUCCESS_TITLE = 'Scored Successfully';
export const SCORING_SUCCESS_DESCRIPTION =
  'Once your opponent submits their score, the winners will advance to the next round.';
export const MatchScoredSuccessfully = memo(
  function MatchScoredSuccessfullyUnmemoized() {
    const { go } = useWizard();
    const close = useCallback(() => {
      go(undefined);
    }, [go]);
    return (
      <DialogSuccessfulStandard
        title={SCORING_SUCCESS_TITLE}
        description={SCORING_SUCCESS_DESCRIPTION}
        onFinish={close}
      />
    );
  },
);
