import { useState } from 'react';
import { Stack, Popover } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import EventIcon from '@mui/icons-material/EventRounded';
import { useTheme } from '@mui/material/styles';
import { DateString } from 'src/contexts/EventsLazyContext';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { GradientBorder } from '../../gradients/GradientBorder';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { toDateString } from '../../../../functions/src/util/dates/Dates';
import { memo } from '../../../util/memo';
import { LoadingCheckmark } from './LoadingCheckmark';

export type CalendarNavigationProps = {
  onLeft: () => void;
  onRight: () => void;
  onDoubleLeft: () => void;
  onDoubleRight: () => void;
  selectDate: (date: DateString) => void;
  leftState: 'loading' | 'checkmark' | null;
  rightState: 'loading' | 'checkmark' | null;
  visibleDate?: Date;
};

// eslint-disable-next-line react-memo/require-memo
const CalendarNavigationUnmemoized: React.FC<CalendarNavigationProps> = ({
  onLeft,
  onRight,
  onDoubleLeft,
  onDoubleRight,
  selectDate,
  leftState,
  rightState,
  visibleDate = new Date(),
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [internalDate, setInternalDate] = useState(dayjs(visibleDate));

  // eslint-disable-next-line no-shadow
  const openCalendar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCalendar = () => {
    setAnchorEl(null);
  };

  const chooseDate = (date: DateString) => {
    if (selectDate) {
      selectDate(date);
    }
    closeCalendar();
  };

  const changeDate = (value, selectionState) => {
    if (!value) return;

    setInternalDate(value);
    if (selectionState === 'partial') return;

    chooseDate(toDateString(value.toDate()));
  };

  const isOpen = !!anchorEl;
  const popoverId = isOpen ? 'simple-popover' : undefined;

  return (
    <GradientBorder
      borderRadius={'10px'}
      borderWidth={1}
      disableGlow={true}
      gradientColor="disabled"
      sx={{
        borderBottom: '1',
        borderRight: '0',
        borderTop: '0',
        borderLeft: '0',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="2px"
        sx={{
          px: 1,
          py: 0.5,
          background: theme.palette.background.elevation[4],
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
        }}
      >
        <Stack direction="row" alignItems="center">
          <GradientIconButton
            onClick={onDoubleLeft}
            gradientColor="primary.horizontal"
            IconComponent={KeyboardDoubleArrowLeftIcon}
          />
          <GradientIconButton
            gradientColor="primary.horizontal"
            IconComponent={KeyboardArrowLeftIcon}
            onClick={onLeft}
          />
          <Stack direction="row" sx={{ pl: 2 }}>
            <LoadingCheckmark state={leftState} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" sx={{ pr: 2 }}>
            <LoadingCheckmark state={rightState} />
          </Stack>
          <GradientIconButton
            onClick={openCalendar}
            gradientColor="primary.horizontal"
            IconComponent={EventIcon}
          />
          <Popover
            id={popoverId}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={closeCalendar}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={internalDate}
                views={['year', 'month', 'day']}
                onChange={changeDate}
                sx={{
                  backgroundColor: theme.palette.background.dark,
                  border: theme.palette.background.dark,
                }}
              />
            </LocalizationProvider>
          </Popover>
          <GradientIconButton
            onClick={onRight}
            gradientColor="primary.horizontal"
            IconComponent={KeyboardArrowRightIcon}
          />
          <GradientIconButton
            onClick={onDoubleRight}
            gradientColor="primary.horizontal"
            IconComponent={KeyboardDoubleArrowRightIcon}
          />
        </Stack>
      </Stack>
    </GradientBorder>
  );
};

export const CalendarNavigation = memo(CalendarNavigationUnmemoized);
