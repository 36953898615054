import { MatchCellLayout } from './MatchCellLayout';
import { ChipTime } from '../../../ChipTime';
import { MatchCompetitorHighlightable } from '../MatchCompetitorHighlightable';
import { useTheme } from '@mui/material/styles';
import { useMatchCompetitors } from '../../../../hooks/useMatchCompetitors';
import { MatchLiveBadge } from '../MatchLiveBadge';
import { ReactNode } from 'react';
import { memo } from '../../../../util/memo';
import { useMatch } from '../MatchProvider';

export type MatchCellProps = {
  isLarge?: boolean;
  Button?: ReactNode;
};

export const MatchCell = memo(function MatchCellUnmemoized({
  isLarge = false,
  Button,
}: MatchCellProps) {
  const theme = useTheme();
  const { startTime, status, isMatchDisabled } = useMatch();

  const { MatchCompetitors } = useMatchCompetitors({
    CompetitorComponent: MatchCompetitorHighlightable,
  });
  // TODO: Show startTime to user once design has been finalized
  // and we are confident in our match scheduling
  return (
    <MatchLiveBadge>
      <MatchCellLayout
        sx={{
          ...(status === 'delayed' || status === 'active'
            ? theme.glow.panel
            : {}),
          width: isLarge ? 228 : 180,
          opacity: isMatchDisabled ? 0.4 : 1,
        }}
        Overlay={startTime && <ChipTime time={startTime} />}
        Competitors={MatchCompetitors}
        Interaction={Button}
      />
    </MatchLiveBadge>
  );
});
