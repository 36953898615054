import { useMemo, useState, useEffect } from 'react';
import { memo } from '../../../../util/memo';
import Stack from '@mui/system/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { GradientRadioGroup } from '../GradientRadioGroup';
import { useLinkEventContext } from '../../../../contexts/organizer/LinkEventContext';
import { GradientTypography } from '../../../gradients/GradientTypography';
import { LoadingButton } from '../../../buttons/LoadingButton';
import { ImageOptimized } from '../../../image/ImageOptimized';
import { useTheme } from '@mui/material/styles';
import { PrizeGrid } from '../PrizeGrid';
import { useCurrency } from '../../../../contexts/CurrencyContext';
import { useCreatePrizePool } from '../../../../hooks/prize-pool/useCreatePrizePool';
import { PayoutSummary } from '../../../tournaments/PayoutSummary';

export const LinkToPrizePool = memo(function LinkToPrizePoolUnmemoized() {
  const { prizePools, setSelectedPrizePool, linkValidator, event } =
    useLinkEventContext();

  const [selectedPrizePoolIndex, setSelectedPrizePoolIndex] =
    useState<number>();

  useEffect(() => {
    setSelectedPrizePool(prizePools[Number(selectedPrizePoolIndex)]);
  }, [prizePools, selectedPrizePoolIndex, setSelectedPrizePool]);

  const prizePoolsLinkable = useMemo(() => {
    return prizePools.filter(({ linkedId }) => {
      return !linkedId;
    });
  }, [prizePools]);

  const theme = useTheme();
  const { formatPayout } = useCurrency();
  const create = useCreatePrizePool();
  return (
    <Stack width="100%">
      {prizePoolsLinkable?.length > 0 ? (
        <GradientRadioGroup
          items={prizePoolsLinkable.map(({ id, title, prizes }) => {
            const payoutFormatted = formatPayout(prizes);
            const validation = linkValidator(event, prizes);
            const isDisabled = !validation.isValid;
            return {
              key: id,
              label: (
                <Stack>
                  <Stack direction="row" gap={4}>
                    <GradientTypography
                      variant="h6"
                      gradientColor={
                        isDisabled ? 'disabled.vertical' : 'primary.vertical'
                      }
                      textTransform="uppercase"
                      fontWeight={700}
                    >
                      {/* TODO: can remove id once prize pools are cleared up. */}
                      Prize pool {title || id}
                    </GradientTypography>
                    <PayoutSummary showIcons {...payoutFormatted} />
                  </Stack>
                  {'errorMessage' in validation
                    ? validation.errorMessage
                    : null}
                </Stack>
              ),
              disabled: isDisabled,
              contentOnSelect: (
                <Stack
                  sx={{
                    ...theme.panels[0],
                    'margin-top': '-16px !important',
                    borderRadius: '0px 0px 0px 0px',
                  }}
                >
                  <PrizeGrid items={prizes} />
                </Stack>
              ),
            };
          })}
          selectedIndex={selectedPrizePoolIndex ?? null}
          onValueChange={(index) => {
            return setSelectedPrizePoolIndex(index);
          }}
        />
      ) : (
        <Stack
          direction="row"
          width={'100%'}
          alignItems="center"
          justifyContent="center"
          gap={8}
          sx={theme.panels[1]}
        >
          <ImageOptimized
            src={'/assets/images/mascots/mascot-crying.png'}
            width={200}
            height={200}
          />
          <div>
            <Alert severity="info" variant="outlined">
              <GradientTypography
                variant="h6"
                gradientColor="primary.vertical"
                textTransform="none"
              >
                {`You don't have any valid prize pools to link`}
              </GradientTypography>
              <Typography color="text.primary" textTransform="none" mb={4}>
                Create a prize pool to continue with the linking process.
              </Typography>
              <LoadingButton variant="contained" fullWidth onClick={create}>
                Create prize pool
              </LoadingButton>
            </Alert>
          </div>
        </Stack>
      )}
    </Stack>
  );
});
