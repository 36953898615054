import Stack from '@mui/material/Stack';
import { FC } from 'react';
import { memo } from '../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { AuthenticationInput } from '../AuthenticationInput';
import { ForgotPassword } from '../ForgotPassword';
import { SignInButton } from 'src/components/buttons/SignInButton';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import { Password } from '../Password';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';
import Typography from '@mui/material/Typography';
export const ERROR_MESSAGE_MAX_LENGTH = '440px';
export type AuthenticationEmailSignInInputs = 'email' | 'password';

const AuthenticationEmailSignInDialogUnmemoized: FC = () => {
  const {
    userCredentials: { email },
    signIn,
    errorMessage,
  } = useAuthSubmit();
  const { password: passwordErrorMessage, generic: genericErrorMessage } =
    errorMessage;

  return (
    <Stack width="100%">
      <DialogAuthenticationNavBar />

      <DialogBodyStandard
        title={'Email Sign In'}
        description={'Email found! Enter account password:'}
      >
        <Stack
          spacing={6}
          alignSelf={'center'}
          sx={{ pb: 4 }}
          width="100%"
          maxWidth="440px"
        >
          <Stack spacing={4} width="100%">
            <AuthenticationInput
              label={'Email Address'}
              type={'email'}
              name={'email'}
              defaultValue={email}
              disabled={true}
            />
            <Password
              passwordType={'password'}
              autoFocus={true}
              errorMessage={passwordErrorMessage}
              onSubmit={signIn}
            />
            <ForgotPassword />
          </Stack>
          <SignInButton />
          {!!genericErrorMessage && (
            <Typography
              variant="body2"
              color="error.main"
              sx={{ maxWidth: ERROR_MESSAGE_MAX_LENGTH }}
            >
              {genericErrorMessage}
            </Typography>
          )}
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEmailSignInDialog = memo(
  AuthenticationEmailSignInDialogUnmemoized,
);
