import { useCallback, useContext } from 'react';
import { Round } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useWizardDialog } from '../wizard/useWizardDialog';
import {
  MatchProvider,
  useMatch,
} from '../../components/tournaments/match/MatchProvider';
import { MatchDetails } from '../../components/tournaments/match/details/MatchDetails';
import { ScreenshotUploadPortal } from '../../components/tournaments/self-moderation/ScreenshotUploadPortal';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { MatchScoredSuccessfully } from '../../components/tournaments/self-moderation/MatchScoredSuccessfully';
import { SessionScoringPortal } from '../../components/tournaments/self-moderation/SessionScoringPortal';
import { ScoringProvider } from '../../contexts/ScoringContext';

export const ENTRIES_IDS_MATCH = [
  'details',
  'scoring',
  'screenshot',
  'successfullyScored',
  // 'placement',
] as const;

export type EntryIdsMatch = typeof ENTRIES_IDS_MATCH[number];

export type OpenMatchDetailsWizardParams = {
  rounds: Round<Date>[];
  entryId?: EntryIdsMatch;
  onClose?: () => void;
};

export const useMatchDetailsWizard = (isMatchDisabled: boolean) => {
  const { open: openDialog } = useWizardDialog();
  const tournament = useContext(TournamentContext);
  const match = useMatch();

  const open = useCallback(
    ({ rounds, entryId, onClose }: OpenMatchDetailsWizardParams) => {
      openDialog({
        storeDefault: {},
        onClose,
        elements: {
          details: <MatchDetails rounds={rounds} />,
          scoring: (
            <ScoringProvider>
              <SessionScoringPortal />
            </ScoringProvider>
          ),
          // 'Placement Selection Portal': <MatchPlacementSelector />,
          screenshot: <ScreenshotUploadPortal />,
          successfullyScored: <MatchScoredSuccessfully />,
        },
        elementIdEntry: !entryId ? 'details' : entryId,
        wrapperProps: { showCloseIcon: false },
        Wrapper: (
          <TournamentContext.Provider value={tournament}>
            <MatchProvider {...match} isMatchDisabled={isMatchDisabled}>
              <></>
            </MatchProvider>
          </TournamentContext.Provider>
        ),
      });
    },
    [openDialog, tournament, match, isMatchDisabled],
  );
  return { open };
};
