import {
  DialogBodyConfirmation,
  DialogBodyConfirmationProps,
} from '../dialog/DialogBodyConfirmation';
import { memo } from '../../util/memo';
import { useWizard } from './Wizard';

export type DialogBodyConfirmationWizardProps = Omit<
  DialogBodyConfirmationProps,
  'onConfirm' | 'onCancel'
> & {
  onConfirm?: () => Promise<string[][] | void>;
  onCancel?: () => Promise<string[][] | void>;
};

const DialogBodyConfirmationWizardUnmemoized = ({
  title,
  description,
  onCancel,
  onConfirm,
  ...rest
}: DialogBodyConfirmationWizardProps) => {
  const { go, set } = useWizard();
  return (
    <DialogBodyConfirmation
      title={title}
      description={description}
      onCancel={async () => {
        const keyValuePairs = await onCancel?.();
        if (keyValuePairs) {
          for (const [key, value] of keyValuePairs) {
            set(key, value);
          }
        }
        go(undefined);
      }}
      onConfirm={async () => {
        const keyValuePairs = await onConfirm?.();
        if (keyValuePairs) {
          for (const [key, value] of keyValuePairs) {
            set(key, value);
          }
        }
        go('OutcomeSubmit');
      }}
      {...rest}
    />
  );
};

export const DialogBodyConfirmationWizard = memo(
  DialogBodyConfirmationWizardUnmemoized,
);
