import { useState, useCallback, useMemo } from 'react';
import { useRouter } from './useRouter';
import { LocationType } from '../../contexts/routing/UrlModificationsContext';
import { useRouterValueOptimistic } from './useRouterValueOptimistic';

export type RouterState<T extends string | undefined = string | undefined> = [
  T,
  ReturnType<typeof useState<string | undefined>>[1],
];
export type RouterStateGetter = [RouterState[0]] | RouterState;

export type UseRouterStateProps = {
  key: string;
  location?: LocationType;
  defaultValue?: string;
  silent?: boolean;
};

export function useRouterState<TProps extends UseRouterStateProps>({
  key,
  defaultValue,
  location = 'queryParam',
  silent = false,
}: TProps): RouterState<
  TProps['defaultValue'] extends string ? string : string | undefined
> {
  const { replaceParam, replaceSegment } = useRouter();

  const valueOptimistic = useRouterValueOptimistic(key, location);

  const setValue = useCallback(
    (
      newValue:
        | string
        | undefined
        | ((prevState: string | undefined) => string | undefined),
    ) => {
      const newValueResolved =
        typeof newValue === 'function'
          ? newValue(valueOptimistic || defaultValue)
          : newValue;

      if (newValueResolved === valueOptimistic) {
        return;
      }

      const replace = location === 'queryParam' ? replaceParam : replaceSegment;
      replace({ name: key, value: newValueResolved, silent });
    },
    [
      valueOptimistic,
      location,
      replaceParam,
      replaceSegment,
      defaultValue,
      key,
      silent,
    ],
  );

  return useMemo(() => {
    return [
      (valueOptimistic || defaultValue) as TProps['defaultValue'] extends string
        ? string
        : string | undefined,
      setValue,
    ];
  }, [valueOptimistic, setValue, defaultValue]);
}
