import Typography from '@mui/material/Typography';
import { useMemo, ReactNode } from 'react';
import { GradientTypography } from '../../components/gradients/GradientTypography';
import { useTheme } from '@mui/material/styles';
import type { UserResponse } from 'stream-chat';

export type UseStyledTextParams = {
  mentioned_users: UserResponse[];
  link: string;
  linkText: string;
  text?: string;
};

export const useStyledText = ({
  mentioned_users,
  link,
  linkText,
  text,
}: UseStyledTextParams) => {
  const theme = useTheme();

  const styledText = useMemo(() => {
    if ((!mentioned_users?.length && !link) || !text) {
      return text;
    }

    const textSplit = text.split(' ');

    const styledTextParts: ReactNode[] = textSplit.map((word, index) => {
      const isNotLast = index < textSplit.length - 1;

      const mentionedUser = mentioned_users?.find(({ name }) => {
        return word.includes(`@${name}`);
      });

      if (mentionedUser) {
        return (
          <GradientTypography
            key={`${mentionedUser}`}
            gradientColor="primary.vertical"
            sx={{ display: 'inline', pr: isNotLast ? 1 : 0 }}
          >
            {word}
          </GradientTypography>
        );
      }
      if (word === linkText) {
        return (
          <a
            href={link}
            key={link}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography
              color={theme.palette.primary.mid}
              sx={{
                display: 'inline',
                pr: isNotLast ? 1 : 0,
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {word}
            </Typography>
          </a>
        );
      }
      return word + (isNotLast ? ' ' : '');
    });

    return styledTextParts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentioned_users, text, linkText, link]);
  return styledText;
};
