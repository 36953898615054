import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type TournamentDateProps = {
  title: string;
  dateFormatted: string;
};

export const TournamentDate = memo(function TournamentDateUnmemoized({
  title,
  dateFormatted,
}: TournamentDateProps) {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <GradientTypography
        gradientColor="primary.vertical"
        variant="subtitle1"
        textTransform="uppercase"
      >
        {title}:
      </GradientTypography>
      <Typography
        component="span"
        color="text.primary"
        textTransform="uppercase"
      >
        {dateFormatted}
      </Typography>
    </Stack>
  );
});
