import { memo } from '../../util/memo';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { useTheme } from '@mui/material/styles';
import { GradientTypography } from '../gradients/GradientTypography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { DataGridProps, GridSlotsComponentsProps } from '@mui/x-data-grid';

export const ROW_HEIGHT = 52 as const;
export const BASE_SPACING = 30 as const;
export const DECREASING_FACTOR = 0.5 as const;

export type LeaderboardStandardProps = {
  title: string;
  pageSize?: number;
} & GridSlotsComponentsProps &
  Omit<DataGridProps, 'pageSize'>;

const LeaderboardStandardUnmemoized = ({
  title,
  pageSize,
  ...dataGridProps
}: LeaderboardStandardProps) => {
  const theme = useTheme();

  return (
    <GradientBorder
      disableBorder
      gradientBackground={`linear-gradient(${theme.palette.background.elevation[7]}, 
  ${theme.palette.background.elevation[7]})`}
      sx={{ borderRadius: '10px' }}
      innerSx={{ p: 3 }}
    >
      <GradientTypography
        gradientColor="primary.vertical"
        variant="h6"
        textTransform={'uppercase'}
        sx={{ mb: -2 }}
      >
        {title}
      </GradientTypography>
      <Box>
        <DataGrid
          {...dataGridProps}
          pageSize={pageSize}
          initialState={{
            sorting: {
              sortModel: [{ field: 'rank', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </GradientBorder>
  );
};
export const LeaderboardStandard = memo(LeaderboardStandardUnmemoized);
