import { Round } from 'functions/src/types/firestore/Game/Tournament/Bracket';

export const countFinishedMatches = (round: Round<Date>) => {
  return (round.matches || []).reduce((prev, curr) => {
    return prev + (!!curr.winner ? 1 : 0);
  }, 0);
};

export const calculateActiveRound = (rounds?: Round<Date>[]) => {
  if (!rounds) {
    return;
  }
  // We have to overwrite finishedMatchCount because this is not local to each cohort.
  const roundsModified = rounds.map((round) => {
    return {
      ...round,
      finishedMatchCount: countFinishedMatches(round),
    };
  });
  const activeRoundIndexFound = roundsModified.findIndex((round) => {
    return round.finishedMatchCount < round.maxMatchCount;
  });
  const activeRoundIndex =
    activeRoundIndexFound === -1 ? rounds.length - 1 : activeRoundIndexFound;
  const activeRound = roundsModified[Number(activeRoundIndex)];
  const activeRoundProgress =
    activeRound.finishedMatchCount / activeRound.maxMatchCount;

  return { activeRoundIndex, activeRoundProgress };
};
