import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AvatarNext } from 'src/components/AvatarNext';
import { CompetitorOutcome } from '../../CompetitorOutcome';
import { useMatch } from '../MatchProvider';
import { type SxProps } from '@mui/material/styles';
import { memo } from '../../../../util/memo';
import { Outcome } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';

import { useMemo } from 'react';
export type MatchDetailsCompetitorProps = {
  name?: string;
  avatarUrl?: string;
  outcome?: Outcome;
  showStatus: boolean;
  sx?: SxProps;
  innerSx?: SxProps;
  typographySx?: SxProps;
  score?: number;
};

export const MatchDetailsCompetitor = memo(
  function MatchDetailsCompetitorUnmemoized({
    name,
    avatarUrl,
    outcome,
    showStatus,
    typographySx,
    innerSx,
    sx,
    score,
  }: MatchDetailsCompetitorProps) {
    const { status } = useMatch();

    const label = useMemo(() => {
      return (
        !!name && (
          <>
            <AvatarNext src={avatarUrl} />
            <Box sx={{ ...typographySx }}>
              <Typography color="text.primary" sx={{ wordBreak: 'break-all' }}>
                {name}
              </Typography>
            </Box>
          </>
        )
      );
    }, [avatarUrl, name, typographySx]);

    const outcomeComponent = useMemo(() => {
      return (
        !!showStatus && (
          <CompetitorOutcome
            outcome={outcome}
            score={score}
            status={!!status ? status : 'inactive'}
          />
        )
      );
    }, [showStatus, outcome, score, status]);
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="50%"
        sx={{
          ...sx,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            ...innerSx,
          }}
        >
          {label}
        </Stack>
        {outcomeComponent}
      </Stack>
    );
  },
);
