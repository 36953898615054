import { useEffect, useState } from 'react';

export function useWindowFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  useEffect(() => {
    const changeVisibility = () => {
      return setIsWindowFocused(!document.hidden);
    };
    document.addEventListener('visibilitychange', changeVisibility);
    return () => {
      return document.removeEventListener('visibilitychange', changeVisibility);
    };
  }, []);

  return isWindowFocused;
}
