import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useAuthFlowDialog } from './useAuthFlowDialog';
export type EmailVerificationStatus = true | false | 'unknown';

export const useVerifyEmail = () => {
  const { user, uid, userData } = useAuth();
  const { open: openAuthDialog } = useAuthFlowDialog();
  const [emailVerified, setEmailVerified] =
    useState<EmailVerificationStatus>('unknown');

  useEffect(() => {
    if (
      !uid ||
      !userData?.id ||
      !user?.email ||
      user.emailVerified ||
      emailVerified === true
    ) {
      setEmailVerified('unknown');
      return;
    }
    setEmailVerified(user.emailVerified);
  }, [uid, userData?.id, user?.email, user?.emailVerified, emailVerified]);

  useEffect(() => {
    if (emailVerified !== false) {
      return;
    }
    openAuthDialog({ entryDialog: 'Verifying Email' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified, uid]);

  const updateEmailVerified = useCallback(
    (newEmailVerified: EmailVerificationStatus) => {
      setEmailVerified(newEmailVerified);
    },
    [setEmailVerified],
  );

  return { updateEmailVerified, emailVerified };
};
