import { ViewerCount, ViewerCountProps } from './ViewerCount';
import { memo } from '../../../util/memo';
import { useHover } from '../../../contexts/HoverContext';

const ViewerCountHoverableUnmemoized = (props: ViewerCountProps) => {
  const { isHovered } = useHover();
  return <ViewerCount {...props} isVisible={isHovered} />;
};

export const ViewerCountHoverable = memo(ViewerCountHoverableUnmemoized);
