import { Member } from '../../types/firestore/Game/Tournament/Guestlist';

export const findMemberByUserId = (members: Member[], userId?: string) => {
  if (!userId) {
    return;
  }
  return members.find((member) => {
    return member.userId === userId;
  });
};
