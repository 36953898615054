import { LoadingButton } from './LoadingButton';
import { memo } from '../../util/memo';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';

export const SignInButton = memo(function SignInButtonUnmemoized() {
  const { signIn, isLoading, isValidated } = useAuthSubmit();

  return (
    <LoadingButton
      variant="contained"
      size="large"
      fullWidth
      onClick={signIn}
      isLoading={isLoading}
      disabled={!isValidated.password}
    >
      Sign In
    </LoadingButton>
  );
});
