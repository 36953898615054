import {
  Member,
  Team,
} from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { AccordionCompetitorProps } from '../../components/tournaments/participants/accordion-competitor/AccordionCompetitor';

export const teamsParser = (
  team: Team<Date>,
  maxTeamSize: number,
): AccordionCompetitorProps => {
  const captain = team.members.find((member) => {
    return member.status === 'captain';
  });
  const acceptedMembers = team.members.filter((member) => {
    return member.status === 'accepted' || member.status === 'reassigned';
  });
  const participants = captain
    ? [captain as Member, ...acceptedMembers]
    : acceptedMembers;

  const parsedTeam = {
    isSoloTeam: team.isSolo,
    entryId: team.id,
    participants,
    teamMax: maxTeamSize,
    teamName: team.name,
    teamImage: team.avatarUrl || captain!.imgUrl,
    registrationTime: team.registrationTime,
    currentTeamFill: participants.length,
    isCheckedIn:
      team.checkedInCount === maxTeamSize ||
      participants.every((member) => {
        return member.checkedIn;
      }),
  };
  if ('waitlisted' in team && team.waitlisted) {
    return {
      ...parsedTeam,
      isWaitlisted: true,
    };
  }
  return parsedTeam;
};
