import { memo } from '../../util/memo';
import { Sponsor } from '../../../functions/src/types/firestore/Game/Competition';
import { ImageOptimized } from '../image/ImageOptimized';
import { Link } from '../Link';
import Box from '@mui/material/Box';
import { useMemo } from 'react';

export type SponsorV3Props = Pick<Sponsor, 'imgUrl' | 'redirectUrl'>;

export const SponsorV3 = memo(function SponsorV3Unmemoized({
  redirectUrl,
  imgUrl,
}: SponsorV3Props) {
  const content = useMemo(() => {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          '& img': {
            p: '4px !important',
          },
        }}
      >
        <ImageOptimized
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          src={imgUrl || ''}
        />
      </Box>
    );
  }, [imgUrl]);

  if (!redirectUrl) return content;

  return <Link href={redirectUrl}>{content}</Link>;
});
