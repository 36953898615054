import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { truncateIfTooLong } from '../../util/truncate';
import { useAuthMethodsDisplay } from './useAuthMethodsDisplay';
import { isCustomOAuthProvider } from '../../../functions/src/util/auth/isCustomOAuthProvider';
import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { useUnlinkDialog } from './useUnlinkDialog';
import Tooltip from '@mui/material/Tooltip';
import { useAuthProviders } from './useAuthProviders';
import { SignInMenuItemProps } from '../../components/header/SignInMenuItem';

export const UNLINK_DIALOG_ID = 'UNLINK_DIALOG';

export const useMenuItemsUnlink = () => {
  const { uid } = useAuth();
  const { providersConnected = [] } = useAuthProviders();
  const authOptionsDisplay = useAuthMethodsDisplay();
  const { open: openUnlinkDialog } = useUnlinkDialog();
  const providersFiltered = useMemo(() => {
    return providersConnected.filter(({ providerId }) => {
      return providerId !== 'password' && providerId !== 'wallet';
    });
  }, [providersConnected]);
  const MenuItemsUnlink: SignInMenuItemProps[] = useMemo(() => {
    return providersFiltered.map(
      ({ providerId, email, displayName, phoneNumber, uid }) => {
        const isPhoneProvider =
          providerId.toLocaleLowerCase().includes('phone') && !!phoneNumber;
        const isFirebaseProvider = isFirebaseOAuthProvider(providerId);
        const isCustomProvider = isCustomOAuthProvider(providerId);
        const { icon } =
          authOptionsDisplay[
            `${
              isFirebaseProvider || isCustomProvider
                ? extractProviderId(providerId)
                : 'phone'
            }`
          ];
        const uidFormatted = isCustomProvider ? `${uid}-${providerId}` : uid;
        const isMainProvider =
          uid === uidFormatted || providersConnected.length === 1;

        const label = isFirebaseProvider
          ? email
          : isPhoneProvider
          ? phoneNumber
          : displayName || email || uid;

        const truncatedLabel = truncateIfTooLong(label);

        return {
          label: truncatedLabel,
          onClick: () => {
            if (providersConnected.length <= 1 || isMainProvider) {
              return;
            }
            return openUnlinkDialog({
              email,
              phoneNumber,
              uid,
              providerId,
              displayName,
            });
          },
          IconComponent: icon,
          Wrapper: isMainProvider ? (
            <Tooltip title="You cannot unlink your primary sign-in method.">
              <div></div>
            </Tooltip>
          ) : undefined,
        };
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersConnected.length, providersFiltered, uid]);
  return { MenuItemsUnlink };
};
