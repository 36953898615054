import { useMemo } from 'react';
import { useWagmiWatch } from './useWagmiWatch';

export function useAccountLowercase() {
  const accountResult = useWagmiWatch('watchAccount', undefined);

  return useMemo(() => {
    if (!accountResult) {
      return undefined;
    }

    const { address, status, connector } = accountResult;
    const addressLowercase = address?.toLowerCase() as
      | `0x${string}`
      | undefined;

    return { address: addressLowercase, status, connector };
  }, [accountResult]);
}
