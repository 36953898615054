import { ref, get } from 'firebase/database';
import { database as realtimeDb } from '../../config/firebase-client/database';

export async function getGroupNumber(groupId: string) {
  const groupNumberRef = ref(realtimeDb, `GroupMap/${groupId}`);
  const snapshot = await get(groupNumberRef);

  if (snapshot.exists()) {
    return snapshot.val() as number;
  }
  return null;
}
