import { MatchAggregated } from '..';

export function isBypassMatch<TDate>(match: MatchAggregated<TDate>) {
  return !!match.winner && (!match.team1 || !match.team2);
}

export function isEmptyMatch<TDate>(match: MatchAggregated<TDate>) {
  return !match.team1 && !match.team2;
}

export function isFullMatch<TDate>(match: MatchAggregated<TDate>) {
  return match.team1 && match.team2;
}
