import { SetShipParams } from '../../../functions/src/callable/ship/setShip.f';

export type SendShipRequestParams = Pick<
  SetShipParams,
  | 'userId'
  | 'groupId'
  | 'groupType'
  | 'subgroupId'
  | 'message'
  | 'bypassPrivacy'
>;

export const sendShipRequest = async (props: SendShipRequestParams) => {
  const { setShip } = await import('../../firebaseCloud/ship/setShip');
  const params: SetShipParams = {
    ...props,
    action: 'sendRequest',
  };

  await setShip(params);
};
