import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { ToggleMicButton } from './ToggleMicButton';
import { MoreOptionsButton } from './MoreOptionsButton';
import { EndCallButton } from './EndCallButton';
import { useLocalParticipant } from '@livekit/components-react';
import { useMemo } from 'react';
import { extractAudioTrack } from '../../util/voice-chat/extractAudioTrack';

const CallControlsUnmemoized = () => {
  const { localParticipant } = useLocalParticipant();
  const { audioTrackPublications } = localParticipant;
  const audioTrack = extractAudioTrack(audioTrackPublications);

  const toggleMicButton = useMemo(() => {
    return !!audioTrack && <ToggleMicButton audioTrack={audioTrack} />;
  }, [audioTrack]);

  return (
    <Stack direction="row">
      {toggleMicButton}
      <MoreOptionsButton />
      <EndCallButton />
    </Stack>
  );
};

export const CallControls = memo(CallControlsUnmemoized);
