export const emailPasswordSignIn = async (email: string, password: string) => {
  const emailFormatted = email.toLowerCase();
  const authImport = import('../../config/firebase-client/auth');
  const { auth } = await authImport;
  const firebaseAuthImport = import('firebase/auth');
  const { signInWithEmailAndPassword, linkWithCredential, EmailAuthProvider } =
    await firebaseAuthImport;
  const currentUser = auth.currentUser;

  if (!currentUser || currentUser.isAnonymous) {
    return signInWithEmailAndPassword(auth, emailFormatted, password);
  }
  const credential = EmailAuthProvider.credential(emailFormatted, password);

  return linkWithCredential(currentUser, credential);
};
