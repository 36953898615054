import {
  RegistrationOption,
  RegistrationOptionCustom,
} from '../../types/firestore/Game/Tournament';

export const isCustomOption = (
  option: RegistrationOption,
): option is RegistrationOptionCustom => {
  return 'name' in option;
};
