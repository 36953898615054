import Typography from '@mui/material/Typography';
import { memo } from '../../../../util/memo';
import { formatDateTime } from 'src/util/dates/Dates';

export type CompetitorTimeProps = {
  time: Date;
};

export const CompetitorTimeUnmemoized: React.FC<CompetitorTimeProps> = ({
  time,
}) => {
  const timeFormatted = formatDateTime(time);

  return (
    <Typography
      variant="subtitle2"
      sx={{
        alignSelf: 'center',
        color: 'text.secondary',
      }}
    >
      {timeFormatted}
    </Typography>
  );
};

export const CompetitorTime = memo(CompetitorTimeUnmemoized);
