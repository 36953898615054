import { useDialog } from '../useDialog';
import { usePersonalChannelGroup } from './usePersonalChannelGroup';
import { DialogActionsStandard } from '../../components/dialog/DialogActionsStandard';
import { ExitChannelGroupExternalParams } from '../../firebaseCloud/messaging/exitChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export const CHANNEL_GROUP_DIALOG_ID = 'CHANNEL_GROUP_DIALOG';

export type OpenRemoveChannelGroupDialogParams =
  ExitChannelGroupExternalParams & {
    isSupport: boolean;
  };

export const useRemoveChannelGroupDialog = () => {
  const { open: openDialog, close } = useDialog(CHANNEL_GROUP_DIALOG_ID);
  const { remove: removeChannelGroup } = usePersonalChannelGroup();
  const { closeChannelGroup } = useActiveChannelGroup();

  const open = (params: OpenRemoveChannelGroupDialogParams) => {
    const { isSupport, groupFilter } = params;

    openDialog({
      title: isSupport ? 'Close Support Ticket' : 'Delete Chat',
      description: isSupport
        ? `By closing this support ticket, you will conclude the support session and this chat will no longer be accessible. Thank you for allowing us to assist you!`
        : `Warning, this action is irreversible! You will no longer have access to this chats messages. Wish to continue?`,
      showCloseIcon: true,
      children: (
        <DialogActionsStandard
          buttons={[
            {
              children: isSupport ? 'Close' : 'Delete',
              isAsync: true,
              onClick: async () => {
                await removeChannelGroup({ groupFilter });
                closeChannelGroup();
                close();
              },
              color: 'error',
            },
            {
              children: 'Cancel',
              color: 'primary',
              isAsync: false,
              onClick: close,
            },
          ]}
        />
      ),
    });
  };
  return { open, close };
};
