import Switch, { SwitchProps } from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { GradientTypography } from './gradients/GradientTypography';
import { ReactNode, useCallback } from 'react';
import { memo } from '../util/memo';

export type SwitchPanelProps = {
  titleLeft: ReactNode;
  titleRight?: ReactNode;
  body?: ReactNode;
} & SwitchProps;

function SwitchPanelUnmemoized({
  titleLeft,
  titleRight,
  body,
  ...props
}: SwitchPanelProps) {
  const generateTitle = useCallback((title: ReactNode) => {
    return typeof title === 'string' ? (
      <GradientTypography
        variant="h6"
        gradientColor="primary.vertical"
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {title}
      </GradientTypography>
    ) : (
      title
    );
  }, []);
  return (
    <GradientBorder
      sx={{
        borderRadius: 10,
        width: 'fit-content',
        height: 'fit-content',
        flex: { xs: '1 1 0', md: 'none' },
      }}
    >
      <FormGroup
        sx={{
          px: { md: 8 },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: { xs: '200px', md: 'none' },
          backgroundColor: 'background.elevation.2',
          borderRadius: 10,
        }}
      >
        {generateTitle(titleLeft)}
        {body}
        <Switch {...props} />
        {!!titleRight && generateTitle(titleRight)}
      </FormGroup>
    </GradientBorder>
  );
}
export const SwitchPanel = memo(SwitchPanelUnmemoized);
