import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import { SxProps, useTheme } from '@mui/material/styles';
import { stableHash } from '../../../functions/src/util/hash/stableHash';

export type PulsateProps = {
  children: ReactNode;
  pulsate: boolean;
  opacityRange?: [number, number];
  color?: string;
  durationMs?: number;
  iterations?: number | 'infinite';
  sx?: SxProps;
};

const PulsateUnmemoized = ({
  children,
  pulsate,
  color: propsColor,
  opacityRange = [0, 1],
  durationMs = 2000,
  iterations = 'infinite',
  sx = {},
}: PulsateProps) => {
  const theme = useTheme();
  const colorFallback = propsColor || theme.palette.primary.main;
  const animationId = `pulse-${stableHash(colorFallback)}`;

  const keyframes = !!pulsate
    ? {
        [`@keyframes ${animationId}`]: {
          '0%': {
            opacity: opacityRange[0],
          },
          '50%': {
            opacity: opacityRange[1],
          },
          '100%': {
            opacity: opacityRange[0],
          },
        },
      }
    : undefined;

  return (
    <Box
      sx={{
        position: 'relative',
        '::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          border: !!pulsate ? `2px solid ${colorFallback}` : undefined,
          animation: !!pulsate
            ? `${animationId} ${durationMs}ms ${iterations}`
            : undefined,
          ...keyframes,
          ...sx,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const Pulsate = memo(PulsateUnmemoized);
