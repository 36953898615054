import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ImageOptimized } from '../../image/ImageOptimized';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useMessage } from './MessageContext';
import { useAuth } from '../../../contexts/AuthContext';
import { MESSAGE_MAX_WIDTH } from './Message';
import { ELLIPSIS_SX } from '../../../util/string';

export const YOU = 'you' as const;

const MessageQuotedUnmemoized = () => {
  const theme = useTheme();
  const { userData } = useAuth();
  const {
    isMine,
    isDm,
    message: { quoted_message, user },
  } = useMessage();

  const repliedName = quoted_message?.user?.name;
  const repliedToMe = repliedName === userData?.username;
  const name = repliedToMe && isDm ? YOU : repliedName;
  const replierName = isMine ? YOU : `${user?.name}`;
  const text = quoted_message?.text;
  const attachments = quoted_message?.attachments;

  const attachmentThumbnail =
    !!attachments?.length &&
    (attachments[0].image_url || attachments[0].thumb_url);

  const textSx = {
    maxWidth: MESSAGE_MAX_WIDTH,
    ...ELLIPSIS_SX,
  };

  return (
    <Stack direction="column">
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ ...textSx, pl: 2 }}
      >
        {`${replierName} replied to ${name}`}
      </Typography>
      <Stack
        direction="row"
        sx={{
          background: `${theme.palette.primary.vertical} no-repeat`,
          backgroundSize: '2px 100%',
          backgroundPosition: 'left center',
          p: 2,
          mb: 1,
        }}
      >
        <Box>
          {!!text && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ ...textSx, whiteSpace: 'pre-wrap' }}
            >
              {text}
            </Typography>
          )}
          {!!attachmentThumbnail && (
            <Box
              overflow="hidden"
              borderRadius="4px"
              width="72px"
              height="72px"
            >
              <ImageOptimized
                src={attachmentThumbnail}
                width="72px"
                height="72px"
                style={{ opacity: 0.75 }}
              />
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
export const MessageQuoted = memo(MessageQuotedUnmemoized);
