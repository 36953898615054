import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { useReferee } from 'src/contexts/RefereeContext';

export const CaptainOnlySwitch = () => {
  const { detailsOptions, setDetailsOptions } = useReferee();
  return (
    <FormControl
      onChange={() => {
        if (setDetailsOptions) {
          setDetailsOptions((prev) => {
            return {
              ...prev,
              captainOnly: !prev?.captainOnly,
            };
          });
        }
      }}
      sx={{ width: '100%' }}
    >
      <FormControlLabel
        sx={{ ml: 0, justifyContent: 'space-between' }}
        labelPlacement="start"
        label={
          <Typography variant="body1" textTransform="uppercase">
            {'Captain only'}
          </Typography>
        }
        control={<Switch checked={detailsOptions?.captainOnly} />}
      />
    </FormControl>
  );
};
