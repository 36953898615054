export const isRare = (rarity?: string) => {
  if (!rarity) {
    return false;
  }
  const rareRarities = [
    'gold',
    'iridescent',
    'safari',
    'caution',
    'corsair',
    'ice',
    'puncher',
    'diy',
    'toxic',
    'hunt',
    'corsair',
    'desert',
    'mooncrash',
    'steampunk',
    'vandal',
    'myth',
    'octopus',
    'legendary',
    'epic',
  ];
  return rareRarities.includes(rarity);
};
