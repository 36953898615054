import Stack from '@mui/material/Stack';
import { FormattedPayoutSummary } from '../../FormattedPayoutSummary';
import { useMemo } from 'react';
import { Round } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { memo } from '../../../../util/memo';
import { useMatch } from '../MatchProvider';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDateFormatter } from '../../../../hooks/useDateFormatter';
import { DATE_FORMAT } from './MatchDetails';

export type MatchRewardsProps = {
  rounds: Round<Date>[];
};

export const MatchRewards = memo(function MatchRewardsUnmemoized({
  rounds,
}: MatchRewardsProps) {
  const { outcome, id, startTime } = useMatch();
  const theme = useTheme();
  const { reward, rewardSecured } = useMemo(() => {
    const round = rounds.find((roundEach) => {
      return roundEach.matches?.find((match) => {
        return match.id === id;
      });
    });
    const rewardRound = round?.payout;
    const roundPreviousId = round?.previous;
    const roundPrevious = rounds.find((round) => {
      return round.id === roundPreviousId;
    });
    const rewardSecuredNext = roundPrevious?.payout;
    return { reward: rewardRound, rewardSecured: rewardSecuredNext };
  }, [id, rounds]);

  const format = useDateFormatter({ dateFormat: DATE_FORMAT, separator: ', ' });
  const dateFormatted = startTime ? format(startTime) : undefined;

  return (
    <Stack direction="column" alignItems="center" width="100%">
      {!!rewardSecured?.length && (
        <>
          <FormattedPayoutSummary payoutSingle={rewardSecured} />
          {' Guaranteed.'}
        </>
      )}
      {!!reward?.length && (
        <>
          <span
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            {`Play${!!outcome ? 'ed' : 'ing'} for `}
          </span>
          <FormattedPayoutSummary payoutSingle={reward} />
        </>
      )}
      <Typography variant="caption" color="text.secondary">
        {dateFormatted}
      </Typography>
    </Stack>
  );
});
