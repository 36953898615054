import { type ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { SxProps, useTheme } from '@mui/material/styles';

export const SponsorWrapperV3 = memo(function SponsorWrapperV3Unmemoized({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        ...theme.panels[1],
        padding: 0,
        position: 'relative',
        width: '100%',
        height: '166px',
        ...sx,
      }}
      direction={'column'}
      alignItems="center"
      justifyContent="center"
      gap={4}
    >
      {children}
    </Stack>
  );
});
