import { ChangeEvent, useMemo } from 'react';
import { memo } from '../../util/memo';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useTheme } from '@mui/material/styles';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';

export const PHONE_CONFIG_DEFAULT = {
  phoneNumber: '',
  phoneNumberUnformatted: '',
  countryDialCode: '1',
  country: 'us',
};

export const PhoneNumberInput = memo(function PhoneNumberInputUnmemoized() {
  const theme = useTheme();
  const { onInputChange, isValidated, hasUserTyped } = useAuthSubmit();
  const { phoneNumber: isValidatedPhoneNumber } = isValidated;
  const { country, countryDialCode, phoneNumber } = PHONE_CONFIG_DEFAULT;

  const validPhoneNumber = useMemo(() => {
    if (!hasUserTyped.phoneNumber) {
      return true;
    }

    return isValidatedPhoneNumber;
  }, [hasUserTyped.phoneNumber, isValidatedPhoneNumber]);

  return (
    <PhoneInput
      disableDropdown={true}
      country={country}
      value={countryDialCode + phoneNumber}
      specialLabel="" //this prop is bad. if you remove it, it renders an empty white box on the top left of the textfield
      containerClass={'input-gradient-border'}
      inputClass={'phone-number-input'}
      inputStyle={{
        background: 'transparent',
        boxShadow: 'none',
        paddingLeft: '47px',
        color: theme.palette.text.primary,
      }}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: true,
      }}
      onChange={(
        _value: string,
        _country: CountryData,
        event: ChangeEvent<HTMLInputElement>,
      ) => {
        return onInputChange('phoneNumber', event.target.value);
      }}
      isValid={validPhoneNumber}
    />
  );
});
