import { UserProviderInfo } from '../../types/firestore/User';
import { isCustomOAuthProvider } from '../auth/isCustomOAuthProvider';

export const extractProviderLabel = (providerInfo: UserProviderInfo) => {
  const { providerId, phoneNumber, email, displayName, uid } = providerInfo;
  return providerId === 'phone'
    ? phoneNumber
    : isCustomOAuthProvider(providerId)
    ? displayName
    : email || uid;
};
