import { ANONYMOUS_USER_ID } from '../../../functions/src/util/messaging/getStreamVariables';
import { toGetStreamId } from '../../../functions/src/util/messaging/mapId';
import { FirebaseUserLocal } from '../../contexts/AuthContext';
import { Optional } from 'utility-types';

export const extractGetStreamId = ({
  isAnonymous,
  uid,
}: Optional<Pick<FirebaseUserLocal, 'isAnonymous' | 'uid'>>) => {
  return !uid || isAnonymous ? ANONYMOUS_USER_ID : toGetStreamId(uid);
};
