import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
import { useTheme, SxProps } from '@mui/material/styles';
import { useMobile } from 'src/hooks/useMobile';
import Typography from '@mui/material/Typography';
import { FormattedPayoutSummary } from './FormattedPayoutSummary';
import { Token } from '../../../functions/src/types/firestore/User/Payout';

export type FinalRoundWinLossRewards = {
  win: Token[];
  loss: Token[];
};

export type RoundStepperBoxProps = {
  children: JSX.Element | JSX.Element[];
  finalRoundPrize: FinalRoundWinLossRewards;
  invert?: boolean;
  isGrandFinal?: boolean;
  sx?: SxProps;
};

export const RoundStepperBox = memo(function RoundStepperBoxUnmemoized(
  props: RoundStepperBoxProps,
) {
  const { children, finalRoundPrize, invert, isGrandFinal, sx } = props;
  const { win, loss } = finalRoundPrize;
  const theme = useTheme();
  const isMobile = useMobile();
  const showChin = win || loss;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: invert ? 'column-reverse' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          py: 2,
          px: 2,
          mb: showChin ? 0 : 6,
          background: theme.palette.background.elevationSolid[10],
          border: undefined,
          borderRadius: `10px  ${isMobile && invert ? `0px` : `10px`}
           ${isMobile && !invert && !isGrandFinal ? `0px` : `10px`} 10px`,
        }}
      >
        {children}
      </Box>
      {showChin && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: isMobile && !isGrandFinal ? 'flex-end' : 'center',
            mb: isGrandFinal ? 8 : 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              px: 4,
              pb: invert ? 0 : 2,
              pt: invert ? 2 : 0,
              mt: isMobile ? '-3.5px' : !invert ? '-2px' : 0,
              mb: isMobile ? '-3.5px' : !!invert ? '-2px' : 0,
              borderBottomLeftRadius: invert ? undefined : 10,
              borderBottomRightRadius: invert ? undefined : 10,
              borderTopLeftRadius: invert ? 10 : undefined,
              borderTopRightRadius: invert ? 10 : undefined,
              background: theme.palette.background.elevationSolid[10],
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
              zIndex: 1,
              flexWrap: 'wrap',
              maxWidth: '250px',
            }}
          >
            {!!finalRoundPrize.win && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ display: 'flex', gap: 1 }}
              >
                W:
                <FormattedPayoutSummary payoutSingle={win} variant="body2" />
              </Typography>
            )}
            {!!finalRoundPrize.loss && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ display: 'flex', gap: 1 }}
              >
                L:
                <FormattedPayoutSummary payoutSingle={loss} variant="body2" />
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
});
