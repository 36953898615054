import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useConvertedHits } from '../../hooks/algolia/useConvertedHits';
import { useMergeIndexingHits } from '../../hooks/algolia/useMergeIndexingHits';
import type { useInfiniteHits } from 'react-instantsearch';
import { useHitsDeduped } from './useDedupedHits';

const LOAD_MORE_TIMEOUT_MS = 2000 as const;

export const useCustomHits = (
  infiniteHits: ReturnType<typeof useInfiniteHits>,
  skipHits?: string[],
) => {
  const { items, showMore, isLastPage } = infiniteHits;
  const filters = infiniteHits.results?._state?.filters;

  const itemsSkipped = useMemo(() => {
    if (!skipHits?.length) return items;

    return items.filter((item) => {
      return !skipHits.includes(item.objectID);
    });
  }, [items, skipHits]);

  const isLoading = useMemo(() => {
    const processingMs = infiniteHits.results?.processingTimeMS;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const serverMs = (infiniteHits.results as any)?.serverTimeMS as
      | number
      | undefined;
    return infiniteHits.items.length === 0 && !processingMs && !serverMs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    infiniteHits.hits.length,
    infiniteHits.results?.processingTimeMS,
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
    (infiniteHits.results as any)?.serverTimeMS,
  ]);

  const itemsDeduped = useHitsDeduped(itemsSkipped);
  const hitsConverted = useConvertedHits(itemsDeduped);
  const hitsMerged = useMergeIndexingHits(hitsConverted, filters);

  const hitsMergedRef = useRef(hitsMerged);
  useEffect(() => {
    hitsMergedRef.current = hitsMerged;
  }, [hitsMerged]);

  const canLoadMoreRef = useRef(true);
  const loadMore = useCallback(() => {
    if (!showMore || !canLoadMoreRef.current) {
      return Promise.resolve(0);
    }
    canLoadMoreRef.current = false;

    const currentLength = hitsMergedRef.current.length;
    showMore();

    const startTime = Date.now();

    return new Promise<number>((resolve) => {
      const checkForNewHits = () => {
        const newHitsCount = hitsMergedRef.current.length - currentLength;
        if (newHitsCount > 0) {
          resolve(newHitsCount);
          return;
        }

        if (Date.now() - startTime >= LOAD_MORE_TIMEOUT_MS) {
          resolve(0);
          return;
        }

        requestAnimationFrame(checkForNewHits);
      };

      checkForNewHits();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!showMore]);

  useEffect(() => {
    canLoadMoreRef.current = !isLastPage;
  }, [items.length, isLastPage]);

  return useMemo(() => {
    return { hits: hitsMerged, loadMore, isLoading };
  }, [hitsMerged, loadMore, isLoading]);
};
