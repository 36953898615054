import { ReactElement, ReactNode, cloneElement, isValidElement } from 'react';

const insertInnermost = (child: ReactNode, component: ReactNode): ReactNode => {
  if (!isValidElement(child)) {
    return component;
  }

  return cloneElement(child as ReactElement<{ children?: ReactNode }>, {
    children:
      child.props && 'children' in child.props
        ? insertInnermost(child.props.children, component)
        : component,
  });
};

export const insertInnermostChild = (
  parent: ReactElement<{ children?: ReactNode }>,
  component: ReactNode,
) => {
  return cloneElement(parent, {
    children: insertInnermost(parent.props.children, component),
  });
};
