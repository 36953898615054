import { forwardRef, ForwardedRef, useMemo } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { GradientIcon } from './GradientIcon';
import { useLinearGradient } from 'src/hooks/useLinearGradient';
import { SxProps, useTheme } from '@mui/material/styles';
import { GradientBorder } from './GradientBorder';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { memo } from '../../util/memo';

export type GradientIconButtonProps = IconButtonProps & {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  gradientColor?: string;
  iconBaseFill?: string;
  withBorder?: boolean;
  sx?: SxProps;
  sxOuter?: SxProps;
};

const GradientIconButtonUnmemoized = forwardRef(
  (props: GradientIconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      IconComponent,
      gradientColor = 'primary.horizontal',
      iconBaseFill,
      withBorder = false,
      size,
      sx,
      sxOuter,
      ...otherProps
    } = props;
    const theme = useTheme();
    const gradientName = useLinearGradient(gradientColor);

    const iconBtn = useMemo(() => {
      const iconBtnSx = {
        '&:hover': { svg: { fill: `url(#${gradientName})` } },
        ...sxOuter,
      };
      if (!iconBaseFill) {
        iconBtnSx['svg'] = { fill: `url(#${gradientName})` };
      }
      return (
        <IconButton {...otherProps} ref={ref} sx={iconBtnSx}>
          <GradientIcon
            IconComponent={IconComponent}
            size={size}
            gradientColor={gradientColor}
            sx={{
              ...(iconBaseFill ? { fill: iconBaseFill } : {}),
              fontSize: 'inherit',
              ...sx,
            }}
          />
        </IconButton>
      );
    }, [
      IconComponent,
      gradientColor,
      gradientName,
      iconBaseFill,
      otherProps,
      ref,
      size,
      sx,
      sxOuter,
    ]);

    return (
      <>
        {withBorder ? (
          <GradientBorder
            gradientBackground={`linear-gradient(${theme.palette.background.elevationSolid[7]}, 
        ${theme.palette.background.elevationSolid[7]}), 
        ${theme.palette.primary.vertical}`}
            sx={{
              borderRadius: 10,
              mx: 2,
              filter: `drop-shadow(${theme.shadowsHard.primaryElevation2})`,
              boxShadow: undefined,
              '&:hover': {
                boxShadow: undefined,
              },
            }}
          >
            {iconBtn}
          </GradientBorder>
        ) : (
          iconBtn
        )}
      </>
    );
  },
);

export const GradientIconButton = memo(GradientIconButtonUnmemoized);
GradientIconButtonUnmemoized.displayName = 'GradientIconButtonUnmemoized';
