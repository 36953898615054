import { useMemo } from 'react';
import { verifyAssets } from './wax/verifyAssets';
import { verifyWaxWallet } from './wax/verifyWaxWallet';
import { verifyEvioUser } from './ev.io/verifyEvioUser';

const ERRORS_MAP = {
  accountNotFound: 'Account not found. Please check your input and try again',
  invalidRequest: 'Invalid request',
  missingAssets: 'Missing required assets',
};

export const VERIFY_USER_MAP = {
  'ev.io': verifyEvioUser,
};

export const useAsyncValidation = (gameId: string) => {
  return useMemo(() => {
    return {
      waxWallet: async (
        waxAddress: string,
        requirementTokens?: Record<
          string,
          { collectionName: string; templateId: string; count: number }[]
        >,
      ) => {
        try {
          const isValidHolder = requirementTokens
            ? await verifyAssets({
                walletAddress: waxAddress,
                _id: 'wax',
                requirementTokens,
              })
            : true;

          if (!isValidHolder) {
            return { valid: false, errorMsg: ERRORS_MAP.missingAssets };
          }

          const isValidWallet = await verifyWaxWallet({ waxAddress });
          return {
            valid: isValidWallet,
            errorMsg: isValidWallet ? undefined : ERRORS_MAP.accountNotFound,
          };
        } catch (error) {
          return {
            valid: false,
            errorMsg: ERRORS_MAP.invalidRequest,
          };
        }
      },
      [gameId in VERIFY_USER_MAP ? 'inGameId' : '']: async (
        inGameId: string,
      ) => {
        try {
          const isValidUser = !!inGameId
            ? await VERIFY_USER_MAP[`${gameId}`](inGameId)
            : false;
          return {
            valid: isValidUser,
            errorMsg: isValidUser ? undefined : ERRORS_MAP.accountNotFound,
          };
        } catch (error) {
          return {
            valid: false,
            errorMsg: ERRORS_MAP.invalidRequest,
          };
        }
      },
    };
  }, [gameId]);
};
