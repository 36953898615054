/**
 * Sanitizes and parses a JSON string safely by escaping special characters
 * before parsing.
 *
 * @param jsonString - The JSON string to parse
 * @returns The parsed JavaScript object
 */
export function escapedParse(jsonString: string) {
  const sanitizedString = toLiteralWhitespace(jsonString);
  return JSON.parse(sanitizedString);
}

export function toLiteralWhitespace(jsonString: string) {
  return jsonString
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t');
}
