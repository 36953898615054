import { memo } from '../../../../util/memo';
import { ColumnDesktop } from './ColumnDesktop';
import { calcColumnHeightLoser } from './calcColumnHeight';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ColumnLoserProps } from './ColumnWrapperLoser';

function ColumnLeftLoserUnmemoized({ Pairs }: ColumnLoserProps) {
  const isOfOne = Pairs.length === 1;
  const isOfTwo = Pairs.length === 2;

  return (
    <ColumnDesktop
      columnHeightCalculator={calcColumnHeightLoser}
      spacing={0}
      alignItems="unset"
      justifyContent="space-between"
      width="unset"
    >
      {Pairs.map(([prior, latter], index) => {
        const isFirstOfTwo = isOfTwo && index === 0;
        const isSecondOfTwo = isOfTwo && index === 1;

        return (
          <Stack
            key={`column-left-loser-${index}`}
            direction={isSecondOfTwo ? 'column-reverse' : 'column'}
            width="auto"
            height="100%"
            alignItems="center"
            spacing={4}
            px={isOfTwo ? 4 : 0}
            pt={isFirstOfTwo ? 27 : isOfOne ? 48 : 0}
            pb={isSecondOfTwo ? 7 : 0}
          >
            <Box>{prior}</Box>
            <Box mr={isOfOne ? '-106px !important' : '-112px !important'}>
              {latter}
            </Box>
          </Stack>
        );
      })}
    </ColumnDesktop>
  );
}

export const ColumnLeftLoser = memo(ColumnLeftLoserUnmemoized);
