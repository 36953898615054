import { Shadows } from '@mui/material/styles';

export const SHADOWS: Shadows = [
  'none',
  '0px 0px 1px -1px rgba(57, 156, 250, 0.4), 0px 0px 1px rgba(57, 156, 250, 0.28), 0px 0px 3px rgba(57, 156, 250, 0.24)',
  '0px 0px 1px -2px rgba(57, 156, 250, 0.4), 0px 0px 2px rgba(57, 156, 250, 0.28), 0px 0px 5px rgba(57, 156, 250, 0.24)',
  '0px 0px 3px -2px rgba(57, 156, 250, 0.4), 0px 0px 4px rgba(57, 156, 250, 0.28), 0px 0px 8px rgba(57, 156, 250, 0.24)',
  '0px 0px 4px -1px rgba(57, 156, 250, 0.4), 0px 0px 5px rgba(57, 156, 250, 0.28), 0px 0px 10px rgba(0, 51, 233, 0.24)',
  '0px 0px 5px -1px rgba(57, 156, 250, 0.4), 0px 0px 8px rgba(57, 156, 250, 0.28), 0px 0px 14px rgba(57, 156, 250, 0.24)',
  '0px 0px 5px -1px rgba(57, 156, 250, 0.4), 0px 0px 10px rgba(57, 156, 250, 0.28), 0px 0px 16px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 5px -3px rgba(57, 156, 250, 0.4), 0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 14px 2px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 6px -3px rgba(57, 156, 250, 0.4), 0px 0px 12px 1px rgba(57, 156, 250, 0.28), 0px 0px 16px 2px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 6px -3px rgba(57, 156, 250, 0.4), 0px 0px 14px 1px rgba(57, 156, 250, 0.28), 0px 0px 16px 3px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 7px -4px rgba(57, 156, 250, 0.4), 0px 0px 15px 1px rgba(57, 156, 250, 0.28), 0px 0px 20px 3px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 8px -4px rgba(57, 156, 250, 0.4), 0px 0px 17px 2px rgba(57, 156, 250, 0.28), 0px 0px 22px 4px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 8px -4px rgba(57, 156, 250, 0.4), 0px 0px 19px 2px rgba(57, 156, 250, 0.28), 0px 0px 24px 4px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 9px -4px rgba(57, 156, 250, 0.4), 0px 0px 21px 2px rgba(57, 156, 250, 0.28), 0px 0px 26px 4px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 9px -5px rgba(57, 156, 250, 0.4), 0px 0px 22px 2px rgba(57, 156, 250, 0.28), 0px 0px 28px 5px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 10px -5px rgba(57, 156, 250, 0.4), 0px 0px 24px 2px rgba(57, 156, 250, 0.28), 0px 0px 30px 5px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 11px -5px rgba(57, 156, 250, 0.4), 0px 0px 26px 2px rgba(57, 156, 250, 0.28), 0px 0px 32px 5px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 11px -5px rgba(57, 156, 250, 0.4), 0px 0px 28px 2px rgba(57, 156, 250, 0.28), 0px 0px 34px 6px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 12px -6px rgba(57, 156, 250, 0.4), 0px 0px 29px 2px rgba(57, 156, 250, 0.28), 0px 0px 36px 6px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 13px -6px rgba(57, 156, 250, 0.4), 0px 0px 31px 3px rgba(57, 156, 250, 0.28), 0px 0px 38px 7px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 13px -6px rgba(57, 156, 250, 0.4), 0px 0px 33px 3px rgba(57, 156, 250, 0.28), 0px 0px 40px 7px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 14px -6px rgba(57, 156, 250, 0.4), 0px 0px 35px 3px rgba(57, 156, 250, 0.28), 0px 0px 42px 7px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 14px -7px rgba(57, 156, 250, 0.4), 0px 0px 36px 3px rgba(57, 156, 250, 0.28), 0px 0px 44px 8px rgba(57, 156, 250, 0.24)',
  '0px 0px 10px 1px rgba(57, 156, 250, 0.28), 0px 0px 5px -2px rgba(57, 156, 250, 0.4), 0px 0px 16px 1px rgba(57, 156, 250, 0.24)', // '0px 0px 15px -7px rgba(57, 156, 250, 0.4), 0px 0px 38px 3px rgba(57, 156, 250, 0.28), 0px 0px 46px 8px rgba(57, 156, 250, 0.24)',
];

export const SHADOWS_HARD_COLOR = {
  primary: 'rgba(7, 50, 201, 0.8)',
  secondary: 'rgba(13, 78, 106, 0.8)',
  error: 'rgba(165, 9, 9, 0.6)',
  warning: 'rgba(255, 100, 13, 0.8)',
  info: 'rgba(7, 50, 201, 0.8)',
  success: 'rgba(13, 78, 106, 0.8)',
  royal: 'rgba(112, 54, 178, 0.8)',
};

export const SHADOWS_HARD_DROPSHADOW = ['0.5px 1.5px 0.5px', '0px 2px 0.5px'];

export type ShadowsHard = {
  [key in
    | `${
        | 'primary'
        | 'secondary'
        | 'error'
        | 'warning'
        | 'info'
        | 'success'
        | 'royal'}Elevation${1 | 2}`
    | 'emboss']: string;
};

export const SHADOWS_HARD: ShadowsHard = {
  ...(Object.keys(SHADOWS_HARD_COLOR).reduce((prev, color) => {
    SHADOWS_HARD_DROPSHADOW.forEach((shadow, i) => {
      prev[`${color}Elevation${i + 1}`] = `${shadow} ${
        SHADOWS_HARD_COLOR[`${color}`]
      }`;
    });
    return prev;
  }, {}) as Omit<ShadowsHard, 'emboss'>),
  emboss:
    '-1px -1px 1px 0px rgba(0, 100, 233, 0.9), 1px 1px 0.5px rgba(2, 40, 174, 0.5), 0px 0px 5px rgba(57, 156, 250, 0.12)',
};

export const BACKDROP_FILTERS: Shadows = [
  'none',
  'blur(4px)',
  'blur(5px)',
  'blur(6px)',
  'blur(7px)',
  'blur(8px)',
  'blur(9px)',
  'blur(10px)',
  'blur(11px)',
  'blur(12px)',
  'blur(13px)',
  'blur(14px)',
  'blur(15px)',
  'blur(16px)',
  'blur(17px)',
  'blur(18px)',
  'blur(19px)',
  'blur(20px)',
  'blur(21px)',
  'blur(22px)',
  'blur(23px)',
  'blur(24px)',
  'blur(25px)',
  'blur(26px)',
  'blur(27px)',
];

const HIGHLIGHT = {
  position: 'relative',
  '&::before': {
    borderRadius: 'inherit',
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: '#4DC8FF',
    opacity: 0,
    transition: '0.3s ease',
  },
  '&:hover:before': {
    opacity: 0.05,
  },
} as const;

export const GLOWS = {
  card: {
    '&:hover': {
      cursor: 'pointer',
      transition: 'all 0.01s ease-in-out',
      boxShadow:
        '0px 0px 18.444px rgba(57, 156, 250, 0.7), 0px 0px 13.968px rgba(57, 156, 250, 0.7), 0px 0px 8.148px rgba(57, 156, 250, 0.7), 0px 0px 4.074px rgba(57, 156, 250, 0.7), 0px 0px 1.164px rgba(57, 156, 250, 0.7), 0px 0px 0.582px rgba(57, 156, 250, 0.7)',
    },
  },
  panel: {
    boxShadow: SHADOWS[4],
    '&:hover': {
      boxShadow: SHADOWS[7],
    },
  },
  dialog: {
    boxShadow: SHADOWS[4], // SHADOWS[8]
    '&:hover': {
      boxShadow: SHADOWS[7],
    },
  },
  highlight: HIGHLIGHT,
  selectable: {
    ...HIGHLIGHT,
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
