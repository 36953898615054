import Grid from '@mui/material/Grid';
import { useMobile } from '../../hooks/useMobile';
import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { ImageOptimized } from '../image/ImageOptimized';

export type AuthenticationOptionsBaseProps = {
  children: ReactNode;
  footer?: ReactNode;
};

export const AuthenticationOptionsBase = memo(
  function AuthenticaitonOptionsBaseUnmemoized({
    children,
    footer,
  }: AuthenticationOptionsBaseProps) {
    const isMobile = useMobile();
    const theme = useTheme();
    const screenSizeDrop = useMediaQuery(theme.breakpoints.down(1200));
    const Left = (
      <Stack
        justifyContent={isMobile || screenSizeDrop ? 'center' : 'space-between'}
        alignItems={'center'}
        spacing={6}
        sx={{ p: 2, height: !isMobile || screenSizeDrop ? '100%' : undefined }}
      >
        {!isMobile && (
          <ImageOptimized
            src={'/assets/images/mascots/sign-in-mascot.png'}
            alt={'Sign In Logo'}
            layout="intrinsic"
            height={'259px'}
            width={'328px'}
          />
        )}
        {footer}
      </Stack>
    );

    return (
      <Grid container spacing={isMobile ? 2 : 8} direction="row-reverse">
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
        <Grid item xs={12} md={6}>
          {Left}
        </Grid>
      </Grid>
    );
  },
);
