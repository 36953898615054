import { Bracket, Round } from '../';

export type SingleEliminationBracket<T> = Omit<
  Bracket<T>,
  'bracket' | 'bracketLoser' | 'grandFinal'
> & { bracket: Required<Round<T>>[] };

export type DoubleEliminationBracket<T> = Omit<
  Bracket<T>,
  'bracket' | 'bracketLoser' | 'grandFinal'
> & {
  bracket: Required<Round<T>>[];
  bracketLoser: Required<Round<T>>[];
  grandFinal: Required<Round<T>>[];
};

export const isSingleElimination = <T>(
  bracket: Bracket<T>,
): bracket is SingleEliminationBracket<T> => {
  return (
    'bracket' in bracket &&
    !('bracketLoser' in bracket) &&
    !('grandFinal' in bracket)
  );
};

export const isDoubleElimination = <T>(
  bracket: Bracket<T>,
): bracket is DoubleEliminationBracket<T> => {
  return (
    'bracket' in bracket && 'bracketLoser' in bracket && 'grandFinal' in bracket
  );
};
