import { matchesTemplate } from '../../algoliaRealtime/matchesTemplate';
import {
  PLACEHOLDER_REGEX,
  PLACEHOLDER_TEXT,
} from '../../algoliaRealtime/templates';
import {
  PREEMPTIVE_PATHSORT_TEMPLATES,
  PreemptiveFilterKey,
  PREEMPTIVE_FILTER_TEMPLATES,
} from './templates';

export const buildPreemptionPathSort = (filter: string) => {
  return new PreemptionPathSortBuilder(filter).build();
};

class PreemptionPathSortBuilder {
  public constructor(private readonly filter: string) {}
  public build() {
    if (!this.path) {
      return undefined;
    }

    return { path: this.path, sort: this.sort };
  }

  private get path() {
    if (!this.pathTemplate) {
      return undefined;
    }
    if (!this.pathTemplate.includes(PLACEHOLDER_TEXT)) {
      return this.pathTemplate;
    }
    if (!this.placeholderValue) {
      return undefined;
    }
    return this.pathTemplate.replace(PLACEHOLDER_TEXT, this.placeholderValue);
  }

  private get pathTemplate() {
    return this.pathSortTemplate?.path;
  }

  private get pathSortTemplate() {
    if (!this.entry) {
      return undefined;
    }
    const [filterKey] = this.entry;
    return PREEMPTIVE_PATHSORT_TEMPLATES[filterKey as PreemptiveFilterKey];
  }

  private get entry() {
    return Object.entries(PREEMPTIVE_FILTER_TEMPLATES).find(([_, template]) => {
      return matchesTemplate(template, this.filter);
    });
  }

  private get placeholderValue() {
    if (!this.filterPlaceholderRegex) {
      return undefined;
    }
    const match = this.filter.match(this.filterPlaceholderRegex);
    return match ? match[1] : undefined;
  }

  private get filterPlaceholderRegex() {
    if (!this.entry) {
      return undefined;
    }
    const [, filterTemplate] = this.entry;
    // eslint-disable-next-line security/detect-non-literal-regexp
    return new RegExp(
      `^${filterTemplate.replace(PLACEHOLDER_REGEX, '(.+?)')}$`,
    );
  }

  private get sort() {
    return this.pathSortTemplate?.sort;
  }
}
