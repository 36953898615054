import { convertToHash } from '../../../functions/src/util/convertToHash';

export type ToBanIdParams = {
  userId: string;
  channelId?: string;
};

export const toBanId = ({ userId, channelId }: ToBanIdParams) => {
  return convertToHash(`${userId}-${channelId}`);
};
