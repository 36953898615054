import { useCallback } from 'react';
import { OpenAlertDialogSettings, useAlertDialog } from '../useAlertDialog';

const SIGNATURE_ERROR_DIALOG = 'SIGNATURE_ERROR_DIALOG' as const;

export const useSignatureErrorDialog = () => {
  const { open: openDialog, close } = useAlertDialog(SIGNATURE_ERROR_DIALOG);

  const open = useCallback(
    (settings: Omit<OpenAlertDialogSettings, 'title' | 'description'> = {}) => {
      openDialog({
        title: 'Signature Request Rejected / Error.',
        description:
          'Either an error occured or you have rejected the signature request sent to your wallet. You must accept the signature request in order to proceed.',
        ...settings,
      });
    },
    [openDialog],
  );

  return {
    open,
    close,
  };
};
