import { useMemo, FC } from 'react';
import { normalizeTab } from '../../util/normalizeTab';
import { TabsVariantProps } from './TabsRouted';
import Stack from '@mui/material/Stack';
import { RenderChipTab } from './RenderChipTab';
import { memo } from '../../util/memo';

const TabsChipUnmemoized: FC<TabsVariantProps> = ({
  variant,
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const chipVariant = variant === 'chip-large' ? 'large' : 'small';

  const tabsNormalized = useMemo(() => {
    return tabs.map(normalizeTab);
  }, [tabs]);
  const tabsRendered = useMemo(() => {
    return (
      <>
        {tabsNormalized.map(
          ({ id, value, onClick, sx: sxTab, customization, Wrapper }) => {
            const chip = (
              <RenderChipTab
                id={id}
                label={value}
                isActive={value === activeTab}
                onClick={onClick}
                go={async (event) => {
                  return await routeTab(event, value);
                }}
                sx={sxTab}
                variant={chipVariant}
                {...customization}
              />
            );

            const {
              props: {
                sx: { flex, display },
              },
            } = chip;

            return (
              <Stack key={id} sx={{ flex, display }}>
                {Wrapper ? Wrapper(chip) : chip}
              </Stack>
            );
          },
        )}
      </>
    );
  }, [activeTab, chipVariant, routeTab, tabsNormalized]);

  return (
    <Stack
      direction="row"
      spacing={2}
      width="100%"
      justifyContent="center"
      flexWrap="wrap"
      rowGap={2}
      sx={sx}
    >
      {tabsRendered}
    </Stack>
  );
};

export const TabsChip = memo(TabsChipUnmemoized);
