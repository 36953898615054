import { useCallback } from 'react';
import { useDialog } from '../useDialog';
import {
  PrizePoolSelectionProvider,
  PrizePoolSelectionProviderProps,
} from '../../contexts/organizer/PrizePoolSelectionContext';
import { SelectPrizesForPayout } from '../../components/data-entry-ui/payouts/SelectPrizesForPayout';
import { Token } from '../../../functions/src/types/firestore/User/Payout';
import { EditableStepsContextType } from '../../contexts/organizer/EditableStepsContext';

export type UsePayoutSelectionParams = Pick<
  PrizePoolSelectionProviderProps,
  'prizePool' | 'eventId'
> & {
  payoutsCurrent: { tokens: Token[] }[];
  onChange: EditableStepsContextType<any>['updateField'];
  fieldName: string;
};

// TODO: select amount

export const usePayoutSelection = ({
  payoutsCurrent,
  prizePool,
  onChange,
  fieldName,
  eventId,
}: UsePayoutSelectionParams) => {
  const { close, open: openDialog } = useDialog('PAYOUT_SELECT_PRIZES');

  const confirmSelection = useCallback(
    async (selectedToken: Token, payoutIndex: number) => {
      await onChange([
        {
          action: 'overwrite',
          value: {
            tokens: [
              ...(payoutsCurrent?.[payoutIndex]?.tokens || []),
              selectedToken,
            ],
          },
          field: `${fieldName}.${payoutIndex}`,
        },
      ]);
    },
    [onChange, payoutsCurrent, fieldName],
  );

  const selectPayout = useCallback(
    (payoutIndex: number) => {
      openDialog({
        title: 'Select Prizes',
        description: 'Choose prizes to add to the payout',
        children: (
          <PrizePoolSelectionProvider
            prizePool={prizePool}
            payoutsCurrent={payoutsCurrent}
            eventId={eventId}
          >
            <SelectPrizesForPayout
              confirmSelection={async (selectedToken) => {
                await confirmSelection(selectedToken, payoutIndex);
              }}
              close={close}
            />
          </PrizePoolSelectionProvider>
        ),
      });
    },
    [close, confirmSelection, eventId, openDialog, payoutsCurrent, prizePool],
  );

  return selectPayout;
};
