import { getMessaging, getToken } from 'firebase/messaging';
import {
  initializeApp,
  getApps,
  getApp as initApp,
  FirebaseApp,
  deleteApp,
} from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { isOnApp } from '../../util/isOnApp';

export const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const isSupported = () => {
  return (
    window &&
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  );
};

let initialized = false;

async function initMessaging(app: FirebaseApp) {
  try {
    if (!isSupported() || Notification.permission !== 'granted') {
      //TODO: add push notifications for android app (capacitor)
      return;
    }
    const messaging = getMessaging(app);
    const token = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
    });
    if (token) {
      localStorage.setItem('messagingToken', token);
      //should also set this in server
      return token;
    }
    // Show permission request UI
    console.warn(
      'No registration token available. Request permission to generate one.',
    );
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        'messaging/unsupported-browser',
      )
    ) {
      console.warn(error);
      return;
    }
    console.error('An error occurred while retrieving token. ', error);
  }
  return undefined;
}
const onApp = isOnApp();

export const getApp = () => {
  const app = !getApps().length ? initializeApp(FIREBASE_CONFIG) : initApp();

  if (!initialized && typeof window !== 'undefined') {
    initialized = true;

    window.addEventListener('beforeunload', () => {
      deleteApp(app);
    });

    initMessaging(app);
    const analytics = getAnalytics(app);

    setUserProperties(analytics, {
      is_on_native_app: onApp.toString(),
    });
  }
  return app;
};
