import { TrackReference } from '@livekit/components-core';
import { RemoteParticipant, LocalParticipant } from 'livekit-client';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { CallerCardBase } from './CallerCardBase';
import { SpeakerOverlay } from './SpeakerOverlay';
import { AvatarGroupNext } from '../AvatarGroupNext';
import { KickCallerButton } from './KickCallerButton';
import { MuteCallerButton } from './MuteCallerButton';
import { CallerHit } from './CallersVerticalCarousel';
import { useRoledUser } from '../../hooks/messaging/useRoledUser';

export type CallerCardActiveProps = CallerHit & {
  participant: RemoteParticipant | LocalParticipant;
  audioTrack: TrackReference;
};

const CallerCardActiveUnmemoized = ({
  participant,
  audioTrack,
  ...hit
}: CallerCardActiveProps) => {
  const { hasAnyRole } = useRoledUser();
  const { imgUrl, id: userId } = hit;

  const muteCallerButton = useMemo(() => {
    return (
      participant instanceof RemoteParticipant && (
        <MuteCallerButton participant={participant} />
      )
    );
  }, [participant]);

  const kickCallerButton = useMemo(() => {
    return (
      hasAnyRole &&
      participant instanceof RemoteParticipant && (
        <KickCallerButton userId={userId} />
      )
    );
  }, [hasAnyRole, userId, participant]);

  return (
    <CallerCardBase
      Figure={
        <SpeakerOverlay audioTrack={audioTrack} participant={participant}>
          <AvatarGroupNext
            imgUrls={[imgUrl]}
            avatarProps={{ height: 36, width: 36 }}
            userId={userId}
            showStatus={false}
          />
        </SpeakerOverlay>
      }
      {...hit}
    >
      {muteCallerButton}
      {kickCallerButton}
    </CallerCardBase>
  );
};

export const CallerCardActive = memo(CallerCardActiveUnmemoized);
