export const EDITABLE_FIELDS_TOURNAMENT = [
  'title',
  'date',
  'endDate',
  'checkInTime',
  'imgUrl',
  'sponsors',
  'maxTeamSize',
  'minTeamSize',
  'teamSizeRange',
  'region',
  'bracketType',
  'tournamentDetails',
  'payouts',
  'prizePoolId',
  'registrationOptions',
  'skipCheckIn',
  'maxTeamCount',
] as const;

export const EDITABLE_FIELDS_REGEX = [
  'title',
  'imgUrl',
  'maxTeamSize',
  'minTeamSize',
  'region',
  'bracketType',
] as const;

export const NON_EDITABLE_FIELDS_REGEX = [
  'profileImgUrl',
  'gameId',
  'id',
  'gameTitle',
  'maxTeamCount',
  'bestOf',
] as const;

export type EditableField = typeof EDITABLE_FIELDS_TOURNAMENT[number];

export type RegexValidatedEditableField = typeof EDITABLE_FIELDS_REGEX[number];

export type CustomValidatedEditableField = Exclude<
  EditableField,
  RegexValidatedEditableField
>;

export type RegexValidatedNonEditableField =
  typeof NON_EDITABLE_FIELDS_REGEX[number];

export type RegexValidation = {
  pattern: RegExp;
  explanation: string;
};

export const EDITABLE_TOURNAMENT_REGEX_VALIDATION: Record<
  RegexValidatedEditableField,
  RegexValidation
> = {
  title: {
    explanation:
      'Please enter a tournament title containing up to 50 characters, which can include letters, numbers, underscores, and spaces. The name should not start with a date in the format YYYY-MM-DD or a time in the format Thh:mm',
    pattern: /^(?!\d{4}-\d{2}-\d{2})(?!T\d{2}:\d{2})([\w\s\S]{0,50})$/,
  },
  imgUrl: {
    pattern:
      /^https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/blumint-(development|production)\.appspot\.com\/o\/Ugc%/,
    explanation:
      'Please enter a valid image url hosted on Firebase Storage. The url should start with "https://firebasestorage.googleapis.com/v0/b/blumint-".',
  },
  region: {
    explanation:
      'Please enter a valid region containing only letters, spaces, or hyphens. If left empty, this will be set to US-West.',
    pattern: /^[A-Za-z\s-]*$/,
  },
  bracketType: {
    explanation:
      'Please enter a valid bracket type. You can input either single-elimination, double-elimination, heats, or no-bracket.',
    pattern: /^(single-elimination|double-elimination|heats|no-bracket)$/,
  },
  maxTeamSize: {
    pattern: /^(?:[1-9]|[1-9]\d+)$/,
    explanation:
      'Please input a valid Maximum Team Size. It must be a positive integer greater than or equal to 1.',
  },
  minTeamSize: {
    pattern: /^(?:[1-9]|[1-9]\d+)$/,
    explanation:
      'Please input a valid Minimum Team Size. It must be a positive integer greater than or equal to 1.',
  },
};

export const NON_EDITABLE_TOURNAMENT_REGEX_VALIDATION: Record<
  RegexValidatedNonEditableField,
  RegexValidation
> = {
  bestOf: {
    explanation: 'Please enter a valid bestOf value',
    pattern: /^\d*[13579]$/,
  },
  gameId: {
    explanation:
      'Please enter a valid game ID consisting of lowercase letters and hyphens, e.g. "smash-bros" or "battle-royale".',
    // eslint-disable-next-line security/detect-unsafe-regex
    pattern: /^[a-z]+(?:-[a-z]+)*$/,
  },
  gameTitle: {
    explanation:
      'Please enter a valid game title containing 1 to 50 characters. Allowed characters include letters, numbers, spaces, colons (:), periods (.), hyphens (-), and parentheses (()).',
    pattern: /^[\w\s:.()-]{1,50}$/,
  },
  id: {
    explanation: 'Please enter a valid firestore id',
    pattern: /^[a-zA-Z0-9-]+$/,
  },
  maxTeamCount: {
    pattern: /^(1000|[2-9]|[1-9][0-9]|[1-9][0-9]{2}|10(0[1-9]|1[0-9]|2[0-4]))$/,
    explanation:
      'Please input a valid Team Size, it has to be between 2 and 1024.',
  },
  profileImgUrl: {
    explanation:
      'Please enter a valid image url hosted on Firebase Storage. The url should start with "https://firebasestorage.googleapis.com/v0/b/blumint-".',
    pattern:
      /^https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/blumint-(development|production)\.appspot\.com\/o\/Game%/,
  },
};

export const MARKDOWN_LENGTH_CONSTRAINTS = {
  tournamentDetails: {
    title: {
      max: 25,
      min: 4,
    },
    body: {
      min: 25,
      max: 5000,
    },
  },
} as const;
