import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';
import { MouseEvent } from 'react';
import { memo } from '../../util/memo';

export type LoadingIconButtonProps = Omit<IconButtonProps, 'onClick'> & {
  loading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const LoadingIconButtonUnmemoized = (props: LoadingIconButtonProps) => {
  const { color = 'primary' } = props;
  const [loading, asyncHandler] = useLoadingHandler(props.onClick);
  const loadingInternal = props.loading || loading;

  return (
    <IconButton {...props} onClick={asyncHandler}>
      {!loadingInternal ? (
        props.children
      ) : (
        <CircularProgress
          size="1.5rem"
          color={color === 'default' ? 'primary' : color}
        />
      )}
    </IconButton>
  );
};

export const LoadingIconButton = memo(LoadingIconButtonUnmemoized);
