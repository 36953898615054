import { useState, useEffect, FC, useCallback } from 'react';
import { memo } from '../../../util/memo';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FieldInputProps } from './BasicInput';
import { Stack, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { Markdown } from '../../Markdown';
import { Link } from '../../Link';

export const MARKDOWN_URL = 'https://www.markdownguide.org/cheat-sheet/';

export type MarkdownInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  minLength: number;
  maxLength: number;
  onError?: (error: boolean) => void;
} & Omit<FieldInputProps<string>, 'value' | 'onChange'>;

// TODO: validation that matches backend
const MarkdownInputUnmemoized: FC<MarkdownInputProps> = ({
  value,
  onChange,
  minLength,
  maxLength,
  onError,
  ...rest
}) => {
  const [preview, setPreview] = useState(false);
  const [localValue, setLocalValue] = useState<any>(value);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);

  const validate = useCallback(
    (value?: string) => {
      const isValid =
        value && minLength <= value.length && value.length <= maxLength;
      setError(!isValid);
      setHelperText(
        isValid
          ? undefined
          : `Please fill in this section. It should be between ${minLength} and ${maxLength} characters long.`,
      );
    },
    [maxLength, minLength],
  );
  const handleChange: TextFieldProps['onChange'] = useCallback(
    (event) => {
      const fieldValue = event.target.value;
      validate(fieldValue);
      setLocalValue(fieldValue);

      if (onChange) {
        onChange(fieldValue);
      }
    },
    [onChange, validate],
  );

  useEffect(() => {
    validate(value);
    setLocalValue(value);
    if (onError) {
      onError(error);
    }
  }, [error, validate, value]);

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent={'space-between'}
      >
        <Tooltip
          title={
            <Typography sx={{ fontSize: 14 }}>
              This uses markdown syntax to create rich text. Use the icon to
              switch between editing & preview mode.
            </Typography>
          }
        >
          <div>
            <GradientIconButton
              sx={{ height: 24, width: 24 }}
              onClick={async () => {
                return setPreview(!preview);
              }}
              gradientColor="primary.verticalInverse"
              IconComponent={preview ? EditIcon : VisibilityIcon}
            />
          </div>
        </Tooltip>
        <Link href={MARKDOWN_URL}>
          <Tooltip title="This guide will help you to understand markdown language and format your inputs below.">
            <Typography>Markdown Guide</Typography>
          </Tooltip>
        </Link>
      </Stack>
      {preview ? (
        <Markdown text={localValue || ''} />
      ) : (
        <TextField
          {...rest}
          color="primary"
          variant="filled"
          label={''}
          type="text"
          multiline
          rows={4}
          value={localValue}
          defaultValue={value}
          onChange={handleChange}
          error={error}
          helperText={helperText}
          required
          fullWidth
        />
      )}
    </Stack>
  );
};
export const MarkdownInput = memo(MarkdownInputUnmemoized);
