import Stack from '@mui/material/Stack';
import { memo } from '../util/memo';
import { LoadingIconButton } from './buttons/LoadingIconButton';
import { GradientBorder } from './gradients/GradientBorder';
import { GradientIcon } from './gradients/GradientIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material/styles';

export type ResponseButtonsProps = {
  onAccept: () => Promise<void>;
  onReject: () => Promise<void>;
  disableAccept?: boolean;
  disableReject?: boolean;
  buttonSx?: SxProps;
  iconSx?: SxProps;
};

const ResponseButtonsUnmemoized = ({
  onAccept,
  onReject,
  buttonSx = {},
  iconSx = {},
  disableAccept = false,
  disableReject = false,
}: ResponseButtonsProps) => {
  return (
    <Stack direction="row" spacing={2} sx={{ pr: 2 }} height="36px">
      <LoadingIconButton
        onClick={onAccept}
        disabled={disableAccept}
        color="secondary"
        sx={{ p: 0 }}
      >
        <GradientBorder
          gradientColor={
            disableAccept ? 'disabled.vertical' : 'secondary.vertical'
          }
          borderRadius="36px"
          disableGlow
          sx={{
            height: '100%',
            width: '36px',
            '.MuiSvgIcon-root': {
              mt: '4px',
            },
            ...buttonSx,
          }}
        >
          <GradientIcon
            gradientColor={
              disableAccept ? 'disabled.vertical' : 'secondary.vertical'
            }
            IconComponent={CheckIcon}
            sx={{ height: '24px', width: '24px', ...iconSx }}
          />
        </GradientBorder>
      </LoadingIconButton>
      <LoadingIconButton
        onClick={onReject}
        disabled={disableReject}
        color="error"
        sx={{ p: 0 }}
      >
        <GradientBorder
          gradientColor={disableReject ? 'disabled' : 'error.vertical'}
          borderRadius="36px"
          disableGlow
          sx={{
            height: '100%',
            width: '36px',
            '.MuiSvgIcon-root': {
              mt: '4px',
            },
            ...buttonSx,
          }}
        >
          <GradientIcon
            gradientColor={disableReject ? 'disabled' : 'error.vertical'}
            IconComponent={CloseIcon}
            sx={{ height: '24px', width: '24px', ...iconSx }}
          />
        </GradientBorder>
      </LoadingIconButton>
    </Stack>
  );
};

export const ResponseButtons = memo(ResponseButtonsUnmemoized);
