import { useMemo } from 'react';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { useActiveMatchRouter } from './useActiveMatchRouter';
import { useAuth } from '../../contexts/AuthContext';
import { findCaseInsensitiveIn } from '../../../functions/src/util/findCaseInsensitiveIn';

export const useActiveSessionStatus = () => {
  const { uid } = useAuth();
  const { sessionIndex } = useActiveMatchRouter();
  const {
    team1Scores = [],
    team2Scores = [],
    team1,
    team2,
    screenshots,
  } = useMatch();

  const currentSession = useMemo(() => {
    return sessionIndex + 1;
  }, [sessionIndex]);

  const teamUserIds = useMemo(() => {
    return [team1?.acceptedUserIds, team2?.acceptedUserIds];
  }, [team1, team2]);

  const teamSessionScores = useMemo(() => {
    return [team1Scores, team2Scores];
  }, [team1Scores, team2Scores]);

  const teamIndex = useMemo(() => {
    return teamUserIds.findIndex((userIds) => {
      return !!uid && findCaseInsensitiveIn(uid, userIds);
    });
  }, [teamUserIds, uid]);

  const isActiveSessionCompleted = useMemo(() => {
    return [
      team1Scores[Number(sessionIndex)],
      team2Scores[Number(sessionIndex)],
    ].every((score) => {
      return score === 0 || score === 1;
    });
  }, [sessionIndex, team1Scores, team2Scores]);

  const isActiveSessionScoredByTeam = useMemo(() => {
    const lastIndex = teamSessionScores[Number(teamIndex)]?.length - 1;
    return lastIndex !== -1 && lastIndex + 1 === currentSession;
  }, [currentSession, teamIndex, teamSessionScores]);

  const hasTeamUploadedScreenshot = useMemo(() => {
    return (
      !!screenshots &&
      screenshots?.some(
        ({ sessionNumber: sessionIndexScreenshot, uploaderUid }) => {
          const acceptedUserIds = teamUserIds[Number(teamIndex)];
          const hasTeamUploaded = findCaseInsensitiveIn(
            uploaderUid,
            acceptedUserIds,
          );
          return !!hasTeamUploaded && sessionIndexScreenshot === sessionIndex;
        },
      )
    );
  }, [screenshots, teamUserIds, teamIndex, sessionIndex]);

  return {
    isActiveSessionCompleted,
    isActiveSessionScoredByTeam,
    hasTeamUploadedScreenshot,
  };
};
