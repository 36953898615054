import type { UserMetadata } from 'firebase/auth';
import { getTimeBetweenDates } from '../dates/Dates';

const RESPONSE_WINDOW = 30000 as const;

export const isNewUser = (metadata: UserMetadata) => {
  const accountCreationTime = metadata.creationTime;
  const lastSignInTime = metadata.lastSignInTime;

  if (!!accountCreationTime && !!lastSignInTime) {
    const timeDifference = getTimeBetweenDates(
      new Date(accountCreationTime),
      new Date(lastSignInTime),
    );
    const differenceInMillis =
      Date.now() - Number(new Date(accountCreationTime));

    return (
      (timeDifference.datesAreSame || !timeDifference.date1LaterThanDate2) &&
      differenceInMillis < RESPONSE_WINDOW
    );
  }
  return true;
};
