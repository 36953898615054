import { memo } from '../../../util/memo';
import {
  ExpandableSearch,
  ExpandableSearchProps,
} from '../../search/ExpandableSearch';
import {
  UseDebouncedSearchProps,
  useDebouncedSearch,
} from '../../../hooks/algolia/useDebouncedSearch';

export type SearchAlgoliaProps = Omit<
  ExpandableSearchProps,
  'query' | 'onQueryChange'
> &
  UseDebouncedSearchProps<string>;

const SearchAlgoliaUnmemoized = ({
  initialValue,
  delay,
  ...props
}: SearchAlgoliaProps) => {
  const { inputValue, applyQueryChange } = useDebouncedSearch({
    initialValue,
    delay,
  });

  return (
    <ExpandableSearch
      {...props}
      query={inputValue}
      onQueryChange={applyQueryChange}
    />
  );
};

export const SearchAlgolia = memo(SearchAlgoliaUnmemoized);
