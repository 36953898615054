import { useEffect } from 'react';
import { useAdBlockDialog } from '../useAdBlockDialog';
import { useAdBlock } from '../../contexts/AdBlockContext';

export const useShowAdBlock = () => {
  const { showAdBlockDialog } = useAdBlock();
  const { openAdBlockDialog } = useAdBlockDialog();

  useEffect(() => {
    if (showAdBlockDialog) {
      openAdBlockDialog();
    }
  }, [showAdBlockDialog, openAdBlockDialog]);
};
