import { useMemo } from 'react';
import {
  RouterState,
  RouterStateGetter,
  useRouterState,
} from './useRouterState';

export type UseRouterStateFallbackProps = {
  defaultKey: string;
  state?: RouterStateGetter;
};

export function useRouterStateFallback({
  defaultKey,
  state,
}: UseRouterStateFallbackProps): RouterState {
  const [defaultStateValue, setDefaultStateValue] = useRouterState({
    key: defaultKey,
  });
  const stateValue = useMemo(() => {
    return state?.[0] || defaultStateValue;
  }, [defaultStateValue, state]);
  const setStateValue = useMemo(() => {
    return state?.[1] || setDefaultStateValue;
  }, [state, setDefaultStateValue]);

  return [stateValue, setStateValue];
}
