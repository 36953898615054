import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../gradients/GradientTypography';
import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';
import { GradientIconButton } from '../gradients/GradientIconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';

export type ReplyIndicatorProps = {
  username: string;
  onClick: () => void;
};

const ReplyIndicatorUnmemoized = ({ username, onClick }) => {
  const theme = useTheme();
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={8}
      sx={{
        background: theme.palette.background.elevationSolid[18],
        position: 'absolute',
        bottom: 68,
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography
          variant="caption"
          color="text.primary"
          sx={{ fontSize: { xs: '24px', md: '12px' } }}
        >
          Replying to
        </Typography>
        <GradientTypography
          variant="caption"
          gradientColor="primary.vertical"
          sx={{ fontSize: { xs: '24px', md: '12px' } }}
        >
          {username}
        </GradientTypography>
      </Stack>
      <GradientIconButton
        gradientColor="primary.horizontal"
        IconComponent={HighlightOffIcon}
        onClick={onClick}
        sx={{
          height: { xs: '36px', md: '18px' },
          width: { xs: '36px', md: '18px' },
        }}
      />
    </Stack>
  );
};

export const ReplyIndicator = memo(ReplyIndicatorUnmemoized);
