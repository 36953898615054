import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MESSAGE_MAX_WIDTH } from './Message';
import { joinWithConjunction } from '../../../../functions/src/util/arrays/joinWithConjunction';
import { ReactNode } from 'react';

export type ReactedByProps = {
  emoji: ReactNode;
  reactedBy: string[];
  type: string;
};

const ReactedByUnmemoized = ({ emoji, reactedBy, type }: ReactedByProps) => {
  return (
    <Stack alignItems="center" maxWidth={MESSAGE_MAX_WIDTH}>
      <Typography variant="h4">{emoji}</Typography>
      <Stack alignItems="flex-start" spacing={1}>
        <Typography variant="subtitle2">
          {joinWithConjunction(reactedBy)}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ wordBreak: 'break-all' }}
        >
          reacted with :{type}:
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ReactedBy = memo(ReactedByUnmemoized);
