import { useCallback } from 'react';
import {
  Bracket,
  MatchAggregated,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { isDoubleElimination } from '../../../functions/src/types/firestore/Game/Tournament/Bracket/util/isBracket';

export const useMatchDisabled = (bracket: Bracket<Date>) => {
  const isMatchDisabled = useCallback(
    ({
      team1,
      previous1,
      team2,
      previous2,
      id: matchId,
    }: MatchAggregated<Date>) => {
      if (!isDoubleElimination(bracket) || team1 || team2) {
        return false;
      }
      const { bracket: winner, bracketLoser: loser } = bracket;

      const isFirstRoundLoser = loser[0].matches.some(({ id: loserId }) => {
        return loserId === matchId;
      });

      return !!winner.find(({ matches }) => {
        const matchesPrevious = matches.filter(({ id }) => {
          return id === previous1 || id === previous2;
        });
        return (
          isFirstRoundLoser &&
          matchesPrevious &&
          matchesPrevious.length > 0 &&
          matchesPrevious.every(
            ({ team1: team1Previous, team2: team2Previous }) => {
              return (
                (team1Previous && !team2Previous) ||
                (!team1Previous && team2Previous)
              );
            },
          )
        );
      });
    },
    [bracket],
  );

  return { isMatchDisabled };
};
