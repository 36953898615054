import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { RegistrationContainer } from './containers/RegistrationContainer';
import { RegisteredSoloPanel } from './RegisteredSoloPanel';
import { JoinedTeamPanel } from './JoinedTeamPanel';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { PendingInvitesPanel } from './PendingInvitesPanel';
import { RegisteredTeamPanel } from './RegisteredTeamPanel';
import { CreateTeamPanel } from './CreateTeamPanel';
import { RegisterSoloPanel } from './RegisterSoloPanel';
import { isPreReady } from '../../../../functions/src/types/firestore/Game/Tournament/util';
import { useInviteFromUrl } from '../../../hooks/useInviteFromUrl';
import { isSoloTournament } from '../../../../functions/src/util/tournaments/isSoloTournament';
import { REGISTRATION_AD } from '../../../../functions/src/util/ads/adIds';
import { AdSideRails } from '../../ads/AdSideRails';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';

export const RegistrationPaneUnmemoized = () => {
  const { maxTeamSize, userType, phase, continuousRegistration } =
    useTournamentRegistration();
  useInviteFromUrl();

  const RegistrationPanel = useMemo(() => {
    return userType === 'unregistered' ? (
      <>
        {(isPreReady(phase) || continuousRegistration) && maxTeamSize > 1 && (
          <CreateTeamPanel />
        )}
        {(isPreReady(phase) ||
          (continuousRegistration && maxTeamSize === 1)) && (
          <RegisterSoloPanel />
        )}
      </>
    ) : userType === 'solo' ? (
      <RegisteredSoloPanel />
    ) : userType === 'captain' ? (
      <RegisteredTeamPanel />
    ) : userType === 'accepted' ? (
      <JoinedTeamPanel />
    ) : (
      <></>
    );
  }, [maxTeamSize, phase, userType, continuousRegistration]);

  const SideComponent = useMemo(() => {
    return userType === 'unregistered' &&
      (isPreReady(phase) || continuousRegistration) &&
      !isSoloTournament(maxTeamSize) ? (
      <PendingInvitesPanel />
    ) : undefined;
  }, [maxTeamSize, phase, userType, continuousRegistration]);

  return (
    <AdSideRails
      id={REGISTRATION_AD}
      childrenMaxWidth={MAX_TOURNAMENT_PANE_WIDTH}
      pattern="tile"
      sx={{ minHeight: '600px' }}
    >
      <RegistrationContainer SideComponent={SideComponent}>
        {RegistrationPanel}
      </RegistrationContainer>
    </AdSideRails>
  );
};

export const RegistrationPane = memo(RegistrationPaneUnmemoized);
