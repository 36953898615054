const passwordWhitelistRegex = /^[A-Za-z0-9!\(\)\.\?{}_~;:@#\$%\^&\*\+=-]*$/;
const passwordBlacklistRegex = /[^A-Za-z0-9!\(\)\.\?{}_~;:@#\$%\^&\*\+=-]/g;
const defaultMinLength = 8;
const defaultMaxLength = 32;

export function cleanPassword(password: string): string {
  return password.replaceAll(passwordBlacklistRegex, '');
}

export type PasswordFailureReason =
  | 'needsLowercase'
  | 'needsUppercase'
  | 'needsNumber'
  | 'needsSymbol'
  | 'tooShort'
  | 'tooLong'
  | 'blacklistedChar';

export function couldBePassword(password: string): boolean {
  return passwordWhitelistRegex.test(password) && password.length > 0;
}

export function isValidPassword(
  password: string,
  minLength: number = defaultMinLength,
  maxLength: number = defaultMaxLength,
): boolean {
  return getPasswordFailures(password, minLength, maxLength).length === 0;
}

export function getPasswordFailures(
  password: string,
  minLength: number = defaultMinLength,
  maxLength: number = defaultMaxLength,
): PasswordFailureReason[] {
  const passwordFailures: PasswordFailureReason[] = [];

  if (!passwordWhitelistRegex.test(password))
    passwordFailures.push('blacklistedChar');

  if (password.length < minLength) passwordFailures.push('tooShort');
  else if (password.length > maxLength) passwordFailures.push('tooLong');

  const passwordStrength = gaugePasswordStrength(password);
  return passwordFailures.concat(passwordStrength);
}

function gaugePasswordStrength(password: string): PasswordFailureReason[] {
  const passwordCriteria = {
    needsLowercase: /[a-z]/,
    needsUppercase: /[A-Z]/,
    needsNumber: /[0-9]/,
    needsSymbol: /[-!().?{}_~;:@#$%^&*+=]/, // ensure hyphen is correctly placed
  };

  const passwordStrength: PasswordFailureReason[] = [];

  Object.entries(passwordCriteria).forEach(([failureReason, regex]) => {
    if (!regex.test(password)) {
      passwordStrength.push(failureReason as PasswordFailureReason);
    }
  });

  return passwordStrength;
}
