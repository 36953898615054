import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { memo } from '../../util/memo';
import { useAccountLowercase } from '../../hooks/web3/useAccountLowercase';
import { useAuthFlowDialog } from '../../hooks/auth/useAuthFlowDialog';
import { useWagmiFunc } from '../../hooks/web3/useWagmiFunc';
import { useAuth } from '../AuthContext';
import { useWagmiWatch } from '../../hooks/web3/useWagmiWatch';
import { useSignaturePendingDialog } from '../../hooks/web3/useSignaturePendingDialog';
import { usePageLoadingContext } from '../PageLoadingContext';
import { isNewUser } from '../../util/auth/isNewUser';
import { useSignatureRejectedDialog } from '../../hooks/web3/useSignatureRejectedDialog';
import { useAddressTakenDialog } from '../../hooks/web3/useAddressTakenDialog';
import { useSignatureErrorDialog } from '../../hooks/web3/useSignatureErrorDialog';
import { customOAuthSignIn } from '../../util/auth/customOAuthSignIn';
import { extractErrorMessage } from '../../../functions/src/util/sentinel/extractErrorMessage';
import { messageToAuthenticate, moralisTokenOf } from '../../util/moralis/auth';
import { isLoading } from '../../util/isLoading';

const DELAY_MS = 2000;

const SignatureRequestContext = createContext<{
  onClose: (listener: () => void) => void;
}>({
  onClose: () => {
    /* */
  },
});

const SignatureRequestProviderUnmemoized: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [closeListeners, setCloseListeners] = useState<(() => void)[]>([]);
  const onCloseAll = useCallback(() => {
    closeListeners.forEach((listener) => {
      return listener();
    });
    setCloseListeners([]);
  }, [closeListeners, setCloseListeners]);

  // If there's a wallet connected but I'm not authenticated,
  // then prompt the signature request.

  const network = useWagmiWatch('watchNetwork', undefined);
  const chainId = useMemo(() => {
    return network?.chain?.id;
  }, [network?.chain?.id]);

  const account = useAccountLowercase();
  const address = useMemo(() => {
    return account?.address;
  }, [account?.address]);
  const connector = useMemo(() => {
    return account?.connector;
  }, [account?.connector]);

  const { userData, user } = useAuth();

  const { open: openPendingDialog, close: closePendingDialog } =
    useSignaturePendingDialog();
  const { open: openRejectedDialog } = useSignatureRejectedDialog();
  const { open: openAddressTakenDialog } = useAddressTakenDialog();
  const { open: openErrorDialog } = useSignatureErrorDialog();
  const { open: openAuthDialog } = useAuthFlowDialog();

  const signMessage = useWagmiFunc('signMessage');
  const { toggleLoadingOverlay } = usePageLoadingContext();

  const [isHandlingLock, setIsHandlingLock] = useState(false);

  const disconnect = useWagmiFunc('disconnect');

  useEffect(() => {
    const isRejection = (error: unknown) => {
      return (error as { code: number })?.code === 401;
    };
    const isAlreadyTaken = (error: unknown) => {
      return extractErrorMessage(error).includes('in another account');
    };

    const handler = async () => {
      if (
        !address ||
        isLoading(user) ||
        isLoading(userData) ||
        (user && userData && userData.addresses?.includes(address)) ||
        !chainId ||
        !connector ||
        isHandlingLock
      ) {
        return;
      }
      setIsHandlingLock(true);

      try {
        openPendingDialog();
        const { message } = await messageToAuthenticate(address, chainId);
        const signature = await signMessage({ message });
        closePendingDialog();
        toggleLoadingOverlay(true);

        const token = await moralisTokenOf({
          message,
          signature,
        });
        if (user) {
          return;
        }
        const response = await customOAuthSignIn(token);

        if (isNewUser(response.user.metadata)) {
          openAuthDialog({
            entryDialog: 'Registration End',
          });
        }

        onCloseAll();
      } catch (error) {
        console.warn(error);
        await disconnect();
        closePendingDialog();
        if (isRejection(error)) {
          openRejectedDialog({ onClose: onCloseAll });
        } else if (isAlreadyTaken(error)) {
          openAddressTakenDialog({ onClose: onCloseAll });
        } else {
          openErrorDialog({ onClose: onCloseAll });
        }
      } finally {
        toggleLoadingOverlay(false);
        setTimeout(() => {
          setIsHandlingLock(false);
        }, DELAY_MS);
      }
    };
    handler();
  }, [
    userData,
    chainId,
    address,
    openPendingDialog,
    signMessage,
    closePendingDialog,
    toggleLoadingOverlay,
    onCloseAll,
    openAuthDialog,
    openRejectedDialog,
    openAddressTakenDialog,
    openErrorDialog,
    isHandlingLock,
    disconnect,
    connector,
    user,
  ]);

  const onClose = useCallback(
    (listener: () => void) => {
      setCloseListeners((prev) => {
        return [...prev, listener];
      });
    },
    [setCloseListeners],
  );

  return (
    <SignatureRequestContext.Provider value={{ onClose }}>
      {children}
    </SignatureRequestContext.Provider>
  );
};

export const SignatureRequestProvider = memo(
  SignatureRequestProviderUnmemoized,
);

export const useSignatureRequest = () => {
  const context = useContext(SignatureRequestContext);
  if (!context) {
    throw new Error(
      'useSignatureRequest must be used within a SignatureRequestContext',
    );
  }
  return context;
};
