import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { init, SearchIndex } from 'emoji-mart';
import data from '@emoji-mart/data';
import Tooltip from '@mui/material/Tooltip';
import { ReactedBy } from './ReactedBy';

export type ReactionProps = {
  type: string;
  count?: number;
  onClick: () => Promise<void> | void;
  isOwnReaction?: boolean;
  reactedBy: string[];
};

const ReactionUnmemoized = ({
  type,
  count,
  reactedBy,
  isOwnReaction,
  onClick,
}: ReactionProps) => {
  const theme = useTheme();
  const [emoji, setEmoji] = useState();

  useEffect(() => {
    init({ data });
  }, []);

  useEffect(() => {
    const handler = async () => {
      const results = await SearchIndex.search(type);
      const emojis = results.map((result) => {
        return result.skins[0].native;
      });
      setEmoji(emojis[0]);
    };
    handler();
  }, [type]);

  return (
    <Tooltip
      arrow
      title={<ReactedBy emoji={emoji} type={type} reactedBy={reactedBy} />}
    >
      <Stack
        direction="row"
        onClick={onClick}
        position="relative"
        spacing={1}
        sx={{
          background: isOwnReaction
            ? theme.palette.primary.mid
            : theme.palette.background.elevationSolid[24],
          px: 2,
          mr: 1,
          mb: 1,
          borderRadius: '24px',
          border: '1px solid transparent',
          ':hover': {
            cursor: 'pointer',
            border: `1px solid ${theme.palette.text.secondary}`,
          },
        }}
      >
        <Typography variant="subtitle2">{emoji}</Typography>
        <Typography variant="subtitle2">{count}</Typography>
      </Stack>
    </Tooltip>
  );
};

export const Reaction = memo(ReactionUnmemoized);
