import { useMemo } from 'react';
import { POSITIONAL_SUFFIXES } from '../util/suffixes';

export type UseDisplayedRankSettings = {
  rankInvalid: number;
  invalidDisplay: string;
};

export type UseDisplayedRankParams = {
  rank: number;
  settings?: UseDisplayedRankSettings;
};

const DISPLAYED_RANK_SETTINGS_DEFAULT = {
  rankInvalid: 0,
  invalidDisplay: 'TBD',
};

export const useDisplayedRank = ({
  rank,
  settings = DISPLAYED_RANK_SETTINGS_DEFAULT,
}: UseDisplayedRankParams) => {
  return useMemo(() => {
    const { rankInvalid, invalidDisplay } = settings;
    const ordinalFormat = new Intl.PluralRules('en', {
      type: 'ordinal',
    }).select(rank);
    const placedRank =
      rank +
      POSITIONAL_SUFFIXES[ordinalFormat as keyof typeof POSITIONAL_SUFFIXES];
    return rank === rankInvalid ? invalidDisplay : placedRank;
  }, [rank, settings]);
};
