import { memo } from '../../../util/memo';
import { ImageOptimized } from '../../image/ImageOptimized';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { ThumbnailCard } from './ThumbnailCard';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export type ThumbnailCardItemProps = {
  imgUrl: string;
  amount?: string;
};

export const ThumbnailCardItem = memo(function ThumbnailCardItemUnmemoized({
  imgUrl,
  amount = '1',
}: ThumbnailCardItemProps) {
  const theme = useTheme();

  const content = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={'124px'}
        width={'124px'}
        borderRadius={'10px'}
      >
        <ImageOptimized
          src={imgUrl}
          width={'124px'}
          height={'124px'}
          style={{ borderRadius: '10px' }}
          objectFit="contain"
        />
      </Stack>
    );
  }, [imgUrl]);

  const wrappedContent = useMemo(() => {
    if (BigInt(amount) <= BigInt(1)) return content;

    return (
      <Badge
        max={999}
        overlap={'circular'}
        color="primary"
        badgeContent={amount}
        sx={{
          ml: 4,
          mt: 2,
          zIndex: 1,
          width: '40px',
          '.MuiBadge-badge': {
            width: '40px',
            background: `${theme.palette.primary.horizontal} !important`,
          },
        }}
      >
        {content}
      </Badge>
    );
  }, [amount, content, theme.palette.primary.horizontal]);

  return (
    <ThumbnailCard
      gradientColor={'primary.vertical'}
      sx={{
        borderRadius: '10px',
        width: '128px',
        height: '128px',
      }}
    >
      {wrappedContent}
    </ThumbnailCard>
  );
});
