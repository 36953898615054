export const compareSequentialElements = <T extends number | string>(
  a: T[],
  b: T[],
) => {
  const minLength = Math.min(a.length, b.length);
  for (let i = 0; i < minLength; i++) {
    const elementA = a[Number(i)]!;
    const elementB = b[Number(i)]!;
    if (elementA < elementB) {
      return -1;
    }
    if (elementA > elementB) {
      return 1;
    }
  }
  if (a.length > b.length) {
    return -1;
  }
  if (a.length < b.length) {
    return 1;
  }
  return 0;
};
