import { memo } from '../../util/memo';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

type GradientCircularProgressProps = CircularProgressProps & {
  gradientColor: string;
  label?: number;
};

export const GradientCircularProgress = memo(
  function GradientCircularProgressUnmemoized({
    gradientColor,
    label,
    sx,
    ...props
  }: GradientCircularProgressProps) {
    const theme = useTheme();

    const [section, gradient] = gradientColor.split('.');
    const desiredGradient = theme.palette[`${section}`][`${gradient}`];
    const parseGradient = () => {
      const regex = /#[0-9a-f]{6}/gi;
      return desiredGradient.match(regex);
    };
    const parseOffset = () => {
      const regex = /-*([0-9]*)(\.)*([0-9]*)%/gi;
      return desiredGradient.match(regex).map((item) => {
        return Number(item.substring(0, item.length - 1)) / 100;
      });
    };
    const offsets = parseOffset();
    const stops = parseGradient();
    return (
      <>
        {!label ? (
          <>
            <svg width={0} height={0}>
              <linearGradient
                id={`circularProgress-${gradientColor}`}
                x1={1}
                y1={0}
                x2={1}
                y2={1}
              >
                <stop
                  offset={offsets[0] | 0}
                  stopColor={stops[1] || '#4DC8FF'}
                />
                <stop
                  offset={offsets[1] | 1}
                  stopColor={stops[0] || '#0064E9'}
                />
              </linearGradient>
            </svg>
            <CircularProgress
              {...props}
              sx={{
                ...sx,
                color: 'transparent',
                'svg circle': {
                  stroke: `url(#circularProgress-${gradientColor})`,
                },
              }}
            />
          </>
        ) : (
          <>
            <svg width={0} height={0}>
              <linearGradient
                id={`circularProgress-${gradientColor}`}
                x1={1}
                y1={0}
                x2={1}
                y2={1}
              >
                <stop
                  offset={offsets[0] | 0}
                  stopColor={stops[1] || '#4DC8FF'}
                />
                <stop
                  offset={offsets[1] | 1}
                  stopColor={stops[0] || '#0064E9'}
                />
              </linearGradient>
            </svg>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                {...props}
                sx={{
                  ...sx,
                  color: 'transparent',
                  'svg circle': {
                    stroke: `url(#circularProgress-${gradientColor})`,
                  },
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${Math.round(label)}%`}</Typography>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  },
);
