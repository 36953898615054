import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useKeypressListener } from 'src/hooks/useKeypressListener';
import { memo } from '../../util/memo';

export type AuthenticationInputProps = TextFieldProps & {
  onSubmit?: () => void;
};

export const AuthenticationInput = memo(function AuthenticationInputUnmemoized(
  props: AuthenticationInputProps,
) {
  const { onSubmit, sx, ...rest } = props;
  const { helperText } = rest;

  useKeypressListener(['Enter', 'NumpadEnter'], onSubmit);

  return (
    <TextField
      error={!!helperText}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      fullWidth={true}
      {...rest}
    />
  );
});
