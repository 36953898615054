import { MemberTournamentStatus } from '../../components/tournaments/registration/teammates/TeammatesTable';
import { SxPropsTheme } from '../../styles/theme';

export type StatusTextProps = {
  text: string;
  color: string;
  usernameStyles?: SxPropsTheme;
};

export const BASIC_STYLES: SxPropsTheme = {
  fontWeight: 500,
  color: (theme) => {
    return theme.palette.text.primary;
  },
  fontStyle: 'normal',
};

export const STATUS_TEXT: Record<MemberTournamentStatus, StatusTextProps> = {
  captain: {
    text: 'Captain',
    color: 'text.secondary',
    usernameStyles: {
      ...BASIC_STYLES,
      fontStyle: 'italic',
    },
  },
  accepted: {
    text: 'Accepted!',
    color: 'secondary',
    usernameStyles: { ...BASIC_STYLES },
  },
  declined: {
    text: 'Declined',
    color: 'error',
    usernameStyles: {
      ...BASIC_STYLES,
      textDecoration: 'line-through',
      color: 'text.secondary',
    },
  },
  pending: {
    text: 'Requested...',
    color: 'warning.main',
    usernameStyles: { ...BASIC_STYLES },
  },
  reassigned: {
    text: 'Reassigned',
    color: 'text.primary',
    usernameStyles: { ...BASIC_STYLES },
  },
  solo: {
    text: 'Solo',
    color: 'text.primary',
    usernameStyles: {
      ...BASIC_STYLES,
      fontStyle: 'italic',
      color: 'text.secondary',
    },
  },
};

export const getStatusText = (
  status: MemberTournamentStatus,
): StatusTextProps => {
  return STATUS_TEXT[String(status)];
};
