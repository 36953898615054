export type ColumnHeightCalculator = (matchesCount: number) => number | string;

export const calcColumnHeight: ColumnHeightCalculator = (matchesCount) => {
  switch (matchesCount) {
    case 2:
      return 493;
    case 4:
      return 698;
    case 8:
      return 800;
    default:
      return '100%';
  }
};

export const calcColumnHeightLoser: ColumnHeightCalculator = (matchesCount) => {
  switch (matchesCount) {
    case 2:
      return 612;
    case 4:
      return 802;
    default:
      return '100%';
  }
};
