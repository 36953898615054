import Box from '@mui/material/Box';
import { memo } from '../util/memo';
import { ImageOptimized, ImageOptimizedProps } from './image/ImageOptimized';
import { Link } from './Link';
import { useMemo } from 'react';

export type LogoProps = Omit<ImageOptimizedProps, 'alt' | 'src'> & {
  asLink?: boolean;
};

export const BLUMINT_LOGO_URL = '/assets/images/logo.svg';

export function LogoUnmemoized({ asLink = true, ...props }: LogoProps) {
  const logo = useMemo(() => {
    return (
      <Box sx={{ mx: '-8px' }}>
        <ImageOptimized
          src={BLUMINT_LOGO_URL}
          alt={'BluMint Logo'}
          {...props}
        />
      </Box>
    );
  }, [props]);

  if (!asLink) return logo;

  return <Link href="/">{logo}</Link>;
}
export const Logo = memo(LogoUnmemoized);
