import { Stack } from '@mui/system';
import { FC, forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  AD_UNIT_IDS,
  AD_UNIT_IDS_MOBILE,
  AD_UNIT_IDS_STICKY,
  AD_UNIT_IDS_STICKY_MOBILE,
} from '../../../functions/src/util/ads/adapexAdUnits';
import { findLargestFit } from '../../../functions/src/util/ads/findLargestFit';
import { Adapex } from '../../util/ads/Adapex';
import { AdSize, AdContainer } from './AdContainer';
import { memo } from '../../util/memo';
import { AdProps } from './Ad';
import { useMobileAgent } from '../../hooks/useMobileAgent';

export type AdInsertProps = {
  adUnitId: string;
};

const isHTMLElement = (node: Node): node is HTMLElement => {
  return node.nodeType === Node.ELEMENT_NODE;
};

const isSuccessfulAdElement = (node: Node): node is HTMLElement => {
  return (
    isHTMLElement(node) &&
    node.offsetHeight > 0 &&
    node.offsetWidth > 0 &&
    node.children.length > 0
  );
};

function AdInsertUnmemoized({ adUnitId }: AdInsertProps, ref) {
  const stackRef = useRef<HTMLDivElement>(null);

  const setRef = useCallback(
    (element: HTMLElement | null) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    },
    [ref],
  );

  useEffect(() => {
    if (!stackRef.current) return;

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedElement = Array.from(mutation.addedNodes).find(
            isSuccessfulAdElement,
          );

          if (addedElement) {
            setRef(addedElement);
          }
        }
      }
    });

    observer.observe(stackRef.current, {
      childList: true,
      subtree: false,
    });

    setRef(null);

    return () => {
      observer.disconnect();
      setRef(null);
    };
  }, [setRef]);

  return (
    <Stack
      ref={stackRef}
      data-aaad="true"
      data-aa-lazy-loaded="true"
      data-aa-adunit={adUnitId}
    />
  );
}

const AdInsert = memo(
  forwardRef<HTMLElement | null, AdInsertProps>(AdInsertUnmemoized),
);

export const AdapexAdUnmemoized: FC<AdProps> = ({ sticky, ...props }) => {
  const isMobile = useMobileAgent();

  const adUnitMap = useMemo(() => {
    if (isMobile) {
      return sticky ? AD_UNIT_IDS_STICKY_MOBILE : AD_UNIT_IDS_MOBILE;
    }
    return sticky ? AD_UNIT_IDS_STICKY : AD_UNIT_IDS;
  }, [isMobile, sticky]);

  const findAdSize = useCallback(
    (dimensions: { width: number; height: number }) => {
      return findLargestFit(adUnitMap, dimensions.width, dimensions.height);
    },
    [adUnitMap],
  );

  const refreshAd = useCallback(async (unit: AdSize) => {
    await Adapex.processAdsOnPage();

    return unit;
  }, []);

  return (
    <AdContainer
      {...props}
      findAdSize={findAdSize}
      AdInsert={AdInsert}
      refreshAd={refreshAd}
      doIntervalRefresh={false}
    />
  );
};

export const AdapexAd = memo(AdapexAdUnmemoized);
