import { useContext, useEffect, useState } from 'react';
import { useDetailsSummary } from './useDetailsSummary';
import type { DocumentReference } from 'firebase/firestore';
import { User } from 'functions/src/types/firestore/User';
import { TournamentContext } from '../../contexts/docs/TournamentContext';

export type UseLeaderboardDetailsProps = {
  userIds: string[];
};

export const useLeaderboardDetails = ({
  userIds,
}: UseLeaderboardDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { gameId } = useContext(TournamentContext);
  const [details, setDetails] = useState<Record<string, unknown>[] | undefined>(
    undefined,
  );
  const summaries = useDetailsSummary(details);
  useEffect(() => {
    const handler = async () => {
      setIsLoading(true);

      const { firestore } = await import(
        '../../config/firebase-client/firestore'
      );
      const { getDoc, doc } = await import('firebase/firestore');

      const userDocs = await Promise.all(
        userIds.map((userId) => {
          return getDoc(
            doc(
              firestore,
              `User/${userId}/UserProfile/${userId}`,
            ) as DocumentReference<User>,
          );
        }),
      );
      const userData = userDocs
        .map((userDoc) => {
          return userDoc.data();
        })
        .filter((user): user is User => {
          return !!user;
        });
      setDetails(
        userData.map((userDatum) => {
          return { ...userDatum[`${gameId}`], userId: userDatum.id };
        }),
      );
      setIsLoading(false);
    };
    if (userIds?.length) {
      handler();
    }
  }, [gameId, setIsLoading, userIds]);
  return { details, summaries, isLoading };
};
