import { useMemo } from 'react';
import { Token } from '../../../functions/src/types/firestore/User/Payout';
import { TokenDivider } from '../../../functions/src/util/payouts/TokenDivider';

export type UsePayoutDivisibilityParams = {
  tokens: Token[];
  divisor: number;
};

export function usePayoutDivisibility({
  tokens,
  divisor,
}: UsePayoutDivisibilityParams) {
  return useMemo(() => {
    let divisible = false;
    const divider = new TokenDivider(tokens, divisor);
    try {
      divider.divideEvenly();
      divisible = true;
    } catch (error) {}
    return divisible;
  }, [divisor, tokens]);
}
