import { PriceableToken } from '../../../../util/currencies/PriceableToken';
import { Identifiable } from '../../../Identifiable';
import { Resolve } from '../../../utility-types';
import { PriceablePrizePoolToken } from '../../PrizePool';

export type TokenStandard =
  | 'ERC20'
  | 'ERC721'
  | 'ERC1155'
  | 'native'
  | 'offchain';

export type EvmAddress = `0x${string}`;

export type TokenAddress<TStandard extends TokenStandard> =
  TStandard extends 'native' ? typeof NULL_ADDRESS_EVM : EvmAddress;

export type TokenIdentifier<TStandard extends TokenStandard> =
  TStandard extends 'ERC721' | 'ERC1155' ? string : '0';

export type Token<TStandard extends TokenStandard = TokenStandard> = {
  chainId: number;
  amount: string;
  type: TStandard;
  address: TokenAddress<TStandard>;
  identifier: TokenIdentifier<TStandard>;
};

export type TokenPriceable = Token<'native'> | Token<'ERC20'>;

export type TokenTransferStage =
  | 'uninitiated'
  | 'transferring'
  | 'transferred'
  | 'failed';

export type TokenTransferUnion =
  | {
      stage: 'uninitiated';
      userId: string;
      address?: string;
    }
  | {
      stage: 'transferring';
      userId: string;
      address: string;
    }
  | {
      stage: 'failed';
      userId: string;
      address: string;
      errorMessage: string;
    }
  | {
      stage: 'transferred';
      userId: string;
      address: string;
      transactionHash: string;
    };

export type TokenTransfer<
  TStage extends TokenTransferStage = TokenTransferStage,
> = Extract<TokenTransferUnion, { stage: TStage }>;

export type PayoutToken<TStandard extends TokenStandard = TokenStandard> =
  Token<TStandard> & {
    benefactor: TokenTransfer;
  };

export const NULL_ADDRESS_EVM: `0x${Lowercase<string>}` = `0x0000000000000000000000000000000000000000`;

export type PayoutTokenNative = PayoutToken<'native'>;

export type PayoutTokenERC20 = PayoutToken<'ERC20'>;

export type PayoutTokenERC721 = PayoutToken<'ERC721'>;

export type PayoutTokenERC1155 = PayoutToken<'ERC1155'>;

export type PayoutTokenOffchain = PayoutToken<'offchain'> & {
  address: `firestore-${string}`;
  identifier: '0';
};

export type PriceablePayoutToken = PayoutToken<'native'> | PayoutToken<'ERC20'>;

export type PriceableTokenUnion =
  | PriceableToken
  | PriceablePrizePoolToken
  | PriceablePayoutToken;

export type Payout = Resolve<
  Identifiable & {
    userId: string;
    tokens: PayoutToken[];
    payoutableDocPath: string;
    progressionId?: string;
  }
>;

export type Payoutable<T> = T & { payoutIds?: string[] };
