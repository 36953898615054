import { ReactNode, useCallback, ComponentType, useState } from 'react';
import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';
import {
  DialogCentered,
  DialogCenteredProps,
} from 'src/components/DialogCentered';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { useRedirectCallback } from './useRedirectCallback';
import { WizardOptions } from './wizard/createUseWizard';

export type OpenDialogSettings = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  children?: ReactNode;
  Wrapper?: ComponentType<{ children: ReactNode }>;
} & Omit<DialogCenteredProps, 'open'>;

export function useDialog(
  id: string,
  options: WizardOptions = { closeOnRouteChange: true },
) {
  const { union, remove } = useGlobalComponentsContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback(() => {
    setIsOpen(false);
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.closeOnRouteChange ? close : undefined);

  const open = useCallback(
    ({
      title,
      description,
      children,
      onClose,
      Wrapper,
      ...props
    }: OpenDialogSettings) => {
      const body = !!Wrapper ? (
        <Wrapper>
          <DialogBodyStandard title={title} description={description}>
            {children}
          </DialogBodyStandard>
        </Wrapper>
      ) : (
        <DialogBodyStandard title={title} description={description}>
          {children}
        </DialogBodyStandard>
      );

      union(
        id,
        <DialogCentered
          open={true}
          {...props}
          onClose={() => {
            remove(id);
            onClose?.();
          }}
        >
          {body}
        </DialogCentered>,
      );
      setIsOpen(true);
    },
    [id, remove, union],
  );
  return {
    isOpen,
    open,
    close,
  };
}
