import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { getTeammates } from '../../util/tournaments/getTeammates';
import { isPreReady } from '../../../functions/src/types/firestore/Game/Tournament/util';

export const useCanInvite = () => {
  const { foundTeam, maxTeamSize, inviteUrl, phase, continuousRegistration } =
    useTournamentRegistration();
  const { uid } = useAuth();
  const teammates = uid ? getTeammates({ userId: uid, foundTeam }) : undefined;
  const isOnTeam = teammates?.find((teammate) => {
    return (
      teammate.userId === uid &&
      (teammate.status === 'accepted' || teammate.status === 'captain')
    );
  });
  const canInvite = useMemo(() => {
    return (
      (isPreReady(phase) || continuousRegistration) &&
      !!teammates &&
      teammates.length < maxTeamSize &&
      !!isOnTeam
    );
  }, [continuousRegistration, isOnTeam, maxTeamSize, phase, teammates]);

  return { canInvite, inviteUrl };
};
