import { Round } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { calculateRoundKind } from './calculateRoundKind';
import { RoundStepperProps } from 'src/components/tournaments/RoundStepper';

export type CreateRoundStepsParams = Pick<
  RoundStepperProps,
  'rounds' | 'activeRound' | 'activeRoundProgress'
> & {
  isLoserBracket: boolean;
  roundPayouts: Pick<Round, 'payout'>[];
  isMobile: boolean;
};

export function createRoundSteps({
  rounds,
  activeRoundProgress,
  isLoserBracket,
  isMobile,
  roundPayouts = [],
  activeRound = -1,
}: CreateRoundStepsParams) {
  const roundsCount = rounds.length;
  const roundSteps = [...new Array(roundsCount).keys()].map((round) => {
    const roundKind = calculateRoundKind({ index: round, roundsCount });
    return {
      isReversed: false,
      kind: isLoserBracket && roundKind !== 'final' ? 'loser' : roundKind,
      payout: roundPayouts[Number(round)],
      isActiveRound: activeRound === round,
      roundNumber: isLoserBracket ? round / 2 + 1.5 : round + 1,
      progress:
        activeRound === round
          ? activeRoundProgress
          : activeRound > round
          ? 100
          : 0,
    };
  });
  const roundStepsReversed = isMobile
    ? []
    : roundSteps
        .slice(0, roundsCount - 1)
        .map((roundStep) => {
          return { ...roundStep, isReversed: true };
        })
        .reverse();
  return { roundSteps, roundStepsReversed };
}
