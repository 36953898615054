import { useEffect, useMemo, useState } from 'react';
import type { DocumentData } from 'firebase/firestore';
import {
  UseOnSnapshotInfiniteScrollProps,
  useOnSnapshotInfiniteScroll,
} from './useOnSnapshotInfiniteScroll';

export type UseRealtimeInfiniteScrollProps<TDoc extends DocumentData> = Omit<
  UseOnSnapshotInfiniteScrollProps<TDoc>,
  'startAfter'
> & {
  timeKey: keyof TDoc & string;
  startTime?: Date;
};

export function useRealtimeInfiniteScroll<TDoc extends DocumentData>({
  timeKey,
  startTime: startTimeSpecified,
  query,
  ...props
}: UseRealtimeInfiniteScrollProps<TDoc>) {
  const startTime = useMemo(() => {
    return startTimeSpecified || new Date();
  }, [startTimeSpecified]);

  const queryInfinitePromise = useMemo(async () => {
    const queryAwaited = await query;
    if (!queryAwaited) {
      return;
    }

    const {
      query: firestoreQuery,
      where,
      orderBy,
    } = await import('firebase/firestore');

    return firestoreQuery(
      queryAwaited,
      where(timeKey, '<=', startTime),
      orderBy(timeKey, 'desc'),
    );
  }, [query, timeKey, startTime]);

  const { containerRef, documents: infiniteScrollDocuments } =
    useOnSnapshotInfiniteScroll<TDoc>({
      query: queryInfinitePromise,
      ...props,
    });

  const [realtimeDocuments, setRealtimeDocuments] = useState<TDoc[]>([]);

  useEffect(() => {
    const handler = async () => {
      const queryAwaited = await query;

      if (!queryAwaited) {
        return async () => {
          // hehe
        };
      }
      const {
        query: firestoreQuery,
        where,
        onSnapshot,
      } = await import('firebase/firestore');

      const queryRealtime = firestoreQuery(
        queryAwaited,
        where(timeKey, '>', startTime),
      );

      return onSnapshot(
        queryRealtime,
        (snapshot) => {
          const newDocs = snapshot.docs.map((doc) => {
            return doc.data();
          });
          setRealtimeDocuments(newDocs);
        },
        (error) => {
          console.error(error);
        },
      );
    };
    const unsubscribePromise = handler();

    return () => {
      const handlerDestructor = async () => {
        (await unsubscribePromise)();
      };
      handlerDestructor();
    };
  }, [timeKey, query, startTime]);

  const documents = useMemo(() => {
    const combinedDocs = [...realtimeDocuments, ...infiniteScrollDocuments];
    const uniqueDocsMap = new Map<string, TDoc>();
    combinedDocs.forEach((doc) => {
      return uniqueDocsMap.set(doc.id, doc);
    });

    const uniqueDocs = Array.from(uniqueDocsMap.values());
    const sortedDocs = uniqueDocs.sort((a, b) => {
      return b.timeKey - a.timeKey;
    });

    return sortedDocs;
  }, [realtimeDocuments, infiniteScrollDocuments]);

  return {
    containerRef,
    documents,
  };
}
