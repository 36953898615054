import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export const toEventUrl = ({
  id,
  date,
  gameId,
}: Pick<Tournament<Date>, 'id' | 'date' | 'gameId'>) => {
  const eventsPage = `/game/${gameId}/events`;
  return `${eventsPage}?event=Game/${gameId}/Tournament/${id}&eventDate=${new Date(
    date,
  ).getTime()}`;
};
