/* eslint-disable no-shadow */
import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  ActiveChannelGroup,
  toActiveChannelGroup,
} from '../../functions/src/util/messaging/toActiveChannelGroup';
import { memo } from '../util/memo';
import { useRouterState } from '../hooks/routing/useRouterState';
import { useFindChannelGroup } from '../hooks/messaging/useFindChannelGroup';
import { useSocialDrawer } from '../hooks/useSocialDrawer';

export type RouterStateChange = string | 'unset' | 'no-change';

export type ActiveChannelGroupContextProps = {
  switchChannel: (channelId: string | 'unset') => void;
  openChannelGroup: (channelGroupId: string, channelId?: string) => void;
  closeChannel: () => void;
  closeChannelGroup: () => void;
  isChannelGroupOpen: boolean;
  channelGroupActive?: ActiveChannelGroup;
  channelGroupId?: string;
  channelId?: string;
};

export const ActiveChannelGroupContext = createContext<
  ActiveChannelGroupContextProps | undefined
>(undefined);

export type ActiveChannelGroupProps = {
  children: ReactNode;
};

export const useActiveChannelGroup = () => {
  const context = useContext(ActiveChannelGroupContext);
  if (!context) {
    throw new Error(
      'useActiveChannelGroup must be used within an ActiveChannelGroupProvider',
    );
  }
  return context;
};

const ActiveChannelGroupProviderUnmemoized = ({
  children,
}: ActiveChannelGroupProps) => {
  const { isSocialDrawerOpen, openSocialDrawer } = useSocialDrawer();
  const [channelGroupIdRouter, setChannelGroupId] = useRouterState({
    key: 'channelGroup',
  });

  const isChannelGroupOpen = useMemo(() => {
    return isSocialDrawerOpen && !!channelGroupIdRouter;
  }, [channelGroupIdRouter, isSocialDrawerOpen]);

  const { findByChannelGroupId } = useFindChannelGroup();
  const channelGroupActive = useMemo(() => {
    const activeChannelGroup = findByChannelGroupId(channelGroupIdRouter);

    if (!activeChannelGroup) {
      return;
    }
    return toActiveChannelGroup(activeChannelGroup);
  }, [channelGroupIdRouter, findByChannelGroupId]);

  const [channelIdRouter, setChannelId] = useRouterState({ key: 'channel' });

  const set = useCallback(
    (channelGroupId: RouterStateChange, channelId: RouterStateChange) => {
      if (channelGroupId === 'unset') {
        setChannelGroupId(undefined);
      } else if (channelGroupId !== 'no-change') {
        setChannelGroupId(channelGroupId);

        if (!isSocialDrawerOpen) {
          openSocialDrawer();
        }
      }
      if (channelId === 'unset') {
        setChannelId(undefined);
      } else if (channelId !== 'no-change') {
        setChannelId(channelId);

        if (!isSocialDrawerOpen) {
          openSocialDrawer();
        }
      }
    },
    [isSocialDrawerOpen, openSocialDrawer, setChannelGroupId, setChannelId],
  );

  const openChannelGroup = useCallback(
    (channelGroupId: string, channelId?: string) => {
      openSocialDrawer();
      set(channelGroupId, channelId || 'unset');
    },
    [openSocialDrawer, set],
  );

  const closeChannelGroup = useCallback(() => {
    set('unset', 'unset');
  }, [set]);

  const closeChannel = useCallback(() => {
    set('no-change', 'unset');
  }, [set]);

  const switchChannel = useCallback(
    (channelId: string | 'unset') => {
      set('no-change', channelId);
    },
    [set],
  );

  useEffect(() => {
    if (channelGroupActive) {
      return;
    }
    closeChannelGroup();
  }, [channelGroupActive, closeChannelGroup]);

  const value = useMemo(() => {
    return {
      openChannelGroup,
      closeChannelGroup,
      switchChannel,
      closeChannel,
      channelGroupActive,
      isChannelGroupOpen,
      channelGroupId: channelGroupIdRouter,
      channelId: channelIdRouter,
    };
  }, [
    channelGroupActive,
    channelGroupIdRouter,
    channelIdRouter,
    closeChannel,
    closeChannelGroup,
    isChannelGroupOpen,
    openChannelGroup,
    switchChannel,
  ]);

  return (
    <ActiveChannelGroupContext.Provider value={value}>
      {children}
    </ActiveChannelGroupContext.Provider>
  );
};

export const ActiveChannelGroupProvider = memo(
  ActiveChannelGroupProviderUnmemoized,
);
