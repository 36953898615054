import {
  MessageInput as StreamMessageInput,
  MessageInputProps as StreamMessageInputProps,
  LocalAttachmentUploadMetadata,
} from 'stream-chat-react';
import Box from '@mui/material/Box';
import { ReplyIndicator } from '../ReplyIndicator';
import type { Channel as StreamChannel } from 'stream-chat';
import { useCallback, useMemo, useRef, useEffect } from 'react';
import { memo } from '../../../util/memo';
import { useReply } from '../ReplyContext';
import { useMessageDraft } from '../../../hooks/messaging/useMessageDraft';
import { useProcessMessage } from '../../../hooks/messaging/useProcessMessage';
import { useMessageInputHeight } from '../../../hooks/messaging/useMessageInputHeight';
import { useMessageInputFocus } from '../../../contexts/get-stream/MessageInputFocusContext';
import { MESSAGE_INPUT_ID } from '../channel/ChannelInner';

export const MESSAGE_INPUT_MAX_ROWS = 14;
export const TO_MEGABYTES = 1024 * 1024;

export const SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'audio/mp3',
  'audio/wav',
  'audio/m4a',
  'audio/flac',
  'audio/aac',
  'file/doc',
  'file/docx',
  'file/pdf',
  'file/ppt',
  'file/pptx',
  'file/txt',
  'file/xls',
  'file/xlsx',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/heic',
  'image/heic-sequence',
  'image/heif',
  'image/heif-sequence',
  'image/svg+xml',
  'video/mp4',
  'video/ogg',
  'video/webm',
  'video/quicktime',
];

export type MessageInputProps = StreamMessageInputProps & {
  id: string;
};

const MessageInputUnmemoized = (props: MessageInputProps) => {
  const { id, ...restProps } = props;
  const { replyingTo, setReplyingTo } = useReply();
  const { getMainInputDraft, onInputChange } = useMessageDraft();
  const { sendMessageOverride, processFile } = useProcessMessage({
    replyingTo,
  });
  const inputRef = useRef<HTMLElement | null>(null);
  const messageInputHeight = useMessageInputHeight(inputRef);
  const { isFocused, setFocus, unsetFocus } = useMessageInputFocus();

  const textAreaProps = useMemo(() => {
    return {
      // placeholder: 'Say something...',
      onChange: id === MESSAGE_INPUT_ID ? onInputChange : undefined,
      onFocus: (_) => {
        return setFocus(id);
      },
      onBlur: (_) => {
        return unsetFocus(id);
      },
    };
  }, [onInputChange, setFocus, unsetFocus, id]);

  useEffect(() => {
    if (!replyingTo) {
      return;
    }
    setFocus(id);
  }, [replyingTo, setFocus, id]);

  const focus = useMemo(() => {
    return isFocused(id);
  }, [isFocused, id]);

  return (
    <Box zIndex={!!replyingTo ? 10 : 0} width="100%" position="relative">
      {!!replyingTo && (
        <ReplyIndicator
          username={replyingTo.username}
          onClick={() => {
            setReplyingTo(undefined);
          }}
        />
      )}
      <Box
        ref={inputRef}
        sx={{
          '& .str-chat__send-button': {
            height: `${messageInputHeight}px !important`,
          },
        }}
      >
        <StreamMessageInput
          grow
          focus={focus}
          maxRows={MESSAGE_INPUT_MAX_ROWS}
          overrideSubmitHandler={sendMessageOverride}
          getDefaultValue={getMainInputDraft}
          additionalTextareaProps={textAreaProps}
          doFileUploadRequest={useCallback(
            async (
              file: LocalAttachmentUploadMetadata['file'],
              channel: StreamChannel,
            ) => {
              const newFile = processFile(file);
              if (!newFile) {
                return;
              }
              return await channel.sendFile(newFile);
            },
            [processFile],
          )}
          doImageUploadRequest={useCallback(
            async (
              file: LocalAttachmentUploadMetadata['file'],
              channel: StreamChannel,
            ) => {
              const newFile = processFile(file);
              if (!newFile) {
                return;
              }
              return await channel.sendImage(newFile);
            },
            [processFile],
          )}
          {...restProps}
        />
      </Box>
    </Box>
  );
};

export const MessageInput = memo(MessageInputUnmemoized);
