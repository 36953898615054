import { useEffect } from 'react';
import {
  EMAIL_VERIFIED_SUCCESS,
  WELCOME_TITLE,
} from '../../../hooks/auth/useAuthFlowBase';
import { AuthenticationFlowCompleteDialog } from './AuthenticationFlowCompleteDialog';
import { useVerifyEmail } from '../../../hooks/auth/useVerifyEmail';

export const EmailVerificationCompleteDialog = () => {
  const { updateEmailVerified } = useVerifyEmail();
  useEffect(() => {
    updateEmailVerified(true);
  }, [updateEmailVerified]);
  return (
    <AuthenticationFlowCompleteDialog
      title={WELCOME_TITLE}
      description={EMAIL_VERIFIED_SUCCESS}
    />
  );
};
