import { useEffect } from 'react';

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      // .then(function (registration: ServiceWorkerRegistration) {
      //   console.log('Registration successful, scope is:', registration.scope);
      // })
      .catch(function (err: Error) {
        console.warn('Service worker registration failed, error:', err);
      });
  }
}

export const useRegisterServiceWorker = (): void => {
  useEffect(() => {
    registerServiceWorker();
  }, []);
};
