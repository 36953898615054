import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { LoadingButton } from '../../buttons/LoadingButton';
import { FormItem } from '../../form/FormItem';
import type { Channel } from 'stream-chat';
import { GradientRadio } from '../../gradients/GradientRadio';

export type RemoveBanFormProps = {
  onClose: () => void;
  onSubmitRemoveBanDetails: (channel?: Channel) => Promise<void>;
  isSelectionShown: boolean;
  isUserBanned: boolean;
  channel: Channel;
};

const RemoveBanFormUnmemoized = ({
  onClose,
  onSubmitRemoveBanDetails,
  isSelectionShown,
  isUserBanned,
  channel,
}: RemoveBanFormProps) => {
  const [isRemoveChannelBan, setIsRemoveChannelBan] = useState(isUserBanned);

  useEffect(() => {
    setIsRemoveChannelBan(isUserBanned);
  }, [isUserBanned]);

  const removeBan = useCallback(async () => {
    await onSubmitRemoveBanDetails(isRemoveChannelBan ? channel : undefined);
    onClose();
  }, [channel, isRemoveChannelBan, onClose, onSubmitRemoveBanDetails]);

  const radioButtonChannel = useMemo(() => {
    return <GradientRadio checked={isRemoveChannelBan} />;
  }, [isRemoveChannelBan]);

  const radioButtonApp = useMemo(() => {
    return <GradientRadio />;
  }, []);

  const radioGroup = useMemo(() => {
    return (
      isSelectionShown && (
        <FormItem title="Type">
          <RadioGroup
            row
            onChange={(event) => {
              const { value } = event.target;
              setIsRemoveChannelBan(value === 'channel');
            }}
          >
            <FormControlLabel
              value="channel"
              control={radioButtonChannel}
              label="Channel"
            />
            <FormControlLabel
              value="app"
              control={radioButtonApp}
              label="App"
            />
          </RadioGroup>
        </FormItem>
      )
    );
  }, [isSelectionShown, radioButtonApp, radioButtonChannel]);

  return (
    <Stack direction="column" width="100%" spacing={6} minWidth={480}>
      <FormControl>
        <Stack
          direction="column"
          spacing={3}
          sx={{ '& .MuiFormGroup-root': { mt: 0 } }}
        >
          {radioGroup}
        </Stack>
      </FormControl>
      <LoadingButton fullWidth variant="contained" onClick={removeBan}>
        Remove Ban
      </LoadingButton>
    </Stack>
  );
};

export const RemoveBanForm = memo(RemoveBanFormUnmemoized);
