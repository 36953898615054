import { useMemo } from 'react';
import { Hit } from '../../../functions/src/types/Hit';

export const useHitsDeduped = (hits: Hit[]) => {
  return useMemo(() => {
    const seen = new Map();
    return hits.filter((hit) => {
      if (!seen.has(hit.objectID)) {
        seen.set(hit.objectID, true);
        return true;
      }
      return false;
    });
  }, [hits]);
};
