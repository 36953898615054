import { useCallback, useEffect, useRef } from 'react';
import { CUSTOM_OAUTH_INFO } from '../../util/auth/oauthUrlUtils';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { useWizard } from '../../components/wizard/Wizard';
import {
  CustomSignInMethod,
  EMAIL_AUTH_METHOD,
} from '../../../functions/src/types/firestore/User';
import { AuthFlowStore } from './useAuthFlowBase';
import { useAuth } from '../../contexts/AuthContext';
import { extractErrorCode } from '../../util/auth/authErrorConfig';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { customOAuthSignIn } from '../../util/auth/customOAuthSignIn';
import { DEPLOYMENT_URL } from '../../../functions/src/util/deploymentUrl';
import { openInAppBrowser } from '../../util/openInAppBrowser';
import { isOnApp } from '../../util/isOnApp';

type EventListenerCallback = (event: MessageEvent) => void;

export const useOAuthCustom = () => {
  const { setErrorCodeOf, setIsLoading } = useAuthSubmit();
  const { go } = useWizard<AuthFlowStore, string>();
  const { uid } = useAuth();

  const signInOAuthCustom = useCallback(
    async (customToken: string) => {
      try {
        setIsLoading(true);
        await customOAuthSignIn(customToken);
      } catch (e) {
        const errorCode = extractErrorCode(e);
        setErrorCodeOf('generic', errorCode);
      } finally {
        setIsLoading(false);
      }
    },
    [setErrorCodeOf, setIsLoading],
  );

  const authenticateWithCode = useCallback(
    async (event: MessageEvent, customSignInMethod: CustomSignInMethod) => {
      const { code } = event.data;
      if (!!code) {
        try {
          const { issueCustomToken } = await import(
            '../../firebaseCloud/auth/issueCustomToken'
          );
          const { token } = await issueCustomToken({
            code,
            providerId: customSignInMethod,
            isOnApp: isOnApp(),
          });
          if (!uid) {
            await signInOAuthCustom(token);
          }

          go(!!uid ? 'Link Successful' : undefined);
        } catch (error) {
          console.error(error);
          const provider = EMAIL_AUTH_METHOD.find((method) => {
            return ((error as any)?.details as string)?.includes(method);
          });
          const errorCode = provider
            ? `email-linked-to-${provider}`
            : extractErrorCode(error);
          setErrorCodeOf('generic', errorCode);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signInOAuthCustom, !!uid],
  );

  const authWindowsRef = useRef<Map<Window, EventListenerCallback>>(new Map());

  const registerOAuthEventListener = useCallback(
    (authWindow: Window, method: CustomSignInMethod) => {
      const messageHandler = (event: MessageEvent) => {
        if (event.origin.includes(DEPLOYMENT_URL)) {
          return authenticateWithCode(event, method);
        }
        throw new HttpsError(
          'aborted',
          'untrusted-message-source',
          `origin: ${event.origin}`,
        );
      };
      authWindowsRef.current.set(authWindow, messageHandler);
      window.addEventListener('message', messageHandler);

      authWindow.onbeforeunload = () => {
        window.removeEventListener('message', messageHandler);
        authWindowsRef.current.delete(authWindow);
      };
    },
    [authenticateWithCode],
  );

  const connectOAuthCustom = useCallback(
    async (customSignInMethod: CustomSignInMethod) => {
      const oAuthUrl =
        CUSTOM_OAUTH_INFO[`${customSignInMethod}`]['authorization']['url'];
      if (isOnApp()) {
        await openInAppBrowser(oAuthUrl.toJSON());
        return;
      }
      const authWindow = window.open(oAuthUrl);
      if (authWindow) {
        registerOAuthEventListener(authWindow, customSignInMethod);
      }
    },
    [registerOAuthEventListener],
  );

  useEffect(() => {
    return () => {
      authWindowsRef.current.forEach((callback, authWindow) => {
        window.removeEventListener('message', callback);
        if (!authWindow.closed) {
          authWindow.close();
        }
      });
      authWindowsRef.current.clear();
    };
  }, []);

  return { connectOAuthCustom };
};
