import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../util/memo';
import { LoadingButton } from '../buttons/LoadingButton';
import { useSendConfirmationCode } from '../../hooks/auth/useSendConfirmationCode';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ResendCodeTextUnmemoized = () => {
  const theme = useTheme();
  const { sendConfirmationCode, secondsRemaining } = useSendConfirmationCode(
    {},
  );
  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={'4px'}
    >
      <Typography variant="body1" color={'text.primary'}>
        Didn`t get a code?
      </Typography>
      <LoadingButton
        id="recaptcha"
        onClick={sendConfirmationCode}
        variant="text"
        disabled={secondsRemaining > 0}
        sx={{
          color: theme.palette.primary.main,
          textDecoration: 'underline',
          cursor: 'pointer',
          p: 0,
          width: {
            xs: '88px',
            md: '88px',
          },
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        another one!
      </LoadingButton>
      <Box sx={{ width: '25px' }}>
        {secondsRemaining > 0 && (
          <Typography variant="body1" color={'action.disabled'}>
            {secondsRemaining}s
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export const ResendCodeText = memo(ResendCodeTextUnmemoized);
