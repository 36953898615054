import Stack from '@mui/material/Stack';
import { FC, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import {
  PriceablePrizePoolToken,
  PrizePool,
} from 'functions/src/types/firestore/PrizePool';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { InstancePanelHeadered } from '../../InstancePanelHeadered';
import { GradientTypography } from '../../../gradients/GradientTypography';
import Typography from '@mui/material/Typography';
import { PrizeGrid } from '../PrizeGrid';
import { useLinkDialog } from '../../../../hooks/prize-pool/useLinkDialog';
import { LinkToTournament } from './LinkToTournament';
import { Item } from '../../../../../functions/src/types/firestore/Game/Collection/Item';
import { Token } from '../../../../../functions/src/types/firestore/User/Payout';
import { useAuth } from '../../../../contexts/AuthContext';
import { Link } from '../../../Link';
import Button from '@mui/material/Button';

export type PrizePoolPanelProps = {
  prizePool: PrizePool;
  events: any[];
};

export const isToken = (item: Token | Item<Date>): item is Token => {
  return 'address' in item;
};

export const isPriceableToken = (
  item: Token | Item<Date>,
): item is PriceablePrizePoolToken => {
  return isToken(item) && ['ERC20', 'native'].includes(item.type);
};

export const PrizePoolPanel: FC<PrizePoolPanelProps> = memo(
  function PrizePoolPanelUnmemoized({ prizePool, events }) {
    const { uid } = useAuth();
    const { linkToEvent } = useLinkDialog({
      // TODO: make this generic for events
      linkComponents: { event: <LinkToTournament /> },
      isChangingLink: !!prizePool.linkedId,
    });

    const countPrizes = prizePool.prizes.length;
    const countConfirmedPrizes = useMemo(() => {
      return prizePool.prizes.filter((prize) => {
        return prize.contributor.stage === 'transferred';
      }).length;
    }, [prizePool.prizes]);

    return (
      <InstancePanelHeadered
        Header={
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="column">
              <GradientTypography variant="h6" gradientColor="primary.vertical">
                {/* TODO: can remove id once we've cleaned up existing pools. */}
                {`Prize Pool ${prizePool.title || prizePool.id}`}
              </GradientTypography>
              <Typography>
                {countPrizes === 0
                  ? 'No Prizes Selected'
                  : `${countPrizes} Selected Prizes (${countConfirmedPrizes} confirmed)`}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                onClick={async () => {
                  return linkToEvent({
                    prizePool,
                    isChangingLink: !!prizePool.linkedId,
                  });
                }}
                variant="outlined"
                color="secondary"
                sx={{ height: 35, minWidth: !!prizePool.linkedId ? 170 : 120 }}
                disabled={
                  !events.length ||
                  countPrizes === 0 ||
                  countConfirmedPrizes !== countPrizes
                }
              >
                {!!prizePool.linkedId ? 'Change Linked Event' : 'Link event'}
              </LoadingButton>
              <Link
                style={{ textDecoration: 'none' }}
                href={`/${user?.uid}/prize-pool/${prizePool.id}`}
              >
                <Button variant="outlined" sx={{ height: 35, minWidth: 120 }}>
                  Edit
                </Button>
              </Link>
            </Stack>
          </Stack>
        }
        PanelContent={<PrizeGrid items={prizePool.prizes} />}
      />
    );
  },
);
