import { memo } from '../../../util/memo';
import { AuthenticationStackBase } from '../AuthenticationStackBase';

const AuthLinkMethodsDialogUnmemoized = () => {
  return (
    <AuthenticationStackBase
      title="Link Sign-In Method"
      subtitle="Please select the additional sign-in option you would like to add to
      your account."
    />
  );
};
export const AuthLinkMethodsDialog = memo(AuthLinkMethodsDialogUnmemoized);
