import { useRouter } from 'src/hooks/routing/useRouter';

export const AUTH_QUERY_PARAMS = [
  'apiKey',
  'mode',
  'oobCode',
  'continueUrl',
  'code',
  'customProvider',
] as const;

export const useRemoveAuthQueryParams = () => {
  const { replaceParam } = useRouter();

  const removeAuthQueryParams = () => {
    return replaceParam(
      ...AUTH_QUERY_PARAMS.map((name) => {
        return { name, value: undefined, silent: true };
      }),
    );
  };

  return { removeAuthQueryParams };
};
