import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMessage } from './MessageContext';
import { MessageQuoted } from './MessageQuoted';
import { memo } from '../../../util/memo';
import { LinkPreviewText } from './LinkPreviewText';
import { MESSAGE_MAX_WIDTH } from './Message';
import { useStyledText } from '../../../hooks/messaging/useStyledText';
import { useMemo } from 'react';

const MessageBubbleUnmemoized = () => {
  const theme = useTheme();
  const { isMine, message, linkConfig } = useMessage();
  const { text, type, quoted_message, mentioned_users } = message;
  const { link, linkText } = linkConfig || {};
  const isLink = !!Object.keys(linkConfig).length;
  const styledText = useStyledText({ mentioned_users, link, linkText, text });
  const isDeleted = type === 'deleted';

  const quotedMessage = useMemo(() => {
    return !!quoted_message && !isDeleted && <MessageQuoted />;
  }, [isDeleted, quoted_message]);

  const linkPreviewText = useMemo(() => {
    return isLink && !isDeleted && <LinkPreviewText />;
  }, [isDeleted, isLink]);

  const messageText = useMemo(() => {
    return isDeleted ? 'This message was deleted.' : styledText;
  }, [isDeleted, styledText]);

  return (
    <Stack
      px={4}
      width="fit-content"
      py={!!text ? 1 : 0}
      borderRadius={isMine ? '10px 10px 2px 10px' : '10px 10px 10px 2px'}
      sx={{
        backgroundColor:
          type === 'deleted'
            ? theme.palette.other.textSecondaryShades.opacity8
            : isMine
            ? theme.palette.background.elevationSolid[30]
            : theme.palette.background.elevation[14],
        maxWidth: MESSAGE_MAX_WIDTH,
      }}
    >
      {quotedMessage}
      {linkPreviewText}
      <Typography
        variant="body1"
        color={isDeleted ? 'text.secondary' : 'text.primary'}
        sx={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          maxWidth: MESSAGE_MAX_WIDTH,
        }}
        width="fit-content"
      >
        {messageText}
      </Typography>
    </Stack>
  );
};
export const MessageBubble = memo(MessageBubbleUnmemoized);
