import { memo } from '../util/memo';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { Markdown } from './Markdown';

export type TypographyParagraphedProps = {
  text: string;
  sx?: SxProps;
};

export const TypographyParagraphed = memo(
  function TypographyParagraphedUnmemoized({
    text,
    sx = {},
  }: TypographyParagraphedProps) {
    return (
      <Box sx={{ ...sx }}>
        <Markdown text={text} />
      </Box>
    );
  },
);
