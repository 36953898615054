import {
  useContext,
  createContext,
  ReactNode,
  useRef,
  useEffect,
  useState,
  RefObject,
  FC,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type BracketWidthContextType = {
  bracketWidthRef: RefObject<HTMLElement>;
  bracketWidth?: number;
};

export const BracketWidthContext =
  createContext<BracketWidthContextType | null>(null);

export const useBracketWidth = () => {
  const context = useContext(BracketWidthContext);
  if (!context) {
    throw new Error(
      'useBracketWidth must be used within a BracketWidthProvider',
    );
  }
  return context;
};

const BracketWidthProviderUnmemoized: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const bracketWidthRef = useRef<HTMLElement>(null);
  const [bracketWidth, setBracketWidth] = useState<number>();

  useEffect(() => {
    if (!bracketWidthRef.current) {
      return;
    }

    setBracketWidth(bracketWidthRef.current.clientWidth);
  }, [bracketWidthRef]);

  const bracketWidthData = useMemo(() => {
    return {
      bracketWidthRef,
      bracketWidth,
    };
  }, [bracketWidth]);

  return (
    <BracketWidthContext.Provider value={bracketWidthData}>
      {children}
    </BracketWidthContext.Provider>
  );
};

export const BracketWidthProvider = memo(BracketWidthProviderUnmemoized);
