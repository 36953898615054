import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useAuth } from '../../contexts/AuthContext';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { useInviteGuarded } from '../friends/useInviteGuarded';
import { useUserGroups } from '../group/useUserGroups';

export const useRegistrationFunctions = () => {
  const {
    gameId,
    id: tournamentId,
    foundTeam,
    title: _tournamentTitle,
    roles,
  } = useTournamentRegistration();
  const { uid } = useAuth();
  const userGroups = useUserGroups();

  const acceptTeamInvite = async (
    details: Record<string, unknown>,
    teamId?: string,
  ) => {
    if (!uid || !teamId) {
      return;
    }
    const { acceptInvite } = await import(
      '../../firebaseCloud/tournament/acceptInvite'
    );
    await acceptInvite({
      acceptorId: uid,
      gameId,
      tournamentId,
      teamId,
      details,
    });
  };

  const rejectTeamInvite = async (teamId?: string) => {
    if (!uid || !teamId) {
      return;
    }
    const { rejectInvite } = await import(
      '../../firebaseCloud/tournament/rejectInvite'
    );
    await rejectInvite({
      gameId,
      tournamentId,
      rejectorId: uid,
      teamId,
    });
  };

  const uninvite = async (memberToRemove: string) => {
    if (!uid || !foundTeam?.name) {
      return;
    }
    const { dropTeamMember } = await import(
      '../../firebaseCloud/tournament/dropTeamMember'
    );
    await dropTeamMember({
      callerId: uid,
      gameId,
      tournamentId,
      teamId: foundTeam.id,
      removeMemberId: memberToRemove,
    });
  };

  const notifyMe = async () => {
    // TODO: Replace PopUp with new Wizard Component
    // const { doc, getDoc } = await import('firebase/firestore');
    // const userDoc = await getDoc(doc(firestore, `User/${uid}`));
    // const phoneNumber = userDoc.get('hidden.phone');
    // if (!phoneNumber) {
    //   return setPopUp({
    //     open: true,
    //     variant: 'alerts',
    //   });
    // }
    // if (!uid) {
    //   return;
    // }
    // const { subscribeToUpdates } = await import(
    //   '../../firebaseCloud/twilio/subscribeToUpdates'
    // );
    // await subscribeToUpdates({
    //   serviceConfig: {
    //     name: 'tournamentAlerts',
    //     options: {
    //       tournamentId,
    //       tournamentTitle,
    //     },
    //   },
    //   uid,
    // });
  };

  const unsubscribe = async () => {
    // if (!uid) {
    //   return;
    // }
    // const { unsubscribeFromUpdates } = await import(
    //   '../../firebaseCloud/twilio/unsubscribeFromUpdate'
    // );
    // await unsubscribeFromUpdates({
    //   uid,
    //   serviceConfig: {
    //     name: 'tournamentAlerts',
    //     options: {
    //       tournamentId,
    //       tournamentTitle,
    //     },
    //   },
    // });
  };

  const setTeamName = async (newName: string, teamId: string) => {
    if (!teamId || !newName) {
      return;
    }
    const { updateTeamName } = await import(
      '../../firebaseCloud/tournament/updateTeamName'
    );
    const status = await updateTeamName({
      gameId,
      teamId,
      tournamentId,
      newName,
    });
    return status;
  };

  const leaveTeam = async (teamId: string) => {
    if (!uid || !teamId) {
      return;
    }
    const { exitTeam } = await import(
      '../../firebaseCloud/tournament/exitTeam'
    );
    await exitTeam({
      gameId,
      teamId,
      tournamentId,
      userId: uid,
    });
  };
  const disbandTeam = async () => {
    const teamId = foundTeam?.id;
    if (!uid || !teamId) {
      return;
    }
    const { exitTeam } = await import(
      '../../firebaseCloud/tournament/exitTeam'
    );
    await exitTeam({
      gameId,
      teamId,
      tournamentId,
      userId: uid,
    });
  };
  const checkIn = async (entireTeam: boolean, userToCheckIn?: string) => {
    const effectiveUserId = userToCheckIn || uid;
    if (!effectiveUserId) {
      return;
    }

    const { checkIn: checkInTeam } = await import(
      '../../firebaseCloud/tournament/checkIn'
    );
    await checkInTeam({
      gameId,
      entireTeam,
      userId: effectiveUserId,
      tournamentId,
      requireAccepted: true,
    });
  };

  const checkRegistrationPermission = () => {
    if (!roles?.registrant) {
      return false;
    }

    const allowedRegistrants = roles.registrant;

    if (allowedRegistrants.includes('anyone')) {
      return true;
    }

    return userGroups.some((groupId) => {
      return allowedRegistrants.includes(String(groupId));
    });
  };

  const registerSolo = async (details: Record<string, unknown>) => {
    if (!uid) return;

    const hasPermission = checkRegistrationPermission();
    if (!hasPermission) {
      throw new HttpsError(
        'permission-denied',
        'You are not allowed to register for this tournament.',
      );
    }

    const { createTeam: createTeamSolo } = await import(
      '../../firebaseCloud/tournament/createTeam'
    );
    await createTeamSolo({
      gameId,
      tournamentId,
      // captainId: uid,
      isSolo: true,
      details,
    });
  };

  const createTeam = async (details: Record<string, unknown>) => {
    if (!uid) return;

    const hasPermission = checkRegistrationPermission();
    if (!hasPermission) {
      throw new HttpsError(
        'permission-denied',
        'You are not allowed to register for this tournament.',
      );
    }

    const { createTeam: createTeamNonSolo } = await import(
      '../../firebaseCloud/tournament/createTeam'
    );
    await createTeamNonSolo({
      // captainId: uid,
      isSolo: false,
      gameId,
      tournamentId,
      details,
    });
  };

  const inviteGuarded = useInviteGuarded({
    onSuccess: () => {
      /* */
    },
  });

  const invite = async (inviteeId: string) => {
    if (!uid || !foundTeam) return;

    await inviteGuarded({
      inviteeId,
      teamId: foundTeam.id,
      gameId,
      tournamentId,
    });
  };

  return {
    acceptTeamInvite,
    rejectTeamInvite,
    uninvite,
    notifyMe,
    unsubscribe,
    setTeamName,
    leaveTeam,
    disbandTeam,
    checkIn,
    registerSolo,
    createTeam,
    invite,
    checkRegistrationPermission,
  };
};
