// eslint-disable-next-line no-restricted-imports
import type { UserInfo } from 'firebase/auth';

export const formatProviderData = (data: UserInfo[]) => {
  return data.map(
    ({ displayName, email, photoURL, phoneNumber, providerId, uid }) => {
      return {
        displayName: displayName || '',
        email: email || '',
        photoURL: photoURL || '',
        phoneNumber: phoneNumber || '',
        providerId,
        uid,
      };
    },
  );
};
