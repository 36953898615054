import Stack from '@mui/material/Stack';
import { useEffect, useState, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { MessageFooter } from './MessageFooter';
import { MessageBody } from './MessageBody';
import { MessageEditing } from './MessageEditing';
import { MessageProvider } from './MessageContext';
import { useMessageContext as useStreamMessageContext } from 'stream-chat-react';
import { useReply } from '../ReplyContext';
import { useMuteStatus } from '../../../hooks/messaging/useMuteStatus';

export const MESSAGE_MAX_WIDTH = 264;

const MessageUnmemoized = () => {
  const { message, editing } = useStreamMessageContext();
  const { replyingTo } = useReply();
  const isReplyMessage = replyingTo?.id === message.id;
  const { calculateMuteStatus } = useMuteStatus();
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    const { created_at, user } = message;
    if (!created_at || !user) {
      return;
    }
    setIsMuted(calculateMuteStatus(new Date(created_at), user.id));
  }, [calculateMuteStatus, message]);

  const messageCustom = useMemo(() => {
    return isMuted ? (
      <></>
    ) : (
      <MessageProvider>
        {editing ? (
          <MessageEditing />
        ) : (
          <Stack
            direction="column"
            position="relative"
            sx={{
              transform: isReplyMessage ? 'translateY(-8px)' : 'none',
              transformOrigin: 'left center',
              zIndex: isReplyMessage ? 10 : 0,
              transition: 'transform 0.3s ease, zIndex 0.3s ease',
              pb: 1,
            }}
          >
            <MessageBody />
            <MessageFooter />
          </Stack>
        )}
      </MessageProvider>
    );
  }, [editing, isMuted, isReplyMessage]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{messageCustom}</>;
};

export const Message = memo(MessageUnmemoized);
