import { UtcOffset, sampleIana } from 'functions/src/util/time/timezone';

export class DateTimeFormatterUtc {
  private readonly utcOffset = DateTimeFormatterUtc.getUtcOffset();
  private readonly browserLocal = DateTimeFormatterUtc.getBrowserLocale();

  public constructor(
    private readonly options: Intl.DateTimeFormatOptions = {},
  ) {}

  public static getUtcOffset(): UtcOffset | undefined {
    return typeof global !== 'undefined'
      ? (global.UTC_OFFSET as UtcOffset)
      : undefined;
  }

  public static getBrowserLocale(): string | undefined {
    return typeof window !== 'undefined' ? navigator?.language : undefined;
  }

  public format(
    date: Date,
    hideOffset = !this.options.timeZone && !this.options.timeZoneName,
  ): string {
    const dateTimeFormat = new Intl.DateTimeFormat(
      this.browserLocal,
      this.optionsWithUtc,
    );

    const formattedDate = dateTimeFormat.format(new Date(date));

    return this.utcOffset && !hideOffset
      ? `${formattedDate} ${this.utcOffset}`
      : formattedDate;
  }

  public get optionsWithUtc(): Intl.DateTimeFormatOptions | undefined {
    return this.utcOffset && !this.browserLocal
      ? {
          ...this.options,
          timeZone: sampleIana(this.utcOffset),
          timeZoneName: undefined,
        }
      : this.options;
  }
}
