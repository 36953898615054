import { useMemo, FC } from 'react';
import { normalizeTab } from '../../util/normalizeTab';
import { TabsVariantProps } from './TabsRouted';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { memo } from '../../util/memo';

const TabsScrollableUnmemoized: FC<TabsVariantProps> = ({
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const tabsNormalized = useMemo(() => {
    return tabs.map(normalizeTab);
  }, [tabs]);
  const tabsRendered = useMemo(() => {
    return (
      <>
        {tabsNormalized.map(({ id, value, sx: sxTab, customization }) => {
          return (
            <Tab
              id={id}
              key={id}
              value={value}
              label={value}
              sx={{ ...sxTab, textTransform: 'uppercase' }}
              {...customization}
            />
          );
        })}
      </>
    );
  }, [tabsNormalized]);

  return (
    <Tabs
      value={activeTab}
      onChange={routeTab}
      variant="scrollable"
      scrollButtons={false}
      sx={sx}
    >
      {tabsRendered}
    </Tabs>
  );
};

export const TabsScrollable = memo(TabsScrollableUnmemoized);
