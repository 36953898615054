import { useCallback, useEffect } from 'react';
import { useDeleteAccountDialog } from './useDeleteAccountDialog';
import { useAuth } from '../contexts/AuthContext';
import { useDeleteAccountRouter } from './useDeleteAccountRouter';

export const useDeleteAccount = () => {
  const { uid, userFull } = useAuth();
  const { deleteAccount, close } = useDeleteAccountRouter();
  const { open: openDeleteAccountDialog } = useDeleteAccountDialog();
  const closeDialog = useCallback(() => {
    close();
    userFull?.reload?.();
  }, [close, userFull]);
  useEffect(() => {
    if (uid && deleteAccount === 'true') {
      openDeleteAccountDialog(closeDialog);
    }
  }, [closeDialog, deleteAccount, openDeleteAccountDialog, uid]);
};
