import { useEffect, useRef } from 'react';
import { Adapex } from '../../util/ads/Adapex';

export const REFRESH_ADAPEX_MS = 500 as const;

export const useRefreshAdapex = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const stop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const start = () => {
    if (!intervalRef.current) {
      Adapex.processAdsOnPage();
      intervalRef.current = setInterval(async () => {
        await Adapex.processAdsOnPage();
      }, REFRESH_ADAPEX_MS);
    }
  };

  useEffect(() => {
    start();
    return stop;
  }, []);

  return {
    stop,
    start,
  };
};
