import { memo } from '../../../util/memo';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { NoticeParagraphed } from '../../NoticeParagraphed';
import { useErrorAlert } from '../../../hooks/useErrorAlert';
import { RSVP_ERROR_LIBRARY } from '../../../../functions/src/util/error/library/rsvpTeam';
import { useCallback } from 'react';

const RulesCheckInDialogUnmemoized = () => {
  const { rulesAndRegulations, date } = useTournamentRegistration();
  const { catchError } = useErrorAlert(RSVP_ERROR_LIBRARY);
  const { checkIn } = useRegistrationFunctions();
  // const { userData } = useAuth();
  const { go } = useWizard();

  const checkInSelf = useCallback(async () => {
    await checkIn(false);
    go('CheckedInDialog');
  }, [checkIn, go]);

  const checkInSelfSafe = useCallback(async () => {
    await catchError(checkInSelf, () => {
      return go(undefined);
    });
  }, [catchError, checkInSelf, go]);

  const format = useDateFormatter({
    separator: ', ',
  });
  const startDateFormatted = format(date);

  return (
    <DialogBodyStandard
      title={'Tournament Rules'}
      description={
        <Box>
          <Box
            sx={{
              textAlign: 'center',
              backgroundImage: 'none',
              display: 'inline-block',
              maxWidth: '540px',
            }}
          >
            <Typography variant="h6" sx={{ display: 'inline' }}>
              The tournament begins{' '}
            </Typography>
            <GradientTypography
              variant="h6"
              gradientColor="warning.vertical"
              sx={{ display: 'inline' }}
            >
              {startDateFormatted}
            </GradientTypography>
            <Typography variant="h6" sx={{ display: 'inline' }}>
              . In order to participate, you must agree to the rules below:
            </Typography>
          </Box>

          {rulesAndRegulations && (
            <NoticeParagraphed text={rulesAndRegulations} />
          )}
        </Box>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            children: 'Accept',
            isAsync: true,
            type: 'submit',
            onClick: checkInSelfSafe,
            endIcon: <CheckCircleOutlineIcon />,
            color: 'secondary',
            sx: { flex: 2 },
          },
          {
            children: 'Cancel',
            isAsync: false,
            onClick: () => {
              return go(undefined);
            },
            color: 'error',
            sx: { textTransform: 'uppercase', flex: 1, ml: 1 },
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const RulesCheckInDialog = memo(RulesCheckInDialogUnmemoized);
