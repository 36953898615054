import { ReactNode, memo, useMemo, useState, useCallback } from 'react';
import { GradientButton } from '../gradients/GradientButton';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useMobile } from '../../hooks/useMobile';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const END_ICON_WIDTH = '22px';
export const END_ICON_HEIGHT = '22px';

export type ShareButtonInfoProps = {
  children: ReactNode;
  tooltip?: ReactNode;
};

export const ShareButtonInfo = memo(function ShareButtonInfoUnmemoized({
  children,
  tooltip,
}: ShareButtonInfoProps) {
  const isMobile = useMobile();
  const [isClicked, setIsClicked] = useState(false);

  const markClicked = useCallback(() => {
    setIsClicked(true);
  }, []);

  const button = useMemo(() => {
    return (
      <GradientButton
        endIcon={ShareOutlinedIcon}
        variant="outlined"
        onClick={markClicked}
      >
        {children}
      </GradientButton>
    );
  }, [children, markClicked]);

  if (isMobile || !tooltip || isClicked) return button;

  return (
    <Tooltip arrow title={tooltip}>
      <Box width="100%">{button}</Box>
    </Tooltip>
  );
});
