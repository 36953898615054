import { FC, Children } from 'react';
import { memo } from '../../../../util/memo';
import Stack, { StackProps } from '@mui/material/Stack';
import { ColumnHeightCalculator, calcColumnHeight } from './calcColumnHeight';

export type ColumnDesktopProps = StackProps & {
  columnHeightCalculator?: ColumnHeightCalculator;
};

const ColumnDesktopUnmemoized: FC<ColumnDesktopProps> = ({
  children,
  columnHeightCalculator = calcColumnHeight,
  ...rest
}) => {
  const matchesCount = Children.count(children);
  const height = columnHeightCalculator(matchesCount);

  const isSemiFinal = matchesCount === 1;
  const isQuarterFinal = matchesCount === 2;
  const width = isSemiFinal ? 72 : isQuarterFinal ? 44 : 'auto';

  return (
    <Stack
      direction="column"
      spacing={5.75}
      alignItems="center"
      alignSelf="center"
      width={width}
      height={height}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export const ColumnDesktop = memo(ColumnDesktopUnmemoized);
