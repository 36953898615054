export function cleanEmail(email: string): string {
  const cleanedEmail = email.trim().toLowerCase().replace(/\s+/g, '');
  return !cleanedEmail ? '' : cleanedEmail;
}

export function isValidEmail(email: string): boolean {
  const cleanedEmail = cleanEmail(email);
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    !!cleanedEmail && cleanedEmail.length >= 3 && pattern.test(cleanedEmail)
  );
}
