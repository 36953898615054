import { UserProviderInfo } from '../../types/firestore/User';

export const extractProviderId = (providerId: string) => {
  if (providerId.includes('.com')) {
    return providerId.replace('.com', '');
  }
  return providerId;
};

export const extractProviderIds = (providerData: UserProviderInfo[]) => {
  return providerData.map(({ providerId }) => {
    return extractProviderId(providerId);
  });
};
