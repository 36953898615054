import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
// import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { memo } from '../../util/memo';
import { useCallerCount } from '../../hooks/voice-chat/useCallerCount';
import { useMemo } from 'react';
import { useRoom } from '../../contexts/RoomContext';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
// import { useInviteCallerDialog } from '../../hooks/voice-chat/useInviteCallerDialog';
// import { GradientIconButton } from '../gradients/GradientIconButton';
// import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
// import { useRoledUser } from '../../hooks/messaging/useRoledUser';

const CallersPreviewUnmemoized = () => {
  const { rooms } = useRoom();
  const { mode } = useVoiceChat();

  const room = rooms[mode as string];

  const callerCount = useCallerCount([room?.roomRef]);
  // const isAdmin = useRoledUser();
  // const { open } = useInviteCallerDialog();

  const count = useMemo(() => {
    return (
      !!callerCount &&
      callerCount !== 'unknown' && <Typography>{callerCount}</Typography>
    );
  }, [callerCount]);

  // const callerIcon = useMemo(() => {
  //   return isAdmin && mode === 'studio' ? (
  //     <GradientIconButton
  //       IconComponent={PersonAddAltRoundedIcon}
  //       onClick={open}
  //       sxOuter={{ px: '0px !important' }}
  //     />
  //   ) : (
  //     <RecordVoiceOverOutlinedIcon />
  //   );
  // }, [isAdmin, mode, open]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      // sx={{
      //   ':hover': {
      //     cursor: 'pointer',
      //   },
      // }}
    >
      {count}
      <RecordVoiceOverOutlinedIcon />
    </Stack>
  );
};

export const CallersPreview = memo(CallersPreviewUnmemoized);
