import { useMemo } from 'react';
import { useCanInvite } from '../../hooks/tournaments/useCanInvite';
import Box from '@mui/material/Box';
import { CLIPBOARD_PREFIX_MOBILE, ClipboardShare } from '../ClipboardShare';
import { ShareButtonBase } from '../buttons/ShareButtonBase';
import { useMobile } from '../../hooks/useMobile';
export const SHARE_INVITE_TEXT = 'Invite';
export const SHARE_INVITE_TOOLTIP =
  'Share this link with someone you want to invite into your team.';

export const ShareInviteButtonOld = () => {
  const { canInvite, inviteUrl } = useCanInvite();
  const isMobile = useMobile();

  const description = useMemo(() => {
    if (!isMobile) {
      return;
    }
    return `${CLIPBOARD_PREFIX_MOBILE} ${SHARE_INVITE_TOOLTIP}`;
  }, [isMobile]);

  const guide = useMemo(() => {
    return isMobile ? undefined : SHARE_INVITE_TOOLTIP;
  }, [isMobile]);

  const shareInviteButton = useMemo(() => {
    return (
      canInvite && (
        <ClipboardShare value={inviteUrl} description={description}>
          <ShareButtonBase guide={guide}>{SHARE_INVITE_TEXT}</ShareButtonBase>
        </ClipboardShare>
      )
    );
  }, [canInvite, description, guide, inviteUrl]);

  return <Box>{shareInviteButton}</Box>;
};
