/* eslint-disable security/detect-object-injection */
import {
  useMemo,
  useCallback,
  ReactNode,
  ComponentType,
  MemoExoticComponent,
  SetStateAction,
  Dispatch,
} from 'react';
import { Context, useContextSelector } from 'use-context-selector';
import { memo } from '../util/memo';

export type MutateSetContextType<TEntity> = {
  set: TEntity[];
  union: (entity: TEntity) => void;
  remove: (identifier: string) => void;
};

export type MutateSetProviderProps<TEntity> = {
  context: Context<MutateSetContextType<TEntity>>;
  setState: [TEntity[], Dispatch<SetStateAction<TEntity[]>>];
  identifierKey?: string;
  children: ReactNode;
};

const MutateSetProviderUnmemoized = <TEntity,>({
  context,
  setState: [set, setSet],
  identifierKey,
  children,
}: MutateSetProviderProps<TEntity>) => {
  const union = useCallback(
    (entity: TEntity) => {
      if (
        set.find((prevEntity) => {
          if (identifierKey) {
            return (
              prevEntity[String(identifierKey)] ===
              entity[String(identifierKey)]
            );
          }
          return prevEntity === entity;
        })
      ) {
        return;
      }
      setSet((prevSet) => {
        return [...prevSet, entity];
      });
    },
    [identifierKey, set, setSet],
  );

  const remove = useCallback(
    (identifier: string) => {
      setSet((prev) => {
        return prev.filter((entity) => {
          if (identifierKey) {
            return entity[String(identifierKey)] !== identifier;
          }
          return entity !== identifier;
        });
      });
    },
    [identifierKey, setSet],
  );

  const value = useMemo(() => {
    return { set, union, remove };
  }, [set, union, remove]);

  return useMemo(() => {
    return <context.Provider value={value}>{children}</context.Provider>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, children]);
};

export const MutateSetProvider = memo(
  MutateSetProviderUnmemoized,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as MemoExoticComponent<ComponentType<MutateSetProviderProps<any>>>;

export const MANAGE_OPTIONS = ['ADD', 'REMOVE'] as const;
export type ManageOption = typeof MANAGE_OPTIONS[number];

export type UseMutateSetProps<TEntity> = {
  context: Context<MutateSetContextType<TEntity>>;
  identifier: string;
  identifierKey?: keyof TEntity;
};

export const useManageEntity = <TEntity,>({
  identifier,
  identifierKey,
  context,
}: UseMutateSetProps<TEntity>) => {
  const entity = useContextSelector(context, (contextSelected) => {
    return contextSelected?.set.find((contextEntity) => {
      if (identifierKey) {
        return contextEntity[String(identifierKey)] === identifier;
      }
      return contextEntity === entity;
    });
  });

  const option: ManageOption = useMemo(() => {
    return entity ? 'REMOVE' : 'ADD';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const union = useContextSelector(context, (contextSelected) => {
    return contextSelected?.union;
  });
  const remove = useContextSelector(context, (contextSelected) => {
    return contextSelected?.remove;
  });

  return useMemo(() => {
    return { option, union, remove };
  }, [option, remove, union]);
};
