import { LiveBadge } from 'src/components/LiveBadge';
import { useMatch } from './MatchProvider';
import { ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { Link } from '../../Link';

export type MatchLiveBadgeProps = { children: ReactNode };

export const MatchLiveBadgeUnmemoized: React.FC<MatchLiveBadgeProps> = ({
  children,
}) => {
  const { streamUrl } = useMatch();

  const badge = useMemo(() => {
    return (
      <LiveBadge
        sx={{
          top: -10,
          left: 'calc(50% - 22.5px)',
        }}
      />
    );
  }, []);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  if (!streamUrl) return <>{children}</>;

  return (
    <Link href={`TODO/${streamUrl}`}>
      {badge}
      {children}
    </Link>
  );
};

export const MatchLiveBadge = memo(MatchLiveBadgeUnmemoized);
