import Stack from '@mui/material/Stack';
import { LoadingButton } from '../../buttons/LoadingButton';
import { useEditable } from '../../../contexts/organizer/EditableStepsContext';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { useLinkToPrizePool } from '../../../hooks/prize-pool/useLinkToPrizePool';
import { useCreatePrizePool } from '../../../hooks/prize-pool/useCreatePrizePool';
import Tooltip from '@mui/material/Tooltip';

export const NoPrizePoolLinked = () => {
  const { document: event, updateField } = useEditable<Tournament<Date>>();
  const link = useLinkToPrizePool({
    updateField,
    event,
    isChangingLink: false,
  });

  const create = useCreatePrizePool();
  return (
    <Tooltip title="Give away fungible & non-fungible tokens to participants in your tournament. Create a new prize pool for your tournament or link a previously created prize pool.">
      <Stack direction="column" gap={4}>
        <LoadingButton
          fullWidth
          sx={{ borderRadius: '100px', height: '42px' }}
          variant="outlined"
          color="primary"
          onClick={create}
        >
          Create Prize Pool
        </LoadingButton>
        <LoadingButton
          fullWidth
          sx={{ borderRadius: '100px', height: '42px' }}
          variant="outlined"
          color="primary"
          onClick={link}
        >
          Link Prize Pool
        </LoadingButton>
      </Stack>
    </Tooltip>
  );
};
