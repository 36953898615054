import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { Loadable } from '../isLoading';

export const userInMatch = (
  teams: (Team<Date> | undefined)[],
  uid?: Loadable<string>,
) => {
  return (
    !!uid &&
    teams.some((team) => {
      return team?.acceptedUserIds?.includes(uid);
    })
  );
};
