import type {
  FirestoreDataConverter,
  WithFieldValue,
} from 'firebase-admin/firestore';
import type { DocumentDataEverywhere } from '../../types/DocumentDataEverywhere';
import type { QueryDocumentSnapshotEverywhere } from '../../types/QuerySnapshotEverywhere';
import { isTimestamp } from './timestamp';

export function isIrreducible(data: unknown): boolean {
  return (
    data === null ||
    data === undefined ||
    (typeof data !== 'object' && !Array.isArray(data))
  );
}

export function isReference(
  data: Record<string, unknown> | unknown[],
): boolean {
  return (
    typeof data === 'object' &&
    'type' in data &&
    (data.type === 'document' ||
      data.type === 'collection' ||
      ('path' in data && 'parent' in data))
  );
}

export class Converter<
  T extends DocumentDataEverywhere,
  TConvertableType,
  TConvertedType,
> implements FirestoreDataConverter<T>
{
  public constructor(
    private readonly shouldConvertValue: (value: unknown) => boolean,
    private readonly convertValue: (value: TConvertableType) => TConvertedType,
    // against my own pattern of having boolean parameters not in an options object
    // but in this case, since we only use Converter via ConverterFactory,
    // I think this is fine.
    private readonly preserveTimestamp: boolean = true,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  public toFirestore<TDocument extends WithFieldValue<T>>(
    modelObject: TDocument,
  ): TDocument {
    return modelObject;
  }

  public fromFirestore<TSnapshot extends QueryDocumentSnapshotEverywhere>(
    snapshot: TSnapshot,
  ): T {
    const data = snapshot.data();
    return this.convertData(data);
  }

  public convertData(data: unknown) {
    return this.reducer(data) as T;
  }

  private reducer(data: unknown): any {
    if (this.shouldConvertValue(data)) {
      return this.convertValue(data as TConvertableType);
    }

    if (
      isIrreducible(data) ||
      isReference(data as Record<string, unknown> | unknown[])
    ) {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((valueEntity: unknown | unknown[]) => {
        return this.reducer(valueEntity);
      });
    }

    if (this.preserveTimestamp && isTimestamp(data)) {
      return data;
    }

    return Object.keys(data as Record<string, unknown>).reduce(
      (accumulator, key) => {
        const value = (data as Record<string, unknown>)[`${key}`];
        accumulator[`${key}`] = this.reducer(value);

        return accumulator;
      },
      {} as Record<string, unknown>,
    );
  }
}
