import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { FriendsView } from './FriendsView';
import { FriendsEdit } from './FriendsEdit';
import { FriendsAdd } from './FriendsAdd';
import { TabPane, TabPanes } from '../../routing/TabPanes';
import { ChannelManager } from '../../messaging/ChannelManager';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import PeopleIcon from '@mui/icons-material/PeopleRounded';
import { useGlobalComponentsContext } from '../../../contexts/GlobalComponentsContext';
import { ChatMenu } from '../../messaging/ChatMenu';
import { useAuth } from '../../../contexts/AuthContext';
import { useOnlineStatus } from '../../../hooks/useOnlineStatus';
import { calculateOnlineCount } from '../../../util/friends/calculateOnlineCount';
import { GradientIcon } from '../../gradients/GradientIcon';
import { FriendRequestsBadge } from './FriendRequestsBadge';

export const CHAT_MENU_NAME = 'CHAT_MENU';

export const FRIEND_PANE_OPTIONS = ['View', 'Add', 'Edit', 'Chat'] as const;
export type FriendPaneOption = typeof FRIEND_PANE_OPTIONS[number];

const FriendsPaneUnmemoized = () => {
  const { union, remove } = useGlobalComponentsContext();
  const { userData } = useAuth();

  const { friends = [] } = userData || {};

  const friendsList = useMemo(() => {
    return [...friends];
  }, [friends]);

  const { onlineStatus } = useOnlineStatus(friendsList);

  const { onlineFriendsCount } = calculateOnlineCount(onlineStatus, friends);

  const tabState = useRouterState({
    key: 'friends',
    defaultValue: 'View' as FriendPaneOption,
  });

  const tabPanes: TabPane<string>[] = useMemo(() => {
    return [
      {
        value: 'View' as FriendPaneOption,
        component: <FriendsView onlineFriendsCount={onlineFriendsCount} />,
        customization: {
          IconComponent: <GradientIcon IconComponent={PeopleIcon} />,
        },
      },
      {
        value: 'Add' as FriendPaneOption,
        component: <FriendsAdd />,
        customization: {
          IconComponent: <GradientIcon IconComponent={PersonAddIcon} />,
        },
        Wrapper: (children) => {
          return (
            <FriendRequestsBadge sx={{ '& .MuiBadge-badge': { mr: 1, mt: 1 } }}>
              {children}
            </FriendRequestsBadge>
          );
        },
      },
      {
        value: 'Edit' as FriendPaneOption,
        component: <FriendsEdit onlineFriendsCount={onlineFriendsCount} />,
        customization: {
          IconComponent: <GradientIcon IconComponent={SettingsIcon} />,
        },
      },
      {
        value: 'Chat' as FriendPaneOption,
        onClick: (tabRef, go) => {
          union(
            CHAT_MENU_NAME,
            <ChatMenu
              anchorEl={tabRef.current}
              createChat={() => {
                go();
                remove(CHAT_MENU_NAME);
              }}
              onCreateSupport={() => {
                remove(CHAT_MENU_NAME);
              }}
              sx={{ mt: 2 }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            />,
          );
        },
        component: <ChannelManager />,
        customization: {
          IconComponent: <GradientIcon IconComponent={AddIcon} />,
          label: 'Chat',
        },
      },
    ];
  }, [onlineFriendsCount, remove, union]);

  return (
    <Stack height="100%" width="100%" pt={2}>
      <TabPanes tabPanes={tabPanes} state={tabState} variant="chip" />
    </Stack>
  );
};

export const FriendsPane = memo(FriendsPaneUnmemoized);
