// The following are valid only according to
// https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/combining-boolean-operators/#filters-syntax-validator

export const isModerator = (uid: string) => {
  return `roles.moderator:${uid}`;
};
export const isContributor = (uid: string) => {
  // TODO: Refactor to roles.contributor once we merge in BLU-4793
  return `userIdsContributors:${uid}`;
};
export const EVENT_UNPUBLISHED = `phase:unpublished` as const;
export const EVENT_NO_PRIZE_POOL = '_prizePoolIdNull:true' as const;
export const EVENT_PUBLISHED = `NOT ${EVENT_UNPUBLISHED}` as const;
export const EVENT_LISTED = 'visibility:listed' as const;

export const GUEST_FILTERS = `${EVENT_PUBLISHED} AND ${EVENT_LISTED}` as const;
export const ADMIN_FILTERS = '' as const;
export const normalUserFilters = (uid: string) => {
  const isUserModerator = isModerator(uid);
  const isUserContributor = isContributor(uid);
  return `(${isUserModerator} OR ${isUserContributor} OR ${EVENT_LISTED}) AND (${isUserModerator} OR ${isUserContributor} OR ${EVENT_PUBLISHED})`;
};
export const DEFAULT_HITS_PER_PAGE = 8;
