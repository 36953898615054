import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import {
  useAuthSubmit,
  PasswordInput,
  UsernameInput,
} from '../../contexts/AuthSubmitContext';
import { Fragment } from 'react';

export type ValidationMessageParams = {
  error: boolean;
  hasUserTyped: { [K in PasswordInput | UsernameInput]: boolean };
  children: React.ReactNode;
};

const ValidationMessage = memo(function ValidationMessageUnmemoized({
  error,
  hasUserTyped,
  children,
}: ValidationMessageParams) {
  const theme = useTheme();
  const color = !hasUserTyped.password
    ? '#FFFFFF'
    : error
    ? theme.palette.error.main
    : theme.palette.success.main;
  return (
    <Typography
      component="span"
      variant="body2"
      color={color}
      sx={{ display: 'inline' }}
    >
      {children}
    </Typography>
  );
});

export const AuthenticationPasswordValidation = memo(
  function AuthenticationPasswordValidationUnmemoized() {
    const { passwordStrengthErrors, hasUserTyped } = useAuthSubmit();
    const {
      tooShort,
      tooLong,
      needsUppercase,
      needsLowercase,
      needsNumber,
      needsSymbol,
    } = passwordStrengthErrors;

    const requirements = [
      {
        error: tooShort || tooLong,
        text: ' 8-32 characters',
      },
      { error: needsUppercase, text: 'uppercase letters' },
      { error: needsLowercase, text: 'lowercase letters' },
      { error: needsNumber, text: 'numbers' },
      { error: needsSymbol, text: 'symbols' },
    ];

    return (
      <Typography variant="body2">
        Passwords must contain
        {requirements.map((requirement, index) => {
          const { error, text } = requirement;
          return (
            <Fragment key={`${text}-${error}`}>
              <ValidationMessage error={error} hasUserTyped={hasUserTyped}>
                {text}
              </ValidationMessage>
              {index === 0
                ? ' combining '
                : index < requirements.length - 1
                ? ', '
                : '.'}
            </Fragment>
          );
        })}
      </Typography>
    );
  },
);
