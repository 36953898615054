import { useState, useCallback, useEffect, RefObject } from 'react';

export const useParentDimensions = (
  width: number | '100%',
  height: number | '100%',
  ref: RefObject<HTMLElement>,
) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const updateDimensions = useCallback(() => {
    const parent = ref.current?.parentElement;
    if (!parent) return;

    const newWidth = width === '100%' ? parent.clientWidth : width;
    const newHeight = height === '100%' ? parent.clientHeight : height;

    if (newWidth !== dimensions.width || newHeight !== dimensions.height) {
      setDimensions({ width: newWidth, height: newHeight });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, ref]);

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateDimensions, ref]);

  return dimensions;
};
