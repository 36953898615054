import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useFetchViewerToken } from './useFetchViewerToken';

export type FetchLiveViewersParams = {
  assetId: string;
  assetType: 'playback' | 'video' | 'live_stream' | 'asset';
};

export const useFetchLiveViewers = () => {
  const { fetchViewerToken } = useFetchViewerToken();

  const fetchLiveViewers = async ({
    assetId,
    assetType,
  }: FetchLiveViewersParams) => {
    const token = await fetchViewerToken({
      assetId,
      assetType,
    });

    const response = await fetch(`https://stats.mux.com/counts?token=${token}`);

    if (!response.ok) {
      throw new HttpsError(
        'internal',
        'Failed to fetch viewer counts',
        response,
      );
    }
    const json = await response.json();

    return json.data[0]?.viewers;
  };

  return { fetchLiveViewers };
};
