import { BASE_64_CHARS } from './intToBase64';

export const BIT_PER_CHAR = 6;

export function base64ToInt(str: string, charsPerNum = 5) {
  if (str.length !== charsPerNum) {
    return;
  }
  return str.split('').reduce((result: number, char: string) => {
    const index = BASE_64_CHARS.indexOf(char);
    if (index === -1) {
      throw new Error(`Invalid character: ${char}`);
    }
    return (result << BIT_PER_CHAR) | index;
  }, 0);
}
