import { MatchAggregated } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import {
  toAvatar,
  toName,
  toOutcome,
} from '../../../functions/src/types/firestore/Game/Tournament/Guestlist/util';

export function matchCompetitorsOf({
  team1Score,
  team2Score,
  winner,
  team1,
  team2,
}: Pick<
  MatchAggregated<Date>,
  'team1Score' | 'team2Score' | 'team1' | 'team2' | 'winner'
>) {
  return [
    {
      competitor: team1
        ? { name: toName(team1), avatarUrl: toAvatar(team1) }
        : undefined,
      score: team1Score,
      outcome: toOutcome(team1, team2, winner),
    },
    {
      competitor: team2
        ? { name: toName(team2), avatarUrl: toAvatar(team2) }
        : undefined,
      score: team2Score,
      outcome: toOutcome(team2, team1, winner),
    },
  ];
}
