export const TOURNAMENT_URL_MATCH = /Tournament%2F/;

export const toTournamentId = (url: string) => {
  const tournamentIdMatch = url.match(/Tournament%2F([a-zA-Z0-9-]+)/);
  return tournamentIdMatch ? { tournamentId: tournamentIdMatch[1] } : undefined;
};
// View remarks for gameId in functions/src/types/firestore/User/ChannelGroup/index.ts
// export const toGameId = (url: string) => {
//   const gameIdMatch = url.match(/Game%2F([a-zA-Z0-9-]+)/);
//   return gameIdMatch ? { gameId: gameIdMatch[1] } : undefined;
// };

export const toGuildId = (url: string) => {
  const urlWithoutParams = url.split('?')[0] ?? '';

  const urlParts = urlWithoutParams.split('/');
  const guildIndex = urlParts.indexOf('guild');

  if (guildIndex !== -1 && guildIndex < urlParts.length - 1) {
    const guildId = urlParts[guildIndex + 1];
    return { guildId };
  }

  return undefined;
};
