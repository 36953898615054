import { useCallback, useEffect } from 'react';
import { onWebSocketError } from '../util/onWebSocketError';
import { useSnackbarAlert } from './useSnackbarAlert';

export const WEBSOCKET_WARNING_SNACKBAR_ID = 'websocket-warning-snackbar';

export const WEBSOCKET_WARNING_MESSAGE =
  'It appears that your network or Firewall settings are preventing websocket connections. Some app features may not work as expected.';

export const useWebSocketWarningSnackbar = () => {
  const { open } = useSnackbarAlert(WEBSOCKET_WARNING_SNACKBAR_ID);

  const showSnackbar = useCallback(() => {
    open({
      message: WEBSOCKET_WARNING_MESSAGE,
      autoHideDuration: null,
    });
  }, [open]);

  useEffect(() => {
    onWebSocketError(showSnackbar);
  }, [showSnackbar]);
};
