import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import type { User as FirebaseUser, ParsedToken } from 'firebase/auth';
import { User } from 'functions/src/types/firestore/User';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import type { Unsubscribe } from 'firebase/firestore';
import {
  setEncryptedRefreshToken,
  unsetEncryptedRefreshToken,
} from '../util/auth/setEncryptedRefreshToken';
import { Optional } from 'utility-types';
import { createAnonymousUser } from '../util/auth/createAnonymousUser';
import { findItem, getItem, setItem, removeItem } from '../util/webStorage';
import { minimizeUserData } from '../util/auth/minimizeUserData';
import { setUserSessionOffline } from '../util/session/setUserSessionOffline';
import { isLoading, Loadable } from '../util/isLoading';
import { onlyIdentified } from '../hooks/auth/onlyIdentified';
import { DEFAULT_DELETED_STRING } from '../../functions/src/util/user/deletedDefaults';

export const FIREBASE_USER_LOCAL_KEY_REGEX = /^firebase:authUser/;
export const USER_DATA_LOCAL_KEY = 'firebase:userData' as const;
export const GUEST_USER_ID = 'guest' as const;

export type FirebaseUserLocal = Optional<
  FirebaseUser,
  | 'providerId'
  | 'metadata'
  | 'refreshToken'
  | 'tenantId'
  | 'delete'
  | 'getIdToken'
  | 'getIdTokenResult'
  | 'reload'
  | 'toJSON'
> & {
  claims?: ParsedToken;
};
//  & {
//   apiKey: string;
//   appName: string;
//   createdAt: string;
//   lastLoginAt: string;
// };

export type AuthContextType = {
  /**
   * @remarks Please only use user if you need access
   * other fields than user.uid. Otherwise, you should
   * use uid.
   */
  user: Loadable<FirebaseUserLocal>;
  userData: Loadable<User<Date>>;
  uid: Loadable<string>;
  /**
   * @remarks Please only use userFull if you need access
   * other fields than userFull.uid. Otherwise, you should
   * use uidFull.
   */
  userFull?: FirebaseUserLocal;
  userDataFull?: User<Date>;
  uidFull?: string;
  isUserDeleted?: boolean;
  isSubscribingUserData?: boolean;
};

export type AuthProviderProps = {
  children: ReactElement;
};

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  userData: undefined,
  uid: undefined,
  userFull: undefined,
  userDataFull: undefined,
  uidFull: undefined,
  isUserDeleted: undefined,
  isSubscribingUserData: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProviderUnmemoized = ({ children }: AuthProviderProps) => {
  const [userInternal, setUserInternal] = useState<Loadable<FirebaseUserLocal>>(
    findItem(FIREBASE_USER_LOCAL_KEY_REGEX) || undefined,
  );

  // If/when we don't have to setUserSessionOffline manually,
  // this won't need a dependency, which will eliminate
  // unnecessary re-renders of subscribeIdTokenChange
  const removeUser = useCallback(async () => {
    const oldId = isLoading(userInternal) ? undefined : userInternal?.uid;

    setUserInternal(null);

    if (oldId) {
      await setUserSessionOffline(oldId);
    }
  }, [userInternal]);

  const [userDataInternal, setUserDataInternal] = useState<
    Loadable<User<Date>>
  >(getItem(USER_DATA_LOCAL_KEY) || undefined);
  useEffect(() => {
    if (userDataInternal === null) {
      removeItem(USER_DATA_LOCAL_KEY);
    }
  }, [userDataInternal]);

  const unsubscribeUserDataInternalRef = useRef<Unsubscribe | null>(null);
  const unsubscribeUserDataInternal = useCallback(() => {
    if (unsubscribeUserDataInternalRef.current) {
      unsubscribeUserDataInternalRef.current();
      unsubscribeUserDataInternalRef.current = null;
    }
  }, []);
  const subscribeUserDataInternal = useCallback(
    async (userId: string) => {
      unsubscribeUserDataInternal();
      const firestoreImport = import('../config/firebase-client/firestore');
      const { firestore } = await firestoreImport;
      const firebaseFirestoreImport = import('firebase/firestore');
      const { doc, onSnapshot } = await firebaseFirestoreImport;

      unsubscribeUserDataInternalRef.current = onSnapshot(
        doc(firestore, `User/${userId}`).withConverter<User<Date>>(
          ConverterFactory.buildDateConverter(),
        ),
        (updatedDoc) => {
          const userUpdated = updatedDoc.data();

          if (!userUpdated?.id) {
            // Waiting for auth-onUserCreate to finish running...
            return;
          }

          setUserDataInternal(userUpdated);
          try {
            const userDataMinimum = minimizeUserData(userUpdated);
            setItem(USER_DATA_LOCAL_KEY, userDataMinimum);
          } catch (error) {
            console.error('Failed to set user data in web storage', error);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [unsubscribeUserDataInternal],
  );

  useEffect(() => {
    if (userInternal === null) {
      unsetEncryptedRefreshToken();

      setUserDataInternal(null);

      unsubscribeUserDataInternal();

      createAnonymousUser();
    } else if (!isLoading(userInternal)) {
      setEncryptedRefreshToken();

      subscribeUserDataInternal(userInternal.uid);
    }
  }, [subscribeUserDataInternal, unsubscribeUserDataInternal, userInternal]);

  const subscribeIdTokenChange = useCallback(async () => {
    const authImport = import('../config/firebase-client/auth');
    const { auth } = await authImport;
    const firebaseAuthImport = import('firebase/auth');
    const { onIdTokenChanged } = await firebaseAuthImport;

    return onIdTokenChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        await removeUser();
        return;
      }
      const tokenResult = await firebaseUser.getIdTokenResult();
      const claims = tokenResult.claims;

      setUserInternal((prevUser) => {
        if (
          !prevUser ||
          prevUser.uid !== firebaseUser.uid ||
          JSON.stringify(prevUser.claims) !== JSON.stringify(claims)
        ) {
          return { ...firebaseUser, claims };
        }
        return prevUser;
      });
    });
  }, [removeUser]);

  useEffect(() => {
    const unsubscribePromise = subscribeIdTokenChange();
    return () => {
      unsubscribeUserDataInternal();
      unsubscribePromise.then((unsubscribe) => {
        return unsubscribe();
      });
    };
  }, [subscribeIdTokenChange, unsubscribeUserDataInternal]);

  const user = useMemo(() => {
    return onlyIdentified(userInternal);
  }, [userInternal]);

  const userData = useMemo(() => {
    return onlyIdentified(userDataInternal);
  }, [userDataInternal]);

  const uid = useMemo(() => {
    if (isLoading(user)) {
      return undefined;
    }
    if (user === null) {
      return null;
    }
    return user.uid;
  }, [user]);

  const userFull = useMemo(() => {
    return userInternal || undefined;
  }, [userInternal]);

  const userDataFull = useMemo(() => {
    return userDataInternal || undefined;
  }, [userDataInternal]);

  const uidFull = useMemo(() => {
    return userFull?.uid;
  }, [userFull]);

  const isUserDeleted = useMemo(() => {
    return userData?.username === DEFAULT_DELETED_STRING;
  }, [userData?.username]);

  const isSubscribingUserData = useMemo(() => {
    return !!uid && isLoading(userData);
  }, [userData, uid]);

  const userIncomplete = useMemo(() => {
    return !!user && userData === null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!user, userData]);

  useEffect(() => {
    if (!userIncomplete) {
      return;
    }
    const handler = async () => {
      const { backfillUser } = await import(
        '../firebaseCloud/user/backfillUser'
      );
      await backfillUser();
    };
    handler();
  }, [userIncomplete]);

  const value = useMemo(() => {
    return {
      user,
      userData,
      uid,
      userFull,
      userDataFull,
      uidFull,
      isUserDeleted,
      isSubscribingUserData,
    };
  }, [
    user,
    userData,
    uid,
    userFull,
    userDataFull,
    uidFull,
    isUserDeleted,
    isSubscribingUserData,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthProvider = memo(AuthProviderUnmemoized);
