// Slightly different implementation on backend to avoid importing frontend types
export class CryptoChain {
  private constructor(
    public readonly name: string,
    public readonly chainId: number,
    public readonly coin: string,
    public readonly wrappedCoinAddress: `0x${string}`,
    public readonly coinAddress: `0x${string}` | undefined,
    public readonly explorer: string,
  ) {}

  public static ETH: CryptoChain = new CryptoChain(
    'Ethereum',
    1,
    'ETH',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    undefined,
    'https://etherscan.io',
  );

  public static MATIC: CryptoChain = new CryptoChain(
    'Polygon',
    137,
    'MATIC',
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    'https://polygonscan.com',
  );

  public static BSC: CryptoChain = new CryptoChain(
    'Binance',
    56,
    'BNB',
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
    'https://bscscan.com',
  );

  public static AVAX: CryptoChain = new CryptoChain(
    'Avalanche',
    43114,
    'AVAX',
    '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    undefined,
    'https://snowtrace.io',
  );

  public static FTM: CryptoChain = new CryptoChain(
    'Fantom',
    250,
    'FTM',
    '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    undefined,
    'https://ftmscan.com',
  );

  public static ETH_TESTNET: CryptoChain = new CryptoChain(
    'Ethereum (Goerli)',
    5,
    'ETH',
    '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    undefined,
    'https://goerli.etherscan.io',
  );

  public static MATIC_TESTNET: CryptoChain = new CryptoChain(
    'Polygon (Mumbai)',
    80001,
    'MATIC',
    '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    '0xa108830a23a9a054fff4470a8e6292da0886a4d4',
    'https://mumbai.polygonscan.com',
  );

  public static BSC_TESTNET: CryptoChain = new CryptoChain(
    'Binance (Testnet)',
    97,
    'BNB',
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    '0xfc1c82c5edeb51082cf30fddb434d2cbda1f6924',
    'https://testnet.bscscan.com',
  );

  public static AVAX_TESTNET: CryptoChain = new CryptoChain(
    'Avalanche (Testnet)',
    43113,
    'AVAX',
    '0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3',
    undefined,
    'https://testnet.snowtrace.io',
  );

  public static CHAIN_ID_TO_CHAIN: { [key: number]: CryptoChain } = {
    1: CryptoChain.ETH,
    137: CryptoChain.MATIC,
    56: CryptoChain.BSC,
    43114: CryptoChain.AVAX,
    250: CryptoChain.FTM,
    5: CryptoChain.ETH_TESTNET,
    80001: CryptoChain.MATIC_TESTNET,
    97: CryptoChain.BSC_TESTNET,
    43113: CryptoChain.AVAX_TESTNET,
  };

  public static NAME_TO_CHAIN_ID: { [key: string]: CryptoChain } = {
    eth: CryptoChain.ETH,
    matic: CryptoChain.MATIC,
    bsc: CryptoChain.BSC,
    avax: CryptoChain.AVAX,
    goerli: CryptoChain.ETH_TESTNET,
    ftm: CryptoChain.FTM,
    fuji: CryptoChain.AVAX_TESTNET,
  };

  public static from(chainId: string | number) {
    if (typeof chainId === 'string') {
      return CryptoChain.CHAIN_ID_TO_CHAIN[parseInt(chainId)];
    }
    return CryptoChain.CHAIN_ID_TO_CHAIN[Number(chainId)];
  }

  public static fromName(name: string) {
    return CryptoChain.NAME_TO_CHAIN_ID[`${name}`];
  }

  public explorerAddressLink(address: string): string {
    return `${this.explorer}/address/${address}`;
  }

  public explorerTransactionLink(tx: string): string {
    return `${this.explorer}/tx/${tx}`;
  }
}
