import { useMemo } from 'react';
import { Token } from '../../../functions/src/types/firestore/User/Payout';
import { TokenAggregator } from '../../../functions/src/util/payouts/TokenAggregator';

export const useAggregatedTokens = (
  tokens: Token[],
  { withContributors = false }: { withContributors: boolean },
) => {
  return useMemo(() => {
    const aggregator = new TokenAggregator(tokens);
    return withContributors
      ? aggregator.tokensAggregatedWithContributor
      : aggregator.tokensAggregated;
  }, [tokens, withContributors]);
};
