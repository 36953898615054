import { EliminationPlacementStrategy } from './EliminationPlacementStrategy';
import { Required } from 'utility-types';
import { toPayoutIndexSingleElimination } from './SingleEliminationPlacementStrategy';
import {
  Bracket,
  MatchAggregated,
  Round,
} from '../../types/firestore/Game/Tournament/Bracket';
import { isDoubleElimination } from '../../types/firestore/Game/Tournament/Bracket/util/isBracket';

export function toPlacementDoubleElimination(
  bracketPlaceableRoundIndex: number,
): number {
  return (
    2 ** Math.floor(bracketPlaceableRoundIndex / 2) +
    2 ** Math.ceil(bracketPlaceableRoundIndex / 2) +
    1
  );
}

export function toPayoutIndexDoubleElimination(placement: number): number {
  return (
    toPayoutIndexSingleElimination(placement) +
    Math.floor(Math.log2(Math.max(((placement - 1) * 2) / 3, 1)))
  );
}

export class DoubleEliminationPlacementStrategy<
  TTime,
> extends EliminationPlacementStrategy<TTime> {
  constructor(bracket: Bracket<TTime>) {
    super(bracket);
  }

  // DO NOT ABSTRACT OUT. THIS IS COUPLED TO bracketPlaceable.
  // eslint-disable-next-line class-methods-use-this
  public toPlacement(bracketPlaceableRoundIndex: number): number {
    return (
      2 ** Math.floor(bracketPlaceableRoundIndex / 2) +
      2 ** Math.ceil(bracketPlaceableRoundIndex / 2) +
      1
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public toPayoutIndex(placement: number): number {
    return toPayoutIndexDoubleElimination(placement);
  }

  public get finalMatch() {
    const finalMatches = this.finalRound.matches;
    const final = finalMatches[finalMatches.length - 1];

    if (!!final?.team1 && !!final?.team2) {
      return final;
    }
    return finalMatches[0] as MatchAggregated<TTime>;
  }

  public get finalRound(): Required<Round<TTime>, 'matches'> {
    const { grandFinal } = this.bracket;
    const grandFinalRound = grandFinal?.[0];
    return grandFinalRound as Required<Round<TTime>, 'matches'>;
  }

  public get bracketPlaceable() {
    const doubleElimination = isDoubleElimination(this.bracket);
    if (!!doubleElimination) {
      const { bracketLoser } = this.bracket;
      if (!bracketLoser) {
        return [];
      }
      return [...bracketLoser].reverse();
    }
    return [];
  }
}
