import { ComponentProps, useMemo } from 'react';
import { memo } from '../../../util/memo';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../catalogs/VerticalCarousel';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { KeyedNode } from '../../../../functions/src/types/Hit';
import { TournamentCard } from '../../cards/tournament/TournamentCard';
import { SelectableEventWrapper } from '../../calendar/selectable-event/SelectableEventWrapper';
import { EventHit, RenderCard, RenderWrapper } from './EventsCalendar';
import { transformToEventKeyed } from '../../calendar/transformToEventsKeyed';
import { SQUARE_300_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adIds';
import { useTheme } from '@mui/material/styles';

export type EventsVerticalWrapperProps = Omit<
  VerticalCarouselProps,
  'Content'
> & {
  hits: EventHit<Date>[];
  transformHits?: (hits: EventHit<Date>[]) => EventHit<Date>[];
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
  Card?: RenderCard<EventHit<Date>, Date>;
};

const EventsVerticalWrapperUnmemoized = ({
  hits,
  transformHits,
  Card = TournamentCard,
  Wrapper = SelectableEventWrapper,
  ...props
}: EventsVerticalWrapperProps) => {
  const hitsTransformed = useMemo(() => {
    return transformHits ? transformHits(hits) : hits;
  }, [hits, transformHits]);

  const content = useMemo(() => {
    return hitsTransformed
      .map((hitTransformed) => {
        if (
          hitTransformed &&
          typeof hitTransformed === 'object' &&
          'Node' in hitTransformed &&
          'key' in hitTransformed
        ) {
          return hitTransformed as unknown as KeyedNode;
        }
        if (hitTransformed && 'objectID' in hitTransformed) {
          return transformToEventKeyed(hitTransformed, Card, Wrapper);
        }
        return undefined;
      })
      .filter((item): item is KeyedNode => {
        return item !== undefined;
      });
  }, [Card, Wrapper, hitsTransformed]);

  const theme = useTheme();

  return (
    <VerticalCarousel
      Content={content}
      containerSx={{ background: theme.palette.background.elevation[5] }}
      cardSpacing="16px"
      {...props}
    />
  );
};

export const EventsVerticalWrapper = memo(EventsVerticalWrapperUnmemoized);
export const EventsVerticalWrapperAds = withAdInjection({
  WrappedComponent: EventsVerticalWrapper,
  ...SQUARE_300_AD_INJECTION_CONFIG,
  id: CALENDAR_AD,
});

export type CalendarExtensionVerticalAdsProps = ComponentProps<
  typeof EventsVerticalWrapperAds
>;
