import Alert, { AlertProps } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import { memo } from '../util/memo';

export type AlertStandardProps = {
  message: string | ReactNode;
  textColor?: string;
  textSize?: Variant;
} & Omit<AlertProps, 'variant'>;

function AlertStandardUnmemoized({
  message,
  severity,
  textColor = `${severity}.light`,
  textSize = 'subtitle1',
  ...props
}: AlertStandardProps) {
  return (
    <Alert
      severity={severity}
      variant="outlined"
      {...props}
      sx={{ textTransform: 'none', width: '100%' }}
    >
      {typeof message === 'string' ? (
        <Typography
          variant={textSize}
          color={textColor}
          sx={{ my: -1, fontWeight: 500 }}
        >
          {message}
        </Typography>
      ) : (
        message
      )}
    </Alert>
  );
}
export const AlertStandard = memo(AlertStandardUnmemoized);
