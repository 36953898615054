import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { GradientIcon } from '../../gradients/GradientIcon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const LOCK_TEAM_DESCRIPTION =
  'When unlocked, we will fill up empty slots on your team with other random participants.';

function LockTeamTitleUnmemoized() {
  const theme = useTheme();
  return (
    <Stack gap={2} direction={'row'} alignItems={'center'}>
      <Tooltip arrow title={LOCK_TEAM_DESCRIPTION}>
        <Box sx={{ display: 'flex' }}>
          <GradientIcon
            IconComponent={InfoOutlinedIcon}
            sx={{ width: '20px', height: '20px' }}
          />
        </Box>
      </Tooltip>
      <Typography color={theme.palette.primary.main}>Lock Team</Typography>
    </Stack>
  );
}

export const LockTeamTitle = memo(LockTeamTitleUnmemoized);
