import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { v4 as uuid } from 'uuid';

export type FanningPreviewProps = {
  Previews: ReactNode[];
};

const FanningPreviewUnmemoized = ({ Previews }: FanningPreviewProps) => {
  return (
    <Box position="relative">
      {Previews.slice(0, 3).map((Preview, index) => {
        return (
          <Stack
            key={`Preview-${uuid()}`}
            sx={{
              position: index === 0 ? 'relative' : 'absolute',
              bottom: 0,
              alignItems: 'flex-end',
              transform:
                index === 0
                  ? undefined
                  : `rotate(${index * 2}deg) scale(${
                      1 - index * 0.1
                    }) translateX(${index * 24}px)`,
              zIndex: 3 - index,
            }}
          >
            {Preview}
          </Stack>
        );
      })}
    </Box>
  );
};

export const FanningPreview = memo(FanningPreviewUnmemoized);
