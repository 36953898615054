import { TournamentPhase } from '../../types/firestore/Game/Tournament';
import { Team } from '../../types/firestore/Game/Tournament/Guestlist';
import { isPreReady } from '../../types/firestore/Game/Tournament/util';
import { findMemberByUserId } from './findMemberByUserId';

function onlyDefined<T>(arr: Array<T | undefined>): T[] {
  return arr.filter(Boolean) as T[];
}

export const constructPendingInvites = (
  teamsWithInvites: Team<Date>[],
  userId: string,
  phase: TournamentPhase,
  continuousRegistration?: boolean,
) => {
  if (!isPreReady(phase) && !continuousRegistration) {
    return undefined;
  }
  return onlyDefined(
    teamsWithInvites.map((team) => {
      const { members, captainId } = team;
      const currentUser = findMemberByUserId(members, userId);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const captain = findMemberByUserId(members, captainId)!;
      const inviter = findMemberByUserId(members, currentUser?.invitedById);

      return {
        teamId: team.id,
        teammates: team.members.map((member) => {
          return {
            ...member,
            isCurrentUser: member.userId === userId,
          };
        }),
        inviter: inviter || captain,
        teamName: team.name,
        isCaptain: false,
        phase,
      };
    }),
  );
};
