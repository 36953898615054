import { FC } from 'react';
import { memo } from '../../util/memo';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { OutcomeElementProps, OutcomeElement } from '../wizard/OutcomeElement';
import { useWizard } from '../wizard/Wizard';

export type OutcomeSubmitProps = OutcomeElementProps;

const OutcomeSubmitUnmemoized: FC<OutcomeSubmitProps> = ({ ...props }) => {
  const { get } = useWizard<{ success: boolean; message: string }>();
  const message = get('message');
  const success = get('success');
  const icon = (
    <GradientIcon
      IconComponent={success ? CheckCircleOutlineIcon : CancelOutlinedIcon}
      gradientColor={success ? 'secondary.horizontal' : 'error.horizontal'}
      sx={{ fontSize: 128 }}
    />
  );

  return <OutcomeElement image={icon} message={message} {...props} />;
};

export const OutcomeSubmit = memo(OutcomeSubmitUnmemoized);
