import { useCallback, useEffect, useState } from 'react';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : 1920,
      height: isClient ? window.innerHeight : 1080,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = useCallback(() => {
    setWindowSize(getSize());
  }, [getSize]);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, isClient]);

  return windowSize;
}

export function useWindowDimensions(onResize?: () => void) {
  const windowSize = useWindowSize();

  useEffect(() => {
    if (onResize) {
      onResize();
    }
  }, [windowSize, onResize]);

  return windowSize;
}

// export function useWindowSize() {
//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   const handleResize = useCallback(() => {
//     setWindowSize({
//       width: window.innerWidth,
//       height: window.innerHeight,
//     });
//   }, []);

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, [handleResize]);

//   return windowSize;
// }

// export function useWindowDimensions(onResize?: () => void) {
//   const windowSize = useWindowSize();

//   useEffect(() => {
//     if (onResize) {
//       onResize();
//     }
//   }, [windowSize, onResize]);

//   return windowSize;
// }
