import { useCallback } from 'react';
import { useGlobalComponentsContext } from '../../contexts/GlobalComponentsContext';
import { WizardOptions } from '../wizard/createUseWizard';
import { useRedirectCallback } from '../useRedirectCallback';
import {
  NotificationSnackbarCountdown,
  NotificationSnackbarCountdownProps,
} from '../../components/NotificationSnackbarCountdown';

export const useSnackbarCountdown = (
  id: string,
  options: WizardOptions = { closeOnRouteChange: true },
) => {
  const { union, remove } = useGlobalComponentsContext();
  const close = useCallback(() => {
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.closeOnRouteChange ? close : undefined);

  const open = useCallback(
    ({ message, onClose, ...rest }: NotificationSnackbarCountdownProps) => {
      union(
        id,
        <NotificationSnackbarCountdown
          message={message}
          open={true}
          onClose={(event, reason) => {
            if (!!onClose) {
              onClose(event, reason);
            }
            close();
          }}
          ClickAwayListenerProps={{ mouseEvent: false }}
          disableWindowBlurListener
          {...rest}
        />,
      );
    },
    [close, id, union],
  );

  return { open, close };
};
