export const formatPrizeAmount = (
  amount: number,
  options: Intl.NumberFormatOptions = {},
) => {
  return new Intl.NumberFormat(
    typeof window !== 'undefined' ? navigator?.language : undefined,
    {
      notation: 'compact',
      compactDisplay: 'short',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
      ...options,
    },
  ).format(amount);
};
