import { memo } from '../util/memo';
import { LottieLoader, LottieLoaderProps } from './LottieLoader';
import { ReactNode, useMemo, Fragment } from 'react';
import { SxProps } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export type LoadingWrapperProps = LottieLoaderProps & {
  wrapperSx?: SxProps;
  sx?: SxProps;
  isLoading: boolean;
  children?: ReactNode;
};

const LoadingWrapperUnmemoized = ({
  isLoading,
  children,
  wrapperSx,
  sx,
}: LoadingWrapperProps) => {
  const wrapper = useMemo(() => {
    return (
      <Stack sx={wrapperSx}>
        <LottieLoader sx={sx} />
      </Stack>
    );
  }, [sx, wrapperSx]);

  if (isLoading) {
    return wrapper;
  }

  return <Fragment>{children}</Fragment>;
};

export const LoadingWrapper = memo(LoadingWrapperUnmemoized);
