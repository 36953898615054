import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import {
  INVALID_INVITE_TOKEN,
  INVITER_NOT_ON_TEAM,
} from '../../../functions/src/util/error/library/rsvpTeam';
import { TeammateProps } from '../../components/tournaments/registration/teammates/TeammatesTable';

export const toInviterDetails = (
  inviterId: string,
  allTeams: Team<Date>[],
  uid?: string,
) => {
  const inviterTeam = allTeams.find((team) => {
    const { members } = team;
    return members.some((member) => {
      const { userId } = member;
      return userId === inviterId;
    });
  });
  if (!inviterTeam) {
    throw new Error(`${INVALID_INVITE_TOKEN}.`);
  }
  const { name: teamName, members: teamMembers, id } = inviterTeam;
  const teammates = [...teamMembers].map((member) => {
    const { status, username, userId, checkedIn, imgUrl } = member;
    const isCurrentUser = userId === uid;
    return {
      userId,
      username,
      status,
      checkedIn,
      imgUrl,
      isCurrentUser,
    } as TeammateProps;
  });
  const inviter = teamMembers.find((member) => {
    const { userId } = member;
    return userId === inviterId;
  });

  if (!inviter) {
    throw new Error(`${INVITER_NOT_ON_TEAM}.`);
  }

  return { teammates, id, teamName, inviter };
};
