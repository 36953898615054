import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
import { AuthFlowStore } from '../../hooks/auth/useAuthFlowBase';
import { useRemoveAuthQueryParams } from '../../hooks/useRemoveAuthQueryParams';
import { useWizard } from '../wizard/Wizard';
import { AuthenticationOptionsBase } from './AuthenticationOptionsBase';
import Stack from '@mui/material/Stack';
import { AuthenticationActionable } from './AuthenticationActionable';
import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import { AlertStandard } from '../AlertStandard';
import Box from '@mui/material/Box';

export type AuthenticationStackBaseProps = {
  title: string;
  subtitle?: string | ReactNode;
  footer?: ReactNode;
};

const AuthenticationStackBaseUnmemoized = ({
  title,
  subtitle,
  footer,
}: AuthenticationStackBaseProps) => {
  const { removeAuthQueryParams } = useRemoveAuthQueryParams();
  const { errorMessage } = useAuthSubmit();
  const { go, reset } = useWizard<AuthFlowStore>();
  const genericErrorMessage = Object.values(errorMessage).find((message) => {
    return message !== undefined;
  });

  return (
    <AuthenticationOptionsBase footer={footer}>
      <Stack spacing={2}>
        <AuthenticationActionable
          onClose={() => {
            removeAuthQueryParams();
            reset();
            go(undefined);
          }}
          title={title}
          subtitle={subtitle}
        />
        {!!genericErrorMessage && (
          <Box sx={{ pt: 4 }}>
            <AlertStandard severity="error" message={genericErrorMessage} />
          </Box>
        )}
      </Stack>
    </AuthenticationOptionsBase>
  );
};

export const AuthenticationStackBase = memo(AuthenticationStackBaseUnmemoized);
