import { memo } from '../../../util/memo';
import {
  AllSignInMethod,
  UserProviderInfo,
} from '../../../../functions/src/types/firestore/User';
import { extractProviderId } from '../../../../functions/src/util/auth/extractProviderIds';
import { SelectableRegistrationOption } from './SelectableRegistrationOption';
import { extractProviderLabel } from '../../../../functions/src/util/tournaments/extractProviderLabel';
import { AuthOptionsUnconnected } from './AuthOptionsUnconnected';

export type SelectableAuthOptionsProps = {
  currentlyConnected: UserProviderInfo[];
  providers: AllSignInMethod[];
  selectAuthProvider: (providerId: string, uid: string) => void;
  checkSelected: (providerId: string, uid: string) => boolean;
};

export const SelectableAuthOptions = memo(
  function SelectableAuthOptionsUnmemoized({
    currentlyConnected,
    selectAuthProvider,
    providers,
    checkSelected,
  }: SelectableAuthOptionsProps) {
    return (
      <>
        {!!currentlyConnected.length &&
          currentlyConnected.map((provider) => {
            const { providerId, uid } = provider;
            const providerExtracted = extractProviderId(
              providerId,
            ) as AllSignInMethod;
            const value = extractProviderLabel(provider);
            return (
              <SelectableRegistrationOption
                key={`${providerExtracted}-${value}`}
                provider={providerExtracted}
                value={value}
                onSelect={() => {
                  return selectAuthProvider(providerExtracted, uid);
                }}
                isSelected={checkSelected(providerId, uid)}
              />
            );
          })}
        <AuthOptionsUnconnected providers={providers as AllSignInMethod[]} />
      </>
    );
  },
);
