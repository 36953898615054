import { SetShipParams } from '../../../functions/src/callable/ship/setShip.f';

export type RemoveShipParams = Pick<
  SetShipParams,
  'userId' | 'groupId' | 'groupType' | 'subgroupId'
>;

export const removeShip = async (props: RemoveShipParams) => {
  const { setShip } = await import('../../firebaseCloud/ship/setShip');
  const params: SetShipParams = {
    ...props,
    action: 'remove',
  };

  await setShip(params);
};
