import { useMemo } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { memo } from '../../../util/memo';

export type SelectInputBasicProps<
  TValue extends string | number | symbol = string | number | symbol,
> = Omit<SelectProps, 'label'> & {
  values: TValue[];
  label?: string;
  valueDisplayedMap?: Record<TValue, string>;
  helperText?: string;
};

export const SelectInputBasic = memo(function SelectInputBasicUnmemoized({
  values,
  label,
  valueDisplayedMap,
  helperText,
  ...selectProps
}: SelectInputBasicProps) {
  const { sx } = selectProps;
  const options = useMemo(() => {
    return values.map((potentialValue) => {
      return (
        <MenuItem
          key={String(potentialValue)}
          value={String(potentialValue)}
          sx={sx}
        >
          {String(
            valueDisplayedMap?.[String(potentialValue)] || potentialValue,
          )}
        </MenuItem>
      );
    });
  }, [sx, valueDisplayedMap, values]);

  return (
    <>
      <Select
        variant="filled"
        {...selectProps}
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {options}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
});
