import { ConnectionState } from 'livekit-client';
import { VoiceChatMode } from '../../contexts/RoomContext';
import { useCallback } from 'react';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';

export const useConnectedRoom = () => {
  const room = useLivekitRoom();
  const { state, name } = room;

  const isConnectedRoom = useCallback(
    (mode: VoiceChatMode) => {
      return state === ConnectionState['Connected'] && name?.includes(mode);
    },
    [state, name],
  );

  return { isConnectedRoom };
};
