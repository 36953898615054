import { useEffect, useState } from 'react';
import { useDateFormatter } from './useDateFormatter';
import { NUMERIC_TIMEZONE } from 'src/util/dates/presets/dates';

export function useLocalizedMarkdownDate(text: string) {
  const [textModified, setTextModified] = useState(text);

  const formatDate = useDateFormatter({
    dateFormat: NUMERIC_TIMEZONE,
    separator: ', ',
  });

  useEffect(() => {
    const handler = async () => {
      const { strict } = await import('chrono-node');
      const parsedDates = strict.parse(text);

      const filteredDates = parsedDates.filter((date) => {
        return !/(minute|hour|day|second|year)s?/i.test(date.text);
      });

      const regexStrUntruncated = filteredDates
        .map((date) => {
          return date.text;
        })
        .reduce((regex, dateStr) => {
          return regex + `(${dateStr})|`;
        }, '');

      const regexStr = regexStrUntruncated.substring(
        0,
        regexStrUntruncated.length - 1,
      );

      if (!regexStr) {
        setTextModified(text);
        return;
      }

      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(regexStr, 'g');

      const textModifiedNew = text.replaceAll(regex, (date: string) => {
        const dateParsed = strict.parseDate(date);
        return dateParsed ? formatDate(dateParsed) : date;
      });

      setTextModified(textModifiedNew);
    };

    handler();
  }, [formatDate, text]);

  return textModified;
}
