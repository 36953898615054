export const generateRowData = (team1, team2) => {
  const length = Math.max(
    team1?.members.length || 0,
    team2?.members.length || 0,
  );

  return [...new Array(length).keys()].map((i) => {
    return {
      team1Member: team1?.members[Number(i)],
      team2Member: team2?.members[Number(i)],
    };
  });
};
