import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useGlobalComponentsContext } from '../../contexts/GlobalComponentsContext';
import { useTheme } from '@mui/material/styles';
import { ContentCarousel } from '../../components/ContentCarousel';
import { ReactNode, useCallback } from 'react';
import { useKeypressListener } from '../useKeypressListener';

export const FULLSCREEN_CAROUSEL_ID = 'FULLSCREEN_CAROUSEL' as const;

export type PreviewFullscreenParams = {
  FullscreenContent: ReactNode[];
};

export const useFullscreenPreview = () => {
  const { union, remove } = useGlobalComponentsContext();
  const theme = useTheme();

  const removeContent = useCallback(() => {
    return remove(FULLSCREEN_CAROUSEL_ID);
  }, [remove]);

  useKeypressListener(['Escape'], removeContent);

  const previewFullscreen = ({
    FullscreenContent,
  }: PreviewFullscreenParams) => {
    union(
      FULLSCREEN_CAROUSEL_ID,
      <Stack
        direction="column"
        sx={{
          position: 'fixed',
          top: 0,
          height: '100%',
          width: '100%',
          opacity: 1,
          transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          background: 'rgba(0, 0, 0, 0.8)',
          zIndex: theme.zIndex.modal,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ p: 4 }}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              background: theme.palette.primary.main,
              color: theme.palette.text.primary,
              borderRadius: '100px',
              zIndex: theme.zIndex.modal + 1,
              '&:hover': {
                background: theme.palette.primary.main,
                borderRadius: '100px',
              },
            }}
            onClick={removeContent}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <ContentCarousel
          content={FullscreenContent}
          sx={{ img: { p: '16px 24px !important' } }}
        />
      </Stack>,
    );
  };
  return { previewFullscreen };
};
