import { useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { KeyedNode } from '../../../functions/src/types/Hit';
import {
  useNearEndObserver,
  UseNearEndObserverOptions,
} from '../visibility/useNearEndObserver';
import { useLoadingWrapper } from '../useLoadingWrapper';
import Stack from '@mui/material/Stack';

export const PLACEHOLDER_KEY = 'placeholder' as const;

export type NearEndContentOptions = Omit<
  UseNearEndObserverOptions,
  'onNearEnd'
> & {
  Content: KeyedNode[];
  /**
   * @remarks NOTE be careful to memoize onNearEnd!
   */
  onNearEnd?: () => Promise<unknown>;
  /**
   * @remarks NOTE be careful to memoize wrapperProps!
   *
   * @param key the key of the wrapped element in Content
   * @param index the index of the wrapped element in Content
   * @returns props to pass to the wrapped element
   */
  wrapperProps?: BoxProps | ((key: string, index: number) => BoxProps);
};

export const useNearEndContent = ({
  Content,
  onNearEnd,
  wrapperProps,
  ...observerProps
}: NearEndContentOptions) => {
  const {
    LoadingWrapper,
    isLoading,
    funcWrapped: onNearEndWrapped,
  } = useLoadingWrapper(onNearEnd);

  const { setNearEnd } = useNearEndObserver({
    onNearEnd: onNearEndWrapped,
    ...observerProps,
  });

  const NearEndContentWithoutLoading = useMemo(() => {
    return Content.map(({ key, Node }, index) => {
      const isNearEndTrigger =
        index === Content.length - 1 && key !== PLACEHOLDER_KEY;
      const props =
        typeof wrapperProps === 'function'
          ? wrapperProps(key, index)
          : wrapperProps;

      return (
        <Box
          key={key}
          ref={isNearEndTrigger ? setNearEnd : undefined}
          {...props}
        >
          {Node}
        </Box>
      );
    });
  }, [Content, setNearEnd, wrapperProps]);

  const loadingFooter = useMemo(() => {
    return (
      <Stack
        key="loading"
        sx={{
          minHeight: '46px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          bottom: 0,
          position: 'sticky',
          opacity: 0.5,
        }}
      >
        <LoadingWrapper sx={{ height: '32px', width: '32px' }} />
      </Stack>
    );
  }, [LoadingWrapper]);

  const NearEndContent = useMemo(() => {
    return [...NearEndContentWithoutLoading, loadingFooter];
  }, [NearEndContentWithoutLoading, loadingFooter]);

  return {
    NearEndContent,
    isLoading,
  };
};
