export const updateSessionStatus = async (
  uid: string,
  status: string,
  sid?: string,
) => {
  if (!sid) {
    return;
  }

  const { database } = await import('../../config/firebase-client/database');
  const { ref, serverTimestamp, set, onDisconnect } = await import(
    'firebase/database'
  );

  const sessionRef = ref(database, `/session/${uid}/${sid}`);
  const statusData = {
    status,
    lastChanged: serverTimestamp(),
  };

  if (status === 'online') {
    await onDisconnect(sessionRef).set({
      status: 'offline',
      lastChanged: serverTimestamp(),
    });
  }
  await set(sessionRef, statusData);
};
