import { FC, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { useWizard } from '../../wizard/Wizard';
import { DialogNavbar } from '../../dialog/DialogNavbar';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import {
  ActionButtonProps,
  DialogActionsStandard,
} from '../../dialog/DialogActionsStandard';

export type EventFlowBaseProps = {
  title: string;
  subtitle?: ReactNode;
  Content: ReactNode;
  continueButtonProps?: ActionButtonProps;
};

export const EventFlowBase: FC<EventFlowBaseProps> = memo(
  function EventFlowBaseUnmemoized({
    title,
    subtitle,
    Content,
    continueButtonProps,
  }) {
    const { go, prev } = useWizard();
    const buttons: ActionButtonProps[] | undefined = useMemo(() => {
      if (!continueButtonProps) {
        return;
      }
      return [
        {
          ...continueButtonProps,
        },
      ];
    }, [continueButtonProps]);
    return (
      <Fade in timeout={{ enter: 250 }}>
        <Box minWidth="100%" maxWidth="100%">
          {prev && (
            <DialogNavbar
              goBack={() => {
                go(prev);
              }}
              onClose={() => {
                go(undefined);
              }}
            />
          )}
          <DialogBodyStandard title={title} description={subtitle}>
            {Content}
            {buttons?.length && <DialogActionsStandard buttons={buttons} />}
          </DialogBodyStandard>
        </Box>
      </Fade>
    );
  },
);
