import { ReactNode, useCallback } from 'react';
import { memo } from '../../util/memo';
import { EventFlowBase } from '../../components/organizer/new-event-wizard/EventFlowBase';
import { useWizardDialog } from '../wizard/useWizardDialog';
import {
  LinkPrizePoolProps as LinkPrizePoolProviderProps,
  LinkPrizePoolProvider,
  useLinkPrizePoolContext,
} from '../../contexts/organizer/LinkPrizePoolContext';
import {
  LinkEventProvider,
  LinkEventProps as LinkEventProviderProps,
  useLinkEventContext,
} from '../../contexts/organizer/LinkEventContext';
import { EventDocument } from '../../contexts/organizer/create-event/CreateEventContext';
import {
  RequireOnlyOne,
  Resolve,
} from '../../../functions/src/types/utility-types';
import { isPrizePoolDivisible } from '../../../functions/src/util/payouts/isPrizePoolDivisible';
import { useWizard } from '../../components/wizard/Wizard';
import { EditableStepsContextType } from '../../contexts/organizer/EditableStepsContext';

const LINK_DIALOG_COPY_MAP = {
  event: {
    linking: {
      title: `Link Event`,
      description: `Select an existing event to link to your prize pool.`,
    },
    changingLink: {
      title: `Change Linked Event`,
      description: `Choose a different event to link to your prize pool`,
    },
  },
  prizePool: {
    linking: {
      title: `Link Prize Pool`,
      description: `Select an existing prize pool to link to your event.`,
    },
    changingLink: {
      title: `Change Linked Prize Pool`,
      description: `Choose a different prize pool to link to your event`,
    },
  },
};

export type UseLinkDialogProps = Resolve<{
  linkComponents: RequireOnlyOne<
    {
      event: ReactNode;
      prizePool: ReactNode;
    },
    'event' | 'prizePool'
  >;
  isChangingLink: boolean;
  updateField?: EditableStepsContextType<any>['updateField'];
}>;

type LinkToProps = {
  title: string;
  description: string;
  LinkComponent: ReactNode;
};

const LinkToEvent = memo(function LinkToEventUnmemoized({
  title,
  description,
  LinkComponent,
}: LinkToProps) {
  const { selectedEvent, linkEvent } = useLinkPrizePoolContext();
  return (
    <EventFlowBase
      title={title}
      subtitle={description}
      Content={LinkComponent}
      continueButtonProps={{
        isAsync: true,
        onClick: linkEvent,
        disabled: !selectedEvent?.id,
        children: 'Link',
      }}
    />
  );
});

const LinkToPrizePool = memo(function LinkToPrizePoolUnmemoized({
  title,
  description,
  LinkComponent,
}: LinkToProps) {
  const { selectedPrizePool, linkPrizePool } = useLinkEventContext();
  const { go } = useWizard();
  return (
    <EventFlowBase
      title={title}
      subtitle={description}
      Content={LinkComponent}
      continueButtonProps={{
        isAsync: true,
        disabled: !selectedPrizePool?.id,
        onClick: async () => {
          try {
            await linkPrizePool();
            go(undefined);
          } catch (error) {
            console.error(error);
          }
        },
        children: 'Link',
      }}
    />
  );
});

export const useLinkDialog = <TEvent extends EventDocument>({
  linkComponents,
  isChangingLink,
  updateField,
}: UseLinkDialogProps) => {
  const { open: openLinkDialog } = useWizardDialog();

  const linkToEvent = useCallback(
    ({ prizePool }: LinkPrizePoolProviderProps) => {
      const { title, description } =
        LINK_DIALOG_COPY_MAP['event'][
          isChangingLink ? 'changingLink' : 'linking'
        ];
      openLinkDialog({
        storeDefault: {},
        elements: {
          linkPrizePool: (
            <LinkPrizePoolProvider
              isChangingLink={isChangingLink}
              prizePool={prizePool}
            >
              <LinkToEvent
                title={title}
                description={description}
                LinkComponent={linkComponents.event}
              />
            </LinkPrizePoolProvider>
          ),
        },
        elementIdEntry: 'linkPrizePool',
        wrapperProps: { showCloseIcon: false },
      });
    },
    [isChangingLink, openLinkDialog, linkComponents.event],
  );

  const linkToPrizePool = useCallback(
    ({ prizePools, event }: LinkEventProviderProps<TEvent>) => {
      const { title, description } =
        LINK_DIALOG_COPY_MAP['prizePool'][
          isChangingLink ? 'changingLink' : 'linking'
        ];
      openLinkDialog({
        storeDefault: {},
        elements: {
          linkEvent: (
            <LinkEventProvider
              event={event as TEvent}
              prizePools={prizePools.filter((pool) => {
                return (
                  !pool.linkedId &&
                  pool.prizes.every((prize) => {
                    return prize.contributor.stage === 'transferred';
                  })
                );
              })}
              updateField={updateField}
              isChangingLink={isChangingLink}
              linkValidator={(event, prizes) => {
                const isValid = isPrizePoolDivisible(prizes, event.maxTeamSize);
                return {
                  isValid,
                  errorMessage: isValid
                    ? undefined
                    : 'Prize pool is not divisible by team size',
                };
              }}
            >
              <LinkToPrizePool
                title={title}
                description={description}
                LinkComponent={linkComponents.prizePool}
              />
            </LinkEventProvider>
          ),
        },
        elementIdEntry: 'linkEvent',
        wrapperProps: { showCloseIcon: false },
      });
    },
    [isChangingLink, openLinkDialog, updateField, linkComponents.prizePool],
  );

  return { linkToEvent, linkToPrizePool };
};
