import { RequireOnlyOne } from '../../types/utility-types';
import { offsetUtc } from '../time/offsetUtc';

export type FormatDateTimeOptions = {
  dateFormat?: Intl.DateTimeFormatOptions | false;
  timeFormat?: Intl.DateTimeFormatOptions | false;
  separator?: string;
};

export type ToDateStringSettings = Partial<
  RequireOnlyOne<{
    locale: string;
    utcOffsetHours: number;
  }>
>;

export function toDateString(
  date: Date,
  { locale, utcOffsetHours: utcOffset }: ToDateStringSettings = {},
) {
  if (locale) {
    const formatter = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const parts = formatter.formatToParts(date);

    const dateParts = parts.reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {} as { [key: string]: string });

    return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
  }
  const dateOffset = utcOffset ? offsetUtc(date, utcOffset) : date;
  const year = dateOffset.getFullYear();
  const month = dateOffset.getMonth() + 1;
  const day = dateOffset.getDate();

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;

  return formattedDate;
}

export const shiftDate = (date: Date, offsetDays: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offsetDays);
  return newDate;
};
