import { useCollectionSnapshot } from '../firestore/useCollectionSnapshot';
import { Subgroup } from '../../../functions/src/types/firestore/Guild/Subgroup';
import { useGroupBasic } from './useGroupBasic';

export function useGroupSubgroups() {
  const { groupType, groupId } = useGroupBasic();
  const subgroups = useCollectionSnapshot<Subgroup>(
    `${groupType}/${groupId}/Subgroup`,
  );

  return subgroups.map((subgroup) => {
    return {
      username: subgroup.username,
      groupNumber: subgroup.groupNumber,
    };
  });
}
