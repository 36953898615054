import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { GradientIcon } from './gradients/GradientIcon';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { memo } from '../util/memo';
import { SxProps, useTheme } from '@mui/material/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { GliderMethods, GliderProps } from './internal-react-glider/types';
import { GliderComponent } from './internal-react-glider/GliderComponent';

export type GliderContainer = GliderMethods & {
  setCursor: (newCursor: number, behavior?: ScrollBehavior) => void;
  shiftCursor: (offset: number, behavior?: ScrollBehavior) => void;
};

export type ReactGliderContainer = GliderContainer & {
  cursor?: number;
};

export type ReactGliderProps = Omit<GliderProps, 'scrollToSlide'> & {
  itemWidth?: number;
  hasArrows: boolean;
  navGradientColor?: string;
  children?: JSX.Element | JSX.Element[];
  gliderRef?: RefObject<ReactGliderContainer>;
  onElementMount?: (element: HTMLDivElement) => void;
  sxIconLeft?: SxProps & object;
  sxIconRight?: SxProps & object;
  formatContainer?: boolean;
  trackStyle?: React.CSSProperties;
  cursor?: number;
  onCursorChange?: (cursor: number) => void;
};

export const ReactGlider = memo(function ReactGliderUnmemoized(
  props: ReactGliderProps,
) {
  const {
    gliderRef,
    onElementMount,
    children,
    itemWidth,
    hasArrows,
    navGradientColor,
    sxIconLeft,
    sxIconRight,
    trackStyle,
    onCursorChange,
    slidesToShow = 'auto',
    cursor = 0,
    formatContainer = false,
    hasDots = false,
    duration = 1,
    ...gliderProps
  } = props;
  const theme = useTheme();

  const uniqueClass = useMemo(() => {
    return `glider-${Math.floor(Math.random() * 1e18)}`;
  }, []);

  const [gliderContainer, setGliderContainer] = useState<HTMLDivElement | null>(
    null,
  );
  useEffect(() => {
    const div = document.querySelector(`.${uniqueClass}`);
    if (!(div instanceof HTMLDivElement)) {
      return;
    }
    setGliderContainer(div);
    onElementMount?.(div);
  }, [onElementMount, uniqueClass]);

  const setCursor = useCallback(
    (newCursor: number, _behavior: ScrollBehavior = 'smooth') => {
      gliderRef?.current?.scrollItem(newCursor);
    },
    [gliderRef],
  );

  const [cursorInternal, setCursorInternal] = useState<number>(cursor);

  const shiftCursor = useCallback(
    (offset: number, behavior: ScrollBehavior = 'smooth') => {
      setCursor(cursorInternal + offset, behavior);
    },
    [setCursor, cursorInternal],
  );

  useEffect(() => {
    if (gliderRef && gliderRef.current) {
      gliderRef.current['setCursor'] = setCursor;
      gliderRef.current['shiftCursor'] = shiftCursor;
    }
  }, [gliderRef, setCursor, shiftCursor]);

  useEffect(() => {
    if (gliderRef?.current) {
      gliderRef.current.cursor = cursorInternal;
    }
    if (onCursorChange) {
      onCursorChange(cursorInternal);
    }
  }, [cursorInternal, gliderRef, onCursorChange]);

  useEffect(() => {
    if (!gliderContainer) return;

    const trackElement = gliderContainer.children.item(0);
    if (!trackElement) return;

    const childFirst = trackElement.children.item(0);
    if (!childFirst) {
      return;
    }

    const updateCursor = () => {
      const trackChildren = Array.from(trackElement.children);
      const activeElement = trackChildren.findIndex((child: Element) => {
        return child.classList.contains('active');
      });

      if (activeElement !== -1 && activeElement !== cursorInternal) {
        setCursorInternal(activeElement);
      }
    };

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          updateCursor();
        }
      }
    });

    observer.observe(childFirst, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => {
      observer.disconnect();
    };
  }, [gliderContainer, cursorInternal, children]);

  // eslint-disable-next-line @blumintinc/blumint/require-memo
  const createIcon = useCallback(
    (
      IconComponent: OverridableComponent<
        SvgIconTypeMap<SxProps & object, 'svg'>
      >,
      gradientColor: string | undefined,
      additionalStyles?: object,
    ) => {
      return gradientColor ? (
        <GradientIcon
          IconComponent={IconComponent}
          gradientColor={gradientColor}
          sx={{
            width: '50px',
            height: '50px',
            filter: `drop-shadow(${theme.shadowsHard.primaryElevation2})`,
            ...additionalStyles,
          }}
        />
      ) : (
        <IconComponent fontSize={'large'} />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const iconLeft = useMemo(() => {
    return createIcon(ArrowBackIosRoundedIcon, navGradientColor, sxIconLeft);
  }, [createIcon, navGradientColor, sxIconLeft]);

  const iconRight = useMemo(() => {
    return createIcon(
      ArrowForwardIosRoundedIcon,
      navGradientColor,
      sxIconRight,
    );
  }, [createIcon, navGradientColor, sxIconRight]);

  return useMemo(() => {
    return (
      <GliderComponent
        ref={gliderRef}
        className={`glider-children-container ${
          formatContainer ? 'glider-container' : ''
        } ${uniqueClass}`}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        itemWidth={itemWidth}
        exactWidth={true}
        draggable={true}
        hasArrows={hasArrows}
        rewind={true}
        duration={duration}
        scrollLock={false}
        dragVelocity={1}
        iconLeft={iconLeft}
        iconRight={iconRight}
        scrollToSlide={cursor}
        hasDots={hasDots}
        scrollPropagate={false}
        {...gliderProps}
      >
        {children}
      </GliderComponent>
    );
  }, [
    gliderRef,
    formatContainer,
    uniqueClass,
    slidesToShow,
    itemWidth,
    hasArrows,
    duration,
    iconLeft,
    iconRight,
    cursor,
    hasDots,
    gliderProps,
    children,
  ]);
});
