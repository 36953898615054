import { useEffect, useState } from 'react';
import { DatabaseReference } from 'firebase/database';

export const useCallerCount = (roomRefs: (DatabaseReference | undefined)[]) => {
  const [count, setCount] = useState<number | 'unknown'>('unknown');

  useEffect(() => {
    const unsubscribes: (() => void)[] = [];

    const handler = async () => {
      const refs = Array.isArray(roomRefs) ? roomRefs : [roomRefs];
      if (!refs.length) {
        setCount(0);
        return;
      }

      const { onValue, child } = await import('firebase/database');

      let totalCount = 0;

      refs.forEach((roomRef) => {
        if (!roomRef) {
          return;
        }

        const callerCountRef = child(roomRef, 'callerCount');

        const unsubscribe = onValue(callerCountRef, (snapshot) => {
          if (!snapshot.exists()) {
            return setCount(0);
          }

          const value = snapshot.val();
          totalCount += value;
          setCount(totalCount);
        });

        unsubscribes.push(unsubscribe);
      });
    };

    handler();

    return () => {
      unsubscribes.forEach((unsubscribe) => {
        return unsubscribe();
      });
    };
  }, [roomRefs]);

  return count;
};
