import axios from 'axios';

// TODO: originally I had these endpoints by region as I was
// doing something akin to Promise.race() for the first successful
// response. But this got messy; we would need to have cancellable
// promises that cancel when the user input is changed.
export const WAX_GET_ACCOUNT_ENDPOINTS = [
  'https://wax.blokcrafters.io', //us east
  'https://wax-hyperion.wizardsguild.one', //us west
  'https://apiwax.3dkrender.com', //eu central
  'https://wax.cryptolions.io', //asia west
];

export type VerifyWaxWalletParams = {
  waxAddress: string;
};

export const verifyWaxWallet = async ({
  waxAddress,
}: VerifyWaxWalletParams) => {
  const promises = WAX_GET_ACCOUNT_ENDPOINTS.map(async (endpoint) => {
    try {
      const response = await axios.get(`${endpoint}/v2/state/get_account`, {
        params: {
          limit: 1,
          account: waxAddress,
        },
      });

      if (
        response.data.account &&
        Object.keys(response.data.account).length > 1
      ) {
        return response.data.account;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  });

  try {
    const res = await Promise.all(promises);
    if (
      res.some((response) => {
        return response !== null;
      })
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
