import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { memo } from '../../util/memo';
import { GradientIconButton } from '../gradients/GradientIconButton';

export type DialogBackButtonProps = {
  goBack: () => void;
};

export const DialogBackButton = memo(function DialogBackButtonUnmemoized({
  goBack,
}: DialogBackButtonProps) {
  if (!goBack) return null;

  return (
    <GradientIconButton
      onClick={goBack}
      IconComponent={ArrowBackIcon}
      gradientColor={'primary.horizontal'}
      edge="start"
    />
  );
});
