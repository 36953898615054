import { memo } from '../util/memo';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { CountdownTimer, CountdownTimerProps } from './CountdownTimer';
import { useTheme } from '@mui/material/styles';
import Slide, { SlideProps } from '@mui/material/Slide';
import { withOpacity } from '../util/styles/withOpacity';

export const SlideTransition = memo(function SlideTransitionUnmemoized(
  props: SlideProps,
) {
  return <Slide {...props} direction="down" />;
});

export type NotificationSnackbarCountdownProps = SnackbarProps &
  Pick<CountdownTimerProps, 'onComplete' | 'countdownMs'>;

const NotificationSnackbarCountdownUnmemoized = ({
  countdownMs,
  onClose,
  onComplete,
  message,
  ...props
}: NotificationSnackbarCountdownProps) => {
  const theme = useTheme();

  const messageCountdown = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" spacing={4}>
        <CountdownTimer
          onComplete={onComplete}
          countdownMs={countdownMs}
          strokeBgColor={withOpacity(
            theme.palette.background.elevation[24],
            0.5,
          )}
          strokeColor={theme.palette.text.primary}
          strokeWidth={2}
          size={28}
        />
        <Typography variant="subtitle1">{message}</Typography>
      </Stack>
    );
  }, [countdownMs, message, onComplete]);

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      autoHideDuration={countdownMs + 2000}
      message={messageCountdown}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        border: 'none',
        zIndex: theme.zIndex.appBar + 10,
        background: withOpacity(theme.palette.primary.light, 0.95),
      }}
      {...props}
    />
  );
};

export const NotificationSnackbarCountdown = memo(
  NotificationSnackbarCountdownUnmemoized,
);
