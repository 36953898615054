import Stack from '@mui/material/Stack';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { FC, useState } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { AuthenticationInput } from 'src/components/authentication/AuthenticationInput';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import { useWizard } from 'src/components/wizard/Wizard';
import Typography from '@mui/material/Typography';

const CustomizeUsernameDialogUnmemoized: FC = () => {
  const { uid } = useAuth();
  const { go } = useWizard();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    onInputChange,
    updateUsername,
    isValidated,
    hasUserTyped,
    setErrorCodeOf,
    errorMessage,
    userCredentials,
  } = useAuthSubmit();

  const submitUsername = async () => {
    setIsLoading(true);
    const { username } = userCredentials;
    if (!!uid) {
      try {
        await updateUsername(username);
        go('RulesCheckInDialog');
      } catch (e) {
        setErrorCodeOf('username', 'username-unavailable');
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <DialogBodyStandard
      title={'Create a Username'}
      description={
        <Stack spacing={3}>
          <Typography>Please pick a custom username to continue.</Typography>

          <AuthenticationInput
            onSubmit={submitUsername}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              return onInputChange('username', event.currentTarget.value);
            }}
            helperText={
              !!errorMessage.username
                ? 'Username is unavailable.'
                : !isValidated.username && hasUserTyped.username
                ? 'Username must contain 1-25 characters. Dashes and underscores are allowed.'
                : undefined
            }
            label={'Username'}
            type={'text'}
            name={'username'}
            autoFocus={true}
          />
        </Stack>
      }
    >
      <LoadingButton
        fullWidth
        variant="contained"
        size="large"
        disabled={!isValidated.username}
        onClick={submitUsername}
        isLoading={isLoading}
      >
        Change username
      </LoadingButton>
    </DialogBodyStandard>
  );
};

export const CustomizeUsernameDialog = memo(CustomizeUsernameDialogUnmemoized);
