import { memo } from '../../../util/memo';
import { BracketBackground } from './BracketBackground';
import { useRenderColumnsLoser } from './column/useRenderColumnsLoser';
import { ColumnLeftLoser } from './column/ColumnLeftLoser';
import { ColumnCenterLoser } from './column/ColumnCenterLoser';
import { ColumnRightLoser } from './column/ColumnRightLoser';
import { BracketProps } from './Bracket';
import Box from '@mui/material/Box';
import { useBracketColumns } from '../../../hooks/tournaments/useBracketColumns';

export function BracketLoserUnmemoized({ rounds }: BracketProps) {
  const { MobileColumns, LeftColumns, CenterColumns, RightColumns } =
    useBracketColumns({
      rounds,
      useRenderColumns: useRenderColumnsLoser,
      columnComponents: {
        Left: ColumnLeftLoser,
        Center: ColumnCenterLoser,
        Right: ColumnRightLoser,
      },
    });

  return (
    <BracketBackground roundsLength={rounds.length} variant="bracketLoser">
      {MobileColumns}
      {LeftColumns}
      <Box pt={rounds.length === 6 ? 26 : rounds.length == 8 ? 52 : 0}>
        {CenterColumns}
      </Box>
      {RightColumns}
    </BracketBackground>
  );
}

export const BracketLoser = memo(BracketLoserUnmemoized);
