import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { FC } from 'react';
import { memo } from '../../../util/memo';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';
import FormHelperText from '@mui/material/FormHelperText';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { EditableStepsContextType } from '../../../contexts/organizer/EditableStepsContext';
import { TournamentPanelV3 } from '../../tournaments/TournamentPanelV3';

export type SelectInputProps<
  TValue extends string | number | symbol = string | number | symbol,
> = {
  IconComponent?: OverridableComponent<SvgIconTypeMap>;
  values: TValue[];
  label: string;
  onChange: EditableStepsContextType<any>['updateField'];
  fieldName: string;
  valueDisplayedMap?: Record<TValue, string>;
  helperText?: string;
} & Omit<SelectProps, 'label' | 'onChange'>;

const SelectInputUnmemoized: FC<SelectInputProps> = ({
  values,
  label,
  onChange,
  IconComponent,
  fieldName,
  valueDisplayedMap,
  helperText,
  ...selectProps
}) => {
  return (
    <TournamentPanelV3
      title={label}
      TitleIconComponent={IconComponent}
      Content={
        <>
          <Select
            variant="filled"
            label={label}
            onChange={async (e) => {
              const { value: newValue } = e.target;
              await onChange([
                {
                  value: newValue,
                  action: 'overwrite',
                  field: fieldName,
                },
              ]);
            }}
            {...selectProps}
            sx={{
              textTransform: 'uppercase',
            }}
          >
            {values.map((potentialValue) => {
              return (
                <MenuItem
                  key={String(potentialValue)}
                  value={String(potentialValue)}
                  sx={selectProps.sx}
                >
                  {String(
                    valueDisplayedMap?.[String(potentialValue)] ||
                      potentialValue,
                  )}
                </MenuItem>
              );
            })}
          </Select>
          {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </>
      }
    />
  );
};

export const SelectInput = memo(SelectInputUnmemoized);
