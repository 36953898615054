import { useCallback } from 'react';
import { REFEREE_ERROR_LIBRARY } from '../../functions/src/util/error/library/referee';
import { useErrorAlert } from './useErrorAlert';
import { PlacementClaim } from '../../functions/src/types/firestore/Game/Tournament/Bracket';

export type MatchActionsParams = {
  gameId: string;
  matchId: string;
  tournamentId: string;
};

export const useMatchActions = ({
  gameId,
  matchId,
  tournamentId,
}: MatchActionsParams) => {
  const { catchError } = useErrorAlert(REFEREE_ERROR_LIBRARY);

  const revertCurrentMatch = useCallback(async () => {
    const { revertResult } = await import(
      '../firebaseCloud/tournament/revertResult'
    );
    await revertResult({
      gameId,
      tournamentId,
      matchId,
    });
  }, [gameId, matchId, tournamentId]);
  const recreateMatch = useCallback(async () => {
    const { recreateExternalMatch } = await import(
      '../firebaseCloud/tournament/automation/recreateExternalMatch'
    );
    await recreateExternalMatch({ gameId, matchId, tournamentId });
  }, [gameId, matchId, tournamentId]);

  const declareWinner = useCallback(
    async (bestOf: number, winnerId: string, scores: number[][]) => {
      const validScores = scores.some((score) => {
        return score.some((claim) => {
          return claim === 0 || claim === 1;
        });
      });
      if (!validScores) {
        return;
      }
      return await catchError<string>(
        async () => {
          const { finishMatch } = await import(
            '../firebaseCloud/tournament/finishMatch'
          );
          await finishMatch({
            gameId,
            matchId,
            tournamentId,
            winnerId,
            scores: scores as PlacementClaim[][],
            bestOf,
          });
          return 'win';
        },
        () => {
          return 'error';
        },
      );
    },
    [catchError, gameId, matchId, tournamentId],
  );

  const delayMatch = async () => {
    const { setMatchStatus } = await import(
      '../firebaseCloud/tournament/setMatchStatus'
    );
    await setMatchStatus({
      gameId,
      matchId,
      status: 'delayed',
      tournamentId,
    });
  };

  const cancelMatch = async () => {
    console.log('would cancel here...');
  };

  const resetMatch = useCallback(async () => {
    const { resetResult } = await import(
      '../firebaseCloud/tournament/resetResult'
    );
    await resetResult({ gameId, matchId, tournamentId });
  }, [gameId, matchId, tournamentId]);

  return {
    declareWinner,
    delayMatch,
    cancelMatch,
    recreateMatch,
    resetMatch,
    revertCurrentMatch,
  };
};
