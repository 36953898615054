import { RoundKind } from 'src/components/tournaments/RoundStep';
import { RoundStepperProps } from 'src/components/tournaments/RoundStepper';

export type CalculateRoundKindParams = Pick<
  RoundStepperProps,
  'roundsCount'
> & {
  index: number;
};

export function calculateRoundKind({
  index,
  roundsCount,
}: CalculateRoundKindParams): RoundKind {
  return index === roundsCount - 1
    ? 'final'
    : index === roundsCount - 2
    ? 'semifinal'
    : 'normal';
}
