import { useContext } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { TournamentCardThumbnail } from './TournamentCardThumbnail';
import { extractPlaybackIds } from '../../../../functions/src/util/mux/extractPlaybackIds';
import { LivestreamProvider } from '../../../contexts/LivestreamContext';
import {
  SPONSORS_WIDTH,
  TournamentCardSponsors,
} from './TournamentCardSponsors';

export const IMG_URL_DEFAULT =
  '/assets/images/tournament-calendar/img-url-default.png';
export const SPONSOR_IMG_URL_DEFAULT = '/assets/images/logo.svg';

export const IMAGERY_HEIGHT = 106 as const;
export const IMAGERY_WIDTH = 292 as const;

const GAP = 4 as const;

const TournamentCardImageryUnmemoized = () => {
  const {
    tournamentThumbnailImgUrl = IMG_URL_DEFAULT,
    streamerLiveThumbnail,
    sponsors,
    playbacksDownloadable,
  } = useContext(TournamentContext);
  const playbackIdLatest = extractPlaybackIds(playbacksDownloadable)[0];

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: `${GAP}px`,
        display: 'flex',
        height: `${IMAGERY_HEIGHT}px`,
      }}
    >
      <LivestreamProvider
        playbackId={playbackIdLatest}
        playbacksDownloadable={playbacksDownloadable}
      >
        <TournamentCardThumbnail
          imgUrl={streamerLiveThumbnail || tournamentThumbnailImgUrl}
          height={IMAGERY_HEIGHT}
          width={
            sponsors?.length
              ? IMAGERY_WIDTH - SPONSORS_WIDTH - GAP
              : IMAGERY_WIDTH
          }
        />
      </LivestreamProvider>
      {sponsors && sponsors.length > 0 && (
        <TournamentCardSponsors sponsors={sponsors} height={IMAGERY_HEIGHT} />
      )}
    </Stack>
  );
};

export const TournamentCardImagery = memo(TournamentCardImageryUnmemoized);
