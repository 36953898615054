import {
  CUSTOM_SIGN_IN_METHODS,
  CustomSignInMethod,
  GENERIC_SIGN_IN_METHODS,
  GenericSignInMethod,
  NON_REDIRECT_SIGN_IN_METHODS,
  NonRedirectSignInMethod,
} from '../../../functions/src/types/firestore/User';

export const filterNonRedirectSignInMethods = (
  providerIds: string[],
  reAuthenticate = false,
): NonRedirectSignInMethod[] => {
  return NON_REDIRECT_SIGN_IN_METHODS.filter((method) => {
    return reAuthenticate === providerIds.includes(method);
  });
};

export const filterGenericSignInMethods = (
  providerIds: string[],
  reAuthenticate = false,
): GenericSignInMethod[] => {
  return GENERIC_SIGN_IN_METHODS.filter((method) => {
    return reAuthenticate === providerIds.includes(method);
  });
};

export const filterCustomSignInMethods = (
  providerIds: string[],
  reAuthenticate = false,
): CustomSignInMethod[] => {
  return CUSTOM_SIGN_IN_METHODS.filter((method) => {
    return reAuthenticate === providerIds.includes(method);
  });
};
