import clsx from 'clsx';
import { useRouter } from 'src/hooks/routing/useRouter';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { forwardRef, AnchorHTMLAttributes, useMemo, Fragment } from 'react';
import { prependUtc } from 'functions/src/util/time/timezone';
import Box from '@mui/material/Box';
import { isOnApp } from '../util/isOnApp';
import { openInAppBrowser } from '../util/openInAppBrowser';
// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

type NextLinkComposedProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  Omit<
    NextLinkProps,
    'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'
  > & {
    to: NextLinkProps['href'];
    linkAs?: NextLinkProps['as'];
  };

export const NextLinkComposed = forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  // eslint-disable-next-line no-shadow
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } =
    props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href' | 'sx'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Component(
  {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    role, // Link doesn't have roles.
    // eslint-disable-next-line no-shadow
    scroll,
    shallow,
    target = undefined,
    ...other
  },
  ref,
) {
  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });
  const isExternal =
    typeof href === 'string' &&
    (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  const targetFinal = useMemo(() => {
    if (isOnApp()) {
      return undefined;
    }
    return target;
  }, [target]);

  const externalLink = useMemo(() => {
    const urlNormalized = href.toString();
    return isOnApp() ? (
      <Box
        onClick={async () => {
          await openInAppBrowser(urlNormalized);
        }}
        ref={ref}
        className={className}
        {...other}
      >
        {other.children}
      </Box>
    ) : noLinkStyle ? (
      <Anchor
        target="_blank"
        className={className}
        href={urlNormalized}
        ref={ref}
        {...other}
      />
    ) : (
      <MuiLink
        target="_blank"
        className={className}
        href={urlNormalized}
        ref={ref}
        {...other}
      />
    );
  }, [className, href, noLinkStyle, other, ref]);

  if (isExternal) {
    return <Fragment>{externalLink}</Fragment>;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = {
    to: shallow ? prependUtc(router.asPath, href) : href,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
  };

  if (noLinkStyle) {
    return (
      <NextLinkComposed
        className={className}
        ref={ref}
        {...nextjsProps}
        {...other}
        target={targetFinal}
      />
    );
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      className={className}
      ref={ref}
      {...nextjsProps}
      {...other}
      target={targetFinal}
    />
  );
});
