import { TokenPriceable } from '../../../functions/src/types/firestore/User/Payout';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { useCurrency } from '../../contexts/CurrencyContext';
import { PriceableToken } from '../../../functions/src/util/currencies/PriceableToken';

export const usePriceableToken = (token: TokenPriceable) => {
  const { formatFiat } = useCurrency();
  const converter = ConverterFactory.buildEvmAddressConverter<TokenPriceable>();
  return new PriceableToken(converter.convertData(token), {
    formatFiat,
  });
};
